export default class ApiMassUpdateAccruedInterest {
	projectId: number;
	accrualTypeId: string;
	accrualTypeFilterEnabled: boolean;
	financeSourceId: number;
	sourceFilterEnabled: boolean;
	quarterId: string;
	quarterFilterEnabled: boolean;
	accrualStartDate: string;
	accrualEndDate: string;
	paymentDate: string;
	paymentDateSettingEnabled: boolean;
	accruedAmount: number;
	accruedAmountSettingEnabled: boolean;

	constructor(
		projectId: number = 0,
		accrualTypeId: string = "",
		accrualTypeFilterEnabled: boolean = false,
		financeSourceId: number = 0,
		sourceFilterEnabled: boolean = false,
		quarterId: string = "",
		quarterFilterEnabled: boolean = false,
		accrualStartDate: string = "",
		accrualEndDate: string = "",
		paymentDate:  string = "",
		paymentDateSettingEnabled: boolean = false,
		accruedAmount: number = 0,
		accruedAmountSettingEnabled: boolean = false,
	)
	{
		this.projectId = projectId;
		this.accrualTypeId = accrualTypeId;
		this.accrualTypeFilterEnabled = accrualTypeFilterEnabled;
		this.financeSourceId = financeSourceId;
		this.sourceFilterEnabled = sourceFilterEnabled;
		this.quarterId = quarterId;
		this.quarterFilterEnabled = quarterFilterEnabled;
		this.accrualStartDate = accrualStartDate;
		this.accrualEndDate = accrualEndDate;
		this.paymentDate = paymentDate;
		this.paymentDateSettingEnabled = paymentDateSettingEnabled;
		this.accruedAmount = accruedAmount;
		this.accruedAmountSettingEnabled = accruedAmountSettingEnabled;
	}
}

<template>
	<v-card elevation="0" class="loan-card px-4 pt-4 pb-8 fill-height">
		<v-row>
			<v-col class="d-flex justify-space-between align-center">
				<span class="text-h6 blue--text text--darken-4">
					{{ $t("titles.costs") }}
				</span>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="2">
				<frp-autocomplete dense
								  :items="years"
								  item-text="year"
								  item-value="year"
								  v-model="selectedYearValue"
								  hide-details>
				</frp-autocomplete>
			</v-col>
			<v-col cols="2">
				<frp-autocomplete dense
								  :items="months"
								  item-text="name"
								  item-value="month"
								  v-model="selectedMonthValue"
								  hide-details>
				</frp-autocomplete>
			</v-col>
		</v-row>

		<v-row>
			<v-col>
				<v-data-table :headers="headers"
							  :loading-text="$t('tables.loading')"
							  hide-default-footer
							  item-key="id"
							  :items="visibleCosts"
							  :item-class="() => 'text-body-2 cy-table-row'"
							  class="loan-table colored-table clickable-rows d-flex flex-column px-3">

					<template #item.costTypeId="{ item }">
						<span>{{ getCostTypeName(item.costTypeId) }}</span>
					</template>
					<template #item.actualAmount="{ item }">
						<span v-if="mode === MainInfoModeType.READ">{{ formatCurrency(item.actualAmount) }}</span>
						<frp-text-field v-else
										dense
										style="max-width: 100px"
										v-model.number="editableVersion.costs.find(x => x.costTypeId === item.costTypeId && x.year === item.year && x.month === item.month).actualAmount"
										type="number"
										hide-details>
						</frp-text-field>
					</template>
					<template #item.plannedAmount="{ item }">
						<span v-if="mode === MainInfoModeType.READ">{{  formatCurrency(item.plannedAmount) }}</span>
						<frp-text-field v-else
										dense
										style="max-width: 100px"
										v-model.number="editableVersion.costs.find(x => x.costTypeId === item.costTypeId && x.year === item.year && x.month === item.month).plannedAmount"
										type="number"
										hide-details>
						</frp-text-field>
					</template>
					<template #body.append>
						<tr class="loan-costs-totals">
							<td>Итого за месяц</td>
							<td>{{formatCurrency(totals.actual.month)}}</td>
							<td>{{formatCurrency(totals.planned.month)}}</td>
						</tr>
						<tr class="loan-costs-totals">
							<td>Итого за год</td>
							<td>{{formatCurrency(totals.actual.year)}}</td>
							<td>{{formatCurrency(totals.planned.year)}}</td>
						</tr>
						<tr class="loan-costs-totals">
							<td>Итого</td>
							<td>{{formatCurrency(totals.actual.total)}}</td>
							<td>{{formatCurrency(totals.planned.total)}}</td>
						</tr>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
	import { getterTypes } from "@/store/loan/modules/troubledBorrower/modules/mainInfo/types";
	import { MainInfoModeType } from "@/store/loan/modules/troubledBorrower/modules/mainInfo/types/mainInfoModeType";
	import colorsMixin from "@/mixins/colorsMixin";
	import { listMixin } from "@/mixins/listMixin";
	import formMixin from "@/mixins/formMixin";
	import storeManager from "@/store/manager";
	import FrpAutocomplete from "@/components/fields/FrpAutocomplete";
	import FrpBtn from "@/components/buttons/FrpBtn";
	import FrpTextField from "@/components/fields/FrpTextField";
	import FrpIcon from "@/components/icon/FrpIcon";
	import FrpTextBodyTwo from "@/components/typography/FrpTextBodyTwo";
	import { createNamespacedHelpers } from "vuex";

	import { formatDate } from "@/utils/dates";
	import { dateFormat, dateTimeFormat } from "@/utils/formats";
	import { formatCurrency } from "@/utils/formatting";
	import { mapYearToObject, mapMonthToObject } from "@/utils/calendar";
		
	const namespace = storeManager.loan.troubledBorrower.mainInfo.namespace;
	const { mapState, mapGetters } = createNamespacedHelpers(namespace);
	const { mapState: troubledBorrowerMapStateHelpers } = createNamespacedHelpers(storeManager.loan.troubledBorrower.namespace);

	export default {
		mixins: [colorsMixin, listMixin, formMixin],
		data() {
			return {
				namespace,
				MainInfoModeType,
				selectedYear: new Date().getFullYear(),
				selectedMonth: new Date().getMonth() + 1,
				dateFormat,
				dateTimeFormat,
				formatDate,
				formatCurrency,
			}
		},
		computed: {
			...troubledBorrowerMapStateHelpers({
				costTypes: state => state.costTypes
			}),
			...mapState({
				editableVersion: state => state.editableVersion,
				mode: state => state.mode
			}),
			...mapGetters({
				version: getterTypes.version,
			}),
			years() {
				return Array.from(new Set(this.version.costs.map(x => x.year))).sort((x1, x2) => x1 - x2).map(mapYearToObject);
			},
			selectedYearValue: {
				get() {
					return this.selectedYear;
				},
				set(value) {
					this.selectedYear = value ?? this.years[0].year;
				}
			},
			months() {
				const monthsInCurrentYear = this.version.costs
					.filter(x => x.year == this.selectedYear)
					.map(x => x.month);
				return Array.from(new Set(monthsInCurrentYear)).sort((x1, x2) => x1 - x2).map(mapMonthToObject);
			},
			selectedMonthValue: {
				get() {
					return this.selectedMonth;
				},
				set(value) {
					this.selectedMonth = value ?? this.months[0].month;
				}
			},
			visibleCosts() {
				const source = this.mode === MainInfoModeType.READ ? this.version.costs : this.editableVersion.costs;

				return source
					.filter(x => x.year === this.selectedYear && x.month === this.selectedMonth)
					.sort((x1, x2) => this.getCostTypeOrder(x1.costTypeId) - this.getCostTypeOrder(x2.costTypeId));
			},
			totals() {
				const source = this.mode === MainInfoModeType.READ ?
					this.version.costs : this.editableVersion.costs;
				const year = this.selectedYear;
				const month = this.selectedMonth;

				function sum(kindName) {
					const result = { month: 0, year: 0, total: 0 };

					source.forEach((cost) => {
						result.total += cost[kindName];
						if (cost.year == year)
						{
							result.year += cost[kindName];
							if (cost.month == month)
							{
								result.month += cost[kindName];
							}
						}
					});

					return result;
				}
				var actualTotals = sum('actualAmount');
				var plannedTotals = sum('plannedAmount');

				return { actual: actualTotals, planned: plannedTotals };
			},
			headers() {
				return [
					{
						value: "costTypeId",
						class: "text-caption",
						width: "40%",
						sortable: false
					},
					{
						text: this.$t("tables.costs.actualAmount"),
						value: "actualAmount",
						class: "text-caption",
						width: "30%",
						sortable: false
					},
					{
						text: this.$t("tables.costs.plannedAmount"),
						value: "plannedAmount",
						class: "text-caption",
						width: "30%",
						sortable: false
					},
				];
			},
		},
		methods: {
			getCostTypeName(costTypeId) {
				return this.costTypes.find(x => x.id === costTypeId).name;
			},
			getCostTypeOrder(costTypeId) {
				return this.costTypes.find(x => x.id === costTypeId).order;
			}
		},
		components: {
			FrpAutocomplete,
			FrpBtn,
			FrpTextField,
			FrpIcon,
			FrpTextBodyTwo
		}

	}
</script>

<style scoped lang="scss">
	.loan-costs-totals {
		font-weight: bold;
	}
</style>

import ApiDocumentDescription from "@/api/loan/types/reports/apiDocumentDescription";
import ApiUser from "@/api/loan/types/reports/apiUser";
import ApiReportDocumentFile from "@/api/loan/types/reports/apiReportDocumentFile";

export default class ApiReportFinalDocument {
	constructor(
		public description: ApiDocumentDescription = new ApiDocumentDescription(),
		public canUploadFile: boolean = false,
		public canUploadGeneratedFile: boolean = false,
		public canGenerate: boolean = false,
		public uploaderUser: ApiUser = new ApiUser(),
		public uploadDateTime: string = "",
		public signerUser: ApiUser = new ApiUser(),
		public documentFiles: ApiReportDocumentFile[] = [],
		public isSignEnabled: boolean = false,
		public isSignRequired: boolean = false
	)
	{
	}
}

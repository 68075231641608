<template>
	<loan-content-layout>
		<v-row>
			<v-col class="d-flex flex-column pt-4" style="gap: 24px">
				<v-card elevation="0" class="loan-card px-2 py-6">
					<v-row class="align-center">
						<v-col class="py-0">
							<frp-custom-title-loader/>
						</v-col>
						<v-col class="py-0">
							<v-card-actions class="px-4">
								<div class="d-flex justify-md-end" style="width: 100%">
									<frp-btn-loader height="40" width="160" classes="mr-4"/>
									<frp-btn-loader height="40" width="160"/>
								</div>
							</v-card-actions>
						</v-col>
					</v-row>
				</v-card>
				<v-card elevation="0" class="loan-card px-6 pt-9 pb-6">
					<div class="pb-9 d-flex flex-column" style="row-gap: 16px">
						<v-row>
							<v-col class="d-flex flex-column py-0">
								<v-skeleton-loader type="image" height="40" max-width="390"/>
							</v-col>
							<v-col class="d-flex flex-column py-0">
								<v-skeleton-loader type="image" height="40" max-width="240"/>
							</v-col>
							<v-col class="d-flex flex-column py-0">
								<v-skeleton-loader type="image" height="40" max-width="240"/>
							</v-col>
							<v-col class="d-flex flex-column py-0">
								<v-skeleton-loader type="image" height="40" max-width="240"/>
							</v-col>
							<v-spacer/>
							<v-col md="3">
								<v-row>
									<v-col class="d-flex flex-column">
										<v-skeleton-loader type="text" max-width="100" class="mt-2"/>
									</v-col>
									<v-col>
										<v-row>
											<v-col md="3" class="d-flex flex-column">
												<v-skeleton-loader type="text" max-width="20" class="mt-2"/>
											</v-col>
											<v-col class="d-flex flex-column py-0">
												<frp-switch-loader/>
											</v-col>
											<v-col md="3" class="d-flex flex-column">
												<v-skeleton-loader type="text" max-width="20" class="mt-2"/>
											</v-col>
										</v-row>
									</v-col>
								</v-row>
							</v-col>
							<v-col class="d-flex flex-column py-0">
								<v-card-actions class="px-0">
									<div class="d-flex align-center justify-md-end" style="width: 100%">
										<frp-btn-loader height="40" width="140" classes="mr-4"/>
										<frp-btn-loader height="40" width="140"/>
									</div>
								</v-card-actions>
							</v-col>
						</v-row>
						<v-divider class="mb-4"></v-divider>
						<v-row>
							<v-col md="2">
								<v-row>
									<v-col md="3" class="d-flex flex-column py-0">
										<v-skeleton-loader type="text" max-width="20"/>
									</v-col>
									<v-col class="d-flex flex-column py-0">
										<v-skeleton-loader type="text" max-width="100"/>
									</v-col>
								</v-row>
							</v-col>
							<v-col md="1" class="d-flex flex-column py-0">
								<v-skeleton-loader type="text" max-width="100"/>
							</v-col>
							<v-col md="2" class="d-flex flex-column py-0">
								<v-skeleton-loader type="text" max-width="100"/>
							</v-col>
							<v-col md="2" class="d-flex flex-column py-0">
								<v-skeleton-loader type="text" max-width="100"/>
							</v-col>
							<v-col md="1" class="d-flex flex-column py-0">
								<v-skeleton-loader type="text" max-width="100"/>
							</v-col>
							<v-col md="1" class="d-flex flex-column py-0">
								<v-skeleton-loader type="text" max-width="100"/>
							</v-col>
							<v-col md="1" class="d-flex flex-column py-0">
								<v-skeleton-loader type="text" max-width="100"/>
							</v-col>
							<v-col md="1" class="d-flex flex-column py-0">
								<v-skeleton-loader type="text" max-width="100"/>
							</v-col>
							<v-col md="1" class="d-flex flex-column"></v-col>
						</v-row>
						<v-divider class="mb-4"></v-divider>
						<v-row class="pb-3" v-for="(_, index) in 8" :key="index">
							<v-col class="d-flex flex-column py-0" md="2">
								<v-skeleton-loader type="text" max-width="20"/>
							</v-col>
							<v-col md="1" class="d-flex flex-column py-0">
								<v-skeleton-loader type="text" max-width="100"/>
							</v-col>
							<v-col md="2" class="d-flex flex-column py-0">
								<v-skeleton-loader type="text" max-width="100"/>
							</v-col>
							<v-col md="2" class="d-flex flex-column py-0">
								<v-skeleton-loader type="text" max-width="100"/>
							</v-col>
							<v-col md="1" class="d-flex flex-column py-0">
								<v-skeleton-loader type="text" max-width="100"/>
							</v-col>
							<v-col md="1" class="d-flex flex-column py-0">
								<v-skeleton-loader type="text" max-width="100"/>
							</v-col>
							<v-col md="1" class="d-flex flex-column py-0">
								<v-skeleton-loader type="text" max-width="100"/>
							</v-col>
							<v-col md="1" class="d-flex flex-column py-0">
								<v-skeleton-loader type="text" max-width="100"/>
							</v-col>
							<v-col md="1" class="d-flex flex-column">
								<v-row>
									<v-col offset-md="8" class="d-flex flex-column py-0">
										<v-skeleton-loader type="text" max-width="20"/>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</div>
				</v-card>
				<v-row class="pt-0">
					<v-col class="d-flex flex-column pt-0"></v-col>
					<v-col class="d-flex pt-0 justify-end pt-0">
						<v-skeleton-loader type="text" style="width: 32px" class="mr-2" v-for="(_, i) in 8" :key="i"/>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</loan-content-layout>
</template>

<script>
import LoanContentLayout from "Components/layouts/LoanContentLayout";
import FrpCustomTitleLoader from "Components/loaders/common/FrpCustomTitleLoader";
import FrpBtnLoader from "Components/loaders/form/FrpBtnLoader";
import FrpSwitchLoader from "Components/loaders/form/FrpSwitchLoader";

export default {
	components: {
		LoanContentLayout,
		FrpBtnLoader,
		FrpCustomTitleLoader,
		FrpSwitchLoader
	}
};
</script>

import ApiProject from "@/api/loan/types/reports/apiProject";
import ApiReportAcceptance from "@/api/loan/types/reports/apiReportAcceptance";
import ApiReportQueries from "@/api/loan/types/reports/apiReportQueries";
import ReportFinalDocument from "@/store/loan/modules/reportsFinal/types/reportFinalDocument";

export default class ReportFinal {
	documents: ReportFinalDocument[];
	project: ApiProject;
	acceptance: ApiReportAcceptance;
	canCreateReportingQueries: boolean;
	reportQueries: ApiReportQueries;

	constructor(
		documents: ReportFinalDocument[] = [],
		project: ApiProject = new ApiProject(),
		acceptance: ApiReportAcceptance = new ApiReportAcceptance(),
		canCreateReportingQueries: boolean = false,
		reportQueries: ApiReportQueries = new ApiReportQueries()
	)
	{
		this.documents = documents;
		this.project = project;
		this.acceptance = acceptance;
		this.canCreateReportingQueries = canCreateReportingQueries;
		this.reportQueries = reportQueries;
	}
}

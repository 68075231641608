import { addProfile, createMap, createMapper, forMember, mapFrom, MappingProfile } from "@automapper/core";
import { classes } from "@automapper/classes";
import { convertToZonedIso, convertToTimestamp } from "@/utils/dates";
import ApiOverdueInterest from "@/api/loan/types/loanInterest/apiOverdueInterest";
import OverdueInterest from "@/store/loan/modules/overdueInterest/modules/overdueInterestIList/types/overdueInterest";

const mapper = createMapper({
	strategyInitializer: classes()
});

const overdueInterestProfile: MappingProfile = (mapper) => {
	createMap(mapper, ApiOverdueInterest, OverdueInterest,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.number, mapFrom(x => x.number)),
		forMember(d => d.projectId, mapFrom(x => x.projectId)),
		forMember(d => d.financingSourceId, mapFrom(x => x.financingSourceId)),
		forMember(d => d.quarterId, mapFrom(x => x.quarterId)),
		forMember(d => d.startDate, mapFrom(x => convertToTimestamp(x.startDate))),
		forMember(d => d.amount, mapFrom(x => x.amount)),
		forMember(d => d.comment, mapFrom(x => x.comment))
	);

	createMap(mapper, OverdueInterest, ApiOverdueInterest,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.number, mapFrom(x => x.number)),
		forMember(d => d.projectId, mapFrom(x => x.projectId)),
		forMember(d => d.financingSourceId, mapFrom(x => x.financingSourceId)),
		forMember(d => d.quarterId, mapFrom(x => x.quarterId)),
		forMember(d => d.startDate, mapFrom(x => convertToZonedIso(x.startDate))),
		forMember(d => d.amount, mapFrom(x => x.amount)),
		forMember(d => d.comment, mapFrom(x => x.comment)));
};

addProfile(mapper, overdueInterestProfile);

export default mapper;

export default class ApiRequirement {
	constructor(
		public versionId: string = "",
		public debtorId: string = "",
		public requirementId: string = "",
		public requirementTypeId: string = "",
		public sum: number = 0
	)
	{
	}
}

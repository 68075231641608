import { difference } from "@/utils/difference";
import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import AccruedInterestRouteQuery from "@/store/loan/modules/accruedInterest/types/accruedInterestRouteQuery";
import AccruedInterestState from "@/store/loan/modules/accruedInterest/types/accruedInterestState";

export default class AccruedInterestRouteQueryService {
	defaultRouteQuery: AccruedInterestRouteQuery;

	constructor(defaultRouteQuery: AccruedInterestRouteQuery) {
		this.defaultRouteQuery = defaultRouteQuery;
	}

	resolveRouteQueryDictionary(state: AccruedInterestState) {
		let query = new AccruedInterestRouteQuery(
			state.paging.page,
			state.filter.accrualStartDate,
			state.filter.accrualEndDate,
			state.filter.accrualType,
			state.filter.paymentStartDate,
			state.filter.paymentEndDate,
			state.filter.accountedStartDate,
			state.filter.accountedEndDate,
			state.filter.financingSourceId,
			state.filter.quarterId
		);

		return difference(this.defaultRouteQuery, query);
	}

	async resolveRouteQuery(query: Dictionary<any>) {
		try {
			let result = plainToInstance(AccruedInterestRouteQuery, {
				...this.defaultRouteQuery,
				...query
			}, { enableImplicitConversion: true });

			await validateOrReject(result);

			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}
}

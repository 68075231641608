import TreeModel from "tree-model";

class Route {
	name: string;
	children: Route[];

	constructor(name: string, children: Route[] = []) {
		this.name = name;
		this.children = children;
	}
}

export enum RouteNames {
	ROOT = "ROOT",
	CALLBACK = "CALLBACK",
	SILENT_RENEW = "SILENT_RENEW",

	LOAN_SCHEDULE = "LOAN_SCHEDULE",

	ACCRUED_INTEREST = "ACCRUED_INTEREST",
	OVERDUE_INTEREST = "OVERDUE_INTEREST",
	AGREEMENTS_ACCRUED_INTEREST = "AGREEMENTS_ACCRUED_INTEREST",
	
	ASSIGNMENT_COMPENSATION = "ASSIGNMENT_COMPENSATION",

	AGREEMENTS_OVERDUE_PAYMENTS = "AGREEMENTS_OVERDUE_PAYMENTS",
	AGREEMENTS_ACCRUED_PENALTIES = "AGREEMENTS_ACCRUED_PENALTIES",

	CASH_FLOW = "CASH_FLOW",
	TREASURY_STATEMENT = "TREASURY_STATEMENT",

	TROUBLED_BORROWER = "TROUBLED_BORROWER",
	TROUBLED_BORROWERS = "TROUBLED_BORROWERS",
	REPORTS = "REPORTS",
	REPORTS_FINAL = "REPORTS_FINAL",

	UPCOMING_PAYMENTS = "UPCOMING_PAYMENTS",

	ERROR_PAGE_NOT_FOUND = "ERROR_PAGE_NOT_FOUND",
	ERROR_ACCESS_DENIED = "ERROR_ACCESS_DENIED",
	ERROR_INTERNAL_SERVER_ERROR = "ERROR_INTERNAL_SERVER_ERROR"
}


const tree = new TreeModel();

export const findRoute = (routeName: string, route = routesThreeRoot) => {
	return route.first(x => x.model.name === routeName);
};

export const routesThreeRoot = tree.parse<Route>(
	new Route(RouteNames.ROOT, [
		new Route(RouteNames.CALLBACK),
		new Route(RouteNames.SILENT_RENEW),

		new Route(RouteNames.LOAN_SCHEDULE),

		new Route(RouteNames.ACCRUED_INTEREST),
		new Route(RouteNames.OVERDUE_INTEREST),
		new Route(RouteNames.AGREEMENTS_ACCRUED_INTEREST),

		new Route(RouteNames.ASSIGNMENT_COMPENSATION),
		
		new Route(RouteNames.AGREEMENTS_OVERDUE_PAYMENTS),
		new Route(RouteNames.AGREEMENTS_ACCRUED_PENALTIES),

		new Route(RouteNames.CASH_FLOW),
		new Route(RouteNames.TREASURY_STATEMENT),

		new Route(RouteNames.TROUBLED_BORROWER),
		new Route(RouteNames.TROUBLED_BORROWERS),

		new Route(RouteNames.REPORTS),
		new Route(RouteNames.REPORTS_FINAL),

		new Route(RouteNames.UPCOMING_PAYMENTS),

		new Route(RouteNames.ERROR_PAGE_NOT_FOUND),
		new Route(RouteNames.ERROR_ACCESS_DENIED),
		new Route(RouteNames.ERROR_INTERNAL_SERVER_ERROR)
	]));

import storeManager from "@/store/manager";
import { RouteNames } from "@/router/loan/routes";

export const routeToStoreMap = new Map<string, string[]>([
		[RouteNames.LOAN_SCHEDULE, [storeManager.loan.loanSchedule.namespace]],

		[RouteNames.ACCRUED_INTEREST, [storeManager.loan.accruedInterest.namespace]],
		[RouteNames.OVERDUE_INTEREST, [storeManager.loan.overdueInterest.namespace]],
		[RouteNames.AGREEMENTS_ACCRUED_INTEREST, [storeManager.loan.interestAccrual.namespace]],
		
		[RouteNames.ASSIGNMENT_COMPENSATION, [storeManager.loan.assignmentCompensation.namespace]],

		[RouteNames.AGREEMENTS_OVERDUE_PAYMENTS, [storeManager.loan.agreementsOverduePayments.namespace]],
		[RouteNames.AGREEMENTS_ACCRUED_PENALTIES, [storeManager.loan.agreementsAccruedPenalties.namespace]],

		[RouteNames.CASH_FLOW, [storeManager.loan.cashFlow.namespace]],
		[RouteNames.TREASURY_STATEMENT, [storeManager.loan.treasuryStatement.namespace]],

		[RouteNames.TROUBLED_BORROWER, [storeManager.loan.troubledBorrower.namespace]],
		[RouteNames.TROUBLED_BORROWERS, [storeManager.loan.troubledBorrowers.namespace]],

		[RouteNames.UPCOMING_PAYMENTS, [storeManager.loan.upcomingPayments.namespace]]
	]
);

import ApiAgreement from "@/api/loan/types/loanSchedule/apiAgreement";
import { CreateVersionModeType } from "@/store/loan/modules/loanSchedule/modules/agreement/types/createVersionModeType";
import FormState from "@/store/shared/form/models/formState";
import IPageState from "@/store/shared/base/types/pageState";
import Version from "@/store/loan/modules/loanSchedule/types/version";
import { AgreementModeType } from "@/store/loan/modules/loanSchedule/modules/agreement/types/agreementModeType";

export default class AgreementState implements IPageState {
	constructor(
		public form: FormState,
		public snapshot: object,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public draft: Version = new Version(),
		public agreement: ApiAgreement = new ApiAgreement(),
		public mode: CreateVersionModeType = CreateVersionModeType.UNKNOWN,
		public isVersionActivating: boolean = false,
		public agreementMode: AgreementModeType = AgreementModeType.READ,
	)
	{
	}
}

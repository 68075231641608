<template>
	<loan-content-layout>
		<v-row>
			<v-col class="d-flex flex-column pt-4" style="gap: 2px">
				<v-card elevation="0"
						class="loan-card px-6 pt-3 pb-6"
						style="border-bottom-left-radius: 0; border-bottom-right-radius: 0;">
					<v-row>
						<v-col class="d-flex" :style="{ 'flex-direction': $vuetify.breakpoint.lgAndUp ? '' : 'column' }">
							<div class="d-flex flex-wrap" style="gap: 0 24px">
								<div class="d-flex flex-wrap" style="gap: 24px">
									<div style="width: 210px">
										<v-skeleton-loader height="12" width="100" type="image" class="mb-2 rounded-lg"/>
										<v-skeleton-loader height="40" type="image" class="rounded-md"/>
									</div>
									<div style="width: 145px">
										<v-skeleton-loader height="12" width="100" type="image" class="mb-2 rounded-lg"/>
										<v-skeleton-loader height="40" type="image" class="rounded-md"/>
									</div>
									<div style="width: 235px">
										<v-skeleton-loader height="12" width="100" type="image" class="mb-2 rounded-lg"/>
										<v-skeleton-loader height="40" type="image" class="rounded-md"/>
									</div>
								</div>
								<div class="pa-0 ma-0 ml-1 d-flex align-center py-2 flex-nowrap">
									<v-container class="pa-0 mx-0 pt-2 pb-0" fluid>
										<v-row :style="{ 'flex-direction': $vuetify.breakpoint.smAndDown ? 'column' : '' }">
											<v-col class="px-2 pb-2 pt-4 d-flex align-center"
												   md="auto"
												   v-for="(item, i) of 6" :key="`l-${i}`">
												<v-skeleton-loader width="20"
																   height="20"
																   type="image"
																   class="rounded-md">
												</v-skeleton-loader>
												<v-skeleton-loader width="85"
																   height="16"
																   type="image"
																   class="ml-2 rounded-md">
												</v-skeleton-loader>
											</v-col>
										</v-row>
									</v-container>
								</div>
							</div>
							<v-spacer></v-spacer>
							<div class="d-flex pt-5">
								<frp-btn-loader width="145" height="40" class="mr-4"></frp-btn-loader>
								<frp-btn-loader width="125" height="40"></frp-btn-loader>
							</div>
						</v-col>
					</v-row>
				</v-card>
				<v-card elevation="0"
						class="loan-card px-6 py-6 d-flex flex-column"
						style="border-top-left-radius: 0; border-top-right-radius: 0;">
					<v-row style="margin-top: 1px">
						<div style="width: 70px;" class="d-flex justify-center flex-column px-3">
							<v-skeleton-loader type="text" width="40"/>
						</div>
						<v-col v-for="(item, index) in 5" :key="`k-${index}`">
							<v-skeleton-loader type="text" max-width="80"/>
						</v-col>
						<v-col>
							<v-skeleton-loader type="text" max-width="140"/>
						</v-col>
						<v-col>
							<v-skeleton-loader type="text" max-width="200"/>
						</v-col>
						<div style="width: 120px;" class="d-flex flex-column px-3">
						</div>
						<div style="width: 120px;" class="d-flex flex-column px-3 mr-8">
						</div>
					</v-row>
					<v-divider class="mt-3"></v-divider>
					<div class="table-header mb-8 d-flex align-center justify-end">
						<frp-btn-loader width="160" class="mr-4"/>
					</div>
					<v-row style="margin-bottom: 11px" v-for="item in 3" :key="`i-${item}`">
						<div style="width: 70px;" class="d-flex justify-center flex-column px-3">
							<v-skeleton-loader type="text" width="40"/>
						</div>
						<v-col v-for="(item, index) in 5" :key="`j-${index}`">
							<v-skeleton-loader type="text" max-width="80"/>
						</v-col>
						<v-col>
							<v-skeleton-loader type="text" max-width="30"/>
						</v-col>
						<v-col>
							<v-skeleton-loader type="text" max-width="200"/>
						</v-col>
						<div style="width: 150px;" class="d-flex flex-column px-3">
						</div>
						<div style="width: 40px;" class="d-flex flex-column px-3 mr-2">
							<v-skeleton-loader type="text" max-width="20"/>
						</div>
						<div style="width: 40px;" class="d-flex flex-column px-3 mr-8">
							<v-skeleton-loader type="text" max-width="20"/>
						</div>
					</v-row>
					<v-row style="padding-bottom: 10px" class="mt-0">
						<div style="width: 70px;" class="d-flex justify-center flex-column px-3">
						</div>
						<v-col v-for="(item, index) in 2" :key="`o-${index}`">
							<v-skeleton-loader max-width="80"/>
						</v-col>
						<v-col v-for="(item, index) in 2" :key="`y-${index}`">
							<v-skeleton-loader type="text" max-width="80"/>
						</v-col>
						<v-col>
							<v-skeleton-loader max-width="80"/>
						</v-col>

						<v-col>
							<v-skeleton-loader max-width="200"/>
						</v-col>
						<v-col>
							<v-skeleton-loader max-width="200"/>
						</v-col>
						<div style="width: 120px;" class="d-flex flex-column px-3">
						</div>
						<div style="width: 120px;" class="d-flex flex-column px-3 mr-8">
						</div>
					</v-row>
				</v-card>
			</v-col>
		</v-row>
	</loan-content-layout>
</template>

<script>
import LoanContentLayout from "Components/layouts/LoanContentLayout";
import FrpBtnLoader from "Components/loaders/form/FrpBtnLoader";
import FrpInputLoader from "Components/loaders/form/FrpInputLoader";

export default {
	components: { FrpBtnLoader, FrpInputLoader, LoanContentLayout }
};
</script>

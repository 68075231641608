<template>
	<v-row class="mx-0">
		<v-col class="pb-2 pt-0">
			<loan-card :title="$t('titles.plannedPayments')">
				<template #title-append v-if="false">
					<frp-btn elevation="0" dark color="blue">{{ $t("buttons.fullRepaymentRequest") }}</frp-btn>
				</template>

				<v-row class="ma-0">
					<v-col cols="12" lg="4" class="pa-0"
						   :class="{ 'pr-2': $vuetify.breakpoint.lgAndUp, 'mb-4': $vuetify.breakpoint.mdAndDown }">
						<loan-payments-planned-payments-main-debt></loan-payments-planned-payments-main-debt>
					</v-col>

					<v-col cols="12" lg="8" class="pa-0" :class="{ 'pl-2': $vuetify.breakpoint.lgAndUp }">
						<loan-payments-planned-payments-interest></loan-payments-planned-payments-interest>
					</v-col>
				</v-row>
			</loan-card>
		</v-col>
	</v-row>
</template>

<script>
import FrpBtn from "Components/buttons/FrpBtn";
import LoanCard from "Components/layouts/LoanCard";
import LoanPaymentsPlannedPaymentsInterest from "Views/loan/upcomingPayments/plannedPayments/sections/LoanPaymentsPlannedPaymentsInterest";
import LoanPaymentsPlannedPaymentsMainDebt from "Views/loan/upcomingPayments/plannedPayments/sections/LoanPaymentsPlannedPaymentsMainDebt";

export default {
	components: {
		FrpBtn,
		LoanCard,
		LoanPaymentsPlannedPaymentsInterest,
		LoanPaymentsPlannedPaymentsMainDebt
	}
};
</script>

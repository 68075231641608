<template>
	<loan-content-layout>
		<v-row>
			<v-col class="d-flex flex-column pt-4" style="gap: 24px">
				<v-card elevation="0" class="loan-card px-2 py-6">
					<v-row class="align-center">
						<v-col class="py-0">
							<frp-custom-title-loader/>
						</v-col>
						<v-col class="py-0">
							<v-card-actions class="px-4">
								<div class="d-flex justify-md-end" style="width: 100%">
									<frp-btn-loader height="40" width="160" classes="mr-4"/>
									<frp-btn-loader height="40" width="160"/>
								</div>
							</v-card-actions>
						</v-col>
					</v-row>
				</v-card>
				<v-card elevation="0" class="loan-card px-6 pt-9 pb-6">
					<div class="pb-9 d-flex flex-column" style="row-gap: 16px">
						<v-row>
							<v-col cols="auto" class="d-flex flex-column py-0">
								<v-skeleton-loader type="text" max-width="100"/>
								
								<v-skeleton-loader type="image" height="40" width="209"/>
							</v-col>
							<v-col cols="auto" class="d-flex flex-column py-0">
								<v-skeleton-loader type="text" max-width="100"/>
								<v-skeleton-loader type="image" height="40" width="260"/>
							</v-col>
							<v-col cols="auto" class="d-flex flex-column py-0">
								<v-skeleton-loader type="text" max-width="100"/>
								<v-skeleton-loader type="image" height="40" width="260"/>
							</v-col>
							
							<v-spacer/>
							<v-col class="d-flex flex-column py-0">
								<v-card-actions class="px-0">
									<div class="d-flex align-center justify-md-end" style="width: 100%">
										<frp-btn-loader height="40" width="140" classes="mr-4"/>
										<frp-btn-loader height="40" width="140"/>
									</div>
								</v-card-actions>
							</v-col>
						</v-row>
						
						<v-divider class="my-6"></v-divider>
						
						<v-row>
							<v-col class="d-flex flex-column py-0" v-for="(_, index) in 7" :key="index">
								<v-skeleton-loader type="text" max-width="100"/>
							</v-col>
							<v-col class="d-flex flex-column"></v-col>
						</v-row>
						
						<v-divider class="my-3"></v-divider>
						
						<v-row class="pb-3" v-for="(_, index) in 6" :key="index">
							<v-col class="d-flex flex-column py-0" v-for="(_, insideIndex) in 7" :key="insideIndex">
								<v-skeleton-loader type="text" max-width="100"/>
							</v-col>
							<v-col class="d-flex flex-column py-0">
								<frp-btn-loader height="40" width="170"/>
							</v-col>
						</v-row>
					</div>
				</v-card>
			</v-col>
		</v-row>
	</loan-content-layout>
</template>

<script>
import LoanContentLayout from "Components/layouts/LoanContentLayout";
import FrpCustomTitleLoader from "Components/loaders/common/FrpCustomTitleLoader";
import FrpBtnLoader from "Components/loaders/form/FrpBtnLoader";

export default {
	components: {
		LoanContentLayout,
		FrpBtnLoader,
		FrpCustomTitleLoader,
	}
};
</script>

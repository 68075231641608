import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import { listingActionTypes, listingGetterTypes, listingMutationTypes } from "@/store/shared/listing/types";
import { sortingActionTypes, sortingMutationTypes } from "@/store/shared/sorting/types";
import { searchMixinTypes } from "@/store/shared/search/types";
import routeTypes from "@/store/shared/route/types";

export const namespace = "reports-final";

export const getterTypes = {
	...listingGetterTypes
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	...sortingActionTypes,
	...listingActionTypes,
	...searchMixinTypes.actionTypes,
	...routeTypes.actionTypes,
	initializeDefaultActions: "initializeDefaultActions",
	setListingItems: "setListingItems",
	uploadDocument: "uploadDocument",
	acceptReport: "acceptReport",
	declineReport: "declineReport"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	...sortingMutationTypes,
	...listingMutationTypes,
	...searchMixinTypes.mutationTypes,
	...routeTypes.mutationTypes,
	SET_IS_REPORTS_FILTER_LOADING: "SET_IS_REPORTS_FILTER_LOADING",
	RESET_REPORT_FINAL: "RESET_REPORT_FINAL",
	SET_REPORT_FINAL: "SET_REPORT_FINAL",
	RESET_SELECTED_DOCUMENT: "RESET_SELECTED_DOCUMENT",
	SET_SELECTED_DOCUMENT: "SET_SELECTED_DOCUMENT",
	SET_IS_SIGNED_DOCUMENT_UPLOADING: "SET_IS_SIGNED_DOCUMENT_UPLOADING",
	SET_IS_DOCUMENT_UPLOADING: "SET_IS_DOCUMENT_UPLOADING",
	SET_IS_REPORT_ACCEPTING: "SET_IS_REPORT_ACCEPTING",
	SET_IS_REPORT_DECLINING: "SET_IS_REPORT_DECLINING",
	RESET_REPORT_ADD_QUERY_INFO: "RESET_REPORT_ADD_QUERY_INFO",
	SET_REPORT_ADD_QUERY_INFO_TITLE: "SET_REPORT_ADD_QUERY_INFO_TITLE",
	SET_REPORT_ADD_QUERY_INFO_DESCRIPTION: "SET_REPORT_ADD_QUERY_INFO_DESCRIPTION",
	SET_REPORT_ADD_QUERY_INFO_EXECUTION_DATE: "SET_REPORT_ADD_QUERY_INFO_EXECUTION_DATE"

};

const reportsFinalTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default reportsFinalTypes;

export default class ApiUpcomingPaymentsInterestDetailsItem {
	minDate: string;
	maxDate: string;
	periodName: string;
	periodDays: number;
	accrualDays: number;
	accruedAmount: number;
	financingSourceId: number;

	constructor(
		minDate: string = "",
		maxDate: string = "",
		periodName: string = "",
		periodDays: number = 0,
		accrualDays: number = 0,
		accruedAmount: number = 0,
		financingSourceId: number = 0
	)
	{
		this.minDate = minDate;
		this.maxDate = maxDate;
		this.periodName = periodName;
		this.periodDays = periodDays;
		this.accrualDays = accrualDays;
		this.accruedAmount = accruedAmount;
		this.financingSourceId = financingSourceId;
	}
}

import ApiRefundSource from "@/api/loan/types/troubledBorrower/apiRefundSource";

export default class ApiRefundSourceProject {
	constructor(
		public projectId: string,
		public refundSourceInfos?: ApiRefundSource[],
	)
	{
	}
}

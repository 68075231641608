import { actionTypes, getterTypes, mutationTypes, namespace } from "@/store/loan/modules/agreementsAccruedInterest/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationPayload, MutationTree, Store } from "vuex";
import AbortService from "@/services/abortService";
import AgreementsAccruedInterestState from "@/store/loan/modules/agreementsAccruedInterest/types/agreementsAccruedInterestState";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import SearchModel from "@/store/shared/search/models/searchModel";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import ListingMixinBuilder from "@/store/shared/listing";
import PagingMixinBuilder from "@/store/shared/paging";
import SortingMixinBuilder from "@/store/shared/sorting";
import SearchMixinBuilder from "@/store/shared/search";
import { LoanInterestController } from "@/api/loan/loanInterest";
import AgreementsAccruedInterest from "@/store/loan/modules/agreementsAccruedInterest/types/agreementsAccruedInterest";
import { DictionariesController } from "@/api/loan/dictionaries";
import AgreementsAccruedInterestRouteQuery from "@/store/loan/modules/agreementsAccruedInterest/types/agreementsAccruedInterestRouteQuery";
import AgreementsAccruedInterestRouteQueryService
	from "@/store/loan/modules/agreementsAccruedInterest/services/agreementsAccruedInterestRouteQueryService";
import BatchService from "@/services/batchService";
import RouteMixinBuilder from "@/store/shared/route";
import SubscribersManager from "@/store/manager/subscribersManager";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import routeTypes from "@/store/shared/route/types";
import router from "@/router/loan";
import { RouteNames } from "@/router/loan/routes";
import FileMeta from "@/store/shared/storage/types/fileMeta";
import ApiFile from "@/api/types/storage/apiFile";
import { STORAGE_ACCRUED_INTEREST_NAMESPACE } from "@/constants/storage";
import storageMapper from "@/store/shared/storage/mapper";
import ApiFileMeta from "@/api/types/storage/apiFileMeta";
import certificateInfoMapper from "@/store/shared/certificates/mapper";
import ApiCertificate from "@/api/types/signature/apiCertificate";
import CertificateInfo from "@/types/certificateInfo";
import { LoanStorageController } from "@/api/loanStorage";
import AgreementsAccruedInterestReport from "@/store/loan/modules/agreementsAccruedInterest/types/agreementsAccruedInterestReport";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import AgreementsAccruedInterestMapperProfile from "@/store/loan/modules/agreementsAccruedInterest/mapper";
import { formatCurrency } from "@/utils/formatting";
import { formatDate } from "@/utils/dates";
import { dateFormat, isoDateFormat } from "@/utils/formats";
import { prepareUrl } from "@/utils/prepareUrlQuery";
import { DAYS_MAX, DAYS_MIN } from "@/constants/periodDays";

const abortService = new AbortService();
const loanStorageController = new LoanStorageController(abortService);
const loanInterestController = new LoanInterestController(abortService);
const dictionariesController = new DictionariesController(abortService);

const mapper = new AgreementsAccruedInterestMapperProfile();

const defaultRouteQuery = new AgreementsAccruedInterestRouteQuery("company", sortingOrderType.ascending, "", "", "", "", "", "");
const routeQueryService = new AgreementsAccruedInterestRouteQueryService(defaultRouteQuery);

const updateListingBatchService = new BatchService(({ interval: 100 }));

const routeMixin = (new RouteMixinBuilder<AgreementsAccruedInterestState>()).build();

const baseMixin = (new BaseMixinBuilder(abortService)).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new AgreementsAccruedInterestState(
			new ListingModel<AgreementsAccruedInterest>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String>({
				type: defaultRouteQuery.sort,
				order: defaultRouteQuery.sortDirection
			}),
			new SearchModel({
				query: ""
			}),
			routeMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

let subscribersManager: SubscribersManager<AgreementsAccruedInterestState>;

let unsubscribeCallback = () => {
};
let store: Store<{}>;

const initializeSubscribersManager = (value: Store<{}>) => {
	store = value;
	subscribersManager = new SubscribersManager<AgreementsAccruedInterestState>(store);
};

const subscribe = async (mutation: MutationPayload, rootState: any) => {
	let state = resolveNestedState<AgreementsAccruedInterestState>(rootState, namespace);
	switch (mutation.type) {
		case resolveMutation(routeTypes.namespace, routeTypes.mutationTypes.ROUTE_CHANGED):
			if((mutation.payload.from.name === mutation.payload.to.name) && !state.route.isPushing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.processRouteQuery));
			break;
		case resolveMutation(namespace, mutationTypes.SET_SORTING_TYPE):
		case resolveMutation(namespace, mutationTypes.SET_SORTING_ORDER):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_APPLICATION_NUMBER):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_COMPANY_NAME):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_AGREEMENT_STATUS_ID):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_DAYS_MIN):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_DAYS_MAX):
		{
			if(!state.route.isProcessing && !state.isOnlySelectedShown) {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));
			}
			
			break;
		}
		case resolveMutation(namespace, mutationTypes.SET_FILTER_PERIOD_ID):
		{
			if(!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));
			
			if(state.isFilterPeriodIdChanged) {
				updateListingBatchService.push(async () => {
					await subscribersManager.dispatch(resolveAction(namespace, actionTypes.updateListingItems));
				});
				subscribersManager.commit(resolveMutation(namespace, mutationTypes.SET_IS_FILTER_PERIOD_ID_CHANGED), false);
			}
		}
		
	}
};

const getters = <GetterTree<AgreementsAccruedInterestState, any>>{
	...listingMixin.getters,
	[getterTypes.filteredItems]: state => {
		let items = state.listing.items;
		
		const isFilterExist = state.filter.applicationNumber || state.filter.companyName || state.filter.agreementStatusId ||
			state.filter.daysMin || state.filter.daysMax;
		const companyNameFilter = state.filter.companyName.toLowerCase().trim();
		const applicationNumberFilter = state.filter.applicationNumber;
		const agreementStatus = state.agreementStatuses.find(x => x.id == state.filter.agreementStatusId);
		const daysMin = +state.filter.daysMin;
		const daysMax = +state.filter.daysMax;
		
		if(state.isOnlySelectedShown) {
			items = items.filter(x => x.isSelected);
			return items;
		}
		
		if(isFilterExist) {
			if(companyNameFilter) {
				items = items.filter(x => x.company.toLowerCase().includes(companyNameFilter));
			}
			
			if(applicationNumberFilter) {
				items = items.filter(x => String(x.applicationNumber).includes(applicationNumberFilter));
			}
			
			if(agreementStatus?.name) {
				//@ts-ignore
				items = items.filter(x => x.agreementStatusName.includes(agreementStatus.name));
			}
			
			items = items.filter(x => x.daysForPeriod >= daysMin && x.daysForPeriod <= daysMax);
		}
		
		return items;
	},
	[getterTypes.formattedFilteredItems]: (state, getters) => {
		return getters.filteredItems.map((x: AgreementsAccruedInterest) => {
			return {
				...x,
				accruedAmount: formatCurrency(x.accruedAmount) || "--",
				accountedAmount: x.accountedData.length ? x.accountedData.map(y => formatCurrency(y.accountedAmount) || "--") : [],
				accountedDate: x.accountedData.length ? x.accountedData.map(y => formatDate(y.accountedDate, dateFormat) || "--") : [],
				link: (() => {
					const period = state.periods.find(x => x.id === state.filter.periodId);
					
					if(!period)
						return "";
					
					return prepareUrl(`/loan-refundment/accrued-interest/project/${x.projectId}`, {
						filterAccrualStartDate: formatDate(period.startDate, isoDateFormat),
						filterAccrualEndDate: formatDate(period.endDate, isoDateFormat)
					});
				})()
			};
		});
	},
	[getterTypes.isSelectedItemsEmpty]: state => {
		return !state.listing.items.some(x => x.isSelected);
	},
	[getterTypes.selectedItemsLength]: state => {
		return state.listing.items.filter(x => x.isSelected).length;
	},
	[getterTypes.nearestPeriod]: state => {
		return state.periods.find(x => x.isNearest);
	}
};

const actions = <ActionTree<AgreementsAccruedInterestState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...listingMixin.actions,
	...pagingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	...routeMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, state, getters }) {
		await dispatch(actionTypes.initializeBase);
		
		await dispatch(actionTypes.processRouteQuery);
		await dispatch(actionTypes.reconstituteRoute);
		
		await Promise.all([
			dispatch(actionTypes.updateListingItems),
			dispatch(actionTypes.fetchDictionaries)
		]);
		
		if(!router.currentRoute.query.filterPeriodId || !state.periods.some(x => x.id === router.currentRoute.query.filterPeriodId))
			commit(mutationTypes.SET_FILTER_PERIOD_ID, getters.nearestPeriod.id);
		
		if(!router.currentRoute.query.filterDaysMin) {
			commit(mutationTypes.SET_FILTER_DAYS_MIN, DAYS_MIN);
		}
		
		if(!router.currentRoute.query.filterDaysMax) {
			commit(mutationTypes.SET_FILTER_DAYS_MAX, DAYS_MAX);
		}
		
		await dispatch(actionTypes.pushRoute);
		
		unsubscribeCallback = subscribersManager.subscribe(subscribe);
		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.updateListingItems]({ dispatch, commit, state }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);
		
		try {
			const { items } = await loanInterestController.getAgreementsAccruedInterest(mapper.mapToDefaultApiGetAgreementsAccruedInterestParameters(
				state));
			
			commit(mutationTypes.SET_LISTING_ITEMS, items.map(x => mapper.mapToAgreementsAccruedInterest(x)));
			
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			console.error(error);
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.updateSelectedItems]({ dispatch, commit, state }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);
		
		try {
			const { items } = await loanInterestController.getAgreementsAccruedInterest(mapper.mapToApiGetAgreementsAccruedInterestParametersForUpdateSelectedItems(
				state));
			
			const selectedItems = items.map(x => mapper.mapToAgreementsAccruedInterest(x));
			
			const listingItems = state.listing.items.map(x => {
				const targetItem = selectedItems.find(item => item.projectId === x.projectId);
				return targetItem || x;
			});
			
			commit(mutationTypes.SET_LISTING_ITEMS, listingItems);
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			console.error(error);
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.fetchDictionaries]({ commit }) {
		try {
			const [periods, agreementStatuses] = await Promise.all([
				dictionariesController.getPeriods(),
				dictionariesController.getAgreementStatuses()
			]);
			
			commit(mutationTypes.SET_PERIODS, periods.map(x => mapper.mapToPeriod(x)));
			commit(mutationTypes.SET_AGREEMENT_STATUSES, agreementStatuses);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		}
	},
	async [actionTypes.processRouteQuery]({ rootState, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, true);
		
		let routeQuery = await routeQueryService.resolveRouteQuery(rootState.route.query);
		
		commit(mutationTypes.SET_FILTER_PERIOD_ID, routeQuery.filterPeriodId);
		commit(mutationTypes.SET_FILTER_APPLICATION_NUMBER, routeQuery.filterApplicationNumber);
		commit(mutationTypes.SET_FILTER_COMPANY_NAME, routeQuery.filterCompanyName);
		commit(mutationTypes.SET_FILTER_AGREEMENT_STATUS_ID, routeQuery.filterAgreementStatusId);
		commit(mutationTypes.SET_FILTER_DAYS_MIN, routeQuery.filterDaysMin);
		commit(mutationTypes.SET_FILTER_DAYS_MAX, routeQuery.filterDaysMax);
		
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, false);
	},
	async [actionTypes.pushRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);
		
		await router.push({
			name: RouteNames.AGREEMENTS_ACCRUED_INTEREST,
			query: routeQueryService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.reconstituteRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);
		
		await router.replace({
			name: RouteNames.AGREEMENTS_ACCRUED_INTEREST,
			query: routeQueryService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.destroy]({ dispatch }) {
		unsubscribeCallback();
		await dispatch(actionTypes.destroyBase);
	},
	async [actionTypes.handleAccruedInterestFileUpload]({ state, commit, dispatch }, file) {
		if(!file) {
			commit(mutationTypes.RESET_ACCRUED_INTEREST_FILE_META);
			return;
		}
		
		try {
			commit(mutationTypes.SET_ACCRUED_INTEREST_FILE_META_IS_LOADING, true);
			
			const { name } = file;
			let meta = await loanStorageController.createTemperFile(new ApiFile(file, name, STORAGE_ACCRUED_INTEREST_NAMESPACE, "pdf", ""));
			
			commit(mutationTypes.SET_ACCRUED_INTEREST_FILE_META, storageMapper.map(meta, ApiFileMeta, FileMeta));
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_ACCRUED_INTEREST_FILE_META_IS_LOADING, false);
		}
	},
	async [actionTypes.handleAccruedInterestFileSigned]({ commit, dispatch, state }, { signature, file }) {
		try {
			commit(mutationTypes.SET_ACCRUED_INTEREST_FILE_META_IS_LOADING, true);
			
			const { name } = file;
			let meta = await loanStorageController.createTemperFile(new ApiFile(file,
				name,
				STORAGE_ACCRUED_INTEREST_NAMESPACE,
				"pdf",
				signature));
			
			commit(mutationTypes.SET_ACCRUED_INTEREST_FILE_META, storageMapper.map(meta, ApiFileMeta, FileMeta));
			
			commit(mutationTypes.SET_AGREEMENTS_ACCRUED_INTEREST_REPORT_SIGNATURE_ID, state.accruedInterestFileMeta.id);
			
			commit(mutationTypes.SET_IS_SIGNED, true);
			await dispatch(actionTypes.fetchCertificateInfo);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_ACCRUED_INTEREST_FILE_META_IS_LOADING, false);
		}
	},
	async [actionTypes.sendAgreementsAccruedInterestReport]({ commit, state }) {
		try {
			commit(mutationTypes.IS_AGREEMENTS_ACCRUED_INTEREST_REPORT_LOADING, true);
			
			await loanInterestController.handleApproveAccounting(mapper.mapToApiAgreementsAccruedInterestReport(state.agreementsAccruedInterestReport));
			
			commit(mutationTypes.SET_IS_SIGNED, false);
			
			alertService.addInfo(AlertKeys.SUCCESS_SENT_INFO);
		} catch (error) {
			commit(mutationTypes.SET_IS_REPORT_ERROR, true);
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.IS_AGREEMENTS_ACCRUED_INTEREST_REPORT_LOADING, false);
		}
	},
	async [actionTypes.fetchCertificateInfo]({ commit, state }) {
		try {
			const [certificate] = await loanStorageController.getSignerCertificate(state.accruedInterestFileMeta.signature);
			
			commit(mutationTypes.SET_CERTIFICATE_INFO, certificateInfoMapper.map(certificate, ApiCertificate, CertificateInfo));
			
		} catch (error) {
			commit(mutationTypes.SET_IS_SIGNED, false);
			commit(mutationTypes.RESET_ACCRUED_INTEREST_FILE_META);
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		}
		
	}
};

const mutations = <MutationTree<AgreementsAccruedInterestState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...listingMixin.mutations,
	...pagingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	...routeMixin.mutations,
	[mutationTypes.SET_IS_LOADING](state, value) {
		state.isLoading = value;
	},
	[mutationTypes.SET_PERIODS](state, value) {
		state.periods = value;
	},
	[mutationTypes.SET_AGREEMENT_STATUSES](state, value) {
		state.agreementStatuses = value;
	},
	[mutationTypes.SET_FILTER_PERIOD_ID](state, value) {
		state.filter.periodId = value;
	},
	[mutationTypes.SET_IS_FILTER_PERIOD_ID_CHANGED](state, value) {
		state.isFilterPeriodIdChanged = value;
	},
	[mutationTypes.SET_FILTER_APPLICATION_NUMBER](state, value) {
		state.filter.applicationNumber = value;
	},
	[mutationTypes.SET_FILTER_COMPANY_NAME](state, value) {
		state.filter.companyName = value;
	},
	[mutationTypes.SET_FILTER_AGREEMENT_STATUS_ID](state, value) {
		state.filter.agreementStatusId = value;
	},
	[mutationTypes.SET_FILTER_DAYS_MIN](state, value) {
		state.filter.daysMin = value;
	},
	[mutationTypes.SET_FILTER_DAYS_MAX](state, value) {
		state.filter.daysMax = value;
	},
	[mutationTypes.SET_LISTING_ITEM_IS_SELECTED](state, { id, value }) {
		state.listing.items[state.listing.items.findIndex(x => x.projectId === id)].isSelected = value;
	},
	[mutationTypes.SET_IS_ONLY_SELECTED_SHOWN](state, value) {
		state.isOnlySelectedShown = value;
	},
	[mutationTypes.SET_IS_UPDATE_SELECTED_ITEMS_REQUIRED](state, value) {
		state.isUpdateSelectedItemsRequired = value;
	},
	[mutationTypes.RESET_AGREEMENTS_ACCRUED_INTEREST_REPORT](state) {
		state.agreementsAccruedInterestReport = new AgreementsAccruedInterestReport();
	},
	[mutationTypes.IS_AGREEMENTS_ACCRUED_INTEREST_REPORT_LOADING](state, value) {
		state.isAgreementsAccruedInterestReportLoading = value;
	},
	[mutationTypes.SET_AGREEMENTS_ACCRUED_INTEREST_REPORT_REPORT_NUMBER](state, value) {
		state.agreementsAccruedInterestReport.reportNumber = value;
	},
	[mutationTypes.RESET_AGREEMENTS_ACCRUED_INTEREST_REPORT_COMMENT](state) {
		state.agreementsAccruedInterestReport.comment = "";
	},
	[mutationTypes.SET_AGREEMENTS_ACCRUED_INTEREST_REPORT_COMMENT](state, value) {
		state.agreementsAccruedInterestReport.comment = value;
	},
	[mutationTypes.RESET_AGREEMENTS_ACCRUED_INTEREST_REPORT_SIGNATURE_ID](state) {
		state.agreementsAccruedInterestReport.signatureId = "";
	},
	[mutationTypes.SET_AGREEMENTS_ACCRUED_INTEREST_REPORT_SIGNATURE_ID](state, value) {
		state.agreementsAccruedInterestReport.signatureId = value;
	},
	[mutationTypes.SET_IS_REPORT_ERROR](state, value) {
		state.isReportError = value;
	},
	[mutationTypes.RESET_ACCRUED_INTEREST_FILE_META](state) {
		state.accruedInterestFileMeta = new FileMeta();
	},
	[mutationTypes.SET_ACCRUED_INTEREST_FILE_META](state, value) {
		state.accruedInterestFileMeta = value;
	},
	[mutationTypes.SET_ACCRUED_INTEREST_FILE_META_IS_LOADING](state, value) {
		state.accruedInterestFileMeta.isLoading = value;
	},
	[mutationTypes.RESET_CERTIFICATE_INFO](state) {
		state.certificateInfo = new CertificateInfo();
	},
	[mutationTypes.SET_CERTIFICATE_INFO](state, value) {
		state.certificateInfo = value;
	},
	[mutationTypes.SET_IS_SIGNED](state, value) {
		state.isSigned = value;
	}
};

export {
	namespace, state, getters, actions, mutations, initializeSubscribersManager
};

const agreementsAccruedInterestModule = {
	namespace, state, getters, actions, mutations, initializeSubscribersManager, namespaced: true
};

export default agreementsAccruedInterestModule;

import { difference } from "@/utils/difference";
import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import AssignmentCompensationRouteQuery from "@/store/loan/modules/assignmentCompensation/types/assignmentCompensationRouteQuery";
import AssignmentCompensationState from "@/store/loan/modules/assignmentCompensation/types/assignmentCompensationState";

export default class AssignmentCompensationRouteQueryService {
	defaultRouteQuery: AssignmentCompensationRouteQuery;
	
	constructor(defaultRouteQuery: AssignmentCompensationRouteQuery) {
		this.defaultRouteQuery = defaultRouteQuery;
	}
	
	resolveRouteQueryDictionary(state: AssignmentCompensationState) {
		let query = new AssignmentCompensationRouteQuery(
			state.paging.page,
			state.filter.assignmentPeriodStartDate,
			state.filter.assignmentPeriodEndDate,
			state.filter.assignmentAgreementId,
			state.filter.assigneeId
		);
		
		return difference(this.defaultRouteQuery, query);
	}
	
	async resolveRouteQuery(query: Dictionary<any>) {
		try {
			let result = plainToInstance(AssignmentCompensationRouteQuery, {
				...this.defaultRouteQuery,
				...query
			}, { enableImplicitConversion: true });
			
			await validateOrReject(result);
			
			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}
}

export default class ApiEventType {
	id: string;
	statusTypeId: string;
	name: string;
	isFinalGoal: boolean;

	constructor(
		id: string = "",
		statusTypeId: string = "",
		name: string = "",
		isFinalGoal: boolean = false
	)
	{
		this.id = id;
		this.statusTypeId = statusTypeId;
		this.name = name;
		this.isFinalGoal = isFinalGoal;
	}
}

export default class ApiProject {
	id: number;
	name: string;
	companyId: number;

	constructor(
		id: number = 0,
		name: string = "",
		companyId: number = 0
	)
	{
		this.id = id;
		this.name = name;
		this.companyId = companyId;
	}

}

import ApiQuarter from "@/api/loan/types/dictionaries/apiQuarter";
import ApiFinanceSource from "@/api/loan/types/dictionaries/apiFinanceSource";

export default class ApiOverdue {
	id: string;
	number: number;
	startDate: string;
	source: ApiFinanceSource;
	quarter: ApiQuarter;
	amount: number;
	comment: string;
	isProblem: boolean;

	constructor(
		id: string = "",
		number: number = 0,
		startDate: string = "",
		source: ApiFinanceSource = new ApiFinanceSource(),
		quarter: ApiQuarter = new ApiQuarter(),
		amount: number = 0,
		comment: string = "",
		isProblem: boolean = false
	)
	{
		this.id = id;
		this.number = number;
		this.startDate = startDate;
		this.source = source;
		this.quarter = quarter;
		this.amount = amount;
		this.comment = comment;
		this.isProblem = isProblem;
	}
}

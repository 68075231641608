<template>
	<div class="d-flex flex-column" v-if="isRecordVisible">
		<v-card elevation="0" class="pt-4 px-4 pb-1 mt-6 loan-card">
			<v-row>
				<v-col cols="4" xl="4" md="5" sm="6">
					<div class="d-flex flex-column justify-space-between px-2 pb-3 fill-height">
						<div>
							<div class="d-flex justify-space-between">
								<div class="d-flex flex-column text-no-wrap">
									<span class="pb-2 text-h6">{{ $t("details.titles.treasuryStatementRecord") }}</span>
									<span class="text-body-2 primary--text">
										{{
											$t("details.titles.treasuryStatementRecordDocument",
												{ number: record.numberDoc, date: formatDate(record.dateDoc, dateFormat) })
										}}
									</span>
								</div>
								<span class="grey--text text-caption mt-1">{{ activeTreasuryStatementOperationTypeValue }}</span>
							</div>
							<v-row class="mt-0">
								<v-col cols="4">
									<span class="pb-2 text-body-2 blue--text text--darken-4">{{ $t("details.titles.borrower") }}</span>
								</v-col>
								<v-col>
								<span class="text-body-1 font-weight-bold blue--text text--darken-4">
									{{ `${record.payerName} (${record.payerINN})` }}
								</span>
								</v-col>
							</v-row>
							<v-row class="mt-0">
								<v-col cols="4">
									<span class="pb-2 text-body-2 blue--text text--darken-4">{{ $t("details.titles.kbk") }}</span>
								</v-col>
								<v-col>
									<span class="text-body-1 font-weight-bold blue--text text--darken-4">{{ +record.kbk }}</span>
								</v-col>
							</v-row>
							<v-row class="mt-0">
								<v-col cols="4">
									<span class="pb-2 text-body-2 blue--text text--darken-4">{{ $t("details.titles.paymentSum") }}</span>
								</v-col>
								<v-col>
								<span class="text-body-1 font-weight-bold blue--text text--darken-4">
									{{ formatCurrency(record.payment) }}</span>
								</v-col>
							</v-row>
							<v-row class="mt-0">
								<v-col cols="4">
									<span class="pb-2 text-body-2 blue--text text--darken-4">
										{{ $t("details.titles.paymentPurpose") }}
									</span>
								</v-col>
								<v-col>
									<span class="text-body-1 font-weight-bold blue--text text--darken-4">{{ record.paymentPurpose }}</span>
								</v-col>
							</v-row>
						</div>
						<div class="mt-4 d-flex" v-if="can(Permissions.LOAN_UPDATE)">
							<v-spacer></v-spacer>
							<frp-btn v-if="record.isFinal"
									 color="blue"
									 dark
									 elevation="0"
									 @click="updateRecordStatus({ record, status: false })">
								{{ $t("buttons.setTreasuryStatementRecordIsNotFinal") }}
							</frp-btn>
							<frp-btn v-else
									 color="blue"
									 dark
									 :disabled="record.payment !== +record.payments.reduce((sum, x) => sum += x.amount, 0).toFixed(2) || record.payments.some(x => !x.isFinal)"
									 elevation="0"
									 @click="updateRecordStatus({ record, status: true })">
								{{ $t("buttons.setTreasuryStatementRecordIsFinal") }}
							</frp-btn>
						</div>
					</div>
				</v-col>

				<v-col>
					<div class="d-flex flex-column justify-space-between fill-height">
						<div>
							<div class="d-flex align-center pl-1 mb-6">
								<span class="text-body-1 font-weight-bold blue--text text--darken-4 mr-1">
									{{ $t("subheaders.project") }}
								</span>
								<template v-if="isProjectEditable">
									<frp-autocomplete v-model="editableProjectRecords.find(x => x.id === record.id).projectId"
													  :items="borrowers"
													  :loading="savingProjectRecords.some(x => x.id === record.id)"
													  :min-query-length="MIN_BORROWERS_QUERY_LENGTH"
													  :class="{ 'field-border-secondary': !editableProjectRecords.find(x => x.id === record.id).projectId }"
													  item-text="name"
													  item-value="projectId"
													  color="blue"
													  hide-details
													  required
													  :placeholder="$t('fields.borrower.placeholder')">
									</frp-autocomplete>
									<frp-btn without-padding
											 height="28"
											 class="ml-4 mr-n2"
											 small
											 icon
											 color="blue"
											 dark
											 :disabled="!editableProjectRecords.find(x => x.id === record.id).projectId"
											 :loading="savingProjectRecords.some(x => x.id === record.id)"
											 @click="updateRecordProject({ record, projectId: editableProjectRecords.find(x => x.id === record.id).projectId })">
										<frp-icon src="ico_save" :color="colors.blue.base"></frp-icon>
									</frp-btn>
									<frp-btn without-padding
											 small
											 v-if="record.projectId"
											 icon
											 color="blue"
											 dark
											 height="28"
											 @click="removedEditableProjectRecord(record)">
										<frp-icon src="ico_close" :color="colors.primary.darken1"></frp-icon>
									</frp-btn>
								</template>

								<template v-else>
									<div class="d-flex align-center">
										<div>
											<span class="text-body-1 font-weight-bold blue--text text--darken-4">
												{{ `${borrowers.find(x => x.projectId === record.projectId)?.name}` }}
											</span>
											<frp-btn without-padding
													 height="24"
													 class="ml-4"
													 small
													 @click="addEditableProjectRecord(record)"
													 color="primary"
													 v-if="can(Permissions.LOAN_UPDATE)"
													 icon>
												<frp-icon src="ico_edit" :color="colors.primary.darken1"></frp-icon>
											</frp-btn>
										</div>
										
										<div v-if="record.projectStatus" class="ml-8">
											<span class="text-subtitle-2 primary--text">{{ $t('content.status') }}: {{ record.projectStatus }}</span>
										</div>
									</div>
								</template>
							</div>
							<v-data-table :headers="headers"
										  :loading-text="$t('tables.loading')"
										  hide-default-footer
										  item-key="id"
										  :items="record.payments"
										  :items-per-page="-1"
										  :mobile-breakpoint="mobileBreakpoint"
										  :options="options"
										  :item-class="() => 'text-body-2 cy-table-row'"
										  class="loan-table clickable-rows d-flex flex-column">
								<template #item.paymentExpense="{ item }">
									<span v-if="!checkIsItemEditable(item) || (!item.isFinal && item.paymentExpenseId)">
										{{ paymentExpenses?.find(x => x.id === item.paymentExpenseId)?.name || "--" }}
									</span>
									<frp-autocomplete dense
													  required
													  :class="{ 'field-border-secondary': !item.isFinal }"
													  v-model="editableItems.find(x => x.id === item.id).paymentExpenseId"
													  :items="paymentExpenses.filter(x => x.isFact)"
													  :rules="validation.paymentExpenseId"
													  item-text="name"
													  item-value="id"
													  color="blue"
													  hide-details
													  v-else
													  :placeholder="$t('fields.paymentExpense.label')">
									</frp-autocomplete>
								</template>

								<template #item.quarter="{ item }">
									<span v-if="!checkIsItemEditable(item) || (!item.isFinal && item.quarterId)">
										{{ quarters?.find(x => x.id === item.quarterId)?.title || "--" }}
									</span>
									<frp-autocomplete dense
													  required
													  :class="{ 'field-border-secondary': !item.isFinal }"
													  v-model="editableItems.find(x => x.id === item.id).quarterId"
													  :items="quarters"
													  :loading="isRecordDictionariesLoading"
													  :rules="validation.quarterId"
													  item-text="title"
													  item-value="id"
													  color="blue"
													  hide-details
													  v-else
													  auto
													  :placeholder="$t('fields.quarter.label')">
									</frp-autocomplete>
								</template>

								<template #item.amount="{ item }">
									<span v-if="!checkIsItemEditable(item) || (!item.isFinal && item.amount)">
										{{ formatCurrency(item.amount) }}
									</span>
									<frp-text-field dense
													required
													:class="{ 'field-border-secondary': !item.isFinal }"
													type="number"
													v-model.number="editableItems.find(x => x.id === item.id).amount"
													:rules="validation.amount"
													hide-details
													v-else
													:placeholder="$t('fields.amount.labelRUB')">
									</frp-text-field>
								</template>

								<template #item.source="{ item }">
									<span v-if="!checkIsItemEditable(item) || (!item.isFinal && item.financingSourceId)">
										{{ allFinanceSources?.find(x => x.id === item.financingSourceId)?.name || "--" }}
									</span>
									<frp-autocomplete dense
													  required
													  :class="{ 'field-border-secondary': !item.isFinal }"
													  v-model.number="editableItems.find(x => x.id === item.id).financingSourceId"
													  :items="getFilteredFinanceSources(editableItems.find(x => x.id === item.id).paymentExpenseId)"
													  :loading="isRecordDictionariesLoading"
													  :rules="validation.financingSourceId"
													  item-value="id"
													  item-text="name"
													  color="blue"
													  hide-details
													  v-else
													  :placeholder="$t('fields.financeSource.label')">
									</frp-autocomplete>
								</template>

								<template #item.action1="{ item }">
									<frp-btn small
											 color="blue"
											 dark
											 v-if="checkIsItemEditable(item)"
											 :disabled="!checkIsItemValid(editableItems.find(x => x.id === item.id))"
											 :loading="savingItems.some(x => x.id === item.id)"
											 @click="handleUpdateItem(editableItems.find(x => x.id === item.id))"
											 icon>
										<frp-icon src="ico_save" :color="colors.blue.base"></frp-icon>
									</frp-btn>

									<frp-btn small
											 color="primary"
											 icon
											 @click="addEditableItem(item)"
											 v-else-if="can(Permissions.LOAN_UPDATE)">
										<frp-icon src="ico_edit" :color="colors.primary.darken1"></frp-icon>
									</frp-btn>
								</template>

								<template #item.action2="{ item }">
									<frp-btn small
											 color="primary"
											 icon
											 v-if="checkIsItemEditable(item) && item.isFinal"
											 @click="handleCancelItemEditing(item)">
										<frp-icon src="ico_close" :color="colors.primary.darken1"></frp-icon>
									</frp-btn>

									<frp-btn small
											 color="primary"
											 icon
											 v-else-if="can(Permissions.LOAN_DELETE)"
											 @click="handleOpenDeleteItemDialog(item)">
										<frp-icon src="ico_delete" :color="colors.primary.darken1"></frp-icon>
									</frp-btn>
								</template>
							</v-data-table>
						</div>

						<div class="d-flex justify-end mx-n4 py-2">
							<frp-btn outlined
									 dense
									 elevation="0"
									 class="text-none pl-1 pr-2 mr-4"
									 :color="colors.blue.base"
									 dark
									 :disabled="!record.projectId"
									 v-if="can(Permissions.LOAN_CREATE)"
									 @click="handleOpenCreateItemDialog">
								<v-icon color="blue">mdi-plus</v-icon>
								{{ $t("buttons.addNote") }}
							</frp-btn>
						</div>
					</div>
				</v-col>
			</v-row>
		</v-card>

		<frp-dialog v-model="isNewItemDialogOpened" :title="$t('dialogs.addPayment.title')" persistent>
			<template #content="{ onIntersect }">
				<v-form :ref="refs.form" v-intersect="onIntersect" v-model="formValid">
					<frp-autocomplete :label="$t('fields.paymentExpense.label')"
									  v-model="newItemPaymentExpense"
									  :rules="validation.paymentExpenses"
									  :items="paymentExpenses"
									  item-text="name"
									  item-value="id"
									  color="blue"
									  required
									  :placeholder="$t('fields.paymentExpense.placeholder')">
					</frp-autocomplete>
					<frp-autocomplete :label="$t('fields.quarter.label')"
									  v-model="newItemQuarter"
									  :rules="validation.quarter"
									  :items="quarters"
									  :loading="isRecordDictionariesLoading"
									  item-text="title"
									  item-value="id"
									  color="blue"
									  required
									  auto
									  :placeholder="$t('fields.quarter.placeholder')">
					</frp-autocomplete>
					<frp-autocomplete :label="$t('fields.financeSource.label')"
									  v-model="newItemSource"
									  :rules="validation.financingSourceId"
									  :items="getFilteredFinanceSources(newItem.paymentExpenseId)"
									  :loading="isRecordDictionariesLoading"
									  item-text="name"
									  item-value="id"
									  color="blue"
									  required
									  :placeholder="$t('fields.financeSource.placeholder')">
					</frp-autocomplete>
					<frp-text-field :label="$t('fields.amount.labelRUB')"
									v-model.number="newItemAmount"
									:rules="validation.amount"
									type="number"
									required
									:placeholder="$t('fields.amount.placeholder')">
					</frp-text-field>
				</v-form>
			</template>

			<template #footer>
				<frp-btn outlined
						 @click="handleCancelCreateItem"
						 color="primary">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				<frp-btn elevation="0"
						 color="blue"
						 dark
						 :loading="isFormSaving"
						 :disabled="!stateContainsUnsavedChanges || !formValid"
						 @click="handleCreateItem">
					{{ $t("buttons.addPayment") }}
				</frp-btn>
			</template>
		</frp-dialog>

		<frp-dialog v-model="isDeleteItemDialogOpened" :title="$t('dialogs.deletePayment.title')">
			<template #content="{ onIntersect }">
				<span v-intersect="onIntersect" class="primary--text text-body-2">
					{{ $t("dialogs.deletePayment.warning") }}
				</span>
			</template>

			<template #footer>
				<frp-btn outlined
						 color="primary"
						 @click="handleCancelItemDeleting">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				<frp-btn elevation="0"
						 color="blue"
						 dark
						 @click="handleDeleteItem">
					{{ $t("buttons.remove") }}
				</frp-btn>
			</template>
		</frp-dialog>
	</div>
</template>

<script>
import {
	TreasuryStatementPaymentExpenseCategoryTypeEnum
} from "@/store/loan/modules/treasuryStatement/types/TreasuryStatementPaymentExpenseCategoryTypeEnum";
import { DictionariesController } from "Api/loan/dictionaries";
import FrpDialog from "Components/dialogs/FrpDialog";
import FrpAutocomplete from "Components/fields/FrpAutocomplete";
import FrpTextField from "Components/fields/FrpTextField";
import FrpIcon from "Components/icon/FrpIcon";
import FrpBtn from "Components/buttons/FrpBtn";
import authorizationMixin from "Mixins/authorizationMixin";
import colorsMixin from "Mixins/colorsMixin";
import formMixin from "Mixins/formMixin";
import { listMixin } from "Mixins/listMixin";
import AbortService from "Services/abortService";
import { MIN_BORROWERS_QUERY_LENGTH } from "Store/loan/modules/cashFlow/constants";
import { namespace } from "Store/loan/modules/treasuryStatement";
import { TreasuryStatementOperationType } from "Store/loan/modules/treasuryStatement/types/treasuryStatementOperationType";
import { TreasuryStatementPaymentsType } from "Store/loan/modules/treasuryStatement/types/treasuryStatementPaymentsType";
import storeManager from "Store/manager";
import AlertHelper from "Store/modules/alerts/helpers/alertHelper";
import { formatDate } from "Utils/dates";
import { dateFormat } from "Utils/formats";
import { formatCurrency } from "Utils/formatting";
import { requiredRule } from "Utils/validation";
import { createNamespacedHelpers } from "vuex";
import { actionTypes, mutationTypes, getterTypes } from "Store/loan/modules/treasuryStatement/types";

const treasuryStatementHelpers = createNamespacedHelpers(storeManager.loan.treasuryStatement.namespace);

const abortService = new AbortService();
const dictionariesController = new DictionariesController(abortService);

export default {
	mixins: [listMixin, formMixin, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			formatCurrency,
			dateFormat,
			formatDate,
			MIN_BORROWERS_QUERY_LENGTH,
			isNewItemDialogOpened: false,
			isDeleteItemDialogOpened: false,
			itemToDelete: null,
			validation: {
				paymentExpense: [requiredRule()],
				source: [requiredRule()],
				quarter: [requiredRule()],
				amount: [requiredRule()]
			},
			headers: [
				{
					text: this.$t("tables.paymentExpense"),
					value: "paymentExpense",
					class: "text-caption",
					width: "24%",
					sortable: false
				},
				{
					text: this.$t("tables.quarter"),
					value: "quarter",
					class: "text-caption",
					width: "24%",
					sortable: false
				},
				{
					text: this.$t("tables.amountRUB"),
					value: "amount",
					class: "text-caption",
					width: "24%",
					sortable: false
				},
				{
					text: this.$t("tables.financeSource"),
					value: "source",
					class: "text-caption",
					width: "24%",
					sortable: false
				},
				{
					text: "",
					value: "action1",
					class: "text-caption",
					width: "1%",
					sortable: false
				},
				{
					text: "",
					value: "action2",
					class: "text-caption",
					width: "1%",
					sortable: false
				}
			],
			quarters: null,
			financeSources: null,
			isRecordDictionariesLoading: false
		};
	},
	props: {
		record: {
			type: Object,
			required: true
		}
	},
	computed: {
		...treasuryStatementHelpers.mapState({
			paymentsFilterType: state => state.paymentsFilterType,
			paymentExpenses: state => state.paymentExpenses,
			allFinanceSources: state => state.allFinanceSources,
			treasuryStatementOperationTypes: state => state.treasuryStatementOperationTypes,
			editableItems: state => state.editableItems,
			savingItems: state => state.savingItems,
			newItem: state => state.newItem,
			isItemDeleting: state => state.isItemDeleting,
			editableProjectRecords: state => state.editableProjectRecords,
			savingProjectRecords: state => state.savingProjectRecords
		}),
		...treasuryStatementHelpers.mapGetters({
			borrowers: getterTypes.borrowers
		}),
		isRecordVisible() {
			return this.checkIsRecordVisible(this.record);
		},
		activeTreasuryStatementOperationTypeKey() {
			return this.treasuryStatementOperationTypes.find(x => x.key === this.record.typeDoc)?.key;
		},
		activeTreasuryStatementOperationTypeValue() {
			const type = this.treasuryStatementOperationTypes.find(x => x.key === this.record.typeDoc);
			if(type.key !== TreasuryStatementOperationType.TRANSFER)
				return type.value;
		},
		loanIssuancePaymentExpenseId() {
			return this.paymentExpenses.find(x => x.category === TreasuryStatementPaymentExpenseCategoryTypeEnum.LOAN_ISSUANCE)?.id || "";
		},
		newItemQuarter: {
			get() {
				return this.newItem.quarterId;
			},
			set(value) {
				this.setNewItemQuarterId(value);
			}
		},
		newItemSource: {
			get() {
				return this.newItem.financingSourceId;
			},
			set(value) {
				this.setNewItemFinancingSourceId(value);
			}
		},
		newItemPaymentExpense: {
			get() {
				return this.newItem.paymentExpenseId;
			},
			set(value) {
				this.setNewItemPaymentExpenseId(value);
			}
		},
		newItemAmount: {
			get() {
				return this.newItem.amount;
			},
			set(value) {
				this.setNewItemAmount(value);
			}
		},
		isProjectEditable() {
			return this.editableProjectRecords.some(x => x.id === this.record.id);
		}
	},
	methods: {
		...treasuryStatementHelpers.mapActions({
			createItem: actionTypes.createItem,
			updateItem: actionTypes.updateItem,
			deleteItem: actionTypes.deleteItem,
			updateRecordStatus: actionTypes.updateRecordStatus,
			updateRecordProject: actionTypes.updateRecordProject
		}),
		...treasuryStatementHelpers.mapMutations({
			addEditableItem: mutationTypes.ADD_EDITABLE_ITEM,
			removeEditableItem: mutationTypes.REMOVE_EDITABLE_ITEM,
			resetNewItem: mutationTypes.RESET_NEW_ITEM,
			setNewItemRecordId: mutationTypes.SET_NEW_ITEM_RECORD_ID,
			setNewItemFinancingSourceId: mutationTypes.SET_NEW_ITEM_FINANCING_SOURCE_ID,
			setNewItemQuarterId: mutationTypes.SET_NEW_ITEM_QUARTER_ID,
			setNewItemPaymentExpenseId: mutationTypes.SET_NEW_ITEM_PAYMENT_EXPENSE_ID,
			setNewItemAmount: mutationTypes.SET_NEW_ITEM_AMOUNT,
			addEditableProjectRecord: mutationTypes.ADD_EDITABLE_PROJECT_RECORD,
			removedEditableProjectRecord: mutationTypes.REMOVE_EDITABLE_PROJECT_RECORD
		}),
		checkIsRecordVisible(x) {
			return this.paymentsFilterType === TreasuryStatementPaymentsType.ALL ||
				(this.paymentsFilterType === TreasuryStatementPaymentsType.FINAL && x.isFinal) ||
				(this.paymentsFilterType === TreasuryStatementPaymentsType.NOT_FINAL && !x.isFinal);
		},
		checkIsItemValid(item) {
			return Object.entries(this.validation).every(([key, validators]) => !item.hasOwnProperty(key) ||
				validators.every(validate => validate(item[key]) === true));
		},
		checkIsItemEditable(item) {
			return this.editableItems.some(x => x.id === item.id);
		},
		getFilteredFinanceSources(paymentExpenseId) {
			//Если тип операции "Выплата" и выбранная статье ДДС "Выдача займа" у нас отображаются все субсидии, иначе, только по проекту
			const isPayoutOperationType = this.activeTreasuryStatementOperationTypeKey === TreasuryStatementOperationType.PAYOUT;
			
			if (isPayoutOperationType && paymentExpenseId === this.loanIssuancePaymentExpenseId) {
				return this.allFinanceSources;
			} else {
				return this.financeSources;
			}
		},
		async handleUpdateItem(item) {
			await this.updateItem(item);
			this.removeEditableItem(item);
		},
		handleCancelItemEditing(item) {
			this.removeEditableItem(item);
		},
		handleOpenDeleteItemDialog(item) {
			this.isDeleteItemDialogOpened = true;
			this.itemToDelete = item;
		},
		handleCancelItemDeleting() {
			this.isDeleteItemDialogOpened = false;
			this.itemToDelete = null;
		},
		async handleDeleteItem() {
			await this.deleteItem(this.itemToDelete);
			this.itemToDelete = null;
			this.isDeleteItemDialogOpened = false;
		},
		handleOpenCreateItemDialog() {
			this.isNewItemDialogOpened = true;
			this.setNewItemRecordId(this.record.id);
		},
		handleCancelCreateItem() {
			this.isNewItemDialogOpened = false;
			this.resetNewItem();
			this.$refs[this.refs.form].resetValidation();
		},
		async handleCreateItem() {
			await this.createItem(this.record);

			if(this.$refs[this.refs.form])
				this.$refs[this.refs.form].resetValidation();

			this.isNewItemDialogOpened = false;
		},
		async fetchRecordDictionaries(projectId) {
			this.isRecordDictionariesLoading = true;

			try {
				this.quarters = await dictionariesController.getProjectQuarters(projectId);
				this.financeSources = await dictionariesController.getProjectFinanceSources(projectId);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.isRecordDictionariesLoading = false;
			}
		}
	},
	async created() {
		abortService.initialize();

		if(this.record.projectId)
			await this.fetchRecordDictionaries(this.record.projectId);
	},
	watch: {
		async "record.projectId"(projectId) {
			await this.fetchRecordDictionaries(projectId);
		}
	},
	components: {
		FrpDialog,
		FrpTextField,
		FrpAutocomplete,
		FrpIcon,
		FrpBtn
	}
};
</script>

import { actionTypes, getterTypes, mutationTypes, namespace } from "@/store/loan/modules/loanSchedule/modules/agreement/types";
import scheduleItemsTypes from "@/store/loan/modules/loanSchedule/modules/scheduleItems/types";
import tranchesTypes from "@/store/loan/modules/loanSchedule/modules/tranches/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import AgreementState from "@/store/loan/modules/loanSchedule/modules/agreement/types/agreementState";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import AbortService from "@/services/abortService";
import { LoanScheduleController } from "@/api/loan/loanSchedule";
import { CreateVersionModeType } from "@/store/loan/modules/loanSchedule/modules/agreement/types/createVersionModeType";
import FormMixinBuilder from "@/store/shared/form";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import { resolveAction, resolveGetter, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import LoanScheduleState from "@/store/loan/modules/loanSchedule/types/loanScheduleState";
import storeManager from "@/store/manager";
import Version from "@/store/loan/modules/loanSchedule/types/version";
import loanScheduleMapper from "@/store/loan/modules/loanSchedule/mapper";
import mapper from "@/store/loan/modules/loanSchedule/modules/agreement/mapper";
import ApiVersion from "@/api/loan/types/loanSchedule/apiVersion";
import Agreement from "@/store/loan/modules/loanSchedule/modules/agreement/types/agreement";
import Tranche from "@/store/loan/modules/loanSchedule/modules/tranches/types/tranche";
import ScheduleItem from "@/store/loan/modules/loanSchedule/modules/scheduleItems/types/scheduleItem";
import Payment from "@/store/loan/modules/loanSchedule/modules/payments/types/payment";
import ApiAgreement from "@/api/loan/types/loanSchedule/apiAgreement";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import ApiTitleDocument from "@/api/loan/types/dictionaries/apiTitleDocument";
import { AgreementModeType } from "@/store/loan/modules/loanSchedule/modules/agreement/types/agreementModeType";
import PaymentsState from "@/store/loan/modules/loanSchedule/modules/payments/types/paymentsState";
import { cloneDeep } from "lodash";
import router from "@/router/loan";
import { sumFloat } from "@/utils/number";
import { TitleDocuments } from "@/store/loan/modules/loanSchedule/types/titleDocuments";

const abortService = new AbortService();
const loanScheduleController = new LoanScheduleController(abortService);

const baseMixin = (new BaseMixinBuilder(abortService)).build();
const formMixin = (new FormMixinBuilder()).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["draft"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new AgreementState(
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<AgreementState, any>>{
	...formMixin.getters,
	...snapshotMixin.getters,
	[getterTypes.version]: (state, getters, rootState) => {
		const { version } = resolveNestedState<LoanScheduleState>(rootState, storeManager.loan.loanSchedule.namespace);
		return version;
	},
	[getterTypes.titleDocuments]: (state, getters, rootState) => {
		const { titleDocuments } = resolveNestedState<LoanScheduleState>(rootState, storeManager.loan.loanSchedule.namespace);
		return titleDocuments;
	}
};

const actions = <ActionTree<AgreementState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, rootState }) {
		await dispatch(actionTypes.initializeBase);
		
		await dispatch(actionTypes.fetch);
		
		const { version } = resolveNestedState<LoanScheduleState>(rootState, storeManager.loan.loanSchedule.namespace);
		commit(mutationTypes.SET_MODE, !version.id || version.isActive ? CreateVersionModeType.VERSION : CreateVersionModeType.DRAFT);
		
		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.fetch]({ commit }) {
		commit(mutationTypes.SET_IS_FORM_LOADING, true);
		
		try {
			const agreement = await loanScheduleController.getAgreement(router.currentRoute.params.projectId);
			
			commit(mutationTypes.SET_AGREEMENT, mapper.map(agreement, ApiAgreement, Agreement));
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_LOADING, false);
		}
	},
	async [actionTypes.resetDraft]({ commit, getters, state }) {
		if(!getters.version.id) {
			commit(mutationTypes.SET_DRAFT_TITLE_DOCUMENT,
				getters.titleDocuments.find((x: ApiTitleDocument) => x.name === "Договор займа"));
			commit(mutationTypes.SET_DRAFT_DOCUMENT_NUMBER, state.agreement.documentNumber);
			commit(mutationTypes.SET_DRAFT_START_DATE, state.agreement.signingDate);
		} else {
			commit(mutationTypes.SET_DRAFT, cloneDeep(getters.version));
		}
		
		commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
	},
	async [actionTypes.saveVersion]({ state, commit, rootState, rootGetters }) {
		commit(mutationTypes.SET_IS_VERSION_ACTIVATING, true);
		
		try {
			const { version } = resolveNestedState<LoanScheduleState>(rootState, storeManager.loan.loanSchedule.namespace);
			const { listing: { items: paymentItems } } = resolveNestedState<PaymentsState>(rootState,
				storeManager.loan.loanSchedule.payments.namespace);
			
			
			const scheduleItems = rootGetters[resolveGetter(storeManager.loan.loanSchedule.scheduleItems.namespace,
				scheduleItemsTypes.getterTypes.formattedItems)];
			const tranches = rootGetters[resolveGetter(storeManager.loan.loanSchedule.tranches.namespace,
				tranchesTypes.getterTypes.formattedItems)];
			
			const scheduleItemsSum = scheduleItems.map((x: ScheduleItem) => x.amount)
				.reduce((acc: number, x: number) => sumFloat(acc, +x), 0);
			const tranchesSum = tranches.map((x: Tranche) => x.amount)
				.reduce((acc: number, x: number) => sumFloat(acc, +x), 0);
			const assignmentPaymentsSum = paymentItems.map((x: Payment) => x.assignedLoan)
				.reduce((acc: number, x: number) => sumFloat(acc, +x), 0);
			const isAssignmentVersion = version.titleDocument.number === TitleDocuments.CessionAgreement;
			
			if(!isAssignmentVersion && state.agreement.exactFrpSum !== scheduleItemsSum) {
				commit(mutationTypes.WRONG_SCHEDULE_AMOUNT_EVENT);
				return;
			}
			if(isAssignmentVersion && assignmentPaymentsSum !== scheduleItemsSum) {
				commit(mutationTypes.WRONG_ASSIGNMENT_SCHEDULE_AMOUNT_EVENT);
				return;
			}
			if(tranches.length && state.agreement.exactFrpSum !== tranchesSum) {
				commit(mutationTypes.WRONG_TRANCHES_AMOUNT_EVENT);
				return;
			}
			
			
			const activatedVersion = await loanScheduleController.activateVersion(version.id);
			
			alertService.addInfo(AlertKeys.VERSION_SUCCESS_ACTIVATED);
			commit(mutationTypes.VERSION_UPDATED_EVENT, activatedVersion);
			commit(mutationTypes.SET_MODE, CreateVersionModeType.VERSION);
		} catch (error) {
			console.error(error);
			
			if(error.response.data.errors?.schedule) {
				alertService.addCustomError(error.response.data.errors.schedule[0]);
			} else {
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_VERSION_ACTIVATING, false);
		}
	},
	async [actionTypes.saveDraft]({ state, commit, rootState }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);
		
		try {
			const { version, versionHeaders } = resolveNestedState<LoanScheduleState>(rootState, storeManager.loan.loanSchedule.namespace);
			
			let draft;
			
			if(versionHeaders.length) {
				if(version.isActive) {
					draft = await loanScheduleController.createDraft(version.id, loanScheduleMapper.map(state.draft, Version, ApiVersion));
					alertService.addInfo(AlertKeys.DRAFT_SUCCESS_CREATED);
				} else {
					draft = await loanScheduleController.updateDraft(version.id, loanScheduleMapper.map(state.draft, Version, ApiVersion));
					alertService.addInfo(AlertKeys.DRAFT_SUCCESS_SAVED);
				}
			} else {
				draft = await loanScheduleController.createFirstDraft(loanScheduleMapper.map({
					...state.draft,
					projectId: +router.currentRoute.params.projectId
				}, Version, ApiVersion));
				alertService.addInfo(AlertKeys.DRAFT_SUCCESS_CREATED);
			}
			
			commit(mutationTypes.VERSION_UPDATED_EVENT, draft);
			commit(mutationTypes.SET_MODE, CreateVersionModeType.DRAFT);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.saveChanges]({ state, commit, rootState }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);
		
		try {
			const { version } = resolveNestedState<LoanScheduleState>(rootState, storeManager.loan.loanSchedule.namespace);
			
			const newVersion = await loanScheduleController.updateDraft(version.id,
				loanScheduleMapper.map(state.draft, Version, ApiVersion));
			
			alertService.addInfo(AlertKeys.CHANGES_SUCCESS_SAVED);
			commit(mutationTypes.VERSION_UPDATED_EVENT, newVersion);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.deleteDraft]({ state, commit, rootState }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);
		try {
			const { version, versionHeaders } = resolveNestedState<LoanScheduleState>(rootState, storeManager.loan.loanSchedule.namespace);
			
			await loanScheduleController.deleteDraft(version.id);
			
			commit(mutationTypes.VERSION_DELETED_EVENT, version.id);
			
			alertService.addInfo(AlertKeys.DRAFT_SUCCESS_DELETED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	}
};

const mutations = <MutationTree<AgreementState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	[mutationTypes.VERSION_UPDATED_EVENT]() {
	},
	[mutationTypes.VERSION_SELECTED_EVENT]() {
	},
	[mutationTypes.VERSION_DELETED_EVENT]() {
	},
	[mutationTypes.SET_DRAFT](state, value) {
		state.draft = value;
	},
	[mutationTypes.SET_DRAFT_TITLE_DOCUMENT](state, value) {
		state.draft.titleDocument = value;
	},
	[mutationTypes.SET_DRAFT_DOCUMENT_NUMBER](state, value) {
		state.draft.documentNumber = value;
	},
	[mutationTypes.SET_DRAFT_START_DATE](state, value) {
		state.draft.startDate = value;
	},
	[mutationTypes.SET_DRAFT_CHANGE_REASON](state, value) {
		state.draft.changeReason = value;
	},
	[mutationTypes.SET_DRAFT_COMMENT](state, value) {
		state.draft.comment = value;
	},
	[mutationTypes.SET_AGREEMENT](state, value) {
		state.agreement = value;
	},
	[mutationTypes.SET_MODE](state, value) {
		state.mode = value;
	},
	[mutationTypes.SET_AGREEMENT_MODE](state, value) {
		state.agreementMode = value;
	},
	[mutationTypes.SET_IS_VERSION_ACTIVATING](state, value) {
		state.isVersionActivating = value;
	},
	[mutationTypes.WRONG_TRANCHES_AMOUNT_EVENT]() {
	},
	[mutationTypes.WRONG_SCHEDULE_AMOUNT_EVENT]() {
	},
	[mutationTypes.WRONG_ASSIGNMENT_SCHEDULE_AMOUNT_EVENT]() {
	}
};

const subscribe = (store: any) => {
	const { commit, dispatch } = store;
	
	store.subscribe(async ({ type, payload }: any, state: any) => {
		switch (type) {
			case resolveMutation(storeManager.loan.loanSchedule.agreement.namespace, mutationTypes.SET_AGREEMENT_MODE):
			{
				if(payload === AgreementModeType.EDIT) {
					await dispatch(resolveAction(storeManager.loan.loanSchedule.agreement.namespace, actionTypes.resetDraft));
				}
				break;
			}
			default:
				break;
		}
	});
};

export {
	namespace, state, getters, actions, mutations, subscribe
};

const agreementModule = {
	namespace, state, getters, actions, mutations, subscribe, namespaced: true
};

export default agreementModule;

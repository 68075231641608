import IPageState from "@/store/shared/base/types/pageState";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import AgreementsAccruedInterest from "@/store/loan/modules/agreementsAccruedInterest/types/agreementsAccruedInterest";
import AgreementsAccruedInterestFilter from "@/store/loan/modules/agreementsAccruedInterest/types/agreementsAccruedInterestFilter";
import ApiAgreementStatus from "@/api/loan/types/loanSchedule/apiAgreementStatus";
import RouteState from "@/store/shared/route/types/routeState";
import Period from "@/store/loan/modules/agreementsAccruedInterest/types/period";
import FileMeta from "@/store/shared/storage/types/fileMeta";
import CertificateInfo from "@/types/certificateInfo";
import AgreementsAccruedInterestReport from "@/store/loan/modules/agreementsAccruedInterest/types/agreementsAccruedInterestReport";

export default class AgreementsAccruedInterestState implements IPageState {
	constructor(
		public listing: ListingModel<AgreementsAccruedInterest>,
		public sorting: SortingModel<String>,
		public search: SearchModel,
		public route: RouteState,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isLoading: boolean = false,
		public periods: Period[] = [],
		public agreementStatuses: ApiAgreementStatus[] = [],
		public filter: AgreementsAccruedInterestFilter = new AgreementsAccruedInterestFilter(),
		public isFilterPeriodIdChanged: boolean = false,
		public isOnlySelectedShown: boolean = false,
		public isUpdateSelectedItemsRequired: boolean = false,
		public agreementsAccruedInterestReport: AgreementsAccruedInterestReport = new AgreementsAccruedInterestReport(),
		public isReportError: boolean = false,
		public isAgreementsAccruedInterestReportLoading: boolean = false,
		public accruedInterestFileMeta: FileMeta = new FileMeta(),
		public certificateInfo: CertificateInfo = new CertificateInfo(),
		public isSigned: boolean = false
	)
	{
	}
}

<template>
	<loan-content-layout v-if="isInitialized">
		<v-row>
			<v-col class="d-flex flex-column pt-4" style="gap: 24px">
				<v-card elevation="0" class="loan-card px-2 py-6">
					<v-row class="align-center">
						<v-col class="py-0">
							<v-card-title class="flex-column align-start py-0">
								<span class="text-h6">
									{{ $t("titles.accruedInterest") }}
								</span>
								<span class="text-body-2 mt-1">
									{{
										$t("common.documentFromDate",
											{ number: agreement.documentNumber, date: formatDate(agreement.signingDate, dateFormat) })
									}}
								</span>
							</v-card-title>
						</v-col>
						<v-col class="py-0">
							<v-card-actions class="px-4">
								<div class="d-flex justify-md-end" style="width: 100%">
									<frp-btn outlined
											 v-if="can(Permissions.LOAN_CREATE)"
											 color="primary"
											 @click="isAddItemsParametersDialogOpened = true">
										{{ $t("buttons.addManually") }}
									</frp-btn>

									<frp-btn @click="isExtraRateDialogOpened = true"
											 v-if="can(Permissions.LOAN_UPDATE)"
											 outlined
											 color="primary">
										{{ $t("buttons.additionalInterest") }}
									</frp-btn>

									<frp-btn color="blue" dark elevation="0" @click="openAgreementsAccruedInterest">
										{{ $t("buttons.recalculateInterest") }}
									</frp-btn>
								</div>
							</v-card-actions>
						</v-col>
					</v-row>
				</v-card>

				<v-card elevation="0" class="loan-card pa-0 pt-6">
					<v-row class="px-6">
						<v-col class="d-flex justify-center align-end" style="gap: 24px">
							<frp-range-date-field v-model="accrualDatesRange"
												  :label="$t('fields.accrualDate.label')"
												  type="string"
												  :disabled="isDictionariesLoading"
												  dense
												  hide-details
												  :placeholder="$t('fields.accrualDate.placeholder')">
							</frp-range-date-field>

							<frp-range-date-field v-model="paymentDatesRange"
												  :label="$t('fields.paymentDate.label')"
												  :disabled="isDictionariesLoading"
												  type="string"
												  dense
												  hide-details
												  :placeholder="$t('fields.paymentDate.placeholder')">
							</frp-range-date-field>

							<frp-range-date-field v-model="accountedDatesRange"
												  :label="$t('fields.accountedDate.label')"
												  :disabled="isDictionariesLoading"
												  type="string"
												  dense
												  hide-details
												  :placeholder="$t('fields.accountedDate.placeholder')">
							</frp-range-date-field>

							<frp-autocomplete v-model="internalFilterValues.accrualType"
											  :label="$t('fields.accrualType.label')"
											  :items="accrualTypes"
											  :disabled="isDictionariesLoading"
											  item-text="name"
											  item-value="id"
											  color="blue"
											  hide-details
											  clearable
											  :placeholder="$t('fields.accrualType.placeholder')">
							</frp-autocomplete>

							<frp-autocomplete v-model="internalFilterValues.financingSourceId"
											  :label="$t('fields.financeSource.label')"
											  :items="financeSources"
											  :disabled="isDictionariesLoading"
											  item-text="name"
											  item-value="id"
											  color="blue"
											  hide-details
											  clearable
											  :placeholder="$t('fields.financeSource.placeholder')">
							</frp-autocomplete>

							<frp-autocomplete v-model="internalFilterValues.quarterId"
											  :label="$t('fields.quarter.label')"
											  :items="quarters"
											  :disabled="isDictionariesLoading"
											  item-text="title"
											  item-value="id"
											  color="blue"
											  hide-details
											  clearable
											  :placeholder="$t('fields.quarter.placeholder')">
							</frp-autocomplete>

							<v-spacer></v-spacer>

							<div class="align-self-end d-flex flex-nowrap">
								<frp-btn @click="resetFilter"
										 :disabled="isFilterEmpty && !isFilterChanged"
										 color="primary"
										 outlined>
									{{ $t("buttons.clearAll") }}
								</frp-btn>
								<frp-btn elevation="0"
										 color="blue"
										 dark
										 :disabled="!isFilterChanged"
										 @click="applyFilter">
									{{ $t("buttons.accept") }}
								</frp-btn>
							</div>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-data-table :headers="headers"
										  :loading-text="$t('tables.loading')"
										  :loading="isItemsLoading"
										  hide-default-footer
										  item-key="id"
										  :items="formattedItems"
										  :items-per-page="-1"
										  :mobile-breakpoint="mobileBreakpoint"
										  :options="options"
										  :item-class="() => 'text-body-2 cy-table-row'"
										  class="loan-table colored-table troubled-borrowers-table clickable-rows d-flex flex-column">
								<template #header>
									<tr class="table-header">
										<td colspan="100">
											<div class="d-flex justify-end">
												<frp-btn
													v-if="can(Permissions.LOAN_UPDATE)"
													@click="isMassUpdateDialogOpened = true"
													outlined
													dense
													style="text-transform: none"
													elevation="0"
													class="mr-2"
													color="blue"
													dark>
													<frp-icon class="pr-3" :color="colors.blue.base" src="ico_edit"></frp-icon>
													{{ $t("buttons.massEdit") }}
												</frp-btn>
											</div>
										</td>
									</tr>
								</template>

								<template #item.accrualTypeId="{ item }">
									<span v-if="!checkIsItemEditable(item)" :class="{ 'blue--text': !item.isActive }">
										{{ accrualTypes.find(x => x.id === item.accrualTypeId).name || "--" }}
									</span>
									<frp-autocomplete dense
													  required
													  v-model="editableItems.find(x => x.id === item.id).accrualTypeId"
													  :disabled="isDictionariesLoading"
													  :items="accrualTypes"
													  :rules="validation.accrualTypeId"
													  item-text="name"
													  item-value="id"
													  color="blue"
													  hide-details
													  v-else
													  :placeholder="$t('fields.financeSource.label')">
									</frp-autocomplete>
								</template>

								<template #item.financingSourceId="{ item }">
									<span v-if="!checkIsItemEditable(item)" :class="{ 'blue--text': !item.isActive }">
										{{ financeSources.find(x => x.id === item.financingSourceId).name || "--" }}
									</span>
									<frp-autocomplete dense
													  required
													  v-model="editableItems.find(x => x.id === item.id).financingSourceId"
													  :disabled="isDictionariesLoading"
													  :items="financeSources"
													  :rules="validation.financingSourceId"
													  item-text="name"
													  item-value="id"
													  color="blue"
													  hide-details
													  v-else
													  :placeholder="$t('fields.financeSource.label')">
									</frp-autocomplete>
								</template>

								<template #item.quarterId="{ item }">
									<span v-if="!checkIsItemEditable(item)" :class="{ 'blue--text': !item.isActive }">
										{{ quarters.find(x => x.id === item.quarterId).title || "--" }}
									</span>
									<frp-autocomplete dense
													  required
													  v-model="editableItems.find(x => x.id === item.id).quarterId"
													  :disabled="isDictionariesLoading"
													  :items="quarters"
													  :rules="validation.quarterId"
													  item-text="title"
													  item-value="id"
													  color="blue"
													  hide-details
													  v-else
													  :placeholder="$t('fields.quarter.label')">
									</frp-autocomplete>
								</template>

								<template #item.accrualDate="{ item }">
									<span v-if="!checkIsItemEditable(item)" :class="{ 'blue--text': !item.isActive }">
										{{ formatDate(item.accrualDate, dateFormat) || "--" }}
									</span>
									<frp-date-field dense
													required
													v-model="editableItems.find(x => x.id === item.id).accrualDate"
													:rules="validation.accrualDate"
													hide-details
													v-else
													:placeholder="$t('fields.date.label')">
									</frp-date-field>
								</template>

								<template #item.paymentDate="{ item }">
									<span v-if="!checkIsItemEditable(item)" :class="{ 'blue--text': !item.isActive }">
										{{ formatDate(item.paymentDate, dateFormat) || "--" }}
									</span>
									<frp-date-field dense
													required
													v-model="editableItems.find(x => x.id === item.id).paymentDate"
													:rules="validation.paymentDate"
													hide-details
													v-else
													:placeholder="$t('fields.date.label')">
									</frp-date-field>
								</template>

								<template #item.accountedDate="{ item }">
									<span v-if="!checkIsItemEditable(item)" :class="{ 'blue--text': !item.isActive }">
										{{ formatDate(item.accountedDate, dateFormat) || "--" }}
									</span>
									<frp-date-field dense
													v-model="editableItems.find(x => x.id === item.id).accountedDate"
													:rules="validation.accountedDate"
													hide-details
													v-else
													:placeholder="$t('fields.date.label')">
									</frp-date-field>
								</template>

								<template #item.accruedAmount="{ item }">
									<span v-if="!checkIsItemEditable(item)" :class="{ 'blue--text': !item.isActive }">
										{{ formatCurrency(item.accruedAmount) || "--" }}
									</span>
									<frp-text-field dense
													required
													type="number"
													v-model.number="editableItems.find(x => x.id === item.id).accruedAmount"
													:rules="validation.accruedAmount"
													hide-details
													v-else
													:placeholder="$t('fields.amount.labelRUB')">
									</frp-text-field>
								</template>

								<template #item.comment="{ item }">
									<span v-if="!checkIsItemEditable(item)" :class="{ 'blue--text': !item.isActive }">
										{{ item.comment || "--" }}
									</span>
									<frp-text-field dense
													type="text"
													v-model="editableItems.find(x => x.id === item.id).comment"
													:rules="validation.comment"
													hide-details
													v-else
													:placeholder="$t('fields.comment.placeholder')">
									</frp-text-field>
								</template>

								<template #item.action1="{ item }">
									<frp-btn without-padding
											 height="28"
											 small
											 v-if="checkIsItemEditable(item)"
											 icon
											 color="blue"
											 dark
											 :disabled="!checkIsItemValid(editableItems.find(x => x.id === item.id))"
											 :loading="savingItems.some(x => x.id === item.id)"
											 @click="handleUpdateItem(editableItems.find(x => x.id === item.id))">
										<frp-icon src="ico_save" :color="colors.blue.base"></frp-icon>
									</frp-btn>

									<frp-btn without-padding
											 height="28"
											 small
											 icon
											 color="blue"
											 dark
											 v-else-if="can(Permissions.LOAN_UPDATE)"
											 :disabled="!item.isActive || !!item.accountedDate"
											 @click="addEditableItem(item)">
										<frp-icon src="ico_edit" :color="colors.primary.darken1"></frp-icon>
									</frp-btn>
								</template>

								<template #item.action2="{ item }">
									<frp-btn
										without-padding
										small
										icon
										height="28"
										color="blue"
										dark
										v-if="checkIsItemEditable(item)"
										@click="handleCancelItemEditing(item)">
										<frp-icon src="ico_close" :color="colors.primary.darken1"></frp-icon>
									</frp-btn>
									<frp-btn
										without-padding
										small
										icon
										height="28"
										color="blue"
										dark
										v-else-if="can(Permissions.LOAN_DELETE)"
										:disabled="!item.isActive || !!item.accountedDate"
										@click="handleOpenDeleteItemDialog(item)">
										<frp-icon src="ico_delete" :color="colors.primary.darken1"></frp-icon>
									</frp-btn>
								</template>

								<template #foot>
									<tfoot class="text-subtitle-1 font-weight-medium grey--text text--darken-3">
									<tr>
										<template v-if="isMobileBreakpoint">
											<td class="d-flex justify-space-between mt-5">
												<span>{{ $t("content.total") }}</span>
												<span>{{ formatCurrency(totalAmount) }}</span>
											</td>
										</template>

										<template v-else>
											<td colspan="5"/>
											<td>
												<span>{{ $t("content.total") }}</span>
											</td>
											<td style="position: relative">
												<span>{{ formatCurrency(totalAmount) }}</span>
											</td>
										</template>
									</tr>
									</tfoot>
								</template>
							</v-data-table>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
		</v-row>

		<v-row class="mt-0">
			<v-col class="d-flex justify-end">
				<frp-pagination v-if="!isItemsLoading"
								v-model="page"
								active-color="blue"
								:length="Math.ceil(paging.total / paging.pageSize)">
				</frp-pagination>
			</v-col>
		</v-row>

		<frp-dialog v-model="isAddItemsParametersDialogOpened" :title="$t('dialogs.addInterest.title')" persistent>
			<template #content="{ onIntersect }">
				<v-form :ref="refs.form" v-intersect="onIntersect" v-model="formValid">
					<frp-autocomplete :label="$t('fields.accrualType.label')"
									  v-model="addItemsParametersAccrualTypeId"
									  :rules="validation.accrualTypeId"
									  :items="accrualTypes"
									  item-text="name"
									  item-value="id"
									  color="blue"
									  required
									  :placeholder="$t('fields.accrualType.label')">
					</frp-autocomplete>
					<frp-autocomplete :label="$t('fields.financeSource.label')"
									  v-model="addItemsParametersFinancingSourceId"
									  :rules="validation.financingSourceId"
									  :items="financeSources"
									  item-text="name"
									  item-value="id"
									  color="blue"
									  required
									  :placeholder="$t('fields.financeSource.placeholder')">
					</frp-autocomplete>
					<frp-autocomplete :label="$t('fields.quarter.label')"
									  v-model="addItemsParametersQuarterId"
									  :rules="validation.quarterId"
									  :items="quarters"
									  item-text="title"
									  item-value="id"
									  color="blue"
									  required
									  :placeholder="$t('fields.quarter.label')">
					</frp-autocomplete>
					<frp-range-date-field :label="$t('fields.accrualDate.label')"
									v-model="addItemsParametersAccrualDatesRange"
									:rules="validation.accrualDatesRange"
									required
									:placeholder="$t('fields.date.placeholder')">
					</frp-range-date-field>
					<frp-date-field :label="$t('fields.paymentDate.label')"
									v-model="addItemsParametersPaymentDate"
									:rules="validation.paymentDate"
									required
									:placeholder="$t('fields.date.placeholder')">
					</frp-date-field>
					<frp-text-field :label="$t('fields.accruedAmount.label')"
									v-model.number="addItemsParametersAccruedAmount"
									:rules="validation.accruedAmount"
									type="number"
									required
									:placeholder="$t('fields.accruedAmount.placeholder')">
					</frp-text-field>
				</v-form>
			</template>

			<template #footer>
				<frp-btn outlined
						 @click="resetAddItems"
						 color="primary">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				<frp-btn elevation="0"
						 color="blue"
						 dark
						 :loading="isFormSaving"
						 :disabled="!stateContainsUnsavedChanges || !isFormValid"
						 @click="handleAddItems">
					{{ $t("buttons.addInterest") }}
				</frp-btn>
			</template>
		</frp-dialog>

		<frp-dialog v-model="isExtraRateDialogOpened" :title="$t('dialogs.additionalInterest.title')" persistent>
			<template #content="{ onIntersect }">
				<v-form ref="extraRateForm" v-intersect="onIntersect" v-model="isExtraRateFormValid">
					<frp-range-date-field :label="$t('fields.accrualDate.label')"
										  v-model="extraRateParametersAccrualDatesRange"
										  type="number"
										  required
										  :placeholder="$t('fields.accrualDate.placeholder')">
					</frp-range-date-field>
					<frp-date-field :label="$t('fields.paymentDate.label')"
									v-model="extraRateParametersPaymentDate"
									return-value-type="number"
									required
									:placeholder="$t('fields.date.placeholder')">
					</frp-date-field>
					<frp-text-field :label="$t('fields.additionalInterest.label')"
									v-model.number="extraRateParametersExtraInterestRate"
									type="number"
									required
									:placeholder="$t('fields.additionalInterest.placeholder')">
					</frp-text-field>
				</v-form>
			</template>

			<template #footer>
				<frp-btn outlined
						 @click="resetExtraRate"
						 color="primary">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				<frp-btn elevation="0"
						 color="blue"
						 dark
						 :loading="isFormSaving"
						 :disabled="!isExtraRateFormValid"
						 @click="handleSubmitExtraRateParameters">
					{{ $t("buttons.addInterest") }}
				</frp-btn>
			</template>
		</frp-dialog>

		<frp-dialog v-model="isMassUpdateDialogOpened" :title="$t('dialogs.massEdit.title')" persistent>
			<template #content="{ onIntersect }">
				<v-form ref="massUpdateForm" v-intersect="onIntersect" v-model="isMassUpdateFormValid">
					<p class="text-h6 primary--text text--darken-1 mb-4 d-flex justify-space-between">
						{{ $t("subheaders.fieldFilter") }}
					</p>
					<div class="ml-4">
						<div class="d-flex align-center">
							<v-simple-checkbox color="blue"
											   class="frp-simple-checkbox blue--text mr-1"
											   v-model="massUpdateParametersAccrualTypeFilterEnabled">
							</v-simple-checkbox>
							<frp-autocomplete :label="$t('fields.accrualType.label')"
											  style="width: 100%"
											  v-model="massUpdateParametersAccrualTypeId"
											  :items="accrualTypes"
											  item-text="name"
											  item-value="id"
											  color="blue"
											  :required="massUpdateParametersAccrualTypeFilterEnabled"
											  :placeholder="$t('fields.accrualType.label')">
							</frp-autocomplete>
						</div>
						<div class="d-flex align-center">
							<v-simple-checkbox color="blue"
											   class="frp-simple-checkbox blue--text mr-1"
											   v-model="massUpdateParametersSourceFilterEnabled">
							</v-simple-checkbox>
							<frp-autocomplete :label="$t('fields.financeSource.label')"
											  style="width: 100%"
											  v-model="massUpdateParametersFinanceSourceId"
											  :items="financeSources"
											  item-text="name"
											  item-value="id"
											  color="blue"
											  :required="massUpdateParametersSourceFilterEnabled"
											  :placeholder="$t('fields.financeSource.placeholder')">
							</frp-autocomplete>
						</div>
						<div class="d-flex align-center">
							<v-simple-checkbox color="blue"
											   class="frp-simple-checkbox blue--text mr-1"
											   v-model="massUpdateParametersQuarterFilterEnabled">
							</v-simple-checkbox>
							<frp-autocomplete :label="$t('fields.quarter.label')"
											  style="width: 100%"
											  v-model="massUpdateParametersQuarterId"
											  :items="quarters"
											  item-text="title"
											  item-value="id"
											  color="blue"
											  :required="massUpdateParametersQuarterFilterEnabled"
											  :placeholder="$t('fields.quarter.label')">
							</frp-autocomplete>
						</div>
						<div class="d-flex align-center">
							<v-responsive class="ml-9">
								<frp-range-date-field :label="$t('fields.accrualDate.label')"
													  v-model="massUpdateParametersAccrualDatesRange"
													  required
													  :placeholder="$t('fields.accrualDate.placeholder')">
								</frp-range-date-field>
							</v-responsive>
						</div>
					</div>

					<p class="text-h6 primary--text text--darken-1 my-4 d-flex justify-space-between">
						{{ $t("subheaders.setValue") }}
					</p>
					<div class="ml-4">
						<div class="d-flex align-center">
							<v-simple-checkbox color="blue"
											   class="frp-simple-checkbox blue--text mr-1"
											   v-model="massUpdateParametersPaymentDateSettingEnabled">
							</v-simple-checkbox>
							<v-responsive>
								<frp-date-field :label="$t('fields.paymentDate.label')"
												v-model="massUpdateParametersPaymentDate"
												:required="massUpdateParametersPaymentDateSettingEnabled"
												:placeholder="$t('fields.date.placeholder')">
								</frp-date-field>
							</v-responsive>
						</div>
						<div class="d-flex align-center">
							<v-simple-checkbox color="blue"
											   class="frp-simple-checkbox blue--text mr-1"
											   v-model="massUpdateParametersAccruedAmountSettingEnabled">
							</v-simple-checkbox>
							<frp-text-field :label="$t('fields.accruedAmount.label')"
											style="width: 100%;"
											v-model.number="massUpdateParametersAccruedAmount"
											type="number"
											:required="massUpdateParametersAccruedAmountSettingEnabled"
											:placeholder="$t('fields.accruedAmount.placeholder')">
							</frp-text-field>
						</div>
					</div>
				</v-form>
			</template>

			<template #footer>
				<frp-btn outlined
						 @click="handleCancelMassUpdateItems"
						 color="primary">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				<frp-btn elevation="0"
						 color="blue"
						 dark
						 :loading="isFormSaving"
						 :disabled="!isMassUpdateFormValid"
						 @click="handleMassUpdateItems">
					{{ $t("buttons.edit") }}
				</frp-btn>
			</template>
		</frp-dialog>

		<frp-dialog v-model="isDeleteItemDialogOpened" :title="$t('dialogs.deleteNote.title')">
			<template #content="{ onIntersect }">
					<span v-intersect="onIntersect" class="primary--text text-body-2">
						{{ $t("dialogs.deleteNote.warning") }}
					</span>
			</template>

			<template #footer>
				<frp-btn outlined
						 @click="isDeleteItemDialogOpened = false"
						 color="primary">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				<frp-btn elevation="0"
						 :loading="isItemDeleting"
						 color="blue"
						 dark
						 @click="handleDeleteItem">
					{{ $t("buttons.deleteNote") }}
				</frp-btn>
			</template>
		</frp-dialog>
	</loan-content-layout>

	<loan-accrued-interest-loader v-else/>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn";
import FrpAutocomplete from "@/components/fields/FrpAutocomplete";
import FrpDateField from "@/components/fields/FrpDateField";
import FrpRangeDateField from "@/components/fields/FrpRangeDateField";
import FrpTextField from "@/components/fields/FrpTextField";
import FrpIcon from "@/components/icon/FrpIcon";
import LoanContentLayout from "@/components/layouts/LoanContentLayout";
import colorsMixin from "@/mixins/colorsMixin";
import formMixin from "@/mixins/formMixin";
import { listMixin } from "@/mixins/listMixin";
import { convertIsoToNumber, formatDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import { formatCurrency } from "@/utils/formatting";
import FrpPagination from "Components/common/FrpPagination";
import FrpDialog from "Components/dialogs/FrpDialog";
import { assign, isEqual } from "lodash";
import authorizationMixin from "Mixins/authorizationMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import { i18n } from "Plugins/index";
import { RouteNames } from "Router/loan/routes";
import { actionTypes, getterTypes, mutationTypes, namespace } from "Store/loan/modules/accruedInterest/types";
import { requiredRule } from "Utils/validation";
import LoanAccruedInterestLoader from "Views/loan/accruedInterest/LoanAccruedInterestLoader";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	metaInfo: {
		title: i18n.t("metaTitles.accruedInterest")
	},
	mixins: [storeModuleBasedPage, listMixin, colorsMixin, formMixin, authorizationMixin],
	data() {
		return {
			namespace,
			RouteNames,
			dateFormat,
			formatDate,
			formatCurrency,
			isDeleteItemDialogOpened: false,
			isAddItemsParametersDialogOpened: false,
			isExtraRateDialogOpened: false,
			isExtraRateFormValid: false,
			isMassUpdateDialogOpened: false,
			itemToDelete: null,
			isDataValid: false,
			isMassUpdateFormValid: false,
			internalFilterValues: {
				accrualStartDate: "",
				accrualEndDate: "",
				accrualType: "",
				paymentStartDate: "",
				paymentEndDate: "",
				accountedStartDate: "",
				accountedEndDate: "",
				financingSourceId: "",
				quarterId: ""
			},
			validation: {
				accrualTypeId: [requiredRule()],
				financingSourceId: [requiredRule()],
				quarterId: [requiredRule()],
				accrualDate: [requiredRule()],
				paymentDate: [requiredRule()],
				accountedDate: [],
				accruedAmount: [requiredRule()],
				comment: []
			}
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			isDictionariesLoading: state => state.isDictionariesLoading,
			addItemsParameters: state => state.addItemsParameters,
			extraRateParameters: state => state.extraRateParameters,
			massUpdateParameters: state => state.massUpdateParameters,
			mode: state => state.mode,
			quarters: state => state.quarters,
			borrowers: state => state.borrowers,
			financeSources: state => state.financeSources,
			accrualTypes: state => state.accrualTypes,
			editableItems: state => state.editableItems,
			savingItems: state => state.savingItems,
			isItemDeleting: state => state.isItemDeleting,
			agreement: state => state.agreement,
			totalAmount: state => state.totalAmount
		}),
		...mapGetters({
			formattedItems: getterTypes.formattedItems
		}),
		headers() {
			return [
				{
					text: this.$t("tables.accrualType"),
					value: "accrualTypeId",
					class: "text-caption",
					width: "11%",
					sortable: false,
					sort: (a, b) => this.financeSources.find(x => x.id === a).name.localeCompare(this.financeSources.find(x => x.id === b).name)
				},
				{
					text: this.$t("tables.financeSource"),
					value: "financingSourceId",
					class: "text-caption",
					width: "11%",
					sortable: false,
					sort: (a, b) => this.financeSources.find(x => x.id === a).name.localeCompare(this.financeSources.find(x => x.id === b).name)
				},
				{
					text: this.$t("tables.quarter"),
					value: "quarterId",
					class: "text-caption",
					width: "7%",
					sortable: false,
					sort: (a, b) => convertIsoToNumber(this.quarters.find(x => x.id === a).startDate) - convertIsoToNumber(this.quarters.find(x => x.id === b).startDate)
				},
				{
					text: this.$t("tables.accrualDate"),
					value: "accrualDate",
					class: "text-caption",
					width: "9%",
					sortable: false
				},
				{
					text: this.$t("tables.paymentDate"),
					value: "paymentDate",
					class: "text-caption",
					width: "12%",
					sortable: false
				},
				{
					text: this.$t("tables.accountedDate"),
					value: "accountedDate",
					class: "text-caption",
					width: "12%",
					sortable: false
				},
				{
					text: this.$t("tables.accruedAmountSum"),
					value: "accruedAmount",
					class: "text-caption",
					width: "12%",
					sortable: false
				},
				{
					text: this.$t("tables.comment"),
					value: "comment",
					class: "text-caption",
					width: "15%",
					sortable: false
				},
				{
					text: "",
					value: "action1",
					class: "text-caption",
					width: "1%",
					sortable: false
				},
				{
					text: "",
					value: "action2",
					class: "text-caption",
					width: "1%",
					sortable: false
				}
			];
		},
		addItemsParametersAccrualTypeId: {
			get() {
				return this.addItemsParameters.accrualTypeId;
			},
			set(value) {
				this.setAddItemsParametersAccrualTypeId(value);
			}
		},
		addItemsParametersFinancingSourceId: {
			get() {
				return this.addItemsParameters.financingSourceId;
			},
			set(value) {
				this.setAddItemsParametersFinancingSourceId(value);
			}
		},
		addItemsParametersQuarterId: {
			get() {
				return this.addItemsParameters.quarterId;
			},
			set(value) {
				this.setAddItemsParametersQuarterId(value);
			}
		},
		addItemsParametersAccrualDatesRange: {
			get() {
				return [this.addItemsParameters.accrualStartDate, this.addItemsParameters.accrualEndDate];
			},
			set([startDate, endDate]) {
				this.setAddItemsParametersAccrualStartDate(startDate);
				this.setAddItemsParametersAccrualEndDate(endDate);
			}
		},
		addItemsParametersPaymentDate: {
			get() {
				return this.addItemsParameters.paymentDate;
			},
			set(value) {
				this.setAddItemsParametersPaymentDate(value);
			}
		},
		addItemsParametersAccruedAmount: {
			get() {
				return this.addItemsParameters.accruedAmount;
			},
			set(value) {
				this.setAddItemsParametersAccruedAmount(value);
			}
		},
		accrualDatesRange: {
			get() {
				return [this.internalFilterValues.accrualStartDate, this.internalFilterValues.accrualEndDate];
			},
			set([startDate, endDate]) {
				this.internalFilterValues.accrualStartDate = startDate;
				this.internalFilterValues.accrualEndDate = endDate;
			}
		},
		paymentDatesRange: {
			get() {
				return [this.internalFilterValues.paymentStartDate, this.internalFilterValues.paymentEndDate];
			},
			set([startDate, endDate]) {
				this.internalFilterValues.paymentStartDate = startDate;
				this.internalFilterValues.paymentEndDate = endDate;
			}
		},
		accountedDatesRange: {
			get() {
				return [this.internalFilterValues.accountedStartDate, this.internalFilterValues.accountedEndDate];
			},
			set([startDate, endDate]) {
				this.internalFilterValues.accountedStartDate = startDate;
				this.internalFilterValues.accountedEndDate = endDate;
			}
		},
		extraRateParametersAccrualDatesRange: {
			get() {
				return [this.extraRateParameters.accrualStartDate, this.extraRateParameters.accrualEndDate];
			},
			set([startDate, endDate]) {
				this.setExtraRateParametersAccrualStartDate(startDate);
				this.setExtraRateParametersAccrualEndDate(endDate);
			}
		},
		extraRateParametersExtraInterestRate: {
			get() {
				return this.extraRateParameters.extraInterestRate;
			},
			set(value) {
				this.setExtraRateParametersExtraInterestRate(value);
			}
		},
		extraRateParametersPaymentDate: {
			get() {
				return this.extraRateParameters.paymentDate;
			},
			set(value) {
				this.setExtraRateParametersPaymentDate(value);
			}
		},
		filter() {
			return {
				accrualStartDate: this.internalFilterValues.accrualStartDate,
				accrualEndDate: this.internalFilterValues.accrualEndDate,
				accrualType: this.internalFilterValues.accrualType,
				paymentStartDate: this.internalFilterValues.paymentStartDate,
				paymentEndDate: this.internalFilterValues.paymentEndDate,
				accountedStartDate: this.internalFilterValues.accountedStartDate,
				accountedEndDate: this.internalFilterValues.accountedEndDate,
				financingSourceId: this.internalFilterValues.financingSourceId,
				quarterId: this.internalFilterValues.quarterId
			};
		},
		isFilterChanged() {
			return !isEqual(Object.fromEntries(Object.entries(this.internalFilterValues).map(([k, v]) => [k, v === null ? "" : v])),
				assign({}, this.filterValues));
		},
		isFilterEmpty() {
			return Object.values(this.filterValues).every(x => !x);
		},
		massUpdateParametersAccrualTypeId: {
			get() {
				return this.massUpdateParameters.accrualTypeId;
			},
			set(value) {
				this.setMassUpdateParametersAccrualTypeId(value);
			}
		},
		massUpdateParametersAccrualTypeFilterEnabled: {
			get() {
				return this.massUpdateParameters.accrualTypeFilterEnabled;
			},
			set(value) {
				this.setMassUpdateParametersAccrualTypeFilterEnabled(value);
			}
		},
		massUpdateParametersFinanceSourceId: {
			get() {
				return this.massUpdateParameters.financeSourceId;
			},
			set(value) {
				this.setMassUpdateParametersFinanceSourceId(value);
			}
		},
		massUpdateParametersSourceFilterEnabled: {
			get() {
				return this.massUpdateParameters.sourceFilterEnabled;
			},
			set(value) {
				this.setMassUpdateParametersSourceFilterEnabled(value);
			}
		},
		massUpdateParametersQuarterId: {
			get() {
				return this.massUpdateParameters.quarterId;
			},
			set(value) {
				this.setMassUpdateParametersQuarterId(value);
			}
		},
		massUpdateParametersQuarterFilterEnabled: {
			get() {
				return this.massUpdateParameters.quarterFilterEnabled;
			},
			set(value) {
				this.setMassUpdateParametersQuarterFilterEnabled(value);
			}
		},
		massUpdateParametersAccrualDatesRange: {
			get() {
				return [this.massUpdateParameters.accrualStartDate, this.massUpdateParameters.accrualEndDate];
			},
			set([startDate, endDate]) {
				this.setMassUpdateParametersAccrualStartDate(startDate);
				this.setMassUpdateParametersAccrualEndDate(endDate);
			}
		},
		massUpdateParametersPaymentDate: {
			get() {
				return this.massUpdateParameters.paymentDate;
			},
			set(value) {
				this.setMassUpdateParametersPaymentDate(value);
			}
		},
		massUpdateParametersPaymentDateSettingEnabled: {
			get() {
				return this.massUpdateParameters.paymentDateSettingEnabled;
			},
			set(value) {
				this.setMassUpdateParametersPaymentDateSettingEnabled(value);
			}
		},
		massUpdateParametersAccruedAmount: {
			get() {
				return this.massUpdateParameters.accruedAmount;
			},
			set(value) {
				this.setMassUpdateParametersAccruedAmount(value);
			}
		},
		massUpdateParametersAccruedAmountSettingEnabled: {
			get() {
				return this.massUpdateParameters.accruedAmountSettingEnabled;
			},
			set(value) {
				this.setMassUpdateParametersAccruedAmountSettingEnabled(value);
			}
		}
	},
	methods: {
		...mapActions({
			initializeStore: actionTypes.initialize,
			addItems: actionTypes.addItems,
			updateItem: actionTypes.updateItem,
			deleteItem: actionTypes.deleteItem,
			submitExtraRateParameters: actionTypes.submitExtraRateParameters,
			massUpdate: actionTypes.massUpdate
		}),
		...mapMutations({
			addEditableItem: mutationTypes.ADD_EDITABLE_ITEM,
			resetAddItemsParameters: mutationTypes.RESET_ADD_ITEMS_PARAMETERS,
			removeEditableItem: mutationTypes.REMOVE_EDITABLE_ITEM,
			setAddItemsParametersAccrualTypeId: mutationTypes.SET_ADD_ITEMS_PARAMETERS_ACCRUAL_TYPE_ID,
			setAddItemsParametersFinancingSourceId: mutationTypes.SET_ADD_ITEMS_PARAMETERS_FINANCING_SOURCE_ID,
			setAddItemsParametersQuarterId: mutationTypes.SET_ADD_ITEMS_PARAMETERS_QUARTER_ID,
			setAddItemsParametersAccrualStartDate: mutationTypes.SET_ADD_ITEMS_PARAMETERS_ACCRUAL_START_DATE,
			setAddItemsParametersAccrualEndDate: mutationTypes.SET_ADD_ITEMS_PARAMETERS_ACCRUAL_END_DATE,
			setAddItemsParametersPaymentDate: mutationTypes.SET_ADD_ITEMS_PARAMETERS_PAYMENT_DATE,
			setAddItemsParametersAccruedAmount: mutationTypes.SET_ADD_ITEMS_PARAMETERS_ACCRUED_AMOUNT,
			resetFilter: mutationTypes.RESET_FILTER,
			setFilterAccrualStartDate: mutationTypes.SET_FILTER_ACCRUAL_START_DATE,
			setFilterAccrualEndDate: mutationTypes.SET_FILTER_ACCRUAL_END_DATE,
			setFilterAccrualType: mutationTypes.SET_FILTER_ACCRUAL_TYPE,
			setFilterPaymentStartDate: mutationTypes.SET_FILTER_PAYMENT_START_DATE,
			setFilterPaymentEndDate: mutationTypes.SET_FILTER_PAYMENT_END_DATE,
			setFilterAccountedStartDate: mutationTypes.SET_FILTER_ACCOUNTED_START_DATE,
			setFilterAccountedEndDate: mutationTypes.SET_FILTER_ACCOUNTED_END_DATE,
			setFilterFinancingSourceId: mutationTypes.SET_FILTER_FINANCING_SOURCE_ID,
			setFilterQuarterId: mutationTypes.SET_FILTER_QUARTER_ID,
			resetExtraRateParameters: mutationTypes.RESET_EXTRA_RATE_PARAMETERS,
			setExtraRateParametersAccrualStartDate: mutationTypes.SET_EXTRA_RATE_PARAMETERS_ACCRUAL_START_DATE,
			setExtraRateParametersAccrualEndDate: mutationTypes.SET_EXTRA_RATE_PARAMETERS_ACCRUAL_END_DATE,
			setExtraRateParametersExtraInterestRate: mutationTypes.SET_EXTRA_RATE_PARAMETERS_EXTRA_INTEREST_RATE,
			setExtraRateParametersPaymentDate: mutationTypes.SET_EXTRA_RATE_PARAMETERS_PAYMENT_DATE,
			resetMassUpdateParameters: mutationTypes.RESET_MASS_UPDATE_PARAMETERS,
			setMassUpdateParametersAccrualTypeId: mutationTypes.SET_MASS_UPDATE_PARAMETERS_ACCRUAL_TYPE_ID,
			setMassUpdateParametersAccrualTypeFilterEnabled: mutationTypes.SET_MASS_UPDATE_PARAMETERS_ACCRUAL_TYPE_FILTER_ENABLED,
			setMassUpdateParametersFinanceSourceId: mutationTypes.SET_MASS_UPDATE_PARAMETERS_FINANCE_SOURCE_ID,
			setMassUpdateParametersSourceFilterEnabled: mutationTypes.SET_MASS_UPDATE_PARAMETERS_SOURCE_FILTER_ENABLED,
			setMassUpdateParametersQuarterId: mutationTypes.SET_MASS_UPDATE_PARAMETERS_QUARTER_ID,
			setMassUpdateParametersQuarterFilterEnabled: mutationTypes.SET_MASS_UPDATE_PARAMETERS_QUARTER_FILTER_ENABLED,
			setMassUpdateParametersAccrualStartDate: mutationTypes.SET_MASS_UPDATE_PARAMETERS_ACCRUAL_START_DATE,
			setMassUpdateParametersAccrualEndDate: mutationTypes.SET_MASS_UPDATE_PARAMETERS_ACCRUAL_END_DATE,
			setMassUpdateParametersPaymentDate: mutationTypes.SET_MASS_UPDATE_PARAMETERS_PAYMENT_DATE,
			setMassUpdateParametersPaymentDateSettingEnabled: mutationTypes.SET_MASS_UPDATE_PARAMETERS_PAYMENT_DATE_SETTING_ENABLED,
			setMassUpdateParametersAccruedAmount: mutationTypes.SET_MASS_UPDATE_PARAMETERS_ACCRUED_AMOUNT,
			setMassUpdateParametersAccruedAmountSettingEnabled: mutationTypes.SET_MASS_UPDATE_PARAMETERS_ACCRUED_AMOUNT_SETTING_ENABLED
		}),
		getSum(items) {
			return items.map(x => x.isActive && x.accruedAmount).reduce((x, sum) => sum += +x, 0);
		},
		checkIsItemEditable(item) {
			return this.editableItems.some(x => x.id === item.id);
		},
		async handleUpdateItem(item) {
			await this.updateItem(item);
			this.removeEditableItem(item);
		},
		handleCancelItemEditing(item) {
			this.removeEditableItem(item);
		},
		handleOpenDeleteItemDialog(item) {
			this.isDeleteItemDialogOpened = true;
			this.itemToDelete = item;
		},
		async handleDeleteItem() {
			await this.deleteItem({ item: this.itemToDelete });
			this.itemToDelete = null;
			this.isDeleteItemDialogOpened = false;
		},
		async handleAddItems() {
			await this.addItems();
			this.resetAddItems();
		},
		resetAddItems() {
			this.resetAddItemsParameters();
			this.$refs[this.refs.form].resetValidation();
			this.isAddItemsParametersDialogOpened = false;
		},
		async handleSubmitExtraRateParameters() {
			await this.submitExtraRateParameters();
			this.resetExtraRate();
		},
		resetExtraRate() {
			this.resetExtraRateParameters();
			this.$refs.extraRateForm.resetValidation();
			this.isExtraRateDialogOpened = false;
		},
		async handleMassUpdateItems() {
			await this.massUpdate();
			this.$refs.massUpdateForm.resetValidation();
			this.isMassUpdateDialogOpened = false;
		},
		async handleCancelMassUpdateItems() {
			await this.resetMassUpdateParameters();
			this.$refs.massUpdateForm.resetValidation();
			this.isMassUpdateDialogOpened = false;
		},
		setInternalFilterValues() {
			Object.keys(this.internalFilterValues).forEach(key => this.internalFilterValues[key] = this.filterValues[key]);
		},
		applyFilter() {
			this.setFilterAccrualStartDate(this.internalFilterValues.accrualStartDate);
			this.setFilterAccrualEndDate(this.internalFilterValues.accrualEndDate);
			this.setFilterAccrualType(this.internalFilterValues.accrualType || "");
			this.setFilterPaymentStartDate(this.internalFilterValues.paymentStartDate);
			this.setFilterPaymentEndDate(this.internalFilterValues.paymentEndDate);
			this.setFilterAccountedStartDate(this.internalFilterValues.accountedStartDate);
			this.setFilterAccountedEndDate(this.internalFilterValues.accountedEndDate);
			this.setFilterFinancingSourceId(this.internalFilterValues.financingSourceId || "");
			this.setFilterQuarterId(this.internalFilterValues.quarterId || "");
		},
		checkIsItemValid(item) {
			return Object.entries(this.validation).every(([key, validators]) => !item.hasOwnProperty(key) ||
				validators.every(validate => validate(item[key]) === true));
		},
		async openAgreementsAccruedInterest() {
			await this.$router.push({
				name: RouteNames.AGREEMENTS_ACCRUED_INTEREST,
				query: {
					filterApplicationNumber: this.borrowers.find(x => x.projectId === +this.$route.params.projectId).applicationNumber
				}
			});

			location.reload();
		}
	},
	created() {
		this.initializeStore();
	},
	components: {
		LoanContentLayout,
		FrpPagination,
		FrpRangeDateField,
		FrpDialog,
		FrpBtn,
		FrpDateField,
		FrpIcon,
		FrpTextField,
		FrpAutocomplete,
		LoanAccruedInterestLoader
	}
};
</script>
<style lang="scss">
.table-header {
	height: 48px;
	width: 100%;
	background-color: var(--v-grey-darken4) !important;
}
</style>

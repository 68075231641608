<template>
	<v-row class="px-6">
		<v-col class="d-flex justify-center align-center" style="gap: 24px">
			<frp-autocomplete :items="periods"
							  item-text="periodName"
							  item-value="id"
							  color="blue"
							  v-model="internalFilterValues.periodId"
							  style="width: 250px"
							  hide-details
							  :disabled="state.isOnlySelectedShown || isInterestAccrueLoading"
							  required
							  :placeholder="$t('fields.period.additionPlaceholder')">
			</frp-autocomplete>
			
			<frp-text-field :placeholder="$t('fields.projectNumber.placeholder')"
							v-model="internalFilterValues.applicationNumber"
							:disabled="state.isOnlySelectedShown || isInterestAccrueLoading"
							hide-details>
			</frp-text-field>
			
			<frp-text-field :placeholder="$t('fields.organization.label')"
							v-model="internalFilterValues.companyName"
							:disabled="state.isOnlySelectedShown || isInterestAccrueLoading"
							hide-details>
			</frp-text-field>
			
			<frp-autocomplete :items="agreementStatuses.map(x => ({ ...x, id: x.id.toString() }))"
							  item-text="name"
							  item-value="id"
							  color="blue"
							  v-model="internalFilterValues.agreementStatusId"
							  :disabled="state.isOnlySelectedShown || isInterestAccrueLoading"
							  hide-details
							  clearable
							  :placeholder="$t('fields.agreementStatus.placeholder')">
			</frp-autocomplete>
			
			<div class="d-flex align-center">
				<span class="text-caption mr-2" style="white-space: nowrap">{{ $t("fields.daysByPeriod.label") }}</span>
				
				<frp-day-autocomplete v-model="internalFilterValues.daysMin" :disabled="state.isOnlySelectedShown">
				</frp-day-autocomplete>
				
				<span class="primary--text mx-1">-</span>
				
				<frp-day-autocomplete v-model="internalFilterValues.daysMax" :disabled="state.isOnlySelectedShown">
				</frp-day-autocomplete>
			</div>
			
			<v-spacer></v-spacer>
			
			<span class="text-caption">{{ $t("fields.allSelected.label") }}</span>
			<loan-boolean-switch v-model="isOnlySelectedShown"
								 :disabled="isSelectedItemsEmpty || isInterestAccrueLoading"></loan-boolean-switch>
			
			<div class="align-self-end d-flex flex-nowrap">
				<frp-btn color="primary"
						 @click="resetFilter"
						 :disabled="isFilterEmpty && !isFilterChanged || isInterestAccrueLoading"
						 outlined>
					{{ $t("buttons.clearAll") }}
				</frp-btn>
				<frp-btn elevation="0"
						 color="blue"
						 dark
						 :disabled="!isFilterChanged"
						 @click="applyFilter">
					{{ $t("buttons.accept") }}
				</frp-btn>
			</div>
		</v-col>
	</v-row>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpAutocomplete from "@/components/fields/FrpAutocomplete.vue";
import FrpDayAutocomplete from "@/components/fields/FrpDayAutocomplete.vue";
import FrpTextField from "@/components/fields/FrpTextField.vue";
import LoanBooleanSwitch from "@/components/fields/LoanBooleanSwitch.vue";
import { DAYS_MAX, DAYS_MIN } from "@/constants/periodDays";
import { listMixin } from "@/mixins/listMixin";
import { namespace, getterTypes, mutationTypes, actionTypes } from "Store/loan/modules/agreementsAccruedInterest/types";
import { assign, isEqual } from "lodash";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [listMixin],
	props: {
		isInterestAccruing: {
			type: Boolean,
			default: false
		},
		isSubmitToAccounting: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			namespace,
			DAYS_MIN,
			DAYS_MAX,
			internalFilterValues: {
				periodId: "",
				applicationNumber: "",
				companyName: "",
				agreementStatusId: "",
				daysMin: DAYS_MIN,
				daysMax: DAYS_MAX
			}
		};
	},
	computed: {
		...mapState({
			state: state => state,
			periods: state => state.periods,
			interestFilter: state => state.filter,
			agreementStatuses: state => state.agreementStatuses
		}),
		...mapGetters({
			nearestPeriod: getterTypes.nearestPeriod,
			filteredItems: getterTypes.filteredItems,
			formattedFilteredItems: getterTypes.formattedFilteredItems,
			isSelectedItemsEmpty: getterTypes.isSelectedItemsEmpty,
			selectedItemsLength: getterTypes.selectedItemsLength
		}),
		filter() {
			return {
				periodId: this.internalFilterValues.periodId,
				applicationNumber: this.internalFilterValues.applicationNumber,
				companyName: this.internalFilterValues.companyName,
				agreementStatusId: this.internalFilterValues.agreementStatusId,
				daysMin: this.internalFilterValues.daysMin,
				daysMax: this.internalFilterValues.daysMax
			};
		},
		isFilterChanged() {
			return !isEqual(Object.fromEntries(Object.entries(this.internalFilterValues).map(([k, v]) => [k, v || ""])),
				assign({}, this.filterValues));
		},
		isFilterEmpty() {
			const isEqualFilterValues = this.filterValues.periodId === this.nearestPeriod.id && this.filterValues.daysMin === DAYS_MIN &&
				this.filterValues.daysMax === "31";
			return Object.values(this.filterValues).slice(1, 4).every(x => !x) && isEqualFilterValues;
		},
		isInterestAccrueLoading() {
			return this.isInterestAccruing || this.isSubmitToAccounting;
		},
		isOnlySelectedShown: {
			get() {
				return this.state.isOnlySelectedShown;
			},
			set(value) {
				this.handleSetIsOnlySelectedShown(value);
			}
		}
	},
	methods: {
		...mapMutations({
			setFilterPeriodId: mutationTypes.SET_FILTER_PERIOD_ID,
			setIsFilterPeriodIdChanged: mutationTypes.SET_IS_FILTER_PERIOD_ID_CHANGED,
			setFilterApplicationNumber: mutationTypes.SET_FILTER_APPLICATION_NUMBER,
			setFilterCompanyName: mutationTypes.SET_FILTER_COMPANY_NAME,
			setFilterAgreementStatusId: mutationTypes.SET_FILTER_AGREEMENT_STATUS_ID,
			setFilterDaysMin: mutationTypes.SET_FILTER_DAYS_MIN,
			setFilterDaysMax: mutationTypes.SET_FILTER_DAYS_MAX,
			setListingItemIsSelected: mutationTypes.SET_LISTING_ITEM_IS_SELECTED,
			setIsOnlySelectedShown: mutationTypes.SET_IS_ONLY_SELECTED_SHOWN
		}),
		handleSetIsOnlySelectedShown(value) {
			if(value)
				this.resetFilter({ changePeriodIdFilter: true });
			
			this.setIsOnlySelectedShown(value);
		},
		resetFilter({ changePeriodIdFilter = false }) {
			let isEqualPeriodIdFilter = this.internalFilterValues.periodId === this.nearestPeriod.id;
			if(!isEqualPeriodIdFilter && !changePeriodIdFilter)
				this.internalFilterValues.periodId = this.nearestPeriod.id;
			
			this.internalFilterValues.applicationNumber = "";
			this.internalFilterValues.companyName = "";
			this.internalFilterValues.agreementStatusId = "";
			this.internalFilterValues.daysMin = DAYS_MIN;
			this.internalFilterValues.daysMax = DAYS_MAX;
			this.applyFilter();
		},
		setInternalFilterValues() {
			this.internalFilterValues.periodId = this.filterValues.periodId;
			this.internalFilterValues.applicationNumber = this.filterValues.applicationNumber;
			this.internalFilterValues.companyName = this.filterValues.companyName;
			this.internalFilterValues.agreementStatusId = this.filterValues.agreementStatusId;
			this.internalFilterValues.daysMin = this.filterValues.daysMin;
			this.internalFilterValues.daysMax = this.filterValues.daysMax;
		},
		applyFilter() {
			if(this.filterValues.periodId !== this.internalFilterValues.periodId) {
				this.setFilterPeriodId(this.internalFilterValues.periodId);
				this.items.forEach(x => {
					this.setListingItemIsSelected({ id: x.projectId, value: false });
				});
				this.setIsOnlySelectedShown(false);
				this.setIsFilterPeriodIdChanged(true);
			}
			this.setFilterApplicationNumber(this.internalFilterValues.applicationNumber);
			this.setFilterCompanyName(this.internalFilterValues.companyName);
			this.setFilterAgreementStatusId(this.internalFilterValues.agreementStatusId || "");
			this.setFilterDaysMin(this.internalFilterValues.daysMin || DAYS_MIN);
			this.setFilterDaysMax(this.internalFilterValues.daysMax || DAYS_MAX);
		}
		
	},
	components: { FrpTextField, FrpAutocomplete, FrpDayAutocomplete, LoanBooleanSwitch, FrpBtn }
};
</script>

<style scoped>

</style>

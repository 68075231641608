import { difference } from "@/utils/difference";
import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import { parseArrayParameter } from "@/utils/query";
import AgreementsAccruedPenaltiesRouteQuery
	from "@/store/loan/modules/agreementsAccruedPenalties/types/agreementsAccruedPenaltiesRouteQuery";
import AgreementsAccruedPenaltiesState from "@/store/loan/modules/agreementsAccruedPenalties/types/agreementsAccruedPenaltiesState";

export default class AgreementsAccruedPenaltiesRouteQueryService {
	defaultRouteQuery: AgreementsAccruedPenaltiesRouteQuery;

	constructor(defaultRouteQuery: AgreementsAccruedPenaltiesRouteQuery) {
		this.defaultRouteQuery = defaultRouteQuery;
	}

	resolveRouteQueryDictionary(state: AgreementsAccruedPenaltiesState) {
		let query = new AgreementsAccruedPenaltiesRouteQuery(
			state.paging.page,
			state.filter.projectNumber,
			state.filter.organization,
			state.filter.controller,
			state.filter.statuses
		);

		return difference(this.defaultRouteQuery, query);
	}

	async resolveRouteQuery(query: Dictionary<any>) {
		try {
			let result = plainToInstance(AgreementsAccruedPenaltiesRouteQuery, {
				...this.defaultRouteQuery,
				...query,
				statuses: parseArrayParameter(query.statuses)
			}, { enableImplicitConversion: true });

			await validateOrReject(result);

			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}
}

export default class ApiBorrower {
	companyId: number;
	projectId: number;
	shortName: string;
	applicationNumber: number;

	constructor(
		companyId: number = 0,
		projectId: number = 0,
		shortName: string = "",
		applicationNumber: number = 0
	)
	{
		this.companyId = companyId;
		this.projectId = projectId;
		this.shortName = shortName;
		this.applicationNumber = applicationNumber;
	}
}

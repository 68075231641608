<template>
	<div>
		<v-subheader v-if="label" class="pa-0 mb-2 grey--text text-caption" style="height: fit-content">
			<span>{{ label }}</span>
		</v-subheader>
		<div class="d-flex align-center">
			<span :class="{ 'grey--text text--lighten-3': innerValue, 'blue--text': !innerValue }">{{ options[0].text }}</span>
			<v-switch class="loan-switch single-color-switch mt-0"
					  color="blue"
					  :disabled="disabled || !options[1]"
					  v-model="innerValue"
					  hide-details>
			</v-switch>
			<span v-if="options[1]" :class="{ 'grey--text text--lighten-3': !innerValue, 'blue--text': innerValue }">
				{{ options[1].text }}
			</span>
		</div>
	</div>

</template>

<script>
export default {
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: [String, Number],
		disabled: Boolean,
		label: String,
		options: {
			type: Array,
			required: true,
			validator: value => (value.length === 2 || value.length === 1) &&
				value.every(x => x.hasOwnProperty("text") && x.hasOwnProperty("value"))
		}
	},
	computed: {
		innerValue: {
			get() {
				return this.value === this.options[1]?.value;
			},
			set(value) {
				this.$emit("update:value", value ? this.options[1].value : this.options[0].value);
			}
		}
	}
};
</script>

<style lang="scss">
.single-color-switch.loan-switch {
	.v-input--switch__track {
		color: var(--v-blue-base) !important;
	}
}
</style>

import { difference } from "@/utils/difference";
import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import CashFlowRouteQuery from "@/store/loan/modules/cashFlow/types/cashFlowRouteQuery";
import CashFlowState from "@/store/loan/modules/cashFlow/types/cashFlowState";
import { parseArrayParameter } from "@/utils/query";

export default class CashFlowRouteQueryService {
	defaultRouteQuery: CashFlowRouteQuery;

	constructor(defaultRouteQuery: CashFlowRouteQuery) {
		this.defaultRouteQuery = defaultRouteQuery;
	}

	resolveRouteQueryDictionary(state: CashFlowState) {
		let query = new CashFlowRouteQuery(
			state.paging.page,
			state.filter.paymentType,
			state.filter.startPaymentDate,
			state.filter.endPaymentDate,
			state.filter.quarterCodeIds,
			state.filter.paymentExpenseIds
		);

		return difference(this.defaultRouteQuery, query);
	}

	async resolveRouteQuery(query: Dictionary<any>) {
		try {
			let result = plainToInstance(CashFlowRouteQuery, {
				...this.defaultRouteQuery,
				...query,
				filterQuarterCodeIds: parseArrayParameter(query.filterQuarterCodeIds),
				filterPaymentExpenseIds: parseArrayParameter(query.filterPaymentExpenseIds)
			}, { enableImplicitConversion: true });

			await validateOrReject(result);

			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}
}

export default class AssignmentCompensationFilter {
	constructor(
		public assignmentPeriodStartDate: string = "",
		public assignmentPeriodEndDate: string = "",
		public assignmentAgreementId: string = "",
		public assigneeId: string = ""
	)
	{
	}
}

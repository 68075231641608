import { i18n } from "@/plugins";
import { SizeUnits } from "@/constants/sizeUnits";

function convertBytesToSize(bytes: number) {
	const units = [
		i18n.t(`aliases.sizeType.${SizeUnits.BYTE}`),
		i18n.t(`aliases.sizeType.${SizeUnits.KI_B}`),
		i18n.t(`aliases.sizeType.${SizeUnits.MI_B}`),
		i18n.t(`aliases.sizeType.${SizeUnits.GI_B}`),
		i18n.t(`aliases.sizeType.${SizeUnits.TI_B}`),
	];

	if(bytes == 0) return "n/a";
	var i = Math.floor(Math.log(bytes) / Math.log(1024));

	if(i == 0) return bytes + " " + units[i];
	return (bytes / Math.pow(1024, i)).toFixed(1) + " " + units[i];
}

function convertToArrayBuffer(file: Blob): Promise<ArrayBuffer> {
	return new Promise<ArrayBuffer>((resolve, reject) => {
		var reader = new FileReader();
		reader.readAsArrayBuffer(file);
		reader.onloadend = function () {
			resolve(reader.result as ArrayBuffer);
		};
	});
}

function convertToBase64WithHeaderRemove(file: Blob): Promise<string> {
	return new Promise<string>((resolve, reject) => {
		var header = ";base64,";
		var reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onloadend = function () {
			var resStr = reader.result as string;
			var res = resStr.substr(resStr.indexOf(header) + header.length);
			resolve(res);
		};
	});
};

export { convertBytesToSize, convertToArrayBuffer, convertToBase64WithHeaderRemove };

import IPageState from "@/store/shared/base/types/pageState";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import FormState from "@/store/shared/form/models/formState";
import Overdue from "@/store/loan/modules/loanSchedule/modules/overdues/types/overdue";
import { OverdueModeType } from "@/store/loan/modules/loanSchedule/modules/overdues/types/overdueModeType";

export default class OverduesState implements IPageState {
	constructor(
		public listing: ListingModel<Overdue>,
		public sorting: SortingModel<String>,
		public paging: PagingModel,
		public search: SearchModel,
		public form: FormState,
		public snapshot: object,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public newItem: Overdue = new Overdue(),
		public editableItems: Overdue[] = [],
		public isItemsUpdating: boolean = false,
		public isItemCreating: boolean = false,
		public isItemDeleting: boolean = false,
		public mode: OverdueModeType = OverdueModeType.READ
	)
	{
	}
}

import { addProfile, createMap, createMapper, forMember, mapFrom, MappingProfile } from "@automapper/core";
import { classes } from "@automapper/classes";
import { convertToZonedIso, convertToTimestamp } from "@/utils/dates";
import ApiVersion from "@/api/loan/types/loanSchedule/apiVersion";
import Version from "@/store/loan/modules/loanSchedule/types/version";
import ApiQuarter from "@/api/loan/types/dictionaries/apiQuarter";
import Quarter from "@/types/loan/quarter";

const mapper = createMapper({
	strategyInitializer: classes()
});

const loanScheduleProfile: MappingProfile = (mapper) => {
	createMap(mapper, ApiVersion, Version,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.number, mapFrom(x => x.number)),
		forMember(d => d.title, mapFrom(x => x.title)),
		forMember(d => d.createdAt, mapFrom(x => convertToTimestamp(x.createdAt))),
		forMember(d => d.projectId, mapFrom(x => x.projectId)),
		forMember(d => d.titleDocument, mapFrom(x => x.titleDocument)),
		forMember(d => d.documentNumber, mapFrom(x => x.documentNumber)),
		forMember(d => d.startDate, mapFrom(x => convertToTimestamp(x.startDate))),
		forMember(d => d.changeReason, mapFrom(x => x.changeReason)),
		forMember(d => d.comment, mapFrom(x => x.comment)),
		forMember(d => d.isActive, mapFrom(x => x.isActive))
	);

	createMap(mapper, Version, ApiVersion,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.number, mapFrom(x => x.number)),
		forMember(d => d.title, mapFrom(x => x.title)),
		forMember(d => d.createdAt, mapFrom(x => convertToZonedIso(x.createdAt))),
		forMember(d => d.projectId, mapFrom(x => x.projectId)),
		forMember(d => d.titleDocument, mapFrom(x => x.titleDocument)),
		forMember(d => d.documentNumber, mapFrom(x => x.documentNumber)),
		forMember(d => d.startDate, mapFrom(x => convertToZonedIso(x.startDate))),
		forMember(d => d.changeReason, mapFrom(x => x.changeReason)),
		forMember(d => d.comment, mapFrom(x => x.comment)),
		forMember(d => d.isActive, mapFrom(x => x.isActive))
	);

	createMap(mapper, ApiQuarter, Quarter,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.number, mapFrom(x => x.number)),
		forMember(d => d.title, mapFrom(x => x.title)),
		forMember(d => d.startDate, mapFrom(x => convertToTimestamp(x.startDate)))
	);

	createMap(mapper, Quarter, ApiQuarter,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.number, mapFrom(x => x.number)),
		forMember(d => d.title, mapFrom(x => x.title)),
		forMember(d => d.startDate, mapFrom(x => convertToZonedIso(x.startDate)))
	);
};

addProfile(mapper, loanScheduleProfile);

export default mapper;

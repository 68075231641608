import { convertToTimestamp } from "@/utils/dates";
import { AgreementsAccruedPenaltiesItem } from "@/store/loan/modules/agreementsAccruedPenalties/types/agreementsAccruedPenaltiesItem";
import AgreementsAccruedPenaltiesState from "@/store/loan/modules/agreementsAccruedPenalties/types/agreementsAccruedPenaltiesState";
import { ApiAgreementsAccruedPenaltiesItem } from "@/api/loan/types/loanInterest/apiAgreementsAccruedPenaltiesItem";
import ApiGetAgreementsAccruedPenaltiesParameters from "@/api/loan/types/loanInterest/apiGetAgreementsAccruedPenaltiesParameters";

export default class AgreementsAccruedPenaltiesMapperProfile {
	constructor() {
	}

	mapToAgreementsAccruedPenaltiesItem(source: ApiAgreementsAccruedPenaltiesItem): AgreementsAccruedPenaltiesItem {
		return {
			...source,
			paymentDate: convertToTimestamp(source.paymentDate) as number,
			projectNumber: source.project.applicationNumber,
			organizationName: source.project.company.shortName,
			status: source.project.agreement.status.name,
			id: ""
		};
	}

	mapToApiGetAgreementsAccruedPenaltiesParameters(source: AgreementsAccruedPenaltiesState): ApiGetAgreementsAccruedPenaltiesParameters {
		return {
			...source.filter
		};
	}
}

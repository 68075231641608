import { stringifyQuery } from "@/utils/query";
import FrpInternalServerError from "@/views/errors/FrpInternalServerError.vue";
import FrpAccessDeniedError from "@/views/errors/FrpAccessDeniedError.vue";
import FrpPageNotFound from "@/views/errors/FrpPageNotFound.vue";
import FrpSilentRenew from "@/views/FrpSilentRenew.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import LoanLayout from "@/views/layouts/LoanLayout.vue";
import FrpEmptyLayout from "@/views/layouts/FrpEmptyLayout.vue";
import LoanSchedule from "@/views/loan/loanSchedule/LoanSchedule.vue";
import LoanAccruedInterest from "@/views/loan/accruedInterest/LoanAccruedInterest.vue";
import LoanOverdueInterest from "@/views/loan/overdueInterest/LoanOverdueInterest.vue";
import LoanAgreementsAccruedInterest from "@/views/loan/agreementAccruedInterest/LoanAgreementsAccruedInterest.vue";
import LoanCashFlow from "@/views/loan/cashFlow/LoanCashFlow.vue";
import LoanTreasuryStatement from "@/views/loan/treasuryStatement/LoanTreasuryStatement.vue";
import LoanTroubledBorrower from "@/views/loan/troubledBorrower/LoanTroubledBorrower.vue";
import LoanTroubledBorrowers from "@/views/loan/troubledBorrowers/LoanTroubledBorrowers.vue";
import FrpCallback from "@/views/FrpCallback.vue";
import { RouteNames } from "@/router/loan/routes";
import { configureMiddleware } from "@/router/loan/middleware";
import LoanReports from "@/views/loan/reports/LoanReports.vue";
import LoanReportsFinal from "@/views/loan/reportsFinal/LoanReportsFinal.vue";
import LoanUpcomingPayments from "@/views/loan/upcomingPayments/LoanUpcomingPayments.vue";
import LoanAgreementsOverduePayments from "@/views/loan/agreementsOverduePayments/LoanAgreementsOverduePayments.vue";
import LoanAgreementsAccruedPenalties from "@/views/loan/agreementsAccruedPenalties/LoanAgreementsAccruedPenalties.vue";
import LoanAssignmentCompensation from "@/views/loan/assignmentCompensation/LoanAssignmentCompensation.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/callback.html",
		component: FrpCallback,
		meta: { public: true },
		name: RouteNames.CALLBACK
	},
	{
		path: "/silent-renew.html",
		component: FrpSilentRenew,
		meta: { public: true },
		name: RouteNames.SILENT_RENEW
	},
	{
		path: "/",
		component: LoanLayout,
		name: RouteNames.ROOT,
		children: [
			{
				path: "/loan-refundment-schedule/versions/project/:projectId",
				component: LoanSchedule,
				name: RouteNames.LOAN_SCHEDULE
			},
			{
				path: "/loan-refundment/accrued-interest/project/:projectId",
				component: LoanAccruedInterest,
				name: RouteNames.ACCRUED_INTEREST
			},
			{
				path: "/loan-refundment/overdue-interest/project/:projectId",
				component: LoanOverdueInterest,
				name: RouteNames.OVERDUE_INTEREST
			},
			{
				path: "/loan-refundment/agreements-accrued-interest",
				component: LoanAgreementsAccruedInterest,
				name: RouteNames.AGREEMENTS_ACCRUED_INTEREST
			},
			{
				path: "/loan-refundment/cashflow-registry/project/:projectId",
				component: LoanCashFlow,
				name: RouteNames.CASH_FLOW
			},
			{
				path: "/loan-refundment/treasury-statement",
				component: LoanTreasuryStatement,
				name: RouteNames.TREASURY_STATEMENT
			},
			{
				path: "/problemloaner/project/:projectId",
				component: LoanTroubledBorrower,
				name: RouteNames.TROUBLED_BORROWER
			},
			{
				path: "/problemloaners",
				component: LoanTroubledBorrowers,
				name: RouteNames.TROUBLED_BORROWERS
			},
			{
				path: "/projects/project/:projectId/reporting/:companyId?/:year?/:quarter?",
				component: LoanReports,
				name: RouteNames.REPORTS
			},
			{
				path: "/projects/project/:projectId/reportingfinal",
				component: LoanReportsFinal,
				name: RouteNames.REPORTS_FINAL
			},
			{
				path: "/projects/project/:projectId/upcoming-payments",
				component: LoanUpcomingPayments,
				name: RouteNames.UPCOMING_PAYMENTS
			},
			{
				path: "/loan-refundment/agreements-overdue-payments",
				component: LoanAgreementsOverduePayments,
				name: RouteNames.AGREEMENTS_OVERDUE_PAYMENTS
			},
			{
				path: "/loan-refundment/agreements-accrued-penalties",
				component: LoanAgreementsAccruedPenalties,
				name: RouteNames.AGREEMENTS_ACCRUED_PENALTIES
			},
			{
				path: "/loan-refundment/assignment-compensation",
				component: LoanAssignmentCompensation,
				name: RouteNames.ASSIGNMENT_COMPENSATION
			}
		]
	},
	{
		path: "/",
		component: FrpEmptyLayout,
		meta: { public: true },
		children: [
			{
				path: "internal-server-error",
				component: FrpInternalServerError,
				name: RouteNames.ERROR_INTERNAL_SERVER_ERROR
			},
			{
				path: "access-denied",
				component: FrpAccessDeniedError,
				name: RouteNames.ERROR_ACCESS_DENIED
			},
			{
				path: "*",
				component: FrpPageNotFound,
				name: RouteNames.ERROR_PAGE_NOT_FOUND
			}
		]
	}
];

const router = new VueRouter({
	routes,
	mode: "history",
	stringifyQuery: query => {
		// @ts-ignore
		let result = stringifyQuery(query);
		return result ? ("?" + result) : "";
	}
});

configureMiddleware(router);

export default router;

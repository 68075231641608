import { mutationTypes, actionTypes, getterTypes, namespace } from "@/store/loan/modules/loanSchedule/modules/overdues/types";
import loanScheduleTypes from "@/store/loan/modules/loanSchedule/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import OverduesState from "@/store/loan/modules/loanSchedule/modules/overdues/types/overduesState";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import AbortService from "@/services/abortService";
import { LoanScheduleController } from "@/api/loan/loanSchedule";
import FormMixinBuilder from "@/store/shared/form";
import ListingMixinBuilder from "@/store/shared/listing";
import PagingMixinBuilder from "@/store/shared/paging";
import SortingMixinBuilder from "@/store/shared/sorting";
import SearchMixinBuilder from "@/store/shared/search";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import Overdue from "@/store/loan/modules/loanSchedule/modules/overdues/types/overdue";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import LoanScheduleState from "@/store/loan/modules/loanSchedule/types/loanScheduleState";
import storeManager from "@/store/manager";
import mapper from "@/store/loan/modules/loanSchedule/modules/overdues/mapper";
import { ScheduleModeType } from "@/store/loan/modules/loanSchedule/modules/scheduleItems/types/scheduleModeType";
import { cloneDeep } from "lodash";
import ApiOverdue from "@/api/loan/types/loanSchedule/apiOverdue";
import { plainToInstance } from "class-transformer";
import { OverdueModeType } from "@/store/loan/modules/loanSchedule/modules/overdues/types/overdueModeType";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";

const abortService = new AbortService();
const loanScheduleController = new LoanScheduleController(abortService);

const baseMixin = (new BaseMixinBuilder(abortService)).build();
const formMixin = (new FormMixinBuilder()).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["newItem"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new OverduesState(
			new ListingModel<Overdue>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String>({
				type: "startDate",
				order: sortingOrderType.ascending
			}),
			new PagingModel({
				total: 0,
				page: 1,
				pageSize: 25
			}),
			new SearchModel({
				query: ""
			}),
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<OverduesState, any>>{
	...listingMixin.getters,
	...formMixin.getters,
	...snapshotMixin.getters,
	[getterTypes.formattedItems]: state => {
		return state.listing.items.map(x => {
			return plainToInstance(Overdue, {
				...x
			});
		});
	},
	[getterTypes.version]: (state, getters, rootState) => {
		const { version } = resolveNestedState<LoanScheduleState>(rootState, storeManager.loan.loanSchedule.namespace);
		return version;
	}
};

const actions = <ActionTree<OverduesState, any>>{
	...listingMixin.actions,
	...pagingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }) {
		await dispatch(actionTypes.initializeBase);

		await dispatch(actionTypes.fetch);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
		commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
	},
	async [actionTypes.fetch]({ commit, getters }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);

		try {
			let items = getters.version.id ? await loanScheduleController.getOverdues(getters.version.id) : [];

			commit(mutationTypes.SET_LISTING_ITEMS, items.map(x => mapper.map(x, ApiOverdue, Overdue)));
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			console.error(error);
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.updateItems]({ commit, getters }) {
		commit(mutationTypes.SET_IS_ITEMS_UPDATING, true);

		try {
			await loanScheduleController.updateOverdues(getters.version.id,
				state.editableItems.map(x => mapper.map(x, Overdue, ApiOverdue)));

			commit(mutationTypes.SET_LISTING_ITEMS, cloneDeep(state.editableItems));
			commit(mutationTypes.SET_MODE, ScheduleModeType.READ);
			alertService.addInfo(AlertKeys.OVERDUES_SUCCESS_SAVED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_ITEMS_UPDATING, false);
		}
	},
	async [actionTypes.createItem]({ commit, state, getters }) {
		commit(mutationTypes.SET_IS_ITEM_CREATING, true);

		try {
			const item = await loanScheduleController.createOverdue(getters.version.id, mapper.map(state.newItem, Overdue, ApiOverdue));

			alertService.addInfo(AlertKeys.OVERDUE_SUCCESS_CREATED);

			commit(mutationTypes.SET_LISTING_ITEMS, [...state.listing.items, mapper.map(item, ApiOverdue, Overdue)]);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_ITEM_CREATING, false);
		}
	},
	async [actionTypes.deleteItem]({ dispatch, commit, rootState, state, getters }, { item }) {
		commit(mutationTypes.SET_IS_ITEM_DELETING, true);

		try {
			await loanScheduleController.deleteOverdue(getters.version.id, item.id);

			alertService.addInfo(AlertKeys.OVERDUE_SUCCESS_DELETED);
			commit(mutationTypes.SET_LISTING_ITEMS, state.listing.items.filter(x => x.id !== item.id));
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_ITEM_DELETING, false);
		}
	}
};

const mutations = <MutationTree<OverduesState>>{
	...listingMixin.mutations,
	...pagingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	[mutationTypes.RESET_NEW_ITEM](state) {
		state.newItem = new Overdue();
	},
	[mutationTypes.SET_EDITABLE_ITEMS](state, value) {
		state.editableItems = value;
	},
	[mutationTypes.SET_IS_ITEMS_UPDATING](state, value) {
		state.isItemsUpdating = value;
	},
	[mutationTypes.SET_IS_ITEM_CREATING](state, value) {
		state.isItemCreating = value;
	},
	[mutationTypes.SET_IS_ITEM_DELETING](state, value) {
		state.isItemDeleting = value;
	},
	[mutationTypes.SET_NEW_ITEM_SOURCE](state, value) {
		state.newItem.source = value;
	},
	[mutationTypes.SET_NEW_ITEM_START_DATE](state, value) {
		state.newItem.startDate = value;
	},
	[mutationTypes.SET_NEW_ITEM_QUAERTER](state, value) {
		state.newItem.quarter = value;
	},
	[mutationTypes.SET_NEW_ITEM_AMOUNT](state, value) {
		state.newItem.amount = value;
	},
	[mutationTypes.SET_NEW_ITEM_IS_PROBLEM](state, value) {
		state.newItem.isProblem = value;
	},
	[mutationTypes.SET_NEW_ITEM_COMMENT](state, value) {
		state.newItem.comment = value;
	},
	[mutationTypes.SET_MODE](state, value) {
		state.mode = value;
	}
};

const subscribe = (store: any) => {
	const { commit, dispatch } = store;

	store.subscribe(async ({ type, payload }: any, state: any) => {
		switch (type) {
			case resolveMutation(storeManager.loan.loanSchedule.overdues.namespace, mutationTypes.SET_MODE):
			{
				const { listing: { items } } = resolveNestedState<OverduesState>(state,
					storeManager.loan.loanSchedule.overdues.namespace);

				if(payload === OverdueModeType.EDIT)
					commit(resolveMutation(storeManager.loan.loanSchedule.overdues.namespace, mutationTypes.SET_EDITABLE_ITEMS),
						cloneDeep(items));

				break;
			}

			case resolveMutation(storeManager.loan.loanSchedule.namespace, loanScheduleTypes.mutationTypes.SET_VERSION):
			{
				const { isInitialized } = resolveNestedState<OverduesState>(state,
					storeManager.loan.loanSchedule.overdues.namespace);

				if(isInitialized) {
					await dispatch(resolveAction(storeManager.loan.loanSchedule.overdues.namespace, actionTypes.fetch));
					commit(resolveMutation(storeManager.loan.loanSchedule.overdues.namespace, mutationTypes.SET_MODE),
						OverdueModeType.READ);
				}

				break;
			}

			default:
				break;
		}
	});
};

export {
	namespace, state, getters, actions, mutations, subscribe
};

const overduesModule = {
	namespace, state, getters, actions, mutations, subscribe, namespaced: true
};

export default overduesModule;

import ApiCompany from "@/api/loan/types/reports/apiCompany";
import ApiPeriod from "@/api/loan/types/reports/apiPeriod";

export default class ApiReportFilter {
	companies: ApiCompany[];
	periods: ApiPeriod[];
	defaultPeriod: ApiPeriod;
	defaultCompany: ApiCompany;

	constructor(
		companies: ApiCompany[] = [],
		periods: ApiPeriod[] = [],
		defaultPeriod: ApiPeriod = new ApiPeriod(),
		defaultCompany: ApiCompany = new ApiCompany()
	)
	{
		this.companies = companies;
		this.periods = periods;
		this.defaultPeriod = defaultPeriod;
		this.defaultCompany = defaultCompany;
	}
}

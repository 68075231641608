<template>
	<loan-content-layout v-if="isInitialized">
		<v-row>
			<v-col class="d-flex align-center pb-2 pt-4">
				<v-card-title class="text-h6 pa-0 ml-4">
					<span style="white-space: nowrap">{{ $t("titles.troubledBorrower") }}</span>
				</v-card-title>
				<frp-text-btn v-if="can(Permissions.LOAN_UPDATE)"
							  class="pa-0 ml-8 passport-text-btn"
							  :href="getGeneratePassportLink()"
							  color="blue">
					<frp-icon class="mr-2" :color="colors.blue.base" src="ico_notes"></frp-icon>
					<span>{{ $t("buttons.generatePassport") }}</span>
				</frp-text-btn>
				<v-spacer></v-spacer>
				<frp-text-btn class="pa-0 passport-text-btn"
							  :to="{ name: RouteNames.TROUBLED_BORROWERS }"
							  color="blue">
					<frp-icon class="mr-2" :color="colors.blue.base" src="ico_demography"></frp-icon>
					<span>{{ $t("buttons.troubledBorrowers") }}</span>
				</frp-text-btn>
			</v-col>
		</v-row>
		<v-row v-if="company.id">
			<v-col class="pb-4">
				<v-card elevation="0" class="loan-card px-4 py-6">
					<v-row class="px-3 blue--text text--darken-4"
						   :style="$vuetify.breakpoint.mdAndUp ? 'display: flex; align-items: center' : 'display: flex; flex-direction: column'">
						<v-col cols="auto" class="pa-0">
							<v-card-title class="text-h6 px-0 mr-10">
								<span style="white-space: nowrap">{{ company.name }}</span>
							</v-card-title>
						</v-col>

						<template v-if="company.inn && company.ogrn">
							<v-col cols="auto" class="d-flex align-center mr-10 px-0">
								<span class="text-body-2">
									<span>{{ `${$t("common.inn")} ${company.inn} / ${$t("common.ogrn")} ${company.ogrn}` }}</span>
								</span>
							</v-col>
						</template>

						<template v-if="versionHeaders.length && formattedVersion.id">
							<v-col class="d-flex align-center px-0">
								<span class="text-body-2 mr-2">
									{{ $t("content.documentVersion") }}
								</span>
								<span v-if="mode === MainInfoModeType.EDIT">{{ formattedVersion?.title }}</span>
								<loan-document-version-menu color="blue"
															:current-version="formattedVersion"
															:version-headers="formattedVersionHeaders"
															v-if="!isVersionHeadersLoading && mode === MainInfoModeType.READ"
															@update:value="fetchVersion"/>
							</v-col>
						</template>

						<v-spacer></v-spacer>
						<frp-btn v-if="can(Permissions.LOAN_CREATE)"
								 elevation="0"
								 :color="colors.blue.base"
								 :disabled="mode === MainInfoModeType.EDIT"
								 @click="isCreateVersionDialogOpened = true">
							<span class="white--text">{{ $t("buttons.createVersion") }}</span>
						</frp-btn>
					</v-row>
				</v-card>
			</v-col>
		</v-row>

		<template v-if="company.id && version.versionHeader.versionId">
			<v-row>
				<v-col class="pt-0 pb-4">
					<loan-main-info/>
				</v-col>
			</v-row>

			<v-row>
				<v-col class="pt-0 pb-4">
					<loan-security-agreements/>
				</v-col>
			</v-row>

			<v-row>
				<v-col class="pb-6">
					<loan-production-status/>
				</v-col>
			</v-row>
		</template>

		<frp-dialog v-model="isCreateVersionDialogOpened"
					max-width="568"
					persistent
					:title="$t(`dialogs.versionCreating.title`)">
			<template #content="{ onIntersect }">
				<v-form :ref="refs.form" v-model="formValid" v-intersect="onIntersect">
					<frp-text-field :label="$t('fields.version.label')"
									required
									v-model="versionName"
									:placeholder="$t('fields.version.placeholder')">
					</frp-text-field>
				</v-form>
			</template>

			<template #footer>
				<frp-btn color="primary"
						 outlined
						 @click="resetCreateVersion">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				<frp-btn class="elevation-0"
						 @click="handleCreateVersion"
						 :loading="isVersionLoading"
						 :disabled="!stateContainsUnsavedChanges || !isFormValid"
						 :color="colors.blue.base">
					<span class="white--text">{{ $t("buttons.create") }}</span>
				</frp-btn>
			</template>
		</frp-dialog>
	</loan-content-layout>
	<loan-troubled-borrower-loader v-else/>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn";
import FrpDialog from "@/components/dialogs/FrpDialog";
import FrpAutocomplete from "@/components/fields/FrpAutocomplete";
import FrpTextField from "@/components/fields/FrpTextField";
import LoanDocumentVersionMenu from "@/components/fields/LoanDocumentVersionMenu";
import LoanContentLayout from "@/components/layouts/LoanContentLayout";
import LoanMainInfo from "@/views/loan/troubledBorrower/mainInfo/LoanMainInfo";
import FrpTextBtn from "Components/buttons/FrpTextBtn";
import FrpIcon from "Components/icon/FrpIcon";
import colorsMixin from "Mixins/colorsMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import { RouteNames } from "Router/loan/routes";
import LoanSecurityAgreements from "Views/loan/troubledBorrower/securityAgreements/LoanSecurityAgreements";
import formMixin from "Mixins/formMixin";
import LoanTroubledBorrowerLoader from "Views/loan/troubledBorrower/LoanTroubledBorrowerLoader";
import { namespace, getterTypes, mutationTypes, actionTypes } from "Store/loan/modules/troubledBorrower/types";
import LoanProductionStatus from "Views/loan/troubledBorrower/productionStatus/LoanProductionStatus";
import { createNamespacedHelpers } from "vuex";
import { i18n } from "@/plugins";
import authorizationMixin from "@/mixins/authorizationMixin";
import { MainInfoModeType } from "@/store/loan/modules/troubledBorrower/modules/mainInfo/types/mainInfoModeType";
import storeManager from "Store/manager";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);
const { mapState: mainInfoMapState } = createNamespacedHelpers(storeManager.loan.troubledBorrower.mainInfo.namespace);

export default {
	mixins: [formMixin, storeModuleBasedPage, colorsMixin, authorizationMixin],
	metaInfo: {
		title: i18n.t("metaTitles.troubledBorrower")
	},
	data() {
		return {
			namespace,
			RouteNames,
			isCreateVersionDialogOpened: false,
			MainInfoModeType
		};
	},
	computed: {
		...mapState({
			state: state => state,
			isInitialized: state => state.isInitialized,
			versionHeaders: state => state.versionHeaders,
			version: state => state.version,
			company: state => state.company,
			versionName: state => state.versionName,
			isVersionLoading: state => state.isVersionLoading,
			isVersionHeadersLoading: state => state.isVersionHeadersLoading
		}),
		...mainInfoMapState({
			mode: state => state.mode
		}),
		formattedVersion() {
			return {
				id: this.version.versionHeader.versionId,
				title: this.version.versionHeader.versionName
			};
		},
		formattedVersionHeaders() {
			return this.versionHeaders.map(x => ({
				id: x.versionId,
				title: x.versionName
			}));
		},
		versionName: {
			get() {
				return this.state.versionName;
			},
			set(value) {
				this.setVersionName(value);
			}
		}
	},
	methods: {
		...mapActions({
			initializeStore: actionTypes.initialize,
			createVersion: actionTypes.createVersion,
			fetchVersion: actionTypes.fetchVersion
		}),
		...mapMutations({
			setVersionName: mutationTypes.SET_VERSION_NAME,
			resetVersionName: mutationTypes.RESET_VERSION_NAME
		}),
		async handleCreateVersion() {
			await this.createVersion();
			this.resetCreateVersion();
		},
		resetCreateVersion() {
			this.resetVersionName();
			this.$refs[this.refs.form].resetValidation();
			this.isCreateVersionDialogOpened = false;
		},
		getGeneratePassportLink() {
			return `http://reporting/ReportServer/Pages/ReportViewer.aspx?/Отчеты/ЮД/Паспорт проблемного заемщика&ogrn=${this.company.ogrn}`;
		}
	},
	created() {
		this.initializeStore();
	},
	components: {
		FrpTextBtn,
		FrpIcon,
		LoanSecurityAgreements,
		LoanTroubledBorrowerLoader,
		LoanProductionStatus,
		FrpTextField,
		FrpAutocomplete,
		FrpDialog,
		FrpBtn,
		LoanDocumentVersionMenu,
		LoanMainInfo,
		LoanContentLayout
	}
};
</script>

<style scoped lang="scss">
.passport-text-btn {
	span {
		border-bottom: 1px solid var(--v-blue-base);
	}
}
</style>

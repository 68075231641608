export default class ApiReportDocumentUploadParameters {
	projectId: string;
	companyId: string;
	year: string;
	quarter: string;
	documentType: string;
	document: File;
	signature?: string;
	calendarPlanStageId: number | null;
	keyIndicatorType: string | null;
	generatedFileHash: string | null;

	constructor(
	projectId: string,
	companyId: string,
	year: string,
	quarter: string,
	documentType: string,
	document: File,
	signature?: string,
	calendarPlanStageId: number | null = null,
	keyIndicatorType: string | null = null,
	generatedFileHash:  string | null = null,
	) {
		this.projectId = projectId;
		this.companyId = companyId;
		this.year = year;
		this.quarter = quarter;
		this.documentType = documentType;
		this.document = document;
		this.signature = signature;
		this.calendarPlanStageId = calendarPlanStageId;
		this.keyIndicatorType = keyIndicatorType;
		this.generatedFileHash = generatedFileHash;
	}
}

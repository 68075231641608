import { actionTypes, getterTypes, mutationTypes, namespace } from "@/store/loan/modules/treasuryStatement/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import AbortService from "@/services/abortService";
import FormMixinBuilder from "@/store/shared/form";
import ListingMixinBuilder from "@/store/shared/listing";
import PagingMixinBuilder from "@/store/shared/paging";
import SortingMixinBuilder from "@/store/shared/sorting";
import SearchMixinBuilder from "@/store/shared/search";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import TreasuryStatementState from "@/store/loan/modules/treasuryStatement/types/treasuryStatementState";
import TreasuryStatementRecord from "@/store/loan/modules/treasuryStatement/types/treasuryStatementRecord";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import { DictionariesController } from "@/api/loan/dictionaries";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import ApiGetTreasuryStatementRecordsParameters from "@/api/loan/types/paymentsProcessing/apiGetTreasuryStatementRecordsParameters";
import { TreasuryStatementPaymentsType } from "@/store/loan/modules/treasuryStatement/types/treasuryStatementPaymentsType";
import { cloneDeep, isBoolean, sum } from "lodash";
import { LoanPaymentsProcessingController } from "@/api/loan/paymentsProcessing";
import ApiTreasuryStatementPayment from "@/api/loan/types/paymentsProcessing/apiTreasuryStatementPayment";
import ApiTreasuryStatementRecord from "@/api/loan/types/paymentsProcessing/apiTreasuryStatementRecord";
import mapper from "@/store/loan/modules/treasuryStatement/mapper";
import TreasuryStatementPayment from "@/store/loan/modules/treasuryStatement/types/treasuryStatementPayment";
import RequestCancelledException from "@/exceptions/requestCancelledException";
import { plainToInstance } from "class-transformer";
import ApiBorrower from "@/api/loan/types/dictionaries/apiBorrower";

const abortService = new AbortService();
const dictionariesController = new DictionariesController(abortService);
const paymentsProcessingController = new LoanPaymentsProcessingController(abortService);

const baseMixin = (new BaseMixinBuilder(abortService)).build();
const formMixin = (new FormMixinBuilder()).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["newItem"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new TreasuryStatementState(
			new ListingModel<TreasuryStatementRecord>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String>({
				type: "",
				order: sortingOrderType.descending
			}),
			new PagingModel({
				total: 0,
				page: 1,
				pageSize: 25
			}),
			new SearchModel({
				query: ""
			}),
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<TreasuryStatementState, any>>{
	...listingMixin.getters,
	...formMixin.getters,
	...snapshotMixin.getters,
	[getterTypes.payments]: state => {
		return state.listing.items.reduce<ApiTreasuryStatementPayment[]>((arr, record) => arr.concat(record.payments), []);
	},
	[getterTypes.borrowers]: state => {
		return state.borrowers.map(x => plainToInstance(ApiBorrower, {
			...x,
			name: `${x.shortName} №${x.applicationNumber}`
		}));
	}
};

const actions = <ActionTree<TreasuryStatementState, any>>{
	...listingMixin.actions,
	...pagingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, state }) {
		await dispatch(actionTypes.initializeBase);

		await dispatch(actionTypes.fetchDictionaries);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.fetchDictionaries]({ commit }) {
		try {
			const [
				paymentExpenses, 
				treasuryStatementOperationTypes, 
				borrowers,
				allFinanceSources
			] = await Promise.all([
				dictionariesController.getPaymentExpenses(),
				dictionariesController.getTreasuryStatementOperationTypes(),
				dictionariesController.getBorrowers(),
				dictionariesController.getAllFinanceSources()
			]);

			commit(mutationTypes.SET_PAYMENT_EXPENSES, paymentExpenses.filter(x => x.category != "Cessions"));
			commit(mutationTypes.SET_TREASURY_STATEMENT_OPERATION_TYPES, treasuryStatementOperationTypes);
			commit(mutationTypes.SET_BORROWERS, borrowers);
			commit(mutationTypes.SET_ALL_FINANCE_SOURCES, allFinanceSources);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		}
	},
	async [actionTypes.handleUploadTreasuryStatement]({ dispatch, commit, state, getters }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);
		commit(mutationTypes.SET_FILE_IS_LOADING, true);
		try {
			let { records, fileId } = await paymentsProcessingController.uploadTreasuryStatement(new ApiGetTreasuryStatementRecordsParameters(
				state.file.data));

			alertService.addInfo(AlertKeys.UPLOAD_TREASURY_STATEMENT_SUCCESS);

			commit(mutationTypes.SET_FILE_ID, fileId);

			commit(mutationTypes.SET_LISTING_ITEMS, records.map(x => mapper.map(x, ApiTreasuryStatementRecord, TreasuryStatementRecord)));

			commit(mutationTypes.SET_EDITABLE_ITEMS, getters.payments.filter((x: any) => !x.isFinal));
			commit(mutationTypes.SET_EDITABLE_PROJECT_RECORDS, records.filter(x => !x.projectId));
			commit(mutationTypes.SET_PAYMENTS_FILTER_TYPE, TreasuryStatementPaymentsType.NOT_FINAL);
		} catch (error) {
			switch (error.constructor) {
				case RequestCancelledException:
					alertService.addInfo(AlertKeys.TREASURY_STATEMENT_IMPORT_CANCELLED);
					break;
				default:
					console.error(error);
					AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_FILE_IS_LOADING, false);
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.fetchProjectStatus]({ commit, state }, { id, projectId }) {
		commit(mutationTypes.SET_IS_PROJECT_STATUS_FETCHING, true);
		
		try {
			const status = await dictionariesController.getProjectStatus(projectId);
			
			commit(mutationTypes.SET_RECORD_PROJECT_STATUS, { id, value: status });
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_PROJECT_STATUS_FETCHING, false);
		}
	},
	async [actionTypes.handleTreasuryStatement]({ commit, state }) {
		commit(mutationTypes.SET_IS_TREASURY_STATEMENT_PROCESSING, true);

		try {
			await paymentsProcessingController.handleTreasuryStatement(state.fileId);

			alertService.addInfo(AlertKeys.HANDLE_TREASURY_STATEMENT_SUCCESS);

			commit(mutationTypes.DELETE_FILE_DATA);
			commit(mutationTypes.SET_FILE_ID, "");
			commit(mutationTypes.SET_EDITABLE_ITEMS, []);
			commit(mutationTypes.SET_LISTING_ITEMS, [])
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_TREASURY_STATEMENT_PROCESSING, false);

		}
	},
	async [actionTypes.createItem]({ commit, dispatch, getters, state }, record) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);
		try {
			const item = await paymentsProcessingController.createTreasuryStatementPayment(
				record.fileId,
				record.id,
				mapper.map(state.newItem, TreasuryStatementPayment, ApiTreasuryStatementPayment));

			const updatedPayments = [...getters.payments,
				{ ...item, isFinal: true }].filter((x: ApiTreasuryStatementPayment) => x.recordId === record.id);

			commit(mutationTypes.SET_RECORD_PAYMENTS, { id: record.id, value: updatedPayments });

			await dispatch(actionTypes.updateRecordStatus, { record });

			alertService.addInfo(AlertKeys.NOTE_SUCCESS_CREATED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.RESET_NEW_ITEM);
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.updateItem]({ dispatch, commit, rootState, state, getters }, item) {
		commit(mutationTypes.ADD_SAVING_ITEM, item);

		try {
			const record = state.listing.items.find(x => x.id === item.recordId);

			if(record) {
				const updatedItem = await paymentsProcessingController.updateTreasuryStatementPayment(record.id, item.id, record.fileId, item);

				const payment = { ...updatedItem, isFinal: true };

				const updatedPayments = getters.payments.map((x: ApiTreasuryStatementPayment) => payment.id === x.id ?
					payment : x).filter((x: ApiTreasuryStatementPayment) => x.recordId === record.id);

				commit(mutationTypes.SET_RECORD_PAYMENTS, { id: record.id, value: updatedPayments });

				await dispatch(actionTypes.updateRecordStatus, { record });

				alertService.addInfo(AlertKeys.NOTE_SUCCESS_UPDATED);
			}
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.REMOVE_SAVING_ITEM, item);
		}
	},
	async [actionTypes.deleteItem]({ commit, dispatch, state, rootState }, item) {
		commit(mutationTypes.SET_IS_ITEM_DELETING, true);

		try {
			const record = state.listing.items.find(x => x.id === item.recordId);

			if(record) {
				await paymentsProcessingController.deleteTreasuryStatementPayment(item.recordId, item.id, record.fileId);

				alertService.addInfo(AlertKeys.NOTE_SUCCESS_DELETED);

				const updatedPayments = record.payments.filter(x => x.id !== item.id);
				commit(mutationTypes.SET_RECORD_PAYMENTS, { id: record.id, value: updatedPayments });

				await dispatch(actionTypes.updateRecordStatus, { record });
			}
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_ITEM_DELETING, false);
		}
	},
	async [actionTypes.updateRecordStatus]({ commit, state }, { record, status }) {
		if(isBoolean(status)) {
			if(status === record.isFinal)
				return;

			await paymentsProcessingController.updateRecordStatus(record.fileId, record.id, status);
			commit(mutationTypes.SET_RECORD_IS_FINAL, { id: record.id, value: status });
		} else {
			const isSumEqual = record.payment === sum(record.payments.map((x: ApiTreasuryStatementPayment) => x.amount));
			const isPaymentsIsFinal = record.payments.every((x: ApiTreasuryStatementPayment) => x.isFinal);
			const status = isPaymentsIsFinal && isSumEqual;

			if(status === record.isFinal)
				return;

			await paymentsProcessingController.updateRecordStatus(record.fileId, record.id, status);
			commit(mutationTypes.SET_RECORD_IS_FINAL, { id: record.id, value: status });
		}
	},
	async [actionTypes.cancelTreasuryStatementImport]({ state }) {
		if(state.file.isLoading) {
			abortService.abort();
			abortService.initialize();
		}
	},
	async [actionTypes.updateRecordProject]({ state, commit, dispatch }, { record, projectId }) {
		commit(mutationTypes.ADD_SAVING_PROJECT_RECORD, record);

		try {
			await paymentsProcessingController.updateRecordProject(state.fileId, record.id, projectId);
			
			await dispatch(actionTypes.fetchProjectStatus, { id: record.id, projectId })

			alertService.addInfo(AlertKeys.PROJECT_SUCCESS_UPDATED);

			commit(mutationTypes.REMOVE_EDITABLE_PROJECT_RECORD, record);
			commit(mutationTypes.SET_RECORD_PROJECT, { id: record.id, value: projectId });
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.REMOVE_SAVING_PROJECT_RECORD, record);
		}
	}
};

const mutations = <MutationTree<TreasuryStatementState>>{
	...listingMixin.mutations,
	...pagingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	[mutationTypes.SET_IS_PROJECT_STATUS_FETCHING](state, value) {
		state.isProjectStatusFetching = value;
	},
	[mutationTypes.SET_PAYMENT_EXPENSES](state, value) {
		state.paymentExpenses = value;
	},
	[mutationTypes.SET_ALL_FINANCE_SOURCES](state, value) {
		state.allFinanceSources = value;
	},
	[mutationTypes.SET_TREASURY_STATEMENT_OPERATION_TYPES](state, value) {
		state.treasuryStatementOperationTypes = value;
	},
	[mutationTypes.SET_PAYMENTS_FILTER_TYPE](state, value) {
		state.paymentsFilterType = value;
	},
	[mutationTypes.SET_FILE_DATA](state, value) {
		state.file.data = value;
	},
	[mutationTypes.DELETE_FILE_DATA](state) {
		// @ts-ignore
		state.file.data = undefined;
	},
	[mutationTypes.SET_FILE_IS_LOADING](state, value) {
		state.file.isLoading = value;
	},
	[mutationTypes.SET_FILE_ID](state, value) {
		state.fileId = value;
	},
	[mutationTypes.SET_IS_TREASURY_STATEMENT_PROCESSING](state, value) {
		state.isTreasuryStatementProcessing = value;
	},
	[mutationTypes.SET_EDITABLE_ITEMS](state, value) {
		state.editableItems = cloneDeep(value);
	},
	[mutationTypes.ADD_SAVING_ITEM](state, value) {
		state.savingItems.push(cloneDeep(value));
	},
	[mutationTypes.REMOVE_SAVING_ITEM](state, value) {
		state.savingItems.splice(state.savingItems.findIndex(x => x.id === value.id), 1);
	},
	[mutationTypes.ADD_EDITABLE_ITEM](state, value) {
		state.editableItems.push(cloneDeep(value));
	},
	[mutationTypes.REMOVE_EDITABLE_ITEM](state, value) {
		state.editableItems.splice(state.editableItems.findIndex(x => x.id === value.id), 1);
	},
	[mutationTypes.RESET_NEW_ITEM](state) {
		state.newItem = new TreasuryStatementPayment();
	},
	[mutationTypes.SET_NEW_ITEM_RECORD_ID](state, value) {
		state.newItem.recordId = value;
	},
	[mutationTypes.SET_NEW_ITEM_FINANCING_SOURCE_ID](state, value) {
		state.newItem.financingSourceId = value;
	},
	[mutationTypes.SET_NEW_ITEM_QUARTER_ID](state, value) {
		state.newItem.quarterId = value;
	},
	[mutationTypes.SET_NEW_ITEM_PAYMENT_EXPENSE_ID](state, value) {
		state.newItem.paymentExpenseId = value;
	},
	[mutationTypes.SET_NEW_ITEM_AMOUNT](state, value) {
		state.newItem.amount = value;
	},
	[mutationTypes.SET_RECORD_PAYMENTS](state, { id, value }) {
		state.listing.items[state.listing.items.findIndex(x => x.id === id)].payments = cloneDeep(value);
	},
	[mutationTypes.SET_RECORD_PROJECT](state, { id, value }) {
		state.listing.items[state.listing.items.findIndex(x => x.id === id)].projectId = cloneDeep(value);
	},
	[mutationTypes.SET_RECORD_PROJECT_STATUS](state, { id, value }) {
		state.listing.items[state.listing.items.findIndex(x => x.id === id)].projectStatus = cloneDeep(value);
	},
	[mutationTypes.SET_RECORD_IS_FINAL](state, { id, value }) {
		state.listing.items[state.listing.items.findIndex(x => x.id === id)].isFinal = cloneDeep(value);
	},
	[mutationTypes.SET_IS_ITEM_DELETING](state, value) {
		state.isItemDeleting = value;
	},
	[mutationTypes.SET_EDITABLE_PROJECT_RECORDS](state, value) {
		state.editableProjectRecords = cloneDeep(value);
	},
	[mutationTypes.ADD_EDITABLE_PROJECT_RECORD](state, value) {
		state.editableProjectRecords.push(cloneDeep(value));
	},
	[mutationTypes.REMOVE_EDITABLE_PROJECT_RECORD](state, value) {
		state.editableProjectRecords.splice(state.editableProjectRecords.findIndex(x => x.id === value.id), 1);
	},
	[mutationTypes.ADD_SAVING_PROJECT_RECORD](state, value) {
		state.savingProjectRecords.push(cloneDeep(value));
	},
	[mutationTypes.REMOVE_SAVING_PROJECT_RECORD](state, value) {
		state.savingProjectRecords.splice(state.savingProjectRecords.findIndex(x => x.id === value.id), 1);
	},
	[mutationTypes.SET_BORROWERS](state, value) {
		state.borrowers = value;
	}
};

export {
	namespace, state, getters, actions, mutations
};

const treasuryStatementModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default treasuryStatementModule;

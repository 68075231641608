export default class TroubledBorrowersFilter {
	companyName: string;
	responsibleUserId: string;

	constructor(
		companyName: string = "",
		responsibleUserId: string = ""
	)
	{
		this.companyName = companyName;
		this.responsibleUserId = responsibleUserId;
	}
}

<template>
	<loan-content-layout v-if="isInitialized">
		<v-row>
			<v-col class="d-flex flex-column pt-4" style="gap: 24px">
				<v-card elevation="0" class="loan-card px-2 py-6">
					<v-row class="align-center">
						<v-col class="py-0">
							<v-card-title class="text-h6">
								{{ $t("titles.interestByPeriod") }}
							</v-card-title>
						</v-col>
						<v-col class="py-0">
							<v-card-actions class="px-4">
								<div class="d-flex justify-md-end" style="width: 100%">
									<frp-btn v-if="can(Permissions.LOAN_CREATE)"
											 color="primary"
											 :loading="isSubmitToAccounting"
											 :disabled="!selectedItemsLength || isInterestAccruing"
											 outlined
											 @click="handleInterestAction(InterestActionType.ACCOUNTING)">
										{{ $t("buttons.submitToAccounting") }}
									</frp-btn>
									
									<frp-btn v-if="can(Permissions.LOAN_UPDATE)"
											 elevation="0"
											 color="blue"
											 dark
											 :loading="isInterestAccruing"
											 :disabled="!selectedItemsLength || isSubmitToAccounting"
											 @click="handleInterestAction(InterestActionType.ACCRUE)">
										{{ $t("buttons.accrueInterest") }}
									</frp-btn>
								</div>
							</v-card-actions>
						</v-col>
					</v-row>
				</v-card>
				
				<v-card elevation="0" class="loan-card pa-0 pt-6">
					<loan-agreements-accrued-interest-filter :is-interest-accruing="isInterestAccruing"
															 :is-submit-to-accounting="isSubmitToAccounting">
					</loan-agreements-accrued-interest-filter>
					<loan-agreements-accrued-interest-table :is-interest-accruing="isInterestAccruing"
															:is-submit-to-accounting="isSubmitToAccounting">
					</loan-agreements-accrued-interest-table>
				</v-card>
			</v-col>
		</v-row>
		
		<frp-dialog v-model="isInterestDialogOpened"
					persistent
					max-width="1074"
					:title="$t('dialogs.accrueInterest.title')">			
			<template #content="{ onIntersect }">
				<vue-pdf-embed style="min-height: 600px" v-intersect="onIntersect" :source="interestPdfLink"/>
			</template>
			
			<template #footer>
				<frp-btn color="primary"
						 outlined
						 @click="handleCloseInterestDialog">
					{{ $t("buttons.close") }}
				</frp-btn>
				<frp-btn elevation="0"
						 color="blue"
						 dark
						 :href="interestPdfLink"
						 download>
					{{ $t("buttons.download") }}
				</frp-btn>
				
				<frp-btn elevation="0"
						 color="blue"
						 dark
						 :loading="accruedInterestFileMetaIsLoading"
						 @click="handleSubmitToAccounting">
					{{ $t("buttons.submitToAccounting") }}
				</frp-btn>
			</template>
		</frp-dialog>
		
		<frp-dialog v-model="isAgreementsAccruedInterestReportDialogOpened"
					persistent
					max-width="568"
					:title="$t('dialogs.signOrder.title')">
			<template #content="{ onIntersect }">
				<v-form v-intersect="onIntersect">
					<template v-if="!isSigned && !certificateInfo.thumbprint">
						<div class="mb-2">
							<v-subheader class="pa-0 mb-1 grey--text text-caption" style="height: fit-content">
								<span>{{ $t("fields.defaultSignature.label") }}</span>
							</v-subheader>
							<frp-alert style="width: fit-content" type="warning"
									   :text="$t('details.isSigned.signatureMissed')"/>
						</div>
					</template>
					<template v-else>
						<div class="mb-2">
							<v-subheader class="pa-0 mb-1 grey--text text-caption" style="height: fit-content">
								<span>{{ $t("fields.defaultSignature.label") }}</span>
							</v-subheader>
							<span @click="certificateInfoDialog = true" class="pointer text-body-2 py-2 blue--text">
								{{ $t("aliases.documents.informationAboutSignature") }}
							</span>
						</div>
						
						<frp-dialog v-model="certificateInfoDialog" :title="$t('titles.informationAboutSignature')" maxWidth="420">
							<template #close>
								<div class="pointer d-flex align-center blue--text"
									 @click="certificateInfoDialog = false">
									<v-icon :color="colors.blue.base" class="mr-1 mdi-18px">mdi-close</v-icon>
									<span>{{ $t("buttons.close") }}</span>
								</div>
							</template>
							
							<template #content>
								<frp-details-item
									:title="$t('details.titles.owner')"
									:value="certificateInfo.owner"/>
								<frp-details-item
									:title="$t('details.titles.certificate')"
									:value="certificateInfo.thumbprint"/>
								<frp-details-item
									:title="$t('details.titles.expireDate')"
									:value="certificateInfo.expireDate"/>
							</template>
						</frp-dialog>
					</template>
					
					<frp-textarea v-model="agreementsAccruedInterestReportComment"
								  :label="$t('fields.comment.label')"
								  :placeholder="$t('fields.comment.placeholder')">
					</frp-textarea>
				</v-form>
			</template>
			
			<template #footer>
				<frp-btn color="primary"
						 outlined
						 @click="handleCloseAgreementsAccruedInterestReportDialog">
					{{ $t("buttons.close") }}
				</frp-btn>
				
				<template v-if="isSigned">
					<frp-btn elevation="0"
							 color="blue"
							 dark
							 :loading="isAgreementsAccruedInterestReportLoading"
							 @click="handleSendAgreementsAccruedInterestReport">
						{{ $t("buttons.send") }}
					</frp-btn>
				</template>
				<template v-else>
					<frp-digital-signature-dialog
						:data-url="dataUrl"
						v-model="signatureDialog"
						:file="accruedInterestFile"
						:submit-btn="$t('buttons.choose')"
						@signed="handleDigitalSignatureFileSigned"
						:meta="accruedInterestFileMeta"
						color="blue"
						button-elevation="0"
						pdf>
						<!--					TODO: Убрать prop pdf позже-->
						<template #activator="{ on, attrs }">
							<frp-btn elevation="0"
									 v-on="on"
									 v-bind="attrs"
									 class="ml-4"
									 color="blue"
									 dark
									 :loading="accruedInterestFileMetaIsLoading">
								{{ $t("buttons.sign") }}
							</frp-btn>
						</template>
					</frp-digital-signature-dialog>
				</template>
			</template>
		</frp-dialog>
		
		<frp-dialog v-model="isAccrueInterestDialogOpened"
					max-width="565"
					:title="$t('dialogs.accruedInterest.title')">
			<template #content="{ onIntersect }">
				<div class="d-flex primary--text" style="gap: 16px">
					<div class="d-flex flex-column text-body-2">
						<span>{{ $t("details.titles.interestPeriod") }}</span>
						<span>{{ $t("details.titles.loanAgreementsCount") }}</span>
						<span v-intersect="onIntersect">{{ $t("details.titles.totalSum") }}</span>
						<span>{{ $t("details.titles.interestAccruedAmount") }}</span>
					</div>
					<div class="d-flex flex-column text-body-2 font-weight-bold">
						<span>{{ currentPeriod }}</span>
						<span>{{ selectedItemsLength }}</span>
						<span>0 ₽</span>
						<span>0 ₽</span>
					</div>
				</div>
			</template>
			
			<template #footer>
				<frp-btn elevation="0"
						 color="blue"
						 dark
						 @click="isAccrueInterestDialogOpened = false">
					{{ $t("buttons.close") }}
				</frp-btn>
			</template>
		</frp-dialog>
	</loan-content-layout>
	<loan-agreements-accrued-interest-loader v-else/>
</template>

<script>
import { LoanInterestController } from "@/api/loan/loanInterest";
import ApiAccrueInterestParameters from "@/api/loan/types/loanInterest/apiAccrueInterestParameters";
import FrpAlert from "@/components/alerts/FrpAlert.vue";
import FrpFile from "@/components/common/FrpFile.vue";
import FrpDetailsItem from "@/components/details/FrpDetailsItem.vue";
import FrpDigitalSignatureDialog from "@/components/digitalSignature/FrpDigitalSignatureDialog.vue";
import LoanDropzone from "@/components/dropzone/LoanDropzone.vue";
import FrpDayAutocomplete from "@/components/fields/FrpDayAutocomplete.vue";
import FrpFileField from "@/components/fields/FrpFileField.vue";
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import LoanContentLayout from "@/components/layouts/LoanContentLayout";
import AbortService from "@/services/abortService";
import AgreementsAccruedInterestMapperProfile from "@/store/loan/modules/agreementsAccruedInterest/mapper";
import { InterestActionType } from "@/store/loan/modules/agreementsAccruedInterest/types/interestActionType";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import { getHeadersContentDispositionFilename } from "@/utils/getHeadersProp";
import { plainToInstance } from "class-transformer";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpDialog from "Components/dialogs/FrpDialog";
import FrpAutocomplete from "Components/fields/FrpAutocomplete";
import FrpTextField from "Components/fields/FrpTextField";
import LoanBooleanSwitch from "Components/fields/LoanBooleanSwitch";
import FrpIcon from "Components/icon/FrpIcon";
import authorizationMixin from "Mixins/authorizationMixin";
import colorsMixin from "Mixins/colorsMixin";
import { listMixin } from "Mixins/listMixin";
import { i18n } from "Plugins/index";
import { RouteNames } from "Router/loan/routes";
import LoanAgreementsAccruedInterestLoader from "Views/loan/agreementAccruedInterest/LoanAgreementsAccruedInterestLoader";
import LoanAgreementsAccruedInterestFilter
	from "Views/loan/agreementAccruedInterest/agreementsAccruedInterestFilter/LoanAgreementsAccruedInterestFilter.vue";
import LoanAgreementsAccruedInterestTable
	from "Views/loan/agreementAccruedInterest/agreementsAccruedInterestTable/LoanAgreementsAccruedInterestTable.vue";
import { namespace, getterTypes, mutationTypes, actionTypes } from "Store/loan/modules/agreementsAccruedInterest/types";
import { createNamespacedHelpers } from "vuex";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

const abortService = new AbortService();
const loanInterestController = new LoanInterestController(abortService);

const mapper = new AgreementsAccruedInterestMapperProfile();

export default {
	metaInfo: {
		title: i18n.t("metaTitles.agreementsAccruedInterest")
	},
	mixins: [colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			getHeadersContentDispositionFilename,
			RouteNames,
			InterestActionType,
			dataUrl: "",
			isAccrueInterestDialogOpened: false,
			isAgreementsAccruedInterestReportDialogOpened: false,
			signatureDialog: false,
			isInterestDialogOpened: false,
			accruedInterestFile: null,
			isInterestAccruing: false,
			isSubmitToAccounting: false,
			interestPdfLink: null,
			certificateInfoDialog: false
		};
	},
	computed: {
		...mapState({
			state: state => state,
			isInitialized: state => state.isInitialized,
			periods: state => state.periods,
			interestFilter: state => state.filter,
			agreementStatuses: state => state.agreementStatuses,
			selectedItems: state => state.selectedItems,
			certificateInfo: state => state.certificateInfo,
			isSigned: state => state.isSigned,
			accruedInterestFileMeta: state => state.accruedInterestFileMeta,
			accruedInterestFileMetaIsLoading: state => state.accruedInterestFileMeta.isLoading,
			isAgreementsAccruedInterestReportLoading: state => state.isAgreementsAccruedInterestReportLoading,
			isReportError: state => state.isReportError
		}),
		...mapGetters({
			nearestPeriod: getterTypes.nearestPeriod,
			filteredItems: getterTypes.filteredItems,
			formattedFilteredItems: getterTypes.formattedFilteredItems,
			isSelectedItemsEmpty: getterTypes.isSelectedItemsEmpty,
			selectedItemsLength: getterTypes.selectedItemsLength
		}),
		agreementsAccruedInterestReportComment: {
			get() {
				return this.state.agreementsAccruedInterestReport.comment;
			},
			set(value) {
				this.setAgreementsAccruedInterestReportComment(value);
			}
		},
		currentPeriod() {
			const period = this.periods.find(x => x.id === this.state.filter.periodId);
			if(period)
				return period.periodName;
		}
	},
	methods: {
		...mapActions({
			initializeStore: actionTypes.initialize,
			updateListingItems: actionTypes.updateListingItems,
			updateSelectedItems: actionTypes.updateSelectedItems,
			uploadAccruedInterestFile: actionTypes.handleAccruedInterestFileUpload,
			uploadSignedAccruedInterestFile: actionTypes.handleAccruedInterestFileSigned,
			sendAgreementsAccruedInterestReport: actionTypes.sendAgreementsAccruedInterestReport
		}),
		...mapMutations({
			resetAgreementsAccruedInterestReport: mutationTypes.RESET_AGREEMENTS_ACCRUED_INTEREST_REPORT,
			resetAgreementsAccruedInterestReportComment: mutationTypes.RESET_AGREEMENTS_ACCRUED_INTEREST_REPORT_COMMENT,
			resetAgreementsAccruedInterestReportSignatureId: mutationTypes.RESET_AGREEMENTS_ACCRUED_INTEREST_REPORT_SIGNATURE_ID,
			resetAccruedInterestFileMeta: mutationTypes.RESET_ACCRUED_INTEREST_FILE_META,
			setAgreementsAccruedInterestReportNumber: mutationTypes.SET_AGREEMENTS_ACCRUED_INTEREST_REPORT_REPORT_NUMBER,
			setAgreementsAccruedInterestReportComment: mutationTypes.SET_AGREEMENTS_ACCRUED_INTEREST_REPORT_COMMENT,
			setIsReportError: mutationTypes.SET_IS_REPORT_ERROR,
			setIsSigned: mutationTypes.SET_IS_SIGNED,
			resetCertificateInfo: mutationTypes.RESET_CERTIFICATE_INFO
		}),
		handleOnload(dataUrl) {
			this.dataUrl = dataUrl;
		},
		async handleAccruedInterestFileDelete() {
			this.dataUrl = "";
			this.accruedInterestFile = null;
			await this.resetAccruedInterestFileMeta();
		},
		async handleDigitalSignatureFileSigned(signature) {
			await this.uploadSignedAccruedInterestFile({ signature, file: this.accruedInterestFile });
		},
		prepareInterestParameters() {
			const items = [];
			const selectedItems = this.state.listing.items.filter(x => x.isSelected);
			
			selectedItems.forEach(x => {
				if(x.groupedItems.length)
					x.groupedItems.forEach((y) => y.groupedItems.length ? items.push(...y.groupedItems) : items.push(y));
				else
					items.push(x);
			});
			
			return plainToInstance(ApiAccrueInterestParameters, {
				periodId: this.interestFilter.periodId,
				rows: items.map(x => mapper.mapToApiAgreementsAccruedInterest(x))
			});
		},
		async handleInterestAction(actionType) {
			switch (actionType) {
				case InterestActionType.ACCRUE:
				{
					await this.accrueInterest();
					break;
				}
				case InterestActionType.ACCOUNTING:
				{
					await this.submitToAccounting();
					break;
				}
			}
		},
		setAccruedInterestInformationOutput(data, headers) {
			if(!data || !data.size) {
				this.isAccrueInterestDialogOpened = true;
			} else {
				this.accruedInterestFile = new File([data], "report.pdf");
				
				const { filename } = getHeadersContentDispositionFilename(headers);
				this.setAgreementsAccruedInterestReportNumber(filename);
				
				this.interestPdfLink = URL.createObjectURL(data);
				
				this.isInterestDialogOpened = true;
			}
		},
		async accrueInterest() {
			this.isInterestAccruing = true;
			
			try {
				const parameters = await this.prepareInterestParameters();
				
				const { data, headers } = await loanInterestController.handleInterestAction(parameters, InterestActionType.ACCRUE);
				
				this.setAccruedInterestInformationOutput(data, headers);
			} catch (error) {
				AlertHelper.handleGeneralRequestErrors(error);
				console.error(error);
			} finally {
				this.isInterestAccruing = false;
			}
		},
		async submitToAccounting() {
			this.isSubmitToAccounting = true;
			
			try {
				const parameters = await this.prepareInterestParameters();
				
				const { data, headers } = await loanInterestController.handleInterestAction(parameters, InterestActionType.ACCOUNTING);
				
				this.setAccruedInterestInformationOutput(data, headers);
			} catch (error) {
				AlertHelper.handleGeneralRequestErrors(error);
				console.error(error);
			} finally {
				this.isSubmitToAccounting = false;
			}
		},
		async handleSubmitToAccounting() {
			await this.uploadAccruedInterestFile(this.accruedInterestFile);
			this.isInterestDialogOpened = false;
			this.isAgreementsAccruedInterestReportDialogOpened = true;
		},
		resetAccruedProperties() {
			this.setIsReportError(false);
			this.setIsSigned(false);
			this.resetCertificateInfo();
		},
		async handleCloseAgreementsAccruedInterestReportDialog() {
			this.isAgreementsAccruedInterestReportDialogOpened = false;
			this.isInterestDialogOpened = true;
			
			this.resetAgreementsAccruedInterestReportComment();
			this.resetAgreementsAccruedInterestReportSignatureId();
			this.resetAccruedProperties();
		},
		async handleCloseInterestDialog() {
			this.isInterestDialogOpened = false;
			await this.handleAccruedInterestFileDelete();
			await this.updateSelectedItems();
		},
		async handleSendAgreementsAccruedInterestReport() {
			await this.sendAgreementsAccruedInterestReport();
			
			if(this.isReportError) return;
			
			await this.handleAccruedInterestFileDelete();
			this.isAgreementsAccruedInterestReportDialogOpened = false;
			
			this.resetAgreementsAccruedInterestReport();
			this.resetAccruedProperties();
			await this.updateSelectedItems();
		}
	},
	created() {
		abortService.initialize();
		this.initializeStore();
	},
	components: {
		FrpDayAutocomplete,
		FrpAlert,
		FrpDetailsItem,
		FrpTextarea,
		FrpDigitalSignatureDialog,
		FrpFileField,
		FrpFile,
		LoanDropzone,
		LoanContentLayout,
		FrpDialog,
		FrpIcon,
		FrpAutocomplete,
		FrpBtn,
		LoanBooleanSwitch,
		FrpTextField,
		LoanAgreementsAccruedInterestTable,
		LoanAgreementsAccruedInterestFilter,
		LoanAgreementsAccruedInterestLoader,
		VuePdfEmbed
	}
};
</script>
<style lang="scss" scoped>
.v-input--selection-controls__ripple {
	width: 27px;
	height: 27px;
	left: -8px;
	top: calc(50% - 20px);
}

.pointer {
	cursor: pointer;
}
</style>

export default class ApiGetTroubledBorrowersParameters {
	take: number;
	skip: number;
	companyName: string;
	responsibleUserId: string;

	constructor(
		take: number,
		skip: number,
		companyName: string,
		responsibleUserId: string
	)
	{
		this.take = take;
		this.skip = skip;
		this.companyName = companyName;
		this.responsibleUserId = responsibleUserId;
	}
}

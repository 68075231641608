import IPageState from "@/store/shared/base/types/pageState";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import FormState from "@/store/shared/form/models/formState";
import ApiRequirement from "@/api/loan/types/troubledBorrower/apiRequirement";
import ApiCreateDebtorRequest from "@/api/loan/types/troubledBorrower/apiCreateDebtorRequest";
import DebtorEvent from "@/store/loan/modules/troubledBorrower/modules/productionStatus/types/debtorEvent";
import DebtorStatus from "@/store/loan/modules/troubledBorrower/modules/productionStatus/types/debtorStatus";
import Debtor from "@/store/loan/modules/troubledBorrower/modules/productionStatus/types/debtor";
import ApiUpdateDebtorRequest from "@/api/loan/types/troubledBorrower/apiUpdateDebtorRequest";
import RequirementHistoryItem from "@/store/loan/modules/troubledBorrower/modules/productionStatus/types/requirementHistoryItem";

export default class ProductionStatusState implements IPageState {
	constructor(
		public listing: ListingModel<Debtor>,
		public sorting: SortingModel<String>,
		public paging: PagingModel,
		public search: SearchModel,
		public form: FormState,
		public snapshot: object,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public editableEvent: DebtorEvent = new DebtorEvent(),
		public editableRequirement: ApiRequirement = new ApiRequirement(),
		public createDebtorRequest: ApiCreateDebtorRequest = new ApiCreateDebtorRequest(),
		public updateDebtorRequest: ApiUpdateDebtorRequest = new ApiUpdateDebtorRequest(),
		public editableDebtorStatusComment: string = "",
		public editableDebtorStatus: DebtorStatus = new DebtorStatus(),
		public debtorRequirementsHistory: RequirementHistoryItem[] = []
	)
	{
	}
}

export default class ApiUpdateDebtorRequest {
	versionId: string;
	companyId: number;
	roleInProject: string;
	obligedCompanyId: number;

	constructor(
		versionId: string = "",
		companyId: number = 0,
		roleInProject: string = "",
		obligedCompanyId: number = 0
	)
	{
		this.versionId = versionId;
		this.companyId = companyId;
		this.roleInProject = roleInProject;
		this.obligedCompanyId = obligedCompanyId;
	}
}

import ApiUser from "@/api/loan/types/reports/apiUser";

export default class ApiReportAcceptance {
	canAccept: boolean;
	canDecline: boolean;
	acceptor: ApiUser;
	isAccepted: boolean;

	constructor(
		canAccept: boolean = false,
		canDecline: boolean = false,
		acceptor: ApiUser = new ApiUser(),
		isAccepted: boolean = false
	)
	{
		this.canAccept = canAccept;
		this.canDecline = canDecline;
		this.acceptor = acceptor;
		this.isAccepted = isAccepted;
	}
}

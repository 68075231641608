import ApiAgreementsAccruedInterest from "@/api/loan/types/loanInterest/apiAgreementsAccruedInterest";

export default class ApiAgreementsAccruedInterestItems {
	items: ApiAgreementsAccruedInterest[];
	totalCount: number;

	constructor(items: ApiAgreementsAccruedInterest[], totalCount: number) {
		this.items = items;
		this.totalCount = totalCount;
	}
}

import urls, { urlTemplateParts } from "@/api/config/urls";
import { plainToInstance } from "class-transformer";
import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import ApiTitleDocument from "@/api/loan/types/dictionaries/apiTitleDocument";
import ApiChangeReason from "@/api/loan/types/dictionaries/apiChangeReason";
import ApiPeriod from "@/api/loan/types/dictionaries/apiPeriod";
import ApiAgreementStatus from "@/api/loan/types/loanSchedule/apiAgreementStatus";
import ApiQuarter from "@/api/loan/types/dictionaries/apiQuarter";
import ApiFinanceSource from "@/api/loan/types/dictionaries/apiFinanceSource";
import ApiPenaltyType from "@/api/loan/types/loanInterest/apiPenaltyType";
import ApiAccrualType from "@/api/loan/types/dictionaries/apiAccrualType";
import ApiPaymentExpense from "@/api/loan/types/dictionaries/apiPaymentExpense";
import ApiBorrower from "@/api/loan/types/dictionaries/apiBorrower";
import ApiPaymentExpenseType from "@/api/loan/types/dictionaries/apiPaymentExpenseType";
import { ApiTreasuryStatementOperationType } from "@/api/loan/types/dictionaries/apiTreasuryStatementOperationType";
import ApiOrganization from "@/api/loan/types/dictionaries/apiOrganization";
import { prepareUrl } from "@/utils/prepareUrlQuery";
import ApiEventType from "@/api/loan/types/dictionaries/apiEventType";
import ApiRequirementType from "@/api/loan/types/dictionaries/apiRequirementType";
import ApiAssessmentType from "@/api/loan/types/dictionaries/apiAssessmentType";
import ApiSecurityKindType from "@/api/loan/types/dictionaries/apiSecurityKindType";
import ApiProjectManager from "@/api/loan/types/dictionaries/apiProjectManager";
import ApiDebtorStageType from "@/api/loan/types/dictionaries/apiDebtorStageType";
import ApiJudicialDecisionType from "@/api/loan/types/dictionaries/apiJudicialDecisionType";
import ApiRefundStatusTypes from "@/api/loan/types/dictionaries/apiRefundStatusTypes";
import ApiProjectRoleType from "@/api/loan/types/dictionaries/apiProjectRoleType";
import ApiRefundSourceType from "@/api/loan/types/dictionaries/apiRefundSourceType";
import ApiProblemDebtDepartment from "@/api/loan/types/dictionaries/apiProblemDebtDepartment";
import ApiCostType from "@/api/loan/types/dictionaries/apiCostType";
import { ApiAssignmentAgreement } from "@/api/loan/types/dictionaries/apiAssignmentAgreement";
import { ApiAssigneeCompany } from "@/api/loan/types/dictionaries/apiAssigneeCompany";
import ApiDictionaryItem from "@/api/loan/types/dictionaries/apiDictionaryItem";

export class DictionariesController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}

	getProjectQuarters = async (projectId: number | string): Promise<ApiQuarter[]> => {
		let data = await this.client.get<object[]>(urls.loan.dictionaries.getProjectQuarters.replace(urlTemplateParts.id,
			`${projectId}`));
		return plainToInstance(ApiQuarter, data);
	};

	getQuarters = async (): Promise<ApiQuarter[]> => {
		let data = await this.client.get<object[]>(urls.loan.dictionaries.getQuarters);
		return plainToInstance(ApiQuarter, data);
	};

	getProjectFinanceSources = async (projectId: number | string): Promise<ApiFinanceSource[]> => {
		let data = await this.client.get<object[]>(urls.loan.dictionaries.getProjectFinanceSources.replace(urlTemplateParts.id,
			`${projectId}`));
		return plainToInstance(ApiFinanceSource, data);
	};
	
	getAllFinanceSources = async (): Promise<ApiFinanceSource[]> => {
		let data = await this.client.get<object[]>(urls.loan.dictionaries.getAllFinanceSources);
		return plainToInstance(ApiFinanceSource, data);
	};

	getPaymentExpenses = async (): Promise<ApiPaymentExpense[]> => {
		let data = await this.client.get<object[]>(urls.loan.dictionaries.getPaymentExpenses);
		return plainToInstance(ApiPaymentExpense, data);
	};

	getTitleDocuments = async (): Promise<ApiTitleDocument[]> => {
		let data = await this.client.get<object[]>(urls.loan.dictionaries.getTitleDocuments);
		return plainToInstance(ApiTitleDocument, data);
	};

	getChangeReasons = async (): Promise<ApiChangeReason[]> => {
		let data = await this.client.get<object[]>(urls.loan.dictionaries.getChangeReasons);
		return plainToInstance(ApiChangeReason, data);
	};

	getPeriods = async (): Promise<ApiPeriod[]> => {
		let data = await this.client.get<object[]>(urls.loan.dictionaries.getPeriods);
		return plainToInstance(ApiPeriod, data);
	};

	getAgreementStatuses = async (): Promise<ApiAgreementStatus[]> => {
		let data = await this.client.get<object[]>(urls.loan.dictionaries.getAgreementStatuses);
		return plainToInstance(ApiAgreementStatus, data);
	};

	getPenaltyTypes = async (): Promise<ApiPenaltyType[]> => {
		let data = await this.client.get<object[]>(urls.loan.dictionaries.getPenaltyTypes);
		return plainToInstance(ApiPenaltyType, data);
	};

	getAccrualTypes = async (): Promise<ApiAccrualType[]> => {
		let data = await this.client.get<object[]>(urls.loan.dictionaries.getAccrualTypes);
		return plainToInstance(ApiAccrualType, data);
	};

	getPaymentExpenseTypes = async (): Promise<ApiPaymentExpenseType[]> => {
		let data = await this.client.get<object[]>(urls.loan.dictionaries.getPaymentExpenseTypes);
		return plainToInstance(ApiPaymentExpenseType, data);
	};

	getTreasuryStatementOperationTypes = async (): Promise<ApiTreasuryStatementOperationType[]> => {
		let data = await this.client.get<object[]>(urls.loan.dictionaries.getTreasuryStatementOperationTypes);
		return plainToInstance(ApiTreasuryStatementOperationType, data);
	};
	
	getProjectStatus = async (projectId: string | number): Promise<string> => {
		return await this.client.get<string>(urls.loan.dictionaries.getProjectStatus.replace(urlTemplateParts.id, `${projectId}`));
	};
	
	getAssignmentAgreements = async (): Promise<ApiAssignmentAgreement[]> => {
		return await this.client.get<ApiAssignmentAgreement[]>(urls.loan.dictionaries.getAssignmentAgreements);
	};

	getAssigneeCompanies = async (): Promise<ApiAssigneeCompany[]> => {
		return await this.client.get<ApiAssigneeCompany[]>(urls.loan.dictionaries.getAssigneeCompanies);
	};

	getBorrowers = async (): Promise<ApiBorrower[]> => {
		let data = await this.client.get<object[]>(urls.loan.dictionaries.getBorrowers);
		return plainToInstance(ApiBorrower, data);
	};

	getOrganizations = async (query?: string): Promise<ApiOrganization[]> => {
		let data = await this.client.get<object[]>(prepareUrl(urls.troubledBorrower.dictionaries.getOrganizations, { query }));
		return plainToInstance(ApiOrganization, data);
	};

	getEventTypes = async (): Promise<ApiEventType[]> => {
		let data = await this.client.get<object[]>(urls.troubledBorrower.dictionaries.getEventTypes);
		return plainToInstance(ApiEventType, data);
	};

	getRequirementTypes = async (): Promise<ApiRequirementType[]> => {
		let data = await this.client.get<object[]>(urls.troubledBorrower.dictionaries.getRequirementTypes);
		return plainToInstance(ApiRequirementType, data);
	};

	getAssessmentTypes = async (): Promise<ApiAssessmentType[]> => {
		let data = await this.client.get<object[]>(urls.troubledBorrower.dictionaries.getAssessmentTypes);
		return plainToInstance(ApiAssessmentType, data);
	};

	getSecurityKindTypes = async (): Promise<ApiSecurityKindType[]> => {
		let data = await this.client.get<object[]>(urls.troubledBorrower.dictionaries.getSecurityKindTypes);
		return plainToInstance(ApiSecurityKindType, data);
	};

	getProjectManagerUsers = async (query?: string): Promise<ApiProjectManager[]> => {
		let data = await this.client.get<object[]>(prepareUrl(urls.troubledBorrower.dictionaries.getProjectManagers, { query }));
		return plainToInstance(ApiProjectManager, data);
	};

	getProblemDeptDepartmentUsers = async (query?: string): Promise<ApiProblemDebtDepartment[]> => {
		let data = await this.client.get<object[]>(prepareUrl(urls.troubledBorrower.dictionaries.getProblemDebtDepartment, { query }));
		return plainToInstance(ApiProjectManager, data);
	};
	
	getDebtorStageTypes = async (): Promise<ApiDebtorStageType[]> => {
		let data = await this.client.get<ApiDebtorStageType[]>(urls.troubledBorrower.dictionaries.getDebtorStageTypes);
		return plainToInstance(ApiDebtorStageType, data);
	};

	getDebtorStageStatusTypes = async (): Promise<ApiDictionaryItem[]> => {
		let data = await this.client.get<ApiDictionaryItem[]>(urls.troubledBorrower.dictionaries.getDebtorStageStatusTypes);
		return plainToInstance(ApiDictionaryItem, data);
	};

	getJudicialDecisionTypes = async (): Promise<ApiJudicialDecisionType[]> => {
		let data = await this.client.get<object[]>(urls.troubledBorrower.dictionaries.getJudicialDecisionTypes);
		return plainToInstance(ApiJudicialDecisionType, data);
	};

	getRefundStatusTypes = async (): Promise<ApiRefundStatusTypes[]> => {
		let data = await this.client.get<object[]>(urls.troubledBorrower.dictionaries.getRefundStatusTypes);
		return plainToInstance(ApiRefundStatusTypes, data);
	};

	getProjectRoleTypes = async (): Promise<ApiProjectRoleType[]> => {
		let data = await this.client.get<object[]>(urls.troubledBorrower.dictionaries.getProjectRoleTypes);
		return plainToInstance(ApiProjectRoleType, data);
	};

	getRefundSourceTypes = async (): Promise<ApiRefundSourceType[]> => {
		return await this.client.get<ApiRefundSourceType[]>(urls.troubledBorrower.dictionaries.getRefundSourceTypes);
	};

	getCostTypes = async (): Promise<ApiCostType[]> => {
		return await this.client.get<ApiCostType[]>(urls.troubledBorrower.dictionaries.getCostTypes);
	};
}

import { difference } from "@/utils/difference";
import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import AgreementsAccruedInterestRouteQuery from "@/store/loan/modules/agreementsAccruedInterest/types/agreementsAccruedInterestRouteQuery";
import AgreementsAccruedInterestState from "@/store/loan/modules/agreementsAccruedInterest/types/agreementsAccruedInterestState";

export default class AgreementsAccruedInterestRouteQueryService {
	defaultRouteQuery: AgreementsAccruedInterestRouteQuery;

	constructor(defaultRouteQuery: AgreementsAccruedInterestRouteQuery) {
		this.defaultRouteQuery = defaultRouteQuery;
	}

	resolveRouteQueryDictionary(state: AgreementsAccruedInterestState) {
		let query = new AgreementsAccruedInterestRouteQuery(
			state.sorting.type,
			state.sorting.order,
			state.filter.periodId,
			state.filter.applicationNumber,
			state.filter.companyName,
			state.filter.agreementStatusId,
			state.filter.daysMin,
			state.filter.daysMax
		);

		return difference(this.defaultRouteQuery, query);
	}

	async resolveRouteQuery(query: Dictionary<any>) {
		try {
			let result = plainToInstance(AgreementsAccruedInterestRouteQuery, {
				...this.defaultRouteQuery,
				...query
			}, { enableImplicitConversion: true });

			await validateOrReject(result);

			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}
}

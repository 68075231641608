<template>
	<loan-content-layout>
		<v-row>
			<v-col class="px-3 py-4">
				<loan-agreement-loader/>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="3">
				<v-row>
					<v-col class="pr-0 pl-0 py-4">
						<loan-schedule-items-loader/>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="pr-0 pl-0 py-4">
						<loan-tranches-loader/>
					</v-col>
				</v-row>
			</v-col>

			<v-col cols="9">
				<v-row>
					<v-col class="pr-0 pl-0 py-4">
						<loan-overdues-loader/>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="pr-0 pl-0 pt-3 pb-4">
						<loan-payments-loader/>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</loan-content-layout>
</template>

<script>
import LoanContentLayout from "@/components/layouts/LoanContentLayout";
import LoanAgreementLoader from "@/views/loan/loanSchedule/agreement/LoanAgreementLoader";
import LoanOverduesLoader from "@/views/loan/loanSchedule/overdues/LoanOverduesLoader";
import LoanPaymentsLoader from "@/views/loan/loanSchedule/payments/LoanPaymentsLoader";
import LoanScheduleItemsLoader from "@/views/loan/loanSchedule/scheduleItems/LoanScheduleItemsLoader";
import LoanTranchesLoader from "@/views/loan/loanSchedule/tranches/LoanTranchesLoader";

export default {
	name: "LoanScheduleLoader",
	components: {
		LoanContentLayout,
		LoanPaymentsLoader,
		LoanOverduesLoader,
		LoanTranchesLoader,
		LoanScheduleItemsLoader,
		LoanAgreementLoader
	}
};
</script>

<style scoped>

</style>

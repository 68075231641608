export default class ApiPeriod {
	quarter: number;
	year: number;

	constructor(
		quarter: number = 0,
		year: number = 0
	)
	{
		this.quarter = quarter;
		this.year = year;
	}
}

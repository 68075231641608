export default class ApiReportAddQueryInfo {
	title: string;
	description: string;
	executionDate: string;

	constructor(
		title: string = "",
		description: string = "",
		executionDate: string = ""
	)
	{
		this.title = title;
		this.description = description;
		this.executionDate = executionDate;
	}
}

<template>
	<v-dialog value="true" persistent width="565" @keydown.esc="close">
		<v-card class="pt-7 pb-10">
			<v-card-title></v-card-title>
			<v-card-text class="px-5 pb-2 headline text-h6 primary--text text-center">{{ title }}</v-card-text>
			<v-card-actions class="justify-center">
				<frp-btn color="primary"
						 elevation="0"
						 :color="closeBtnColor"
						 :dark="closeBtnDark"
						 @click="close">
					{{ closeBtnText || $t("buttons.close") }}
				</frp-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import FrpBtn from "Components/buttons/FrpBtn";

export default {
	components: { FrpBtn },
	props: {
		title: String,
		closeBtnText: String,
		closeBtnColor: {
			type: String,
			default: "blue"
		},
		closeBtnDark: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		close() {
			this.$emit("dialog:close");
		}
	}
};
</script>

<style scoped lang="scss">

.v-card__text, .v-card__title {
  word-break: normal;
}
</style>

<template>
	<v-app>
		<v-main>
			<v-container v-if="isUserInitialized"
						 fluid
						 class="pa-0 white darken-2 align-start justify-start"
						 fill-height>
				<template v-if="pageMode === PageModeType.OK">
					<router-view :key="$route.meta.key || $route.name"/>
				</template>
				<frp-page-not-found v-else-if="pageMode === PageModeType.PAGE_NOT_FOUND"></frp-page-not-found>
				<frp-access-denied-error v-else-if="pageMode === PageModeType.ACCESS_DENIED"></frp-access-denied-error>
				<frp-alerts/>
			</v-container>
		</v-main>
	</v-app>
</template>

<script>
import FrpBreadcrumbs from "Components/common/FrpBreadcrumbs";
import FrpAlerts from "Components/alerts/FrpAlerts";
import { i18n } from "Plugins/index";
import storeManager from "Store/manager";
import FrpPageNotFound from "@/views/errors/FrpPageNotFound.vue";
import FrpAccessDeniedError from "@/views/errors/FrpAccessDeniedError.vue";
import { PageModeType } from "@/store/types/pageModeType";
import { namespace } from "@/store/loan/modules/user";
import { actionTypes as userActionTypes } from "@/store/loan/modules/user/types";
import { createNamespacedHelpers, mapState } from "vuex";

const { mapState: mapUserState, mapActions: mapUserActions } = createNamespacedHelpers(namespace);

export default {
	metaInfo: {
		title: i18n.t("metaTitles.loan")
	},
	data() {
		return {
			storeManager,
			PageModeType
		};
	},
	computed: {
		...mapState({
			pageMode: state => state.pageMode
		}),
		...mapUserState({
			isUserInitialized: state => state.isInitialized
		})
	},
	methods: {
		...mapUserActions({
			initializeUserStore: userActionTypes.initialize
		})
	},
	async created() {
		if(!this.isUserInitialized)
			await this.initializeUserStore();
	},
	components: {
		FrpAccessDeniedError,
		FrpPageNotFound,
		FrpBreadcrumbs,
		FrpAlerts
	}
};
</script>

import ApiTroubledBorrowerLoanAgreement from "@/api/loan/types/troubledBorrower/apiTroubledBorrowerLoanAgreement";
import ApiTroubledBorrowerFinancingScheme from "@/api/loan/types/troubledBorrower/apiTroubledBorrowerFinancingScheme";
import ApiTroubledBorrowerVersionHeader from "@/api/loan/types/troubledBorrower/apiTroubledBorrowerVersionHeader";
import ApiRefund from "@/api/loan/types/troubledBorrower/apiRefund";
import ApiResponsibleUsersInfo from "@/api/loan/types/troubledBorrower/apiResponsibleUsersInfo";
import KeyDates from "@/store/loan/modules/troubledBorrower/types/keyDates";
import ApiIncomePrediction from "@/api/loan/types/troubledBorrower/apiIncomePrediction";
import ApiCost from "@/api/loan/types/troubledBorrower/apiCost";
import RefundSourceProject from "@/store/loan/modules/troubledBorrower/types/refundSourceProject";
import ApiRefundsTotal from "@/api/loan/types/troubledBorrower/apiRefundsTotal";

export default class TroubledBorrowerVersion {
	constructor(
		public versionHeader: ApiTroubledBorrowerVersionHeader = new ApiTroubledBorrowerVersionHeader(),
		public loanAgreements: ApiTroubledBorrowerLoanAgreement[] = [],
		public frpSum: number = 0,
		public mainDebt: number = 0,
		public interest: number = 0,
		public penalty: number = 0,
		public financingSchemes: ApiTroubledBorrowerFinancingScheme[] = [],
		public refund: ApiRefund = new ApiRefund(),
		public keyDates: KeyDates = new KeyDates(),
		public responsibleUsersInfo: ApiResponsibleUsersInfo = new ApiResponsibleUsersInfo(),
		public refundSourceInfos: RefundSourceProject[] = [],
		public incomePredictions: ApiIncomePrediction[] = [],
		public refundsTotal: ApiRefundsTotal = new ApiRefundsTotal(),
		public costs: ApiCost[] = [],
	)
	{
	}
}

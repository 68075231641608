export enum CashFlowPaymentType {
	LOAN = "Loan",
	MAIN_DEBT = "MainDebt",
	PERCENT = "Percent",
	PENALTY = "Penalty",
	OVERDUE = "Overdue",
	CESSION = "Cession",
	ALL_INCOME = "AllIncome",
	ALL_PAYMENTS = "AllPayments"
}

import ApiRequirement from "@/api/loan/types/troubledBorrower/apiRequirement";
import ApiStatusHistoryItem from "@/api/loan/types/troubledBorrower/apiStatusHistoryItem";
import ApiDebtorEvent from "@/api/loan/types/troubledBorrower/apiDebtorEvent";
import ApiDebtorStatus from "@/api/loan/types/troubledBorrower/apiDebtorStatus";

export default class ApiDebtor {
	versionId: string;
	debtorId: string;
	currentStatus: ApiDebtorStatus;
	projectRoleId: string;
	debtorCompanyId: number;
	obligedCompanyId: number;
	debtorCompanyName: string;
	comment: string;
	isLegalEntity: boolean;
	requirements: ApiRequirement[];
	statusHistory: ApiStatusHistoryItem[];
	events: ApiDebtorEvent[];

	constructor(
		versionId: string = "",
		debtorId: string = "",
		currentStatus: ApiDebtorStatus = new ApiDebtorStatus(),
		comment: string = "",
		requirements: ApiRequirement[] = [],
		statusHistory: ApiStatusHistoryItem[] = [],
		events: ApiDebtorEvent[] = [],
		isLegalEntity: boolean = false,
		projectRoleId: string = "",
		debtorCompanyName: string = "",
		debtorCompanyId: number = 0,
		obligedCompanyId: number = 0
	)
	{
		this.versionId = versionId;
		this.debtorId = debtorId;
		this.currentStatus = currentStatus;
		this.comment = comment;
		this.requirements = requirements;
		this.statusHistory = statusHistory;
		this.events = events;
		this.isLegalEntity = isLegalEntity;
		this.projectRoleId = projectRoleId;
		this.debtorCompanyName = debtorCompanyName;
		this.debtorCompanyId = debtorCompanyId;
		this.obligedCompanyId = obligedCompanyId;
	}
}

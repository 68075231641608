import ApiFinanceSource from "@/api/loan/types/dictionaries/apiFinanceSource";

export default class Agreement {
	id: string;
	createDate: number;
	projectId: number;
	documentNumber: string;
	signingDate: number;
	financeSource: ApiFinanceSource;
	exactFrpSum: number;

	constructor(
		id: string = "",
		createDate: number = 0,
		projectId: number = 0,
		documentNumber: string = "",
		signingDate: number = 0,
		financeSource: ApiFinanceSource = new ApiFinanceSource(),
		exactFrpSum: number = 0
	)
	{
		this.id = id;
		this.createDate = createDate;
		this.projectId = projectId;
		this.documentNumber = documentNumber;
		this.signingDate = signingDate;
		this.financeSource = financeSource;
		this.exactFrpSum = exactFrpSum;
	}
}

import AssignmentCompensationState from "@/store/loan/modules/assignmentCompensation/types/assignmentCompensationState";

export interface ApiAssignmentCompensationOrderForSubmissionToAccountingRequest {
	startDate: string;
	endDate: string;
	assignmentAgreementId: number;
}

export class ApiAssignmentCompensationOrderForSubmissionToAccountingRequestMapper {
	static map(source: AssignmentCompensationState): ApiAssignmentCompensationOrderForSubmissionToAccountingRequest {
		return {
			startDate: source.filter.assignmentPeriodStartDate,
			endDate: source.filter.assignmentPeriodEndDate,
			assignmentAgreementId: +source.filter.assignmentAgreementId
		};
	}
}
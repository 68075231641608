export default class ApiFinanceSource {
	id: number;
	createDate: string;
	name: string;

	constructor(
		id: number = 0,
		createDate: string = "",
		name: string = ""
	)
	{
		this.id = id;
		this.createDate = createDate;
		this.name = name;
	}
}

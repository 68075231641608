export default class AccruedInterestAddItemsParameters {
	projectId: number;
	accrualTypeId: string;
	financingSourceId: number;
	quarterId: string;
	accrualStartDate: number;
	accrualEndDate: number;
	paymentDate: number;
	accruedAmount: number;

	constructor(
		projectId: number = 0,
		accrualTypeId: string = "",
		financingSourceId: number = 0,
		quarterId: string = "",
		accrualStartDate: number = 0,
		accrualEndDate: number = 0,
		paymentDate: number = 0,
		accruedAmount: number = 0
	)
	{
		this.projectId = projectId;
		this.accrualTypeId = accrualTypeId;
		this.financingSourceId = financingSourceId;
		this.quarterId = quarterId;
		this.accrualStartDate = accrualStartDate;
		this.accrualEndDate = accrualEndDate;
		this.paymentDate = paymentDate;
		this.accruedAmount = accruedAmount;
	}
}

<template>
	<v-row>
		<v-col class="px-6 py-4">
			<v-card style="box-shadow: 3px 6px 20px rgba(0, 0, 0, 0.15); min-height: 100%; height: fit-content"
					class="loan-card px-1 mt-2 pt-8 pb-4">
				<v-row class="px-2">
					<v-col class="d-flex flex-column pt-0 pb-0">
						<frp-details-item-loader/>
					</v-col>
					<v-col class="d-flex flex-column pt-0 pb-0">
						<frp-details-item-loader/>
					</v-col>
					<v-col class="d-flex flex-column pt-0 pb-0">
						<frp-details-item-loader/>
					</v-col>
					<v-spacer></v-spacer>
					<v-col style="max-width: 130px" class="d-flex flex-column pt-3 pb-0">
						<frp-text-loader width="100"/>
					</v-col>
				</v-row>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import LoanContentLayout from "@/components/layouts/LoanContentLayout";
import LoanCard from "Components/layouts/LoanCard";
import LoanNestedCard from "Components/layouts/LoanNestedCard";
import FrpCustomTitleLoader from "Components/loaders/common/FrpCustomTitleLoader";
import FrpTextLoader from "Components/loaders/common/FrpTextLoader";
import FrpDetailsItemLoader from "Components/loaders/details/FrpDetailsItemLoader";
import FrpBtnLoader from "Components/loaders/form/FrpBtnLoader";

export default {
	components: {
		FrpBtnLoader,
		FrpTextLoader,
		FrpCustomTitleLoader,
		FrpDetailsItemLoader,
		LoanCard,
		LoanNestedCard,
		LoanContentLayout
	}
};
</script>

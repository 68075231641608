import ReportDocument from "@/store/loan/modules/reports/types/reportDocument";

export default class ReportSection {
	documents: ReportDocument[];
	title: string;
	number: string;

	constructor(
		documents: ReportDocument[] = [],
		title: string = "",
		number: string = ""
	)
	{
		this.documents = documents;
		this.title = title;
		this.number = number;
	}
}

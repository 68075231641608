<template>
	<div class="mb-6">
		<v-subheader v-if="label" class="pa-0 mb-1 grey--text text-caption" style="height: 16px">
			<span>{{ label }}</span>
			<span v-if="required">*</span>
		</v-subheader>
		<vue-dropzone :name="type"
					  id="dropzone"
					  v-if="!fileData"
					  :options="dropzoneOptions"
					  :useCustomSlot="true"
					  @vdropzone-file-added="handleFileAdded"
					  class="white rounded-lg pa-2 d-flex align-center flex-wrap justify-center frp-dropzone loan-reports-dropzone">
			<div class="d-flex align-center">
				<frp-icon src="ico_upload" :color="colors.grey.base" class="mr-2 mb-1"></frp-icon>
				<span class="grey--text">{{ title }}</span>
			</div>
			<div class="text-overline grey--text mt-1 d-flex justify-center flex-column">
				<span>{{ $t("fields.dropzone.description.maxFileWeightWithoutMb", { maxSize }) }}</span>
				<template v-if="showFormats">
					<span class="mt-1">
						{{ `${$t("fields.dropzone.description.formats")}: ${formats.map(x => x.toLowerCase()).join(", ")}.` }}
					</span>
				</template>
			</div>
		</vue-dropzone>
		<div v-else>
			<v-row justify="space-between"
				   class="loan-reports-file align-center px-0"
				   style="width:100%"
				   :class="label ? 'loan-reports-file--label' : ''">
				<v-col cols="12" :md="fileMdCol" :lg="fileLgCol">
					<frp-file class="text-break"
							  :name="filename"
							  :uploading="isLoading"
							  bold-text>
					</frp-file>
				</v-col>
				<v-col cols="auto">
					<template v-if="$slots['file-action']">
						<slot name="file-action"></slot>
					</template>

					<frp-btn :outlined="!!$slots['file-action']"
							 elevation="0"
							 :disabled="isUploading"
							 :color="$slots['file-action'] ? colors.primary.base : colors.blue.base"
							 @click="onDelete(filename)">
						<span :class="$slots['file-action'] ? 'primary--text' : 'white--text'">{{ $t("buttons.remove") }}</span>
					</frp-btn>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpFile from "@/components/common/FrpFile.vue";
import { FileTypes } from "@/constants/fileTypes";
import { i18n } from "@/plugins";
import AlertHelper from "Store/modules/alerts/helpers/alertHelper";
import VueDropzone from "vue2-dropzone";
import colorsMixin from "Mixins/colorsMixin";
import FrpIcon from "Components/icon/FrpIcon";

const DEFAULT_URL = "http://localhost";

export default {
	mixins: [colorsMixin],
	props: {
		type: {
			type: String,
			required: true
		},
		maxSize: [Number, String],
		formats: Array,
		showFormats: {
			type: Boolean,
			default: false
		},
		file: [File, Object],
		fileMdCol: {
			type: String | Number,
			default: 7
		},
		fileLgCol: {
			type: String | Number,
			default: 7
		},
		label: String,
		title: {
			type: String,
			default: i18n.t("fields.dropzone.title.uploadFile")
		},
		isUploading: {
			type: Boolean,
			default: false
		},
		required: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			FileTypes,
			dropzoneOptions: {
				autoProcessQueue: false,
				url: DEFAULT_URL,
				maxFilesize: this.maxSize,
				acceptedFiles: "",
				maxFiles: 1
			},
			validateFormats: [],
			fileData: null,
			isLoading: false,
			isValidFormat: false
		};
	},
	computed: {
		filename() {
			return this.fileData.name;
		}
	},
	methods: {
		onDelete(name) {
			this.fileData = null;
			this.$emit("file:delete", name);
		},
		async handleFileAdded(file) {
			this.isLoading = true;
			try {
				let { name, type } = file;
				
				if (name.endsWith(".rar")) {
					type = FileTypes.RAR;
				}

				const res = new File([file], name, { type });

				this.fileData = res;
				this.$emit("file:add", res, type);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.isLoading = false;
			}
		},
		setAcceptedFiles() {
			if(!this.formats)
				return;

			const res = [];

			this.formats.forEach(x => {
				const format = x.toLowerCase();

				switch (format) {
					case "bd":
						res.push(FileTypes.BD);
						break;
					case "pdf":
						res.push(FileTypes.PDF);
						break;
					case "doc":
						res.push(FileTypes.DOC);
						break;
					case "rar":
						res.push(FileTypes.X_RAR);
						res.push(FileTypes.RAR);
						break;
					case "zip":
						res.push(FileTypes.ZIP);
						res.push(FileTypes.X_ZIP_COMPRESSED);
						break;
					case "docx":
						res.push(FileTypes.DOCX);
						break;
					case "xls":
						res.push(FileTypes.XLS);
						break;
					case "xlsx":
						res.push(FileTypes.XLSX);
						break;
				}
			});

			this.validateFormats = res;
			this.dropzoneOptions.acceptedFiles = res.join(",");
		}
	},
	watch: {
		formats: {
			handler(formats) {
				this.setAcceptedFiles(formats);
			},
			immediate: true
		},
		fileData: {
			handler(file) {
				if(!file) return;

				const isValid = this.validateFormats.includes(file.type);

				this.$emit("format:is-valid", isValid);
			},
			immediate: true
		}
	},
	components: {
		FrpBtn,
		FrpFile,
		VueDropzone,
		FrpIcon
	}
};
</script>
<style lang="scss" scoped>
.loan-reports-file {
	border: 2px dashed var(--v-primary-base) !important;
	min-height: 80px !important;
	border-radius: 8px !important;
	margin: 0px 0px 24px 0px;

	&--label {
		margin-top: 4px;
	}
}

.loan-reports-dropzone {
	min-height: 80px !important;
}
</style>

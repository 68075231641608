export default class ApiPaymentExpense {
	id: string;
	number: number;
	category: string;
	name: string;
	isFact: boolean;
	isLoan: boolean;
	isMainDebt: boolean;
	isPercent: boolean;
	isPenalty: boolean;
	isOver: boolean;
	isAssignedLoan: boolean;
	isAssignedMainDebt: boolean;

	constructor(
		id: string = "",
		number: number = 0,
		category: string = "",
		name: string = "",
		isFact: boolean = false,
		isLoan: boolean = false,
		isMainDebt: boolean = false,
		isPercent: boolean = false,
		isPenalty: boolean = false,
		isOver: boolean = false,
		isAssignedLoan: boolean = false,
		isAssignedMainDebt: boolean = false
	)
	{
		this.id = id;
		this.number = number;
		this.category = category;
		this.name = name;
		this.isFact = isFact;
		this.isLoan = isLoan;
		this.isMainDebt = isMainDebt;
		this.isPercent = isPercent;
		this.isPenalty = isPenalty;
		this.isOver = isOver;
		this.isAssignedLoan = isAssignedLoan;
		this.isAssignedMainDebt = isAssignedMainDebt;
	}
}

import { RouteNames } from "@/router/loan/routes";
import { Permissions } from "@/constants/permissions";

const routeToPermissionsMap = new Map<RouteNames, Permissions[]>([
		[RouteNames.ROOT, []],
		[RouteNames.CALLBACK, []],
		[RouteNames.SILENT_RENEW, []],
		[RouteNames.ERROR_PAGE_NOT_FOUND, []],
		[RouteNames.ERROR_ACCESS_DENIED, []],
		[RouteNames.ERROR_INTERNAL_SERVER_ERROR, []],

		[RouteNames.LOAN_SCHEDULE, [Permissions.LOAN_READ]],

		[RouteNames.ACCRUED_INTEREST, [Permissions.LOAN_READ]],
		[RouteNames.OVERDUE_INTEREST, [Permissions.LOAN_READ]],
		[RouteNames.AGREEMENTS_ACCRUED_INTEREST, [Permissions.LOAN_READ]],

		[RouteNames.ASSIGNMENT_COMPENSATION, [Permissions.LOAN_READ]],
		
		[RouteNames.AGREEMENTS_OVERDUE_PAYMENTS, [Permissions.LOAN_READ]],
		[RouteNames.AGREEMENTS_ACCRUED_PENALTIES, [Permissions.LOAN_READ]],

		[RouteNames.CASH_FLOW, [Permissions.LOAN_READ]],
		[RouteNames.TREASURY_STATEMENT, [Permissions.LOAN_READ]],

		[RouteNames.TROUBLED_BORROWER, [Permissions.LOAN_READ]],

		[RouteNames.UPCOMING_PAYMENTS, [Permissions.LOAN_READ]]
	]
);

export default routeToPermissionsMap;

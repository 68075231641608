import IPageState from "@/store/shared/base/types/pageState";
import UpcomingPaymentsItem from "@/store/loan/modules/upcomingPayments/types/upcomingPaymentsItem";
import UpcomingPaymentsInterestDetailsItem from "@/store/loan/modules/upcomingPayments/types/upcomingPaymentsInterestDetailsItem";
import ApiFinanceSource from "@/api/loan/types/dictionaries/apiFinanceSource";
import Agreement from "@/store/loan/modules/upcomingPayments/types/agreement";
import { LoadingFile } from "@/store/loan/modules/upcomingPayments/types/loadingFile";

export default class UpcomingPaymentsState implements IPageState {
	constructor(
		public payments: UpcomingPaymentsItem[] = [],
		public interestDetailsItems: UpcomingPaymentsInterestDetailsItem[] = [],
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public agreement: Agreement = new Agreement(),
		public financeSources: ApiFinanceSource[] = [],
		public isAgreementLoading: boolean = false,
		public isItemsLoading: boolean = false,
		public loadingFiles: LoadingFile[] = [],
		public frpIssuedSum: number = NaN,
	)
	{
	}
}

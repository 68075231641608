import IPageState from "@/store/shared/base/types/pageState";
import ApiVersionHeader from "@/api/loan/types/loanSchedule/apiVersionHeader";
import Version from "@/store/loan/modules/loanSchedule/types/version";
import ApiQuarter from "@/api/loan/types/dictionaries/apiQuarter";
import ApiFinanceSource from "@/api/loan/types/dictionaries/apiFinanceSource";
import { WrongAmountDialogType } from "@/store/loan/modules/loanSchedule/types/wrongAmountDialogType";
import ApiTitleDocument from "@/api/loan/types/dictionaries/apiTitleDocument";
import ApiChangeReason from "@/api/loan/types/dictionaries/apiChangeReason";

export default class LoanScheduleState implements IPageState {
	constructor(
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isLoading: boolean = false,
		public version: Version = new Version(),
		public versionHeaders: ApiVersionHeader[] = [],
		public quarters: ApiQuarter[] = [],
		public financeSources: ApiFinanceSource[] = [],
		public titleDocuments: ApiTitleDocument[] = [],
		public changeReasons: ApiChangeReason[] = [],
		public wrongAmountDialog: WrongAmountDialogType = WrongAmountDialogType.NONE
	)
	{
	}
}

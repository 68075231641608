import ApiFinanceSource from "@/api/loan/types/dictionaries/apiFinanceSource";

export default class Tranche {
	id: string;
	number: number;
	paymentDate: number;
	source: ApiFinanceSource;
	amount: number;

	constructor(
		id: string = "",
		number: number = 0,
		paymentDate: number = 0,
		source: ApiFinanceSource = new ApiFinanceSource(),
		amount: number = 0
	)
	{
		this.id = id;
		this.number = number;
		this.paymentDate = paymentDate;
		this.source = source;
		this.amount = amount;
	}
}

<template>
	<loan-content-layout>
		<loan-reports-buttons-group :report="report"
									:is-items-loading="isItemsLoading"
									:can-send-request-email-confirmation="report.reportFilledDate !== null"
									:is-bulk-downloading="isBulkDownloading"
									:is-email-confirmation-requesting="isEmailConfirmationRequesting"
									:is-report-accepting="isReportAccepting"
									:is-report-declining="isReportDeclining"
									:can-accept="report.acceptance?.canAccept"
									:can-decline="report.acceptance?.canDecline"
									:acceptor="report.acceptance?.acceptor?.lastName"
									:can-create-reporting-queries="report?.canCreateReportingQueries ?? false"
									:queries-url="`/queries/${$route.params.projectId}?type=Reporting&status=&authorId=0&companyId=${report.project?.company?.id}`"
									:queries-opened-count="report.reportQueries?.openedCount ?? 0"
									:queries-total-count="report.reportQueries?.totalCount ?? 0"
									@report:accept="handleAcceptReport"
									@report:decline="handleDeclineReport"
									@request-email-confirmation:send="sendRequestEmailConfirmation"
									@add-query-dialog:open="isAddQueryDialogOpened = true">
			<template #download-bulk>
				<v-menu left
						offset-y
						nudge-top="156"
						close-on-click
						content-class="frp-menu frp-blue-menu pointer">
					<template v-slot:activator="{ on, attrs }">
						<frp-btn v-bind="attrs"
								 v-on="on"
								 fab
								 color="blue"
								 height="56"
								 :loading="isBulkDownloading"
								 :disabled="isItemsLoading || !report?.project?.id"
								 @dblclick="handleDownloadBulk(false)"
								 :title="$t('alerts.info.saveAllReportFiles')">
							<v-icon color="white">mdi-cloud-download</v-icon>
						</frp-btn>
					</template>
					
					<v-list>
						<v-list-item v-for="(menuItem, index) in downloadBulkMenuItems"
									 :key="index"
									 @click="handleDownloadBulk(menuItem.includeAllVersions)">
							<v-list-item-title>{{ menuItem.title }}</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</template>
		</loan-reports-buttons-group>
		
		<v-row>
			<v-col class="d-flex align-center pb-2">
				<v-card-title class="text-h6 px-0 pt-2 pb-0 ml-4">
					<span style="white-space: nowrap">{{ $t("titles.reports") }}</span>
				</v-card-title>
			</v-col>
		</v-row>
		
		<v-row>
			<v-col class="d-flex flex-column pt-4" style="gap: 24px">
				<v-card elevation="0" class="loan-card pa-0 pt-6">
					<v-row class="px-6">
						<v-col class="d-flex align-end" style="gap: 24px">
							<frp-autocomplete v-model="filterCompany"
											  :label="$t('fields.organization.label')"
											  :items="reportsFilter.companies"
											  :loading="isReportsFilterLoading"
											  item-text="title"
											  item-value="id"
											  color="blue"
											  hide-details
											  :placeholder="$t('fields.organization.placeholder')">
							</frp-autocomplete>
							
							<frp-autocomplete v-model="filterYear"
											  :label="$t('fields.year.label')"
											  :items="reportsFilter.periods"
											  :loading="isReportsFilterLoading"
											  item-text="year"
											  item-value="year"
											  color="blue"
											  hide-details
											  :placeholder="$t('fields.year.placeholder')">
							</frp-autocomplete>
							
							<frp-autocomplete v-model="filterQuarter"
											  :label="$t('fields.period.label')"
											  :items="reportsFilter.periods.length ? quarters : []"
											  :loading="isReportsFilterLoading"
											  item-text="text"
											  item-value="id"
											  color="blue"
											  hide-details
											  :placeholder="$t('fields.period.placeholder')">
							</frp-autocomplete>
							
							<template v-if="formattedItems.length">
								<template v-if="report?.status === ReportStatusType.FILLED">
									<v-fade-transition>
										<frp-alert
											:text="`${$t(`alerts.info.reportUploaded`)} ${(report.reportFilledFineDateTime ?? report.reportFilledDate) ? formatDate(report.reportFilledFineDateTime ?? report.reportFilledDate, dateTimeFormat) : ''}`"
											class="ma-0">
										</frp-alert>
									</v-fade-transition>
								</template>
								
								<template v-if="report?.status === ReportStatusType.NOT_FILLED">
									<v-fade-transition>
										<frp-alert type="error" :text="$t('alerts.errors.reportNotUploaded')" class="ma-0">
										</frp-alert>
									</v-fade-transition>
								</template>
								
								<template v-if="report?.status === ReportStatusType.NOT_REQUIRED">
									<v-fade-transition>
										<frp-alert type="info"
												   :color="colors.white.darken3"
												   :text="$t('alerts.errors.reportNotRequired')"
												   class="ma-0">
										</frp-alert>
									</v-fade-transition>
								</template>
							</template>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-data-table :headers="headers"
										  :loading-text="$t('tables.loading')"
										  :loading="isItemsLoading"
										  hide-default-footer
										  item-key="id"
										  :items="formattedItems"
										  :items-per-page="-1"
										  :mobile-breakpoint="mobileBreakpoint"
										  :options="options"
										  group-by="sectionNumber"
										  :item-class="(item) => `text-body-2 cy-table-row ${getErrorRowClass(item)}`"
										  class="loan-table d-flex flex-column loan-reports-table">
								<template #group.header="{items}">
									<th>
										<span class="mr-10"> {{ items[0].sectionNumber }} </span>
									</th>
									<th>
										<span> {{ items[0].sectionTitle }} </span>
									</th>
									<th></th>
									<th></th>
									<th></th>
									<th></th>
									<th></th>
									<th></th>
								</template>
								
								<template #item.numberOrder="{item}">
									<span>
										{{ `${item.description.sectionNumber}.${item.description.documentNumber}` }}
									</span>
								</template>
								
								<template #item.documentName="{item}">
									<loan-reports-document-name :item="item"
																@history-dialog:open="handleOpenHistoryDialog($event)">
									</loan-reports-document-name>
								</template>
								
								<template #item.uploadFile="{item}">
									<v-row justify="center">
										<template v-if="item.canUploadFile">
											<v-col cols="auto" class="px-1">
												<frp-btn :color="colors.blue.base"
														 elevation="0"
														 @click="handleOpenUploadDialog(item)">
													<span class="white--text">{{ getUploadFileButtonText(item) }}</span>
												</frp-btn>
											</v-col>
										</template>
										<template v-if="item.canGenerate">
											<v-col cols="auto" class="px-1">
												<frp-btn :color="colors.blue.base"
														 elevation="0"
														 @click="handleOpenGenerateDialog(item)">
													<span class="white--text">{{ $t("buttons.generate") }}</span>
												</frp-btn>
											</v-col>
										</template>
									</v-row>
								</template>
								
								<template #item.uploaderName="{item}">
									<span>
										{{
											`${item.lastDocument?.uploader?.lastName || ""}
											${item.lastDocument?.uploader?.firstName || ""}
											${item.lastDocument?.uploader?.middleName || ""}`
										}}
									</span>
								</template>
								
								<template #item.uploadDateTime="{item}">
									<span>
										{{
											item.lastDocument?.uploadDateTime ?
												`${formatDate(item.lastDocument.uploadDateTime, dateTimeFormat)}` : ""
										}}
									</span>
								</template>
								
								<template #item.signerName="{item}">
									<span>
										{{ item.lastDocument?.signer || "" }}
									</span>
								</template>
							</v-data-table>
						</v-col>
					</v-row>
					
					<frp-dialog v-model="isHistoryDialogOpened"
								:title="$t('dialogs.uploadedDocuments.title')"
								max-width="960"
								persistent
								@dialog:close="handleCloseHistoryDialog">
						<template #content="{ onIntersect }">
							<loan-reports-dialog-layout v-intersect="onIntersect">
								<template #title>{{ selectedDocument?.description?.documentTitle }}</template>
								
								<template #content v-if="selectedDocument?.documentFiles.length">
									<v-row>
										<v-col class="py-0">
											<loan-reports-list :selected-document="selectedDocument"></loan-reports-list>
										</v-col>
									</v-row>
								</template>
							</loan-reports-dialog-layout>
						</template>
						<template #footer>
							<frp-btn @click="handleCloseHistoryDialog"
									 elevation="0"
									 :color="colors.blue.base">
								<span class="white--text">{{ $t("buttons.close") }}</span>
							</frp-btn>
						</template>
					</frp-dialog>
					
					<frp-dialog v-model="isGenerateDialogOpened"
								persistent
								max-width="960"
								:title="$t('dialogs.generateReport.title')"
								@dialog:close="handleCloseGenerateDialog">
						<template #content="{ onIntersect }">
							<template v-if="selectedDocument">
								<loan-reports-dialog-layout v-intersect="onIntersect">
									<template #title>{{ selectedDocument?.description?.documentTitle }}</template>
									
									<template #content>
										<v-row>
											<v-col>
												<div v-if="isReportGenerating && !isGenerateReportError"
													 class="d-flex align-center justify-center"
													 style="min-height: 450px">
													<frp-spinner-loader :color="colors.blue.base"
																		size="50"
																		classes="ma-0"
																		width="5">
													</frp-spinner-loader>
													<span class="text-caption ml-8">{{ $t("alerts.info.isReportGenerating") }}</span>
												</div>
												
												<vue-pdf-embed v-else-if="generatedReportPdfLink && !isGenerateReportError"
															   style="min-height: 450px"
															   :source="generatedReportPdfLink">
												</vue-pdf-embed>
												
												<div v-else
													 class="d-flex align-center justify-center"
													 style="min-height: 450px">
													<span class="text-caption">{{ $t("alerts.errors.reportGenerateError") }}</span>
												</div>
											</v-col>
										</v-row>
									</template>
								</loan-reports-dialog-layout>
							</template>
						</template>
						
						<template #footer>
							<frp-btn color="primary"
									 outlined
									 @click="handleCloseGenerateDialog">
								{{ $t("buttons.close") }}
							</frp-btn>
							<frp-btn elevation="0"
									 :disabled="isReportGenerating || !generatedReportPdfLink"
									 :color="colors.blue.base"
									 :href="generatedReportPdfLink"
									 download>
								<span class="white--text">{{ $t("buttons.download") }}</span>
							</frp-btn>
							
							<template v-if="selectedDocument?.canUploadGeneratedFile">
								<frp-btn v-if="!selectedDocument?.isSignRequired"
										 class="elevation-0 mr-4"
										 :color="colors.blue.base"
										 :loading="isDocumentUploading"
										 :disabled="isSignedDocumentUploading || isReportGenerating || !generatedReportPdfLink || !reportDocumentFile"
										 dark
										 @click="handleUploadDocument(UploadingDocumentActionType.GENERATE)">
									{{ $t("buttons.upload") }}
								</frp-btn>
								
								<loan-reports-digital-signature-dialog v-model="isGenerateSignatureDialogOpened"
																	   :file="reportDocumentFile"
																	   @signed="handleSignAndUploadDocument($event, UploadingDocumentActionType.GENERATE)"
																	   color="blue"
																	   button-elevation="0">
									<template #activator="{ on, attrs }">
										<frp-btn elevation="0"
												 v-on="on"
												 v-bind="attrs"
												 class="ml-4"
												 :loading="isSignedDocumentUploading"
												 :disabled="isDocumentUploading || isReportGenerating || !generatedReportPdfLink || !reportDocumentFile"
												 :color="colors.blue.base">
											<span class="white--text">{{ $t("buttons.uploadWithSignature") }}</span>
										</frp-btn>
									</template>
								</loan-reports-digital-signature-dialog>
							</template>
						</template>
					</frp-dialog>
					
					<frp-dialog v-model="isUploadDialogOpened"
								:title="$t('dialogs.uploadDocument.title')"
								max-width="960"
								persistent
								@dialog:close="handleCloseUploadDialog">
						<template #content="{ onIntersect }">
							<loan-reports-dialog-layout v-intersect="onIntersect">
								<template #title>{{ selectedDocument?.description?.documentTitle }}</template>
								
								<template #content>
									<v-row>
										<v-col>
										<span>
											{{ `(${$t("common.fileFormat")}: ` }}
										</span>
											<span>
											{{ `${selectedDocument?.description?.allowedExtensions.map(x => x.toUpperCase()).join("/")})` }}
										</span>
										</v-col>
									</v-row>
									
									<template v-if="selectedDocument?.documentFiles.length">
										<v-row>
											<v-col class="py-0">
												<loan-reports-list :selected-document="selectedDocument"></loan-reports-list>
											</v-col>
										</v-row>
									</template>
									
									<template v-if="selectedDocument">
										<v-row>
											<v-col>
												<loan-reports-dropzone
													:max-size="convertBytesToSize(selectedDocument?.description?.maxFileSize)"
													:file-md-col="!selectedDocument.isSignRequired ? 6 : 7"
													:file-lg-col="!selectedDocument.isSignRequired ? 6 : 7"
													@file:add="handleSetReportDocumentFile"
													:formats="[...selectedDocument?.description?.allowedExtensions]"
													:is-uploading="isDocumentUploading"
													@file:delete="handleDeleteReportDocumentFile"
													@format:is-valid="isValidFileFormat = $event"
													type="reportDocument">
													<template #file-action>
														<frp-btn v-if="!selectedDocument.isSignRequired"
																 class="elevation-0 mr-4"
																 :color="colors.blue.base"
																 :loading="isDocumentUploading"
																 :disabled="isSignedDocumentUploading || !isValidFileFormat"
																 dark
																 @click="handleUploadDocument(UploadingDocumentActionType.UPLOAD)">
															{{ $t("buttons.upload") }}
														</frp-btn>
														
														<loan-reports-digital-signature-dialog v-model="isUploadSignatureDialogOpened"
																							   :file="reportDocumentFile"
																							   @signed="handleSignAndUploadDocument($event, UploadingDocumentActionType.UPLOAD)"
																							   color="blue"
																							   button-elevation="0">
															<template #activator="{ on, attrs }">
																<frp-btn elevation="0"
																		 v-on="on"
																		 v-bind="attrs"
																		 :loading="isSignedDocumentUploading"
																		 :disabled="isDocumentUploading || !isValidFileFormat"
																		 :color="colors.blue.base">
																	<span class="white--text">{{ $t("buttons.uploadWithSignature") }}</span>
																</frp-btn>
															</template>
														</loan-reports-digital-signature-dialog>
													</template>
												</loan-reports-dropzone>
											</v-col>
										</v-row>
									</template>
								</template>
							</loan-reports-dialog-layout>
						</template>
						
						<template #footer>
							<frp-btn @click="handleCloseUploadDialog"
									 elevation="0"
									 :color="colors.blue.base">
								<span class="white--text">{{ $t("buttons.close") }}</span>
							</frp-btn>
						</template>
					</frp-dialog>
					
					<frp-dialog
						v-model="isAddQueryDialogOpened"
						:title="$t('dialogs.addQuery.title')"
						persistent
						@dialog:close="handleCloseAddQueryDialog">
						<template #content="{ onIntersect }">
							<v-form v-intersect="onIntersect" ref="addQueryForm" v-model="isAddQueryFormValid">
								<frp-text-field :label="$t('fields.queryTheme.label')"
												required
												v-model="addQueryTheme"
												:placeholder="$t('fields.queryTheme.placeholder')">
								</frp-text-field>
								
								<frp-textarea v-model="addQueryDescription"
											  required
											  :label="$t('fields.queryDescription.label')"
											  :placeholder="$t('fields.queryDescription.placeholder')">
								</frp-textarea>
								
								<frp-date-field :label="$t('fields.executionDate.label')"
												required
												v-model="addQueryExecutionDate"
												:placeholder="$t('fields.executionDate.placeholder')">
								</frp-date-field>
								
								<loan-reports-dropzone
									:max-size="`25 ${$t('fields.dropzone.description.mb')}`"
									@file:add="handleSetReportDocumentFile"
									@file:delete="handleDeleteReportDocumentFile"
									:is-uploading="isAddQueryReporting"
									type="addQueryReport">
								</loan-reports-dropzone>
							</v-form>
						</template>
						
						<template #footer>
							<frp-btn outlined
									 @click="handleCloseAddQueryDialog"
									 :disabled="isAddQueryReporting"
									 color="primary">
								{{ $t("buttons.cancel") }}
							</frp-btn>
							<frp-btn class="elevation-0"
									 :color="colors.blue.base"
									 :loading="isAddQueryReporting"
									 :disabled="!isAddQueryFormValid"
									 @click="handleAddReportingQuery">
								<span class="white--text">{{ $t("buttons.send") }}</span>
							</frp-btn>
						</template>
					</frp-dialog>
				</v-card>
			</v-col>
		</v-row>
	</loan-content-layout>
</template>

<script>
import { ReportsController } from "@/api/loan/reports";
import ApiReportAddQueryInfo from "@/api/loan/types/reports/apiReportAddQueryInfo";
import ApiReportsParameters from "@/api/loan/types/reports/apiReportsParameters";
import FrpAlert from "@/components/alerts/FrpAlert.vue";
import FrpBtn from "@/components/buttons/FrpBtn";
import LoanReportsButtonsGroup from "@/components/common/LoanReportsButtonsGroup.vue";
import LoanReportsDocumentName from "@/components/common/LoanReportsDocumentName.vue";
import LoanReportsDigitalSignatureDialog from "@/components/digitalSignature/LoanReportsDigitalSignatureDialog.vue";
import LoanReportsDropzone from "@/components/dropzone/LoanReportsDropzone.vue";
import FrpAutocomplete from "@/components/fields/FrpAutocomplete";
import FrpDateField from "@/components/fields/FrpDateField";
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import FrpTextField from "@/components/fields/FrpTextField";
import FrpIcon from "@/components/icon/FrpIcon";
import LoanContentLayout from "@/components/layouts/LoanContentLayout";
import LoanReportsDialogLayout from "@/components/layouts/LoanReportsDialogLayout.vue";
import FrpSpinnerLoader from "@/components/loaders/common/FrpSpinnerLoader.vue";
import { FileTypes } from "@/constants/fileTypes";
import NotDefinedException from "@/exceptions/notDefinedException";
import colorsMixin from "@/mixins/colorsMixin";
import { listMixin } from "@/mixins/listMixin";
import AbortService from "@/services/abortService";
import { getterTypes } from "@/store/bod/modules/documents/types";
import mapper from "@/store/loan/modules/reports/mapper";
import ReportAddQueryInfo from "@/store/loan/modules/reports/types/reportAddQueryInfo";
import { ReportDocumentType } from "@/store/loan/modules/reports/types/reportDocumentType";
import { ReportsRouteType } from "@/store/loan/modules/reports/types/reportsRouteType";
import { ReportStatusType } from "@/store/loan/modules/reports/types/reportStatusType";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import { convertBytesToSize } from "@/utils/converters";
import { formatDate } from "@/utils/dates";
import { dateTimeFormat } from "@/utils/formats";
import { formatCurrency, formatFullName } from "@/utils/formatting";
import { getHeadersContentDispositionFilename } from "@/utils/getHeadersProp";
import LoanReportsList from "@/components/common/LoanReportsList.vue";
import FrpTextBtn from "Components/buttons/FrpTextBtn";
import FrpPagination from "Components/common/FrpPagination";
import FrpDialog from "Components/dialogs/FrpDialog";
import { saveAs } from "file-saver";
import { first } from "lodash";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import { i18n } from "Plugins/index";
import { RouteNames } from "Router/loan/routes";
import { actionTypes, mutationTypes, namespace } from "Store/loan/modules/reports/types";
import { createNamespacedHelpers } from "vuex";
import authorizationMixin from "@/mixins/authorizationMixin";
import contentDispositionParser from "content-disposition-parser";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

const abortService = new AbortService();
const reportsController = new ReportsController(abortService);

const UploadingDocumentActionType = {
	UPLOAD: "UPLOAD",
	GENERATE: "GENERATE"
};

export default {
	metaInfo: {
		title: i18n.t("metaTitles.reports")
	},
	mixins: [storeModuleBasedPage, listMixin, colorsMixin],
	data() {
		return {
			namespace,
			RouteNames,
			convertBytesToSize,
			formatCurrency,
			formatFullName,
			formatDate,
			first,
			dateTimeFormat,
			UploadingDocumentActionType,
			ReportsRouteType,
			ReportStatusType,
			ReportDocumentType,
			quarters: [
				{ id: 0, text: this.$t("common.year") },
				{ id: 1, text: "1" },
				{ id: 2, text: "2" },
				{ id: 3, text: "3" },
				{ id: 4, text: "4" }
			],
			downloadBulkMenuItems: [
				{ title: this.$t("content.reportingDownloadBulkConfiguration.allFilesVersions"), includeAllVersions: true },
				{ title: this.$t("content.reportingDownloadBulkConfiguration.onlyLastFilesVersions"), includeAllVersions: false }
			],
			isHistoryDialogOpened: false,
			isGenerateDialogOpened: false,
			isUploadDialogOpened: false,
			reportDocumentFile: null,
			isUploadSignatureDialogOpened: false,
			isGenerateSignatureDialogOpened: false,
			isAddQueryDialogOpened: false,
			isValidFileFormat: false,
			generatedReportPdfLink: null,
			isReportGenerating: false,
			isGenerateReportError: false,
			isBulkDownloading: false,
			isAddQueryFormValid: false,
			isAddQueryReporting: false
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			isReportsFilterLoading: state => state.isReportsFilterLoading,
			report: state => state.report,
			reportsFilter: state => state.reportsFilter,
			reportsInternalFilter: state => state.reportsInternalFilter,
			selectedDocument: state => state.selectedDocument,
			isSignedDocumentUploading: state => state.isSignedDocumentUploading,
			isDocumentUploading: state => state.isDocumentUploading,
			isReportAccepting: state => state.isReportAccepting,
			isReportDeclining: state => state.isReportDeclining,
			isEmailConfirmationRequesting: state => state.isEmailConfirmationRequesting,
			reportAddQueryInfo: state => state.reportAddQueryInfo,
			reportAddQueryTitle: state => state.reportAddQueryInfo.title,
			reportAddQueryDescription: state => state.reportAddQueryInfo.description,
			reportAddQueryExecutionDate: state => state.reportAddQueryInfo.executionDate
		}),
		...mapGetters({
			formattedItems: getterTypes.formattedItems
		}),
		filterCompany: {
			get() {
				return this.reportsFilter.companies.find(x => x.id === parseInt(this.reportsInternalFilter.companyId));
			},
			async set(value) {
				await this.pushRoute({ newValue: String(value), type: ReportsRouteType.COMPANY });
			}
		},
		filterYear: {
			get() {
				return this.reportsFilter.periods.find(x => x.year === parseInt(this.reportsInternalFilter.year));
			},
			async set(value) {
				await this.pushRoute({ newValue: String(value), type: ReportsRouteType.YEAR });
			}
		},
		filterQuarter: {
			get() {
				return this.quarters.find(x => x.id === parseInt(this.reportsInternalFilter.quarter));
			},
			async set(value) {
				await this.pushRoute({ newValue: String(value), type: ReportsRouteType.QUARTER });
			}
		},
		headers() {
			return [
				{
					text: this.$t("tables.sectionNumber"),
					value: "numberOfSection",
					class: "text-caption",
					width: "4%",
					sortable: false
				},
				{
					text: this.$t("tables.sectionName"),
					value: "title",
					class: "text-caption",
					width: "12%",
					sortable: false
				},
				{
					text: this.$t("tables.numberOrder"),
					value: "numberOrder",
					class: "text-caption",
					width: "4%",
					sortable: false
				},
				{
					text: this.$t("tables.documentName"),
					value: "documentName",
					class: "text-caption",
					width: "22%",
					sortable: false
				},
				{
					text: this.$t("tables.upload"),
					value: "uploadFile",
					align: "center",
					class: "text-caption",
					width: "14%",
					sortable: false
				},
				{
					text: this.$t("tables.uploaderFullName"),
					value: "uploaderName",
					class: "text-caption",
					width: "10%",
					sortable: false
				},
				{
					text: this.$t("tables.uploadDate"),
					value: "uploadDateTime",
					class: "text-caption",
					width: "6%",
					sortable: false
				},
				{
					text: this.$t("tables.signerFullName"),
					value: "signerName",
					class: "text-caption",
					width: "10%",
					sortable: false
				}
			];
		},
		addQueryTheme: {
			get() {
				return this.reportAddQueryTitle;
			},
			set(value) {
				this.setReportAddQueryTitle(value);
			}
		},
		addQueryDescription: {
			get() {
				return this.reportAddQueryDescription;
			},
			set(value) {
				this.setReportAddQueryDescription(value);
			}
		},
		addQueryExecutionDate: {
			get() {
				return this.reportAddQueryExecutionDate;
			},
			set(value) {
				this.setReportAddQueryExecutionDate(value);
			}
		}
	},
	methods: {
		...mapActions({
			pushRoute: actionTypes.pushRoute,
			uploadDocument: actionTypes.uploadDocument,
			acceptReport: actionTypes.acceptReport,
			declineReport: actionTypes.declineReport,
			sendRequestEmailConfirmation: actionTypes.sendRequestEmailConfirmation,
			updateListingItems: actionTypes.updateListingItems
		}),
		...mapMutations({
			resetSelectedDocument: mutationTypes.RESET_SELECTED_DOCUMENT,
			setSelectedDocument: mutationTypes.SET_SELECTED_DOCUMENT,
			resetReportAddQueryInfo: mutationTypes.RESET_REPORT_ADD_QUERY_INFO,
			setReportAddQueryTitle: mutationTypes.SET_REPORT_ADD_QUERY_INFO_TITLE,
			setReportAddQueryDescription: mutationTypes.SET_REPORT_ADD_QUERY_INFO_DESCRIPTION,
			setReportAddQueryExecutionDate: mutationTypes.SET_REPORT_ADD_QUERY_INFO_EXECUTION_DATE
		}),
		getUploadFileButtonText(document) {
			if(this.hasDocumentFiles(document) && !document.description.multiple) {
				return this.$t("buttons.update");
			}
			if(this.hasDocumentFiles(document) && document.description.multiple) {
				return this.$t("buttons.loadMore");
			}
			if(!this.hasDocumentFiles(document)) {
				return this.$t("buttons.upload");
			}
		},
		getErrorRowClass(document) {
			return document.description.isRequired && !this.hasDocumentFiles(document) ? "loan-reports-table--error" : "";
		},
		hasDocumentFiles(document) {
			return document.documentFiles && document.documentFiles.length > 0;
		},
		handleSetReportDocumentFile(file) {
			this.reportDocumentFile = file;
		},
		async handleUploadDocument(type) {
			try {
				await this.uploadDocument({ file: this.reportDocumentFile });
				this.closeChosenDialog(type);
			} catch (e) {
				throw e;
			}
		},
		async handleSignAndUploadDocument({ file, signature }, type) {
			try {
				await this.uploadDocument({ file, signature });
				this.closeChosenDialog(type);
			} catch (e) {
				throw e;
			}
		},
		async handleGenerateReportFile() {
			this.isReportGenerating = true;
			
			try {
				const {
					data,
					responseHeaders
				} = await reportsController.generateReport(this.selectedDocument?.description?.generationPath || "");
				
				if(!data || !data.size || data?.type !== FileTypes.PDF) {
					this.isGenerateReportError = true;
					throw new NotDefinedException(data);
				} else {
					const { "content-disposition": contentDisposition } = responseHeaders;
					const { filename } = contentDispositionParser(contentDisposition);
					
					const sectionTitle = this.selectedDocument?.description.sectionTitle.split(" ").join("-");
					const documentTitle = this.selectedDocument?.description.documentTitle.split(" ").join("-");
					const additionName = `${sectionTitle}_${documentTitle}.pdf`;
					
					this.reportDocumentFile = new File([data], filename || additionName, { type: data.type });
					
					this.generatedReportPdfLink = URL.createObjectURL(data);
				}
			} catch (error) {
				this.isGenerateReportError = true;
				AlertHelper.handleGeneralRequestErrors(error);
				console.error(error);
			} finally {
				this.isReportGenerating = false;
			}
		},
		async handleAddReportingQuery() {
			this.isAddQueryReporting = true;
			try {
				const info = mapper.map(this.reportAddQueryInfo, ReportAddQueryInfo, ApiReportAddQueryInfo);
				
				const { projectId, companyId, year, quarter } = this.$route.params;
				const params = new ApiReportsParameters(projectId, companyId, year, quarter);
				
				await reportsController.addQueryReport(params, this.reportDocumentFile, info);
				
				this.handleCloseAddQueryDialog();
				await this.updateListingItems();
				
				alertService.addInfo(AlertKeys.QUERY_SUCCESS_REPORTED);
			} catch (error) {
				AlertHelper.handleGeneralRequestErrors(error);
				console.error(error);
			} finally {
				this.isAddQueryReporting = false;
			}
		},
		async handleAcceptReport() {
			await this.acceptReport();
		},
		async handleDeclineReport() {
			await this.declineReport();
		},
		async handleDownloadBulk(includeAllVersions) {
			this.isBulkDownloading = true;
			
			try {
				const { projectId, companyId, year, quarter } = this.$route.params;
				const apiReportsParameters = new ApiReportsParameters(projectId, companyId, year, quarter, includeAllVersions);
				
				const { data, responseHeaders } = await reportsController.downloadBulk(apiReportsParameters);
				
				if(data) {
					const { "content-disposition": contentDisposition } = responseHeaders;
					const { filename } = contentDispositionParser(contentDisposition);
					
					saveAs(data, filename || "download.zip");
				} else {
					alertService.addCustomError(this.$t("alerts.errors.downloadDocumentsError"));
				}
			} catch (error) {
				console.error(error);
				alertService.addCustomError(error?.message || this.$t("alerts.errors.downloadDocumentsError"));
			} finally {
				this.isBulkDownloading = false;
			}
		},
		handleDeleteReportDocumentFile() {
			this.reportDocumentFile = null;
		},
		handleOpenHistoryDialog(document) {
			this.setSelectedDocument(document);
			this.isHistoryDialogOpened = true;
		},
		async handleOpenGenerateDialog(document) {
			this.setSelectedDocument(document);
			this.isGenerateDialogOpened = true;
			await this.handleGenerateReportFile();
		},
		handleOpenUploadDialog(document) {
			this.setSelectedDocument(document);
			this.isUploadDialogOpened = true;
		},
		handleCloseHistoryDialog() {
			this.isHistoryDialogOpened = false;
			this.resetSelectedDocument();
		},
		handleCloseGenerateDialog() {
			this.isGenerateDialogOpened = false;
			this.isGenerateReportError = false;
			this.handleDeleteReportDocumentFile();
			this.generatedReportPdfLink = null;
			this.resetSelectedDocument();
		},
		handleCloseUploadDialog() {
			this.isUploadDialogOpened = false;
			this.resetSelectedDocument();
			this.handleDeleteReportDocumentFile();
		},
		closeChosenDialog(type) {
			switch (type) {
				case UploadingDocumentActionType.UPLOAD:
				{
					this.handleCloseUploadDialog();
					break;
				}
				case UploadingDocumentActionType.GENERATE:
				{
					this.handleCloseGenerateDialog();
					break;
				}
			}
		},
		handleCloseAddQueryDialog() {
			this.$refs["addQueryForm"].resetValidation();
			this.isAddQueryDialogOpened = false;
			this.handleDeleteReportDocumentFile();
			this.resetReportAddQueryInfo();
		}
	},
	created() {
		abortService.initialize();
		this.initializeStore();
	},
	components: {
		LoanReportsDialogLayout,
		LoanReportsDocumentName,
		FrpTextarea,
		LoanReportsButtonsGroup,
		FrpSpinnerLoader,
		VuePdfEmbed,
		LoanReportsList,
		LoanReportsDigitalSignatureDialog,
		LoanReportsDropzone,
		FrpAlert,
		FrpTextBtn,
		LoanContentLayout,
		FrpPagination,
		FrpDialog,
		FrpBtn,
		FrpDateField,
		FrpIcon,
		FrpTextField,
		FrpAutocomplete
	}
};
</script>

import {
	namespace,
	actionTypes,
	mutationTypes
} from "@/store/loan/modules/breadcrumbs/types";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import BreadcrumbsState from "@/store/loan/modules/breadcrumbs/types/breadcrumbsState";
import Breadcrumb from "@/store/loan/modules/breadcrumbs/types/breadcrumb";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import NotDefinedException from "@/exceptions/notDefinedException";
import baseMixinTypes from "@/store/shared/base/types";
import { isArray, isString } from "lodash";
import { findRoute, RouteNames, routesThreeRoot } from "@/router/loan/routes";
import { routeToStoreMap } from "@/router/loan/routeToStoreMap";

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new BreadcrumbsState();
	}
}

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<BreadcrumbsState, any>>{};

const actions = <ActionTree<BreadcrumbsState, any>>{
	async [actionTypes.resolveBreadcrumb]({ rootState, commit }, { segment, route }) {

		const map = new Map<string, () => Breadcrumb>(
			[
				[RouteNames.LOAN_SCHEDULE, () => new Breadcrumb("График возврата займов", { name: segment })]
			]
		);

		let callback = map.get(segment);

		if(callback)
			return callback();
	},
	async [actionTypes.processRoute]({ rootState, commit, dispatch }, routeName: string) {
		const result = [];

		if(routeName) {
			result.push(new Breadcrumb("Реестр проектов", { name: RouteNames.ROOT }));
			result.push(new Breadcrumb("ООО \"БСП\"", { name: RouteNames.ROOT }));

			let route = routesThreeRoot.first(x => x.model.name === routeName);

			if(!route) throw new NotDefinedException("route");

			for (const segment of route.getPath()) {
				let breadcrumb = await dispatch(actionTypes.resolveBreadcrumb, { segment: segment.model.name, route: routeName });
				if(breadcrumb)
					result.push(breadcrumb);
			}
		}

		commit(mutationTypes.SET_ITEMS, result);
		commit(mutationTypes.SET_IS_LOADING, false);
	}
};

const mutations = <MutationTree<BreadcrumbsState>>{
	[mutationTypes.SET_ITEMS](state, value) {
		state.items = value;
	},
	[mutationTypes.SET_IS_LOADING](state, value) {
		state.isLoading = value;
	}
};

const subscribe = (store: any) => {
	const initializedMap = new Map<string, string | string[]>();
	const beforeInitializedMap = new Map<string, string | string[]>();

	for (const [route, namespace] of routeToStoreMap) {
		initializedMap.set(route, namespace.map(x => resolveMutation(x, baseMixinTypes.mutationTypes.SET_IS_INITIALIZED)));
		beforeInitializedMap.set(route, namespace.map(x => resolveMutation(x, baseMixinTypes.mutationTypes.BEFORE_INITIALIZED)));
	}

	store.subscribe(async ({ type, payload }: any, state: any) => {
		const beforeInitializedEventValue = beforeInitializedMap.get(state.route.name);
		if(beforeInitializedEventValue) {
			if(isString(beforeInitializedEventValue)) {
				if(type === beforeInitializedEventValue) {
					store.commit(resolveMutation(namespace, mutationTypes.SET_IS_LOADING), true);
				}
			} else if(isArray(beforeInitializedEventValue)) {
				let result = beforeInitializedEventValue.find(x => type === x);
				if(result) {
					store.commit(resolveMutation(namespace, mutationTypes.SET_IS_LOADING), true);
				}
			}
		}

		const initializedEventValue = initializedMap.get(state.route.name);
		if(initializedEventValue) {
			if(isString(initializedEventValue)) {
				if(type === initializedEventValue) {
					store.dispatch(resolveAction(namespace, actionTypes.processRoute), state.route.name);
				}
			} else if(isArray(initializedEventValue)) {
				let result = initializedEventValue.find(x => type === x);
				if(result) {
					store.dispatch(resolveAction(namespace, actionTypes.processRoute), state.route.name);
				}
			}
		}
	});
};

export {
	namespace, state, getters, actions, mutations, subscribe
};

const breadcrumbsModule = {
	namespace, state, getters, actions, mutations, namespaced: true, subscribe
};

export default breadcrumbsModule;

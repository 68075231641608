<template>
	<loan-content-layout>
		<loan-payments-agreement-loader></loan-payments-agreement-loader>

		<v-row class="mx-0">
			<v-col class="pt-2 pb-2 d-flex align-center">
				<frp-text-loader width="400"/>
				<v-spacer></v-spacer>
				<frp-text-loader width="100"/>
			</v-col>
		</v-row>

		<loan-payments-planned-payments-loader></loan-payments-planned-payments-loader>

		<loan-payments-overdues-loader></loan-payments-overdues-loader>
	</loan-content-layout>
</template>

<script>
import LoanContentLayout from "@/components/layouts/LoanContentLayout";
import LoanCard from "Components/layouts/LoanCard";
import LoanNestedCard from "Components/layouts/LoanNestedCard";
import FrpCustomTitleLoader from "Components/loaders/common/FrpCustomTitleLoader";
import FrpTextLoader from "Components/loaders/common/FrpTextLoader";
import FrpDetailsItemLoader from "Components/loaders/details/FrpDetailsItemLoader";
import FrpBtnLoader from "Components/loaders/form/FrpBtnLoader";
import LoanPaymentsAgreementLoader from "Views/loan/upcomingPayments/agreement/LoanPaymentsAgreementLoader";
import LoanPaymentsOverduesLoader from "Views/loan/upcomingPayments/overdues/LoanPaymentsOverduesLoader";
import LoanPaymentsPlannedPaymentsLoader from "Views/loan/upcomingPayments/plannedPayments/LoanPaymentsPlannedPaymentsLoader";

export default {
	components: {
		LoanPaymentsAgreementLoader,
		LoanPaymentsOverduesLoader,
		LoanPaymentsPlannedPaymentsLoader,
		FrpBtnLoader,
		FrpTextLoader,
		FrpCustomTitleLoader,
		FrpDetailsItemLoader,
		LoanCard,
		LoanNestedCard,
		LoanContentLayout
	}
};
</script>

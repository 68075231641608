export default class ApiKeyDates {
	recallReason: string;
	recallDate: string;
	requirements: string;
	declaration: string;
	settlement: string;

	constructor(
		recallReason: string = "",
		recallDate: string = "",
		requirements: string = "",
		declaration: string = "",
		settlement: string = ""
	)
	{
		this.recallReason = recallReason;
		this.recallDate = recallDate;
		this.requirements = requirements;
		this.declaration = declaration;
		this.settlement = settlement;
	}
}

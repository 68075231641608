export default class SecurityAgreement {
	constructor(
		public versionId: string = "",
		public securityAgreementId: number = 0,
		public securityAgreementProjectId: number = 0,
		public securityKindId: number = 0,
		public propertyLocation: string = "",
		public guarantor: string = "",
		public agreementValue: number = 0,
		public lastAssessmentDate: number = 0,
		public assessmentTypeId: string = "",
		public lastValuation: number = 0,
		public isPropertySold: boolean = false,
		public agreementDate: number = 0 // todo
	)
	{
	}
}

import RefundSource from "@/store/loan/modules/troubledBorrower/types/refundSource";

export default class RefundSourceProject {
	constructor(
		public projectId: string = "",
		public refundSourceInfos?: RefundSource[]
	)
	{
	}
}

import IPageState from "@/store/shared/base/types/pageState";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import ApiAgreementStatus from "@/api/loan/types/loanSchedule/apiAgreementStatus";
import RouteState from "@/store/shared/route/types/routeState";
import ApiQuarter from "@/api/loan/types/dictionaries/apiQuarter";
import ApiFinanceSource from "@/api/loan/types/dictionaries/apiFinanceSource";
import { AgreementsAccruedPenaltiesModeType } from "@/store/loan/modules/agreementsAccruedPenalties/types/agreementsAccruedPenaltiesModeType";
import AgreementsAccruedPenaltiesFilter from "@/store/loan/modules/agreementsAccruedPenalties/types/agreementsAccruedPenaltiesFilter";
import { AgreementsAccruedPenaltiesItem } from "@/store/loan/modules/agreementsAccruedPenalties/types/agreementsAccruedPenaltiesItem";

export default class AgreementsAccruedPenaltiesState implements IPageState {
	constructor(
		public listing: ListingModel<AgreementsAccruedPenaltiesItem>,
		public sorting: SortingModel<String[]>,
		public paging: PagingModel,
		public search: SearchModel,
		public route: RouteState,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isDictionariesLoading: boolean = false,
		public quarters: ApiQuarter[] = [],
		public agreementStatuses: ApiAgreementStatus[] = [],
		public financeSources: ApiFinanceSource[] = [],
		public filter: AgreementsAccruedPenaltiesFilter = new AgreementsAccruedPenaltiesFilter(),
		public selectedItems: AgreementsAccruedPenaltiesItem[] = [],
		public isUpdateSelectedItemsRequired: boolean = false,
		public mode: AgreementsAccruedPenaltiesModeType = AgreementsAccruedPenaltiesModeType.MAIN_DEBT,
		public mainDebtItems: AgreementsAccruedPenaltiesItem[] = [],
		public interestItems: AgreementsAccruedPenaltiesItem[] = []
	)
	{
	}
}

<template>
	<loan-card-layout v-if="isInitialized">
		<template #title>
			{{ $t("titles.overdues") }}
		</template>
		<template #actions>
			<frp-btn v-if="can(Permissions.LOAN_CREATE) && mode === OverdueModeType.READ"
					 @click="isNewItemDialogOpened = true"
					 class="text-none pl-1 pr-2"
					 outlined
					 dense
					 color="blue"
					 dark>
				<v-icon color="blue">mdi-plus</v-icon>
				{{ $t("buttons.addNote") }}
			</frp-btn>
			<template v-if="mode === OverdueModeType.EDIT">
				<frp-btn class="text-none px-3"
						 text
						 plain
						 dense
						 color="primary"
						 @click="setMode(OverdueModeType.READ)">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				<frp-btn class="text-none px-3"
						 outlined
						 dense
						 :loading="isItemsUpdating"
						 :disabled="!isDataValid"
						 @click="updateItems()"
						 color="blue"
						 dark>
					{{ $t("buttons.save") }}
				</frp-btn>
			</template>
			<frp-btn class="text-none px-3"
					 v-else-if="can(Permissions.LOAN_UPDATE)"
					 outlined
					 dense
					 color="blue"
					 dark
					 :disabled="!formattedItems.length"
					 @click="setMode(OverdueModeType.EDIT)">
				{{ $t("buttons.edit") }}
			</frp-btn>
		</template>

		<template #content>
			<v-card elevation="0"
					class="loan-card pa-4 pt-3 d-flex flex-column"
					style="row-gap: 18px">
				<v-row>
					<v-col v-if="!formattedItems.length"
						   class="d-flex align-center flex-column py-8">
						<span class="text-body-2 grey--text">{{ $t("content.noNotes") }}</span>
					</v-col>

					<v-col v-else>
						<v-form v-model="isDataValid">
							<v-data-table :headers="headers"
										  :loading-text="$t('tables.loading')"
										  hide-default-footer
										  item-key="id"
										  :items="formattedItems"
										  :items-per-page="-1"
										  :mobile-breakpoint="mobileBreakpoint"
										  :options="options"
										  :item-class="() => 'text-body-2 cy-table-row'"
										  class="loan-table clickable-rows d-flex flex-column">
								<template #item.source="{ item }">
									<span v-if="mode === OverdueModeType.READ">{{ item.source.name }}</span>
									<frp-autocomplete dense
													  required
													  :items="financeSources"
													  item-text="name"
													  color="blue"
													  return-object
													  v-model="editableItems.find(x => x.id === item.id).source"
													  hide-details
													  v-else
													  :placeholder="$t('fields.financeSource.label')">
									</frp-autocomplete>
								</template>

								<template #item.startDate="{ item }">
									<span v-if="mode === OverdueModeType.READ"> {{ formatDate(item.startDate, dateFormat) }} </span>
									<frp-date-field dense
													required
													v-model="editableItems.find(x => x.id === item.id).startDate"
													hide-details
													v-else
													:placeholder="$t('fields.date.label')">
									</frp-date-field>
								</template>
								<template #item.amount="{ item }">
									<span v-if="mode === OverdueModeType.READ">{{ formatCurrency(item.amount) }}</span>
									<frp-text-field dense
													required
													v-model.number="editableItems.find(x => x.id === item.id).amount"
													type="number"
													hide-details
													v-else
													:placeholder="$t('fields.amount.labelRUB')">
									</frp-text-field>
								</template>

								<template #item.quarter="{ item }">
									<span v-if="mode === OverdueModeType.READ">{{ item.quarter.title }}</span>
									<frp-autocomplete dense
													  required
													  :items="quarters"
													  item-text="title"
													  color="blue"
													  return-object
													  v-model="editableItems.find(x => x.id === item.id).quarter"
													  hide-details
													  v-else
													  :placeholder="$t('fields.quarter.label')">
									</frp-autocomplete>
								</template>

								<template #item.isProblem="{ item }">
									<span v-if="mode === OverdueModeType.READ">
										{{ item.isProblem ? $t("aliases.affirmation.yes") : $t("aliases.affirmation.no") }}
									</span>
									<loan-boolean-switch v-else
														 v-model="editableItems.find(x => x.id === item.id).isProblem">
									</loan-boolean-switch>
								</template>
								
								<template #item.comment="{ item }">
									<span v-if="mode === OverdueModeType.READ">
										{{ item.comment }}
									</span>
									<frp-text-field v-else
													dense
													v-model="editableItems.find(x => x.id === item.id).comment"
													hide-details
													:placeholder="$t('fields.comment.label')">
									</frp-text-field>
								</template>

								<template #item.delete="{ item }" v-if="can(Permissions.LOAN_DELETE)">
									<frp-btn without-padding
											 height="28"
											 small
											 icon
											 color="blue"
											 dark
											 @click="handleOpenDeleteItemDialog(item)">
										<frp-icon src="ico_delete" :color="colors.primary.darken1"></frp-icon>
									</frp-btn>
								</template>

								<template #foot>
									<tfoot class="text-subtitle-1 font-weight-medium grey--text text--darken-3">
									<tr>
										<template v-if="isMobileBreakpoint">
											<td class="d-flex justify-space-between mt-5">
												<span>{{ $t("content.total") }}</span>
												<span>{{ sum }}</span>
											</td>
										</template>

										<template v-else>
											<td v-for="i in 3" :key="i"/>
											<td style="position: relative">
											<span style="position: absolute; left: -80px">
												{{ $t("content.total") }}
											</span>
												<span>{{ sum }}</span>
											</td>
										</template>
									</tr>
									</tfoot>
								</template>
							</v-data-table>
						</v-form>
					</v-col>
				</v-row>
			</v-card>

			<frp-dialog v-model="isNewItemDialogOpened" :title="$t('dialogs.addAnOverduesNote.title')" persistent>
				<template #content="{ onIntersect }">
					<v-form :ref="refs.form" v-intersect="onIntersect" v-model="formValid">
						<frp-autocomplete :label="$t('fields.financeSource.label')"
										  return-object
										  v-model="newItemSource"
										  :items="financeSources"
										  item-text="name"
										  color="blue"
										  required
										  :placeholder="$t('fields.financeSource.placeholder')">
						</frp-autocomplete>
						
						<frp-date-field :label="$t('fields.date.label')"
										v-model="newItemStartDate"
										required
										:placeholder="$t('fields.date.placeholder')">
						</frp-date-field>
						
						<frp-autocomplete :label="$t('fields.quarter.label')"
										  v-model="newItemQuarter"
										  return-object
										  :items="quarters"
										  item-text="title"
										  color="blue"
										  required
										  :placeholder="$t('fields.quarter.label')">
						</frp-autocomplete>
						
						<frp-text-field :label="$t('fields.amount.labelRUB')"
										v-model.number="newItemAmount"
										type="number"
										required
										:placeholder="$t('fields.amount.placeholder')">
						</frp-text-field>
						
						<loan-boolean-switch :label="$t('content.isProblem')" 
											 v-model="newItemIsProblem">
						</loan-boolean-switch>
						
						<frp-textarea :label="$t('fields.comment.label')"
									  autocomplete="off"
									  class="mt-4"
									  :placeholder="$t('fields.comment.placeholder')"
									  outlined
									  v-model="newItemComment">
						</frp-textarea>
					</v-form>
				</template>

				<template #footer>
					<frp-btn outlined
							 @click="resetCreateItem"
							 color="primary">
						{{ $t("buttons.cancel") }}
					</frp-btn>
					<frp-btn elevation="0"
							 color="blue"
							 dark
							 :loading="isItemCreating"
							 :disabled="!stateContainsUnsavedChanges || !isFormValid"
							 @click="handleCreateItem">
						{{ $t("buttons.addNote") }}
					</frp-btn>
				</template>
			</frp-dialog>
			<frp-dialog v-model="isDeleteItemDialogOpened" :title="$t('dialogs.deleteNote.title')">
				<template #content="{ onIntersect }">
						<span v-intersect="onIntersect" class="primary--text text-body-2">
							{{ $t("dialogs.deleteNote.warning") }}
						</span>
				</template>

				<template #footer>
					<frp-btn outlined
							 @click="isDeleteItemDialogOpened = false"
							 color="primary">
						{{ $t("buttons.cancel") }}
					</frp-btn>
					<frp-btn elevation="0"
							 color="blue"
							 dark
							 :loading="isItemDeleting"
							 @click="handleDeleteItem">
						{{ $t("buttons.deleteNote") }}
					</frp-btn>
				</template>
			</frp-dialog>
		</template>
	</loan-card-layout>
	<loan-overdues-loader v-else/>
</template>

<script>
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpDialog from "Components/dialogs/FrpDialog";
import FrpAutocomplete from "Components/fields/FrpAutocomplete";
import FrpDateField from "Components/fields/FrpDateField";
import FrpTextField from "Components/fields/FrpTextField";
import LoanBooleanSwitch from "Components/fields/LoanBooleanSwitch";
import FrpIcon from "Components/icon/FrpIcon";
import LoanCardLayout from "Components/layouts/LoanCardLayout";
import authorizationMixin from "Mixins/authorizationMixin";
import colorsMixin from "Mixins/colorsMixin";
import formMixin from "Mixins/formMixin";
import { listMixin } from "Mixins/listMixin";
import {
	getterTypes,
	mutationTypes, actionTypes
} from "Store/loan/modules/loanSchedule/modules/overdues/types";
import { OverdueModeType } from "Store/loan/modules/loanSchedule/modules/overdues/types/overdueModeType";
import storeManager from "Store/manager";
import { formatDate } from "Utils/dates";
import { dateFormat } from "Utils/formats";
import { formatCurrency } from "Utils/formatting";
import { sumFloat } from "Utils/number";
import LoanOverduesLoader from "Views/loan/loanSchedule/overdues/LoanOverduesLoader";
import { createNamespacedHelpers } from "vuex";

const namespace = storeManager.loan.loanSchedule.overdues.namespace;
const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);
const { mapState: loanScheduleMapState } = createNamespacedHelpers(storeManager.loan.loanSchedule.namespace);

export default {
	mixins: [formMixin, listMixin, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			dateFormat,
			formatDate,
			formatCurrency,
			isDeleteItemDialogOpened: false,
			isNewItemDialogOpened: false,
			OverdueModeType,
			itemToDelete: null,
			isDataValid: false
		};
	},
	computed: {
		...loanScheduleMapState({
			quarters: state => state.quarters,
			financeSources: state => state.financeSources
		}),
		...mapState({
			isInitialized: state => state.isInitialized,
			mode: state => state.mode,
			newItem: state => state.newItem,
			isItemCreating: state => state.isItemCreating,
			isItemDeleting: state => state.isItemDeleting,
			isItemsUpdating: state => state.isItemsUpdating,
			editableItems: state => state.editableItems
		}),
		...mapGetters({
			formattedItems: getterTypes.formattedItems
		}),
		headers() {
			return [
				{
					text: this.$t("tables.financeSource"),
					value: "source",
					class: "text-caption",
					width: "16%",
					sortable: false
				},
				{
					text: this.$t("tables.date"),
					value: "startDate",
					class: "text-caption",
					width: "16%",
					sort: (a, b) => new Date(a).getTime() - new Date(b).getTime(),
					sortable: false
				},
				{
					text: this.$t("tables.quarter"),
					value: "quarter",
					class: "text-caption",
					width: "16%",
					sortable: false
				},
				{
					text: this.$t("tables.amountRUB"),
					value: "amount",
					class: "text-caption",
					width: "16%",
					sortable: false
				},
				{
					text: this.$t("tables.isProblem"),
					value: "isProblem",
					class: "text-caption",
					width: "16%",
					sortable: false
				},
				{
					text: this.$t("tables.comment"),
					value: "comment",
					class: "text-caption",
					width: "16%",
					sortable: false
				},
				{
					text: "",
					value: "delete",
					width: "1%",
					sortable: false
				}
			];
		},
		sum() {
			return this.mode === OverdueModeType.EDIT ?
				formatCurrency(this.getSum(this.editableItems)) :
				formatCurrency(this.getSum(this.formattedItems));
		},
		newItemSource: {
			get() {
				return this.newItem.source;
			},
			set(value) {
				this.setNewItemSource(value);
			}
		},
		newItemStartDate: {
			get() {
				return this.newItem.startDate;
			},
			set(value) {
				this.setNewItemStartDate(value);
			}
		},
		newItemQuarter: {
			get() {
				return this.newItem.quarter;
			},
			set(value) {
				this.setNewItemQuarter(value);
			}
		},
		newItemAmount: {
			get() {
				return this.newItem.amount;
			},
			set(value) {
				this.setNewItemAmount(value);
			}
		},
		newItemIsProblem: {
			get() {
				return this.newItem.isProblem;
			},
			set(value) {
				this.setNewItemIsProblem(value);
			}
		},
		newItemComment: {
			get() {
				return this.newItem.comment;
			},
			set(value) {
				this.setNewItemComment(value);
			}
		}
	},
	methods: {
		...mapActions({
			initializeStore: actionTypes.initialize,
			createItem: actionTypes.createItem,
			updateItems: actionTypes.updateItems,
			deleteItem: actionTypes.deleteItem
		}),
		...mapMutations({
			setMode: mutationTypes.SET_MODE,
			resetNewItem: mutationTypes.RESET_NEW_ITEM,
			setNewItemSource: mutationTypes.SET_NEW_ITEM_SOURCE,
			setNewItemStartDate: mutationTypes.SET_NEW_ITEM_START_DATE,
			setNewItemQuarter: mutationTypes.SET_NEW_ITEM_QUAERTER,
			setNewItemAmount: mutationTypes.SET_NEW_ITEM_AMOUNT,
			setNewItemIsProblem: mutationTypes.SET_NEW_ITEM_IS_PROBLEM,
			setNewItemComment: mutationTypes.SET_NEW_ITEM_COMMENT
		}),
		async handleCreateItem() {
			await this.createItem();
			this.resetCreateItem();
		},
		resetCreateItem() {
			this.resetNewItem();
			this.$refs[this.refs.form].resetValidation();
			this.isNewItemDialogOpened = false;
		},
		async handleDeleteItem() {
			await this.deleteItem({ item: this.itemToDelete });
			this.itemToDelete = null;
			this.isDeleteItemDialogOpened = false;
		},
		handleOpenDeleteItemDialog(item) {
			this.isDeleteItemDialogOpened = true;
			this.itemToDelete = item;
		},
		getSum(items) {
			return items.map(x => x.amount).reduce((acc, x) => sumFloat(acc, +x), 0);
		}
	},
	async created() {
		await this.initializeStore();
	},
	components: {
		FrpTextarea,
		FrpBtn,
		LoanOverduesLoader,
		LoanBooleanSwitch,
		FrpAutocomplete,
		FrpDialog,
		FrpTextField,
		FrpDateField,
		FrpIcon,
		LoanCardLayout
	}
};
</script>

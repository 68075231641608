export default class ApiUpdateDebtorStatusRequest {
	constructor(
		public stageTypeId: string = "",
		public debtorStageStatusTypeId: string = "",
		public startDate: string = "",
		public expirationDate?: string
	)
	{
	}
}

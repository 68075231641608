import ApiFinanceSource from "@/api/loan/types/dictionaries/apiFinanceSource";
import Quarter from "@/types/loan/quarter";

export default class Overdue {
	id: string;
	number: number;
	startDate: number;
	source: ApiFinanceSource;
	quarter: Quarter;
	amount: number;
	comment: string;
	isProblem: boolean;
	constructor(
		id: string = "",
		number: number = 0,
		startDate: number = 0,
		source: ApiFinanceSource = new ApiFinanceSource(),
		quarter: Quarter = new Quarter(),
		amount: number = 0,
		comment: string = "",
		isProblem: boolean = false
	)
	{
		this.id = id;
		this.number = number;
		this.startDate = startDate;
		this.source = source;
		this.quarter = quarter;
		this.amount = amount;
		this.comment = comment;
		this.isProblem = isProblem;
	}
}

<template>
	<loan-card-layout v-if="isInitialized">
		<template #title>
			{{ $t("titles.payments") }}
		</template>
		<template #content>
			<v-card elevation="0"
					class="loan-card pa-4 pt-3 d-flex flex-column">
				<v-row>
					<v-col>
						<v-data-table :headers="visibleHeaders"
									  :loading-text="$t('tables.loading')"
									  hide-default-footer
									  item-key="id"
									  :items="formattedItems"
									  :items-per-page="-1"
									  :mobile-breakpoint="mobileBreakpoint"
									  :options="options"
									  :item-class="() => 'text-body-2 cy-table-row'"
									  class="loan-table clickable-rows d-flex flex-column loan-payments-table">
							<template #item.date="{ item }">
								<span class="align-self-end pt-1 pb-3 mr-4">
									<span>{{ formatDate(item.date, dateFormat) }}</span>
								</span>
							</template>

							<template #footer>
								<span class="align-self-end pt-1 pb-3 mr-4">
									<span class="grey--text text--lighten-3 mr-3">
										{{ $t("content.remainingDebt") }}
									</span>
									<span class="orange--text font-weight-bold">
										{{ remainingDebt }}
									</span>
									<span class="grey--text text--lighten-3 mr-3" v-show="hasCessions">
										{{ $t("content.remainingAssign") }}
									</span>
									<span class="orange--text font-weight-bold" v-show="hasCessions">
										{{ formatCurrency(formattedItems.slice(-1).pop()?.balanceAssign) || 0 }}
									</span>
								</span>
							</template>
						</v-data-table>
					</v-col>
				</v-row>
			</v-card>
		</template>
	</loan-card-layout>
	<loan-payments-loader v-else/>
</template>

<script>
import LoanCardLayout from "Components/layouts/LoanCardLayout";
import { listMixin } from "Mixins/listMixin";
import {
	getterTypes,
	mutationTypes,
	actionTypes
} from "@/store/loan/modules/loanSchedule/modules/payments/types";
import storeManager from "Store/manager";
import { formatDate } from "Utils/dates";
import { dateFormat } from "Utils/formats";
import { formatCurrency } from "Utils/formatting";
import LoanPaymentsLoader from "Views/loan/loanSchedule/payments/LoanPaymentsLoader";
import { createNamespacedHelpers } from "vuex";

const namespace = storeManager.loan.loanSchedule.payments.namespace;
const { mapState, mapGetters, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [listMixin],
	data() {
		return {
			namespace,
			formatCurrency,
			formatDate,
			dateFormat
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized
		}),
		...mapGetters({
			formattedItems: getterTypes.formattedItems
		}),
		headers() {
			return [
				{
					text: this.$t("tables.date"),
					value: "date",
					class: "text-caption",
					width: "10%",
					sortable: false
				},
				{
					text: this.$t("tables.plannedRepaymentAmountRUB"),
					value: "planMainDebt",
					class: "text-caption",
					width: "10%",
					sortable: false
				},
				{
					text: this.$t("tables.issuanceLoanRUB"),
					value: "factLoan",
					class: "text-caption",
					width: "10%",
					sortable: false
				},
				{
					text: this.$t("tables.loanReIssuanceRUB"),
					value: "loanReIssuance",
					class: "text-caption",
					width: "10%",
					sortable: false
				},
				{
					text: this.$t("tables.actualRepaymentAmountRUB"),
					value: "factMainDebt",
					class: "text-caption",
					width: "10%",
					sortable: false
				},
				{
					text: this.$t("tables.temporaryRefundRUB"),
					value: "temporaryRefund",
					class: "text-caption",
					width: "10%",
					sortable: false
				},
				{
					text: this.$t("tables.withdrawalForDelayRUB"),
					value: "overdue",
					class: "text-caption",
					width: "10%",
					sortable: false
				},
				{
					text: this.$t("tables.paymentOfDelayRUB"),
					value: "factOverdue",
					class: "text-caption",
					width: "10%",
					sortable: false
				},
				{
					text: this.$t("tables.assignedLoanRUB"),
					value: "assignedLoan",
					class: "text-caption",
					width: "10%",
					sortable: false,
					cession: true
				},
				{
					text: this.$t("tables.assignedMainDebtRUB"),
					value: "assignedMainDebt",
					class: "text-caption",
					width: "10%",
					sortable: false,
					cession: true
				}
			];
		},
		hasCessions() {
			return this.items.some(x => x.hasCessions);
		},
		visibleHeaders() {
			return this.headers.filter(x => this.hasCessions || !x.cession);
		},
		remainingDebt() {
			let { balanceMainDebt } = this.formattedItems.at(-1);
			return formatCurrency(balanceMainDebt) || 0;
		}
	},
	methods: {
		...mapActions({
			initializeStore: actionTypes.initialize
		})
	},
	async created() {
		await this.initializeStore();
	},
	components: {
		LoanPaymentsLoader,
		LoanCardLayout
	}
};
</script>

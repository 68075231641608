<template>
	<v-row class="mx-0">
		<v-col class="pb-0">
			<loan-card>
				<v-row>
					<v-col class="d-flex flex-column pt-0">
						<span class="text-body-2 pb-1">{{ $t("titles.loanAgreement") }}</span>
						<span class="text-body-1 font-weight-bold">
							№ {{ agreement.documentNumber }} от {{ formatDate(agreement.signingDate, dateFormat) }}
						</span>
					</v-col>
					<v-col v-if="false" class="d-flex flex-column pt-0">
						<span class="text-body-2 pb-1">{{ $t("details.titles.financeScheme") }}</span>
						<span class="text-body-1 font-weight-bold">Приоритетные проекты</span>
					</v-col>
					<v-col class="d-flex flex-column pt-0">
						<span class="text-body-2 pb-1">{{ $t("details.titles.frpSum") }}</span>
						<span class="text-body-1 font-weight-bold">{{ formatCurrency(agreement.exactFrpSum) }}</span>
					</v-col>
					<v-col class="d-flex flex-column pt-0">
						<span class="text-body-2 pb-1">{{ $t("details.titles.frpIssuedSum") }}</span>
						<span v-if="frpIssuedSum === 0" class="text-body-1 font-weight-bold">{{ $t("details.titles.frpIssedSumEmpty") }}</span>
						<span v-if="frpIssuedSum > 0" class="text-body-1 font-weight-bold">{{ formatCurrency(frpIssuedSum) }}</span>
					</v-col>
					<v-spacer></v-spacer>
					<v-col cols="auto" class="d-flex align-center pt-0">
						<frp-text-btn class="pa-0 passport-text-btn"
									  @click="handleOpenCashFlow"
									  color="blue">
							<span class="text-decoration-underline">
								{{ (isLoanUserBorrower || isLoanUserAssignee) ? $t("links.loanCashFlowActualPayments") : $t("links.loanCashFlow") }}
							</span>
							<frp-icon class="ml-2" :color="colors.primary.base" src="ico_open-in-new"></frp-icon>
						</frp-text-btn>
					</v-col>
				</v-row>
			</loan-card>
		</v-col>
	</v-row>
</template>

<script>
import storeManager from "@/store/manager";
import FrpTextBtn from "Components/buttons/FrpTextBtn";
import FrpIcon from "Components/icon/FrpIcon";
import LoanCard from "Components/layouts/LoanCard";
import colorsMixin from "Mixins/colorsMixin";
import { RouteNames } from "Router/loan/routes";
import { formatDate } from "Utils/dates";
import { dateFormat } from "Utils/formats";
import { formatCurrency } from "Utils/formatting";
import { openRouteInNewTab } from "Utils/router";
import { createNamespacedHelpers } from "vuex";
import { namespace } from "Store/loan/modules/upcomingPayments/types";
import { getterTypes as loanUserGetterTypes } from "Store/loan/modules/user/types";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

const loanUserHelpers = createNamespacedHelpers(storeManager.loan.loanUser.namespace);

export default {
	mixins: [colorsMixin],
	data() {
		return {
			RouteNames,
			formatCurrency,
			formatDate,
			dateFormat
		};
	},
	computed: {
		...loanUserHelpers.mapGetters({
			isLoanUserBorrower: loanUserGetterTypes.isLoanUserBorrower,
			isLoanUserAssignee: loanUserGetterTypes.isLoanUserAssignee
		}),
		...mapState({
			agreement: state => state.agreement,
			frpIssuedSum: state => state.frpIssuedSum,
		})
	},
	methods: {
		handleOpenCashFlow() {
			openRouteInNewTab(this.$router, { name: RouteNames.CASH_FLOW, params: { projectId: this.$route.params.projectId } });
		}
	},
	components: { FrpIcon, FrpTextBtn, LoanCard }
};
</script>

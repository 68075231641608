import { convertToTimestamp } from "@/utils/dates";
import { AgreementsOverduePaymentsItem } from "@/store/loan/modules/agreementsOverduePayments/types/agreementsOverduePaymentsItem";
import { ApiAgreementsOverduePaymentsItem } from "@/api/loan/types/loanInterest/apiAgreementsOverduePaymentsItem";
import ApiGetAgreementsOverduePaymentsParameters from "@/api/loan/types/loanInterest/apiGetAgreementsOverduePaymentsParameters";
import AgreementsOverduePaymentsState from "@/store/loan/modules/agreementsOverduePayments/types/agreementsOverduePaymentsState";

export default class AgreementsOverduePaymentsMapperProfile {
	constructor() {
	}

	mapToAgreementsAccruedPenaltiesItem(source: ApiAgreementsOverduePaymentsItem): AgreementsOverduePaymentsItem {
		return {
			...source,
			paymentDate: convertToTimestamp(source.paymentDate) as number,
			projectNumber: source.project.applicationNumber,
			organizationName: source.project.company.shortName,
			status: source.project.agreement.status.name,
			id: ""
		};
	}

	mapToApiGetAgreementsAccruedPenaltiesParameters(source: AgreementsOverduePaymentsState): ApiGetAgreementsOverduePaymentsParameters {
		return {
			...source.filter
		};
	}
}

<template>
	<v-container fluid>
		<v-row class="px-4">
			<v-col class="px-0">
				<loan-overdue-interest-list-loader/>
			</v-col>
			<v-col class="px-0">
				<loan-penalty-interest-list-loader/>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import LoanOverdueInterestListLoader from "Views/loan/overdueInterest/overdueInterestList/LoanOverdueInterestListLoader";
import LoanPenaltyInterestListLoader from "Views/loan/overdueInterest/penaltyInterestList/LoanPenaltyInterestListLoader";

export default {
	components: { LoanPenaltyInterestListLoader, LoanOverdueInterestListLoader }
};
</script>

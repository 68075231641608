import { ReportCompanyType } from "@/store/loan/modules/reports/types/reportCompanyType";

export default class ApiCompany {
	id: number;
	title: string;
	types: ReportCompanyType[];

	constructor(
		id: number = 0,
		title: string = "",
		types: ReportCompanyType[] = []
	)
	{
		this.id = id;
		this.title = title;
		this.types = types;
	}
}

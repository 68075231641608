import IPageState from "@/store/shared/base/types/pageState";
import ApiQuarter from "@/api/loan/types/dictionaries/apiQuarter";
import ApiFinanceSource from "@/api/loan/types/dictionaries/apiFinanceSource";
import ApiPenaltyType from "@/api/loan/types/loanInterest/apiPenaltyType";

export default class OverdueInterestState implements IPageState {
	constructor(
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isLoading: boolean = false,
		public quarters: ApiQuarter[] = [],
		public financeSources: ApiFinanceSource[] = [],
		public penaltyTypes: ApiPenaltyType[] = []
	)
	{
	}
}

import IPageState from "@/store/shared/base/types/pageState";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import ApiAgreementStatus from "@/api/loan/types/loanSchedule/apiAgreementStatus";
import RouteState from "@/store/shared/route/types/routeState";
import AgreementsOverduePaymentsFilter from "@/store/loan/modules/agreementsOverduePayments/types/agreementsOverduePaymentsFilter";
import { AgreementsOverduePaymentsItem } from "@/store/loan/modules/agreementsOverduePayments/types/agreementsOverduePaymentsItem";
import { AgreementsOverduePaymentsModeType } from "@/store/loan/modules/agreementsOverduePayments/types/agreementsOverduePaymentsModeType";
import ApiQuarter from "@/api/loan/types/dictionaries/apiQuarter";
import ApiFinanceSource from "@/api/loan/types/dictionaries/apiFinanceSource";

export default class AgreementsOverduePaymentsState implements IPageState {
	
	constructor(
		public listing: ListingModel<AgreementsOverduePaymentsItem>,
		public sorting: SortingModel<String[]>,
		public paging: PagingModel,
		public search: SearchModel,
		public route: RouteState,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isDictionariesLoading: boolean = false,
		public quarters: ApiQuarter[] = [],
		public agreementStatuses: ApiAgreementStatus[] = [],
		public financeSources: ApiFinanceSource[] = [],
		public filter: AgreementsOverduePaymentsFilter = new AgreementsOverduePaymentsFilter(),
		public selectedItems: AgreementsOverduePaymentsItem[] = [],
		public isUpdateSelectedItemsRequired: boolean = false,
		public mode: AgreementsOverduePaymentsModeType = AgreementsOverduePaymentsModeType.MAIN_DEBT,
		public mainDebtItems: AgreementsOverduePaymentsItem[] = [],
		public interestItems: AgreementsOverduePaymentsItem[] = []
	)
	{
	}
}

import { actionTypes, mutationTypes, namespace } from "@/store/loan/modules/troubledBorrowers/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationPayload, MutationTree, Store } from "vuex";
import AbortService from "@/services/abortService";
import ListingMixinBuilder from "@/store/shared/listing";
import PagingMixinBuilder from "@/store/shared/paging";
import SortingMixinBuilder from "@/store/shared/sorting";
import SearchMixinBuilder from "@/store/shared/search";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import router from "@/router/loan";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import { DictionariesController } from "@/api/loan/dictionaries";
import mapper from "@/store/loan/modules/troubledBorrowers/mapper";
import { RouteNames } from "@/router/loan/routes";
import SubscribersManager from "@/store/manager/subscribersManager";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import routeTypes from "@/store/shared/route/types";
import BatchService from "@/services/batchService";
import RouteMixinBuilder from "@/store/shared/route";
import TroubledBorrowersRouteQuery from "@/store/loan/modules/troubledBorrowers/types/troubledBorrowersRouteQuery";
import TroubledBorrowersRouteQueryService from "@/store/loan/modules/troubledBorrowers/services/troubledBorrowersRouteQueryService";
import TroubledBorrowersState from "@/store/loan/modules/troubledBorrowers/types/troubledBorrowersState";
import ApiTroubledBorrower from "@/api/loan/types/troubledBorrower/apiTroubledBorrower";
import ApiGetTroubledBorrowersParameters from "@/api/loan/types/troubledBorrower/apiGetTroubledBorrowersParameters";
import { TroubledBorrowerController } from "@/api/loan/troubledBorrower";

const abortService = new AbortService();

const troubledBorrowerController = new TroubledBorrowerController(abortService);
const dictionariesController = new DictionariesController(abortService);

const defaultRouteQuery = new TroubledBorrowersRouteQuery(1, "", "");
const routeQueryService = new TroubledBorrowersRouteQueryService(defaultRouteQuery);
const updateListingBatchService = new BatchService(({ interval: 100 }));
const routeMixin = (new RouteMixinBuilder<TroubledBorrowersState>()).build();

const baseMixin = (new BaseMixinBuilder(abortService)).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new TroubledBorrowersState(
			new ListingModel<ApiTroubledBorrower>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String>({
				type: "",
				order: sortingOrderType.descending
			}),
			new PagingModel({
				total: 0,
				page: 1,
				pageSize: 25
			}),
			new SearchModel({
				query: ""
			}),
			routeMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

let subscribersManager: SubscribersManager<TroubledBorrowersState>;

let unsubscribeCallback = () => {
};
let store: Store<{}>;

const initializeSubscribersManager = (value: Store<{}>) => {
	store = value;
	subscribersManager = new SubscribersManager<TroubledBorrowersState>(store);
};

const subscribe = async (mutation: MutationPayload, rootState: any) => {
	let state = resolveNestedState<TroubledBorrowersState>(rootState, namespace);
	switch (mutation.type) {
		case resolveMutation(routeTypes.namespace, routeTypes.mutationTypes.ROUTE_CHANGED):
			if((mutation.payload.from.name === mutation.payload.to.name) && !state.route.isPushing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.processRouteQuery));
			break;
		case resolveMutation(namespace, mutationTypes.SET_FILTER_COMPANY_NAME):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_RESPONSIBLE_USER_ID):
			if(!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.resetPagingPage));
			break;
		case resolveMutation(namespace, mutationTypes.SET_PAGING_PAGE):
		{
			if(!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));

			updateListingBatchService.push(async () => {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.updateListingItems));
			});

			break;
		}
	}
};

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<TroubledBorrowersState, any>>{
	...listingMixin.getters
};

const actions = <ActionTree<TroubledBorrowersState, any>>{
	...listingMixin.actions,
	...pagingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...routeMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }) {
		await dispatch(actionTypes.initializeBase);

		await dispatch(actionTypes.processRouteQuery);
		await dispatch(actionTypes.reconstituteRoute);

		await Promise.all([
			dispatch(actionTypes.updateListingItems),
			dispatch(actionTypes.fetchDictionaries)
		]);

		unsubscribeCallback = subscribersManager.subscribe(subscribe);
		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.updateListingItems]({ commit, state }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);

		try {
			const { items, totalCount } = await troubledBorrowerController.getTroubledBorrowerItems(mapper.map(state,
				TroubledBorrowersState,
				ApiGetTroubledBorrowersParameters));

			commit(mutationTypes.SET_LISTING_ITEMS, items);
			commit(mutationTypes.SET_PAGING_TOTAL, totalCount);
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			console.error(error);
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.fetchDictionaries]({ commit }) {
		commit(mutationTypes.SET_IS_DICTIONARIES_LOADING, true);

		try {
			const [problemDebtDepartments, refundStatusTypes] = await Promise.all([
				dictionariesController.getProblemDeptDepartmentUsers(),
				dictionariesController.getRefundStatusTypes()
			])
			
			commit(mutationTypes.SET_PROBLEM_DEBT_DEPARTMENTS, problemDebtDepartments.map(x => ({ ...x, id: `${x.id}` })));
			commit(mutationTypes.SET_REFUND_STATUS_TYPES, refundStatusTypes);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_DICTIONARIES_LOADING, false);
		}
	},
	async [actionTypes.processRouteQuery]({ rootState, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, true);

		let routeQuery = await routeQueryService.resolveRouteQuery(rootState.route.query);

		commit(mutationTypes.SET_PAGING_PAGE, routeQuery.page);
		commit(mutationTypes.SET_FILTER_COMPANY_NAME, routeQuery.companyName);
		commit(mutationTypes.SET_FILTER_RESPONSIBLE_USER_ID, routeQuery.responsibleUserId);

		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, false);
	},
	async [actionTypes.pushRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);

		await router.push({
			name: RouteNames.TROUBLED_BORROWERS,
			query: routeQueryService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});

		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.reconstituteRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);

		await router.replace({
			name: RouteNames.TROUBLED_BORROWERS,
			query: routeQueryService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});

		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.destroy]({ dispatch }) {
		unsubscribeCallback();
		await dispatch(actionTypes.destroyBase);
	}
};

const mutations = <MutationTree<TroubledBorrowersState>>{
	...listingMixin.mutations,
	...pagingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...routeMixin.mutations,
	[mutationTypes.SET_IS_DICTIONARIES_LOADING](state, value) {
		state.isDictionariesLoading = value;
	},
	[mutationTypes.SET_FILTER_COMPANY_NAME](state, value) {
		state.filter.companyName = value;
	},
	[mutationTypes.SET_FILTER_RESPONSIBLE_USER_ID](state, value) {
		state.filter.responsibleUserId = value;
	},
	[mutationTypes.SET_PROBLEM_DEBT_DEPARTMENTS](state, value) {
		state.problemDebtDepartments = value;
	},
	[mutationTypes.SET_REFUND_STATUS_TYPES](state, value) {
		state.refundStatusTypes = value;
	},
};

export {
	namespace, state, getters, actions, mutations, initializeSubscribersManager
};

const troubledBorrowersModule = {
	namespace, state, getters, actions, mutations, initializeSubscribersManager, namespaced: true
};

export default troubledBorrowersModule;

import { addProfile, createMap, createMapper, forMember, fromValue, mapFrom, MappingProfile } from "@automapper/core";
import { classes } from "@automapper/classes";
import Debtor from "@/store/loan/modules/troubledBorrower/modules/productionStatus/types/debtor";
import ApiDebtor from "@/api/loan/types/troubledBorrower/apiDebtor";
import ApiStatusHistoryItem from "@/api/loan/types/troubledBorrower/apiStatusHistoryItem";
import StatusHistoryItem from "@/store/loan/modules/troubledBorrower/modules/productionStatus/types/statusHistoryItem";
import { convertToZonedIso, convertToTimestamp, convertToZonedTimestamp, timezoneNames } from "@/utils/dates";
import ApiDebtorEvent from "@/api/loan/types/troubledBorrower/apiDebtorEvent";
import DebtorEvent from "@/store/loan/modules/troubledBorrower/modules/productionStatus/types/debtorEvent";
import ApiDebtorStatus from "@/api/loan/types/troubledBorrower/apiDebtorStatus";
import DebtorStatus from "@/store/loan/modules/troubledBorrower/modules/productionStatus/types/debtorStatus";
import ApiRequirementHistoryItem from "@/api/loan/types/troubledBorrower/apiRequirementHistoryItem";
import RequirementHistoryItem from "@/store/loan/modules/troubledBorrower/modules/productionStatus/types/requirementHistoryItem";
import ApiUpdateDebtorStatusRequest from "@/api/loan/types/troubledBorrower/apiUpdateDebtorStatusRequest";

const mapper = createMapper({
	strategyInitializer: classes()
});

const proceedingStatusProfile: MappingProfile = (mapper) => {
	createMap(mapper, ApiDebtor, Debtor,
		forMember(d => d.versionId, mapFrom(x => x.versionId)),
		forMember(d => d.debtorId, mapFrom(x => x.debtorId)),
		forMember(d => d.currentStatus, mapFrom(x => mapper.map(x.currentStatus, ApiDebtorStatus, DebtorStatus))),
		forMember(d => d.comment, mapFrom(x => x.comment)),
		forMember(d => d.requirements, mapFrom(x => x.requirements ?? [])),
		forMember(d => d.statusHistory, mapFrom(x => x.statusHistory ? x.statusHistory.map(y => mapper.map(y, ApiStatusHistoryItem, StatusHistoryItem)) : [])),
		forMember(d => d.events, mapFrom(x => x.events ? x.events.map(y => mapper.map(y, ApiDebtorEvent, DebtorEvent)) : [])),
		forMember(d => d.isLegalEntity, mapFrom(x => x.isLegalEntity)),
		forMember(d => d.projectRoleId, mapFrom(x => x.projectRoleId)),
		forMember(d => d.debtorCompanyName, mapFrom(x => x.debtorCompanyName)),
		forMember(d => d.debtorCompanyId, mapFrom(x => x.debtorCompanyId)),
		forMember(d => d.obligedCompanyId, mapFrom(x => x.obligedCompanyId)),
		forMember(d => d.obligedCompanyName, fromValue(""))
	);

	createMap(mapper, Debtor, ApiDebtor,
		forMember(d => d.versionId, mapFrom(x => x.versionId)),
		forMember(d => d.debtorId, mapFrom(x => x.debtorId)),
		forMember(d => d.currentStatus, mapFrom(x => mapper.map(x.currentStatus, DebtorStatus, ApiDebtorStatus))),
		forMember(d => d.comment, mapFrom(x => x.comment)),
		forMember(d => d.requirements, mapFrom(x => x.requirements)),
		forMember(d => d.statusHistory, mapFrom(x => x.statusHistory.map(y => mapper.map(y, StatusHistoryItem, ApiStatusHistoryItem)))),
		forMember(d => d.events, mapFrom(x => x.events.map(y => mapper.map(y, DebtorEvent, ApiDebtorEvent)))),
		forMember(d => d.isLegalEntity, mapFrom(x => x.isLegalEntity)),
		forMember(d => d.projectRoleId, mapFrom(x => x.projectRoleId)),
		forMember(d => d.debtorCompanyName, mapFrom(x => x.debtorCompanyName)),
		forMember(d => d.debtorCompanyId, mapFrom(x => x.debtorCompanyId)),
		forMember(d => d.obligedCompanyId, mapFrom(x => x.obligedCompanyId))
	);

	createMap(mapper, ApiStatusHistoryItem, StatusHistoryItem,
		forMember(d => d.statusId, mapFrom(x => x.statusId)),
		forMember(d => d.stageTypeId, mapFrom(x => x.stageTypeId)),
		forMember(d => d.stageStatusTypeId, mapFrom(x => x.stageStatusTypeId)),
		forMember(d => d.updatedAt, mapFrom(x => convertToTimestamp(x.updatedAt))),
		forMember(d => d.modifiedBy, mapFrom(x => x.modifiedBy)),
		forMember(d => d.startDate, mapFrom(x => convertToTimestamp(x.startDate))),
		forMember(d => d.expirationDate, mapFrom(x => convertToTimestamp(x.expirationDate)))
	);

	createMap(mapper, StatusHistoryItem, ApiStatusHistoryItem,
		forMember(d => d.statusId, mapFrom(x => x.statusId)),
		forMember(d => d.stageTypeId, mapFrom(x => x.stageTypeId)),
		forMember(d => d.stageStatusTypeId, mapFrom(x => x.stageStatusTypeId)),
		forMember(d => d.updatedAt, mapFrom(x => convertToZonedIso(x.updatedAt))),
		forMember(d => d.modifiedBy, mapFrom(x => x.modifiedBy)),
		forMember(d => d.startDate, mapFrom(x => convertToZonedIso(x.startDate))),
		forMember(d => d.expirationDate, mapFrom(x => convertToZonedIso(x.expirationDate)))
	);

	createMap(mapper, ApiRequirementHistoryItem, RequirementHistoryItem,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.updatedAt, mapFrom(x => convertToTimestamp(x.updatedAt))),
		forMember(d => d.createdBy, mapFrom(x => x.createdBy)),
		forMember(d => d.debtorRequirementId, mapFrom(x => x.debtorRequirementId)),
		forMember(d => d.debtorRequirementTypeId, mapFrom(x => x.debtorRequirementTypeId)),
		forMember(d => d.sum, mapFrom(x => x.sum))
	);

	createMap(mapper, RequirementHistoryItem, ApiRequirementHistoryItem,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.updatedAt, mapFrom(x => convertToZonedIso(x.updatedAt))),
		forMember(d => d.createdBy, mapFrom(x => x.createdBy)),
		forMember(d => d.debtorRequirementId, mapFrom(x => x.debtorRequirementId)),
		forMember(d => d.debtorRequirementTypeId, mapFrom(x => x.debtorRequirementTypeId)),
		forMember(d => d.sum, mapFrom(x => x.sum))
	);

	createMap(mapper, ApiDebtorEvent, DebtorEvent,
		forMember(d => d.eventId, mapFrom(x => x.eventId)),
		forMember(d => d.eventTypeId, mapFrom(x => x.eventTypeId)),
		forMember(d => d.controlDate, mapFrom(x => convertToZonedTimestamp(x.controlDate))),
		forMember(d => d.factualDate, mapFrom(x => convertToZonedTimestamp(x.factualDate))),
		forMember(d => d.judicialDecisionTypeId, mapFrom(x => x.judicialDecisionTypeId)),
		forMember(d => d.debtorStatusHistoryItemId, mapFrom(x => x.debtorStatusHistoryItemId)),
		forMember(d => d.factualPenaltySum, mapFrom(x => x.factualPenaltySum)),
		forMember(d => d.number, mapFrom(x => x.number))
	);

	createMap(mapper, DebtorEvent, ApiDebtorEvent,
		forMember(d => d.eventId, mapFrom(x => x.eventId)),
		forMember(d => d.eventTypeId, mapFrom(x => x.eventTypeId)),
		forMember(d => d.controlDate, mapFrom(x => convertToZonedIso(x.controlDate))),
		forMember(d => d.factualDate, mapFrom(x => convertToZonedIso(x.factualDate))),
		forMember(d => d.judicialDecisionTypeId, mapFrom(x => x.judicialDecisionTypeId)),
		forMember(d => d.factualPenaltySum, mapFrom(x => x.factualPenaltySum)),
		forMember(d => d.debtorStatusHistoryItemId, mapFrom(x => x.debtorStatusHistoryItemId)),
		forMember(d => d.number, mapFrom(x => x.number))
	);

	createMap(mapper, ApiDebtorStatus, DebtorStatus,
		forMember(d => d.statusId, mapFrom(x => x.statusId)),
		forMember(d => d.stageTypeId, mapFrom(x => x.stageTypeId)),
		forMember(d => d.stageStatusTypeId, mapFrom(x => x.stageStatusTypeId)),
		forMember(d => d.updatedAt, mapFrom(x => convertToTimestamp(x.updatedAt))),
		forMember(d => d.modifiedBy, mapFrom(x => x.modifiedBy)),
		forMember(d => d.startDate, mapFrom(x => convertToTimestamp(x.startDate))),
		forMember(d => d.expirationDate, mapFrom(x => convertToTimestamp(x.expirationDate)))
	);

	createMap(mapper, DebtorStatus, ApiDebtorStatus,
		forMember(d => d.statusId, mapFrom(x => x.statusId)),
		forMember(d => d.stageTypeId, mapFrom(x => x.stageTypeId)),
		forMember(d => d.stageStatusTypeId, mapFrom(x => x.stageStatusTypeId)),
		forMember(d => d.updatedAt, mapFrom(x => convertToZonedIso(x.updatedAt))),
		forMember(d => d.modifiedBy, mapFrom(x => x.modifiedBy)),
		forMember(d => d.startDate, mapFrom(x => convertToZonedIso(x.startDate))),
		forMember(d => d.expirationDate, mapFrom(x => convertToZonedIso(x.expirationDate)))
	);

	createMap(mapper, DebtorStatus, ApiUpdateDebtorStatusRequest,
		forMember(d => d.stageTypeId, mapFrom(x => x.stageTypeId)),
		forMember(d => d.debtorStageStatusTypeId, mapFrom(x => x.stageStatusTypeId)),
		forMember(d => d.startDate, mapFrom(x => convertToZonedIso(x.startDate))),
		forMember(d => d.expirationDate, mapFrom(x => convertToZonedIso(x.expirationDate)))
	);
};

addProfile(mapper, proceedingStatusProfile);

export default mapper;

import IPageState from "@/store/shared/base/types/pageState";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import FormState from "@/store/shared/form/models/formState";
import TreasuryStatementRecord from "@/store/loan/modules/treasuryStatement/types/treasuryStatementRecord";
import ApiPaymentExpense from "@/api/loan/types/dictionaries/apiPaymentExpense";
import { TreasuryStatementPaymentsType } from "@/store/loan/modules/treasuryStatement/types/treasuryStatementPaymentsType";
import TreasuryStatementFile from "@/store/loan/modules/treasuryStatement/types/treasuryStatementFile";
import ApiTreasuryStatementPayment from "@/api/loan/types/paymentsProcessing/apiTreasuryStatementPayment";
import TreasuryStatementPayment from "@/store/loan/modules/treasuryStatement/types/treasuryStatementPayment";
import { ApiTreasuryStatementOperationType } from "@/api/loan/types/dictionaries/apiTreasuryStatementOperationType";
import ApiBorrower from "@/api/loan/types/dictionaries/apiBorrower";
import ApiFinanceSource from "@/api/loan/types/dictionaries/apiFinanceSource";

export default class TreasuryStatementState implements IPageState {
	constructor(
		public listing: ListingModel<TreasuryStatementRecord>,
		public sorting: SortingModel<String>,
		public paging: PagingModel,
		public search: SearchModel,
		public form: FormState,
		public snapshot: object,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isProjectStatusFetching: boolean = false,
		public paymentExpenses: ApiPaymentExpense[] = [],
		public allFinanceSources: ApiFinanceSource[] = [],
		public treasuryStatementOperationTypes: ApiTreasuryStatementOperationType[] = [],
		public paymentsFilterType: TreasuryStatementPaymentsType = TreasuryStatementPaymentsType.ALL,
		// @ts-ignore
		public file: TreasuryStatementFile = new TreasuryStatementFile(),
		public fileId: string = "",
		public isTreasuryStatementProcessing: boolean = false,
		public editableItems: ApiTreasuryStatementPayment[] = [],
		public savingItems: ApiTreasuryStatementPayment[] = [],
		public newItem: TreasuryStatementPayment = new TreasuryStatementPayment(),
		public isItemDeleting: boolean = false,
		public editableProjectRecords: TreasuryStatementRecord[] = [],
		public savingProjectRecords: TreasuryStatementRecord[] = [],
		public borrowers: ApiBorrower[] = []
	)
	{
	}
}

export default class Refund {
	refundStatusTypeId: number;
	withoutAcceptance: number;
	bankGuarantee: number;
	penalty: number;

	constructor(
		refundStatusTypeId: number = 0,
		withoutAcceptance: number = 0,
		bankGuarantee: number = 0,
		penalty: number = 0
	)
	{
		this.refundStatusTypeId = refundStatusTypeId;
		this.withoutAcceptance = withoutAcceptance;
		this.bankGuarantee = bankGuarantee;
		this.penalty = penalty;
	}
}

import ApiAgreementsAccruedInterestAccountedData from "@/api/loan/types/loanInterest/apiAgreementsAccruedInterestAccountedData";

export default class ApiAgreementsAccruedInterest {
	projectId: number;
	applicationNumber: number;
	company: string;
	agreementTitle: string;
	agreementStatusName: string;
	daysForPeriod: number;
	accruedAmount: number;
	accountedData: ApiAgreementsAccruedInterestAccountedData[];
	isIncluded: boolean;

	constructor(
		projectId: number = 0,
		applicationNumber: number = 0,
		company: string = "",
		agreementTitle: string = "",
		agreementStatusName: string = "",
		daysForPeriod: number = 0,
		accruedAmount: number = 0,
		accountedData: ApiAgreementsAccruedInterestAccountedData[] = [],
		isIncluded: boolean = false
	)
	{
		this.projectId = projectId;
		this.applicationNumber = applicationNumber;
		this.company = company;
		this.agreementTitle = agreementTitle;
		this.agreementStatusName = agreementStatusName;
		this.daysForPeriod = daysForPeriod;
		this.accruedAmount = accruedAmount;
		this.accountedData = accountedData;
		this.isIncluded = isIncluded;
	}
}

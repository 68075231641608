import { Location } from "vue-router/types/router";

export default class Breadcrumb {
	text: string;
	to: Location;

	constructor(text: string, to: Location) {
		this.text = text;
		this.to = to;
	}
}

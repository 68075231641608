<template>
	<span>
		<frp-dialog :value="!!url" max-width="1074" :title="title">
			<template #content="{ onIntersect }">
				<vue-pdf-embed v-intersect="onIntersect" style="min-height: 450px" :source="url" />
			</template>

			<template #footer>
				<frp-btn elevation="0"
						 outlined
						 color="primary"
						 @click="close">
					{{ $t("buttons.close") }}
				</frp-btn>
				<frp-btn elevation="0"
						 color="blue"
						 dark
						 :href="url"
						 download>
					{{ $t("buttons.download") }}
				</frp-btn>
				<frp-btn elevation="0"
						 color="blue"
						 dark
						 @click="isSubmitOpened = true">
					{{ $t("buttons.submitToAccounting") }}
				</frp-btn>
			</template>
		</frp-dialog>

		<frp-dialog v-model="isSubmitOpened" max-width="630" :title="submitTitle">
			<template #content>
				<template>
					<frp-textarea v-model="comment" :label="$t('dialogs.submitOverdueOrderToAccounting.commentLabel')" :placeholder="$t('dialogs.submitOverdueOrderToAccounting.commentPlaceholder')"></frp-textarea>
				</template>
			</template>

			<template #footer>
				<frp-btn elevation="0"
						 outlined
						 color="primary"
						 @click="isSubmitOpened = false">
					{{ $t("buttons.close") }}
				</frp-btn>
				<frp-btn elevation="0"
						 color="blue"
						 dark
						 @click="submit">
					{{ $t("buttons.submit") }}
				</frp-btn>
			</template>
		</frp-dialog>
	</span>
</template>

<script>
	import FrpDialog from "Components/dialogs/FrpDialog";
	import FrpBtn from "Components/buttons/FrpBtn";
	import FrpTextarea from "Components/fields/FrpTextarea.vue";
	import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
	import { i18n } from "Plugins/index";

	export default {
		data() {
			return {
				comment: "",
				isSubmitOpened: false
			}
		},
		props: ['title', 'blob', 'submitTitle'],
		computed: {
			url() {
				return this.blob ? URL.createObjectURL(this.blob) : "";
			}
		},
		emits: ['close', 'submit'],
		methods: {
			close() {
				this.comment = null;
				this.isSubmitOpened = false;
				this.$emit('close');
			},
			submit() {
				this.$emit('submit', this.comment);
				this.close();
			},
		},
		components: {
			FrpDialog,
			FrpBtn,
			FrpTextarea,
			VuePdfEmbed
		}
	};
</script>

import { getterTypes, actionTypes, mutationTypes, namespace } from "@/store/loan/modules/reportsFinal/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationPayload, MutationTree, Store } from "vuex";
import AbortService from "@/services/abortService";
import ListingMixinBuilder from "@/store/shared/listing";
import SortingMixinBuilder from "@/store/shared/sorting";
import SearchMixinBuilder from "@/store/shared/search";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import SearchModel from "@/store/shared/search/models/searchModel";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import mapper from "@/store/loan/modules/reportsFinal/mapper";
import { ReportsController } from "@/api/loan/reports";
import { first } from "lodash";
import NotDefinedException from "@/exceptions/notDefinedException";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import ReportAddQueryInfo from "@/store/loan/modules/reports/types/reportAddQueryInfo";
import ReportsFinalState from "@/store/loan/modules/reportsFinal/types/reportsFinalState";
import ReportFinalDocument from "@/store/loan/modules/reportsFinal/types/reportFinalDocument";
import ReportFinal from "@/store/loan/modules/reportsFinal/types/reportFinal";
import ApiReportFinal from "@/api/loan/types/reports/apiReportFinal";

const abortService = new AbortService();

const reportsController = new ReportsController(abortService);

const baseMixin = (new BaseMixinBuilder(abortService)).build();
const listingMixin = (new ListingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new ReportsFinalState(
			new ListingModel<ReportFinalDocument>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String>({
				type: "",
				order: sortingOrderType.descending
			}),
			new SearchModel({
				query: ""
			})
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<ReportsFinalState, any>>{
	...listingMixin.getters,
	[getterTypes.formattedItems]: state => {
		return state.listing.items.map(x => {
			let lastDocument = null;
			
			if(x.documentFiles.length && x.documentFiles.length === 1) {
				lastDocument = first(x.documentFiles);
			}
			if(x.documentFiles.length && x.documentFiles.length > 1) {
				const sortedFiles = x.documentFiles.sort((a, b) => {
					if(a.uploadDateTime > b.uploadDateTime)
						return -1;
					else if(a.uploadDateTime < b.uploadDateTime)
						return 1;
					else return 0;
				});
				lastDocument = first(sortedFiles);
			}
			return {
				...x,
				lastDocument
			};
		});
	}
};

const actions = <ActionTree<ReportsFinalState, any>>{
	...listingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }) {
		await dispatch(actionTypes.initializeBase);
		
		await dispatch(actionTypes.updateListingItems);
		
		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.updateListingItems]({ commit, rootState, dispatch, state }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);
		
		try {
			const reportFinal = await reportsController.getReportFinal(rootState.route.params.projectId);
			
			commit(mutationTypes.SET_REPORT_FINAL, mapper.map(reportFinal, ApiReportFinal, ReportFinal));
			commit(mutationTypes.SET_LISTING_ITEMS, state.reportFinal.documents);
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			console.error(error);
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.setListingItems]({ commit, rootState, state }, value) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);
		
		commit(mutationTypes.SET_REPORT_FINAL, mapper.map(value, ApiReportFinal, ReportFinal));
		commit(mutationTypes.SET_LISTING_ITEMS, state.reportFinal.documents);
		
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
	},
	async [actionTypes.uploadDocument]({ commit, dispatch, rootState, state }, { file, signature }: { file: File, signature?: string }) {
		if(signature)
			commit(mutationTypes.SET_IS_SIGNED_DOCUMENT_UPLOADING, true);
		else
			commit(mutationTypes.SET_IS_DOCUMENT_UPLOADING, true);
		
		try {
			if(!state.selectedDocument) throw new NotDefinedException("selectedDocument");
			
			await reportsController.uploadReportFinalDocument(rootState.route.params.projectId,
				file,
				state.selectedDocument.description.documentType,
				signature);
			
			await dispatch(actionTypes.updateListingItems);
			
			alertService.addInfo(AlertKeys.DOCUMENT_SUCCESS_UPLOADED);
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			console.error(error);
		} finally {
			if(signature)
				commit(mutationTypes.SET_IS_SIGNED_DOCUMENT_UPLOADING, false);
			else
				commit(mutationTypes.SET_IS_DOCUMENT_UPLOADING, false);
		}
	},
	async [actionTypes.acceptReport]({ commit, dispatch, rootState, state }) {
		commit(mutationTypes.SET_IS_REPORT_ACCEPTING, true);
		
		try {
			const report = await reportsController.acceptReportFinal(rootState.route.params.projectId);
			
			await dispatch(actionTypes.setListingItems, report);
			
			alertService.addInfo(AlertKeys.REPORT_SUCCESS_ACCEPTED);
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			console.error(error);
		} finally {
			commit(mutationTypes.SET_IS_REPORT_ACCEPTING, false);
		}
	},
	async [actionTypes.declineReport]({ commit, dispatch, rootState, state }) {
		commit(mutationTypes.SET_IS_REPORT_DECLINING, true);
		
		try {
			const report = await reportsController.declineReportFinal(rootState.route.params.projectId);
			
			await dispatch(actionTypes.setListingItems, report);
			
			alertService.addInfo(AlertKeys.REPORT_SUCCESS_DECLINED);
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			console.error(error);
		} finally {
			commit(mutationTypes.SET_IS_REPORT_DECLINING, false);
		}
	},
	async [actionTypes.destroy]({ dispatch }) {
		await dispatch(actionTypes.destroyBase);
	}
};

const mutations = <MutationTree<ReportsFinalState>>{
	...listingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	[mutationTypes.SET_IS_REPORTS_FILTER_LOADING](state, value) {
		state.isReportsFilterLoading = value;
	},
	[mutationTypes.RESET_REPORT_FINAL](state) {
		state.reportFinal = new ReportFinal();
	},
	[mutationTypes.SET_REPORT_FINAL](state, value) {
		state.reportFinal = value;
	},
	[mutationTypes.RESET_SELECTED_DOCUMENT](state) {
		state.selectedDocument = null;
	},
	[mutationTypes.SET_SELECTED_DOCUMENT](state, value) {
		state.selectedDocument = value;
	},
	[mutationTypes.SET_IS_SIGNED_DOCUMENT_UPLOADING](state, value) {
		state.isSignedDocumentUploading = value;
	},
	[mutationTypes.SET_IS_DOCUMENT_UPLOADING](state, value) {
		state.isDocumentUploading = value;
	},
	[mutationTypes.SET_IS_REPORT_ACCEPTING](state, value) {
		state.isReportAccepting = value;
	},
	[mutationTypes.SET_IS_REPORT_DECLINING](state, value) {
		state.isReportDeclining = value;
	},
	[mutationTypes.RESET_REPORT_ADD_QUERY_INFO](state) {
		state.reportAddQueryInfo = new ReportAddQueryInfo();
	},
	[mutationTypes.SET_REPORT_ADD_QUERY_INFO_TITLE](state, value) {
		state.reportAddQueryInfo.title = value;
	},
	[mutationTypes.SET_REPORT_ADD_QUERY_INFO_DESCRIPTION](state, value) {
		state.reportAddQueryInfo.description = value;
	},
	[mutationTypes.SET_REPORT_ADD_QUERY_INFO_EXECUTION_DATE](state, value) {
		state.reportAddQueryInfo.executionDate = value;
	}
};

export {
	namespace, state, getters, actions, mutations
};

const reportsFinalModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default reportsFinalModule;

import { difference } from "@/utils/difference";
import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import AgreementsOverduePaymentsRouteQuery from "@/store/loan/modules/agreementsOverduePayments/types/agreementsOverduePaymentsRouteQuery";
import AgreementsOverduePaymentsState from "@/store/loan/modules/agreementsOverduePayments/types/agreementsOverduePaymentsState";
import { parseArrayParameter } from "@/utils/query";

export default class AgreementsOverduePaymentsRouteQueryService {
	defaultRouteQuery: AgreementsOverduePaymentsRouteQuery;

	constructor(defaultRouteQuery: AgreementsOverduePaymentsRouteQuery) {
		this.defaultRouteQuery = defaultRouteQuery;
	}

	resolveRouteQueryDictionary(state: AgreementsOverduePaymentsState) {
		let query = new AgreementsOverduePaymentsRouteQuery(
			state.paging.page,
			state.filter.projectNumber,
			state.filter.organization,
			state.filter.controller,
			state.filter.statuses
		);

		return difference(this.defaultRouteQuery, query);
	}

	async resolveRouteQuery(query: Dictionary<any>) {
		try {
			let result = plainToInstance(AgreementsOverduePaymentsRouteQuery, {
				...this.defaultRouteQuery,
				...query,
				statuses: parseArrayParameter(query.statuses)
			}, { enableImplicitConversion: true });

			await validateOrReject(result);

			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}
}

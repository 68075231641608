<template>
	<loan-nested-card :title="$t('titles.mainDebt')">
		<template #title-append>
			<router-link :to="{ name: RouteNames.LOAN_SCHEDULE, params: { projectId: $route.params.projectId } }">
				<div @click.prevent="handleOpenLoanSchedule" class="d-flex align-center">
					<span class="blue--text mr-1" style="font-size: 14px">{{ $t("links.loanSchedule") }}</span>
					<frp-icon :color="colors.primary.base" src="ico_open-in-new"></frp-icon>
				</div>
			</router-link>
		</template>
		
		<v-data-table :headers="headers"
					  :loading-text="$t('tables.loading')"
					  hide-default-footer
					  item-key="id"
					  :items="payments"
					  :loading="isItemsLoading"
					  :items-per-page="-1"
					  :mobile-breakpoint="mobileBreakpoint"
					  :options="options"
					  :item-class="() => 'text-body-2 '"
					  class="d-flex flex-column table-headers-top black--text upcoming-payments-table">
			<template #item.dateMainDebt="{ item }">
				<span>{{ formatNullableTableValue(formatDate(item.dateMainDebt, dateFormat)) }}</span>
			</template>
			
			<template #item.paymentMainDebt="{ item }">
				<span>{{ formatNullableTableValue(formatCurrency(item.paymentMainDebt)) }}</span>
			</template>
			
			<template #item.financingSourceId="{ item }">
				<span>{{ financeSources.find(x => x.id === item.financingSourceId).name }}</span>
			</template>
			
			<template #item.action="{ item }">
				<frp-btn elevation="0"
						 dark
						 :loading="loadingFiles.some(x => x.type === PaymentOrderType.SCHEDULE_MAIN_DEBT_REPAYMENT && x.sourceId === item.financingSourceId)"
						 color="blue"
						 @click="downloadPaymentOrder({ sourceId: item.financingSourceId, type: PaymentOrderType.SCHEDULE_MAIN_DEBT_REPAYMENT })">
					{{ $t("buttons.generatePaymentOrder") }}
				</frp-btn>
			</template>
		</v-data-table>
		
		<v-spacer></v-spacer>
		
		<div class="d-flex pa-4 pb-8 text-subtitle-1 font-weight-medium grey--text text--darken-3">
			<span>{{ `${$t("common.debtBalanceFor")}:` }}</span>
			<v-spacer></v-spacer>
			<span class="text-no-wrap ml-2">{{ balanceMainDebt }}</span>
		</div>
	</loan-nested-card>
</template>

<script>
import FrpBtn from "Components/buttons/FrpBtn";
import FrpLinkBtn from "Components/buttons/FrpLinkBtn";
import FrpIcon from "Components/icon/FrpIcon";
import LoanNestedCard from "Components/layouts/LoanNestedCard";
import colorsMixin from "Mixins/colorsMixin";
import { RouteNames } from "Router/loan/routes";
import { PaymentOrderType } from "Store/loan/modules/upcomingPayments/types/paymentOrderType";
import { formatDate } from "Utils/dates";
import { dateFormat } from "Utils/formats";
import { formatCurrency, formatNullableTableValue } from "Utils/formatting";
import { openRouteInNewTab } from "Utils/router";
import { createNamespacedHelpers } from "vuex";
import { actionTypes, namespace } from "Store/loan/modules/upcomingPayments/types";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin],
	data() {
		return {
			mobileBreakpoint: 880,
			options: {},
			PaymentOrderType,
			RouteNames,
			formatDate,
			dateFormat,
			formatCurrency,
			formatNullableTableValue
		};
	},
	computed: {
		...mapState({
			loadingFiles: state => state.loadingFiles,
			isItemsLoading: state => state.isItemsLoading,
			financeSources: state => state.financeSources,
			payments: state => state.payments
		}),
		headers() {
			const headers = [
				{
					text: this.$t("tables.dateMainDebt"),
					value: "dateMainDebt",
					class: "text-body-2 font-weight-bold black--text py-2",
					width: "40%",
					sort: (a, b) => new Date(a).getTime() - new Date(b).getTime(),
					sortable: false
				},
				{
					text: this.$t("tables.paymentSum"),
					value: "paymentMainDebt",
					class: "text-body-2 font-weight-bold black--text py-2",
					width: "30%",
					sortable: false
				},
				{
					text: "",
					value: "action",
					width: "1%",
					sortable: false
				}
			];
			
			if(this.financeSources.length >= 2)
				headers.splice(-1, 0, {
					text: this.$t("tables.financeSource"),
					value: "financingSourceId",
					class: "text-body-2 font-weight-bold black--text py-2",
					width: "30%",
					sortable: false
				});
			
			return headers;
		},
		balanceMainDebt() {
			return formatNullableTableValue(formatCurrency(this.payments.map(x => x.balanceMainDebt).reduce((a, v) => a + v, 0)));
		}
	},
	methods: {
		...mapActions({
			downloadPaymentOrder: actionTypes.downloadPaymentOrder
		}),
		handleOpenLoanSchedule() {
			openRouteInNewTab(this.$router, { name: RouteNames.LOAN_SCHEDULE, params: { projectId: this.$route.params.projectId } });
		}
	},
	components: {
		FrpLinkBtn,
		FrpIcon,
		FrpBtn,
		LoanNestedCard
	}
};
</script>

export default class DebtorEvent {
	eventId: string;
	eventTypeId: string;
	controlDate: number;
	factualDate: number;
	judicialDecisionTypeId: string;
	debtorStatusHistoryItemId: string;
	factualPenaltySum: number;
	number: number;

	constructor(
		eventId: string = "",
		eventTypeId: string = "",
		controlDate: number = 0,
		factualDate: number = 0,
		judicialDecisionTypeId: string = "",
		debtorStatusHistoryItemId: string = "",
		factualPenaltySum: number = 0,
		number: number = 0
	)
	{
		this.eventId = eventId;
		this.eventTypeId = eventTypeId;
		this.controlDate = controlDate;
		this.factualDate = factualDate;
		this.judicialDecisionTypeId = judicialDecisionTypeId;
		this.debtorStatusHistoryItemId = debtorStatusHistoryItemId;
		this.factualPenaltySum = factualPenaltySum;
		this.number = number;
	}
}

<template>
	<v-card elevation="0" class="loan-card px-4 py-4" v-if="isInitialized && !isVersionLoading">
		<v-row class="px-3">
			<v-col cols="auto" class="px-0">
				<div class="d-flex flex-column">
					<span class="text-h6">{{ $t("titles.securityAgreements") }}</span>
					<span v-if="items.length" class="text-caption blue--text text--darken-4 mt-4">
						{{ $t("common.lastAssessmentHint") }}
					</span>
				</div>
			</v-col>
		</v-row>

		<template v-if="!items.length">
			<v-row>
				<v-col class="d-flex align-center flex-column py-16">
					<span class="text-body-2 grey--text">{{ $t("common.noData") }}</span>
				</v-col>
			</v-row>
		</template>

		<template v-else>
			<v-row>
				<v-col>
					<v-data-table :headers="headers"
								  :loading-text="$t('tables.loading')"
								  hide-default-footer
								  :loading="isItemsLoading"
								  item-key="id"
								  :items="items"
								  :items-per-page="-1"
								  :item-class="() => 'text-body-2 cy-table-row'"
								  class="loan-table colored-table clickable-rows d-flex flex-column px-3">
						<template #header>
							<tr class="table-header" style="background-color: var(--v-accent-lighten5) !important;">
								<td>
									<span class="text-subtitle-1">{{ $t("tables.pledges") }}</span>
								</td>
							</tr>
						</template>

						<template #item.securityAgreementId="{ item }">
							<a :href="`/security-agreements/${item.securityAgreementProjectId}/${item.securityAgreementId}/agreement-info`"
							   class="loan-link">
								<span>
									{{ `${securityKindTypes.find(x => x.id === item.securityKindId)?.name} ` }}
								</span>
								<span>
									{{ `[${item.propertyLocation || " -- "}]` }}
								</span>
							</a>
						</template>

						<template #item.guarantor="{ item }">
							<span>{{ item.guarantor }}</span>
						</template>

						<template #item.agreementValue="{ item }">
							<span>{{ formatCurrency(item.agreementValue) }}</span>
						</template>

						<template #item.lastAssessmentDate="{ item }">
							<div class="d-flex align-center">
								<template v-if="mode === SecurityAgreementsModeType.READ">
								<span v-if="item.lastAssessmentDate" class="mr-10">
									{{ formatDate(item.lastAssessmentDate, dateFormat) }}
								</span>
									<span v-if="item.assessmentTypeId">
									{{ assessmentTypes.find(x => x.id === item.assessmentTypeId)?.name }}
								</span>
								</template>
								<template v-else>
									<frp-date-field dense
													placeholder=""
													width="120px"
													v-model="editableItems.find(x => x.securityAgreementId === item.securityAgreementId).lastAssessmentDate"
													hide-details>
									</frp-date-field>
									<frp-autocomplete dense
													  class="ml-2"
													  style="max-width: 70px"
													  :items="assessmentTypes"
													  item-text="name"
													  item-value="id"
													  v-model="editableItems.find(x => x.securityAgreementId === item.securityAgreementId).assessmentTypeId"
													  hide-details>
									</frp-autocomplete>
								</template>
							</div>
						</template>

						<template #item.agreementDate="{ item }">
							<div class="d-flex align-center">
								<span v-if="item.agreementDate" class="mr-10">
									{{ formatDate(item.agreementDate, dateFormat) }}
								</span>
							</div>
						</template>

						<template #item.lastValuation="{ item }">
							<span v-if="mode === SecurityAgreementsModeType.READ">{{ formatCurrency(item.lastValuation) }}</span>
							<span v-else-if="mode === SecurityAgreementsModeType.EDIT && assessmentTypes.find(x => x.id === item.assessmentTypeId)?.name === AssessmentType.AGREEMENT">{{ formatCurrency(item.lastValuation) }}</span>
							<frp-text-field v-else
											dense
											style="max-width: 100px"
											v-model.number="editableItems.find(x => x.securityAgreementId === item.securityAgreementId).lastValuation"
											type="number"
											hide-details>
							</frp-text-field>
						</template>

						<template #item.isPropertySold="{ item }">
							<span>{{item.isPropertySold ? $t("aliases.affirmation.yes") : ""}}</span>
						</template>

						<template #foot>
							<tfoot class="text-subtitle-1 font-weight-medium grey--text text--darken-3">
							<tr>
								<td colspan="2">
									<span>{{ $t("content.total") }}</span>
								</td>
								<td colspan="2">
									<span>{{ agreementAmountSum }}</span>
								</td>
								<td>
									<span>{{ latestEstimateAmountSum }}</span>
								</td>
							</tr>
							</tfoot>
						</template>
					</v-data-table>
				</v-col>
			</v-row>

			<v-row class="mt-16">
				<v-col class="d-flex justify-end">
					<frp-btn v-if="mode === SecurityAgreementsModeType.READ && can(Permissions.LOAN_UPDATE)"
							 :color="colors.blue.base"
							 elevation="0"
							 @click="setMode(SecurityAgreementsModeType.EDIT)"
							 :disabled="!items.length">
						<span class="white--text">{{ $t("buttons.edit") }}</span>
					</frp-btn>
					<frp-btn :color="colors.blue.base"
							 v-if="mode === SecurityAgreementsModeType.EDIT"
							 :loading="isFormSaving"
							 elevation="0"
							 @click="save">
						<span class="white--text">{{ $t("buttons.save") }}</span>
					</frp-btn>

					<v-fade-transition>
						<v-alert v-if="stateContainsUnsavedChanges && mode === SecurityAgreementsModeType.EDIT"
								 class="pa-4 text-start loan-card"
								 style="position: absolute; bottom: 53px; right: 24px; box-shadow: 0 8px 7px rgba(0, 0, 0, 0.08)">
							<div class="d-flex flex-column text-none">
								<span class="subtitle-2 primary--text font-weight-regular">{{ $t("alerts.saveChangesAlert.title") }}</span>
								<span class="text-body-2 grey--text mt-2">{{ $t("alerts.saveChangesAlert.text") }}</span>
							</div>
						</v-alert>
					</v-fade-transition>
				</v-col>
			</v-row>
		</template>
	</v-card>
	<loan-security-agreements-loader v-else/>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn";
import FrpCheckbox from "@/components/fields/FrpCheckbox.vue";
import { AssessmentType } from "@/store/loan/modules/troubledBorrower/types/assessmentType";
import FrpAutocomplete from "Components/fields/FrpAutocomplete";
import FrpDateField from "Components/fields/FrpDateField";
import FrpTextField from "Components/fields/FrpTextField";
import authorizationMixin from "Mixins/authorizationMixin";
import colorsMixin from "Mixins/colorsMixin";
import { convertIsoToNumber, formatDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import { formatCurrency } from "@/utils/formatting";
import formMixin from "Mixins/formMixin";
import { listMixin } from "Mixins/listMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import {
	SecurityAgreementsModeType
} from "Store/loan/modules/troubledBorrower/modules/securityAgreements/types/securityAgreementsModeType";
import LoanSecurityAgreementsLoader from "Views/loan/troubledBorrower/securityAgreements/LoanSecurityAgreementsLoader";
import {
	getterTypes,
	mutationTypes,
	actionTypes
} from "Store/loan/modules/troubledBorrower/modules/securityAgreements/types";
import storeManager from "Store/manager";
import { createNamespacedHelpers } from "vuex";

const namespace = storeManager.loan.troubledBorrower.securityAgreements.namespace;
const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);
const { mapState: troubledBorrowerMapState } = createNamespacedHelpers(storeManager.loan.troubledBorrower.namespace);

export default {
	mixins: [colorsMixin, storeModuleBasedPage, formMixin, listMixin, authorizationMixin],
	data() {
		return {
			namespace,
			SecurityAgreementsModeType,
			AssessmentType,
			formatCurrency,
			formatDate,
			dateFormat
		};
	},
	computed: {
		...troubledBorrowerMapState({
			assessmentTypes: state => state.assessmentTypes,
			securityKindTypes: state => state.securityKindTypes,
			isVersionLoading: state => state.isVersionLoading
		}),
		...mapState({
			isInitialized: state => state.isInitialized,
			mode: state => state.mode,
			editableItems: state => state.editableItems
		}),
		headers() {
			return [
				{
					text: this.$t("tables.securityKindAndLocation"),
					value: "securityAgreementId",
					class: "text-caption",
					width: "20%",
					sortable: false
				},
				{
					text: this.$t("tables.guarantor"),
					value: "guarantor",
					class: "text-caption",
					width: "15%",
					sortable: false
				},
				{
					text: this.$t("tables.agreementValue"),
					value: "agreementValue",
					class: "text-caption",
					width: "15%",
					sortable: false
				},
				{
					text: this.$t("tables.securityAgreementDate"),
					value: "agreementDate",
					class: "text-caption",
					width: "15%",
					sortable: false
				},
				{
					text: this.$t("tables.lastValuation"),
					value: "lastValuation",
					class: "text-caption",
					width: "15%",
					sortable: false
				},
				{
					text: this.$t("tables.lastAssessmentDateAndType"),
					value: "lastAssessmentDate",
					class: "text-caption",
					width: "20%",
					sortable: false
				},
				{
					text: this.$t("tables.isPropertySold"),
					value: "isPropertySold",
					class: "text-caption",
					width: "10%",
					sortable: false
				}
			];
		},
		agreementAmountSum() {
			return this.mode === SecurityAgreementsModeType.EDIT ?
				formatCurrency(this.editableItems.map(x => x.agreementValue).reduce((x, sum) => sum += +x, 0)) :
				formatCurrency(this.items.map(x => x.agreementValue).reduce((x, sum) => sum += +x, 0));
		},
		latestEstimateAmountSum() {
			return this.mode === SecurityAgreementsModeType.EDIT ?
				formatCurrency(this.editableItems.map(x => x.lastValuation).reduce((x, sum) => sum += +x, 0)) :
				formatCurrency(this.items.map(x => x.lastValuation).reduce((x, sum) => sum += +x, 0));
		}
	},
	methods: {
		...mapActions({
			initializeStore: actionTypes.initialize,
			save: actionTypes.save
		}),
		...mapMutations({
			setMode: mutationTypes.SET_MODE
		})
	},
	async created() {
		await this.initializeStore();
	},
	components: {
		FrpCheckbox,
		FrpAutocomplete,
		FrpDateField,
		FrpTextField,
		FrpBtn,
		LoanSecurityAgreementsLoader
	}
};
</script>

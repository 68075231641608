export default class UpcomingPaymentsInterestDetailsItem {
	minDate: number;
	maxDate: number;
	periodName: string;
	periodDays: number;
	accrualDays: number;
	accruedAmount: number;
	financingSourceId: number;

	constructor(
		minDate: number = 0,
		maxDate: number = 0,
		periodName: string = "",
		periodDays: number = 0,
		accrualDays: number = 0,
		accruedAmount: number = 0,
		financingSourceId: number = 0
	)
	{
		this.minDate = minDate;
		this.maxDate = maxDate;
		this.periodName = periodName;
		this.periodDays = periodDays;
		this.accrualDays = accrualDays;
		this.accruedAmount = accruedAmount;
		this.financingSourceId = financingSourceId;
	}
}

import { addProfile, createMap, createMapper, forMember, mapFrom, MappingProfile } from "@automapper/core";
import { classes } from "@automapper/classes";
import { convertToZonedIso, convertToTimestamp } from "@/utils/dates";
import ApiScheduleItem from "@/api/loan/types/loanSchedule/apiScheduleItem";
import ScheduleItem from "@/store/loan/modules/loanSchedule/modules/scheduleItems/types/scheduleItem";
import scheduleMapper from "@/store/loan/modules/loanSchedule/mapper";
import ApiQuarter from "@/api/loan/types/dictionaries/apiQuarter";
import Quarter from "@/types/loan/quarter";

const mapper = createMapper({
	strategyInitializer: classes()
});

const scheduleItemsProfile: MappingProfile = (mapper) => {
	createMap(mapper, ApiScheduleItem, ScheduleItem,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.number, mapFrom(x => x.number)),
		forMember(d => d.paymentDate, mapFrom(x => convertToTimestamp(x.paymentDate))),
		forMember(d => d.source, mapFrom(x => x.source)),
		forMember(d => d.quarter, mapFrom(x => scheduleMapper.map(x.quarter, ApiQuarter, Quarter))),
		forMember(d => d.amount, mapFrom(x => x.amount))
	);

	createMap(mapper, ScheduleItem, ApiScheduleItem,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.number, mapFrom(x => x.number)),
		forMember(d => d.paymentDate, mapFrom(x => convertToZonedIso(x.paymentDate))),
		forMember(d => d.source, mapFrom(x => x.source)),
		forMember(d => d.quarter, mapFrom(x => scheduleMapper.map(x.quarter, Quarter, ApiQuarter))),
		forMember(d => d.amount, mapFrom(x => x.amount))
	);
};

addProfile(mapper, scheduleItemsProfile);

export default mapper;

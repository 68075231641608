import { addProfile, createMap, createMapper, forMember, mapFrom, MappingProfile } from "@automapper/core";
import { classes } from "@automapper/classes";
import ApiReportDocument from "@/api/loan/types/reports/apiReportDocument";
import ReportDocument from "@/store/loan/modules/reports/types/reportDocument";
import { convertToTimestamp, convertToZonedIso, formatDate } from "@/utils/dates";
import ApiReportDocumentFile from "@/api/loan/types/reports/apiReportDocumentFile";
import ReportDocumentFile from "@/store/loan/modules/reports/types/reportDocumentFile";
import ApiReport from "@/api/loan/types/reports/apiReport";
import Report from "@/store/loan/modules/reports/types/report";
import ApiReportSection from "@/api/loan/types/reports/apiReportSection";
import ReportSection from "@/store/loan/modules/reports/types/reportSection";
import ReportAddQueryInfo from "@/store/loan/modules/reports/types/reportAddQueryInfo";
import ApiReportAddQueryInfo from "@/api/loan/types/reports/apiReportAddQueryInfo";
import { isoDateFormat } from "@/utils/formats";

const mapper = createMapper({
	strategyInitializer: classes()
});

const reportsProfile: MappingProfile = (mapper) => {
	createMap(mapper, ApiReport, Report,
		forMember(d => d.sections, mapFrom(x => x.sections.map(s => mapper.map(s, ApiReportSection, ReportSection)))),
		forMember(d => d.status, mapFrom(x => x.status)),
		forMember(d => d.reportFilledDate, mapFrom(x => convertToTimestamp(x.reportFilledDate))),
		forMember(d => d.reportFilledFineDateTime, mapFrom(x => convertToTimestamp(x.reportFilledFineDateTime))),
		forMember(d => d.hasAllFineableDocuments, mapFrom(x => x.hasAllFineableDocuments)),
		forMember(d => d.project, mapFrom(x => x.project)),
		forMember(d => d.acceptance, mapFrom(x => x.acceptance)),
		forMember(d => d.canCreateReportingQueries, mapFrom(x => x.canCreateReportingQueries)),
		forMember(d => d.reportQueries, mapFrom(x => x.reportQueries))
	);

	createMap(mapper, Report, ApiReport,
		forMember(d => d.sections, mapFrom(x => x.sections.map(s => mapper.map(s, ReportSection, ApiReportSection)))),
		forMember(d => d.status, mapFrom(x => x.status)),
		forMember(d => d.reportFilledDate, mapFrom(x => convertToZonedIso(x.reportFilledDate))),
		forMember(d => d.reportFilledFineDateTime, mapFrom(x => convertToZonedIso(x.reportFilledFineDateTime))),
		forMember(d => d.hasAllFineableDocuments, mapFrom(x => x.hasAllFineableDocuments)),
		forMember(d => d.project, mapFrom(x => x.project)),
		forMember(d => d.acceptance, mapFrom(x => x.acceptance)),
		forMember(d => d.canCreateReportingQueries, mapFrom(x => x.canCreateReportingQueries)),
		forMember(d => d.reportQueries, mapFrom(x => x.reportQueries))
	);

	createMap(mapper, ApiReportSection, ReportSection,
		forMember(d => d.documents, mapFrom(x => x.documents.map(d => mapper.map(d, ApiReportDocument, ReportDocument)))),
		forMember(d => d.title, mapFrom(x => x.title)),
		forMember(d => d.number, mapFrom(x => convertToTimestamp(x.number)))
	);

	createMap(mapper, ReportSection, ApiReportSection,
		forMember(d => d.documents, mapFrom(x => x.documents.map(d => mapper.map(d, ReportDocument, ApiReportDocument)))),
		forMember(d => d.title, mapFrom(x => x.title)),
		forMember(d => d.number, mapFrom(x => convertToTimestamp(x.number)))
	);

	createMap(mapper, ApiReportDocumentFile, ReportDocumentFile,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.fileName, mapFrom(x => x.fileName)),
		forMember(d => d.uploader, mapFrom(x => x.uploader)),
		forMember(d => d.uploadDateTime, mapFrom(x => convertToTimestamp(x.uploadDateTime))),
		forMember(d => d.signer, mapFrom(x => x.signer)),
		forMember(d => d.documentId, mapFrom(x => x.documentId))
	);

	createMap(mapper, ReportDocumentFile, ApiReportDocumentFile,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.fileName, mapFrom(x => x.fileName)),
		forMember(d => d.uploader, mapFrom(x => x.uploader)),
		forMember(d => d.uploadDateTime, mapFrom(x => convertToZonedIso(x.uploadDateTime))),
		forMember(d => d.signer, mapFrom(x => x.signer)),
		forMember(d => d.documentId, mapFrom(x => x.documentId))
	);

	createMap(mapper, ApiReportDocument, ReportDocument,
		forMember(d => d.description, mapFrom(x => x.description)),
		forMember(d => d.canUploadFile, mapFrom(x => x.canUploadFile)),
		forMember(d => d.canUploadGeneratedFile, mapFrom(x => x.canUploadGeneratedFile)),
		forMember(d => d.canGenerate, mapFrom(x => x.canGenerate)),
		forMember(d => d.isSignRequired, mapFrom(x => x.isSignRequired)),
		forMember(d => d.uploadDateTime, mapFrom(x => convertToTimestamp(x.uploadDateTime))),
		forMember(d => d.documentFiles, mapFrom(x => x.documentFiles.map(d => mapper.map(d, ApiReportDocumentFile, ReportDocumentFile))))
	);

	createMap(mapper, ReportDocument, ApiReportDocument,
		forMember(d => d.description, mapFrom(x => x.description)),
		forMember(d => d.canUploadFile, mapFrom(x => x.canUploadFile)),
		forMember(d => d.canUploadGeneratedFile, mapFrom(x => x.canUploadGeneratedFile)),
		forMember(d => d.canGenerate, mapFrom(x => x.canGenerate)),
		forMember(d => d.isSignRequired, mapFrom(x => x.isSignRequired)),
		forMember(d => d.uploadDateTime, mapFrom(x => convertToZonedIso(x.uploadDateTime))),
		forMember(d => d.documentFiles, mapFrom(x => x.documentFiles.map(d => mapper.map(d, ReportDocumentFile, ApiReportDocumentFile))))
	);

	createMap(mapper, ReportAddQueryInfo, ApiReportAddQueryInfo,
		forMember(d => d.title, mapFrom(x => x.title)),
		forMember(d => d.description, mapFrom(x => x.description)),
		forMember(d => d.executionDate, mapFrom(x => formatDate(x.executionDate, isoDateFormat)))
	);
};

addProfile(mapper, reportsProfile);

export default mapper;

import ApiFinanceSource from "@/api/loan/types/dictionaries/apiFinanceSource";
import ApiQuarter from "@/api/loan/types/dictionaries/apiQuarter";
import ApiPaymentExpense from "@/api/loan/types/dictionaries/apiPaymentExpense";

export default class ApiTreasuryStatementPayment {
	id: string;
	number: number;
	recordId: string;
	financingSourceId: number;
	quarterId: string;
	paymentExpenseId: string;
	amount: number;
	isFinal: boolean;

	constructor(
		id: string = "",
		number: number = 0,
		recordId: string = "",
		financingSourceId: number = 0,
		quarterId: string = "",
		paymentExpenseId: string = "",
		amount: number = 0,
		isFinal: boolean = false
	)
	{
		this.id = id;
		this.number = number;
		this.recordId = recordId;
		this.financingSourceId = financingSourceId;
		this.quarterId = quarterId;
		this.paymentExpenseId = paymentExpenseId;
		this.amount = amount;
		this.isFinal = isFinal;
	}
}

export default class KeyDates {
	recallReason: string;
	recallDate: number;
	requirements: number;
	declaration: string;
	settlement: number;

	constructor(
		recallReason: string = "",
		recallDate: number = 0,
		requirements: number = 0,
		declaration: string = "",
		settlement: number = 0
	)
	{
		this.recallReason = recallReason;
		this.recallDate = recallDate;
		this.requirements = requirements;
		this.declaration = declaration;
		this.settlement = settlement;
	}
}

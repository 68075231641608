import { ReportStatusType } from "@/store/loan/modules/reports/types/reportStatusType";
import ApiProject from "@/api/loan/types/reports/apiProject";
import ApiReportAcceptance from "@/api/loan/types/reports/apiReportAcceptance";
import ApiReportQueries from "@/api/loan/types/reports/apiReportQueries";
import ReportSection from "@/store/loan/modules/reports/types/reportSection";

export default class Report {
	sections: ReportSection[];
	status: ReportStatusType;
	reportFilledDate: number;
	reportFilledFineDateTime: number;
	hasAllFineableDocuments: boolean;
	project: ApiProject | null;
	acceptance: ApiReportAcceptance;
	canCreateReportingQueries: boolean;
	reportQueries: ApiReportQueries;

	constructor(
		sections: ReportSection[] = [],
		status: ReportStatusType = ReportStatusType.NOT_FILLED,
		reportFilledDate: number = 0,
		reportFilledFineDateTime: number = 0,
		hasAllFineableDocuments: boolean = false,
		project: ApiProject | null = null,
		acceptance: ApiReportAcceptance = new ApiReportAcceptance(),
		canCreateReportingQueries: boolean = false,
		reportQueries: ApiReportQueries = new ApiReportQueries()
	)
	{
		this.sections = sections;
		this.status = status;
		this.reportFilledDate = reportFilledDate;
		this.reportFilledFineDateTime = reportFilledFineDateTime;
		this.hasAllFineableDocuments = hasAllFineableDocuments;
		this.project = project;
		this.acceptance = acceptance;
		this.canCreateReportingQueries = canCreateReportingQueries;
		this.reportQueries = reportQueries;
	}
}

export default class ExtraRateParameters {
	projectId: number;
	accrualStartDate: number;
	accrualEndDate: number;
	extraInterestRate: number;
	paymentDate: number;

	constructor(
		projectId: number = 0,
		accrualStartDate: number = 0,
		accrualEndDate: number = 0,
		extraInterestRate: number = 0,
		paymentDate: number = 0
	)
	{
		this.projectId = projectId;
		this.accrualStartDate = accrualStartDate;
		this.accrualEndDate = accrualEndDate;
		this.extraInterestRate = extraInterestRate;
		this.paymentDate = paymentDate;
	}
}

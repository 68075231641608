import ApiCashFlowItem from "@/api/loan/types/paymentsProcessing/apiCashFlowItem";
import { Type } from "class-transformer";

export default class ApiGetCashFlowItemsResponse {
	type: string;
	reservedTotalAmount: number;

	@Type(() => ApiCashFlowItem)
	items: ApiCashFlowItem[];

	constructor(
		type: string,
		reservedTotalAmount: number,
		items: ApiCashFlowItem[]
	)
	{
		this.type = type;
		this.reservedTotalAmount = reservedTotalAmount;
		this.items = items;
	}
}

import { mutationTypes, actionTypes, getterTypes, namespace } from "@/store/loan/modules/loanSchedule/modules/tranches/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import TranchesState from "@/store/loan/modules/loanSchedule/modules/tranches/types/tranchesState";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import AbortService from "@/services/abortService";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import storeManager from "@/store/manager";
import loanScheduleTypes from "@/store/loan/modules/loanSchedule/types";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import mapper from "@/store/loan/modules/loanSchedule/modules/tranches/mapper";
import LoanScheduleState from "@/store/loan/modules/loanSchedule/types/loanScheduleState";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import FormMixinBuilder from "@/store/shared/form";
import ListingMixinBuilder from "@/store/shared/listing";
import PagingMixinBuilder from "@/store/shared/paging";
import SortingMixinBuilder from "@/store/shared/sorting";
import SearchMixinBuilder from "@/store/shared/search";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import { LoanScheduleController } from "@/api/loan/loanSchedule";
import Tranche from "@/store/loan/modules/loanSchedule/modules/tranches/types/tranche";
import ApiTranche from "@/api/loan/types/loanSchedule/apiTranche";
import { TranchesModeType } from "@/store/loan/modules/loanSchedule/modules/tranches/types/tranchesModeType";
import { cloneDeep } from "lodash";
import AgreementState from "@/store/loan/modules/loanSchedule/modules/agreement/types/agreementState";
import { plainToInstance } from "class-transformer";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import { sumFloat } from "@/utils/number";

const abortService = new AbortService();
const loanScheduleController = new LoanScheduleController(abortService);

const baseMixin = (new BaseMixinBuilder(abortService)).build();
const formMixin = (new FormMixinBuilder()).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["newItem"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new TranchesState(
			new ListingModel<Tranche>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String>({
				type: "paymentDate",
				order: sortingOrderType.ascending
			}),
			new PagingModel({
				total: 0,
				page: 1,
				pageSize: 25
			}),
			new SearchModel({
				query: ""
			}),
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<TranchesState, any>>{
	...listingMixin.getters,
	...formMixin.getters,
	...snapshotMixin.getters,
	[getterTypes.formattedItems]: state => {
		return state.listing.items.map(x => {
			return plainToInstance(Tranche, {
				...x
			});
		});
	},
	[getterTypes.version]: (state, getters, rootState) => {
		const { version } = resolveNestedState<LoanScheduleState>(rootState, storeManager.loan.loanSchedule.namespace);
		return version;
	}
};

const actions = <ActionTree<TranchesState, any>>{
	...listingMixin.actions,
	...pagingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }) {
		await dispatch(actionTypes.initializeBase);

		await dispatch(actionTypes.fetch);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
		commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
	},
	async [actionTypes.fetch]({ commit, getters }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);

		try {
			let items = getters.version.id ? await loanScheduleController.getTranches(getters.version.id) : [];

			commit(mutationTypes.SET_LISTING_ITEMS, items.map(x => mapper.map(x, ApiTranche, Tranche)));
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.updateItems]({ commit, state, getters, rootState }) {
		commit(mutationTypes.SET_IS_ITEMS_UPDATING, true);

		try {
			const { agreement: { exactFrpSum } } = resolveNestedState<AgreementState>(rootState,
				storeManager.loan.loanSchedule.agreement.namespace);

			const amount = state.editableItems.map((x: Tranche) => x.amount)
							 .reduce((acc: number, x: number) => sumFloat(acc, +x), 0);

			if(getters.formattedItems.length && exactFrpSum !== amount) {
				commit(mutationTypes.WRONG_TRANCHES_AMOUNT_EVENT);
				return;
			}

			await loanScheduleController.updateTranches(getters.version.id,
				state.editableItems.map(x => mapper.map(x, Tranche, ApiTranche)));

			commit(mutationTypes.SET_LISTING_ITEMS, cloneDeep(state.editableItems));
			commit(mutationTypes.SET_MODE, TranchesModeType.READ);
			alertService.addInfo(AlertKeys.TRANCHES_SUCCESS_SAVED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_ITEMS_UPDATING, false);
		}
	},
	async [actionTypes.createItem]({ commit, state, getters }) {
		commit(mutationTypes.SET_IS_ITEM_CREATING, true);

		try {
			const item = await loanScheduleController.createTranche(getters.version.id,
				mapper.map(state.newItem, Tranche, ApiTranche));

			alertService.addInfo(AlertKeys.TRANCHE_SUCCESS_CREATED);
			commit(mutationTypes.SET_LISTING_ITEMS, [...state.listing.items, mapper.map(item, ApiTranche, Tranche)]);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_ITEM_CREATING, false);
		}
	},
	async [actionTypes.deleteItem]({ dispatch, commit, rootState, state, getters }, { item }) {
		commit(mutationTypes.SET_IS_ITEM_DELETING, true);

		try {
			await loanScheduleController.deleteTranche(getters.version.id, item.id);

			alertService.addInfo(AlertKeys.TRANCHE_SUCCESS_DELETED);
			commit(mutationTypes.SET_LISTING_ITEMS, state.listing.items.filter(x => x.id !== item.id));
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_ITEM_DELETING, false);
		}
	}
};

const mutations = <MutationTree<TranchesState>>{
	...listingMixin.mutations,
	...pagingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	[mutationTypes.RESET_NEW_ITEM](state) {
		state.newItem = new Tranche();
	},
	[mutationTypes.SET_EDITABLE_ITEMS](state, value) {
		state.editableItems = value;
	},
	[mutationTypes.SET_IS_ITEMS_UPDATING](state, value) {
		state.isItemsUpdating = value;
	},
	[mutationTypes.SET_IS_ITEM_CREATING](state, value) {
		state.isItemCreating = value;
	},
	[mutationTypes.SET_IS_ITEM_DELETING](state, value) {
		state.isItemDeleting = value;
	},
	[mutationTypes.SET_NEW_ITEM_SOURCE](state, value) {
		state.newItem.source = value;
	},
	[mutationTypes.SET_NEW_ITEM_PAYMENT_DATE](state, value) {
		state.newItem.paymentDate = value;
	},
	[mutationTypes.SET_NEW_ITEM_AMOUNT](state, value) {
		state.newItem.amount = value;
	},
	[mutationTypes.SET_MODE](state, value) {
		state.mode = value;
	},
	[mutationTypes.WRONG_TRANCHES_AMOUNT_EVENT]() {
	}
};

const subscribe = (store: any) => {
	const { commit, dispatch } = store;

	store.subscribe(async ({ type, payload }: any, state: any) => {
		switch (type) {
			case resolveMutation(storeManager.loan.loanSchedule.tranches.namespace, mutationTypes.SET_MODE):
			{
				const { listing: { items } } = resolveNestedState<TranchesState>(state,
					storeManager.loan.loanSchedule.tranches.namespace);

				if(payload === TranchesModeType.EDIT)
					commit(resolveMutation(storeManager.loan.loanSchedule.tranches.namespace, mutationTypes.SET_EDITABLE_ITEMS),
						cloneDeep(items));

				break;
			}

			case resolveMutation(storeManager.loan.loanSchedule.namespace, loanScheduleTypes.mutationTypes.SET_VERSION):
			{
				const { isInitialized } = resolveNestedState<TranchesState>(state, storeManager.loan.loanSchedule.tranches.namespace);

				if(isInitialized) {
					await dispatch(resolveAction(storeManager.loan.loanSchedule.tranches.namespace, actionTypes.fetch));
					commit(resolveMutation(storeManager.loan.loanSchedule.tranches.namespace, mutationTypes.SET_MODE),
						TranchesModeType.READ);
				}

				break;
			}

			default:
				break;
		}
	});
};

export {
	namespace, state, getters, actions, mutations, subscribe
};

const tranchesModule = {
	namespace, state, getters, actions, mutations, subscribe, namespaced: true
};

export default tranchesModule;

import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import urls, { urlTemplateParts } from "@/api/config/urls";
import { ApiGetAssignmentCompensationProjectsParameters }
	from "@/api/loan/types/assignmentCompensation/apiGetAssignmentCompensationProjectsParameters";
import { ApiAssignmentCompensationProject } from "@/api/loan/types/assignmentCompensation/apiAssignmentCompensationProject";
import { prepareUrl } from "@/utils/prepareUrlQuery";
import { ApiAssignmentCompensationInterestRequest } from "@/api/loan/types/assignmentCompensation/apiAssignmentCompensationInterestRequest";
import {
	ApiAssignmentCompensationOrderForSubmissionToAccountingRequest
} from "@/api/loan/types/assignmentCompensation/apiAssignmentCompensationOrderForSubmissionToAccountingRequest";
import {
	ApiCalculateAssignmentCompensationRequest
} from "@/api/loan/types/assignmentCompensation/apiCalculateAssignmentCompensationRequest";

export class LoanAssignmentCompensationController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getAssignmentCompensationProjects = async (parameters: ApiGetAssignmentCompensationProjectsParameters): Promise<ApiAssignmentCompensationProject[]> => {
		const url = prepareUrl(urls.loan.assignmentCompensation.getProjects, parameters);
		return await this.client.get<ApiAssignmentCompensationProject[]>(url);
	};
	
	addInterest = async (projectId: string, payload: ApiAssignmentCompensationInterestRequest): Promise<any> => {
		const url = urls.loan.assignmentCompensation.addInterest.replace(urlTemplateParts.id, projectId);
		return await this.client.post<any>(url, payload);
	};
	
	calculateAssignmentCompensation = async (payload: ApiCalculateAssignmentCompensationRequest): Promise<void> => {
		const url = urls.loan.assignmentCompensation.calculateAssignmentCompensation;
		return await this.client.post(url, payload);
	};
	
	createOrderForSubmissionToAccounting = async (payload: ApiAssignmentCompensationOrderForSubmissionToAccountingRequest): Promise<{ orderNumber: number }> => {
		const url = urls.loan.assignmentCompensation.createOrderForSubmissionToAccounting;
		return await this.client.post<{ orderNumber: number }>(url, payload);
	};
	
	getAccountingReport = async (orderNumber: string): Promise<Blob> => {
		const url = urls.loan.assignmentCompensation.getAccountingReport.replace(urlTemplateParts.id, orderNumber);
		return await this.client.get<Blob>(url, { responseType: "blob" });
	};
	
	sendDocumentToAccounting = async (number: string): Promise<void> => {
		const url = urls.loan.assignmentCompensation.sendDocumentToAccounting.replace(urlTemplateParts.id, number);
		return await this.client.post(url, {});
	};
}

<template>
	<loan-content-layout>
		<v-row>
			<v-col class="d-flex align-center pb-2">
				<v-card-title class="text-h6 px-0 pt-2 pb-0 ml-4">
					<span style="white-space: nowrap">{{ $t("titles.troubledBorrowers") }}</span>
				</v-card-title>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="d-flex flex-column pt-4" style="gap: 24px">
				<v-card elevation="0" class="loan-card pa-0 pt-6">
					<v-row class="px-6">
						<v-col class="d-flex justify-center align-end" style="gap: 24px">
							<frp-text-field v-model="internalFilterValues.companyName"
											:label="$t('fields.organizationName.label')"
											hide-details
											@keydown.enter="applyFilter"
											:placeholder="$t('fields.organizationName.placeholder')">
							</frp-text-field>

							<frp-autocomplete v-model="internalFilterValues.responsibleUserId"
											  :label="$t('fields.responsibleUser.label')"
											  :items="problemDebtDepartments"
											  :loading="isDictionariesLoading"
											  item-text="name"
											  item-value="id"
											  color="blue"
											  hide-details
											  @keydown.enter="applyFilter"
											  clearable
											  :placeholder="$t('fields.responsibleUser.placeholder')">
							</frp-autocomplete>

							<v-spacer></v-spacer>

							<div class="align-self-end d-flex flex-nowrap">
								<frp-btn elevation="0"
										 color="blue"
										 dark
										 class="text-none px-14"
										 :disabled="!isFilterChanged"
										 @click="applyFilter">
									{{ $t("buttons.search") }}
								</frp-btn>
							</div>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-data-table :headers="headers"
										  :loading-text="$t('tables.loading')"
										  :loading="isItemsLoading"
										  hide-default-footer
										  item-key="id"
										  :items="items"
										  :items-per-page="-1"
										  :mobile-breakpoint="mobileBreakpoint"
										  :options="options"
										  :item-class="() => 'text-body-2 cy-table-row'"
										  class="loan-table d-flex flex-column troubled-borrowers-table">
								<template #item.companyName="{ item }">
									<frp-text-btn class="pa-0 loan-text-btn"
												  :to="{ name: RouteNames.TROUBLED_BORROWER, params: { projectId: first(item.projectIds) }}"
												  :text="item.companyName"
												  color="primary">
									</frp-text-btn>
								</template>

								<template #item.agreementInfos="{ item }">
									<span>
										{{
											item.agreementInfos.map(x => `${x.number}${x.agreementStatusName ? ` (${x.agreementStatusName})` : ''} - ${x.financingSchemes.map(
												y => y.name).join(", ")}`).join(", ")
										}}
									</span>
								</template>

								<template #item.frpSum="{ item }">
									<span>{{ formatCurrency(item.frpSum, 0) || "--" }}</span>
								</template>

								<template #item.requirementsSum="{ item }">
									<span>{{ formatCurrency(item.requirementsSum, 0) || "--" }}</span>
								</template>

								<template #item.fullRefundStatus="{ item }">
									<span>{{ refundStatusTypes.find(x => x.id === item.fullRefundStatusId)?.name }}</span>
								</template>

								<template #item.responsibleUserName="{ item }">
									<span>{{ item.responsibleUserName }}</span>
								</template>

								<template #item.action="{ item }">
									<frp-text-btn v-if="can(Permissions.LOAN_UPDATE)"
												  class="pa-0 loan-text-btn"
												  :href="getGeneratePassportLink(item.companyOgrn)"
												  :text="$t('buttons.generatePassport')"
												  color="primary">
									</frp-text-btn>
								</template>
							</v-data-table>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
		</v-row>

		<v-row class="mt-0">
			<v-col class="d-flex justify-end">
				<frp-pagination v-if="!isItemsLoading"
								v-model="page"
								active-color="blue"
								:length="Math.ceil(paging.total / paging.pageSize)">
				</frp-pagination>
			</v-col>
		</v-row>
	</loan-content-layout>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn";
import FrpAutocomplete from "@/components/fields/FrpAutocomplete";
import FrpDateField from "@/components/fields/FrpDateField";
import FrpTextField from "@/components/fields/FrpTextField";
import FrpIcon from "@/components/icon/FrpIcon";
import LoanContentLayout from "@/components/layouts/LoanContentLayout";
import colorsMixin from "@/mixins/colorsMixin";
import {listMixin} from "@/mixins/listMixin";
import {formatCurrency} from "@/utils/formatting";
import FrpTextBtn from "Components/buttons/FrpTextBtn";
import FrpPagination from "Components/common/FrpPagination";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import {i18n} from "Plugins/index";
import {RouteNames} from "Router/loan/routes";
import {actionTypes, mutationTypes, namespace} from "Store/loan/modules/troubledBorrowers/types";
import {createNamespacedHelpers} from "vuex";
import {first} from "lodash";
import authorizationMixin from "@/mixins/authorizationMixin";

const {mapState, mapGetters, mapMutations, mapActions} = createNamespacedHelpers(namespace);

export default {
	metaInfo: {
		title: i18n.t("metaTitles.troubledBorrowers")
	},
	mixins: [storeModuleBasedPage, listMixin, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			RouteNames,
			formatCurrency,
			first,
			internalFilterValues: {
				companyName: "",
				responsibleUserId: ""
			}
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			isDictionariesLoading: state => state.isDictionariesLoading,
			projectManagers: state => state.projectManagers,
			problemDebtDepartments: state => state.problemDebtDepartments,
			refundStatusTypes: state => state.refundStatusTypes
		}),
		headers() {
			return [
				{
					text: this.$t("tables.organizationShortName"),
					value: "companyName",
					class: "text-caption",
					width: "18%",
					sortable: false
				},
				{
					text: this.$t("tables.loanAgreements"),
					value: "agreementInfos",
					class: "text-caption",
					width: "26%",
					sortable: false
				},
				{
					text: this.$t("tables.frpSum"),
					value: "frpSum",
					class: "text-caption",
					width: "10%",
					sortable: false
				},
				{
					text: this.$t("tables.requirements"),
					value: "requirementsSum",
					class: "text-caption",
					width: "10%",
					sortable: false
				},
				{
					text: this.$t("tables.fullRefundStatus"),
					value: "fullRefundStatus",
					class: "text-caption",
					width: "10%",
					sortable: false
				},
				{
					text: this.$t("tables.responsibleUser"),
					value: "responsibleUserName",
					class: "text-caption",
					width: "10%",
					sortable: false
				},
				{
					text: this.$t("tables.newTroubledBorrowerPassportGeneration"),
					value: "action",
					class: "text-caption",
					width: "10%",
					sortable: false
				}
			];
		},
		filter() {
			return {
				companyName: this.internalFilterValues.companyName,
				responsibleUserId: this.internalFilterValues.responsibleUserId
			};
		}
	},
	methods: {
		...mapActions({
			initializeStore: actionTypes.initialize
		}),
		...mapMutations({
			setFilterCompanyName: mutationTypes.SET_FILTER_COMPANY_NAME,
			setFilterResponsibleUserId: mutationTypes.SET_FILTER_RESPONSIBLE_USER_ID
		}),
		setInternalFilterValues() {
			Object.keys(this.internalFilterValues).forEach(key => this.internalFilterValues[key] = this.filterValues[key]);
		},
		applyFilter() {
			this.setFilterCompanyName(this.internalFilterValues.companyName);
			this.setFilterResponsibleUserId(this.internalFilterValues.responsibleUserId || "");
		},
		getGeneratePassportLink(ogrn) {
			return `http://reporting/ReportServer/Pages/ReportViewer.aspx?/Отчеты/ЮД/Паспорт проблемного заемщика&ogrn=${ogrn}`;
		}
	},
	created() {
		this.initializeStore();
	},
	components: {
		FrpTextBtn,
		LoanContentLayout,
		FrpPagination,
		FrpBtn,
		FrpDateField,
		FrpIcon,
		FrpTextField,
		FrpAutocomplete
	}
};
</script>
<style lang="scss">
.table-header {
	height: 48px;
	width: 100%;
	background-color: var(--v-grey-darken4) !important;
}

.v-application {
	.loan-text-btn {
		text-decoration: underline;

		&:hover {
			color: var(--v-blue-base) !important;
			caret-color: var(--v-blue-base) !important;
		}

		.v-btn__content {
			justify-content: start;
		}
	}

	.troubled-borrowers-table {
		tr:nth-child(even) {
			background-color: var(--v-primary-lighten3);
		}

		th {
			padding-top: 36px !important;
			padding-bottom: 14px !important;
			border-top: 1px solid var(--v-grey-lighten4) !important;
		}

		td {
			vertical-align: top;
			padding: 20px 5px !important;
		}

		td:first-child, th:first-child {
			padding-left: 40px !important;
		}

		td:last-child, th:last-child {
			padding-right: 40px !important;
		}
	}
}
</style>

import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import { listingActionTypes, listingGetterTypes, listingMutationTypes } from "@/store/shared/listing/types";
import { pagingActionTypes, pagingMutationTypes } from "@/store/shared/paging/types";
import { sortingActionTypes, sortingMutationTypes } from "@/store/shared/sorting/types";
import { searchMixinTypes } from "@/store/shared/search/types";
import routeTypes from "@/store/shared/route/types";

export const namespace = "troubled-borrowers";

export const getterTypes = {
	...listingGetterTypes
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	...pagingActionTypes,
	...sortingActionTypes,
	...listingActionTypes,
	...searchMixinTypes.actionTypes,
	...routeTypes.actionTypes,
	fetchDictionaries: "fetchDictionaries"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	...sortingMutationTypes,
	...pagingMutationTypes,
	...listingMutationTypes,
	...searchMixinTypes.mutationTypes,
	...routeTypes.mutationTypes,
	SET_IS_DICTIONARIES_LOADING: "SET_IS_DICTIONARIES_LOADING",
	SET_FILTER_COMPANY_NAME: "SET_FILTER_COMPANY_NAME",
	SET_FILTER_RESPONSIBLE_USER_ID: "SET_FILTER_RESPONSIBLE_USER_ID",
	SET_PROBLEM_DEBT_DEPARTMENTS: "SET_PROBLEM_DEBT_DEPARTMENTS",
	SET_REFUND_STATUS_TYPES: "SET_REFUND_STATUS_TYPES"
};

const troubledBorrowersTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default troubledBorrowersTypes;

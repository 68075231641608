import { mutationTypes, actionTypes, getterTypes, namespace } from "@/store/loan/modules/troubledBorrower/modules/securityAgreements/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import AbortService from "@/services/abortService";
import { TroubledBorrowerController } from "@/api/loan/troubledBorrower";
import SecurityAgreementsState from "@/store/loan/modules/troubledBorrower/modules/securityAgreements/types/securityAgreementsState";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import SecurityAgreement from "@/store/loan/modules/troubledBorrower/modules/securityAgreements/types/securityAgreement";
import FormMixinBuilder from "@/store/shared/form";
import ListingMixinBuilder from "@/store/shared/listing";
import PagingMixinBuilder from "@/store/shared/paging";
import SortingMixinBuilder from "@/store/shared/sorting";
import SearchMixinBuilder from "@/store/shared/search";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import { resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import TroubledBorrowerState from "@/store/loan/modules/troubledBorrower/types/troubledBorrowerState";
import storeManager from "@/store/manager";
import ApiSecurityAgreement from "@/api/loan/types/troubledBorrower/apiSecurityAgreement";
import mapper from "@/store/loan/modules/troubledBorrower/modules/securityAgreements/mapper";
import { cloneDeep } from "lodash";
import {
	SecurityAgreementsModeType
} from "@/store/loan/modules/troubledBorrower/modules/securityAgreements/types/securityAgreementsModeType";
import { MainInfoModeType } from "@/store/loan/modules/troubledBorrower/modules/mainInfo/types/mainInfoModeType";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import { AssessmentType } from "@/store/loan/modules/troubledBorrower/types/assessmentType";
import ApiAssessmentType from "@/api/loan/types/dictionaries/apiAssessmentType";

const abortService = new AbortService();
const troubledBorrowerController = new TroubledBorrowerController(abortService);

const baseMixin = (new BaseMixinBuilder(abortService)).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();
const formMixin = (new FormMixinBuilder()).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["editableItems"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new SecurityAgreementsState(
			new ListingModel<SecurityAgreement>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String>({
				type: "",
				order: sortingOrderType.ascending
			}),
			new PagingModel({
				total: 0,
				page: 1,
				pageSize: 25
			}),
			new SearchModel({
				query: ""
			}),
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<SecurityAgreementsState, any>>{
	...listingMixin.getters,
	...formMixin.getters,
	...snapshotMixin.getters,
	[getterTypes.version]: (state, getters, rootState) => {
		return resolveNestedState<TroubledBorrowerState>(rootState, storeManager.loan.troubledBorrower.namespace).version;
	}
};

const actions = <ActionTree<SecurityAgreementsState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...listingMixin.actions,
	...pagingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }) {
		await dispatch(actionTypes.initializeBase);
		
		await dispatch(actionTypes.updateListingItems);
		
		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.updateListingItems]({ commit, getters }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);
		
		try {
			let items = await troubledBorrowerController.getSecurityAgreements(getters.version.versionHeader.versionId);
			
			commit(mutationTypes.SET_LISTING_ITEMS, items.map(x => mapper.map(x, ApiSecurityAgreement, SecurityAgreement)));
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.save]({ commit, dispatch, state, rootState }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);
		
		try {
			await troubledBorrowerController.updateSecurityAgreements(state.editableItems.map(x => mapper.map(x,
				SecurityAgreement,
				ApiSecurityAgreement)));
			
			const { assessmentTypes } = resolveNestedState<TroubledBorrowerState>(rootState, storeManager.loan.troubledBorrower.namespace);
			
			const items = state.editableItems.map(x => {
				const assessmentType = assessmentTypes.find((type: ApiAssessmentType) => type.id === x.assessmentTypeId);
				return assessmentType?.name === AssessmentType.AGREEMENT ? { ...x, lastValuation: x.agreementValue } : x;
			});
			
			commit(mutationTypes.SET_LISTING_ITEMS, cloneDeep(items));
			commit(mutationTypes.SET_MODE, MainInfoModeType.READ);
			alertService.addInfo(AlertKeys.SUCCESS_UPDATED_INFO);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	}
};

const mutations = <MutationTree<SecurityAgreementsState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...listingMixin.mutations,
	...pagingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	[mutationTypes.SET_MODE](state, value) {
		state.mode = value;
	},
	[mutationTypes.SET_EDITABLE_ITEMS](state, value) {
		state.editableItems = cloneDeep(value);
	}
};

const subscribe = (store: any) => {
	const { commit, dispatch } = store;
	
	store.subscribe(async ({ type, payload }: any, state: any) => {
		switch (type) {
			case resolveMutation(storeManager.loan.troubledBorrower.securityAgreements.namespace, mutationTypes.SET_MODE):
			{
				const { listing: { items } } = resolveNestedState<SecurityAgreementsState>(state, storeManager.loan.troubledBorrower.securityAgreements.namespace);
				const { assessmentTypes } = resolveNestedState<TroubledBorrowerState>(state, storeManager.loan.troubledBorrower.namespace);
				
				if(payload === SecurityAgreementsModeType.EDIT) {
					// Когда включаем mode edit, стандартное значение типа проведенной оценки должно стать [д], а не пустым значением
					const agreementAssessmentTypeId = assessmentTypes.find((type: ApiAssessmentType) => type.name === AssessmentType.AGREEMENT);
					const mappedItems = items.map(x => x.assessmentTypeId ? x : { ...x, assessmentTypeId: agreementAssessmentTypeId?.id });

					commit(resolveMutation(storeManager.loan.troubledBorrower.securityAgreements.namespace,
						mutationTypes.SET_EDITABLE_ITEMS), mappedItems);
					
					commit(resolveMutation(storeManager.loan.troubledBorrower.securityAgreements.namespace,
						mutationTypes.SET_STATE_SNAPSHOT), stateSnapshotKeys.LAST_SAVED);
				}
				
				break;
			}
			
			default:
				break;
		}
	});
};

export {
	namespace, state, getters, actions, mutations, subscribe
};

const securityAgreementsModule = {
	namespace, state, getters, actions, mutations, subscribe, namespaced: true
};

export default securityAgreementsModule;

export default class ApiIncomePrediction {
	id: string;
	year: number;
	quarter: number;
	conservativeValue?: number;
	optimisticValue?: number;

	constructor(id: string, year: number, quarter: number, conservativeValue?: number, optimisticValue?: number) {
		this.id = id;
		this.year = year;
		this.quarter = quarter;
		this.conservativeValue = conservativeValue;
		this.optimisticValue = optimisticValue;
	}
}

export function expandDefault(items: ApiIncomePrediction[]) {
	const currentYear = new Date().getFullYear();

	var result: ApiIncomePrediction[] = [...items];

	for (var year = currentYear; year < currentYear + 20; year++) {
		for (var quarter = 1; quarter < 5; quarter++) {
			var item = items.find(x => x.year == year && x.quarter == quarter);
			if (!item) {
				result.push({ id: `00000000-0000-0000-0000-0000000${year}${quarter}`, quarter: quarter, year: year });
			}
		}
	}

	return result.sort((x1, x2) => x1.year < x2.year ? -1 : (x1.year > x2.year ? 1 : (x1.quarter < x2.quarter ? -1 : (x1.quarter > x2.quarter ? 1 : 0))));
}

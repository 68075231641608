import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import FormState from "@/store/shared/form/models/formState";
import IPageState from "@/store/shared/base/types/pageState";
import Tranche from "@/store/loan/modules/loanSchedule/modules/tranches/types/tranche";
import { TranchesModeType } from "@/store/loan/modules/loanSchedule/modules/tranches/types/tranchesModeType";

export default class TranchesState implements IPageState {
	constructor(
		public listing: ListingModel<Tranche>,
		public sorting: SortingModel<String>,
		public paging: PagingModel,
		public search: SearchModel,
		public form: FormState,
		public snapshot: object,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public newItem: Tranche = new Tranche(),
		public editableItems: Tranche[] = [],
		public isItemsUpdating: boolean = false,
		public isItemCreating: boolean = false,
		public isItemDeleting: boolean = false,
		public mode: TranchesModeType = TranchesModeType.READ
	)
	{
	}
}

<template>
	<v-row class="ma-0 mb-12">
		<v-col cols="12"
			   :lg="isOverduesMainDebtVisible && isOverduesInterestVisible ? 8 : 5"
			   v-if="isOverduesMainDebtVisible || isOverduesInterestVisible"
			   :class="{ 'pb-0': $vuetify.breakpoint.mdAndDown }">
			<loan-card :title="$t('titles.overdues')" :card-style="financeSources.length >= 2 ? 'min-height: unset' : ''">
				<template #title-append>
					<frp-icon src="ico_info" :color="colors.secondary.lighten2"></frp-icon>
					<span class="secondary--text text--lighten-2 ml-1">{{ $t("content.immediateRepaymentIsRequired") }}</span>
				</template>
				
				<v-row class="ma-0 fill-height">
					<v-col :md="isOverduesInterestVisible ? 6 : 12" class="pa-0"
						   v-if="isOverduesMainDebtVisible"
						   :class="{ 'pr-2': $vuetify.breakpoint.mdAndUp, 'mb-4': $vuetify.breakpoint.smAndDown }">
						<loan-payments-overdues-main-debt></loan-payments-overdues-main-debt>
					</v-col>
					
					<v-col :md="isOverduesMainDebtVisible ? 6 : 12" class="pa-0"
						   v-if="isOverduesInterestVisible"
						   :class="{ 'pl-2': $vuetify.breakpoint.mdAndUp }">
						<loan-payments-overdues-interest></loan-payments-overdues-interest>
					</v-col>
				</v-row>
			</loan-card>
		</v-col>
		
		<v-col v-if="isPenaltyVisible"
			   cols="12"
			   :lg="!isOverduesMainDebtVisible && !isOverduesInterestVisible ? 5 : 4"
			   :class="{ 'pt-2': $vuetify.breakpoint.mdAndDown }">
			<loan-card :title="$t('titles.penalty')">
				<template #title-append v-if="isPenaltyPositive">
					<frp-icon src="ico_info" :color="colors.secondary.lighten2"></frp-icon>
					<span class="secondary--text text--lighten-2 ml-1">{{ $t("content.immediateRepaymentIsRequired") }}</span>
				</template>
				
				<v-row class="ma-0 fill-height">
					<v-col class="pa-0">
						<loan-payments-overdues-penalty-multiple-sources v-if="financeSources.length >= 2" />
						<loan-payments-overdues-penalty v-else />
					</v-col>
				</v-row>
			</loan-card>
		</v-col>
	</v-row>
</template>

<script>

import FrpIcon from "Components/icon/FrpIcon";
import LoanCard from "Components/layouts/LoanCard";
import colorsMixin from "Mixins/colorsMixin";
import LoanPaymentsOverduesInterest from "Views/loan/upcomingPayments/overdues/sections/LoanPaymentsOverduesInterest.vue";
import LoanPaymentsOverduesMainDebt from "Views/loan/upcomingPayments/overdues/sections/LoanPaymentsOverduesMainDebt.vue";
import LoanPaymentsOverduesPenalty from "Views/loan/upcomingPayments/overdues/sections/LoanPaymentsOverduesPenalty.vue";
import LoanPaymentsOverduesPenaltyMultipleSources
	from "Views/loan/upcomingPayments/overdues/sections/LoanPaymentsOverduesPenaltyMultipleSources.vue";
import { createNamespacedHelpers } from "vuex";
import { namespace } from "Store/loan/modules/upcomingPayments/types";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin],
	computed: {
		...mapState({
			financeSources: state => state.financeSources,
			payments: state => state.payments
		}),
		isOverduesMainDebtVisible() {
			return this.payments.some(x => x.dayOverMainDebt || x.overMainDebt);
		},
		isOverduesInterestVisible() {
			return this.payments.some(x => x.dayOverPercent || x.overPercent);
		},
		isPenaltyVisible() {
			return this.payments.some(x => x.paymentPenaltyMainDebt || x.paymentPenaltyPercent || x.paymentPenaltyOther);
		},
		isPenaltyPositive() {
			return this.payments.some(x => x.paymentPenaltyMainDebt > 0 || x.paymentPenaltyPercent > 0 || x.paymentPenaltyOther > 0);
		}
	},
	components: {
		LoanCard,
		FrpIcon,
		LoanPaymentsOverduesInterest,
		LoanPaymentsOverduesMainDebt,
		LoanPaymentsOverduesPenalty,
		LoanPaymentsOverduesPenaltyMultipleSources
	}
};
</script>

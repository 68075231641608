import ApiTitleDocument from "@/api/loan/types/dictionaries/apiTitleDocument";
import ApiChangeReason from "@/api/loan/types/dictionaries/apiChangeReason";

export default class Version {
	id: string;
	number: number;
	title: string;
	createdAt: number;
	projectId: number;
	titleDocument: ApiTitleDocument;
	documentNumber: string;
	startDate: number;
	changeReason: ApiChangeReason | null;
	comment: string;
	isActive: boolean;

	constructor(
		id: string = "",
		number: number = 0,
		title: string = "",
		createdAt: number = 0,
		projectId: number = 0,
		titleDocument: ApiTitleDocument = new ApiTitleDocument(),
		documentNumber: string = "",
		startDate: number = 0,
		changeReason: ApiChangeReason | null = null,
		comment: string = "",
		isActive: boolean = false
	)
	{
		this.id = id;
		this.number = number;
		this.title = title;
		this.createdAt = createdAt;
		this.projectId = projectId;
		this.titleDocument = titleDocument;
		this.documentNumber = documentNumber;
		this.startDate = startDate;
		this.changeReason = changeReason;
		this.comment = comment;
		this.isActive = isActive;
	}
}

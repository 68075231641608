<template>
	<v-card elevation="0" class="loan-card px-1 py-6" v-if="isInitialized">
		<v-row>
			<v-col class="py-0">
				<v-card-title class="text-h6 pb-4">
					<template v-if="agreementMode === AgreementModeType.READ">
						<span style="white-space: nowrap" class="mr-1">
							{{ agreement.documentNumber }}
						</span>
						<span style="white-space: nowrap">
							{{ formatDate(agreement.signingDate, dateFormat) }}
						</span>
					</template>
					
					<span v-else>{{ $t("titles.documentEditing") }}</span>
				</v-card-title>
			</v-col>
			<v-col class="py-0">
				<v-card-actions class="pl-4">
					<div class="d-flex justify-md-end" style="width: 100%">
						<template v-if="agreementMode === AgreementModeType.READ">
							<frp-btn elevation="0"
									 color="#F44336"
									 dark
									 v-if="can(Permissions.LOAN_DELETE) && currentVersion.id && !currentVersion.isActive"
									 @click="openDeleteDraftDialog">
								{{ $t("buttons.deleteDraft") }}
							</frp-btn>
							
							<frp-btn color="primary"
									 v-if="can(Permissions.LOAN_CREATE) && (!currentVersion.id || currentVersion.isActive)"
									 outlined
									 @click="handleOpenDraftDialog">
								{{ $t("buttons.createDraft") }}
							</frp-btn>
							
							<template v-if="can(Permissions.LOAN_UPDATE) && (currentVersion.id && !currentVersion.isActive)">
								<frp-btn color="primary"
										 outlined
										 @click="handleOpenDraftDialog">
									{{ $t("buttons.saveAsDraft") }}
								</frp-btn>
								
								<frp-btn elevation="0"
										 color="blue"
										 dark
										 :disabled="!this.scheduleItems.length"
										 @click="versionDialog = true">
									{{ $t("buttons.saveAsVersion") }}
								</frp-btn>
							</template>
							
							<frp-btn v-if="can(Permissions.LOAN_UPDATE) && currentVersion.id"
									 outlined
									 @click="setAgreementMode(AgreementModeType.EDIT)"
									 color="blue"
									 dark>
								{{ $t("buttons.edit") }}
							</frp-btn>
						</template>
						
						<template v-else>
							<frp-btn text
									 plain
									 color="primary"
									 @click="setAgreementMode(AgreementModeType.READ)">
								{{ $t("buttons.cancel") }}
							</frp-btn>
							<frp-btn outlined
									 :loading="isFormSaving || isFormLoadingState"
									 :disabled="!agreementFormIsValid || !stateContainsUnsavedChanges"
									 @click="handleSaveChanges"
									 color="blue"
									 dark>
								{{ $t("buttons.save") }}
							</frp-btn>
						</template>
					</div>
				</v-card-actions>
			</v-col>
		</v-row>
		
		<v-row class="px-4 blue--text text--darken-4 mt-5 mt-md-2"
			   :style="$vuetify.breakpoint.mdAndUp ? '' : 'display-flex; flex-direction: column'"
			   v-if="versionHeaders.length">
			<template v-if="agreementMode === AgreementModeType.READ">
				<v-col class="d-flex flex-column pt-0">
					<span class="text-body-2 pb-1">
						{{ $t("content.documentVersion") }}
					</span>
					
					<loan-document-version-menu :current-version="currentVersion"
												color="blue"
												:version-headers="versionHeaders"
												@update:value="dispatchVersionSelectedEvent"/>
				</v-col>
				
				<v-col class="d-flex flex-column pt-0">
					<span class="text-body-2 pb-1">{{ $t("content.titleDocument") }}</span>
					<span class="text-body-1 font-weight-bold">{{ currentVersion.titleDocument.name || "--" }}</span>
				</v-col>
				<v-col class="d-flex flex-column pt-0">
					<span class="text-body-2 pb-1">{{ $t("content.reasonForScheduleChange") }}</span>
					<span class="text-body-1 font-weight-bold">{{ currentVersion.changeReason?.name || "--" }}</span>
				</v-col>
				<v-col class="d-flex flex-column pt-0">
					<span class="text-body-2 pb-1">{{ $t("content.comment") }}</span>
					<span class="text-body-1 font-weight-bold">{{ currentVersion.comment || $t("content.withoutComments") }}</span>
				</v-col>
				<v-col v-if="$vuetify.breakpoint.lgAndUp" class="d-flex flex-column"></v-col>
			</template>
			
			<v-form v-else v-model="agreementFormIsValid" :readonly="isFormSaving || isFormReadonly" class="d-flex">
				<v-col class="d-flex flex-column pt-0">
					<span class="text-body-2 pb-1">
						{{ $t("content.agreementNumber") }}
					</span>
					<frp-text-field dense
									required
									v-model="draftDocumentNumber"
									hide-details
									:placeholder="$t('fields.number.placeholder')">
					</frp-text-field>
				</v-col>
				
				<v-col class="d-flex flex-column pt-0">
					<span class="text-body-2 pb-1">{{ $t("fields.validFromDate.label") }}</span>
					<frp-date-field v-model="draftStartDate"
									required
									:placeholder="$t('fields.validFromDate.placeholder')">
					</frp-date-field>
				</v-col>
				
				<v-col class="d-flex flex-column pt-0">
					<span class="text-body-2 pb-1">{{ $t("content.titleDocument") }}</span>
					<frp-autocomplete v-model="draftTitleDocument"
									  return-object
									  :items="titleDocuments"
									  item-text="name"
									  color="blue"
									  required
									  :placeholder="$t('fields.titleDocument.placeholder')">
					</frp-autocomplete>
				</v-col>
				
				<v-col class="d-flex flex-column pt-0">
					<span class="text-body-2 pb-1">{{ $t("content.reasonForScheduleChange") }}</span>
					<frp-autocomplete v-model="draftChangeReason"
									  return-object
									  clearable
									  :items="changeReasons"
									  item-text="name"
									  color="blue"
									  :placeholder="$t('fields.changeReason.placeholder')">
					</frp-autocomplete>
				</v-col>
				
				<v-col class="d-flex flex-column pt-0">
					<span class="text-body-2 pb-1">{{ $t("content.comment") }}</span>
					<frp-text-field dense
									v-model="draftComment"
									hide-details
									:placeholder="$t('fields.comment.placeholder')">
					</frp-text-field>
				</v-col>
			</v-form>
		</v-row>
		
		<frp-dialog v-model="draftDialog"
					max-width="565"
					:title="$t('dialogs.saveDraft.title')"
					persistent>
			<template #content="{ onIntersect }">
				<v-form :ref="refs.form" v-model="formValid" :readonly="isFormSaving || isFormReadonly" v-intersect="onIntersect">
					<frp-autocomplete required
									  item-text="name"
									  color="blue"
									  return-object
									  v-model="draftTitleDocument"
									  :placeholder="$t('fields.titleDocument.placeholder')"
									  :items="titleDocuments"
									  :label="$t('fields.titleDocument.label')">
					</frp-autocomplete>
					<frp-text-field :label="$t('fields.number.label')"
									v-model="draftDocumentNumber"
									required
									:placeholder="$t('fields.number.placeholder')">
					</frp-text-field>
					<frp-date-field required
									v-model="draftStartDate"
									:label="$t('fields.validFromDate.placeholder')"
									:rules="validation.registrationDate"
									:placeholder="$t('fields.validFromDate.placeholder')">
					</frp-date-field>
					<frp-autocomplete :placeholder="$t('fields.changeReason.placeholder')"
									  item-text="name"
									  color="blue"
									  clearable
									  return-object
									  v-model="draftChangeReason"
									  :items="changeReasons"
									  :label="$t('fields.changeReason.label')">
					</frp-autocomplete>
					<frp-textarea :label="$t('fields.comment.label')"
								  v-model="draftComment"
								  :placeholder="$t('fields.comment.placeholder')">
					</frp-textarea>
				</v-form>
			</template>
			
			<template #footer>
				<frp-btn color="primary"
						 outlined
						 @click="draftDialog = false">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				<frp-btn elevation="0"
						 color="blue"
						 dark
						 :loading="isFormSaving || isFormLoadingState"
						 :disabled="!isFormValid"
						 @click="handleSaveDraft">
					{{ $t("buttons.saveAsDraft") }}
				</frp-btn>
			</template>
		</frp-dialog>
		
		<frp-dialog v-model="versionDialog"
					max-width="565"
					:title="$t('dialogs.saveVersion.title')">
			
			<template #content="{ onIntersect }">
				<span v-intersect="onIntersect" class="primary--text text-body-2">
					{{ $t("dialogs.saveVersion.text") }}
				</span>
			</template>
			
			<template #footer>
				<frp-btn color="primary"
						 outlined
						 @click="versionDialog = false">
					{{ $t("buttons.close") }}
				</frp-btn>
				<frp-btn elevation="0"
						 color="blue"
						 dark
						 :loading="isVersionActivating"
						 @click="handleSaveVersion">
					{{ $t("buttons.save") }}
				</frp-btn>
			</template>
		</frp-dialog>
		
		<frp-confirm-dialog v-if="isConfirmDeleteDraftOpened"
							submit-btn-color="blue"
							submit-btn-dark
							@submit="onDeleteDraftSubmit"
							@cancel="isConfirmDeleteDraftOpened = false"
							:title="$t('dialogs.confirmDeleteVersionDraft.title')"
							:description="$t('dialogs.confirmDeleteVersionDraft.description')">
		</frp-confirm-dialog>
	
	</v-card>
	<loan-agreement-loader v-else/>
</template>

<script>
import FrpConfirmDialog from "@/components/dialogs/FrpConfirmDialog.vue";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpDialog from "Components/dialogs/FrpDialog";
import FrpAutocomplete from "Components/fields/FrpAutocomplete";
import FrpDateField from "Components/fields/FrpDateField";
import FrpTextarea from "Components/fields/FrpTextarea";
import FrpTextField from "Components/fields/FrpTextField";
import LoanDocumentVersionMenu from "Components/fields/LoanDocumentVersionMenu";
import FrpIcon from "Components/icon/FrpIcon";
import authorizationMixin from "Mixins/authorizationMixin";
import formMixin from "Mixins/formMixin";
import { actionTypes, mutationTypes } from "Store/loan/modules/loanSchedule/modules/agreement/types";
import { AgreementModeType } from "Store/loan/modules/loanSchedule/modules/agreement/types/agreementModeType";
import { CreateVersionModeType } from "Store/loan/modules/loanSchedule/modules/agreement/types/createVersionModeType";
import { getterTypes as scheduleItemsGetterTypes } from "Store/loan/modules/loanSchedule/modules/scheduleItems/types";

import storeManager from "Store/manager";
import { formatDate } from "Utils/dates";
import { dateFormat } from "Utils/formats";
import LoanAgreementLoader from "Views/loan/loanSchedule/agreement/LoanAgreementLoader";
import { createNamespacedHelpers } from "vuex";

const namespace = storeManager.loan.loanSchedule.agreement.namespace;
const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);
const { mapState: loanScheduleMapState } = createNamespacedHelpers(storeManager.loan.loanSchedule.namespace);
const { mapGetters: scheduleItemsMapGetters } = createNamespacedHelpers(storeManager.loan.loanSchedule.scheduleItems.namespace);

export default {
	mixins: [formMixin, authorizationMixin],
	data() {
		return {
			namespace,
			formatDate,
			dateFormat,
			CreateVersionModeType,
			AgreementModeType,
			validation: {},
			draftDialog: false,
			versionDialog: false,
			versionMenu: false,
			agreementFormIsValid: false,
			isConfirmDeleteDraftOpened: false
		};
	},
	computed: {
		...loanScheduleMapState({
			currentVersion: state => state.version,
			versionHeaders: state => state.versionHeaders,
			titleDocuments: state => state.titleDocuments,
			changeReasons: state => state.changeReasons
		}),
		...scheduleItemsMapGetters({
			scheduleItems: scheduleItemsGetterTypes.formattedItems
		}),
		...mapState({
			isInitialized: state => state.isInitialized,
			isVersionActivating: state => state.isVersionActivating,
			mode: state => state.mode,
			agreementMode: state => state.agreementMode,
			draft: state => state.draft,
			agreement: state => state.agreement
		}),
		draftTitleDocument: {
			get() {
				return this.draft.titleDocument;
			},
			set(value) {
				this.setDraftTitleDocument(value);
			}
		},
		draftChangeReason: {
			get() {
				return this.draft.changeReason;
			},
			set(value) {
				this.setDraftChangeReason(value);
			}
		},
		draftComment: {
			get() {
				return this.draft.comment;
			},
			set(value) {
				this.setDraftComment(value);
			}
		},
		draftDocumentNumber: {
			get() {
				return this.draft.documentNumber;
			},
			set(value) {
				this.setDraftDocumentNumber(value);
			}
		},
		draftStartDate: {
			get() {
				return this.draft.startDate;
			},
			set(value) {
				this.setDraftStartDate(value);
			}
		}
	},
	methods: {
		...mapMutations({
			setDraftTitleDocument: mutationTypes.SET_DRAFT_TITLE_DOCUMENT,
			setDraftDocumentNumber: mutationTypes.SET_DRAFT_DOCUMENT_NUMBER,
			setDraftChangeReason: mutationTypes.SET_DRAFT_CHANGE_REASON,
			setDraftComment: mutationTypes.SET_DRAFT_COMMENT,
			setDraftStartDate: mutationTypes.SET_DRAFT_START_DATE,
			setMode: mutationTypes.SET_MODE,
			setAgreementMode: mutationTypes.SET_AGREEMENT_MODE,
			dispatchVersionSelectedEvent: mutationTypes.VERSION_SELECTED_EVENT
		}),
		...mapActions({
			initializeStore: actionTypes.initialize,
			saveVersion: actionTypes.saveVersion,
			saveDraft: actionTypes.saveDraft,
			resetDraft: actionTypes.resetDraft,
			saveChanges: actionTypes.saveChanges,
			deleteDraft: actionTypes.deleteDraft
		}),
		async handleSaveVersion() {
			await this.saveVersion();
			this.versionDialog = false;
		},
		async handleSaveDraft() {
			await this.saveDraft();
			this.draftDialog = false;
			this.setAgreementMode(AgreementModeType.READ);
		},
		async handleOpenDraftDialog() {
			await this.resetDraft();
			this.draftDialog = true;
		},
		async handleSaveChanges() {
			await this.saveChanges();
			this.setAgreementMode(AgreementModeType.READ);
		},
		async openDeleteDraftDialog() {
			this.isConfirmDeleteDraftOpened = true;
		},
		async onDeleteDraftSubmit() {
			await this.deleteDraft();
			this.isConfirmDeleteDraftOpened = false;
		}
	},
	async created() {
		await this.initializeStore();
	},
	components: {
		LoanDocumentVersionMenu,
		FrpBtn,
		LoanAgreementLoader,
		FrpIcon,
		FrpTextarea,
		FrpDateField,
		FrpAutocomplete,
		FrpTextField,
		FrpDialog,
		FrpConfirmDialog
	}
};
</script>

<style scoped>
.v-icon:focus::after {
	opacity: 0 !important;
}
</style>

import { mutationTypes, actionTypes, getterTypes, namespace } from "@/store/loan/modules/loanSchedule/modules/scheduleItems/types";
import loanScheduleTypes from "@/store/loan/modules/loanSchedule/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import ScheduleItemsState from "@/store/loan/modules/loanSchedule/modules/scheduleItems/types/scheduleItemsState";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import AbortService from "@/services/abortService";
import { LoanScheduleController } from "@/api/loan/loanSchedule";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import LoanScheduleState from "@/store/loan/modules/loanSchedule/types/loanScheduleState";
import storeManager from "@/store/manager";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import FormMixinBuilder from "@/store/shared/form";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import ApiScheduleItem from "@/api/loan/types/loanSchedule/apiScheduleItem";
import Payment from "@/store/loan/modules/loanSchedule/modules/payments/types/payment";
import ListingMixinBuilder from "@/store/shared/listing";
import PagingMixinBuilder from "@/store/shared/paging";
import SortingMixinBuilder from "@/store/shared/sorting";
import SearchMixinBuilder from "@/store/shared/search";
import mapper from "@/store/loan/modules/loanSchedule/modules/scheduleItems/mapper";
import ScheduleItem from "@/store/loan/modules/loanSchedule/modules/scheduleItems/types/scheduleItem";
import { ScheduleModeType } from "@/store/loan/modules/loanSchedule/modules/scheduleItems/types/scheduleModeType";
import { cloneDeep } from "lodash";
import AgreementState from "@/store/loan/modules/loanSchedule/modules/agreement/types/agreementState";
import { plainToInstance } from "class-transformer";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import { sumFloat } from "@/utils/number";
import PaymentsState from "@/store/loan/modules/loanSchedule/modules/payments/types/paymentsState";
import { TitleDocuments } from "@/store/loan/modules/loanSchedule/types/titleDocuments";

const abortService = new AbortService();
const loanScheduleController = new LoanScheduleController(abortService);

const baseMixin = (new BaseMixinBuilder(abortService)).build();
const formMixin = (new FormMixinBuilder()).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["newItem"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new ScheduleItemsState(
			new ListingModel<ScheduleItem>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String>({
				type: "paymentDate",
				order: sortingOrderType.ascending
			}),
			new PagingModel({
				total: 0,
				page: 1,
				pageSize: 25
			}),
			new SearchModel({
				query: ""
			}),
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<ScheduleItemsState, any>>{
	...listingMixin.getters,
	...formMixin.getters,
	...snapshotMixin.getters,
	[getterTypes.formattedItems]: state => {
		return state.listing.items.map(x => {
			return plainToInstance(ScheduleItem, {
				...x
			});
		});
	},
	[getterTypes.version]: (state, getters, rootState) => {
		const { version } = resolveNestedState<LoanScheduleState>(rootState, storeManager.loan.loanSchedule.namespace);
		return version;
	}
};

const actions = <ActionTree<ScheduleItemsState, any>>{
	...listingMixin.actions,
	...pagingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }) {
		await dispatch(actionTypes.initializeBase);

		await dispatch(actionTypes.fetch);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
		commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
	},
	async [actionTypes.fetch]({ commit, getters }) {
		if(!getters.version.id)
			return;

		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);

		try {
			let items = await loanScheduleController.getScheduleItems(getters.version.id);

			commit(mutationTypes.SET_LISTING_ITEMS, items.map(x => mapper.map(x, ApiScheduleItem, ScheduleItem)));
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.updateItems]({ commit, state, getters, rootState, rootGetters }) {
		commit(mutationTypes.SET_IS_ITEMS_UPDATING, true);

		try {
			const { agreement: { exactFrpSum } } = resolveNestedState<AgreementState>(rootState,
				storeManager.loan.loanSchedule.agreement.namespace);
			const { listing: { items : paymentItems } } = resolveNestedState<PaymentsState>(rootState,
				storeManager.loan.loanSchedule.payments.namespace);

			const amount = state.editableItems.map((x: ScheduleItem) => x.amount)
				.reduce((acc: number, x: number) => sumFloat(acc, +x), 0);
			const assignmentAmount = paymentItems.map((x: Payment) => x.assignedLoan)
				.reduce((acc: number, x: number) => sumFloat(acc, +x), 0);
			const isAssignmentVersion = getters.version.titleDocument.number === TitleDocuments.CessionAgreement;

			if (!isAssignmentVersion && exactFrpSum !== amount) {
				commit(mutationTypes.WRONG_SCHEDULE_AMOUNT_EVENT);
				return;
			}
			if (isAssignmentVersion && assignmentAmount !== amount) {
				commit(mutationTypes.WRONG_ASSIGNMENT_SCHEDULE_AMOUNT_EVENT);
				return;
			}

			await loanScheduleController.updateScheduleItems(getters.version.id,
				state.editableItems.map(x => mapper.map(x, ScheduleItem, ApiScheduleItem)));

			commit(mutationTypes.SET_LISTING_ITEMS, cloneDeep(state.editableItems));
			commit(mutationTypes.SET_MODE, ScheduleModeType.READ);
			alertService.addInfo(AlertKeys.SCHEDULE_ITEMS_SUCCESS_SAVED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_ITEMS_UPDATING, false);
		}
	},
	async [actionTypes.createItem]({ commit, state, getters }) {
		commit(mutationTypes.SET_IS_ITEM_CREATING, true);

		try {
			const item = await loanScheduleController.createScheduleItem(getters.version.id,
				mapper.map(state.newItem, ScheduleItem, ApiScheduleItem));

			alertService.addInfo(AlertKeys.SCHEDULE_ITEM_SUCCESS_CREATED);
			commit(mutationTypes.SET_LISTING_ITEMS, [...state.listing.items, mapper.map(item, ApiScheduleItem, ScheduleItem)]);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_ITEM_CREATING, false);
		}
	},
	async [actionTypes.deleteItem]({ dispatch, commit, rootState, state, getters }, { item }) {
		commit(mutationTypes.SET_IS_ITEM_DELETING, true);

		try {
			await loanScheduleController.deleteScheduleItem(getters.version.id, item.id);

			alertService.addInfo(AlertKeys.SCHEDULE_ITEM_SUCCESS_DELETED);
			commit(mutationTypes.SET_LISTING_ITEMS, state.listing.items.filter(x => x.id !== item.id));
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_ITEM_DELETING, false);
		}
	}
};

const mutations = <MutationTree<ScheduleItemsState>>{
	...listingMixin.mutations,
	...pagingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	[mutationTypes.RESET_NEW_ITEM](state) {
		state.newItem = new ScheduleItem();
	},
	[mutationTypes.SET_EDITABLE_ITEMS](state, value) {
		state.editableItems = value;
	},
	[mutationTypes.SET_IS_ITEMS_UPDATING](state, value) {
		state.isItemsUpdating = value;
	},
	[mutationTypes.SET_IS_ITEM_CREATING](state, value) {
		state.isItemCreating = value;
	},
	[mutationTypes.SET_IS_ITEM_DELETING](state, value) {
		state.isItemDeleting = value;
	},
	[mutationTypes.SET_NEW_ITEM_SOURCE](state, value) {
		state.newItem.source = value;
	},
	[mutationTypes.SET_NEW_ITEM_PAYMENT_DATE](state, value) {
		state.newItem.paymentDate = value;
	},
	[mutationTypes.SET_NEW_ITEM_AMOUNT](state, value) {
		state.newItem.amount = value;
	},
	[mutationTypes.SET_MODE](state, value) {
		state.mode = value;
	},
	[mutationTypes.WRONG_SCHEDULE_AMOUNT_EVENT]() {
	},
	[mutationTypes.WRONG_ASSIGNMENT_SCHEDULE_AMOUNT_EVENT]() {
	}
};

const subscribe = (store: any) => {
	const { commit, dispatch } = store;

	store.subscribe(async ({ type, payload }: any, state: any) => {
		switch (type) {
			case resolveMutation(storeManager.loan.loanSchedule.scheduleItems.namespace, mutationTypes.SET_MODE):
			{
				const { listing: { items } } = resolveNestedState<ScheduleItemsState>(state,
					storeManager.loan.loanSchedule.scheduleItems.namespace);

				if(payload === ScheduleModeType.EDIT)
					commit(resolveMutation(storeManager.loan.loanSchedule.scheduleItems.namespace, mutationTypes.SET_EDITABLE_ITEMS),
						cloneDeep(items));

				break;
			}

			case resolveMutation(storeManager.loan.loanSchedule.namespace, loanScheduleTypes.mutationTypes.SET_VERSION):
			{
				const { isInitialized } = resolveNestedState<ScheduleItemsState>(state,
					storeManager.loan.loanSchedule.scheduleItems.namespace);

				if(isInitialized) {
					await dispatch(resolveAction(storeManager.loan.loanSchedule.scheduleItems.namespace, actionTypes.fetch));
					commit(resolveMutation(storeManager.loan.loanSchedule.scheduleItems.namespace, mutationTypes.SET_MODE),
						ScheduleModeType.READ);
				}

				break;
			}

			default:
				break;
		}
	});
};

export {
	namespace, state, getters, actions, mutations, subscribe
};

const scheduleItemsModule = {
	namespace, state, getters, actions, mutations, subscribe, namespaced: true
};

export default scheduleItemsModule;

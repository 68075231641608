import ApiDocumentDescription from "@/api/loan/types/reports/apiDocumentDescription";
import ApiUser from "@/api/loan/types/reports/apiUser";
import ReportFinalDocumentFile from "@/store/loan/modules/reportsFinal/types/reportFinalDocumentFile";

export default class ReportFinalDocument {
	constructor(
		public description: ApiDocumentDescription = new ApiDocumentDescription(),
		public canUploadFile: boolean = false,
		public canUploadGeneratedFile: boolean = false,
		public canGenerate: boolean = false,
		public uploaderUser: ApiUser = new ApiUser(),
		public uploadDateTime: number = 0,
		public signerUser: ApiUser = new ApiUser(),
		public documentFiles: ReportFinalDocumentFile[] = [],
		public isSignEnabled: boolean = false,
		public isSignRequired: boolean = false
	)
	{
		
	}
}

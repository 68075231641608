export default class AgreementsAccruedInterestReport {
	reportNumber: string;
	comment: string;
	signatureId: string;

	constructor(
		reportNumber: string = "",
		comment: string = "",
		signatureId: string = ""
	)
	{
		this.reportNumber = reportNumber;
		this.comment = comment;
		this.signatureId = signatureId;
	}
}

export const getHeadersContentDispositionFilename = (headers: any) => {
	const { "content-disposition": contentDisposition } = headers;
	if(!contentDisposition) return "";
	
	const regex = /filename(?:\*=[^']*'[^']*'\s)?=([^;]+)/;
	const found = contentDisposition.match(regex);
	const splittedResult = found[0].split("=");
	
	return { [splittedResult[0]]: splittedResult[1].replace(/"/g, "") };
};

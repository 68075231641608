import { actionTypes, getterTypes, mutationTypes, namespace } from "@/store/loan/modules/agreementsAccruedPenalties/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationPayload, MutationTree, Store } from "vuex";
import AbortService from "@/services/abortService";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import ListingMixinBuilder from "@/store/shared/listing";
import PagingMixinBuilder from "@/store/shared/paging";
import SortingMixinBuilder from "@/store/shared/sorting";
import SearchMixinBuilder from "@/store/shared/search";
import { LoanInterestController } from "@/api/loan/loanInterest";
import { DictionariesController } from "@/api/loan/dictionaries";
import BatchService from "@/services/batchService";
import RouteMixinBuilder from "@/store/shared/route";
import SubscribersManager from "@/store/manager/subscribersManager";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import routeTypes from "@/store/shared/route/types";
import router from "@/router/loan";
import { RouteNames } from "@/router/loan/routes";
import { cloneDeep } from "lodash";
import AgreementsAccruedPenaltiesRouteQueryService
	from "@/store/loan/modules/agreementsAccruedPenalties/services/agreementsAccruedPenaltiesRouteQueryService";
import AgreementsAccruedPenaltiesRouteQuery
	from "@/store/loan/modules/agreementsAccruedPenalties/types/agreementsAccruedPenaltiesRouteQuery";
import AgreementsAccruedPenaltiesState from "@/store/loan/modules/agreementsAccruedPenalties/types/agreementsAccruedPenaltiesState";
import { AgreementsAccruedPenaltiesItem } from "@/store/loan/modules/agreementsAccruedPenalties/types/agreementsAccruedPenaltiesItem";
import { AgreementsAccruedPenaltiesModeType } from "@/store/loan/modules/agreementsAccruedPenalties/types/agreementsAccruedPenaltiesModeType";
import AgreementsAccruedPenaltiesMapperProfile from "@/store/loan/modules/agreementsAccruedPenalties/mapper";

const abortService = new AbortService();
const loanInterestController = new LoanInterestController(abortService);
const dictionariesController = new DictionariesController(abortService);

const mapper = new AgreementsAccruedPenaltiesMapperProfile();

const defaultRouteQuery = new AgreementsAccruedPenaltiesRouteQuery(1, "", "", "", []);

const routeQueryService = new AgreementsAccruedPenaltiesRouteQueryService(defaultRouteQuery);

const updateListingBatchService = new BatchService(({ interval: 100 }));

const routeMixin = (new RouteMixinBuilder<AgreementsAccruedPenaltiesState>()).build();

const baseMixin = (new BaseMixinBuilder(abortService)).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new AgreementsAccruedPenaltiesState(
			new ListingModel<AgreementsAccruedPenaltiesItem>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String[]>({
				type: ["paymentDate", "organizationName"],
				order: [sortingOrderType.ascending, sortingOrderType.ascending]
			}),
			new PagingModel({
				total: 0,
				page: defaultRouteQuery.page,
				pageSize: 25
			}),
			new SearchModel({
				query: ""
			}),
			routeMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

let subscribersManager: SubscribersManager<AgreementsAccruedPenaltiesState>;

let unsubscribeCallback = () => {
};
let store: Store<{}>;

const initializeSubscribersManager = (value: Store<{}>) => {
	store = value;
	subscribersManager = new SubscribersManager<AgreementsAccruedPenaltiesState>(store);
};

const subscribe = async (mutation: MutationPayload, rootState: any) => {
	let state = resolveNestedState<AgreementsAccruedPenaltiesState>(rootState, namespace);
	switch (mutation.type) {
		case resolveMutation(routeTypes.namespace, routeTypes.mutationTypes.ROUTE_CHANGED):
			if((mutation.payload.from.name === mutation.payload.to.name) && !state.route.isPushing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.processRouteQuery));
			break;
		case resolveMutation(namespace, mutationTypes.SET_FILTER_PROJECT_NUMBER):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_ORGANIZATION):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_CONTROLLER):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_STATUSES):
		{
			if(!state.route.isProcessing) {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.resetPagingPage));
			}

			updateListingBatchService.push(async () => {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.updateListingItems));
			});

			break;
		}
		case resolveMutation(namespace, mutationTypes.SET_PAGING_PAGE):
		{
			if(!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));

			break;
		}
		case resolveMutation(namespace, mutationTypes.SET_MODE):
		{
			const items = mutation.payload === AgreementsAccruedPenaltiesModeType.MAIN_DEBT ? state.mainDebtItems : state.interestItems;
			await subscribersManager.commit(resolveMutation(namespace, mutationTypes.SET_SELECTED_ITEMS), items);
			break;
		}
	}
};

const getters = <GetterTree<AgreementsAccruedPenaltiesState, any>>{
	...listingMixin.getters,
	[getterTypes.currentItems]: state => {
		return state.mode === AgreementsAccruedPenaltiesModeType.MAIN_DEBT ? state.mainDebtItems : state.interestItems;
	}
};

const actions = <ActionTree<AgreementsAccruedPenaltiesState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...listingMixin.actions,
	...pagingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	...routeMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, state, getters }) {
		await dispatch(actionTypes.initializeBase);

		await dispatch(actionTypes.processRouteQuery);
		await dispatch(actionTypes.reconstituteRoute);

		await Promise.all([
			dispatch(actionTypes.updateListingItems)
			// dispatch(actionTypes.fetchDictionaries)
		]);

		if(!state.mainDebtItems.length && state.interestItems.length)
			commit(mutationTypes.SET_MODE, AgreementsAccruedPenaltiesModeType.INTEREST);

		commit(mutationTypes.SET_SELECTED_ITEMS, getters.currentItems);

		unsubscribeCallback = subscribersManager.subscribe(subscribe);
		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.updateListingItems]({ dispatch, commit, state }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);
		commit(mutationTypes.SET_MAIN_DEBT_ITEMS, []);
		commit(mutationTypes.SET_INTEREST_ITEMS, []);

		try {
			const [mainDebtItems, interestItems] = await Promise.all([
				loanInterestController.getAgreementsPenaltyMainDebt(),
				loanInterestController.getAgreementsPenaltyInterest()
			]);

			commit(mutationTypes.SET_MAIN_DEBT_ITEMS, mainDebtItems.map(x => mapper.mapToAgreementsAccruedPenaltiesItem(x)).map((x, i) => ({
				...x,
				id: i + ""
			})));
			commit(mutationTypes.SET_INTEREST_ITEMS, interestItems.map(x => mapper.mapToAgreementsAccruedPenaltiesItem(x)).map((x, i) => ({
				...x,
				id: i + ""
			})));
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			console.error(error);
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.fetchDictionaries]({ commit }) {
		commit(mutationTypes.SET_IS_DICTIONARIES_LOADING, true);

		try {
			const [financeSources, quarters, agreementStatuses] = await Promise.all([
				// TODO: нужен метод для всех субсидий
				dictionariesController.getProjectFinanceSources(260),
				dictionariesController.getQuarters(),
				dictionariesController.getAgreementStatuses()
			]);

			commit(mutationTypes.SET_FINANCE_SOURCES, financeSources);
			commit(mutationTypes.SET_QUARTERS, quarters);
			commit(mutationTypes.SET_AGREEMENT_STATUSES, agreementStatuses);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_DICTIONARIES_LOADING, false);
		}
	},
	async [actionTypes.processRouteQuery]({ rootState, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, true);

		let routeQuery = await routeQueryService.resolveRouteQuery(rootState.route.query);

		commit(mutationTypes.SET_PAGING_PAGE, routeQuery.page);
		commit(mutationTypes.SET_FILTER_PROJECT_NUMBER, routeQuery.projectNumber);
		commit(mutationTypes.SET_FILTER_ORGANIZATION, routeQuery.organization);
		commit(mutationTypes.SET_FILTER_CONTROLLER, routeQuery.controller);
		commit(mutationTypes.SET_FILTER_STATUSES, routeQuery.statuses);

		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, false);
	},
	async [actionTypes.pushRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);

		await router.push({
			name: RouteNames.AGREEMENTS_ACCRUED_PENALTIES,
			query: routeQueryService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});

		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.reconstituteRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);

		await router.replace({
			name: RouteNames.AGREEMENTS_ACCRUED_PENALTIES,
			query: routeQueryService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});

		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.destroy]({ dispatch }) {
		unsubscribeCallback();
		await dispatch(actionTypes.destroyBase);
	}
};

const mutations = <MutationTree<AgreementsAccruedPenaltiesState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...listingMixin.mutations,
	...pagingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	...routeMixin.mutations,
	[mutationTypes.SET_IS_DICTIONARIES_LOADING](state, value) {
		state.isDictionariesLoading = value;
	},
	[mutationTypes.SET_QUARTERS](state, value) {
		state.quarters = value;
	},
	[mutationTypes.SET_FINANCE_SOURCES](state, value) {
		state.financeSources = value;
	},
	[mutationTypes.SET_AGREEMENT_STATUSES](state, value) {
		state.agreementStatuses = value;
	},
	[mutationTypes.SET_MODE](state, value) {
		state.mode = value;
	},
	[mutationTypes.SET_FILTER_PROJECT_NUMBER](state, value) {
		state.filter.projectNumber = value;
	},
	[mutationTypes.SET_FILTER_ORGANIZATION](state, value) {
		state.filter.organization = value;
	},
	[mutationTypes.SET_FILTER_CONTROLLER](state, value) {
		state.filter.controller = value;
	},
	[mutationTypes.SET_FILTER_STATUSES](state, value) {
		state.filter.statuses = value;
	},
	[mutationTypes.SET_SELECTED_ITEMS](state, value) {
		state.selectedItems = cloneDeep(value);
	},
	[mutationTypes.ADD_SELECTED_ITEM](state, value) {
		state.selectedItems.push(value);
	},
	[mutationTypes.REMOVE_SELECTED_ITEM](state, value) {
		state.selectedItems.splice(state.selectedItems.findIndex(x => x.id === value.id), 1);
	},
	[mutationTypes.SET_IS_UPDATE_SELECTED_ITEMS_REQUIRED](state, value) {
		state.isUpdateSelectedItemsRequired = value;
	},
	[mutationTypes.SET_MAIN_DEBT_ITEMS](state, value) {
		state.mainDebtItems = value;
	},
	[mutationTypes.SET_INTEREST_ITEMS](state, value) {
		state.interestItems = value;
	}
};

export {
	namespace, state, getters, actions, mutations, initializeSubscribersManager
};

const agreementsAccruedPenaltiesModule = {
	namespace, state, getters, actions, mutations, initializeSubscribersManager, namespaced: true
};

export default agreementsAccruedPenaltiesModule;

import { addProfile, createMap, createMapper, forMember, mapFrom, MappingProfile } from "@automapper/core";
import { classes } from "@automapper/classes";
import { convertToTimestamp, convertToZonedIso, parseDate } from "@/utils/dates";
import ApiAccruedInterest from "@/api/loan/types/loanInterest/apiAccruedInterest";
import AccruedInterest from "@/store/loan/modules/accruedInterest/types/accruedInterest";
import AccruedInterestState from "@/store/loan/modules/accruedInterest/types/accruedInterestState";
import ApiGetAccruedInterestParameters from "@/api/loan/types/loanInterest/apiGetAccruedInterestParameters";
import ApiExtraRateParameters from "@/api/loan/types/loanInterest/apiExtraRateParameters";
import ExtraRateParameters from "@/store/loan/modules/accruedInterest/types/extraRateParameters";
import MassUpdateAccruedInterest from "@/store/loan/modules/accruedInterest/types/massUpdateAccruedInterest";
import ApiMassUpdateAccruedInterest from "@/api/loan/types/loanInterest/apiMassUpdateAccruedInterest";
import ApiAccruedInterestAddItemsParameters from "@/api/loan/types/loanInterest/apiAccruedInterestAddItemsParameters";
import AccruedInterestAddItemsParameters from "@/store/loan/modules/accruedInterest/types/accruedInterestAddItemsParameters";

const mapper = createMapper({
	strategyInitializer: classes()
});

const accruedInterestProfile: MappingProfile = (mapper) => {
	createMap(mapper, ApiAccruedInterest, AccruedInterest,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.isActive, mapFrom(x => x.isActive)),
		forMember(d => d.projectId, mapFrom(x => x.projectId)),
		forMember(d => d.number, mapFrom(x => x.number)),
		forMember(d => d.financingSourceId, mapFrom(x => x.financingSourceId)),
		forMember(d => d.quarterId, mapFrom(x => x.quarterId)),
		forMember(d => d.accrualTypeId, mapFrom(x => x.accrualTypeId)),
		forMember(d => d.accrualDate, mapFrom(x => convertToTimestamp(x.accrualDate))),
		forMember(d => d.paymentDate, mapFrom(x => convertToTimestamp(x.paymentDate))),
		forMember(d => d.accountedDate, mapFrom(x => convertToTimestamp(x.accountedDate))),
		forMember(d => d.accruedAmount, mapFrom(x => x.accruedAmount)),
		forMember(d => d.comment, mapFrom(x => x.comment))
	);

	createMap(mapper, AccruedInterest, ApiAccruedInterest,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.isActive, mapFrom(x => x.isActive)),
		forMember(d => d.projectId, mapFrom(x => x.projectId)),
		forMember(d => d.number, mapFrom(x => x.number)),
		forMember(d => d.financingSourceId, mapFrom(x => x.financingSourceId)),
		forMember(d => d.quarterId, mapFrom(x => x.quarterId)),
		forMember(d => d.accrualTypeId, mapFrom(x => x.accrualTypeId)),
		forMember(d => d.accrualDate, mapFrom(x => convertToZonedIso(x.accrualDate))),
		forMember(d => d.paymentDate, mapFrom(x => convertToZonedIso(x.paymentDate))),
		forMember(d => d.accountedDate, mapFrom(x => convertToZonedIso(x.accountedDate))),
		forMember(d => d.accruedAmount, mapFrom(x => x.accruedAmount)),
		forMember(d => d.comment, mapFrom(x => x.comment))
	);

	createMap(mapper, AccruedInterestAddItemsParameters, ApiAccruedInterestAddItemsParameters,
		forMember(d => d.projectId, mapFrom(x => x.projectId)),
		forMember(d => d.accrualTypeId, mapFrom(x => x.accrualTypeId)),
		forMember(d => d.financingSourceId, mapFrom(x => x.financingSourceId)),
		forMember(d => d.quarterId, mapFrom(x => x.quarterId)),
		forMember(d => d.accrualStartDate, mapFrom(x => convertToZonedIso(x.accrualStartDate))),
		forMember(d => d.accrualEndDate, mapFrom(x => convertToZonedIso(x.accrualEndDate))),
		forMember(d => d.paymentDate, mapFrom(x => convertToZonedIso(x.paymentDate))),
		forMember(d => d.accruedAmount, mapFrom(x => x.accruedAmount))
	);

	createMap(mapper, AccruedInterestState, ApiGetAccruedInterestParameters,
		forMember(d => d.take, mapFrom(x => x.paging.pageSize)),
		forMember(d => d.skip, mapFrom(x => x.paging.pageSize * (x.paging.page - 1))),
		forMember(d => d.accrualStartDate, mapFrom(x => convertToZonedIso(parseDate(x.filter.accrualStartDate)))),
		forMember(d => d.accrualEndDate, mapFrom(x => convertToZonedIso(parseDate(x.filter.accrualEndDate)))),
		forMember(d => d.accrualTypeId, mapFrom(x => x.filter.accrualType)),
		forMember(d => d.paymentStartDate, mapFrom(x => convertToZonedIso(parseDate(x.filter.paymentStartDate)))),
		forMember(d => d.paymentEndDate, mapFrom(x => convertToZonedIso(parseDate(x.filter.paymentEndDate)))),
		forMember(d => d.accountedStartDate, mapFrom(x => convertToZonedIso(parseDate(x.filter.accountedStartDate)))),
		forMember(d => d.accountedEndDate, mapFrom(x => convertToZonedIso(parseDate(x.filter.accountedEndDate)))),
		forMember(d => d.financingSourceId, mapFrom(x => +x.filter.financingSourceId || "")),
		forMember(d => d.quarterId, mapFrom(x => x.filter.quarterId))
	);

	createMap(mapper, ExtraRateParameters, ApiExtraRateParameters,
		forMember(d => d.projectId, mapFrom(x => x.projectId)),
		forMember(d => d.accrualStartDate, mapFrom(x => convertToZonedIso(x.accrualStartDate))),
		forMember(d => d.accrualEndDate, mapFrom(x => convertToZonedIso(x.accrualEndDate))),
		forMember(d => d.extraInterestRate, mapFrom(x => x.extraInterestRate)),
		forMember(d => d.paymentDate, mapFrom(x => convertToZonedIso(x.paymentDate)))
	);

	createMap(mapper, MassUpdateAccruedInterest, ApiMassUpdateAccruedInterest,
		forMember(d => d.projectId, mapFrom(x => x.projectId)),
		forMember(d => d.accrualTypeId, mapFrom(x => x.accrualTypeId)),
		forMember(d => d.accrualTypeFilterEnabled, mapFrom(x => x.accrualTypeFilterEnabled)),
		forMember(d => d.financeSourceId, mapFrom(x => x.financeSourceId)),
		forMember(d => d.sourceFilterEnabled, mapFrom(x => x.sourceFilterEnabled)),
		forMember(d => d.quarterId, mapFrom(x => x.quarterId)),
		forMember(d => d.quarterFilterEnabled, mapFrom(x => x.quarterFilterEnabled)),
		forMember(d => d.accrualStartDate, mapFrom(x => convertToZonedIso(x.accrualStartDate))),
		forMember(d => d.accrualEndDate, mapFrom(x => convertToZonedIso(x.accrualEndDate))),
		forMember(d => d.paymentDate, mapFrom(x => convertToZonedIso(x.paymentDate))),
		forMember(d => d.paymentDateSettingEnabled, mapFrom(x => x.paymentDateSettingEnabled)),
		forMember(d => d.accruedAmount, mapFrom(x => x.accruedAmount)),
		forMember(d => d.accruedAmountSettingEnabled, mapFrom(x => x.accruedAmountSettingEnabled))
	);
};

addProfile(mapper, accruedInterestProfile);

export default mapper;

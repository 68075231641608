import { mutationTypes, actionTypes, getterTypes, namespace } from "@/store/loan/modules/loanSchedule/types";
import agreementTypes from "@/store/loan/modules/loanSchedule/modules/agreement/types";
import tranchesTypes from "@/store/loan/modules/loanSchedule/modules/tranches/types";
import scheduleItemsTypes from "@/store/loan/modules/loanSchedule/modules/scheduleItems/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import LoanScheduleState from "@/store/loan/modules/loanSchedule/types/loanScheduleState";
import AbortService from "@/services/abortService";
import agreementModule from "@/store/loan/modules/loanSchedule/modules/agreement";
import overduesModule from "@/store/loan/modules/loanSchedule/modules/overdues";
import paymentsModule from "@/store/loan/modules/loanSchedule/modules/payments";
import scheduleItemsModule from "@/store/loan/modules/loanSchedule/modules/scheduleItems";
import tranchesModule from "@/store/loan/modules/loanSchedule/modules/tranches";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import { LoanScheduleController } from "@/api/loan/loanSchedule";
import { resolveAction, resolveMutation } from "@/utils/vuexModules";
import storeManager from "@/store/manager";
import mapper from "@/store/loan/modules/loanSchedule/mapper";
import Version from "@/store/loan/modules/loanSchedule/types/version";
import { WrongAmountDialogType } from "@/store/loan/modules/loanSchedule/types/wrongAmountDialogType";
import ApiVersion from "@/api/loan/types/loanSchedule/apiVersion";
import { DictionariesController } from "@/api/loan/dictionaries";
import router from "@/router/loan";
import ApiQuarter from "@/api/loan/types/dictionaries/apiQuarter";
import Quarter from "@/types/loan/quarter";
import { limitQuartersByMaxYear } from "@/utils/dates";

const abortService = new AbortService();
const loanScheduleController = new LoanScheduleController(abortService);
const dictionariesController = new DictionariesController(abortService);

const baseMixin = (new BaseMixinBuilder(abortService)).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new LoanScheduleState();
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<LoanScheduleState, any>>{};

const actions = <ActionTree<LoanScheduleState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }) {
		await dispatch(actionTypes.initializeBase);

		await dispatch(actionTypes.fetchVersionHeaders);

		await Promise.all([
			dispatch(actionTypes.fetchVersion),
			dispatch(actionTypes.fetchDictionaries)
		]);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.fetchVersionHeaders]({ commit, state }) {
		commit(mutationTypes.SET_IS_LOADING, true);

		try {
			const versionHeaders = await loanScheduleController.getVersionHeaders(router.currentRoute.params.projectId);

			commit(mutationTypes.SET_VERSION_HEADERS, versionHeaders);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_LOADING, false);
		}
	},
	async [actionTypes.fetchVersion]({ commit, state }, versionId) {
		commit(mutationTypes.SET_IS_LOADING, true);

		try {
			if (versionId) {
				const version = await loanScheduleController.getVersion(versionId);
				commit(mutationTypes.SET_VERSION, mapper.map(version, ApiVersion, Version));
			} else if (state.versionHeaders.length) {
				const [lastVersion] = state.versionHeaders;
				const version = await loanScheduleController.getVersion(lastVersion.id);
				commit(mutationTypes.SET_VERSION, mapper.map(version, ApiVersion, Version));
			} else {
				const version = new Version();
				commit(mutationTypes.SET_VERSION, version);
			}
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_LOADING, false);
		}
	},
	async [actionTypes.fetchDictionaries]({ commit }) {
		commit(mutationTypes.SET_IS_LOADING, true);

		try {
			const [quarters, financeSources, titleDocuments, changeReasons] = await Promise.all([
				dictionariesController.getQuarters(),
				dictionariesController.getProjectFinanceSources(router.currentRoute.params.projectId),
				dictionariesController.getTitleDocuments(),
				dictionariesController.getChangeReasons()
			]);

			const preparedQuarters = quarters.map(x => mapper.map(x, ApiQuarter, Quarter));

			commit(mutationTypes.SET_QUARTERS, limitQuartersByMaxYear(preparedQuarters, 15));
			commit(mutationTypes.SET_FINANCE_SOURCES, financeSources);
			commit(mutationTypes.SET_TITLE_DOCUMENTS, titleDocuments);
			commit(mutationTypes.SET_CHANGE_REASONS, changeReasons);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_LOADING, false);
		}
	}
};

const mutations = <MutationTree<LoanScheduleState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	[mutationTypes.SET_VERSION](state, value) {
		state.version = value;
	},
	[mutationTypes.SET_VERSION_HEADERS](state, value) {
		state.versionHeaders = value;
	},
	[mutationTypes.SET_IS_LOADING](state, value) {
		state.isLoading = value;
	},
	[mutationTypes.SET_QUARTERS](state, value) {
		state.quarters = value;
	},
	[mutationTypes.SET_FINANCE_SOURCES](state, value) {
		state.financeSources = value;
	},
	[mutationTypes.SET_TITLE_DOCUMENTS](state, value) {
		state.titleDocuments = value;
	},
	[mutationTypes.SET_CHANGE_REASONS](state, value) {
		state.changeReasons = value;
	},
	[mutationTypes.SET_WRONG_AMOUNT_DIALOG](state, value) {
		state.wrongAmountDialog = value;
	}
};

const modules = {
	[agreementModule.namespace]: {
		...agreementModule
	},
	[overduesModule.namespace]: {
		...overduesModule
	},
	[paymentsModule.namespace]: {
		...paymentsModule
	},
	[scheduleItemsModule.namespace]: {
		...scheduleItemsModule
	},
	[tranchesModule.namespace]: {
		...tranchesModule
	}
};

const subscribe = (store: any) => {
	scheduleItemsModule.subscribe(store);
	tranchesModule.subscribe(store);
	overduesModule.subscribe(store);
	agreementModule.subscribe(store);

	const { commit, dispatch } = store;

	store.subscribe(async ({ type, payload }: any, state: any) => {
		switch (type) {
			case resolveMutation(storeManager.loan.loanSchedule.agreement.namespace, agreementTypes.mutationTypes.VERSION_UPDATED_EVENT):
			{
				commit(resolveMutation(namespace, mutationTypes.SET_VERSION), mapper.map(payload, ApiVersion, Version));

				await dispatch(resolveAction(namespace, actionTypes.fetchVersionHeaders));

				break;
			}

			case resolveMutation(storeManager.loan.loanSchedule.agreement.namespace, agreementTypes.mutationTypes.VERSION_SELECTED_EVENT):
			{
				await dispatch(resolveAction(namespace, actionTypes.fetchVersion), payload);

				break;
			}

			case resolveMutation(storeManager.loan.loanSchedule.agreement.namespace, agreementTypes.mutationTypes.VERSION_DELETED_EVENT):
			{
				await dispatch(resolveAction(namespace, actionTypes.fetchVersionHeaders));
				await dispatch(resolveAction(namespace, actionTypes.fetchVersion));

				break;
			}

			case resolveMutation(storeManager.loan.loanSchedule.agreement.namespace,
				agreementTypes.mutationTypes.WRONG_TRANCHES_AMOUNT_EVENT):
			case resolveMutation(storeManager.loan.loanSchedule.tranches.namespace,
				tranchesTypes.mutationTypes.WRONG_TRANCHES_AMOUNT_EVENT):
			{
				commit(resolveMutation(namespace, mutationTypes.SET_WRONG_AMOUNT_DIALOG), WrongAmountDialogType.TRANCHES);

				break;
			}

			case resolveMutation(storeManager.loan.loanSchedule.agreement.namespace,
				agreementTypes.mutationTypes.WRONG_SCHEDULE_AMOUNT_EVENT):
			case resolveMutation(storeManager.loan.loanSchedule.scheduleItems.namespace,
				scheduleItemsTypes.mutationTypes.WRONG_SCHEDULE_AMOUNT_EVENT):
			{
				commit(resolveMutation(namespace, mutationTypes.SET_WRONG_AMOUNT_DIALOG), WrongAmountDialogType.SCHEDULE);

				break;
			}

			case resolveMutation(storeManager.loan.loanSchedule.agreement.namespace,
				agreementTypes.mutationTypes.WRONG_ASSIGNMENT_SCHEDULE_AMOUNT_EVENT):
			case resolveMutation(storeManager.loan.loanSchedule.scheduleItems.namespace,
				scheduleItemsTypes.mutationTypes.WRONG_ASSIGNMENT_SCHEDULE_AMOUNT_EVENT):
				{
					commit(resolveMutation(namespace, mutationTypes.SET_WRONG_AMOUNT_DIALOG), WrongAmountDialogType.ASSIGNMENT_SCHEDULE);
					break;
				}

			default:
				break;
		}
	});
};

export {
	namespace, state, getters, actions, mutations, modules, subscribe
};

const loanScheduleModule = {
	namespace, state, getters, actions, mutations, modules, subscribe, namespaced: true
};

export default loanScheduleModule;

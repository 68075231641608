<template>
	<loan-card-layout>
		<template #title>
			<frp-custom-title-loader style="margin-bottom: 6px"/>
		</template>

		<template #content>
			<v-card elevation="0"
					class="loan-card pa-4 pt-3 d-flex flex-column"
					style="row-gap: 18px">
				<v-row>
					<v-col md="2" class="d-flex flex-column pb-1 pt-7">
						<v-skeleton-loader type="text" max-width="40"/>
					</v-col>
					<v-col md="2" class="d-flex flex-column pb-1 pt-7 ml-n4">
						<v-skeleton-loader type="text" max-width="160"/>
					</v-col>
					<v-col md="1" class="d-flex flex-column pb-1 pt-7 ml-8 mr-16">
						<v-skeleton-loader type="text" max-width="100"/>
					</v-col>
					<v-col md="2" class="d-flex flex-column pb-1 pt-7 mr-8">
						<v-skeleton-loader type="text" max-width="220"/>
					</v-col>
					<v-col class="d-flex flex-column pb-1 pt-7">
						<v-skeleton-loader type="text" max-width="100"/>
					</v-col>
					<v-col class="d-flex flex-column pb-1 pt-7">
						<v-skeleton-loader type="text" max-width="100"/>
					</v-col>
				</v-row>
				<v-divider class="mb-3"></v-divider>
				<v-row class="pb-3">
					<v-col md="2" class="d-flex flex-column py-0">
						<v-skeleton-loader type="text" max-width="80"/>
					</v-col>
					<v-col md="2" class="d-flex flex-column py-0 ml-n4">
						<v-skeleton-loader type="text" max-width="100"/>
					</v-col>
					<v-col md="1" class="d-flex flex-column py-0 ml-8 mr-16">
						<v-skeleton-loader type="text" max-width="120"/>
					</v-col>
					<v-col md="2" class="d-flex flex-column py-0 mr-8">
						<v-skeleton-loader type="text" max-width="80"/>
					</v-col>
					<v-col class="d-flex flex-column py-0">
						<v-skeleton-loader type="text" max-width="80"/>
					</v-col>
					<v-col class="d-flex flex-column py-0">
						<v-skeleton-loader type="text" max-width="80"/>
					</v-col>
				</v-row>
				<v-row class="pb-1">
					<v-col md="2" class="d-flex flex-column py-0">
						<v-skeleton-loader type="text" max-width="80"/>
					</v-col>
					<v-col md="2" class="d-flex flex-column py-0 ml-n4">
						<v-skeleton-loader type="text" max-width="100"/>
					</v-col>
					<v-col md="1" class="d-flex flex-column py-0 ml-8 mr-16">
						<v-skeleton-loader type="text" max-width="120"/>
					</v-col>
					<v-col md="2" class="d-flex flex-column py-0 mr-8">
						<v-skeleton-loader type="text" max-width="80"/>
					</v-col>
					<v-col class="d-flex flex-column py-0">
						<v-skeleton-loader type="text" max-width="80"/>
					</v-col>
					<v-col class="d-flex flex-column py-0">
						<v-skeleton-loader type="text" max-width="80"/>
					</v-col>
				</v-row>
				<v-row style="padding-bottom: 11px" class=" pt-0">
					<v-col class="d-flex flex-column pt-0"></v-col>
					<v-col class="d-flex pt-0 justify-end pt-0">
						<v-skeleton-loader type="text" style="width: 170px" class="mr-6"/>
						<v-skeleton-loader type="text" style="width: 140px"/>
					</v-col>
				</v-row>
			</v-card>
		</template>
	</loan-card-layout>
</template>

<script>
import LoanCardLayout from "Components/layouts/LoanCardLayout";
import FrpCustomTitleLoader from "Components/loaders/common/FrpCustomTitleLoader";

export default {
	name: "LoanPaymentsLoader",
	components: { FrpCustomTitleLoader, LoanCardLayout }
};
</script>

<style scoped>

</style>

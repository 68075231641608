import FormState from "@/store/shared/form/models/formState";
import IPageState from "@/store/shared/base/types/pageState";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import ScheduleItem from "@/store/loan/modules/loanSchedule/modules/scheduleItems/types/scheduleItem";
import { ScheduleModeType } from "@/store/loan/modules/loanSchedule/modules/scheduleItems/types/scheduleModeType";

export default class ScheduleItemsState implements IPageState {
	constructor(
		public listing: ListingModel<ScheduleItem>,
		public sorting: SortingModel<String>,
		public paging: PagingModel,
		public search: SearchModel,
		public form: FormState,
		public snapshot: object,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public newItem: ScheduleItem = new ScheduleItem(),
		public editableItems: ScheduleItem[] = [],
		public isItemsUpdating: boolean = false,
		public isItemCreating: boolean = false,
		public isItemDeleting: boolean = false,
		public mode: ScheduleModeType = ScheduleModeType.READ
	)
	{
	}
}

import { addProfile, createMap, createMapper, forMember, mapFrom, MappingProfile } from "@automapper/core";
import { classes } from "@automapper/classes";
import { convertToTimestamp } from "@/utils/dates";
import ApiTreasuryStatementRecord from "@/api/loan/types/paymentsProcessing/apiTreasuryStatementRecord";
import TreasuryStatementRecord from "@/store/loan/modules/treasuryStatement/types/treasuryStatementRecord";
import ApiTreasuryStatementPayment from "@/api/loan/types/paymentsProcessing/apiTreasuryStatementPayment";
import TreasuryStatementPayment from "@/store/loan/modules/treasuryStatement/types/treasuryStatementPayment";

const mapper = createMapper({
	strategyInitializer: classes()
});

const treasuryStatementProfile: MappingProfile = (mapper) => {
	createMap(mapper, ApiTreasuryStatementRecord, TreasuryStatementRecord,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.number, mapFrom(x => x.number)),
		forMember(d => d.fileId, mapFrom(x => x.fileId)),
		forMember(d => d.dateDoc, mapFrom(x => convertToTimestamp(x.dateDoc))),
		forMember(d => d.typeDoc, mapFrom(x => x.typeDoc)),
		forMember(d => d.datePayment, mapFrom(x => convertToTimestamp(x.datePayment))),
		forMember(d => d.numberDoc, mapFrom(x => x.numberDoc)),
		forMember(d => d.kbk, mapFrom(x => x.kbk)),
		forMember(d => d.kSubs, mapFrom(x => x.kSubs)),
		forMember(d => d.oktmo, mapFrom(x => x.oktmo)),
		forMember(d => d.payment, mapFrom(x => x.payment)),
		forMember(d => d.payerBIK, mapFrom(x => x.payerBIK)),
		forMember(d => d.payerBank, mapFrom(x => x.payerBank)),
		forMember(d => d.payerAccNumber, mapFrom(x => x.payerAccNumber)),
		forMember(d => d.payerINN, mapFrom(x => x.payerINN)),
		forMember(d => d.payerKPP, mapFrom(x => x.payerKPP)),
		forMember(d => d.payerName, mapFrom(x => x.payerName)),
		forMember(d => d.payeeBIK, mapFrom(x => x.payeeBIK)),
		forMember(d => d.payeeBank, mapFrom(x => x.payeeBank)),
		forMember(d => d.payeeAccNumber, mapFrom(x => x.payeeAccNumber)),
		forMember(d => d.payeeINN, mapFrom(x => x.payeeINN)),
		forMember(d => d.payeeKPP, mapFrom(x => x.payeeKPP)),
		forMember(d => d.payeeName, mapFrom(x => x.payeeName)),
		forMember(d => d.paymentPurpose, mapFrom(x => x.paymentPurpose)),
		forMember(d => d.projectId, mapFrom(x => x.projectId)),
		forMember(d => d.projectStatus, mapFrom(x => x.projectStatus)),
		forMember(d => d.isFinal, mapFrom(x => x.isFinal)),
		forMember(d => d.payments, mapFrom(x => x.payments || []))
	);

	createMap(mapper, ApiTreasuryStatementPayment, TreasuryStatementPayment,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.number, mapFrom(x => x.number)),
		forMember(d => d.recordId, mapFrom(x => x.recordId)),
		forMember(d => d.financingSourceId, mapFrom(x => x.financingSourceId)),
		forMember(d => d.quarterId, mapFrom(x => x.quarterId)),
		forMember(d => d.paymentExpenseId, mapFrom(x => x.paymentExpenseId)),
		forMember(d => d.amount, mapFrom(x => x.amount)),
		forMember(d => d.isFinal, mapFrom(x => x.isFinal))
	);

	createMap(mapper, TreasuryStatementPayment, ApiTreasuryStatementPayment,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.number, mapFrom(x => x.number)),
		forMember(d => d.recordId, mapFrom(x => x.recordId)),
		forMember(d => d.financingSourceId, mapFrom(x => x.financingSourceId)),
		forMember(d => d.quarterId, mapFrom(x => x.quarterId)),
		forMember(d => d.paymentExpenseId, mapFrom(x => x.paymentExpenseId)),
		forMember(d => d.amount, mapFrom(x => x.amount)),
		forMember(d => d.isFinal, mapFrom(x => x.isFinal))
	);
};

addProfile(mapper, treasuryStatementProfile);

export default mapper;

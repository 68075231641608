import { IsArray, IsInt, IsString, Max, Min } from "class-validator";

export default class AgreementsOverduePaymentsRouteQuery {
	@IsInt()
	@Min(0)
	@Max(1000)
	page: number;

	@IsString()
	projectNumber: string;
	@IsString()
	organization: string;
	@IsString()
	controller: string;
	@IsArray()
	statuses: string[];

	constructor(
		page: number,
		projectNumber: string,
		organization: string,
		controller: string,
		statuses: string[]
	)
	{
		this.page = page;
		this.projectNumber = projectNumber;
		this.organization = organization;
		this.controller = controller;
		this.statuses = statuses;
	}
}

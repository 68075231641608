import { addProfile, createMap, createMapper, forMember, mapFrom, MappingProfile } from "@automapper/core";
import { classes } from "@automapper/classes";
import { convertToTimestamp } from "@/utils/dates";
import UpcomingPaymentsItem from "@/store/loan/modules/upcomingPayments/types/upcomingPaymentsItem";
import ApiUpcomingPaymentsItem from "@/api/loan/types/loanInterest/apiUpcomingPaymentsItem";
import ApiUpcomingPaymentsInterestDetailsItem from "@/api/loan/types/loanInterest/apiUpcomingPaymentsInterestDetailsItem";
import UpcomingPaymentsInterestDetailsItem from "@/store/loan/modules/upcomingPayments/types/upcomingPaymentsInterestDetailsItem";
import ApiAgreement from "@/api/loan/types/loanSchedule/apiAgreement";
import Agreement from "@/store/loan/modules/upcomingPayments/types/agreement";

const mapper = createMapper({
	strategyInitializer: classes()
});

const upcomingPaymentsProfile: MappingProfile = (mapper) => {
	createMap(mapper, ApiUpcomingPaymentsItem, UpcomingPaymentsItem,
		forMember(d => d.projectId, mapFrom(x => x.projectId)),
		forMember(d => d.financingSourceId, mapFrom(x => x.financingSourceId)),
		forMember(d => d.dateMainDebt, mapFrom(x => convertToTimestamp(x.dateMainDebt))),
		forMember(d => d.paymentMainDebt, mapFrom(x => x.paymentMainDebt)),
		forMember(d => d.balanceMainDebt, mapFrom(x => x.balanceMainDebt)),
		forMember(d => d.balancePrevPercent, mapFrom(x => x.balancePrevPercent)),
		forMember(d => d.datePaymentPrev, mapFrom(x => convertToTimestamp(x.datePaymentPrev))),
		forMember(d => d.datePayment, mapFrom(x => convertToTimestamp(x.datePayment))),
		forMember(d => d.accruedPercent, mapFrom(x => x.accruedPercent)),
		forMember(d => d.paymentPrevPercent, mapFrom(x => x.paymentPrevPercent)),
		forMember(d => d.payment, mapFrom(x => x.payment)),
		forMember(d => d.dayOverMainDebt, mapFrom(x => x.dayOverMainDebt)),
		forMember(d => d.overMainDebt, mapFrom(x => x.overMainDebt)),
		forMember(d => d.mathPenaltyMainDebt, mapFrom(x => x.mathPenaltyMainDebt)),
		forMember(d => d.dayOverPercent, mapFrom(x => x.dayOverPercent)),
		forMember(d => d.overPercent, mapFrom(x => x.overPercent)),
		forMember(d => d.mathPenaltyPercent, mapFrom(x => x.mathPenaltyPercent)),
		forMember(d => d.paymentPenaltyMainDebt, mapFrom(x => x.paymentPenaltyMainDebt)),
		forMember(d => d.paymentPenaltyPercent, mapFrom(x => x.paymentPenaltyPercent)),
		forMember(d => d.paymentPenaltyOther, mapFrom(x => x.paymentPenaltyOther)),
		forMember(d => d.maxDateDds, mapFrom(x => convertToTimestamp(x.maxDateDds)))
	);

	createMap(mapper, ApiUpcomingPaymentsInterestDetailsItem, UpcomingPaymentsInterestDetailsItem,
		forMember(d => d.minDate, mapFrom(x => convertToTimestamp(x.minDate))),
		forMember(d => d.maxDate, mapFrom(x => convertToTimestamp(x.maxDate))),
		forMember(d => d.periodName, mapFrom(x => x.periodName)),
		forMember(d => d.periodDays, mapFrom(x => x.periodDays)),
		forMember(d => d.accrualDays, mapFrom(x => x.accrualDays)),
		forMember(d => d.accruedAmount, mapFrom(x => x.accruedAmount)),
		forMember(d => d.financingSourceId, mapFrom(x => x.financingSourceId))
	);

	createMap(mapper, ApiAgreement, Agreement,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.projectId, mapFrom(x => x.projectId)),
		forMember(d => d.documentNumber, mapFrom(x => x.documentNumber)),
		forMember(d => d.financeSource, mapFrom(x => x.financeSource)),
		forMember(d => d.exactFrpSum, mapFrom(x => x.exactFrpSum)),
		forMember(d => d.createDate, mapFrom(x => convertToTimestamp(x.createDate))),
		forMember(d => d.signingDate, mapFrom(x => convertToTimestamp(x.signingDate)))
	);
};

addProfile(mapper, upcomingPaymentsProfile);

export default mapper;

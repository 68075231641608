import { addProfile, createMap, createMapper, forMember, mapFrom, MappingProfile } from "@automapper/core";
import { classes } from "@automapper/classes";
import { convertToZonedIso, convertToTimestamp } from "@/utils/dates";
import Tranche from "@/store/loan/modules/loanSchedule/modules/tranches/types/tranche";
import ApiTranche from "@/api/loan/types/loanSchedule/apiTranche";

const mapper = createMapper({
	strategyInitializer: classes()
});

const tranchesProfile: MappingProfile = (mapper) => {
	createMap(mapper, ApiTranche, Tranche,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.number, mapFrom(x => x.number)),
		forMember(d => d.paymentDate, mapFrom(x => convertToTimestamp(x.paymentDate))),
		forMember(d => d.source, mapFrom(x => x.source)),
		forMember(d => d.amount, mapFrom(x => x.amount))
	);

	createMap(mapper, Tranche, ApiTranche,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.number, mapFrom(x => x.number)),
		forMember(d => d.paymentDate, mapFrom(x => convertToZonedIso(x.paymentDate))),
		forMember(d => d.source, mapFrom(x => x.source)),
		forMember(d => d.amount, mapFrom(x => x.amount))
	);
};

addProfile(mapper, tranchesProfile);

export default mapper;

export default class ReportsInternalFilter {
	companyId: string = "";
	year: string = "";
	quarter: string = "";

	constructor(
		companyId: string = "",
		year: string = "",
		quarter: string = ""
	)
	{
		this.companyId = companyId;
		this.year = year;
		this.quarter = quarter;
	}
}

import { Type } from "class-transformer";
import ApiAccruedInterest from "@/api/loan/types/loanInterest/apiAccruedInterest";

export default class ApiAccruedInterestItems {
	@Type(() => ApiAccruedInterest)
	items: ApiAccruedInterest[];
	totalCount: number;
	totalAmount: number;

	constructor(items: ApiAccruedInterest[], totalCount: number, totalAmount: number) {
		this.items = items;
		this.totalCount = totalCount;
		this.totalAmount = totalAmount;
	}
}

import { actionTypes, getterTypes, mutationTypes, namespace } from "@/store/loan/modules/assignmentCompensation/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationPayload, MutationTree, Store } from "vuex";
import AbortService from "@/services/abortService";
import AccruedInterestState from "@/store/loan/modules/accruedInterest/types/accruedInterestState";
import FormMixinBuilder from "@/store/shared/form";
import ListingMixinBuilder from "@/store/shared/listing";
import PagingMixinBuilder from "@/store/shared/paging";
import SearchMixinBuilder from "@/store/shared/search";
import ListingModel from "@/store/shared/listing/models/listingModel";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import { DictionariesController } from "@/api/loan/dictionaries";
import router from "@/router/loan";
import BatchService from "@/services/batchService";
import RouteMixinBuilder from "@/store/shared/route";
import SubscribersManager from "@/store/manager/subscribersManager";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import routeTypes from "@/store/shared/route/types";
import { RouteNames } from "@/router/loan/routes";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import SortingMixinBuilder from "@/store/shared/sorting";
import AssignmentCompensationFilter from "@/store/loan/modules/assignmentCompensation/types/assignmentCompensationFilter";
import { LoanAssignmentCompensationController } from "@/api/loan/assignmentCompensation";
import AssignmentCompensationRouteQuery from "@/store/loan/modules/assignmentCompensation/types/assignmentCompensationRouteQuery";
import AssignmentCompensationRouteQueryService
	from "@/store/loan/modules/assignmentCompensation/services/assignmentCompensationRouteQueryService";
import AssignmentCompensationState from "@/store/loan/modules/assignmentCompensation/types/assignmentCompensationState";
import { AssigneeCompany, AssigneeCompanyMapper } from "@/store/loan/modules/assignmentCompensation/types/assigneeCompany";
import { AssignmentAgreementMapper } from "@/store/loan/modules/assignmentCompensation/types/assignmentAgreement";
import {
	AssignmentCompensationProject,
	AssignmentCompensationProjectMapper
} from "@/store/loan/modules/assignmentCompensation/types/assignmentCompensationProject";
import {
	ApiGetAssignmentCompensationProjectsParametersMapper
} from "@/api/loan/types/assignmentCompensation/apiGetAssignmentCompensationProjectsParameters";
import { formatDate } from "@/utils/dates";
import { dateFormat, isoDate, isoDateFormat } from "@/utils/formats";
import { format, getMonth, startOfQuarter } from "date-fns";
import { QuarterMapper } from "@/store/loan/modules/loanSchedule/types/quarter";
import { i18n } from "@/plugins";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import {
	ApiAssignmentCompensationOrderForSubmissionToAccountingRequestMapper
} from "@/api/loan/types/assignmentCompensation/apiAssignmentCompensationOrderForSubmissionToAccountingRequest";
import {
	ApiCalculateAssignmentCompensationRequestMapper
} from "@/api/loan/types/assignmentCompensation/apiCalculateAssignmentCompensationRequest";
import {
	AssignmentCompensationInterest,
	AssignmentCompensationInterestHelper
} from "@/store/loan/modules/assignmentCompensation/types/assignmentCompensationInterest";
import {
	ApiAssignmentCompensationInterestRequestMapper
} from "@/api/loan/types/assignmentCompensation/apiAssignmentCompensationInterestRequest";

const abortService = new AbortService();
const loanAssignmentCompensationController = new LoanAssignmentCompensationController(abortService);
const dictionariesController = new DictionariesController(abortService);

const defaultRouteQuery = new AssignmentCompensationRouteQuery(1, "", "", "", "");
const routeQueryService = new AssignmentCompensationRouteQueryService(defaultRouteQuery);
const updateListingBatchService = new BatchService(({ interval: 100 }));
const routeMixin = (new RouteMixinBuilder<AccruedInterestState>()).build();

const baseMixin = (new BaseMixinBuilder(abortService)).build();
const formMixin = (new FormMixinBuilder()).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new AssignmentCompensationState(
			new ListingModel<AssignmentCompensationProject>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String[]>({
				type: ["assignmentAgreementDate", "organizationName"],
				order: [sortingOrderType.ascending, sortingOrderType.ascending]
			}),
			new PagingModel({
				total: 0,
				page: defaultRouteQuery.page,
				pageSize: 25
			}),
			new SearchModel({
				query: ""
			}),
			formMixin.state(),
			routeMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

let subscribersManager: SubscribersManager<AssignmentCompensationState>;

let unsubscribeCallback = () => {
};
let store: Store<{}>;

const initializeSubscribersManager = (value: Store<{}>) => {
	store = value;
	subscribersManager = new SubscribersManager<AssignmentCompensationState>(store);
};

const subscribe = async (mutation: MutationPayload, rootState: any) => {
	let state = resolveNestedState<AssignmentCompensationState>(rootState, namespace);
	switch (mutation.type) {
		case resolveMutation(routeTypes.namespace, routeTypes.mutationTypes.ROUTE_CHANGED):
			if((mutation.payload.from.name === mutation.payload.to.name) && !state.route.isPushing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.processRouteQuery));
			break;
		case resolveMutation(namespace, mutationTypes.SET_FILTER_ASSIGNMENT_PERIOD_START_DATE):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_ASSIGNMENT_PERIOD_END_DATE):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_ASSIGNEE_ID):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_ASSIGNMENT_AGREEMENT_ID):
			if(!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.resetPagingPage));
		case resolveMutation(namespace, mutationTypes.SET_PAGING_PAGE):
		{
			if(!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));
			
			updateListingBatchService.push(async () => {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.updateListingItems));
			});
			
			break;
		}
	}
};

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<AssignmentCompensationState, any>>{
	...listingMixin.getters,
	...formMixin.getters,
	[getterTypes.formattedAssignmentCompensationProjects]: state => {
		return state.listing.items.map(x => {
			return {
				id: x.id.toString(),
				name: x.organizationName
			};
		});
	},
	[getterTypes.formattedAssignmentAgreements]: state => {
		return state.assignmentAgreements.map(agreement => {
			return {
				id: agreement.id,
				name: `${agreement.number} ${i18n.t("common.from")} ${formatDate(agreement.dateCreate, dateFormat)}`
			};
		});
	},
	[getterTypes.filteredAssignmentCompanies]: state => {
		return state.assigneeCompanies.filter(assigneeCompany => assigneeCompany?.assignmentAgreements.length);
	},
	[getterTypes.formattedAssignmentCompanies]: (state, getters) => {
		return getters.filteredAssignmentCompanies.map((assigneeCompany: AssigneeCompany) => {
			return {
				id: assigneeCompany.company.id,
				name: assigneeCompany.company.shortName,
				assignmentAgreements: assigneeCompany.assignmentAgreements
			};
		});
	}
};

const actions = <ActionTree<AssignmentCompensationState, any>>{
	...listingMixin.actions,
	...pagingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	...routeMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, state }) {
		await dispatch(actionTypes.initializeBase);
		
		await dispatch(actionTypes.fetchLastQuarter);
		
		await dispatch(actionTypes.processRouteQuery);
		await dispatch(actionTypes.setDefaultFilterValues);
		await dispatch(actionTypes.reconstituteRoute);
		
		await Promise.all([
			dispatch(actionTypes.fetchDictionaries),
			dispatch(actionTypes.updateListingItems)
		]);
		
		unsubscribeCallback = subscribersManager.subscribe(subscribe);
		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.setDefaultFilterValues]({ commit, state }) {
		if(!state.filter.assignmentPeriodStartDate)
			commit(mutationTypes.SET_FILTER_ASSIGNMENT_PERIOD_START_DATE, formatDate(state.lastQuarter.startDate, isoDateFormat));
		
		if(!state.filter.assignmentPeriodEndDate)
			commit(mutationTypes.SET_FILTER_ASSIGNMENT_PERIOD_END_DATE, formatDate(Date.now(), isoDateFormat));
	},
	async [actionTypes.fetchLastQuarter]({ commit }) {
		commit(mutationTypes.SET_IS_DICTIONARIES_LOADING, true);
		
		try {
			const quarters = await dictionariesController.getQuarters();
			if(!quarters.length) return;
			
			const currentDate = new Date();
			const formattedCurrentDate = format(currentDate, isoDate);
			
			const lastQuarter = quarters.find(x => x.startDate <= formattedCurrentDate);
			if(!lastQuarter) return;
			
			commit(mutationTypes.SET_LAST_QUARTER, QuarterMapper.map(lastQuarter));
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_DICTIONARIES_LOADING, false);
		}
	},
	async [actionTypes.fetchDictionaries]({ commit }) {
		commit(mutationTypes.SET_IS_DICTIONARIES_LOADING, true);
		
		try {
			const [assignmentAgreements, assigneeCompanies] = await Promise.all([
				dictionariesController.getAssignmentAgreements(),
				dictionariesController.getAssigneeCompanies()
			]);
			
			commit(mutationTypes.SET_ASSIGNMENT_AGREEMENTS, assignmentAgreements.map(x => AssignmentAgreementMapper.map(x)));
			commit(mutationTypes.SET_ASSIGNEE_COMPANIES, assigneeCompanies.map(x => AssigneeCompanyMapper.map(x)));
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_DICTIONARIES_LOADING, false);
		}
	},
	async [actionTypes.updateListingItems]({ commit, state }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);
		
		try {
			const parameters = ApiGetAssignmentCompensationProjectsParametersMapper.map(state);
			
			const assignmentCompensationProjects = await loanAssignmentCompensationController.getAssignmentCompensationProjects(parameters);
			
			const mappedAssignmentCompensationProjects = assignmentCompensationProjects.map(x => AssignmentCompensationProjectMapper.map(x));
			commit(mutationTypes.SET_LISTING_ITEMS, mappedAssignmentCompensationProjects);
			commit(mutationTypes.SET_PAGING_TOTAL, mappedAssignmentCompensationProjects.length);
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			console.error(error);
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.calculateAssignmentCompensation]({ dispatch, commit, state }) {
		commit(mutationTypes.SET_IS_ASSIGNMENT_COMPENSATION_CALCULATING, true);
		
		try {
			const payload = ApiCalculateAssignmentCompensationRequestMapper.map(state);
			
			await loanAssignmentCompensationController.calculateAssignmentCompensation(payload);
			
			alertService.addInfo(AlertKeys.ASSIGNMENT_COMPENSATION_SUCCESS_CALCULATED);
			
			await dispatch(actionTypes.updateListingItems);
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			console.error(error);
		} finally {
			commit(mutationTypes.SET_IS_ASSIGNMENT_COMPENSATION_CALCULATING, false);
		}
	},
	async [actionTypes.createOrderForSubmissionToAccounting]({ commit, state }) {
		commit(mutationTypes.SET_IS_ORDER_FOR_SUBMISSION_TO_ACCOUNTING_CREATING, true);
		
		try {
			const payload = ApiAssignmentCompensationOrderForSubmissionToAccountingRequestMapper.map(state);
			
			const { orderNumber } = await loanAssignmentCompensationController.createOrderForSubmissionToAccounting(payload);
			
			commit(mutationTypes.SET_ORDER_NUMBER_FOR_SUBMISSION_TO_ACCOUNTING, orderNumber);
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			console.error(error);
		} finally {
			commit(mutationTypes.SET_IS_ORDER_FOR_SUBMISSION_TO_ACCOUNTING_CREATING, false);
		}
	},
	async [actionTypes.sendOrderDocumentToAccountingForApprove]({ commit, state }) {
		commit(mutationTypes.RESET_IS_REPORT_APPROVING_HAS_ERROR);
		commit(mutationTypes.SET_IS_ORDER_DOCUMENT_TO_ACCOUNTING_FOR_APPROVE_SENDING, true);
		
		try {
			await loanAssignmentCompensationController.sendDocumentToAccounting(state.orderNumberForSubmissionToAccounting!.toString());
		} catch (error) {
			commit(mutationTypes.SET_IS_REPORT_APPROVING_HAS_ERROR, true);
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_ORDER_DOCUMENT_TO_ACCOUNTING_FOR_APPROVE_SENDING, false);
		}
	},
	async [actionTypes.addInterest]({ commit, state }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);
		
		try {
			const payload = ApiAssignmentCompensationInterestRequestMapper.map(state.newInterestItem);
			await loanAssignmentCompensationController.addInterest(state.newInterestItem.projectId, payload);
			
			const { projectId, preferentialAmount, compensableAmount } = state.newInterestItem;
			const compensationAmount = compensableAmount - preferentialAmount;
			const foundProject = state.listing.items.find(project => project.id === +projectId);
			
			commit(mutationTypes.SET_PROJECT_PREFERENTIAL_AMOUNT, { id: projectId, value: preferentialAmount });
			commit(mutationTypes.SET_PROJECT_COMPENSABLE_AMOUNT, { id: projectId, value: compensableAmount });
			commit(mutationTypes.SET_PROJECT_PERIOD_DAYS, { id: projectId, value: foundProject?.periodDays || 1 });
			commit(mutationTypes.SET_PROJECT_COMPENSATION_AMOUNT, { id: projectId, value: compensationAmount });
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			console.error(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.processRouteQuery]({ rootState, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, true);
		
		let routeQuery = await routeQueryService.resolveRouteQuery(rootState.route.query);
		
		commit(mutationTypes.SET_PAGING_PAGE, routeQuery.page);
		commit(mutationTypes.SET_FILTER_ASSIGNMENT_PERIOD_START_DATE, routeQuery.assignmentPeriodStartDate);
		commit(mutationTypes.SET_FILTER_ASSIGNMENT_PERIOD_END_DATE, routeQuery.assignmentPeriodEndDate);
		commit(mutationTypes.SET_FILTER_ASSIGNEE_ID, routeQuery.assigneeId);
		commit(mutationTypes.SET_FILTER_ASSIGNMENT_AGREEMENT_ID, routeQuery.assignmentAgreementId);
		
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, false);
	},
	async [actionTypes.pushRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);
		
		await router.push({
			name: RouteNames.ASSIGNMENT_COMPENSATION,
			params: router.currentRoute.params,
			query: routeQueryService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.reconstituteRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);
		
		await router.replace({
			name: RouteNames.ASSIGNMENT_COMPENSATION,
			params: router.currentRoute.params,
			query: routeQueryService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.destroy]({ dispatch }) {
		unsubscribeCallback();
		await dispatch(actionTypes.destroyBase);
	}
};

const mutations = <MutationTree<AssignmentCompensationState>>{
	...listingMixin.mutations,
	...pagingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	...routeMixin.mutations,
	[mutationTypes.SET_IS_DICTIONARIES_LOADING](state, value) {
		state.isDictionariesLoading = value;
	},
	[mutationTypes.SET_FILTER_ASSIGNMENT_PERIOD_START_DATE](state, value) {
		state.filter.assignmentPeriodStartDate = value;
	},
	[mutationTypes.SET_FILTER_ASSIGNMENT_PERIOD_END_DATE](state, value) {
		state.filter.assignmentPeriodEndDate = value;
	},
	[mutationTypes.SET_FILTER_ASSIGNMENT_AGREEMENT_ID](state, value) {
		state.filter.assignmentAgreementId = value;
	},
	[mutationTypes.SET_FILTER_ASSIGNEE_ID](state, value) {
		state.filter.assigneeId = value;
	},
	[mutationTypes.SET_LAST_QUARTER](state, value) {
		state.lastQuarter = value;
	},
	[mutationTypes.SET_ASSIGNMENT_AGREEMENTS](state, value) {
		state.assignmentAgreements = value;
	},
	[mutationTypes.SET_ASSIGNEE_COMPANIES](state, value) {
		state.assigneeCompanies = value;
	},
	[mutationTypes.RESET_NEW_INTEREST_ITEM](state) {
		state.newInterestItem = AssignmentCompensationInterestHelper.getEmpty();
	},
	[mutationTypes.SET_NEW_INTEREST_ITEM_PROJECT_ID](state, value) {
		state.newInterestItem.projectId = value;
	},
	[mutationTypes.SET_NEW_INTEREST_ITEM_DATE](state, value) {
		state.newInterestItem.date = value;
	},
	[mutationTypes.SET_NEW_INTEREST_ITEM_PREFERENTIAL_AMOUNT](state, value) {
		state.newInterestItem.preferentialAmount = value;
	},
	[mutationTypes.SET_NEW_INTEREST_ITEM_COMPENSABLE_AMOUNT](state, value) {
		state.newInterestItem.compensableAmount = value;
	},
	[mutationTypes.SET_PROJECT_PREFERENTIAL_AMOUNT](state, { id, value }) {
		state.listing.items[state.listing.items.findIndex(x => x.id == id)].preferentialAmount = value;
	},
	[mutationTypes.SET_PROJECT_COMPENSABLE_AMOUNT](state, { id, value }) {
		state.listing.items[state.listing.items.findIndex(x => x.id == id)].compensableAmount = value;
	},
	[mutationTypes.SET_PROJECT_PERIOD_DAYS](state, { id, value }) {
		state.listing.items[state.listing.items.findIndex(x => x.id == id)].periodDays = value;
	},
	[mutationTypes.SET_PROJECT_COMPENSATION_AMOUNT](state, { id, value }) {
		state.listing.items[state.listing.items.findIndex(x => x.id == id)].compensationAmount = value;
	},
	[mutationTypes.SET_IS_ASSIGNMENT_COMPENSATION_CALCULATING](state, value) {
		state.isAssignmentCompensationCalculating = value;
	},
	[mutationTypes.SET_IS_ORDER_FOR_SUBMISSION_TO_ACCOUNTING_CREATING](state, value) {
		state.isOrderForSubmissionToAccountingCreating = value;
	},
	[mutationTypes.SET_IS_ORDER_DOCUMENT_TO_ACCOUNTING_FOR_APPROVE_SENDING](state, value) {
		state.isOrderDocumentToAccountingForApproveSending = value;
	},
	[mutationTypes.RESET_IS_REPORT_APPROVING_HAS_ERROR](state) {
		state.isReportApprovingHasError = false;
	},
	[mutationTypes.SET_IS_REPORT_APPROVING_HAS_ERROR](state, value) {
		state.isReportApprovingHasError = value;
	},
	[mutationTypes.SET_ORDER_NUMBER_FOR_SUBMISSION_TO_ACCOUNTING](state, value) {
		state.orderNumberForSubmissionToAccounting = value;
	}
	
};

export {
	namespace, state, getters, actions, mutations, initializeSubscribersManager
};

const assignmentCompensationModule = {
	namespace, state, getters, actions, mutations, initializeSubscribersManager, namespaced: true
};

export default assignmentCompensationModule;

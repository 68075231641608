import { Type } from "class-transformer";
import ApiTroubledBorrower from "@/api/loan/types/troubledBorrower/apiTroubledBorrower";

export default class ApiTroubledBorrowerItems {
	@Type(() => ApiTroubledBorrower)
	items: ApiTroubledBorrower[];
	totalCount: number;

	constructor(
		items: ApiTroubledBorrower[] = [],
		totalCount: number = 0
	)
	{
		this.items = items;
		this.totalCount = totalCount;
	}
}

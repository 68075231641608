import { mutationTypes, actionTypes, getterTypes, namespace } from "@/store/loan/modules/loanSchedule/modules/payments/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import PaymentsState from "@/store/loan/modules/loanSchedule/modules/payments/types/paymentsState";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import AbortService from "@/services/abortService";
import { LoanScheduleController } from "@/api/loan/loanSchedule";
import ListingMixinBuilder from "@/store/shared/listing";
import PagingMixinBuilder from "@/store/shared/paging";
import SortingMixinBuilder from "@/store/shared/sorting";
import SearchMixinBuilder from "@/store/shared/search";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import mapper from "@/store/loan/modules/loanSchedule/modules/payments/mapper";
import Payment from "@/store/loan/modules/loanSchedule/modules/payments/types/payment";
import { formatCurrency } from "@/utils/formatting";
import ApiPayment from "@/api/loan/types/loanSchedule/apiPayment";
import router from "@/router/loan";

const abortService = new AbortService();
const loanScheduleController = new LoanScheduleController(abortService);

const baseMixin = (new BaseMixinBuilder(abortService)).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new PaymentsState(
			new ListingModel<Payment>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String>({
				type: "date",
				order: sortingOrderType.ascending
			}),
			new PagingModel({
				total: 0,
				page: 1,
				pageSize: 25
			}),
			new SearchModel({
				query: ""
			})
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<PaymentsState, any>>{
	...listingMixin.getters,
	[getterTypes.formattedItems]: state => {
		return state.listing.items.map(x => {
			return {
				date: x.date,
				planMainDebt: formatCurrency(x.planMainDebt) || "--",
				factLoan: formatCurrency(x.factLoan) || "--",
				factMainDebt: formatCurrency(x.factMainDebt) || "--",
				overdue: formatCurrency(x.overdue) || "--",
				factOverdue: formatCurrency(x.factOverdue) || "--",
				balanceMainDebt: x.balanceMainDebt,
				assignedLoan: formatCurrency(x.assignedLoan) || "--",
				assignedMainDebt: formatCurrency(x.assignedMainDebt) || "--",
				loanReIssuance: formatCurrency(x.loanReIssuance) || "--",
				temporaryRefund: formatCurrency(x.temporaryRefund) || "--",
				balanceAssign: x.balanceAssign
			};
		});
	}
};

const actions = <ActionTree<PaymentsState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...listingMixin.actions,
	...pagingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }) {
		await dispatch(actionTypes.initializeBase);

		await dispatch(actionTypes.fetch);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.fetch]({ commit }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);
		try {
			let items = await loanScheduleController.getPayments(router.currentRoute.params.projectId);

			commit(mutationTypes.SET_LISTING_ITEMS, items.map(x => mapper.map(x, ApiPayment, Payment)));
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	}
};

const mutations = <MutationTree<PaymentsState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...listingMixin.mutations,
	...pagingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations
};

export {
	namespace, state, getters, actions, mutations
};

const paymentsModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default paymentsModule;

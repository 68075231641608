<template>
	<loan-card-layout>
		<template #title>
			<frp-custom-title-loader style="margin-bottom: 6px"/>
		</template>
		<template #actions>
			<frp-btn-loader height="32" width="140"/>
		</template>

		<template #content>
			<v-card elevation="0"
					class="loan-card pa-4 pt-3">
				<div class="pb-9 d-flex flex-column" style="row-gap: 16px">
					<v-row class="align-start justify-space-between">
						<v-col class="pt-5 d-flex flex-column py-0">
							<frp-custom-title-loader height="14"/>
						</v-col>
						<v-col class="d-flex justify-end">
							<frp-btn-loader height="32" width="160"/>
						</v-col>
					</v-row>
					<v-row>
						<v-col class="d-flex flex-column py-0">
							<v-skeleton-loader type="text" max-width="40"/>
						</v-col>
						<v-col class="d-flex flex-column py-0">
							<v-skeleton-loader type="text" max-width="60"/>
						</v-col>
						<v-col class="d-flex flex-column py-0">
							<v-skeleton-loader type="text" max-width="60"/>
						</v-col>
						<v-col sm="1" v-if="$vuetify.breakpoint.lgAndUp" class="d-flex flex-column"></v-col>
					</v-row>
					<v-divider class="mb-4"></v-divider>
					<v-row class="pb-3" v-for="(item, index) in 2" :key="index">
						<v-col class="d-flex flex-column py-0">
							<v-skeleton-loader type="text" max-width="80"/>
						</v-col>
						<v-col class="d-flex flex-column py-0">
							<v-skeleton-loader type="text" max-width="80"/>
						</v-col>
						<v-col class="d-flex flex-column py-0">
							<v-skeleton-loader type="text" max-width="90"/>
						</v-col>
						<div style="width: 40px;" class="d-flex flex-column px-3 mr-1">
							<v-skeleton-loader type="text" max-width="20"/>
						</div>
					</v-row>
					<v-row>
						<v-col class="d-flex flex-column py-0">
							<v-skeleton-loader type="text" max-width="50"/>
						</v-col>
						<v-col></v-col>
						<v-row>
							<v-col>
								<v-skeleton-loader type="text" max-width="90"/>
							</v-col>
							<div style="width: 45px;" class="d-flex flex-column px-3 mr-1">
							</div>
						</v-row>
					</v-row>
				</div>
			</v-card>
		</template>
	</loan-card-layout>
</template>

<script>
import LoanCardLayout from "Components/layouts/LoanCardLayout";
import FrpCustomTitleLoader from "Components/loaders/common/FrpCustomTitleLoader";
import FrpBtnLoader from "Components/loaders/form/FrpBtnLoader";

export default {
	name: "LoanScheduleItemsLoader",
	components: { FrpBtnLoader, FrpCustomTitleLoader, LoanCardLayout }
};
</script>

<style scoped>

</style>

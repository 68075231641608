import IPageState from "@/store/shared/base/types/pageState";
import ListingModel from "@/store/shared/listing/models/listingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import FormState from "@/store/shared/form/models/formState";
import AccruedInterest from "@/store/loan/modules/accruedInterest/types/accruedInterest";
import ApiQuarter from "@/api/loan/types/dictionaries/apiQuarter";
import ApiFinanceSource from "@/api/loan/types/dictionaries/apiFinanceSource";
import ApiAccrualType from "@/api/loan/types/dictionaries/apiAccrualType";
import AccruedInterestFilter from "@/store/loan/modules/accruedInterest/types/accruedInterestFilter";
import RouteState from "@/store/shared/route/types/routeState";
import MassUpdateAccruedInterest from "@/store/loan/modules/accruedInterest/types/massUpdateAccruedInterest";
import ExtraRateParameters from "@/store/loan/modules/accruedInterest/types/extraRateParameters";
import AccruedInterestAddItemsParameters from "@/store/loan/modules/accruedInterest/types/accruedInterestAddItemsParameters";
import Agreement from "@/store/loan/modules/loanSchedule/modules/agreement/types/agreement";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import ApiBorrower from "@/api/loan/types/dictionaries/apiBorrower";

export default class AccruedInterestState implements IPageState {
	constructor(
		public listing: ListingModel<AccruedInterest>,
		public sorting: SortingModel<String[]>,
		public paging: PagingModel,
		public search: SearchModel,
		public form: FormState,
		public snapshot: object,
		public route: RouteState,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public addItemsParameters: AccruedInterestAddItemsParameters = new AccruedInterestAddItemsParameters(),
		public massUpdateParameters: MassUpdateAccruedInterest = new MassUpdateAccruedInterest(),
		public editableItems: AccruedInterest[] = [],
		public quarters: ApiQuarter[] = [],
		public financeSources: ApiFinanceSource[] = [],
		public accrualTypes: ApiAccrualType[] = [],
		public isDictionariesLoading: boolean = false,
		public savingItems: AccruedInterest[] = [],
		public isItemDeleting: boolean = false,
		public filter: AccruedInterestFilter = new AccruedInterestFilter(),
		public extraRateParameters: ExtraRateParameters = new ExtraRateParameters(),
		public agreement: Agreement = new Agreement(),
		public totalAmount: number = 0,
		public borrowers: ApiBorrower[] = []
	)
	{
	}
}

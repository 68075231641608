import ApiTroubledBorrowersLoanAgreement from "@/api/loan/types/troubledBorrower/apiTroubledBorrowersLoanAgreement";

export default class ApiTroubledBorrower {
	companyId: number;
	companyOgrn: string;
	companyName: string;
	agreementInfos: ApiTroubledBorrowersLoanAgreement[];
	frpSum: number;
	requirementsSum: number;
	fullRefundStatusId: number;
	responsibleUserName: string;
	responsibleUserId: number;
	projectIds: Array<number>

	constructor(
		companyId: number = 0,
		companyOgrn: string = "",
		companyName: string = "",
		agreementInfos: ApiTroubledBorrowersLoanAgreement[] = [],
		frpSum: number = 0,
		requirementsSum: number = 0,
		fullRefundStatusId: number = 0,
		responsibleUserName: string = "",
		responsibleUserId: number = 0,
		projectIds: Array<number> = []
	)
	{
		this.companyId = companyId;
		this.companyOgrn = companyOgrn;
		this.companyName = companyName;
		this.agreementInfos = agreementInfos;
		this.frpSum = frpSum;
		this.requirementsSum = requirementsSum;
		this.fullRefundStatusId = fullRefundStatusId;
		this.responsibleUserName = responsibleUserName;
		this.responsibleUserId = responsibleUserId;
		this.projectIds = projectIds;
	}
}

import ApiFinanceSource from "@/api/loan/types/dictionaries/apiFinanceSource";
import ApiAgreementStatus from "@/api/loan/types/loanSchedule/apiAgreementStatus";

export default class ApiAgreement {
	id: string;
	createDate: string;
	projectId: number;
	documentNumber: string;
	signingDate: string;
	financeSource: ApiFinanceSource;
	exactFrpSum: number;
	status: ApiAgreementStatus;

	constructor(
		id: string = "",
		createDate: string = "",
		projectId: number = 0,
		documentNumber: string = "",
		signingDate: string = "",
		financeSource: ApiFinanceSource = new ApiFinanceSource(),
		exactFrpSum: number = 0,
		status: ApiAgreementStatus = new ApiAgreementStatus()
	)
	{
		this.id = id;
		this.createDate = createDate;
		this.projectId = projectId;
		this.documentNumber = documentNumber;
		this.signingDate = signingDate;
		this.financeSource = financeSource;
		this.exactFrpSum = exactFrpSum;
		this.status = status;
	}
}

import { ApiCompany } from "@/api/loan/types/dictionaries/apiCompany";
import { convertToZonedTimestamp } from "@/utils/dates";

export interface Company {
	id: string;
	createDate: number;
	shortName: string;
	inn: string;
}

export class CompanyMapper {
	static map(source: ApiCompany): Company {
		return {
			...source,
			id: source.id.toString(),
			createDate: convertToZonedTimestamp(source.createDate) as number
		};
	}
}
export default class ApiPeriod {
	quartId: string;
	periodSmallName: string;
	periodName: string;
	startDate: string;
	endDate: string;
	id: string;
	number: number;
	isNearest: boolean;

	constructor(
		quartId: string = "",
		periodSmallName: string = "",
		periodName: string = "",
		startDate: string = "",
		endDate: string = "",
		id: string = "",
		number: number = 0,
		isNearest: boolean = false
	)
	{
		this.quartId = quartId;
		this.periodSmallName = periodSmallName;
		this.periodName = periodName;
		this.startDate = startDate;
		this.endDate = endDate;
		this.id = id;
		this.number = number;
		this.isNearest = isNearest;
	}
}

<template>
	<div>
		<v-subheader v-if="label" class="pa-0 mb-2 grey--text text-caption" style="height: fit-content">
			<span>{{ label }}</span>
		</v-subheader>
		<div class="d-flex align-center">
			<span :class="{ 'blue--text': !value }">{{ $t("aliases.affirmation.no") }}</span>
			<v-switch
				class="loan-switch mt-0"
				color="blue"
				:disabled="disabled"
				:input-value="value"
				@change="$emit('update:value', $event)"
				hide-details>
			</v-switch>
			<span :class="{ 'blue--text': value }">{{ $t("aliases.affirmation.yes") }}</span>
		</div>
	</div>

</template>

<script>
export default {
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean,
		disabled: Boolean,
		label: String
	}
};
</script>

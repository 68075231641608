import ApiProject from "@/api/loan/types/reports/apiProject";
import ApiReportAcceptance from "@/api/loan/types/reports/apiReportAcceptance";
import ApiReportQueries from "@/api/loan/types/reports/apiReportQueries";
import ApiReportFinalDocument from "@/api/loan/types/reports/apiReportFinalDocument";

export default class ApiReportFinal {
	documents: ApiReportFinalDocument[];
	project: ApiProject;
	acceptance: ApiReportAcceptance;
	canCreateReportingQueries: boolean;
	reportQueries: ApiReportQueries;

	constructor(
		documents: ApiReportFinalDocument[] = [],
		project: ApiProject = new ApiProject(),
		acceptance: ApiReportAcceptance = new ApiReportAcceptance(),
		canCreateReportingQueries: boolean = false,
		reportQueries: ApiReportQueries = new ApiReportQueries()
	)
	{
		this.documents = documents;
		this.project = project;
		this.acceptance = acceptance;
		this.canCreateReportingQueries = canCreateReportingQueries;
		this.reportQueries = reportQueries;
	}
}

<template>
	<loan-card-layout v-if="isInitialized">
		<template #title>
			{{ $t("titles.tranches") }}
		</template>
		<template #actions>
			<template v-if="mode === TranchesModeType.EDIT">
				<frp-btn class="text-none px-3"
						 text
						 plain
						 dense
						 color="primary"
						 @click="setMode(TranchesModeType.READ)">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				<frp-btn class="text-none px-3"
						 outlined
						 dense
						 :loading="isItemsUpdating"
						 :disabled="!isDataValid"
						 @click="updateItems()"
						 color="blue"
						 dark>
					{{ $t("buttons.save") }}
				</frp-btn>
			</template>
			<frp-btn class="text-none px-3"
					 v-else-if="can(Permissions.LOAN_UPDATE)"
					 outlined
					 dense
					 color="blue"
					 dark
					 :disabled="!formattedItems.length"
					 @click="setMode(TranchesModeType.EDIT)">
				{{ $t("buttons.edit") }}
			</frp-btn>
		</template>

		<template #content>
			<v-card elevation="0"
					class="loan-card pa-4 pt-3 d-flex flex-column"
					style="row-gap: 18px"
					:style="formattedItems.length ? 'border-bottom-right-radius: 0; border-bottom-left-radius: 0' : ''">
				<div v-if="!formattedItems.length">
					<div class="d-flex justify-end" v-if="can(Permissions.LOAN_CREATE)">
						<frp-btn @click="isNewItemDialogOpened = true"
								 class="text-none pl-1 pr-2"
								 dense
								 outlined
								 color="blue"
								 dark>
							<v-icon color="blue">mdi-plus</v-icon>
							{{ $t("buttons.addNote") }}
						</frp-btn>
					</div>

					<div class="d-flex align-center flex-column py-8">
						<span class="text-body-2 grey--text">{{ $t("content.noNotes") }}</span>
					</div>
				</div>

				<div v-else v-for="(source, i) in notEmptyFinanceSources" :key="source.id">
					<v-row class="align-start justify-space-between">
						<v-col class="py-0">
							<v-card-title class="px-0 pb-0">
								<span style="word-break: initial"
									  class="mr-1 text-subtitle-1 font-weight-medium blue--text text--darken-4">
									{{ source.name }}
								</span>
							</v-card-title>
						</v-col>

						<v-col style="width: fit-content" class="flex-grow-0" v-if="can(Permissions.LOAN_CREATE)">
							<frp-btn @click="isNewItemDialogOpened = true"
									 v-if="i === 0"
									 class="text-none pl-1 pr-2"
									 dense
									 outlined
									 color="blue"
									 dark>
								<v-icon color="blue">mdi-plus</v-icon>
								{{ $t("buttons.addNote") }}
							</frp-btn>
						</v-col>
					</v-row>

					<v-row>
						<v-col class="pt-0">
							<v-form v-model="isDataValid">
								<v-data-table :headers="headers"
											  :loading-text="$t('tables.loading')"
											  hide-default-footer
											  item-key="id"
											  :items="getItemsByFinanceSource(source)"
											  :items-per-page="-1"
											  :mobile-breakpoint="mobileBreakpoint"
											  :options="options"
											  :item-class="() => 'text-body-2 cy-table-row'"
											  class="loan-table clickable-rows d-flex flex-column">
									<template #item.paymentDate="{ item }">
										<span>{{ formatDate(item.paymentDate, dateFormat) || "--" }}</span>
									</template>
									<template #item.amount="{ item }">
										<span v-if="mode === TranchesModeType.READ">{{ formatCurrency(item.amount) }}</span>
										<frp-text-field dense
														required
														v-model.number="editableItems.find(x => x.id === item.id).amount"
														type="number"
														hide-details
														v-else
														:placeholder="$t('fields.amount.labelRUB')">
										</frp-text-field>
									</template>

									<template #item.delete="{ item }" v-if="can(Permissions.LOAN_DELETE)">
										<frp-btn without-padding
												 height="28"
												 small
												 icon
												 color="blue"
												 dark
												 @click="handleOpenDeleteItemDialog(item)">
											<frp-icon src="ico_delete" :color="colors.primary.darken1"></frp-icon>
										</frp-btn>

									</template>

									<template #foot>
										<tfoot class="text-subtitle-1 font-weight-medium grey--text text--darken-3">
										<tr>
											<template v-if="isMobileBreakpoint">
												<td class="d-flex justify-space-between mt-5">
													<span>{{ $t("content.total") }}</span>
													<span>{{ formatCurrency(getSum(getItemsByFinanceSource(source))) }}</span>
												</td>
											</template>

											<template v-else>
												<td>
													<span>{{ $t("content.total") }}</span>
												</td>
												<td>
													<span>{{ formatCurrency(getSum(getItemsByFinanceSource(source))) }}</span>
												</td>
											</template>
										</tr>
										</tfoot>
									</template>
								</v-data-table>
							</v-form>
						</v-col>
					</v-row>
				</div>
			</v-card>

			<v-card v-if="formattedItems.length"
					elevation="0"
					color="primary"
					class="loan-card py-4 px-7 text-subtitle-1 font-weight-medium white--text d-flex justify-space-between"
					style="border-top-right-radius: 0; border-top-left-radius: 0">
				<span>{{ $t("content.allInAll") }}</span>
				<span>{{ sum }}</span>
			</v-card>

			<frp-dialog v-model="isNewItemDialogOpened" :title="$t('dialogs.addTrancheFundingNote.title')" persistent>
				<template #content="{ onIntersect }">
					<v-form :ref="refs.form" v-model="formValid" v-intersect="onIntersect">
						<frp-autocomplete :label="$t('fields.financeSource.label')"
										  v-model="newItemSource"
										  :items="financeSources"
										  return-object
										  item-text="name"
										  color="blue"
										  required
										  :placeholder="$t('fields.financeSource.placeholder')">
						</frp-autocomplete>
						<frp-text-field :label="$t('fields.amount.labelRUB')"
										v-model.number="newItemAmount"
										type="number"
										required
										:placeholder="$t('fields.amount.placeholder')">
						</frp-text-field>
					</v-form>
				</template>

				<template #footer>
					<frp-btn outlined
							 @click="resetCreateItem"
							 color="primary">
						{{ $t("buttons.cancel") }}
					</frp-btn>
					<frp-btn elevation="0"
							 color="blue"
							 dark
							 :loading="isItemCreating"
							 :disabled="!stateContainsUnsavedChanges || !isFormValid"
							 @click="handleCreateItem">
						{{ $t("buttons.addNote") }}
					</frp-btn>
				</template>
			</frp-dialog>
			<frp-dialog v-model="isDeleteItemDialogOpened" :title="$t('dialogs.deleteNote.title')">
				<template #content="{ onIntersect }">
						<span class="primary--text text-body-2" v-intersect="onIntersect">
							{{ $t("dialogs.deleteNote.warning") }}
						</span>
				</template>

				<template #footer>
					<frp-btn outlined
							 @click="isDeleteItemDialogOpened = false"
							 :loading="isItemDeleting"
							 color="primary">
						{{ $t("buttons.cancel") }}
					</frp-btn>
					<frp-btn elevation="0"
							 color="blue"
							 dark
							 @click="handleDeleteItem">
						{{ $t("buttons.deleteNote") }}
					</frp-btn>
				</template>
			</frp-dialog>
		</template>
	</loan-card-layout>
	<loan-tranches-loader v-else/>
</template>

<script>
import FrpBtn from "Components/buttons/FrpBtn";
import FrpDialog from "Components/dialogs/FrpDialog";
import FrpAutocomplete from "Components/fields/FrpAutocomplete";
import FrpDateField from "Components/fields/FrpDateField";
import FrpTextField from "Components/fields/FrpTextField";
import FrpIcon from "Components/icon/FrpIcon";
import LoanCardLayout from "Components/layouts/LoanCardLayout";
import authorizationMixin from "Mixins/authorizationMixin";
import colorsMixin from "Mixins/colorsMixin";
import formMixin from "Mixins/formMixin";
import { listMixin } from "Mixins/listMixin";
import { ScheduleModeType } from "Store/loan/modules/loanSchedule/modules/scheduleItems/types/scheduleModeType";
import {
	getterTypes,
	mutationTypes,
	actionTypes
} from "Store/loan/modules/loanSchedule/modules/tranches/types";
import { TranchesModeType } from "Store/loan/modules/loanSchedule/modules/tranches/types/tranchesModeType";
import storeManager from "Store/manager";
import { formatDate } from "Utils/dates";
import { formatCurrency } from "Utils/formatting";
import { dateFormat } from "Utils/formats";
import { sumFloat } from "Utils/number";
import LoanTranchesLoader from "Views/loan/loanSchedule/tranches/LoanTranchesLoader";
import { createNamespacedHelpers } from "vuex";

const namespace = storeManager.loan.loanSchedule.tranches.namespace;
const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);
const { mapState: loanScheduleMapState } = createNamespacedHelpers(storeManager.loan.loanSchedule.namespace);

export default {
	mixins: [formMixin, listMixin, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			dateFormat,
			formatDate,
			formatCurrency,
			isDeleteItemDialogOpened: false,
			isNewItemDialogOpened: false,
			TranchesModeType,
			itemToDelete: null,
			isDataValid: false
		};
	},
	computed: {
		...loanScheduleMapState({
			quarters: state => state.quarters,
			financeSources: state => state.financeSources
		}),
		...mapState({
			isInitialized: state => state.isInitialized,
			mode: state => state.mode,
			newItem: state => state.newItem,
			isItemCreating: state => state.isItemCreating,
			isItemDeleting: state => state.isItemDeleting,
			isItemsUpdating: state => state.isItemsUpdating,
			editableItems: state => state.editableItems
		}),
		...mapGetters({
			formattedItems: getterTypes.formattedItems
		}),
		headers() {
			return [
				{
					text: this.$t("tables.dateFact"),
					value: "paymentDate",
					class: "text-caption",
					width: "50%",
					sortable: false
				},
				{
					text: this.$t("tables.amountRUB"),
					value: "amount",
					class: "text-caption",
					width: "50%",
					sortable: false
				},
				{
					text: "",
					value: "delete",
					width: "1%",
					sortable: false
				}
			];
		},
		sum() {
			return this.mode === TranchesModeType.EDIT ?
				formatCurrency(this.getSum(this.editableItems)) :
				formatCurrency(this.getSum(this.formattedItems));
		},
		newItemSource: {
			get() {
				return this.newItem.source;
			},
			set(value) {
				this.setNewItemSource(value);
			}
		},
		newItemPaymentDate: {
			get() {
				return this.newItem.paymentDate;
			},
			set(value) {
				this.setNewItemPaymentDate(value);
			}
		},
		newItemAmount: {
			get() {
				return this.newItem.amount;
			},
			set(value) {
				this.setNewItemAmount(value);
			}
		},
		notEmptyFinanceSources() {
			return this.financeSources.filter(x => this.formattedItems.some(y => y.source.id === x.id));
		}
	},
	methods: {
		...mapActions({
			initializeStore: actionTypes.initialize,
			createItem: actionTypes.createItem,
			updateItems: actionTypes.updateItems,
			deleteItem: actionTypes.deleteItem
		}),
		...mapMutations({
			setMode: mutationTypes.SET_MODE,
			resetNewItem: mutationTypes.RESET_NEW_ITEM,
			setNewItemSource: mutationTypes.SET_NEW_ITEM_SOURCE,
			setNewItemPaymentDate: mutationTypes.SET_NEW_ITEM_PAYMENT_DATE,
			setNewItemAmount: mutationTypes.SET_NEW_ITEM_AMOUNT
		}),
		getSum(items) {
			return items.map(x => x.amount).reduce((acc, x) => sumFloat(acc, +x), 0);
		},
		getItemsByFinanceSource(source) {
			return this.mode === ScheduleModeType.EDIT ?
				this.editableItems.filter(x => x.source.id === source.id) :
				this.formattedItems.filter(x => x.source.id === source.id);
		},
		async handleCreateItem() {
			await this.createItem();
			this.resetCreateItem();
		},
		resetCreateItem() {
			this.resetNewItem();
			this.$refs[this.refs.form].resetValidation();
			this.isNewItemDialogOpened = false;
		},
		async handleDeleteItem() {
			await this.deleteItem({ item: this.itemToDelete });
			this.itemToDelete = null;
			this.isDeleteItemDialogOpened = false;
		},
		handleOpenDeleteItemDialog(item) {
			this.isDeleteItemDialogOpened = true;
			this.itemToDelete = item;
		}
	},
	async created() {
		await this.initializeStore();
	},
	components: {
		FrpBtn,
		LoanTranchesLoader,
		LoanCardLayout,
		FrpIcon,
		FrpDateField,
		FrpAutocomplete,
		FrpTextField,
		FrpDialog
	}
};
</script>

import IPageState from "@/store/shared/base/types/pageState";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import FormState from "@/store/shared/form/models/formState";
import CashFlowItem from "@/store/loan/modules/cashFlow/types/cashFlowItem";
import ApiQuarter from "@/api/loan/types/dictionaries/apiQuarter";
import ApiFinanceSource from "@/api/loan/types/dictionaries/apiFinanceSource";
import ApiPaymentExpense from "@/api/loan/types/dictionaries/apiPaymentExpense";
import RouteState from "@/store/shared/route/types/routeState";
import CashFlowFilter from "@/store/loan/modules/cashFlow/types/cashFlowFilter";
import ApiBorrower from "@/api/loan/types/dictionaries/apiBorrower";
import ApiPaymentExpenseType from "@/api/loan/types/dictionaries/apiPaymentExpenseType";

export default class CashFlowState implements IPageState {
	constructor(
		public listing: ListingModel<CashFlowItem>,
		public sorting: SortingModel<String>,
		public paging: PagingModel,
		public search: SearchModel,
		public form: FormState,
		public snapshot: object,
		public route: RouteState,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isDictionariesLoading: boolean = false,
		public editableItem: CashFlowItem = new CashFlowItem(),
		public isItemDeleting: boolean = false,
		public quarters: ApiQuarter[] = [],
		public financeSources: ApiFinanceSource[] = [],
		public paymentExpenses: ApiPaymentExpense[] = [],
		public borrowers: ApiBorrower[] = [],
		public filter: CashFlowFilter = new CashFlowFilter(),
		public dynamicFinanceSources: ApiFinanceSource[] = [],
		public dynamicQuarters: ApiQuarter[] = [],
		public paymentExpenseTypes: ApiPaymentExpenseType[] = [],
		public allFinanceSources: ApiFinanceSource[] = [],
		public isFullMode: boolean = false,
		public reservedTotalAmount: number | null = null,
	)
	{
	}
}

<template>
	<v-card elevation="0" class="loan-card px-4 pt-4 pb-8 fill-height">
		<v-row>
			<v-col class="d-flex justify-space-between align-center">
				<span class="text-h6 blue--text text--darken-4">
					{{ $t("titles.incomePrediction") }}
				</span>
				<div>
					<frp-btn small height="32" color="primary"
							 @click="handleOpenIncomePredictionHistoryDialog()"
							 style="margin-left: 8px !important" class="mr-1"
							 icon>
						<frp-icon src="ico_history" :color="colors.blue.darken4"></frp-icon>
					</frp-btn>
				</div>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="2">
				<frp-autocomplete dense
								  :items="years"
								  item-text="year"
								  item-value="year"
								  v-model="selectedYearValue"
								  hide-details>
				</frp-autocomplete>
			</v-col>
		</v-row>

		<v-row>
			<v-col>
				<v-data-table :headers="headers"
							  :loading-text="$t('tables.loading')"
							  hide-default-footer
							  item-key="id"
							  :items="visibleIncomePredictions"
							  :item-class="() => 'text-body-2 cy-table-row'"
							  class="loan-table colored-table clickable-rows d-flex flex-column px-3">

					<template #item.quarter="{ item }">
						<span>{{ quarterName(item.quarter) }}</span>
					</template>
					<template #item.conservativeValue="{ item }">
						<span v-if="mode === MainInfoModeType.READ">{{  formatCurrency(item.conservativeValue) }}</span>
						<frp-text-field v-else
										dense
										style="max-width: 100px"
										v-model.number="editableVersion.incomePredictions.find(x => x.id === item.id).conservativeValue"
										type="number"
										hide-details>
						</frp-text-field>
					</template>
					<template #item.optimisticValue="{ item }">
						<span v-if="mode === MainInfoModeType.READ">{{ formatCurrency(item.optimisticValue) }}</span>
						<frp-text-field v-else
										dense
										style="max-width: 100px"
										v-model.number="editableVersion.incomePredictions.find(x => x.id === item.id).optimisticValue"
										type="number"
										hide-details>
						</frp-text-field>
					</template>

				</v-data-table>
			</v-col>
		</v-row>

		<frp-dialog max-width="920" min-height="600"
					v-model="isHistoryDialogOpened"
					:title="$t('dialogs.incomePredictionHistory.title')">
			<template #content="{ onIntersect }">
				<div class="d-flex justify-center flex-column" style="gap: 8px" v-intersect="onIntersect">
					<frp-text-body-two v-if="!history?.length"
									   class="grey--text align-self-center mt-6 mb-3">
						{{ $t("dialogs.debtorStageStatusHistory.noDataText") }}
					</frp-text-body-two>
					<div v-else class="history">
						<v-row cols="5">
							<v-col cols="2">
								<frp-autocomplete dense
												  :items="historyYears"
												  item-text="year"
												  item-value="year"
												  v-model="selectedHistoryYear"
												  hide-details>
								</frp-autocomplete>
							</v-col>
						</v-row>

						<v-row>
							<v-col>{{ $t('dialogs.incomePredictionHistory.createdAt') }}</v-col>
							<v-col cols="1">{{ $t('dialogs.incomePredictionHistory.quarter') }}</v-col>
							<v-col>{{ $t('dialogs.incomePredictionHistory.conservativeValue') }}</v-col>
							<v-col>{{ $t('dialogs.incomePredictionHistory.optimisticValue') }}</v-col>
							<v-col>{{ $t('dialogs.incomePredictionHistory.createdBy') }}</v-col>
						</v-row>

						<v-row v-for="item in visibleHistory" :key="item.id">
							<v-col>{{ formatDate(item.createdAt, dateTimeFormat) }}</v-col>
							<v-col cols="1">{{ quarterName(item.quarter) }}</v-col>
							<v-col>{{ formatCurrency(item.conservativeValue) }}</v-col>
							<v-col>{{ formatCurrency(item.optimisticValue) }}</v-col>
							<v-col>{{ item.createdBy }}</v-col>
						</v-row>
					</div>
				</div>
			</template>

			<template #footer>
				<frp-btn class="elevation-0"
						 @click="isHistoryDialogOpened = false"
						 :color="colors.blue.base">
					<span class="white--text">{{ $t("buttons.close") }}</span>
				</frp-btn>
			</template>
		</frp-dialog>
	</v-card>
</template>

<script>
	import { getterTypes } from "@/store/loan/modules/troubledBorrower/modules/mainInfo/types";
	import { MainInfoModeType } from "@/store/loan/modules/troubledBorrower/modules/mainInfo/types/mainInfoModeType";
	import { actionTypes as historyActionTypes } from "@/store/loan/modules/troubledBorrower/types";
	import colorsMixin from "@/mixins/colorsMixin";
	import { listMixin } from "@/mixins/listMixin";
	import formMixin from "@/mixins/formMixin";
	import storeManager from "@/store/manager";
	import FrpAutocomplete from "@/components/fields/FrpAutocomplete";
	import FrpBtn from "@/components/buttons/FrpBtn";
	import FrpTextField from "@/components/fields/FrpTextField";
	import FrpIcon from "@/components/icon/FrpIcon";
	import FrpDialog from "@/components/dialogs/FrpDialog";
	import FrpTextBodyTwo from "@/components/typography/FrpTextBodyTwo";
	import { createNamespacedHelpers } from "vuex";

	import { formatDate } from "@/utils/dates";
	import { dateFormat, dateTimeFormat } from "@/utils/formats";
	import { formatCurrency } from "@/utils/formatting";
	import { orderBy } from "lodash";
	
	const namespace = storeManager.loan.troubledBorrower.mainInfo.namespace;
	const { mapState, mapGetters } = createNamespacedHelpers(namespace);
	const { mapState: troubledBorrowerMapState, mapActions: troubledBorrowerMapActions } = createNamespacedHelpers(storeManager.loan.troubledBorrower.namespace);

	function mapYearToObject(x) {
		return { year: x };
	}


	export default {
		mixins: [colorsMixin, listMixin, formMixin],
		data() {
			return {
				namespace,
				MainInfoModeType,
				isHistoryDialogOpened: false,
				selectedYear: new Date().getFullYear(),
				dateFormat,
				dateTimeFormat,
				formatDate,
				formatCurrency: formatCurrency,
				selectedHistoryYear: 0,
			}
		},
		computed: {
			...mapState({
				editableVersion: state => state.editableVersion,
				mode: state => state.mode
			}),
			...troubledBorrowerMapState({
				history: state => state.incomePredictionHistory,
			}),
			...mapGetters({
				version: getterTypes.version,
			}),
			selectedYearValue: {
				get() {
					return this.selectedYear;
				},
				set(value) {
					if (!value)
						value = this.years[0].year;
					this.selectedYear = value;
				}
			},
			years() {
				return Array.from(new Set(this.version.incomePredictions.map(x => x.year))).sort((x1, x2) => x1 - x2).map(mapYearToObject);
			},
			visibleIncomePredictions() {
				return this.mode === MainInfoModeType.READ ? 
					this.version.incomePredictions.filter(x => x.year === this.selectedYear) :
					this.editableVersion.incomePredictions.filter(x => x.year === this.selectedYear);
			},
			historyYears() {
				return Array.from(new Set(this.history.map(x => x.year))).sort((x1, x2) => x1 - x2).map(mapYearToObject);
			},
			visibleHistory() {
				return orderBy(this.history.filter(x => x.year == this.selectedHistoryYear), ["createdAt", "quarter"], ["desc", "asc"]);
			},
			headers() {
				return [
					{
						value: "quarter",
						class: "text-caption",
						width: "20%",
						sortable: false
					},
					{
						text: this.$t("tables.incomePredictions.conservativeValue"),
						value: "conservativeValue",
						class: "text-caption",
						width: "40%",
						sortable: false
					},
					{
						text: this.$t("tables.incomePredictions.optimisticValue"),
						value: "optimisticValue",
						class: "text-caption",
						width: "40%",
						sortable: false
					},
				];
			},
		},
		methods: {
			...troubledBorrowerMapActions({
				fetchHistory: historyActionTypes.fetchIncomePredictionHistory
			}),
			async handleOpenIncomePredictionHistoryDialog() {
				await this.fetchHistory(this.version.versionHeader.versionId);
				if (this.selectedHistoryYear === 0)
					this.selectedHistoryYear = this.selectedYear;
				this.isHistoryDialogOpened = true;
			},
			quarterName(quarter) {
				const quarterNames = ['', 'I кв.', 'II кв.', 'III кв.', 'IV кв.'];
				return quarterNames[quarter];
			}
		},
		components: {
			FrpAutocomplete,
			FrpBtn,
			FrpTextField,
			FrpIcon,
			FrpDialog,
			FrpTextBodyTwo
		}

	}
</script>

<style scoped lang="scss">
	.history {
		font-size: 12px;
	}
</style>

<template>
	<v-row class="mx-0">
		<v-col class="pb-2 pt-0">
			<loan-card>
				<template #title>
					<frp-text-loader classes="mb-4 mt-2" width="100"/>
				</template>

				<template #title-append>
					<frp-btn-loader/>
				</template>

				<v-row class="ma-0">
					<v-col cols="12" lg="4" class="pa-0"
						   :class="{ 'pr-2': $vuetify.breakpoint.lgAndUp, 'mb-4': $vuetify.breakpoint.mdAndDown }">
						<loan-nested-card class="pa-4 pb-5">
							<template #title>
								<frp-text-loader classes="ml-n4" width="100"/>
							</template>

							<template #title-append>
								<v-skeleton-loader height="16" width="16" type="image" class="rounded-lg mr-n4"/>
							</template>

							<v-row>
								<v-col class="d-flex flex-column pb-7 pt-6">
									<v-skeleton-loader type="text" max-width="100"/>
								</v-col>
								<v-col class="d-flex flex-column pb-7 pt-6">
									<v-skeleton-loader type="text" max-width="100"/>
								</v-col>
								<v-col class="d-flex flex-column pb-7 pt-6"></v-col>
							</v-row>
							<v-divider class="mb-3"></v-divider>
							<v-row class="py-4">
								<v-col class="d-flex flex-column py-0">
									<v-skeleton-loader type="text" max-width="100"/>
								</v-col>
								<v-col class="d-flex flex-column py-0">
									<v-skeleton-loader type="text" max-width="100"/>
								</v-col>
								<v-col class="d-flex flex-column justify-end align-end mt-n5 py-0">
									<frp-btn-loader width="150"/>
								</v-col>
							</v-row>
							<v-row class="pt-7">
								<v-col class="d-flex flex-column pt-0">
									<v-skeleton-loader type="text" style="width: 170px"/>
								</v-col>
								<v-col class="d-flex pt-0 justify-end pt-0">
									<v-skeleton-loader type="text" style="width: 140px"/>
								</v-col>
							</v-row>
						</loan-nested-card>
					</v-col>

					<v-col cols="12" lg="8" class="pa-0 pl-2" :class="{ 'pl-2': $vuetify.breakpoint.lgAndUp }">
						<loan-nested-card class="pa-4 pb-5">
							<template #title>
								<frp-text-loader classes="ml-n4" width="100"/>
							</template>

							<v-row class="flex-grow-0">
								<v-col class="d-flex flex-column pb-7 pt-6 ml-7">
									<v-skeleton-loader type="text" max-width="100"/>
								</v-col>
								<v-col class="d-flex flex-column pb-7 pt-6">
									<v-skeleton-loader type="text" max-width="100"/>
								</v-col>
								<v-col class="d-flex flex-column pb-7 pt-6">
									<v-skeleton-loader type="text" max-width="100"/>
								</v-col>
								<v-col class="d-flex flex-column pb-7 pt-6">
									<v-skeleton-loader type="text" max-width="100"/>
								</v-col>
								<v-col class="d-flex flex-column pb-7 pt-6">
									<v-skeleton-loader type="text" max-width="100"/>
								</v-col>
								<v-col class="d-flex flex-column pb-7 pt-6"></v-col>
							</v-row>
							<v-divider class="mb-3"></v-divider>
							<v-row class="py-4">
								<v-col class="d-flex flex-column py-0 ml-7">
									<v-skeleton-loader type="text" max-width="100"/>
								</v-col>
								<v-col class="d-flex flex-column py-0">
									<v-skeleton-loader type="text" max-width="100"/>
								</v-col>
								<v-col class="d-flex flex-column py-0">
									<v-skeleton-loader type="text" max-width="100"/>
								</v-col>
								<v-col class="d-flex flex-column py-0">
									<v-skeleton-loader type="text" max-width="100"/>
								</v-col>
								<v-col class="d-flex flex-column py-0">
									<v-skeleton-loader type="text" max-width="100"/>
								</v-col>
								<v-col class="d-flex flex-column align-end mt-n3 py-0">
									<frp-btn-loader width="150"/>
								</v-col>
							</v-row>
						</loan-nested-card>
					</v-col>
				</v-row>
			</loan-card>
		</v-col>
	</v-row>
</template>

<script>
import LoanContentLayout from "@/components/layouts/LoanContentLayout";
import LoanCard from "Components/layouts/LoanCard";
import LoanNestedCard from "Components/layouts/LoanNestedCard";
import FrpCustomTitleLoader from "Components/loaders/common/FrpCustomTitleLoader";
import FrpTextLoader from "Components/loaders/common/FrpTextLoader";
import FrpDetailsItemLoader from "Components/loaders/details/FrpDetailsItemLoader";
import FrpBtnLoader from "Components/loaders/form/FrpBtnLoader";

export default {
	components: {
		FrpBtnLoader,
		FrpTextLoader,
		FrpCustomTitleLoader,
		FrpDetailsItemLoader,
		LoanCard,
		LoanNestedCard,
		LoanContentLayout
	}
};
</script>

import { CashFlowPaymentType } from "@/store/loan/modules/cashFlow/types/cashFlowPaymentType";

export default class CashFlowFilter {
	paymentType: string;
	startPaymentDate: string;
	endPaymentDate: string;
	quarterCodeIds: string[];
	paymentExpenseIds: string[];

	constructor(
		paymentType: string = "",
		startPaymentDate: string = "",
		endPaymentDate: string = "",
		quarterCodeIds: string[] = [],
		paymentExpenseIds: string[] = []
	)
	{
		this.paymentType = paymentType;
		this.startPaymentDate = startPaymentDate;
		this.endPaymentDate = endPaymentDate;
		this.quarterCodeIds = quarterCodeIds;
		this.paymentExpenseIds = paymentExpenseIds;
	}
}

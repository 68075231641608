<template>
	<loan-nested-card :title="$t('titles.mainDebt')">
			<v-data-table :headers="headers"
						  :loading-text="$t('tables.loading')"
						  hide-default-footer
						  item-key="id"
						  :items="payments"
						  :loading="isItemsLoading"
						  :items-per-page="-1"
						  :mobile-breakpoint="mobileBreakpoint"
						  :options="options"
						  :item-class="() => 'text-body-2 '"
						  class="d-flex flex-column table-headers-top black--text upcoming-payments-table">				
				<template #item="{ item, index }">
					<tr class="text-body-2 row-no-border">
						<td v-for="column in headers">
							<span v-if="column.value === 'dayOverPercent'">
								{{ formatNullableTableValue(item.dayOverMainDebt) }}
							</span>
								
							<span v-else-if="column.value === 'overMainDebt'">
								{{ formatNullableTableValue(formatCurrency(item.overMainDebt)) }}
							</span>
								
							<span v-else-if="column.value === 'financingSourceId'">
								{{ financeSources.find(x => x.id === item.financingSourceId).name }}
							</span>
														
							<div v-else-if="column.value === 'action'">
								<frp-btn elevation="0"
										 dark
										 :loading="loadingFiles.some(x => x.type === PaymentOrderType.OVERDUE_MAIN_DEBT_REPAYMENT && x.sourceId === item.financingSourceId)"
										 color="blue"
										 @click="downloadPaymentOrder({ sourceId: item.financingSourceId, type: PaymentOrderType.OVERDUE_MAIN_DEBT_REPAYMENT })">
									{{ $t("buttons.generatePaymentOrder") }}
								</frp-btn>
							</div>
							
							<span v-else>{{ item[column.value] }}</span>
						</td>
					</tr>
					
					<tr class="row-no-hover" :class="financeSources.length >= 2 && index === 0 ? '' : 'row-no-border'">
						<td colspan="99">
							<div class="d-flex text-subtitle-1 font-weight-medium grey--text text--darken-3">
								<span>{{ `${$t("common.estimatedInterestAfterFullRepayment")} ${formatDate(new Date().getTime(), dateFormat)}:` }}</span>
								<v-spacer></v-spacer>
								<span class="text-no-wrap ml-2">{{ formatNullableTableValue(formatCurrency(item.mathPenaltyMainDebt)) }}</span>
							</div>
						</td>
					</tr>
					
					<tr class="row-no-hover row-no-border">
						<td colspan="99" style="height: 12px !important"></td>
					</tr>
				</template>
			</v-data-table>
	</loan-nested-card>
</template>

<script>
import FrpBtn from "Components/buttons/FrpBtn";
import LoanNestedCard from "Components/layouts/LoanNestedCard";
import { PaymentOrderType } from "Store/loan/modules/upcomingPayments/types/paymentOrderType";
import { formatDate } from "Utils/dates";
import { dateFormat } from "Utils/formats";
import { formatCurrency, formatNullableTableValue } from "Utils/formatting";
import { createNamespacedHelpers } from "vuex";
import { actionTypes, namespace } from "Store/loan/modules/upcomingPayments/types";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	data() {
		return {
			mobileBreakpoint: 880,
			options: {},
			formatDate,
			dateFormat,
			formatCurrency,
			formatNullableTableValue,
			PaymentOrderType
		};
	},
	computed: {
		...mapState({
			loadingFiles: state => state.loadingFiles,
			isItemsLoading: state => state.isItemsLoading,
			financeSources: state => state.financeSources,
			payments: state => state.payments
		}),
		headers() {
			const headers = [
				{
					text: this.$t("tables.dayOver"),
					value: "dayOverMainDebt",
					class: "text-body-2 font-weight-bold black--text py-2",
					width: "60%",
					sortable: false
				},
				{
					text: this.$t("tables.paymentSum"),
					value: "overMainDebt",
					class: "text-body-2 font-weight-bold black--text py-2",
					width: "40%",
					sortable: false
				},
				{
					text: "",
					value: "action",
					width: "1%",
					sortable: false
				}
			];
			
			if(this.financeSources.length >= 2)
				headers.splice(-1, 0, {
					text: this.$t("tables.financeSource"),
					value: "financingSourceId",
					class: "text-body-2 font-weight-bold black--text py-2",
					width: "30%",
					sortable: false
				});
			
			return headers;
		}
	},
	methods: {
		...mapActions({
			downloadPaymentOrder: actionTypes.downloadPaymentOrder
		})
	},
	components: { FrpBtn, LoanNestedCard }
};
</script>

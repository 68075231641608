import ApiTroubledBorrowerLoanAgreement from "@/api/loan/types/troubledBorrower/apiTroubledBorrowerLoanAgreement";
import ApiTroubledBorrowerFinancingScheme from "@/api/loan/types/troubledBorrower/apiTroubledBorrowerFinancingScheme";
import ApiTroubledBorrowerVersionHeader from "@/api/loan/types/troubledBorrower/apiTroubledBorrowerVersionHeader";
import ApiRefund from "@/api/loan/types/troubledBorrower/apiRefund";
import ApiKeyDates from "@/api/loan/types/troubledBorrower/apiKeyDates";
import ApiResponsibleUsersInfo from "@/api/loan/types/troubledBorrower/apiResponsibleUsersInfo";
import ApiIncomePrediction from "@/api/loan/types/troubledBorrower/apiIncomePrediction";
import ApiCost from "@/api/loan/types/troubledBorrower/apiCost";
import ApiRefundSourceProject from "@/api/loan/types/troubledBorrower/apiRefundSourceProject";
import ApiRefundsTotal from "@/api/loan/types/troubledBorrower/apiRefundsTotal";

export default class ApiTroubledBorrowerVersion {
	constructor(
		public versionHeader: ApiTroubledBorrowerVersionHeader = new ApiTroubledBorrowerVersionHeader(),
		public loanAgreements: ApiTroubledBorrowerLoanAgreement[] = [],
		public financingSchemes: ApiTroubledBorrowerFinancingScheme[] = [],
		public frpSum: number = 0,
		public mainDebt: number = 0,
		public interest: number = 0,
		public penalty: number = 0,
		public refund: ApiRefund = new ApiRefund(),
		public keyDates: ApiKeyDates = new ApiKeyDates(),
		public responsibleUsersInfo: ApiResponsibleUsersInfo = new ApiResponsibleUsersInfo(),
		public refundSourceInfos?: ApiRefundSourceProject[],
		public incomePredictions: ApiIncomePrediction[] = [],
		public refundsTotal: ApiRefundsTotal = new ApiRefundsTotal(),
		public costs: ApiCost[] = [],
	)
	{
	}
}

export default class ApiCompany {
	id: string;
	name: string;
	inn: string;
	ogrn: string;

	constructor(
		id: string = "",
		name: string = "",
		inn: string = "",
		ogrn: string = ""
	)
	{
		this.id = id;
		this.name = name;
		this.inn = inn;
		this.ogrn = ogrn;
	}
}

import { actionTypes, getterTypes, mutationTypes, namespace } from "@/store/loan/modules/upcomingPayments/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationPayload, MutationTree, Store } from "vuex";
import AbortService from "@/services/abortService";
import mapper from "@/store/loan/modules/upcomingPayments/mapper";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import { LoanInterestController } from "@/api/loan/loanInterest";
import { DictionariesController } from "@/api/loan/dictionaries";
import router from "@/router/loan";
import UpcomingPaymentsState from "@/store/loan/modules/upcomingPayments/types/upcomingPaymentsState";
import UpcomingPaymentsItem from "@/store/loan/modules/upcomingPayments/types/upcomingPaymentsItem";
import ApiUpcomingPaymentsItem from "@/api/loan/types/loanInterest/apiUpcomingPaymentsItem";
import ApiUpcomingPaymentsInterestDetailsItem from "@/api/loan/types/loanInterest/apiUpcomingPaymentsInterestDetailsItem";
import UpcomingPaymentsInterestDetailsItem from "@/store/loan/modules/upcomingPayments/types/upcomingPaymentsInterestDetailsItem";
import { LoanScheduleController } from "@/api/loan/loanSchedule";
import { LoanPaymentsProcessingController } from "@/api/loan/paymentsProcessing";
import ApiAgreement from "@/api/loan/types/loanSchedule/apiAgreement";
import Agreement from "@/store/loan/modules/upcomingPayments/types/agreement";
import { cloneDeep } from "lodash";
import { saveAs } from "file-saver";
import { i18n } from "@/plugins";
import ApiGetCashFlowItemsParameters from "@/api/loan/types/paymentsProcessing/apiGetCashFlowItemsParameters";
import { LoadingFile } from "@/store/loan/modules/upcomingPayments/types/loadingFile";

const abortService = new AbortService();
const loanScheduleController = new LoanScheduleController(abortService);
const loanInterestController = new LoanInterestController(abortService);
const dictionariesController = new DictionariesController(abortService);
const loanPaymentsController = new LoanPaymentsProcessingController(abortService);

const baseMixin = (new BaseMixinBuilder(abortService)).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new UpcomingPaymentsState(
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<UpcomingPaymentsState, any>>{};

const actions = <ActionTree<UpcomingPaymentsState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, state }) {
		await dispatch(actionTypes.initializeBase);

		await dispatch(actionTypes.fetchAgreement);
		await dispatch(actionTypes.updateListingItems);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.updateListingItems]({ commit, state }) {
		if(!state.financeSources.length)
			return;
		
		commit(mutationTypes.SET_IS_ITEMS_LOADING, true);

		try {
			const [upcomingPayments, detailsItems] = await Promise.all([
				loanInterestController.getUpcomingPayments(router.currentRoute.params.projectId),
				loanInterestController.getUpcomingPaymentsInterestDetails(router.currentRoute.params.projectId)
			]);

			commit(mutationTypes.SET_PAYMENTS,
				upcomingPayments.map(x => mapper.map(x, ApiUpcomingPaymentsItem, UpcomingPaymentsItem)));
			commit(mutationTypes.SET_INTEREST_DETAILS_ITEMS,
				detailsItems.map(x => mapper.map(x, ApiUpcomingPaymentsInterestDetailsItem, UpcomingPaymentsInterestDetailsItem)));
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			console.error(error);
		} finally {
			commit(mutationTypes.SET_IS_ITEMS_LOADING, false);
		}
	},
	async [actionTypes.fetchAgreement]({ commit }) {
		commit(mutationTypes.SET_IS_AGREEMENT_LOADING, true);

		try {
			const [agreement, financeSources, payments] = await Promise.all([
				loanScheduleController.getAgreement(router.currentRoute.params.projectId),
				dictionariesController.getProjectFinanceSources(router.currentRoute.params.projectId),
				loanPaymentsController.getCashFlowItems(router.currentRoute.params.projectId, new ApiGetCashFlowItemsParameters(0, 0, "Loan"))
			]);

			commit(mutationTypes.SET_AGREEMENT, mapper.map(agreement, ApiAgreement, Agreement));
			commit(mutationTypes.SET_FINANCE_SOURCES, financeSources.slice(0, 2));
			commit(mutationTypes.SET_ISSUED_SUM, payments.items.map(item => item.amount).reduce((sum, amount) => amount + sum, 0));
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_AGREEMENT_LOADING, false);
		}
	},
	async [actionTypes.downloadPaymentOrder]({ commit, state }, { sourceId, type }) {
		if(!sourceId)
			return;

		commit(mutationTypes.ADD_LOADING_FILE, { sourceId, type } as LoadingFile);

		try {
			const projectId = router.currentRoute.params.projectId;
			const file = await loanInterestController.getPaymentOrderFile(projectId, sourceId, type);
			saveAs(file,
				i18n.t("common.upcomingPaymentsFileName.paymentOrder",
					{ type: i18n.t(`common.upcomingPaymentsFileName.${type}`) }).toString());
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_LOADING_FILES, state.loadingFiles.filter(x => !(x.type === type && x.sourceId === sourceId)));
		}
	}
};

const mutations = <MutationTree<UpcomingPaymentsState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	[mutationTypes.SET_IS_AGREEMENT_LOADING](state, value) {
		state.isAgreementLoading = value;
	},
	[mutationTypes.SET_AGREEMENT](state, value) {
		state.agreement = value;
	},
	[mutationTypes.SET_FINANCE_SOURCES](state, value) {
		state.financeSources = value;
	},
	[mutationTypes.SET_INTEREST_DETAILS_ITEMS](state, value) {
		state.interestDetailsItems = value;
	},
	[mutationTypes.SET_PAYMENTS](state, value) {
		state.payments = value;
	},
	[mutationTypes.SET_IS_ITEMS_LOADING](state, value) {
		state.isItemsLoading = value;
	},
	[mutationTypes.SET_PAYMENT_IS_EXPANDED](state, { sourceId, value }) {
		state.payments[state.payments.findIndex(x => x.financingSourceId === sourceId)].isExpanded = value;
	},
	[mutationTypes.ADD_LOADING_FILE](state, value: LoadingFile) {
		state.loadingFiles.push(value);
	},
	[mutationTypes.SET_LOADING_FILES](state, value) {
		state.loadingFiles = cloneDeep(value);
	},
	[mutationTypes.SET_ISSUED_SUM](state, value) {
		state.frpIssuedSum = value;
	}

};

export {
	namespace, state, getters, actions, mutations
};

const upcomingPaymentsModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default upcomingPaymentsModule;

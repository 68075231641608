export default class ApiDocumentDescription {
	sectionNumber: number;
	sectionTitle: string;
	documentNumber: string;
	documentTitle: string;
	obsolete: boolean;
	multiple: boolean;
	maxFileSize: number;
	isRequired: boolean;
	calendarPlanStageId: number;
	keyIndicatorType: string;
	documentType: string;
	allowedExtensions: string[];
	generationPath: string;

	constructor(
		sectionNumber: number = 0,
		documentNumber: string = "",
		documentTitle: string = "",
		obsolete: boolean = false,
		multiple: boolean = false,
		maxFileSize: number = 0,
		isRequired: boolean = false,
		calendarPlanStageId: number = 0,
		keyIndicatorType: string = "",
		documentType: string = "",
		allowedExtensions: string[] = [],
		generationPath: string = ""
	)
	{
		this.sectionNumber = sectionNumber;
		this.documentNumber = documentNumber;
		this.documentTitle = documentTitle;
		this.obsolete = obsolete;
		this.multiple = multiple;
		this.maxFileSize = maxFileSize;
		this.isRequired = isRequired;
		this.calendarPlanStageId = calendarPlanStageId;
		this.keyIndicatorType = keyIndicatorType;
		this.documentType = documentType;
		this.allowedExtensions = allowedExtensions;
		this.generationPath = generationPath;
	}
}

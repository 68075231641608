<template>
	<v-card class="loan-card pt-4 fill-height" :style="`border-color: ${colors.grey.darken2}`" elevation="0" outlined>
		<v-row v-if="title || $slots['title'] || $slots['title-append']">
			<v-col class="d-flex align-center px-7 pb-2">
				<slot name="title">
					<span style="word-break: initial; height: 20px"
						  class="mr-1 text-subtitle-1 font-weight-medium">
						{{ title }}
					</span>
				</slot>
				<v-spacer></v-spacer>
				<slot name="title-append"></slot>
			</v-col>
		</v-row>
		<v-row class="fill-height">
			<v-col class="d-flex flex-column">
				<slot></slot>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import colorsMixin from "Mixins/colorsMixin";

export default {
	mixins: [colorsMixin],
	props: {
		title: String
	}
};
</script>

<style scoped>

</style>

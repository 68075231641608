import IPageState from "@/store/shared/base/types/pageState";
import ListingModel from "@/store/shared/listing/models/listingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import FormState from "@/store/shared/form/models/formState";
import { OverdueInterestModeType } from "@/store/loan/modules/overdueInterest/types/overdueInterestModeType";
import PenaltyInterest from "@/store/loan/modules/overdueInterest/modules/penaltyInterestList/types/penaltyInterest";
import SortingModel from "@/store/shared/sorting/models/sortingModel";

export default class PenaltyInterestListState implements IPageState {
	constructor(
		public listing: ListingModel<PenaltyInterest>,
		public sorting: SortingModel<String[]>,
		public paging: PagingModel,
		public search: SearchModel,
		public form: FormState,
		public snapshot: object,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public newItem: PenaltyInterest = new PenaltyInterest(),
		public editableItems: PenaltyInterest[] = [],
		public isItemsUpdating: boolean = false,
		public isItemCreating: boolean = false,
		public isItemDeleting: boolean = false,
		public mode: OverdueInterestModeType = OverdueInterestModeType.READ,
	)
	{
	}
}

import ApiUser from "@/api/loan/types/reports/apiUser";

export default class ApiReportDocumentFile {
	id: number;
	fileName: string;
	uploader: ApiUser;
	uploadDateTime: string;
	signer: string;
	documentId: string;

	constructor(
		id: number = 0,
		fileName: string = "",
		uploader: ApiUser = new ApiUser(),
		uploadDateTime: string = "",
		signer: string = "",
		documentId: string = ""
	)
	{
		this.id = id;
		this.fileName = fileName;
		this.uploader = uploader;
		this.uploadDateTime = uploadDateTime;
		this.signer = signer;
		this.documentId = documentId;
	}
}

import AssignmentCompensationState from "@/store/loan/modules/assignmentCompensation/types/assignmentCompensationState";

export interface ApiCalculateAssignmentCompensationRequest {
	startDate: string;
	endDate: string;
	assignmentAgreementId: number;
	assigneeId: number;
}

export class ApiCalculateAssignmentCompensationRequestMapper {
	static map(source: AssignmentCompensationState): ApiCalculateAssignmentCompensationRequest {
		return {
			startDate: source.filter.assignmentPeriodStartDate,
			endDate: source.filter.assignmentPeriodEndDate,
			assignmentAgreementId: +source.filter.assignmentAgreementId,
			assigneeId: +source.filter.assigneeId
		};
	}
}
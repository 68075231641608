import { ReportStatusType } from "@/store/loan/modules/reports/types/reportStatusType";
import ApiReportSection from "@/api/loan/types/reports/apiReportSection";
import ApiProject from "@/api/loan/types/reports/apiProject";
import ApiReportAcceptance from "@/api/loan/types/reports/apiReportAcceptance";
import ApiReportQueries from "@/api/loan/types/reports/apiReportQueries";

export default class ApiReport {
	sections: ApiReportSection[];
	status: ReportStatusType;
	reportFilledDate: string;
	reportFilledFineDateTime: string;
	hasAllFineableDocuments: boolean;
	project: ApiProject;
	acceptance: ApiReportAcceptance;
	canCreateReportingQueries: boolean;
	reportQueries: ApiReportQueries;

	constructor(
		sections: ApiReportSection[] = [],
		status: ReportStatusType = ReportStatusType.NOT_FILLED,
		reportFilledDate: string = "",
		reportFilledFineDateTime: string = "",
		hasAllFineableDocuments: boolean = false,
		project: ApiProject = new ApiProject(),
		acceptance: ApiReportAcceptance = new ApiReportAcceptance(),
		canCreateReportingQueries: boolean = false,
		reportQueries: ApiReportQueries = new ApiReportQueries()
	)
	{
		this.sections = sections;
		this.status = status;
		this.reportFilledDate = reportFilledDate;
		this.reportFilledFineDateTime = reportFilledFineDateTime;
		this.hasAllFineableDocuments = hasAllFineableDocuments;
		this.project = project;
		this.acceptance = acceptance;
		this.canCreateReportingQueries = canCreateReportingQueries;
		this.reportQueries = reportQueries;
	}
}

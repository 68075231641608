import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import urls, { urlTemplateParts } from "@/api/config/urls";
import { plainToInstance } from "class-transformer";
import ApiCompany from "@/api/loan/types/troubledBorrower/apiCompany";
import ApiTroubledBorrowerVersion from "@/api/loan/types/troubledBorrower/apiTroubledBorrowerVersion";
import ApiTroubledBorrowerVersionHeader from "@/api/loan/types/troubledBorrower/apiTroubledBorrowerVersionHeader";
import ApiSecurityAgreement from "@/api/loan/types/troubledBorrower/apiSecurityAgreement";
import { prepareUrl } from "@/utils/prepareUrlQuery";
import ApiDebtor from "@/api/loan/types/troubledBorrower/apiDebtor";
import ApiCreateDebtorRequest from "@/api/loan/types/troubledBorrower/apiCreateDebtorRequest";
import ApiDebtorEvent from "@/api/loan/types/troubledBorrower/apiDebtorEvent";
import ApiRequirement from "@/api/loan/types/troubledBorrower/apiRequirement";
import ApiTroubledBorrowerItems from "@/api/loan/types/troubledBorrower/apiTroubledBorrowerItems";
import ApiTroubledBorrower from "@/api/loan/types/troubledBorrower/apiTroubledBorrower";
import ApiGetTroubledBorrowersParameters from "@/api/loan/types/troubledBorrower/apiGetTroubledBorrowersParameters";
import ApiProject from "@/api/loan/types/troubledBorrower/apiProject";
import ApiStatusHistoryItem from "@/api/loan/types/troubledBorrower/apiStatusHistoryItem";
import ApiUpdateDebtorRequest from "@/api/loan/types/troubledBorrower/apiUpdateDebtorRequest";
import ApiRequirementHistoryItem from "@/api/loan/types/troubledBorrower/apiRequirementHistoryItem";
import ApiIncomePredictionHistory from "../types/troubledBorrower/apiIncomePredictionHistory";
import ApiRefundStatusTypeHistory from "../types/troubledBorrower/apiRefundStatusTypeHistory";
import ApiUpdateDebtorStatusRequest from "@/api/loan/types/troubledBorrower/apiUpdateDebtorStatusRequest";

export class TroubledBorrowerController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}

	getProject = async (projectId: string): Promise<ApiProject> => {
		let data = await this.client.get(urls.troubledBorrower.versions.getProject.replace(urlTemplateParts.id, projectId));
		return plainToInstance(ApiProject, data);
	};

	getCompany = async (companyId: string): Promise<ApiCompany> => {
		let data = await this.client.get(urls.troubledBorrower.versions.getCompany.replace(urlTemplateParts.id, companyId));
		return plainToInstance(ApiCompany, data);
	};

	getVersionHeaders = async (companyId: string): Promise<ApiTroubledBorrowerVersionHeader[]> => {
		let data = await this.client.get<object[]>(urls.troubledBorrower.versions.getVersionHeaders.replace(urlTemplateParts.id, companyId));
		return plainToInstance(ApiTroubledBorrowerVersionHeader, data);
	};

	getVersion = async (versionId: string): Promise<ApiTroubledBorrowerVersion> => {
		let data = await this.client.get(urls.troubledBorrower.versions.getVersions.replace(urlTemplateParts.id, versionId));
		return plainToInstance(ApiTroubledBorrowerVersion, data);
	};

	createVersion = async (companyId: string, versionName: string): Promise<ApiTroubledBorrowerVersion> => {
		const url = prepareUrl(urls.troubledBorrower.versions.createVersion.replace(urlTemplateParts.id, companyId), { versionName });
		let data = await this.client.post(url, {});
		return plainToInstance(ApiTroubledBorrowerVersion, data);
	};

	updateVersion = async (versionId: string, version: ApiTroubledBorrowerVersion): Promise<void> => {
		return await this.client.put(urls.troubledBorrower.versions.updateVersion.replace(urlTemplateParts.id, versionId), version);
	};

	getSecurityAgreements = async (versionId: string): Promise<ApiSecurityAgreement[]> => {
		let data = await this.client.get<object[]>(urls.troubledBorrower.securityAgreements.getSecurityAgreements.replace(urlTemplateParts.id, versionId));
		return plainToInstance(ApiSecurityAgreement, data);
	};

	updateSecurityAgreements = async (securityAgreements: ApiSecurityAgreement[]): Promise<void> => {
		return await this.client.put(urls.troubledBorrower.securityAgreements.updateSecurityAgreements, securityAgreements);
	};

	getDebtors = async (versionId: string): Promise<ApiDebtor[]> => {
		let data = await this.client.get<object[]>(urls.troubledBorrower.productionStatus.getDebtors.replace(urlTemplateParts.id, versionId));
		return plainToInstance(ApiDebtor, data);
	};

	getDebtorStatusHistory = async (versionId: string, debtorId: string): Promise<ApiStatusHistoryItem[]> => {
		let data = await this.client.get<ApiStatusHistoryItem[]>(urls.troubledBorrower.productionStatus.getDebtorStatusHistory.replace(
			urlTemplateParts.id,
			versionId).replace(urlTemplateParts.subId, debtorId));
		return plainToInstance(ApiStatusHistoryItem, data);
	};

	createDebtor = async (versionId: string, request: ApiCreateDebtorRequest): Promise<ApiDebtor> => {
		const url = prepareUrl(urls.troubledBorrower.productionStatus.createDebtor.replace(urlTemplateParts.id, versionId), request);
		let data = await this.client.post(url, {});

		return plainToInstance(ApiDebtor, data);
	};

	updateDebtor = async (versionId: string, debtorId: string, request: ApiUpdateDebtorRequest): Promise<ApiDebtor> => {
		const url = prepareUrl(urls.troubledBorrower.productionStatus.updateDebtor.replace(urlTemplateParts.id, versionId).replace(
			urlTemplateParts.subId,
			debtorId), request);
		let data = await this.client.put(url, {});

		return plainToInstance(ApiDebtor, data);
	};

	deleteDebtor = async (versionId: string, debtorId: string): Promise<void> => {
		const url = urls.troubledBorrower.productionStatus.deleteDebtor.replace(urlTemplateParts.id,
			versionId).replace(urlTemplateParts.subId, debtorId);

		return await this.client.delete(url);
	};

	updateDebtorStatus = async (versionId: string, debtorId: string, payload: ApiUpdateDebtorStatusRequest): Promise<void> => {
		const url = prepareUrl(urls.troubledBorrower.productionStatus.updateDebtorStatus.replace(urlTemplateParts.id, versionId).replace(urlTemplateParts.subId, debtorId), payload);

		return await this.client.put(url, {});
	};

	deleteDebtorStatus = async (versionId: string, debtorId: string, statusId: string): Promise<void> => {
		return await this.client.delete(urls.troubledBorrower.productionStatus.deleteDebtorStatus.replace(urlTemplateParts.id,
			versionId).replace(urlTemplateParts.subId, debtorId).replace(urlTemplateParts.thirdId, statusId));
	};

	updateDebtorStatusComment = async (versionId: string, debtorId: string, comment: string): Promise<void> => {
		const url = prepareUrl(urls.troubledBorrower.productionStatus.updateDebtorStatusComment.replace(urlTemplateParts.id,
			versionId).replace(urlTemplateParts.subId, debtorId), { comment });

		return await this.client.put(url, {});
	};

	createEvent = async (versionId: string, debtorId: string, event: ApiDebtorEvent): Promise<ApiDebtorEvent> => {
		let data = await this.client.post(urls.troubledBorrower.productionStatus.createEvent.replace(urlTemplateParts.id,
			versionId).replace(urlTemplateParts.subId, debtorId), event);
		return plainToInstance(ApiDebtorEvent, data);
	};

	updateEvent = async (versionId: string, debtorId: string, event: ApiDebtorEvent): Promise<void> => {
		return await this.client.put(urls.troubledBorrower.productionStatus.updateEvent.replace(urlTemplateParts.id,
			versionId).replace(urlTemplateParts.subId, debtorId), event);
	};

	deleteEvent = async (versionId: string, debtorId: string, eventId: string): Promise<void> => {
		return await this.client.delete(urls.troubledBorrower.productionStatus.deleteEvent.replace(urlTemplateParts.id, versionId).replace(
			urlTemplateParts.subId, debtorId).replace(urlTemplateParts.thirdId, eventId));
	};

	createRequirement = async (versionId: string, debtorId: string, requirement: ApiRequirement): Promise<ApiRequirement> => {
		const url = prepareUrl(urls.troubledBorrower.productionStatus.createRequirement.replace(urlTemplateParts.id, versionId).replace(urlTemplateParts.subId, debtorId), requirement);

		let data = await this.client.post(url, {});
		return plainToInstance(ApiRequirement, data);
	};

	updateRequirement = async (versionId: string, debtorId: string, requirementId: string, requirement: ApiRequirement): Promise<void> => {
		const url = urls.troubledBorrower.productionStatus.updateRequirement.replace(urlTemplateParts.id,
			versionId).replace(urlTemplateParts.subId, debtorId).replace(urlTemplateParts.thirdId, requirementId);

		return await this.client.put(prepareUrl(url, { requirementTypeId: requirement.requirementTypeId, sum: requirement.sum }), {});
	};

	deleteRequirement = async (versionId: string, debtorId: string, requirementId: string): Promise<void> => {
		return await this.client.delete(urls.troubledBorrower.productionStatus.deleteRequirement.replace(urlTemplateParts.id,
			versionId).replace(urlTemplateParts.subId, debtorId).replace(urlTemplateParts.thirdId, requirementId));
	};

	getRequirementHistoryItems = async (versionId: string, debtorId: string, requirementId: string): Promise<ApiRequirementHistoryItem[]> => {
		const url = urls.troubledBorrower.productionStatus.getRequirementHistoryItems.replace(urlTemplateParts.id,
			versionId).replace(urlTemplateParts.subId, debtorId).replace(urlTemplateParts.thirdId, requirementId);

		const data = await this.client.get<ApiRequirementHistoryItem[]>(url, {});

		return plainToInstance(ApiRequirementHistoryItem, data)
	};
	deleteRequirementHistory = async (versionId: string, debtorId: string, requirementId: string, historyItemId: string): Promise<void> => {
		const url = urls.troubledBorrower.productionStatus.deleteRequirementHistory.replace(urlTemplateParts.id,
			versionId).replace(urlTemplateParts.subId, debtorId).replace(urlTemplateParts.thirdId, requirementId).replace(urlTemplateParts.fourthId, historyItemId);

		return await this.client.delete(url);
	};

	getTroubledBorrowerItems = async (parameters: ApiGetTroubledBorrowersParameters): Promise<ApiTroubledBorrowerItems> => {
		let data = await this.client.get<{ items: ApiTroubledBorrower[], totalCount: number }>(prepareUrl(urls.troubledBorrower.items.get, parameters));
		return plainToInstance(ApiTroubledBorrowerItems, data);
	};

	getIncomePredictionHistory = async (versionId: string): Promise<ApiIncomePredictionHistory[]> => {
		const url = urls.troubledBorrower.versions.incomePredictionHistory.replace(urlTemplateParts.id, versionId);
		return await this.client.get<ApiIncomePredictionHistory[]>(url);
	};

	getRefundStatusTypeHistory = async (versionId: string): Promise<ApiRefundStatusTypeHistory[]> => {
		const url = urls.troubledBorrower.versions.refundStatusTypeHistory.replace(urlTemplateParts.id, versionId);
		return await this.client.get<ApiRefundStatusTypeHistory[]>(url);
	};
}

import IPageState from "@/store/shared/base/types/pageState";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import RouteState from "@/store/shared/route/types/routeState";
import ReportsInternalFilter from "@/store/loan/modules/reports/types/reportsInternalFilter";
import ApiReportFilter from "@/api/loan/types/reports/apiReportFilter";
import Report from "@/store/loan/modules/reports/types/report";
import ReportDocument from "@/store/loan/modules/reports/types/reportDocument";
import ReportAddQueryInfo from "@/store/loan/modules/reports/types/reportAddQueryInfo";

export default class ReportsState implements IPageState {
	constructor(
		public listing: ListingModel<ReportDocument>,
		public sorting: SortingModel<String>,
		public search: SearchModel,
		public route: RouteState,
		public report: Report = new Report(),
		public reportsFilter: ApiReportFilter = new ApiReportFilter(),
		public reportsInternalFilter: ReportsInternalFilter = new ReportsInternalFilter(),
		public selectedDocument: ReportDocument | null = null,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isReportsFilterLoading: boolean = false,
		public isSignedDocumentUploading: boolean = false,
		public isDocumentUploading: boolean = false,
		public isReportAccepting: boolean = false,
		public isReportDeclining: boolean = false,
		public isEmailConfirmationRequesting: boolean = false,
		public reportAddQueryInfo: ReportAddQueryInfo = new ReportAddQueryInfo()
	)
	{
	}
}

export default class UpcomingPaymentsItem {
	projectId: number;
	financingSourceId: number;
	dateMainDebt: number;
	paymentMainDebt: number;
	balanceMainDebt: number;
	balancePrevPercent: number;
	datePaymentPrev: number;
	datePayment: number;
	accruedPercent: number;
	paymentPrevPercent: number;
	payment: number;
	dayOverMainDebt: number;
	overMainDebt: number;
	mathPenaltyMainDebt: number;
	dayOverPercent: number;
	overPercent: number;
	mathPenaltyPercent: number;
	paymentPenaltyMainDebt: number;
	paymentPenaltyPercent: number;
	paymentPenaltyOther: number;
	maxDateDds: number;
	isExpanded: boolean;

	constructor(
		projectId: number = 0,
		financingSourceId: number = 0,
		dateMainDebt: number = 0,
		paymentMainDebt: number = 0,
		balanceMainDebt: number = 0,
		balancePrevPercent: number = 0,
		datePaymentPrev: number = 0,
		datePayment: number = 0,
		accruedPercent: number = 0,
		paymentPrevPercent: number = 0,
		payment: number = 0,
		dayOverMainDebt: number = 0,
		overMainDebt: number = 0,
		mathPenaltyMainDebt: number = 0,
		dayOverPercent: number = 0,
		overPercent: number = 0,
		mathPenaltyPercent: number = 0,
		paymentPenaltyMainDebt: number = 0,
		paymentPenaltyPercent: number = 0,
		paymentPenaltyOther: number = 0,
		maxDateDds: number = 0,
		isExpanded: boolean = true
	)
	{
		this.projectId = projectId;
		this.financingSourceId = financingSourceId;
		this.dateMainDebt = dateMainDebt;
		this.paymentMainDebt = paymentMainDebt;
		this.balanceMainDebt = balanceMainDebt;
		this.balancePrevPercent = balancePrevPercent;
		this.datePaymentPrev = datePaymentPrev;
		this.datePayment = datePayment;
		this.accruedPercent = accruedPercent;
		this.paymentPrevPercent = paymentPrevPercent;
		this.payment = payment;
		this.dayOverMainDebt = dayOverMainDebt;
		this.overMainDebt = overMainDebt;
		this.mathPenaltyMainDebt = mathPenaltyMainDebt;
		this.dayOverPercent = dayOverPercent;
		this.overPercent = overPercent;
		this.mathPenaltyPercent = mathPenaltyPercent;
		this.paymentPenaltyMainDebt = paymentPenaltyMainDebt;
		this.paymentPenaltyPercent = paymentPenaltyPercent;
		this.paymentPenaltyOther = paymentPenaltyOther;
		this.maxDateDds = maxDateDds;
		this.isExpanded = isExpanded;
	}
}

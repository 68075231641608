<template>
	<loan-content-layout>
		<loan-reports-buttons-group :report="reportFinal"
									:is-items-loading="isItemsLoading"
									:is-bulk-downloading="isBulkDownloading"
									:is-report-accepting="isReportAccepting"
									:is-report-declining="isReportDeclining"
									:can-accept="reportFinal.acceptance?.canAccept"
									:can-decline="reportFinal.acceptance?.canDecline"
									:acceptor="reportFinal.acceptance?.acceptor?.lastName"
									:can-create-reporting-queries="reportFinal?.canCreateReportingQueries ?? false"
									:queries-url="`/queries/${$route.params.projectId}?type=FinalReporting&status=&authorId=0`"
									:queries-opened-count="reportFinal.reportQueries?.openedCount ?? 0"
									:queries-total-count="reportFinal.reportQueries?.totalCount ?? 0"
									@report:accept="handleAcceptReport"
									@report:decline="handleDeclineReport"
									@add-query-dialog:open="isAddQueryDialogOpened = true">
			<template #download-bulk>
				<frp-btn fab
						 color="blue"
						 height="56"
						 :loading="isBulkDownloading"
						 :disabled="isItemsLoading || !reportFinal?.project?.id"
						 :title="$t('alerts.info.saveAllReportFiles')"
						 @click="handleDownloadBulk">
					<v-icon color="white">mdi-cloud-download</v-icon>
				</frp-btn>
			</template>
		</loan-reports-buttons-group>
		
		<v-row>
			<v-col class="d-flex align-center pb-2">
				<v-card-title class="text-h6 px-0 pt-2 pb-0 ml-4">
					<span style="white-space: nowrap">{{ $t("titles.reportsFinal") }}</span>
				</v-card-title>
			</v-col>
		</v-row>
		
		<v-row>
			<v-col class="d-flex flex-column pt-4" style="gap: 24px">
				<v-card elevation="0" class="loan-card pa-0">
					<v-row>
						<v-col>
							<v-data-table :headers="headers"
										  :loading-text="$t('tables.loading')"
										  :loading="isItemsLoading"
										  hide-default-footer
										  item-key="id"
										  :items="formattedItems"
										  :items-per-page="-1"
										  :mobile-breakpoint="mobileBreakpoint"
										  :options="options"
										  :item-class="() => 'text-body-2 cy-table-row'"
										  class="loan-table d-flex flex-column loan-reports-table">
								<template #item.documentName="{item}">
									<loan-reports-document-name :item="item"
																@history-dialog:open="handleOpenHistoryDialog($event)">
									</loan-reports-document-name>
								</template>
								
								<template #item.uploadFile="{item}">
									<v-row justify="center">
										<template v-if="item.canUploadFile">
											<v-col cols="auto" class="px-1">
												<frp-btn :color="colors.blue.base"
														 elevation="0"
														 @click="handleOpenUploadDialog(item)">
													<span class="white--text">{{ getUploadFileButtonText(item) }}</span>
												</frp-btn>
											</v-col>
										</template>
									</v-row>
								</template>
								
								<template #item.uploaderName="{item}">
									<span>
										{{
											`${item.lastDocument?.uploader?.lastName || ""}
											${item.lastDocument?.uploader?.firstName || ""}
											${item.lastDocument?.uploader?.middleName || ""}`
										}}
									</span>
								</template>
								
								<template #item.uploadDateTime="{item}">
									<span>
										{{
											item.lastDocument?.uploadDateTime ?
												`${formatDate(item.lastDocument.uploadDateTime, dateTimeFormat)}` : ""
										}}
									</span>
								</template>
								
								<template #item.signerName="{item}">
									<span>
										{{ item.lastDocument?.signer || "" }}
									</span>
								</template>
							</v-data-table>
						</v-col>
					</v-row>
					
					<frp-dialog v-model="isHistoryDialogOpened"
								:title="$t('dialogs.uploadedDocuments.title')"
								max-width="960"
								persistent
								@dialog:close="handleCloseHistoryDialog">
						<template #content="{ onIntersect }">
							<loan-reports-dialog-layout v-intersect="onIntersect">
								<template #title>{{ selectedDocument?.description?.documentTitle }}</template>
								
								<template #content v-if="selectedDocument?.documentFiles.length">
									<v-row>
										<v-col class="py-0">
											<loan-reports-list :selected-document="selectedDocument"></loan-reports-list>
										</v-col>
									</v-row>
								</template>
							</loan-reports-dialog-layout>
						</template>
						<template #footer>
							<frp-btn @click="handleCloseHistoryDialog"
									 elevation="0"
									 :color="colors.blue.base">
								<span class="white--text">{{ $t("buttons.close") }}</span>
							</frp-btn>
						</template>
					</frp-dialog>
					
					<frp-dialog v-model="isUploadDialogOpened"
								:title="$t('dialogs.uploadDocument.title')"
								max-width="960"
								persistent
								@dialog:close="handleCloseUploadDialog">
						<template #content="{ onIntersect }">
							<loan-reports-dialog-layout v-intersect="onIntersect">
								<template #title>{{ selectedDocument?.description?.documentTitle }}</template>
								
								<template #content>
									<v-row>
										<v-col>
										<span>
											{{ `(${$t("common.fileFormat")}: ` }}
										</span>
											<span>
											{{ `${selectedDocument?.description?.allowedExtensions.map(x => x.toUpperCase()).join("/")})` }}
										</span>
										</v-col>
									</v-row>
									
									<template v-if="selectedDocument?.documentFiles.length">
										<v-row>
											<v-col class="py-0">
												<loan-reports-list :selected-document="selectedDocument"></loan-reports-list>
											</v-col>
										</v-row>
									</template>
									
									<template v-if="selectedDocument">
										<v-row>
											<v-col>
												<loan-reports-dropzone
													:max-size="convertBytesToSize(selectedDocument?.description?.maxFileSize)"
													:file-md-col="!selectedDocument.isSignRequired ? 6 : 7"
													:file-lg-col="!selectedDocument.isSignRequired ? 6 : 7"
													@file:add="handleSetReportFinalDocumentFile"
													:formats="[...selectedDocument?.description?.allowedExtensions]"
													:is-uploading="isSignedDocumentUploading || isDocumentUploading"
													@file:delete="handleDeleteReportFinalDocumentFile"
													@format:is-valid="isValidFileFormat = $event"
													type="reportDocument">
													<template #file-action>
														<frp-btn v-if="!selectedDocument.isSignRequired"
																 class="elevation-0 mr-4"
																 :color="colors.blue.base"
																 :loading="isDocumentUploading"
																 :disabled="isSignedDocumentUploading || !isValidFileFormat"
																 dark
																 @click="handleUploadDocument">
															{{ $t("buttons.upload") }}
														</frp-btn>
														
														<loan-reports-digital-signature-dialog v-model="isUploadSignatureDialogOpened"
																							   :file="reportFinalDocumentFile"
																							   @signed="handleSignAndUploadDocument($event)"
																							   color="blue"
																							   button-elevation="0">
															<template #activator="{ on, attrs }">
																<frp-btn elevation="0"
																		 v-on="on"
																		 v-bind="attrs"
																		 :loading="isSignedDocumentUploading"
																		 :disabled="isDocumentUploading || !isValidFileFormat"
																		 :color="colors.blue.base">
																	<span class="white--text">{{ $t("buttons.uploadWithSignature") }}</span>
																</frp-btn>
															</template>
														</loan-reports-digital-signature-dialog>
													</template>
												</loan-reports-dropzone>
											</v-col>
										</v-row>
									</template>
								</template>
							</loan-reports-dialog-layout>
						</template>
						
						<template #footer>
							<frp-btn @click="handleCloseUploadDialog"
									 elevation="0"
									 :color="colors.blue.base">
								<span class="white--text">{{ $t("buttons.close") }}</span>
							</frp-btn>
						</template>
					</frp-dialog>
					
					<frp-dialog v-model="isAddQueryDialogOpened"
								:title="$t('dialogs.addQuery.title')"
								persistent
								@dialog:close="handleCloseAddQueryDialog">
						<template #content="{ onIntersect }">
							<v-form v-intersect="onIntersect" ref="addQueryForm" v-model="isAddQueryFormValid">
								<frp-text-field :label="$t('fields.queryTheme.label')"
												required
												v-model="addQueryTheme"
												:placeholder="$t('fields.queryTheme.placeholder')">
								</frp-text-field>
								
								<frp-textarea v-model="addQueryDescription"
											  required
											  :label="$t('fields.queryDescription.label')"
											  :placeholder="$t('fields.queryDescription.placeholder')">
								</frp-textarea>
								
								<frp-date-field :label="$t('fields.executionDate.label')"
												required
												v-model="addQueryExecutionDate"
												:placeholder="$t('fields.executionDate.placeholder')">
								</frp-date-field>
								
								<loan-reports-dropzone
									:max-size="`25 ${$t('fields.dropzone.description.mb')}`"
									@file:add="handleSetReportFinalDocumentFile"
									@file:delete="handleDeleteReportFinalDocumentFile"
									:is-uploading="isAddQueryReporting"
									type="addQueryReport">
								</loan-reports-dropzone>
							</v-form>
						</template>
						
						<template #footer>
							<frp-btn outlined
									 @click="handleCloseAddQueryDialog"
									 :disabled="isAddQueryReporting"
									 color="primary">
								{{ $t("buttons.cancel") }}
							</frp-btn>
							<frp-btn class="elevation-0"
									 :color="colors.blue.base"
									 :loading="isAddQueryReporting"
									 :disabled="!isAddQueryFormValid"
									 @click="handleAddReportingQuery">
								<span class="white--text">{{ $t("buttons.send") }}</span>
							</frp-btn>
						</template>
					</frp-dialog>
				</v-card>
			</v-col>
		</v-row>
	</loan-content-layout>
</template>

<script>

import { ReportsController } from "@/api/loan/reports";
import ApiReportAddQueryInfo from "@/api/loan/types/reports/apiReportAddQueryInfo";
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpTextBtn from "@/components/buttons/FrpTextBtn.vue";
import LoanReportsButtonsGroup from "@/components/common/LoanReportsButtonsGroup.vue";
import LoanReportsDocumentName from "@/components/common/LoanReportsDocumentName.vue";
import LoanReportsList from "@/components/common/LoanReportsList.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import LoanReportsDigitalSignatureDialog from "@/components/digitalSignature/LoanReportsDigitalSignatureDialog.vue";
import LoanReportsDropzone from "@/components/dropzone/LoanReportsDropzone.vue";
import FrpDateField from "@/components/fields/FrpDateField.vue";
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import FrpTextField from "@/components/fields/FrpTextField.vue";
import LoanContentLayout from "@/components/layouts/LoanContentLayout.vue";
import LoanReportsDialogLayout from "@/components/layouts/LoanReportsDialogLayout.vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import { listMixin } from "@/mixins/listMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { i18n } from "@/plugins";
import { RouteNames } from "@/router/loan/routes";
import AbortService from "@/services/abortService";
import { getterTypes } from "@/store/bod/modules/documents/types";
import { actionTypes, mutationTypes } from "@/store/loan/modules/reportsFinal/types";
import { ReportDocumentType } from "@/store/loan/modules/reports/types/reportDocumentType";
import mapper from "@/store/loan/modules/reportsFinal/mapper";
import ReportAddQueryInfo from "@/store/loan/modules/reportsFinal/types/reportAddQueryInfo";
import { namespace } from "@/store/loan/modules/reportsFinal";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import { convertBytesToSize } from "@/utils/converters";
import { formatDate } from "@/utils/dates";
import { dateTimeFormat } from "@/utils/formats";
import { formatCurrency, formatFullName } from "@/utils/formatting";
import { getHeadersContentDispositionFilename } from "@/utils/getHeadersProp";
import contentDispositionParser from "content-disposition-parser";
import { saveAs } from "file-saver";
import { first } from "lodash";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

const abortService = new AbortService();
const reportsController = new ReportsController(abortService);
export default {
	metaInfo: {
		title: i18n.t("metaTitles.finalReports")
	},
	mixins: [storeModuleBasedPage, listMixin, colorsMixin],
	data() {
		return {
			namespace,
			RouteNames,
			formatCurrency,
			formatFullName,
			formatDate,
			convertBytesToSize,
			first,
			dateTimeFormat,
			ReportDocumentType,
			isHistoryDialogOpened: false,
			isUploadDialogOpened: false,
			reportFinalDocumentFile: null,
			isUploadSignatureDialogOpened: false,
			isAddQueryDialogOpened: false,
			isValidFileFormat: false,
			isBulkDownloading: false,
			isAddQueryFormValid: false,
			isAddQueryReporting: false
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			reportFinal: state => state.reportFinal,
			selectedDocument: state => state.selectedDocument,
			isSignedDocumentUploading: state => state.isSignedDocumentUploading,
			isDocumentUploading: state => state.isDocumentUploading,
			isReportAccepting: state => state.isReportAccepting,
			isReportDeclining: state => state.isReportDeclining,
			reportAddQueryInfo: state => state.reportAddQueryInfo,
			reportAddQueryTitle: state => state.reportAddQueryInfo.title,
			reportAddQueryDescription: state => state.reportAddQueryInfo.description,
			reportAddQueryExecutionDate: state => state.reportAddQueryInfo.executionDate
		}),
		...mapGetters({
			formattedItems: getterTypes.formattedItems
		}),
		headers() {
			return [
				{
					text: this.$t("tables.documentName"),
					value: "documentName",
					class: "text-caption",
					width: "22%",
					sortable: false
				},
				{
					text: this.$t("tables.upload"),
					value: "uploadFile",
					align: "center",
					class: "text-caption",
					width: "14%",
					sortable: false
				},
				{
					text: this.$t("tables.uploaderFullName"),
					value: "uploaderName",
					class: "text-caption",
					width: "10%",
					sortable: false
				},
				{
					text: this.$t("tables.uploadDate"),
					value: "uploadDateTime",
					class: "text-caption",
					width: "6%",
					sortable: false
				},
				{
					text: this.$t("tables.signerFullName"),
					value: "signerName",
					class: "text-caption",
					width: "10%",
					sortable: false
				}
			];
		},
		addQueryTheme: {
			get() {
				return this.reportAddQueryTitle;
			},
			set(value) {
				this.setReportAddQueryTitle(value);
			}
		},
		addQueryDescription: {
			get() {
				return this.reportAddQueryDescription;
			},
			set(value) {
				this.setReportAddQueryDescription(value);
			}
		},
		addQueryExecutionDate: {
			get() {
				return this.reportAddQueryExecutionDate;
			},
			set(value) {
				this.setReportAddQueryExecutionDate(value);
			}
		}
	},
	methods: {
		...mapActions({
			uploadDocument: actionTypes.uploadDocument,
			acceptReport: actionTypes.acceptReport,
			declineReport: actionTypes.declineReport,
			updateListingItems: actionTypes.updateListingItems
		}),
		...mapMutations({
			resetSelectedDocument: mutationTypes.RESET_SELECTED_DOCUMENT,
			setSelectedDocument: mutationTypes.SET_SELECTED_DOCUMENT,
			resetReportAddQueryInfo: mutationTypes.RESET_REPORT_ADD_QUERY_INFO,
			setReportAddQueryTitle: mutationTypes.SET_REPORT_ADD_QUERY_INFO_TITLE,
			setReportAddQueryDescription: mutationTypes.SET_REPORT_ADD_QUERY_INFO_DESCRIPTION,
			setReportAddQueryExecutionDate: mutationTypes.SET_REPORT_ADD_QUERY_INFO_EXECUTION_DATE
		}),
		getUploadFileButtonText(document) {
			if(this.hasDocumentFiles(document) && !document.description.multiple) {
				return this.$t("buttons.update");
			}
			if(this.hasDocumentFiles(document) && document.description.multiple) {
				return this.$t("buttons.loadMore");
			}
			if(!this.hasDocumentFiles(document)) {
				return this.$t("buttons.upload");
			}
		},
		hasDocumentFiles(document) {
			return document.documentFiles && document.documentFiles.length > 0;
		},
		handleSetReportFinalDocumentFile(file) {
			this.reportFinalDocumentFile = file;
		},
		async handleUploadDocument() {
			try {
				await this.uploadDocument({ file: this.reportFinalDocumentFile });
				this.handleCloseUploadDialog();
			} catch (e) {
				throw e;
			}
		},
		async handleSignAndUploadDocument({ file, signature }) {
			try {
				await this.uploadDocument({ file, signature });
				this.handleCloseUploadDialog();
			} catch (e) {
				throw e;
			}
		},
		async handleAddReportingQuery() {
			this.isAddQueryReporting = true;
			try {
				const info = mapper.map(this.reportAddQueryInfo, ReportAddQueryInfo, ApiReportAddQueryInfo);
				
				await reportsController.addQueryReportFinal(this.$route.params.projectId, this.reportFinalDocumentFile, info);
				
				this.handleCloseAddQueryDialog();
				await this.updateListingItems();
				
				alertService.addInfo(AlertKeys.QUERY_SUCCESS_REPORTED);
			} catch (error) {
				AlertHelper.handleGeneralRequestErrors(error);
				console.error(error);
			} finally {
				this.isAddQueryReporting = false;
			}
		},
		async handleAcceptReport() {
			await this.acceptReport();
		},
		async handleDeclineReport() {
			await this.declineReport();
		},
		async handleDownloadBulk() {
			this.isBulkDownloading = true;
			
			try {
				const { data, responseHeaders } = await reportsController.downloadBulkFinal(this.$route.params.projectId);
				
				if(data) {
					const { "content-disposition": contentDisposition } = responseHeaders;
					const { filename } = contentDispositionParser(contentDisposition);
					
					saveAs(data, filename || "download.zip");
				} else {
					alertService.addCustomError(this.$t("alerts.errors.downloadDocumentsError"));
				}
			} catch (error) {
				console.error(error);
				alertService.addCustomError(error?.message || this.$t("alerts.errors.downloadDocumentsError"));
			} finally {
				this.isBulkDownloading = false;
			}
		},
		handleDeleteReportFinalDocumentFile() {
			this.reportFinalDocumentFile = null;
		},
		handleOpenHistoryDialog(document) {
			this.setSelectedDocument(document);
			this.isHistoryDialogOpened = true;
		},
		handleOpenUploadDialog(document) {
			this.setSelectedDocument(document);
			this.isUploadDialogOpened = true;
		},
		handleCloseHistoryDialog() {
			this.isHistoryDialogOpened = false;
			this.resetSelectedDocument();
		},
		handleCloseUploadDialog() {
			this.isUploadDialogOpened = false;
			this.resetSelectedDocument();
			this.handleDeleteReportFinalDocumentFile();
		},
		handleCloseAddQueryDialog() {
			this.$refs["addQueryForm"].resetValidation();
			this.isAddQueryDialogOpened = false;
			this.handleDeleteReportFinalDocumentFile();
			this.resetReportAddQueryInfo();
		}
	},
	created() {
		abortService.initialize();
		this.initializeStore();
	},
	components: {
		LoanReportsDialogLayout,
		LoanReportsDocumentName,
		LoanReportsButtonsGroup,
		LoanReportsDigitalSignatureDialog,
		FrpTextField,
		FrpDateField,
		FrpTextarea,
		LoanReportsDropzone,
		LoanReportsList,
		FrpDialog,
		FrpTextBtn,
		FrpBtn,
		LoanContentLayout
	}
};
</script>
<style scoped lang="scss">

</style>

<template>
	<v-row>
		<v-col>
			<v-data-table :headers="headers"
						  :loading-text="$t('tables.loading')"
						  :loading="isItemsLoading"
						  hide-default-footer
						  item-key="projectId"
						  show-select
						  must-sort
						  :headerProps="headerProps"
						  :items="formattedFilteredItems"
						  :items-per-page="-1"
						  @toggle-select-all="handleSelectAll"
						  @item-selected="handleSelectItem"
						  :mobile-breakpoint="mobileBreakpoint"
						  :options="options"
						  :item-class="() => 'text-body-2 cy-table-row'"
						  class="loan-table colored-table troubled-borrowers-table loan-aligned-top-table clickable-rows d-flex flex-column">
				<template #header.data-table-select="{ props, on }">
					<div class="d-flex align-center mr-4">
						<v-simple-checkbox v-bind="props" v-on="on"
										   color="blue"
										   class="frp-simple-checkbox blue--text"
										   :disabled="isInterestAccrueLoading"
										   :value="formattedFilteredItems.length === selectedItemsLength"
										   :indeterminate="state.isOnlySelectedShown ? false : !!selectedItemsLength && formattedFilteredItems.length !== selectedItemsLength">
						</v-simple-checkbox>
						<span class="text-caption">{{ $t("tables.consider") }}</span>
					</div>
				</template>
				
				<template v-slot:item="{ item }">
					<v-lazy tag="tr" style="height: 25px;">
						<div style="display: contents;">
							<td>
								<v-simple-checkbox color="blue"
												   :disabled="isInterestAccrueLoading"
												   @click="handleSelectItem(item, item.isSelected)"
												   :value="item.isSelected">
								</v-simple-checkbox>
							</td>
							<td>
								<span class="text--primary">{{ item.applicationNumber }}</span>
							</td>
							<td>
								<frp-btn text
										 plain
										 dense
										 color="blue"
										 dark
										 :href="`/projects/project/${item.projectId}`"
										 target="_blank"
										 style="padding: 0 !important;"
										 class="text-none font-weight-regular align-start">
									{{ item.company }}
								</frp-btn>
							</td>
							<td>{{ item.agreementTitle}}</td>
							<td>{{ item.agreementStatusName}}</td>
							<td>{{ item.daysForPeriod}}</td>
							<td>{{ item.accruedAmount}}</td>
							<td>
								<div v-if="item.accountedAmount.length" class="d-flex flex-column">
									<span class="mb-3" v-for="(subItem, i) in item.accountedAmount"
										  :key="`${i}-${subItem}`">
										{{ subItem || "--" }}
									</span>
								</div>
								<span v-else>--</span>
							</td>
							<td>
								<div v-if="item.accountedDate.length" class="d-flex flex-column">
									<span class="mb-3" v-for="(subItem, j) in item.accountedDate"
										  :key="`${j}-${subItem}`">
										{{ subItem || "--" }}
									</span>
								</div>
								<span v-else>--</span>
							</td>
							<td>
								<frp-btn without-padding
										 icon
										 height="28"
										 small
										 color="blue"
										 :href="item.link"
										 target="_blank"
										 dark>
									<frp-icon :color="colors.primary.base"
											  src="ico_open-in-new">
									</frp-icon>
								</frp-btn>
							</td>
							
						</div>
					</v-lazy>
				</template>
			
				
			</v-data-table>
		</v-col>
	</v-row>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import colorsMixin from "@/mixins/colorsMixin";
import { listMixin } from "@/mixins/listMixin";
import { namespace, getterTypes, mutationTypes, actionTypes } from "Store/loan/modules/agreementsAccruedInterest/types";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [listMixin, colorsMixin],
	props: {
		isInterestAccruing: {
			type: Boolean,
			default: false
		},
		isSubmitToAccounting: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			namespace,
			headerProps: {
				"sort-icon": "mdi-sort-reverse-variant"
			}
		};
	},
	computed: {
		...mapState({
			state: state => state,
			periods: state => state.periods,
			interestFilter: state => state.filter,
			agreementStatuses: state => state.agreementStatuses
		}),
		...mapGetters({
			formattedFilteredItems: getterTypes.formattedFilteredItems,
			isSelectedItemsEmpty: getterTypes.isSelectedItemsEmpty,
			selectedItemsLength: getterTypes.selectedItemsLength
		}),
		headers() {
			return [
				{
					text: this.$t("tables.projectNumber"),
					value: "applicationNumber",
					class: "text-caption",
					width: "13%",
					sortable: false
				},
				{
					text: this.$t("tables.organizationName"),
					value: "company",
					class: "text-caption",
					width: "12%",
					sort: (a, b) => a.localeCompare(b)
				},
				{
					text: this.$t("tables.agreement"),
					value: "agreementTitle",
					class: "text-caption",
					width: "13%",
					sortable: false
				},
				{
					text: this.$t("tables.currentStatus"),
					value: "agreementStatusName",
					class: "text-caption",
					width: "13%",
					sortable: false
				},
				{
					text: this.$t("tables.daysByPeriod"),
					value: "daysForPeriod",
					class: "text-caption",
					width: "16%",
					sort: (a, b) => a - b
				},
				{
					text: this.$t("tables.accruedAmount"),
					value: "accruedAmount",
					class: "text-caption",
					width: "13%",
					sortable: false
				},
				{
					text: this.$t("tables.accountedAmount"),
					value: "accountedAmount",
					class: "text-caption",
					width: "13%",
					sortable: false
				},
				{
					text: this.$t("tables.accountedDate"),
					value: "accountedDate",
					class: "text-caption",
					width: "13%",
					sortable: false
				},
				{
					text: "",
					value: "link",
					class: "text-caption",
					width: "1%",
					sortable: false
				}
			];
		},
		isInterestAccrueLoading() {
			return this.isInterestAccruing || this.isSubmitToAccounting;
		}
	},
	methods: {
		...mapActions({}),
		...mapMutations({
			setListingItemIsSelected: mutationTypes.SET_LISTING_ITEM_IS_SELECTED,
			setIsOnlySelectedShown: mutationTypes.SET_IS_ONLY_SELECTED_SHOWN
		}),
		async handleSelectAll({ value }) {
			this.formattedFilteredItems.forEach(x => {
				this.setListingItemIsSelected({ id: x.projectId, value });
			});
			
			if(!value)
				this.setIsOnlySelectedShown(false);
		},
		handleSelectItem(item, value) {
			if(this.isInterestAccrueLoading) return;
			
			this.setListingItemIsSelected({ id: item.projectId, value: !value });
		},
		setInternalFilterValues() {
		}
	},
	components: {
		FrpBtn,
		FrpIcon
	}
};
</script>

<style scoped>

</style>

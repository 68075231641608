import { addProfile, createMap, createMapper, forMember, mapFrom, MappingProfile } from "@automapper/core";
import { classes } from "@automapper/classes";
import { convertToZonedIso, convertToTimestamp, parseDate } from "@/utils/dates";
import ApiCashFlowItem from "@/api/loan/types/paymentsProcessing/apiCashFlowItem";
import CashFlowItem from "@/store/loan/modules/cashFlow/types/cashFlowItem";
import CashFlowState from "@/store/loan/modules/cashFlow/types/cashFlowState";
import ApiGetCashFlowItemsParameters from "@/api/loan/types/paymentsProcessing/apiGetCashFlowItemsParameters";

const mapper = createMapper({
	strategyInitializer: classes()
});

const cashFlowProfile: MappingProfile = (mapper) => {
	createMap(mapper, ApiCashFlowItem, CashFlowItem,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.creatorId, mapFrom(x => x.creatorId || 0)),
		forMember(d => d.number, mapFrom(x => x.number || 0)),
		forMember(d => d.projectId, mapFrom(x => x.projectId)),
		forMember(d => d.numberPaymentOrder, mapFrom(x => x.numberPaymentOrder)),
		forMember(d => d.paymentDate, mapFrom(x => convertToTimestamp(x.paymentDate))),
		forMember(d => d.quarterId, mapFrom(x => x.quarterId)),
		forMember(d => d.financingSourceId, mapFrom(x => x.financingSourceId)),
		forMember(d => d.paymentExpenseId, mapFrom(x => x.paymentExpenseId)),
		forMember(d => d.amount, mapFrom(x => x.amount)),
		forMember(d => d.description, mapFrom(x => x.description)),
		forMember(d => d.isForced, mapFrom(x => x.isForced)),
		forMember(d => d.isCession, mapFrom(x => x.isCession)),
		forMember(d => d.isReserve, mapFrom(x => x.isReserve)),
		forMember(d => d.activeDate, mapFrom(x => convertToTimestamp(x.activeDate)))
	);

	createMap(mapper, CashFlowItem, ApiCashFlowItem,
		forMember(d => d.id, mapFrom(x => x.id || "")),
		forMember(d => d.creatorId, mapFrom(x => x.creatorId || 0)),
		forMember(d => d.projectId, mapFrom(x => x.projectId)),
		forMember(d => d.number, mapFrom(x => x.number || 0)),
		forMember(d => d.projectId, mapFrom(x => x.projectId)),
		forMember(d => d.numberPaymentOrder, mapFrom(x => x.numberPaymentOrder)),
		forMember(d => d.paymentDate, mapFrom(x => convertToZonedIso(x.paymentDate))),
		forMember(d => d.quarterId, mapFrom(x => x.quarterId)),
		forMember(d => d.financingSourceId, mapFrom(x => x.financingSourceId)),
		forMember(d => d.paymentExpenseId, mapFrom(x => x.paymentExpenseId)),
		forMember(d => d.amount, mapFrom(x => x.amount)),
		forMember(d => d.description, mapFrom(x => x.description)),
		forMember(d => d.isForced, mapFrom(x => x.isForced)),
		forMember(d => d.isCession, mapFrom(x => x.isCession)),
		forMember(d => d.isReserve, mapFrom(x => x.isReserve)),
		forMember(d => d.activeDate, mapFrom(x => convertToZonedIso(x.activeDate)))
	);

	createMap(mapper, CashFlowState, ApiGetCashFlowItemsParameters,
		forMember(d => d.take, mapFrom(x => x.paging.pageSize)),
		forMember(d => d.skip, mapFrom(x => x.paging.pageSize * (x.paging.page - 1))),
		forMember(d => d.paymentType, mapFrom(x => x.filter.paymentType)),
		forMember(d => d.startPaymentDate, mapFrom(x => convertToZonedIso(parseDate(x.filter.startPaymentDate)))),
		forMember(d => d.endPaymentDate, mapFrom(x => convertToZonedIso(parseDate(x.filter.endPaymentDate)))),
		forMember(d => d.quarterCodeIds, mapFrom(x => x.filter.quarterCodeIds)),
		forMember(d => d.paymentExpenseIds, mapFrom(x => x.filter.paymentExpenseIds))
	);
};

addProfile(mapper, cashFlowProfile);

export default mapper;

export default class Quarter {
	id: string;
	title: string;
	startDate: number;
	number: number;

	constructor(
		id: string = "",
		title: string = "",
		startDate: number = 0,
		number: number = 0
	)
	{
		this.id = id;
		this.title = title;
		this.startDate = startDate;
		this.number = number;
	}
}

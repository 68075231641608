import { addProfile, createMap, createMapper, forMember, mapFrom, MappingProfile } from "@automapper/core";
import { classes } from "@automapper/classes";
import { convertToTimestamp } from "@/utils/dates";
import ApiAgreement from "@/api/loan/types/loanSchedule/apiAgreement";
import Agreement from "@/store/loan/modules/loanSchedule/modules/agreement/types/agreement";

const mapper = createMapper({
	strategyInitializer: classes()
});

const agreementProfile: MappingProfile = (mapper) => {
	createMap(mapper, ApiAgreement, Agreement,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.projectId, mapFrom(x => x.projectId)),
		forMember(d => d.documentNumber, mapFrom(x => x.documentNumber)),
		forMember(d => d.financeSource, mapFrom(x => x.financeSource)),
		forMember(d => d.exactFrpSum, mapFrom(x => x.exactFrpSum)),
		forMember(d => d.createDate, mapFrom(x => convertToTimestamp(x.createDate))),
		forMember(d => d.signingDate, mapFrom(x => convertToTimestamp(x.signingDate)))
	);
};

addProfile(mapper, agreementProfile);

export default mapper;

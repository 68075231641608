export default class ApiTroubledBorrowerVersionHeader {
	versionId: string;
	versionName: string;
	createdAt: string;

	constructor(
		versionId: string = "",
		versionName: string = "",
		createdAt: string = ""
	)
	{
		this.versionId = versionId;
		this.versionName = versionName;
		this.createdAt = createdAt;
	}
}

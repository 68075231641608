<template>
	<div>
		<v-row>
			<v-col>
				<v-card elevation="0" class="loan-card px-4 pt-4 pb-4 fill-height">
					<frp-custom-title-loader width="400" style="margin-bottom: 30px" />
					<v-row class="mt-6">
						<v-col cols="5" class="pa-0 main-block-column main-block-column-left">
							<div><frp-text-loader width="90" /></div>
							<div><frp-text-loader width="100" /></div>
							<div><frp-text-loader width="170" /></div>
							<div class="pl-12"><frp-text-loader width="120" /></div>
							<div class="pl-12"><frp-text-loader width="80" /></div>
							<div class="pl-12"><frp-text-loader width="80" /></div>
						</v-col>
						<v-col cols="2" class="pa-0 main-block-column">
							<div v-for="i in 6" :key="i"></div>
						</v-col>
						<v-col cols="5" class="pa-0 main-block-column main-block-column-right">
							<div><frp-text-loader width="100" /></div>
							<div><frp-text-loader width="100" /></div>
							<div><frp-text-loader width="80" /></div>
							<div><frp-text-loader width="80" /></div>
							<div><frp-text-loader width="80" /></div>
							<div><frp-text-loader width="80" /></div>
						</v-col>
					</v-row>
				</v-card>
			</v-col>

			<v-col>
				<v-card elevation="0" class="loan-card px-4 pt-4 pb-4 fill-height">
					<frp-custom-title-loader width="130" style="margin-bottom: 30px" />
					<v-row class="mt-6">
						<v-col cols="5" class="pa-0 main-block-column main-block-column-left">
							<div><frp-text-loader width="130" /></div>
							<div><frp-text-loader width="200" /></div>
							<div class="pl-12"><frp-text-loader width="100" /></div>
							<div class="pl-12"><frp-text-loader width="160" /></div>
							<div class="pl-12"><frp-text-loader width="90" /></div>
							<div class="pl-12"><frp-text-loader width="100" /></div>
							<div class="pl-12"><frp-text-loader width="160" /></div>
							<div class="pl-12"><frp-text-loader width="90" /></div>
							<div><frp-text-loader width="150" /></div>
						</v-col>
						<v-col cols="2" class="pa-0 main-block-column">
							<div v-for="i in 6" :key="i"></div>
						</v-col>
						<v-col cols="5" class="pa-0 main-block-column main-block-column-right">
							<div><frp-text-loader width="100" /></div>
							<div><frp-text-loader width="100" /></div>
							<div><frp-text-loader width="80" /></div>
							<div><frp-text-loader width="80" /></div>
							<div><frp-text-loader width="80" /></div>
							<div><frp-text-loader width="80" /></div>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
		</v-row>

		<v-row>
			<v-col>
				<v-card elevation="0" class="loan-card px-4 pt-4 pb-4 fill-height">
					<frp-custom-title-loader width="160" style="margin-bottom: 30px" />
					<v-row class="mt-6">
						<v-col cols="5" class="pa-0 main-block-column main-block-column-left">
							<div><frp-text-loader width="110" /></div>
							<div><frp-text-loader width="90" /></div>
							<div><frp-text-loader width="150" /></div>
							<div><frp-text-loader width="190" /></div>
						</v-col>
						<v-col cols="2" class="pa-0 main-block-column">
							<div><frp-text-loader classes="pl-0" width="80" /></div>
							<div v-for="i in 3" :key="i"></div>
						</v-col>
						<v-col cols="5" class="pa-0 main-block-column main-block-column-right">
							<div><frp-text-loader width="100" /></div>
							<div><frp-text-loader width="100" /></div>
							<div><frp-text-loader width="100" /></div>
							<div><frp-text-loader width="100" /></div>
						</v-col>
					</v-row>
				</v-card>
			</v-col>

			<v-col>
				<v-card elevation="0" class="loan-card px-4 pt-4 pb-4 fill-height">
					<frp-custom-title-loader width="300" style="margin-bottom: 30px" />
					<v-row class="mt-6">
						<v-col cols="5" class="pa-0 main-block-column main-block-column-left">
							<div><frp-text-loader width="50" /></div>
							<div><frp-text-loader width="230" /></div>
						</v-col>
						<v-col cols="2" class="pa-0 main-block-column">
							<div v-for="i in 2" :key="i"></div>
						</v-col>
						<v-col cols="5" class="pa-0 main-block-column main-block-column-right">
							<div><frp-text-loader width="140" /></div>
							<div><frp-text-loader width="90" /></div>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
		</v-row>

		<v-row>
			<v-col>
				<v-card elevation="0" class="loan-card px-4 pt-4 pb-4 fill-height">
					<frp-custom-title-loader width="300" style="margin-bottom: 30px" />
					<v-row class="mt-6">
						<v-col cols="5" class="pa-0 main-block-column main-block-column-left">
							<div><frp-text-loader width="50" /></div>
							<div><frp-text-loader width="230" /></div>
						</v-col>
						<v-col cols="2" class="pa-0 main-block-column">
							<div v-for="i in 2" :key="i"></div>
						</v-col>
						<v-col cols="5" class="pa-0 main-block-column main-block-column-right">
							<div><frp-text-loader width="140" /></div>
							<div><frp-text-loader width="90" /></div>
						</v-col>
					</v-row>
				</v-card>
			</v-col>

			<v-col>
				<v-card elevation="0" class="loan-card px-4 pt-4 pb-4 fill-height">
					<frp-custom-title-loader width="160" style="margin-bottom: 30px" />
					<v-row>
						<v-col><frp-text-loader width="100" /></v-col>
					</v-row>
					<v-row v-for="i in 5" :key="i">
						<v-col><frp-text-loader /></v-col>
						<v-col><frp-text-loader /></v-col>
						<v-col><frp-text-loader /></v-col>
					</v-row>
				</v-card>
			</v-col>

		</v-row>

		<v-row class="mb-0">
			<v-col class="d-flex justify-end">
				<frp-btn-loader height="40" width="160" />
			</v-col>
		</v-row>
	</div>
</template>

<script>
import FrpCustomTitleLoader from "Components/loaders/common/FrpCustomTitleLoader";
import FrpTextLoader from "Components/loaders/common/FrpTextLoader";
import FrpBtnLoader from "Components/loaders/form/FrpBtnLoader";

export default {
	components: { FrpTextLoader, FrpCustomTitleLoader, FrpBtnLoader }
};
</script>

<style scoped lang="scss">
.main-block-column {
	& > div {
		height: 48px;
	}
}

.main-block-column-left {
	& > div {
		padding-left: 16px;
	}
}

.main-block-column-right {
	& > div {
		padding-right: 16px;
	}
}
</style>

import { IsInt, IsString, Max, Min } from "class-validator";

export default class TroubledBorrowersRouteQuery {
	@IsInt()
	@Min(0)
	@Max(1000)
	page: number;

	@IsString()
	companyName: string;
	@IsString()
	responsibleUserId: string;

	constructor(
		page: number,
		companyName: string,
		responsibleUserId: string
	)
	{
		this.page = page;
		this.companyName = companyName;
		this.responsibleUserId = responsibleUserId;
	}
}

import IPageState from "@/store/shared/base/types/pageState";
import { SecurityAgreementsModeType } from "@/store/loan/modules/troubledBorrower/modules/securityAgreements/types/securityAgreementsModeType";
import FormState from "@/store/shared/form/models/formState";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import SecurityAgreement from "@/store/loan/modules/troubledBorrower/modules/securityAgreements/types/securityAgreement";

export default class SecurityAgreementsState implements IPageState {
	constructor(
		public listing: ListingModel<SecurityAgreement>,
		public sorting: SortingModel<String>,
		public paging: PagingModel,
		public search: SearchModel,
		public form: FormState,
		public snapshot: object,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public mode: SecurityAgreementsModeType = SecurityAgreementsModeType.READ,
		public editableItems: SecurityAgreement[] = []
	)
	{
	}
}

<template>
	<v-menu v-model="isVersionMenuOpened"
			offset-y
			max-height="300"
			:content-class="contentClass">
		<template v-slot:activator="{ on, attrs }">
			<div class="d-flex align-center"
				 style="cursor: pointer; border-bottom: 1px dashed; width: fit-content"
				 :style="`color: ${menuColor}`">
								<span class="text-body-1 font-weight-bold"
									  v-bind="attrs"
									  v-on="on">
									{{ currentVersion.title || "--" }}
								</span>
				<v-icon v-if="currentVersion.title && isVersionMenuOpened" :color="menuColor" @click="isVersionMenuOpened = false">
					mdi-menu-up
				</v-icon>
				<v-icon v-else-if="currentVersion.title && !isVersionMenuOpened" :color="menuColor" @click="isVersionMenuOpened = true">
					mdi-menu-down
				</v-icon>
			</div>
		</template>

		<v-list dense>
			<v-list-item v-for="header in versionHeaders"
						 link
						 @click="$emit('update:value', header.id)"
						 :key="header.id">
				<v-list-item-title>{{ header.title }}</v-list-item-title>
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<script>
import colorsMixin from "@/mixins/colorsMixin";

export default {
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	data() {
		return {
			isVersionMenuOpened: false
		};
	},
	props: {
		versionHeaders: Array,
		currentVersion: Object,
		color: {
			type: String,
			default: "secondary"
		}
	},
	computed: {
		menuColor() {
			if(this.color === "secondary")
				return this.colors.secondary.base;

			if(this.color === "blue")
				return this.colors.blue.base;
		},
		contentClass() {
			if(this.color === "secondary")
				return "frp-menu";

			if(this.color === "blue")
				return "frp-menu frp-blue-menu";
		}
	}
};
</script>

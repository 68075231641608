<template>
	<div class="loan-reports-buttons-group d-flex align-center">
		<v-fade-transition group>
			<span v-if="$slots['download-bulk']" key="download">
				<slot name="download-bulk"></slot>
			</span>
			
			<template v-if="canSendRequestEmailConfirmation">
				<frp-btn key="email"
						 fab
						 color="blue"
						 height="56"
						 :loading="isEmailConfirmationRequesting"
						 :disabled="isItemsLoading || !report?.project?.id"
						 :title="$t('alerts.info.requestEmailByConfirmationUploadReport')"
						 @click="$emit('request-email-confirmation:send')">
					<v-icon color="white">mdi-email</v-icon>
				</frp-btn>
			</template>
			
			<template v-if="canAccept">
				<frp-btn key="action"
						 color="blue"
						 height="48"
						 :loading="isReportAccepting"
						 :disabled="isItemsLoading || !report?.project?.id"
						 class="loan-reports-buttons-group-btn"
						 @click="isAcceptConfirmationDialogOpened = true">
					<v-icon color="white">mdi-check</v-icon>
					<span class="white--text">{{ $t("buttons.acceptReporting") }}</span>
				</frp-btn>
			</template>
			
			<template v-if="canDecline">
				<frp-btn key="action"
						 :color="colors.grey.lighten2"
						 height="48"
						 :loading="isReportDeclining"
						 :disabled="isItemsLoading || !report?.project?.id"
						 class="loan-reports-buttons-group-btn"
						 @click="isDeclineConfirmationDialogOpened = true">
					<v-icon color="primary">mdi-close-circle</v-icon>
					<span class="primary--text">{{ `${$t("common.reportAccepted")} (${acceptor})` }}</span>
				</frp-btn>
			</template>
			
			<template v-if="canCreateReportingQueries">
				<frp-btn key="create-request"
						 fab
						 :color="colors.grey.lighten2"
						 :disabled="isItemsLoading || !report?.project?.id"
						 height="56"
						 :title="$t('alerts.info.createRequest')"
						 @click="$emit('add-query-dialog:open')">
					<v-icon color="primary">mdi-plus-circle-outline</v-icon>
				</frp-btn>
			</template>
			
			<frp-btn key="total"
					 :color="colors.grey.lighten2"
					 height="48"
					 :href="queriesUrl"
					 target="_blank"
					 class="loan-reports-buttons-group-btn"
					 :disabled="isItemsLoading || !report?.project?.id"
					 :title="$t('alerts.info.executedRequests')">
				<v-icon color="secondary">mdi-file-document</v-icon>
				<span class="primary--text">{{ `${queriesOpenedCount} / ${queriesTotalCount}` }}</span>
			</frp-btn>
		</v-fade-transition>
		
		<frp-dialog v-model="isAcceptConfirmationDialogOpened" :title="$t('dialogs.acceptReport.title')">
			<template #content="{ onIntersect }">
						<span v-intersect="onIntersect" class="primary--text text-body-2">
							{{ $t("dialogs.acceptReport.text") }}
						</span>
			</template>
			
			<template #footer>
				<frp-btn outlined
						 @click="isAcceptConfirmationDialogOpened = false"
						 color="primary">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				<frp-btn elevation="0"
						 color="blue"
						 @click="handleAcceptReport">
					<span class="white--text">{{ $t("buttons.accept") }}</span>
				</frp-btn>
			</template>
		</frp-dialog>
		
		<frp-dialog v-model="isDeclineConfirmationDialogOpened" :title="$t('dialogs.declineReport.title')">
			<template #content="{ onIntersect }">
						<span v-intersect="onIntersect" class="primary--text text-body-2">
							{{ $t("dialogs.acceptReport.text") }}
						</span>
			</template>
			
			<template #footer>
				<frp-btn outlined
						 @click="isDeclineConfirmationDialogOpened = false"
						 color="primary">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				<frp-btn elevation="0"
						 color="blue"
						 @click="handleDeclineReport">
					<span class="white--text">{{ $t("buttons.accept") }}</span>
				</frp-btn>
			</template>
		</frp-dialog>
	</div>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import colorsMixin from "@/mixins/colorsMixin";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";

export default {
	mixins: [colorsMixin],
	props: {
		report: {
			type: Object
		},
		isItemsLoading: {
			type: Boolean,
			default: false
		},
		isBulkDownloading: {
			type: Boolean,
			default: false
		},
		isEmailConfirmationRequesting: {
			type: Boolean,
			default: false
		},
		canSendRequestEmailConfirmation: {
			type: Boolean,
			default: false
		},
		canAccept: {
			type: Boolean,
			default: true
		},
		canDecline: {
			type: Boolean,
			default: false
		},
		acceptor: {
			type: String,
			default: ""
		},
		canCreateReportingQueries: {
			type: Boolean,
			default: false
		},
		queriesUrl: {
			type: String,
			required: true
		},
		queriesOpenedCount: {
			type: [String, Number],
			default: 0
		},
		queriesTotalCount: {
			type: [String, Number],
			default: 0
		},
		isReportAccepting: {
			type: Boolean,
			default: false
		},
		isReportDeclining: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			isAcceptConfirmationDialogOpened: false,
			isDeclineConfirmationDialogOpened: false
		};
	},
	methods: {
		handleAcceptReport() {
			this.$emit("report:accept");
			this.isAcceptConfirmationDialogOpened = false;
		},
		handleDeclineReport() {
			this.$emit("report:decline");
			this.isDeclineConfirmationDialogOpened = false;
		}
	},
	components: { FrpDialog, FrpIcon, FrpBtn }
};
</script>

<style scoped lang="scss">
.loan-reports-buttons-group {
  z-index: 10000;
  top: auto;
  right: 8px;
  bottom: 8px;
  position: fixed;
  margin: 8px 8px 50px 8px;

  .v-btn {
	margin: 0 !important;
  }

  .loan-reports-buttons-group-btn {
	border-radius: 24px;

	&.v-btn--disabled {
	  border: 1px solid var(--v-grey-lighten1) !important;
	  box-shadow: none !important;
	  background-color: var(--v-grey-lighten1) !important;
	  color: var(--v-grey-base) !important;
	}
  }
}
</style>

import ApiRefundSource from "./apiRefundSource";

export default class ApiRefundSourceHistory {
	id: string;
	refundSource: ApiRefundSource;
	creator: { id: number, name: string };
	createdAt: string;

	constructor(id: string, refundSource: ApiRefundSource, creator: { id: number, name: string }, createdAt: string) {
		this.id = id;
		this.refundSource = refundSource;
		this.createdAt = createdAt;
		this.creator = creator;
	}
}

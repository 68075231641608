import { addProfile, createMap, createMapper, forMember, mapFrom, MappingProfile } from "@automapper/core";
import { classes } from "@automapper/classes";
import ApiGetTroubledBorrowersParameters from "@/api/loan/types/troubledBorrower/apiGetTroubledBorrowersParameters";
import TroubledBorrowersState from "@/store/loan/modules/troubledBorrowers/types/troubledBorrowersState";

const mapper = createMapper({
	strategyInitializer: classes()
});

const troubledBorrowersProfile: MappingProfile = (mapper) => {
	createMap(mapper, TroubledBorrowersState, ApiGetTroubledBorrowersParameters,
		forMember(d => d.take, mapFrom(x => x.paging.pageSize)),
		forMember(d => d.skip, mapFrom(x => x.paging.pageSize * (x.paging.page - 1))),
		forMember(d => d.companyName, mapFrom(x => x.filter.companyName)),
		forMember(d => d.responsibleUserId, mapFrom(x => x.filter.responsibleUserId))
	);
};

addProfile(mapper, troubledBorrowersProfile);

export default mapper;

import ApiRequirement from "@/api/loan/types/troubledBorrower/apiRequirement";
import StatusHistoryItem from "@/store/loan/modules/troubledBorrower/modules/productionStatus/types/statusHistoryItem";
import DebtorEvent from "@/store/loan/modules/troubledBorrower/modules/productionStatus/types/debtorEvent";
import DebtorStatus from "@/store/loan/modules/troubledBorrower/modules/productionStatus/types/debtorStatus";

export default class Debtor {
	versionId: string;
	debtorId: string;
	currentStatus: DebtorStatus;
	comment: string;
	requirements: ApiRequirement[];
	statusHistory: StatusHistoryItem[];
	events: DebtorEvent[];
	isLegalEntity: boolean;
	projectRoleId: string;
	debtorCompanyName: string;
	debtorCompanyId: number;
	obligedCompanyId: number;
	obligedCompanyName: string;

	constructor(
		versionId: string = "",
		debtorId: string = "",
		currentStatus: DebtorStatus = new DebtorStatus(),
		comment: string = "",
		requirements: ApiRequirement[] = [],
		statusHistory: StatusHistoryItem[] = [],
		events: DebtorEvent[] = [],
		isLegalEntity: boolean = false,
		projectRoleId: string = "",
		debtorCompanyName: string = "",
		debtorCompanyId: number,
		obligedCompanyId: number,
		obligedCompanyName: string
	)
	{
		this.versionId = versionId;
		this.debtorId = debtorId;
		this.currentStatus = currentStatus;
		this.comment = comment;
		this.requirements = requirements;
		this.statusHistory = statusHistory;
		this.events = events;
		this.isLegalEntity = isLegalEntity;
		this.projectRoleId = projectRoleId;
		this.debtorCompanyName = debtorCompanyName;
		this.debtorCompanyId = debtorCompanyId;
		this.obligedCompanyId = obligedCompanyId;
		this.obligedCompanyName = obligedCompanyName;
	}
}

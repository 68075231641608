export default class ApiUser {
	lastName: string;
	firstName: string;
	middleName: string;

	constructor(
		lastName: string = "",
		firstName: string = "",
		middleName: string = ""
	)
	{
		this.lastName = lastName;
		this.firstName = firstName;
		this.middleName = middleName;
	}
}

<template>
	<v-card elevation="0" class="loan-card px-4 pt-4 pb-8 fill-height">
		<v-row>
			<v-col class="d-flex justify-space-between align-center">
				<span class="text-h6 blue--text text--darken-4 mb-2">
					{{ $t("titles.refundSources") }}
				</span>
			</v-col>
		</v-row>

		<v-row class="mt-0" no-gutters>
			<v-col>
				<div v-for="({ projectId}, i) in version.refundSourceInfos" :key="i" class="d-flex flex-column" style="gap: 12px">
					<span class="text-h6 blue--text text--darken-4">
						№ {{ version.loanAgreements.find(x => x.projectId === projectId)?.name }}
					</span>
					<v-data-table :headers="headers"
								  :loading-text="$t('tables.loading')"
								  hide-default-footer
								  item-key="id"
								  fixed-header
								  :items-per-page="-1"
								  :items="getItems(projectId)"
								  :mobile-breakpoint="mobileBreakpoint"
								  :item-class="(item) => `text-body-2 cy-table-row primary--text`"
								  class="loan-table colored-table clickable-rows d-flex flex-column loan-scroll-table">
						<template #item.paymentDate="{ value }">
							<span>{{ formatDate(value, dateFormat) }}</span>
						</template>
						
						<template #item.paymentSum="{ value }">
							<span>{{ formatCurrency(value) }}</span>
						</template>
						
						<template #item.refundSourceTypeId="{ value, index }">
							<span v-if="mode === MainInfoModeType.READ">
								{{ refundSourceTypes.find(x => x.id === value)?.name ?? "Не выбрано" }}
							</span>
							
							<frp-autocomplete dense
											  :items="refundSourceTypes"
											  item-text="name"
											  item-value="id"
											  v-model="editableVersion.refundSourceInfos.find(x => x.projectId === projectId).refundSourceInfos[index].refundSourceTypeId"
											  hide-details
											  v-else>
							</frp-autocomplete>
						</template>
					</v-data-table>
				</div>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
	import { listMixin } from "@/mixins/listMixin";
	import { formatCurrency } from "@/utils/formatting";
	import FrpDateField from "Components/fields/FrpDateField";
	import FrpAutocomplete from "Components/fields/FrpAutocomplete";
	import FrpBtn from "Components/buttons/FrpBtn";
	import FrpIcon from "Components/icon/FrpIcon";
	import FrpDialog from "Components/dialogs/FrpDialog";
	import FrpTextBodyTwo from "Components/typography/FrpTextBodyTwo";
	import { formatDate } from "@/utils/dates";
	import { dateFormat } from "@/utils/formats";
	import { MainInfoModeType } from "Store/loan/modules/troubledBorrower/modules/mainInfo/types/mainInfoModeType";
	import {
		getterTypes,
		mutationTypes
	} from "@/store/loan/modules/troubledBorrower/modules/mainInfo/types";
	import { actionTypes as historyActionTypes } from "@/store/loan/modules/troubledBorrower/types";
	import storeManager from "Store/manager";
	import { createNamespacedHelpers } from "vuex";
	import colorsMixin from "Mixins/colorsMixin";
	import authorizationMixin from "Mixins/authorizationMixin";
	import formMixin from "Mixins/formMixin";

	const namespace = storeManager.loan.troubledBorrower.mainInfo.namespace;
	const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);
	const { mapState: troubledBorrowerMapState, mapGetters: troubledBorrowerMapGetters, mapActions: troubledBorrowerMapActions } = createNamespacedHelpers(storeManager.loan.troubledBorrower.namespace);

	export default {
		mixins: [colorsMixin, authorizationMixin, formMixin, listMixin],
		data() {
			return {
				namespace,
				MainInfoModeType,
				dateFormat,
				formatDate,
				formatCurrency,
				headers: [
					{
						text: this.$t("tables.plannedPaymentDate"),
						value: "paymentDate",
						class: "text-caption",
						width: "33%",
						sortable: false
					},
					{
						text: this.$t("tables.paymentAmount"),
						value: "paymentSum",
						class: "text-caption",
						width: "33%",
						sortable: false
					},
					{
						text: this.$t("tables.refundSourceType"),
						value: "refundSourceTypeId",
						class: "text-caption",
						width: "33%",
						sortable: false
					}
				]
			};
		},
		computed: {
			...troubledBorrowerMapState({
				refundSourceTypes: state => state.refundSourceTypes,
			}),
			...mapState({
				editableVersion: state => state.editableVersion,
				mode: state => state.mode
			}),
			...mapGetters({
				version: getterTypes.version
			})
		},
		methods: {
			getItems(projectId) {
				return this.version.refundSourceInfos.find(x => x.projectId === projectId).refundSourceInfos.map(x => {
					return { ...x, id: `${this.version.refundSourceInfos[0].projectId}_${x.paymentDate}` };
				});
			}
		},
		components: {
			FrpDateField,
			FrpAutocomplete,
			FrpBtn,
			FrpIcon,
			FrpDialog,
			FrpTextBodyTwo
		}
	}
</script>

<style scoped lang="scss">
	.main-block-column {
		& > div {
			height: 48px;
			display: flex;
			align-items: center;
		}

		& > div:nth-child(even) {
			background-color: var(--v-primary-lighten4);
		}
	}

	.main-block-column-left {
		& > div {
			padding-left: 16px;
		}
	}

	.main-block-column-right {
		& > div {
			padding-right: 16px;
		}
	}
</style>

export default class ApiAccruedInterestAddItemsParameters {
	projectId: number;
	accrualTypeId: string;
	financingSourceId: number;
	quarterId: string;
	accrualStartDate: string;
	accrualEndDate: string;
	paymentDate: string;
	accruedAmount: number;

	constructor(
		projectId: number = 0,
		accrualTypeId: string = "",
		financingSourceId: number = 0,
		quarterId: string = "",
		accrualStartDate: string = "",
		accrualEndDate: string = "",
		paymentDate: string = "",
		accruedAmount: number = 0
	)
	{
		this.projectId = projectId;
		this.accrualTypeId = accrualTypeId;
		this.financingSourceId = financingSourceId;
		this.quarterId = quarterId;
		this.accrualStartDate = accrualStartDate;
		this.accrualEndDate = accrualEndDate;
		this.paymentDate = paymentDate;
		this.accruedAmount = accruedAmount;
	}
}

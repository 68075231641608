export default class ApiGetAccruedInterestParameters {
	take: number;
	skip: number;
	accrualStartDate: string;
	accrualEndDate: string;
	accrualTypeId: string;
	paymentStartDate: string;
	paymentEndDate: string;
	accountedStartDate: string;
	accountedEndDate: string;
	financingSourceId: number | string;
	quarterId: string;

	constructor(
		take: number,
		skip: number,
		accrualStartDate: string,
		accrualEndDate: string,
		accrualTypeId: string,
		paymentStartDate: string,
		paymentEndDate: string,
		accountedStartDate: string,
		accountedEndDate: string,
		financingSourceId: number | string,
		quarterId: string
	)
	{
		this.take = take;
		this.skip = skip;
		this.accrualStartDate = accrualStartDate;
		this.accrualEndDate = accrualEndDate;
		this.accrualTypeId = accrualTypeId;
		this.paymentStartDate = paymentStartDate;
		this.paymentEndDate = paymentEndDate;
		this.accountedStartDate = accountedStartDate;
		this.accountedEndDate = accountedEndDate;
		this.financingSourceId = financingSourceId;
		this.quarterId = quarterId;
	}
}

import IPageState from "@/store/shared/base/types/pageState";
import ListingModel from "@/store/shared/listing/models/listingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import FormState from "@/store/shared/form/models/formState";
import RouteState from "@/store/shared/route/types/routeState";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import AssignmentCompensationFilter from "@/store/loan/modules/assignmentCompensation/types/assignmentCompensationFilter";
import { AssigneeCompany } from "@/store/loan/modules/assignmentCompensation/types/assigneeCompany";
import { AssignmentCompensationProject } from "@/store/loan/modules/assignmentCompensation/types/assignmentCompensationProject";
import { AssignmentAgreement } from "@/store/loan/modules/assignmentCompensation/types/assignmentAgreement";
import { Quarter } from "@/store/loan/modules/loanSchedule/types/quarter";
import {
	AssignmentCompensationInterest,
	AssignmentCompensationInterestHelper
} from "@/store/loan/modules/assignmentCompensation/types/assignmentCompensationInterest";

export default class AssignmentCompensationState implements IPageState {
	constructor(
		public listing: ListingModel<AssignmentCompensationProject>,
		public sorting: SortingModel<String[]>,
		public paging: PagingModel,
		public search: SearchModel,
		public form: FormState,
		public route: RouteState,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isDictionariesLoading: boolean = false,
		public filter: AssignmentCompensationFilter = new AssignmentCompensationFilter(),
		public lastQuarter: Quarter = {} as Quarter,
		public assignmentAgreements: AssignmentAgreement[] = [],
		public assigneeCompanies: AssigneeCompany[] = [],
		public newInterestItem: AssignmentCompensationInterest = AssignmentCompensationInterestHelper.getEmpty(),
		public isAssignmentCompensationCalculating: boolean = false,
		public isOrderForSubmissionToAccountingCreating: boolean = false,
		public isOrderDocumentToAccountingForApproveSending: boolean = false,
		public isReportApprovingHasError: boolean = false,
		public orderNumberForSubmissionToAccounting?: number
	)
	{
	}
}

export default class ApiRefundsTotal {
	constructor(
		public factualRefundPercent: number = 0,
		public factualRefundAbsolute: number = 0,
		public withoutAcceptance: number = 0,
		public guarantee: number = 0,
		public court: number = 0,
		public bankruptcy: number = 0,
		public settlement: number = 0,
		public cession: number = 0,
		public refundLeftover: number = 0
	)
	{
	}
}

import IPageState from "@/store/shared/base/types/pageState";
import ApiTroubledBorrowerVersionHeader from "@/api/loan/types/troubledBorrower/apiTroubledBorrowerVersionHeader";
import ApiCompany from "@/api/loan/types/troubledBorrower/apiCompany";
import ApiOrganization from "@/api/loan/types/dictionaries/apiOrganization";
import ApiEventType from "@/api/loan/types/dictionaries/apiEventType";
import ApiRequirementType from "@/api/loan/types/dictionaries/apiRequirementType";
import ApiAssessmentType from "@/api/loan/types/dictionaries/apiAssessmentType";
import ApiSecurityKindType from "@/api/loan/types/dictionaries/apiSecurityKindType";
import ApiProjectManager from "@/api/loan/types/dictionaries/apiProjectManager";
import ApiDebtorStageType from "@/api/loan/types/dictionaries/apiDebtorStageType";
import ApiRefundStatusTypes from "@/api/loan/types/dictionaries/apiRefundStatusTypes";
import ApiJudicialDecisionType from "@/api/loan/types/dictionaries/apiJudicialDecisionType";
import TroubledBorrowerVersion from "@/store/loan/modules/troubledBorrower/types/troubledBorrowerVersion";
import FormState from "@/store/shared/form/models/formState";
import ApiProjectRoleType from "@/api/loan/types/dictionaries/apiProjectRoleType";
import ApiRefundSourceType from "@/api/loan/types/dictionaries/apiRefundSourceType";
import RefundSourceHistory from "@/store/loan/modules/troubledBorrower/types/refundSourceHistory";
import IncomePredictionHistory from "@/store/loan/modules/troubledBorrower/types/incomePredictionHistory";
import RefundStatusTypeHistory from "@/store/loan/modules/troubledBorrower/types/refundStatusTypeHistory";
import ApiProblemDebtDepartment from "@/api/loan/types/dictionaries/apiProblemDebtDepartment";
import ApiCostType from "@/api/loan/types/dictionaries/apiCostType";
import ApiDictionaryItem from "@/api/loan/types/dictionaries/apiDictionaryItem";

export default class TroubledBorrowerState implements IPageState {
	constructor(
		public form: FormState,
		public snapshot: object,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public version: TroubledBorrowerVersion = new TroubledBorrowerVersion(),
		public versionHeaders: ApiTroubledBorrowerVersionHeader[] = [],
		public refundSourceHistory: RefundSourceHistory[] = [],
		public company: ApiCompany = new ApiCompany(),
		public organizations: ApiOrganization[] = [],
		public obligedCompanies: ApiOrganization[] = [],
		public isOrganizationsLoading: boolean = false,
		public isObligedCompaniesLoading: boolean = false,
		public eventTypes: ApiEventType[] = [],
		public requirementTypes: ApiRequirementType[] = [],
		public assessmentTypes: ApiAssessmentType[] = [],
		public securityKindTypes: ApiSecurityKindType[] = [],
		public projectManagers: ApiProjectManager[] = [],
		public problemDebtDepartments: ApiProblemDebtDepartment[] = [],
		public debtorStageTypes: ApiDebtorStageType[] = [],
		public debtorStageStatusTypes: ApiDictionaryItem[] = [],
		public judicialDecisionTypes: ApiJudicialDecisionType[] = [],
		public refundStatusTypes: ApiRefundStatusTypes[] = [],
		public projectRoleTypes: ApiProjectRoleType[] = [],
		public isVersionHeadersLoading: boolean = false,
		public isVersionLoading: boolean = false,
		public isDictionariesLoading: boolean = false,
		public versionName: string = "",
		public refundSourceTypes: ApiRefundSourceType[] = [],
		public incomePredictionHistory: IncomePredictionHistory[] = [],
		public refundStatusTypeHistory: RefundStatusTypeHistory[] = [],
		public costTypes: ApiCostType[] = [],
	)
	{
	}
}

<template>
	<loan-content-layout>
		<v-row>
			<v-col class="pb-2 mt-3 d-flex align-center">
				<frp-text-loader class="mr-8" width="335"/>
				<frp-text-loader width="170"/>
				<v-spacer></v-spacer>
				<frp-text-loader width="255"/>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="pb-4">
				<v-card elevation="0" class="loan-card px-4 py-6">
					<v-row class="px-3 blue--text text--darken-4"
						   :style="$vuetify.breakpoint.mdAndUp ? 'display: flex; align-items: center' : 'display: flex; flex-direction: column'">
						<v-col cols="auto" class="pa-0 mr-10" style="margin-top: 17px; margin-bottom: 17px">
							<frp-text-loader width="210" height="18"/>
						</v-col>

						<v-col cols="auto" class="d-flex align-center mr-10 px-0">
							<frp-text-loader width="270"/>
						</v-col>

						<v-col class="d-flex align-center px-0">
							<frp-text-loader width="130" class="mr-2"/>
							<frp-text-loader width="280"/>
						</v-col>
						<v-spacer></v-spacer>
						<frp-btn-loader width="170" height="40"/>
					</v-row>
				</v-card>
			</v-col>
		</v-row>

		<v-row>
			<v-col class="pt-0 pb-4">
				<loan-main-info-loader/>
			</v-col>
		</v-row>

		<v-row>
			<v-col class="pt-0 pb-4">
				<loan-security-agreements-loader/>
			</v-col>
		</v-row>

		<v-row>
			<v-col class="pb-6">
				<loan-production-status-loader/>
			</v-col>
		</v-row>
	</loan-content-layout>
</template>

<script>
import LoanProductionStatusLoader from "@/views/loan/troubledBorrower/productionStatus/LoanProductionStatusLoader.vue";
import LoanSecurityAgreementsLoader from "@/views/loan/troubledBorrower/securityAgreements/LoanSecurityAgreementsLoader.vue";
import LoanContentLayout from "Components/layouts/LoanContentLayout";
import FrpCustomTitleLoader from "Components/loaders/common/FrpCustomTitleLoader";
import FrpTextLoader from "Components/loaders/common/FrpTextLoader";
import FrpBtnLoader from "Components/loaders/form/FrpBtnLoader";
import LoanMainInfoLoader from "Views/loan/troubledBorrower/mainInfo/LoanMainInfoLoader";

export default {
	components: {
		LoanProductionStatusLoader,
		LoanSecurityAgreementsLoader,
		FrpTextLoader,
		FrpCustomTitleLoader,
		LoanContentLayout,
		LoanMainInfoLoader,
		FrpBtnLoader
	}
};
</script>

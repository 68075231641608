<template>
	<v-container class="px-0">
		<template v-if="this.$slots.title">
			<v-row>
				<v-col>
					<span class="text-body-1 blue--text text--darken-4 font-weight-bold">
						&#xab;<slot name="title">{{ title }}</slot>&#xbb;
					</span>
				</v-col>
			</v-row>
		</template>
		<slot name="content"></slot>
	</v-container>
</template>

<script>
export default {
	props: {
		title: String
	}
};
</script>

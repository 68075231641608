<template>
	<loan-content-layout>
		<v-row>
			<v-col class="d-flex flex-column" style="gap: 24px">
				<v-card elevation="0" class="loan-card px-2 pt-7" style="padding-bottom: 29px">
					<v-row class="align-center">
						<v-col class="py-0 align-self-start pt-1 pl-9">
							<div class="d-flex flex-column">
								<frp-custom-title-loader width="60" height="10" class="mb-2"/>
								<frp-btn-loader height="40" width="230"/>
							</div>
						</v-col>
						<v-col>
							<v-card-actions>
								<div class="d-flex justify-md-end" style="width: 100%">
									<frp-btn-loader height="40" width="180" classes="mr-4"/>
									<frp-btn-loader height="40" width="200"/>
								</div>
							</v-card-actions>
						</v-col>
					</v-row>
				</v-card>
				<v-row>
					<v-col cols="4" xl="4" md="5" sm="6" class="px-0 pb-0">
						<loan-card-layout>
							<template #title>
								<frp-custom-title-loader width="180" height="14" class="mt-1" style="margin-bottom: 6px"/>
							</template>

							<template #content>
								<v-card class="mt-1 loan-card fill-height pt-3 px-4" elevation="0" style="padding-bottom: 13px">
									<div class="d-flex justify-space-between py-3 px-3">
										<v-skeleton-loader type="text" width="120" class="mt-1"/>
										<v-skeleton-loader type="text" width="20" class="mt-1"/>
									</div>
									<div class="d-flex justify-space-between py-3 px-3">
										<v-skeleton-loader type="text" width="120" class="mt-1"/>
										<v-skeleton-loader type="text" width="150" class="mt-1"/>
									</div>
									<div class="d-flex justify-space-between py-3 px-3">
										<v-skeleton-loader type="text" width="120" class="mt-1"/>
										<v-skeleton-loader type="text" width="110" class="mt-1"/>
									</div>
								</v-card>
							</template>
						</loan-card-layout>
					</v-col>
					<v-col cols="3" xl="3" md="5" sm="6" class="px-0 pb-0">
						<loan-card-layout class="fill-height">
							<template #title>
								<frp-custom-title-loader width="80" height="14" class="mt-1" style="margin-bottom: 6px"/>
							</template>

							<template #content>
								<v-card class="mt-1 loan-card fill-height py-3 px-4" elevation="0">
									<div class="d-flex justify-space-between py-3 px-3">
										<v-skeleton-loader type="text" width="140" class="mt-1"/>
										<v-skeleton-loader type="text" width="130" class="mt-1"/>
									</div>
									<div class="d-flex justify-space-between py-3 px-3">
										<v-skeleton-loader type="text" width="160" class="mt-1"/>
										<v-skeleton-loader type="text" width="20" class="mt-1"/>
									</div>
								</v-card>
							</template>
						</loan-card-layout>
					</v-col>
				</v-row>

				<v-row class="mt-0">
					<v-col class="pt-0">
						<div class="d-flex flex-column">
							<v-card elevation="0" class="pt-4 px-4 pb-1 loan-card">
								<v-row>
									<v-col cols="4" xl="4" md="5" sm="6">
										<div class="d-flex flex-column justify-space-between px-2 pb-3 fill-height">
											<div>
												<div class="d-flex justify-space-between">
													<div class="d-flex flex-column text-no-wrap">
														<v-skeleton-loader type="image" width="220" height="18"/>
														<v-skeleton-loader type="image" width="140" height="14" class="mt-3"/>
													</div>
													<div>
														<v-skeleton-loader type="image" width="100" height="14" class="mt-1"/>
													</div>
												</div>
												<v-row class="mt-0" v-for="item in 4" :key="item">
													<v-col cols="4" class="pt-4">
														<v-skeleton-loader type="text" width="60" class="mt-1"/>
													</v-col>
													<v-col class="pt-4">
														<v-skeleton-loader type="text" width="120" class="mt-1"/>
													</v-col>
												</v-row>
											</div>
											<div class="mt-4 d-flex">
												<v-spacer></v-spacer>
												<frp-btn-loader height="40" width="250"/>
											</div>
										</div>
									</v-col>

									<v-col>
										<div class="d-flex flex-column justify-space-between fill-height">
											<div class="d-flex flex-column mb-3" style="grid-gap: 20px">
												<div class="d-flex align-center pl-1 mb-7">
													<v-skeleton-loader type="image" width="180" height="14" class="mt-1"/>
												</div>
												<v-row class="align-center">
													<v-col class="d-flex flex-column pb-1 pt-2" v-for="(item, i) in 4" :key="i">
														<v-skeleton-loader type="text" max-width="80"/>
													</v-col>
													<div style="width: 40px;"
														 class="d-flex flex-column pb-1 pt-2 mr-1">
													</div>
													<div style="width: 40px;"
														 class="d-flex flex-column pb-1 pt-2 mr-4">
													</div>
												</v-row>
												<v-divider class="mb-3"></v-divider>
												<v-row class="pb-3" v-for="(item, i) in 4" :key="i">
													<v-col class="d-flex flex-column py-0">
														<v-skeleton-loader type="text" max-width="80"/>
													</v-col>
													<v-col class="d-flex flex-column py-0">
														<v-skeleton-loader type="text" max-width="80"/>
													</v-col>
													<v-col class="d-flex flex-column py-0">
														<v-skeleton-loader type="text" max-width="110"/>
													</v-col>
													<v-col class="d-flex flex-column py-0">
														<v-skeleton-loader type="text" max-width="80"/>
													</v-col>
													<div style="width: 40px;" class="d-flex flex-column px-3 mr-1">
														<v-skeleton-loader type="text" max-width="20"/>
													</div>
													<div style="width: 40px;" class="d-flex flex-column px-3 mr-4">
														<v-skeleton-loader type="text" max-width="20"/>
													</div>
												</v-row>
											</div>
											<div class="d-flex justify-end mx-n4 py-2">
												<frp-btn-loader class="mr-4" width="170"/>
											</div>
										</div>
									</v-col>
								</v-row>
							</v-card>
						</div>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</loan-content-layout>
</template>

<script>
import LoanCardLayout from "Components/layouts/LoanCardLayout";
import LoanContentLayout from "Components/layouts/LoanContentLayout";
import FrpCustomTitleLoader from "Components/loaders/common/FrpCustomTitleLoader";
import FrpBtnLoader from "Components/loaders/form/FrpBtnLoader";

export default {
	components: { LoanCardLayout, FrpBtnLoader, FrpCustomTitleLoader, LoanContentLayout }
};
</script>

<template>
	<loan-content-layout>
		<v-row>
			<v-col class="d-flex flex-column pt-4" style="gap: 24px">
				<v-card elevation="0" class="loan-card px-2 py-6">
					<v-row class="align-center">
						<v-col class="py-0">
							<div class="pl-4">
								<frp-custom-title-loader width="300"/>
								<frp-custom-title-loader height="14" class="mt-2"/>
							</div>
						</v-col>
						<v-col class="py-0">
							<v-card-actions class="px-4">
								<div class="d-flex justify-md-end" style="width: 100%">
									<frp-btn-loader class="mr-4" height="40"/>
									<frp-btn-loader width="250" class="mr-4" height="40"/>
									<frp-btn-loader width="250" height="40"/>
								</div>
							</v-card-actions>
						</v-col>
					</v-row>
				</v-card>

				<v-card elevation="0" class="loan-card px-6 py-6 d-flex flex-column">
					<v-row>
						<v-col class="d-flex justify-center align-end" style="gap: 24px; margin-top: 2px">
							<div class="d-flex" style="gap: 24px; width: 100%">
								<div style="width: 100%" v-for="i in 3" :key="i">
									<frp-custom-title-loader height="12" width="100" class="mb-2"/>
									<frp-btn-loader style="width: 100%" height="40"/>
								</div>
							</div>
							<div class="d-flex" style="gap: 24px; width: 100%">
								<frp-btn-loader style="width: 100%" height="40" v-for="i in 3" :key="i"/>
							</div>

							<v-spacer></v-spacer>

							<div class="align-self-end d-flex">
								<frp-btn-loader width="130" height="40" class="ml-4" v-for="i in 2" :key="i"/>
							</div>
						</v-col>
					</v-row>

					<v-row class="mt-6">
						<v-col class="d-flex flex-column" v-for="(item, index) in 8" :key="index">
							<v-skeleton-loader type="text" max-width="100"/>
						</v-col>
						<div style="width: 200px;" class="d-flex flex-column px-3">
						</div>
						<div style="width: 40px;" class="d-flex flex-column px-3">
						</div>
						<div style="width: 40px;" class="d-flex flex-column px-3 mr-8">
						</div>
					</v-row>
					<v-divider class="mt-3"></v-divider>
					<div class="table-header mb-8 d-flex align-center justify-end">
						<frp-btn-loader width="260" height="32" class="mr-4" />
					</div>

					<v-row style="margin-bottom: 18px" v-for="i in 5" :key="i">
						<v-col class="d-flex flex-column py-0">
							<v-skeleton-loader type="text" max-width="150"/>
						</v-col>
						<v-col class="d-flex flex-column py-0">
							<v-skeleton-loader type="text" max-width="150"/>
						</v-col>
						<v-col class="d-flex flex-column py-0">
							<v-skeleton-loader type="text" max-width="80"/>
						</v-col>
						<v-col class="d-flex flex-column py-0">
							<v-skeleton-loader type="text" max-width="80"/>
						</v-col>
						<v-col class="d-flex flex-column py-0">
							<v-skeleton-loader type="text" max-width="80"/>
						</v-col>
						<v-col class="d-flex flex-column py-0">
							<v-skeleton-loader type="text" max-width="80"/>
						</v-col>
						<v-col class="d-flex flex-column py-0">
							<v-skeleton-loader type="text" max-width="80"/>
						</v-col>
						<v-col class="d-flex flex-column py-0">
							<v-skeleton-loader type="text" max-width="180"/>
						</v-col>
						<div style="width: 200px;" class="d-flex flex-column px-3">
						</div>
						<div style="width: 40px;" class="d-flex flex-column px-3">
							<v-skeleton-loader type="text" max-width="20"/>
						</div>
						<div style="width: 40px;" class="d-flex flex-column px-3 mr-8">
							<v-skeleton-loader type="text" max-width="20"/>
						</div>
					</v-row>
					<v-row style="padding-bottom: 22px">
						<v-col class="d-flex flex-column py-0" v-for="i in 5" :key="i">
						</v-col>
						<v-col class="d-flex flex-column py-0">
							<v-skeleton-loader type="text" max-width="80"/>
						</v-col>
						<v-col class="d-flex flex-column py-0">
							<v-skeleton-loader type="text" max-width="120"/>
						</v-col>
						<v-col class="d-flex flex-column py-0">
						</v-col>
						<div style="width: 200px;" class="d-flex flex-column px-3">
						</div>
						<div style="width: 40px;" class="d-flex flex-column px-3">
						</div>
						<div style="width: 40px;" class="d-flex flex-column px-3 mr-8">
						</div>
					</v-row>
				</v-card>
			</v-col>
		</v-row>
	</loan-content-layout>
</template>

<script>
import LoanContentLayout from "Components/layouts/LoanContentLayout";
import FrpCustomTitleLoader from "Components/loaders/common/FrpCustomTitleLoader";
import FrpBtnLoader from "Components/loaders/form/FrpBtnLoader";
import FrpInputLoader from "Components/loaders/form/FrpInputLoader";

export default {
	components: { LoanContentLayout, FrpInputLoader, FrpCustomTitleLoader, FrpBtnLoader }
};
</script>
<style lang="scss">
.table-header {
	height: 48px;
	width: 100%;
	background-color: var(--v-grey-darken4) !important;
}
</style>

export default class ApiVersionHeader {
	id: string;
	number: number;
	title: string;
	isActive: boolean;

	constructor(
		id: string = "",
		number: number = 0,
		title: string = "",
		isActive: boolean = false
	)
	{
		this.id = id;
		this.number = number;
		this.title = title;
		this.isActive = isActive;
	}
}

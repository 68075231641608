<template>
	<v-container fluid class="fill-height">
		<v-row class="my-0 fill-height">
			<v-col class="pa-0">
				<v-card class="loan-card pa-4 d-flex flex-column"
						style="box-shadow: 3px 6px 20px rgba(0, 0, 0, 0.15); min-height: 100%; height: fit-content"
						:style="cardStyle">
					<v-row v-if="title || $slots['title'] || $slots['title-append']" class="ma-0 flex-grow-0">
						<v-col class="d-flex align-center pa-0">
							<slot name="title">
								<span style="word-break: initial"
									  class="mr-1 text-body-1 font-weight-bold">
									{{ title }}
								</span>
							</slot>
							<v-spacer></v-spacer>
							<slot name="title-append"></slot>
						</v-col>
					</v-row>
					<v-row class="fill-height ma-0">
						<v-col class="px-0 pb-0">
							<slot></slot>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	props: {
		title: String,
		cardStyle: String
	}
};
</script>

<style scoped>

</style>

<template>
	<v-container fluid v-if="isInitialized">
		<v-row class="px-4">
			<v-col class="px-0">
				<loan-overdue-interest-list/>
			</v-col>
			<v-col class="px-0">
				<loan-penalty-interest-list/>
			</v-col>
		</v-row>
	</v-container>
	<loan-overdue-interest-loader v-else/>
</template>

<script>
import { i18n } from "Plugins/index";
import LoanOverdueInterestLoader from "Views/loan/overdueInterest/LoanOverdueInterestLoader";
import LoanOverdueInterestList from "Views/loan/overdueInterest/overdueInterestList/LoanOverdueInterestList";
import LoanPenaltyInterestList from "Views/loan/overdueInterest/penaltyInterestList/LoanPenaltyInterestList";
import { namespace, getterTypes, mutationTypes, actionTypes } from "Store/loan/modules/overdueInterest/types";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	metaInfo: {
		title: i18n.t("metaTitles.overdueInterest")
	},
	data() {
		return {};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized
		})
	},
	methods: {
		...mapActions({
			initializeStore: actionTypes.initialize
		})
	},
	created() {
		this.initializeStore();
	},
	components: {
		LoanOverdueInterestLoader,
		LoanOverdueInterestList,
		LoanPenaltyInterestList
	}
};
</script>

import ApiTreasuryStatementPayment from "@/api/loan/types/paymentsProcessing/apiTreasuryStatementPayment";

export default class TreasuryStatementRecord {
	id: string;
	number: number;
	fileId: string;
	typeDoc: number;
	datePayment: number;
	numberDoc: string;
	dateDoc: number;
	kbk: string;
	kSubs: string;
	oktmo: string;
	payment: number;
	payerBIK: string;
	payerBank: string;
	payerAccNumber: string;
	payerINN: string;
	payerKPP: string;
	payerName: string;
	payeeBIK: string;
	payeeBank: string;
	payeeAccNumber: string;
	payeeINN: string;
	payeeKPP: string;
	payeeName: string;
	paymentPurpose: string;
	projectId: number;
	projectStatus: string;
	isFinal: boolean;
	payments: ApiTreasuryStatementPayment[];

	constructor(
		id: string = "",
		number: number = 0,
		fileId: string = "",
		typeDoc: number = 0,
		datePayment: number = 0,
		numberDoc: string = "",
		dateDoc: number = 0,
		kbk: string = "",
		kSubs: string = "",
		oktmo: string = "",
		payment: number = 0,
		payerBIK: string = "",
		payerBank: string = "",
		payerAccNumber: string = "",
		payerINN: string = "",
		payerKPP: string = "",
		payerName: string = "",
		payeeBIK: string = "",
		payeeBank: string = "",
		payeeAccNumber: string = "",
		payeeINN: string = "",
		payeeKPP: string = "",
		payeeName: string = "",
		paymentPurpose: string = "",
		projectId: number = 0,
		projectStatus: string = "",
		isFinal: boolean = false,
		payments: ApiTreasuryStatementPayment[] = []
	)
	{
		this.id = id;
		this.number = number;
		this.fileId = fileId;
		this.typeDoc = typeDoc;
		this.datePayment = datePayment;
		this.numberDoc = numberDoc;
		this.dateDoc = dateDoc;
		this.kbk = kbk;
		this.kSubs = kSubs;
		this.oktmo = oktmo;
		this.payment = payment;
		this.payerBIK = payerBIK;
		this.payerBank = payerBank;
		this.payerAccNumber = payerAccNumber;
		this.payerINN = payerINN;
		this.payerKPP = payerKPP;
		this.payerName = payerName;
		this.payeeBIK = payeeBIK;
		this.payeeBank = payeeBank;
		this.payeeAccNumber = payeeAccNumber;
		this.payeeINN = payeeINN;
		this.payeeKPP = payeeKPP;
		this.payeeName = payeeName;
		this.paymentPurpose = paymentPurpose;
		this.projectId = projectId;
		this.projectStatus = projectStatus;
		this.isFinal = isFinal;
		this.payments = payments;
	}
}

<template>
	<v-tooltip bottom v-if="icon">
		<template v-slot:activator="{ on, attrs }">
			<frp-icon v-bind="attrs" v-on="on" :src="icon" height="20" width="20" :class="targetClass"></frp-icon>
		</template>
		
		<span>{{ tooltip }}</span>
	</v-tooltip>
</template>

<script>
import { ApiTroubledBorrowerStageStatusEnum } from "@/api/loan/types/troubledBorrower/ApiTroubledBorrowerStageStatusEnum";
import FrpIcon from "@/components/icon/FrpIcon.vue";

export default {
	props: {
		value: String,
		targetClass: String
	},
	computed: {
		tooltip() {
			return this.$t(`aliases.troubledBorrowerStageStatus.${this.value}`);
		},
		icon() {
			switch (this.value) {
				case ApiTroubledBorrowerStageStatusEnum.COMPLETED:
					return "ico_debtor-stage-status-completed";
				case ApiTroubledBorrowerStageStatusEnum.COMPLETED_OUT_OF_TIME:
					return "ico_debtor-stage-status-completed-out-of-time";
				case ApiTroubledBorrowerStageStatusEnum.IN_PROGRESS:
					return "ico_debtor-stage-status-in-progress";
				case ApiTroubledBorrowerStageStatusEnum.UNCONFIRMED:
					return "ico_debtor-stage-status-unconfirmed";
				case ApiTroubledBorrowerStageStatusEnum.IN_PROGRESS_OUT_OF_TIME:
					return "ico_debtor-stage-status-in-progress-out-of-time";
				case ApiTroubledBorrowerStageStatusEnum.NOT_COMPLETED:
					return "ico_debtor-stage-status-not-completed";
				case ApiTroubledBorrowerStageStatusEnum.NOT_APPLICABLE:
					return "ico_debtor-stage-status-not-applicable";
				case ApiTroubledBorrowerStageStatusEnum.COURT_TERMINATED:
					return "ico_debtor-stage-status-court-terminated";
				default:
					return null;
			}
		}
	},
	components: {
		FrpIcon
	}
};
</script>

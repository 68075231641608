export default class AgreementsAccruedInterestFilter {
	periodId: string;
	applicationNumber: string;
	companyName: string;
	agreementStatusId: string;
	daysMin: string;
	daysMax: string;

	constructor(
		periodId: string = "",
		applicationNumber: string = "",
		companyName: string = "",
		agreementStatusId: string = "",
		daysMin: string = "",
		daysMax: string = ""
	)
	{
		this.periodId = periodId;
		this.applicationNumber = applicationNumber;
		this.companyName = companyName;
		this.agreementStatusId = agreementStatusId;
		this.daysMin = daysMin;
		this.daysMax = daysMax;
	}
}

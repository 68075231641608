import RefundSource from "./refundSource";

export default class RefundSourceHistory {
	id: string;
	refundSource: RefundSource;
	createdBy: string;
	createdAt: number;

	constructor(id: string, refundSource: RefundSource, createdBy: string, createdAt: number) {
		this.id = id;
		this.refundSource = refundSource;
		this.createdAt = createdAt;
		this.createdBy = createdBy;
	}
}

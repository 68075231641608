export default class ApiReportQueries {
	totalCount: number;
	openedCount: number;

	constructor(
		totalCount: number = 0,
		openedCount: number = 0
	)
	{
		this.totalCount = totalCount;
		this.openedCount = openedCount;
	}
}

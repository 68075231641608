<template>
	<v-card elevation="0" color="loan-card">
		<v-row>
			<v-col class="d-flex justify-space-between align-center mx-4 mb-1">
				<frp-text-loader width="420" class="mt-2"/>
				<frp-btn-loader height="40"/>
			</v-col>
		</v-row>

		<v-row class="mx-2">
			<v-col cols="12" md="6" v-for="i in 2" :key="`a-${i}`">
				<v-card elevation="0" color="loan-card" class="mx-2">
					<v-row>
						<v-col class="d-flex justify-space-between align-center px-0" style="height: 48px;">
							<frp-text-loader width="210"/>
							<frp-text-loader width="70"/>
						</v-col>
					</v-row>

					<v-row>
						<v-col class="px-4 py-5 white darken-2 d-flex justify-space-between align-center">
							<frp-text-loader width="70"/>
							<div class="d-flex">
								<frp-text-loader width="20" class="mr-6"/>
								<frp-text-loader width="20" class="mr-2"/>
							</div>
						</v-col>
					</v-row>

					<v-row align="center" class="px-2 mt-4">
						<v-col cols="5">
							<div class="d-flex align-center flex-wrap">
								<frp-text-loader width="80" height="12" class="mr-6"/>
								<frp-btn-loader height="32" width="120"/>
							</div>
						</v-col>
						<v-col cols="3">
							<div class="d-flex align-center">
								<frp-text-loader width="110" height="12"/>
							</div>
						</v-col>
						<v-col cols="2">
							<div class="d-flex align-center">
								<frp-text-loader width="110" height="12"/>
							</div>
						</v-col>
						<v-col>
							<div class="d-flex align-center justify-end">
								<div style="width: 20px;" class="mr-6"></div>
								<div style="width: 20px;" class="mr-1"></div>
							</div>
						</v-col>
					</v-row>
					<v-divider class="mt-2"></v-divider>
					<v-row align="center" class="px-2 mt-1" v-for="i in 2" :key="i" style="height: 52px;">
						<v-col cols="5">
							<frp-text-loader width="110" height="12"/>
						</v-col>
						<v-col cols="3">
							<frp-text-loader width="90" height="12"/>
						</v-col>
						<v-col cols="2">
							<frp-text-loader width="90" height="12"/>
						</v-col>
						<v-col>
							<div class="d-flex align-center justify-end">
								<frp-text-loader width="20" class="mr-6"/>
								<frp-text-loader width="20" class="mr-1"/>
							</div>
						</v-col>
					</v-row>
					<v-row class="px-2 mt-2">
						<v-col cols="3">
							<frp-btn-loader height="32" width="240"/>
						</v-col>
					</v-row>
					<v-row class="pt-12">
						<v-col class="d-flex justify-space-between align-center pl-5" style="height: 48px; padding-right: 68px">
							<frp-text-loader width="250"/>
							<div class="d-flex align-center">
								<frp-text-loader width="200" class="mr-4"/>
								<frp-text-loader width="20"/>
							</div>
						</v-col>
					</v-row>
					<v-row>
						<v-col class="px-5 pt-2">
							<div class="d-flex align-center">
								<frp-text-loader width="140" class="mr-6"/>
								<frp-btn-loader height="32" width="120"/>
							</div>
						</v-col>
					</v-row>
					<v-row align="center" class="px-2 mt-3" style="padding-bottom: 18px">
						<v-col cols="5">
							<frp-text-loader width="160" height="12"/>
						</v-col>
						<v-col cols="3">
							<div style="max-width: 90px"></div>
						</v-col>
						<v-col cols="2">
							<frp-text-loader width="90" height="12"/>
						</v-col>
						<v-col>
							<div class="d-flex align-center justify-end">
								<frp-text-loader width="20" class="mr-6"/>
								<frp-text-loader width="20" class="mr-1"/>
							</div>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
		</v-row>

	</v-card>
</template>

<script>
import FrpCustomTitleLoader from "@/components/loaders/common/FrpCustomTitleLoader.vue";
import FrpTextLoader from "@/components/loaders/common/FrpTextLoader.vue";
import FrpBtnLoader from "@/components/loaders/form/FrpBtnLoader.vue";

export default {
	components: { FrpTextLoader, FrpCustomTitleLoader, FrpBtnLoader }
};
</script>

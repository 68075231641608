<template>
	<v-card v-if="isInitialized && !isVersionLoading" elevation="0" color="loan-card">
		<v-row>
			<v-col class="d-flex justify-space-between align-center">
				<v-card-title class="text-h6 pb-2">{{ $t("titles.productionStatus") }}</v-card-title>
				<frp-btn v-if="can(Permissions.LOAN_CREATE)"
						 :color="colors.blue.base"
						 @click="isDebtorDialogOpened = true"
						 elevation="0"
						 class="mr-4">
					<span class="white--text">{{ $t("buttons.addDebtor") }}</span>
				</frp-btn>
			</v-col>
		</v-row>

		<v-row v-if="!items.length">
			<v-col class="d-flex align-center flex-column py-16">
				<span class="text-body-2 grey--text">{{ $t("content.noDebtors") }}</span>
			</v-col>
		</v-row>

		<v-row class="mx-2" v-else>
			<v-col cols="12" md="6" v-for="debtor in items" :key="debtor.debtorId">
				<loan-production-status-debtor :debtor="debtor"></loan-production-status-debtor>
			</v-col>
		</v-row>

		<frp-dialog v-model="isDebtorDialogOpened" :title="$t('dialogs.addDebtor.title')" persistent>
			<template #content>
				<v-form :ref="refs.form" v-model="isDebtorFormValid">
					<frp-autocomplete :label="$t('fields.organization.label')"
									  v-model="createDebtorRequestCompanyId"
									  :min-query-length="MIN_ORGANIZATIONS_QUERY_LENGTH"
									  :items="organizations"
									  @update:search-input="getOrganizations"
									  item-value="id"
									  item-text="name"
									  required
									  :loading="isOrganizationsLoading"
									  :placeholder="$t('fields.organization.placeholder')">
					</frp-autocomplete>

					<div class="d-flex align-center justify-end">
						<span class="text-caption blue--text text--darken-4 mt-1">{{ $t("common.counterpartyIsMissing") }}</span>
						<frp-btn class="text-none"
								 :href="addCounterpartyUrl"
								 text
								 plain
								 :color="colors.blue.base">
							<v-icon :color="colors.blue.base" class="mr-2">mdi-plus</v-icon>
							{{ $t("buttons.add") }}
						</frp-btn>
					</div>

					<frp-autocomplete :label="$t('fields.roleInProject.label')"
									  v-model="createDebtorRequestRoleInProject"
									  :items="projectRoleTypes"
									  item-text="name"
									  item-value="id"
									  required
									  :placeholder="$t('fields.roleInProject.placeholder')">
					</frp-autocomplete>

					<frp-autocomplete :label="$t('fields.obligedCompany.label')"
									  v-if="isObligedCompanyFieldVisible"
									  v-model="createDebtorRequestObligedCompanyId"
									  :min-query-length="MIN_ORGANIZATIONS_QUERY_LENGTH"
									  :items="obligedCompanies"
									  @update:search-input="getOrganizations($event, true)"
									  item-text="name"
									  item-value="id"
									  required
									  :loading="isObligedCompaniesLoading"
									  :placeholder="$t('fields.obligedCompany.placeholder')">
					</frp-autocomplete>
				</v-form>
			</template>

			<template #footer>
				<frp-btn outlined
						 @click="isDebtorDialogOpened = false"
						 color="primary">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				<frp-btn class="elevation-0" dark
						 :color="colors.blue.base"
						 :loading="isFormSaving"
						 :disabled="!isDebtorFormValid"
						 @click="handleDebtorBtnClick">
					<span class="white--text">{{ $t("buttons.save") }}</span>
				</frp-btn>
			</template>
		</frp-dialog>
	</v-card>
	<loan-production-status-loader v-else/>
</template>

<script>
import colorsMixin from "@/mixins/colorsMixin";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpDialog from "Components/dialogs/FrpDialog";
import FrpAutocomplete from "Components/fields/FrpAutocomplete";
import authorizationMixin from "Mixins/authorizationMixin";
import formMixin from "Mixins/formMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import {MIN_ORGANIZATIONS_QUERY_LENGTH, ROLES_FOR_OBLIGED_COMPANY} from "Store/loan/modules/troubledBorrower/constants";
import {
	ProductionStatusSnapshotKeys
} from "Store/loan/modules/troubledBorrower/modules/productionStatus/types/productionStatusSnapshotKeys";
import LoanProductionStatusLoader from "Views/loan/troubledBorrower/productionStatus/LoanProductionStatusLoader";
import LoanProductionStatusDebtor from "Views/loan/troubledBorrower/productionStatus/LoanProductionStatusDebtor";
import {
	getterTypes,
	mutationTypes,
	actionTypes
} from "Store/loan/modules/troubledBorrower/modules/productionStatus/types";
import {actionTypes as troubledBorrowerActionTypes} from '@/store/loan/modules/troubledBorrower/types'
import storeManager from "Store/manager";
import {createNamespacedHelpers} from "vuex";
import BatchService from "@/services/batchService";

const namespace = storeManager.loan.troubledBorrower.productionStatus.namespace;
const {mapState, mapGetters, mapMutations, mapActions} = createNamespacedHelpers(namespace);
const troubledBorrower = createNamespacedHelpers(storeManager.loan.troubledBorrower.namespace);

const setRequestBatchService = new BatchService(({interval: 500}));

export default {
	mixins: [colorsMixin, storeModuleBasedPage, formMixin, authorizationMixin],
	data() {
		return {
			namespace,
			MIN_ORGANIZATIONS_QUERY_LENGTH,
			ProceedingStatusSnapshotKeys: ProductionStatusSnapshotKeys,
			isDebtorFormValid: false,
			isDebtorDialogOpened: false,
			addCounterpartyUrl: process.env.VUE_APP_OLK_BASE_URL
		};
	},
	computed: {
		...troubledBorrower.mapState({
			organizations: state => state.organizations,
			obligedCompanies: state => state.obligedCompanies,
			projectRoleTypes: state => state.projectRoleTypes,
			isVersionLoading: state => state.isVersionLoading,
			isOrganizationsLoading: state => state.isOrganizationsLoading,
			isObligedCompaniesLoading: state => state.isObligedCompaniesLoading
		}),
		...mapState({
			isInitialized: state => state.isInitialized,
			createDebtorRequest: state => state.createDebtorRequest,
			items: state => state.listing.items
		}),
		isObligedCompanyFieldVisible() {
			return ROLES_FOR_OBLIGED_COMPANY.includes(this.projectRoleTypes.find(x => x.id ===
				this.createDebtorRequestRoleInProject)?.name);
		},
		createDebtorRequestCompanyId: {
			get() {
				return this.createDebtorRequest.companyId;
			},
			set(value) {
				this.setCreateDebtorRequestCompanyId(value);
			}
		},
		createDebtorRequestRoleInProject: {
			get() {
				return this.createDebtorRequest.roleInProject;
			},
			set(value) {
				this.setCreateDebtorRequestRoleInProject(value);
			}
		},
		createDebtorRequestObligedCompanyId: {
			get() {
				return this.createDebtorRequest.obligedCompanyId;
			},
			set(value) {
				this.setCreateDebtorRequestObligedCompanyId(value);
			}
		}
	},
	methods: {
		...troubledBorrower.mapActions({
			fetchOrganizations: troubledBorrowerActionTypes.fetchOrganizations,
			fetchObligedCompanies: troubledBorrowerActionTypes.fetchObligedCompanies
		}),
		...mapActions({
			initializeStore: actionTypes.initialize,
			createDebtor: actionTypes.createDebtor,
			rollback: actionTypes.cancelChanges
		}),
		...mapMutations({
			setCreateDebtorRequestCompanyId: mutationTypes.SET_CREATE_DEBTOR_REQUEST_COMPANY_ID,
			setCreateDebtorRequestRoleInProject: mutationTypes.SET_CREATE_DEBTOR_REQUEST_ROLE_IN_PROJECT,
			setCreateDebtorRequestObligedCompanyId: mutationTypes.SET_CREATE_DEBTOR_REQUEST_OBLIGED_COMPANY_ID
		}),
		async handleDebtorBtnClick() {
			await this.createDebtor();

			this.isDebtorDialogOpened = false;
		},
		async getOrganizations(query, obligedCompany = false) {
			if (query?.length)
				setRequestBatchService.push(async () => {
					if (obligedCompany)
						await this.fetchObligedCompanies(query)
					else
						await this.fetchOrganizations(query)
				});
		}
	},
	async created() {
		await this.initializeStore();
	},
	watch: {
		isDebtorDialogOpened(value) {
			if (!value) {
				this.rollback(ProductionStatusSnapshotKeys.CREATE_DEBTOR_REQUEST);
				this.$refs[this.refs.form].resetValidation();
			}
		}
	},
	components: {
		FrpAutocomplete,
		FrpDialog,
		FrpBtn,
		LoanProductionStatusDebtor,
		LoanProductionStatusLoader
	}
};
</script>

export default class ApiResponsibleUsersInfo {
	legalDepartmentUserId: number;
	projectManagerUserId: number;

	constructor(
		legalDepartmentUserId: number = 0,
		projectManagerUserId: number = 0
	)
	{
		this.legalDepartmentUserId = legalDepartmentUserId;
		this.projectManagerUserId = projectManagerUserId;
	}
}

import { mutationTypes, actionTypes, getterTypes, namespace } from "@/store/loan/modules/overdueInterest/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import AbortService from "@/services/abortService";
import OverdueInterestState from "@/store/loan/modules/overdueInterest/types/overdueInterestState";
import overdueInterestListModule from "@/store/loan/modules/overdueInterest/modules/overdueInterestIList";
import penaltyInterestListModule from "@/store/loan/modules/overdueInterest/modules/penaltyInterestList";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import { DictionariesController } from "@/api/loan/dictionaries";
import router from "@/router/loan";

const abortService = new AbortService();
const dictionariesController = new DictionariesController(abortService);

const baseMixin = (new BaseMixinBuilder(abortService)).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new OverdueInterestState();
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<OverdueInterestState, any>>{};

const actions = <ActionTree<OverdueInterestState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }) {
		await dispatch(actionTypes.initializeBase);

		await dispatch(actionTypes.fetchDictionaries);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.fetchDictionaries]({ commit }) {
		commit(mutationTypes.SET_IS_LOADING, true);

		try {
			const [quarters, financeSources, penaltyTypes] = await Promise.all([
				dictionariesController.getProjectQuarters(router.currentRoute.params.projectId),
				dictionariesController.getProjectFinanceSources(router.currentRoute.params.projectId),
				dictionariesController.getPenaltyTypes()
			]);

			commit(mutationTypes.SET_QUARTERS, quarters);
			commit(mutationTypes.SET_FINANCE_SOURCES, financeSources);
			commit(mutationTypes.SET_PENALTY_TYPES, penaltyTypes);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_LOADING, false);
		}
	}
};

const mutations = <MutationTree<OverdueInterestState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	[mutationTypes.SET_IS_LOADING](state, value) {
		state.isLoading = value;
	},
	[mutationTypes.SET_QUARTERS](state, value) {
		state.quarters = value;
	},
	[mutationTypes.SET_FINANCE_SOURCES](state, value) {
		state.financeSources = value;
	},
	[mutationTypes.SET_PENALTY_TYPES](state, value) {
		state.penaltyTypes = value;
	}
};

const modules = {
	[overdueInterestListModule.namespace]: {
		...overdueInterestListModule
	},
	[penaltyInterestListModule.namespace]: {
		...penaltyInterestListModule
	}
};

const subscribe = (store: any) => {
	overdueInterestListModule.subscribe(store);
	penaltyInterestListModule.subscribe(store);
};

export {
	namespace, state, getters, actions, mutations, modules, subscribe
};

const overdueInterestModule = {
	namespace, state, getters, actions, mutations, modules, subscribe, namespaced: true
};

export default overdueInterestModule;

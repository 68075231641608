import ApiDocumentDescription from "@/api/loan/types/reports/apiDocumentDescription";
import ReportDocumentFile from "@/store/loan/modules/reports/types/reportDocumentFile";

export default class ReportDocument {
	constructor(
		public description: ApiDocumentDescription = new ApiDocumentDescription(),
		public canUploadFile: boolean = false,
		public canUploadGeneratedFile: boolean = false,
		public canGenerate: boolean = false,
		public uploadDateTime: number = 0,
		public documentFiles: ReportDocumentFile[] = [],
		public isSignRequired: boolean = false
	)
	{
	}
}

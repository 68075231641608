import ApiCompany from "@/api/loan/types/reports/apiCompany";

export default class ApiProject {
	id: number;
	name: string;
	company: ApiCompany;

	constructor(
		id: number = 0,
		name: string = "",
		company: ApiCompany = new ApiCompany()
	)
	{
		this.id = id;
		this.name = name;
		this.company = company;
	}
}

export default class ApiDebtorEvent {
	constructor(
		public eventId: string = "",
		public eventTypeId: string = "",
		public controlDate: string = "",
		public factualDate: string = "",
		public judicialDecisionTypeId: string = "",
		public debtorStatusHistoryItemId: string = "",
		public factualPenaltySum: number = 0,
		public number: number = 0
	)
	{
	}
}

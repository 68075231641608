export default class ApiRequirementHistoryItem {
	id: string;
	updatedAt: string;
	createdBy: string;
	debtorRequirementId: string;
	debtorRequirementTypeId: string;
	sum: number;

	constructor(
		id: string = "",
		updatedAt: string = "",
		createdBy: string = "",
		debtorRequirementId: string = "",
		debtorRequirementTypeId: string = "",
		sum: number = 0
	)
	{
		this.id = id;
		this.updatedAt = updatedAt;
		this.createdBy = createdBy;
		this.debtorRequirementId = debtorRequirementId;
		this.debtorRequirementTypeId = debtorRequirementTypeId;
		this.sum = sum;
	}
}

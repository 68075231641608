export default class AgreementsOverduePaymentsFilter {
	projectNumber: string;
	organization: string;
	controller: string;
	statuses: string[];

	constructor(
		projectNumber: string = "",
		organization: string = "",
		controller: string = "",
		statuses: string[] = []
	)
	{
		this.projectNumber = projectNumber;
		this.organization = organization;
		this.controller = controller;
		this.statuses = statuses;
	}
}

export interface AssignmentCompensationInterest {
	projectId: string;
	date: number;
	preferentialAmount: number;
	compensableAmount: number;
}

export class AssignmentCompensationInterestHelper {
	static getEmpty(): AssignmentCompensationInterest {
		return {
			projectId: "",
			date: 0,
			preferentialAmount: 0,
			compensableAmount: 0
		};
	}
}
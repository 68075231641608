<template>
	<loan-card-layout>
		<template #title>
			<frp-custom-title-loader/>
		</template>
		<template #actions>
			<frp-btn-loader height="32" width="160" class="mr-2"/>
			<frp-btn-loader height="32" width="160"/>
		</template>

		<template #content>
			<v-card elevation="0"
					class="loan-card pa-4 pt-3 d-flex flex-column"
					style="row-gap: 18px">
				<v-row>
					<v-col class="d-flex flex-column pb-1 pt-7" v-for="(item, index) in 4" :key="index">
						<v-skeleton-loader type="text" max-width="80"/>
					</v-col>
					<div style="width: 40px;" class="d-flex flex-column pb-1 pt-7 mr-1">
					</div>
				</v-row>
				<v-divider class="mb-3"></v-divider>
				<v-row class="pb-3" v-for="(item, index) in 3" :key="index">
					<v-col class="d-flex flex-column py-0">
						<v-skeleton-loader type="text" max-width="160"/>
					</v-col>
					<v-col class="d-flex flex-column py-0">
						<v-skeleton-loader type="text" max-width="80"/>
					</v-col>
					<v-col class="d-flex flex-column py-0">
						<v-skeleton-loader type="text" max-width="80"/>
					</v-col>
					<v-col class="d-flex flex-column py-0">
						<v-skeleton-loader type="text" max-width="140"/>
					</v-col>
					<div style="width: 40px;" class="d-flex flex-column px-3 mr-1">
						<v-skeleton-loader type="text" max-width="20"/>
					</div>
				</v-row>
				<v-row>
					<v-col class="d-flex flex-column">
						<v-skeleton-loader type="text" style="width: 80px"/>
					</v-col>
					<v-col class="d-flex justify-end">
						<v-skeleton-loader type="text" style="width: 140px"/>
					</v-col>
				</v-row>
			</v-card>
		</template>
	</loan-card-layout>
</template>

<script>
import LoanCardLayout from "Components/layouts/LoanCardLayout";
import FrpCustomTitleLoader from "Components/loaders/common/FrpCustomTitleLoader";
import FrpTabsLoader from "Components/loaders/common/FrpTabsLoader";
import FrpBtnLoader from "Components/loaders/form/FrpBtnLoader";

export default {
	name: "LoanOverdueInterestListLoader",
	components: { FrpTabsLoader, FrpBtnLoader, FrpCustomTitleLoader, LoanCardLayout }
};
</script>

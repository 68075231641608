<template>
	<div>
		<template v-if="item.description.documentType === ReportDocumentType.CO_FINANCE">
			<frp-text-btn v-if="hasDocumentFiles()"
						  class="pa-0 passport-text-btn"
						  wrap
						  :color="colors.blue.base"
						  @click="$emit('history-dialog:open', item)">
				<span>{{ item.description.documentTitle }}</span>

				<template v-if="item.description.isRequired">
					<span class="secondary--text">*</span>
				</template>
			</frp-text-btn>

			<div v-else>
				<span>{{ $t("common.coFinanceRegister") }}</span>

				<span class="text-no-wrap">
					<span> (</span>
					<a :href="coFinanceLink" class="reports-link text-decoration-none" target="_blank">
						{{ $t("common.onSample") }}
					</a>
					<span>)</span>
				</span>

				<template v-if="item.description.isRequired">
					<span class="secondary--text">*</span>
				</template>
			</div>
		</template>

		<template v-else-if="item.description.documentType === ReportDocumentType.PROJECT_EXECUTION_REPORT">
			<frp-text-btn v-if="hasDocumentFiles()"
						  class="pa-0 passport-text-btn"
						  wrap
						  :color="colors.blue.base"
						  @click="$emit('history-dialog:open', item)">
				<span>{{ item.description.documentTitle }}</span>

				<template v-if="item.description.isRequired">
					<span class="secondary--text">*</span>
				</template>
			</frp-text-btn>

			<div v-else>
				<span>{{ item.description.documentTitle }}</span>

				<span class="text-no-wrap">
					<span> (</span>
					<a :href="projectExecutionReportLink" class="reports-link text-decoration-none" target="_blank">
						{{ $t("common.onSample") }}
					</a>
					<span>)</span>
				</span>

				<template v-if="item.description.isRequired">
					<span class="secondary--text">*</span>
				</template>
			</div>
		</template>

		<template v-else-if="item.description.documentType === ReportDocumentType.PROJECT_FINANCE_EXECUTION">
			<frp-text-btn v-if="hasDocumentFiles()"
						  class="pa-0 passport-text-btn"
						  wrap
						  :color="colors.blue.base"
						  @click="$emit('history-dialog:open', item)">
				<span>{{ item.description.documentTitle }}</span>

				<template>
					<span v-if="item.description.isRequired" class="secondary--text">*</span>
				</template>
			</frp-text-btn>

			<div v-else>
				<span>{{ item.description.documentTitle }}</span>

				<span class="text-no-wrap">
					<span> (</span>
					<a :href="projectFinanceExecutionReportLink" class="reports-link text-decoration-none"
					   target="_blank">
						{{ $t("common.onSample") }}
					</a>
					<span>)</span>
				</span>

				<template v-if="item.description.isRequired">
					<span class="secondary--text">*</span>
				</template>
			</div>
		</template>

		<template v-else-if="item.description.documentType === ReportDocumentType.PROJECT_PASSPORT_REPORT">
			<frp-text-btn v-if="hasDocumentFiles()"
						  class="pa-0 passport-text-btn"
						  wrap
						  :color="colors.blue.base"
						  @click="$emit('history-dialog:open', item)">
				<span>{{ item.description.documentTitle }}</span>

				<template v-if="item.description.isRequired">
					<span class="secondary--text">*</span>
				</template>
			</frp-text-btn>

			<div v-else>
				<span>{{ item.description.documentTitle }}</span>

				<span class="text-no-wrap">
					<span> (</span>
					<a :href="projectPassportReport" class="reports-link text-decoration-none" target="_blank">
						{{ $t("common.onSample") }}
					</a>
					<span>)</span>
				</span>

				<template v-if="item.description.isRequired">
					<span class="secondary--text">*</span>
				</template>
			</div>
		</template>

		<template v-else>
			<frp-text-btn v-if="hasDocumentFiles()"
						  class="pa-0 passport-text-btn"
						  wrap
						  :color="colors.blue.base"
						  @click="$emit('history-dialog:open', item)">
				<span>{{ item.description.documentTitle }}</span>

				<template v-if="item.description.isRequired">
					<span class="secondary--text">*</span>
				</template>
			</frp-text-btn>

			<div v-else>
				<span>{{ item.description.documentTitle }}</span>
				<template v-if="item.description.isRequired">
					<span class="secondary--text">*</span>
				</template>
			</div>
		</template>
	</div>
</template>

<script>
import FrpTextBtn from "@/components/buttons/FrpTextBtn.vue";
import colorsMixin from "@/mixins/colorsMixin";
import { ReportDocumentType } from "@/store/loan/modules/reports/types/reportDocumentType";

export default {
	mixins: [colorsMixin],
	props: {
		item: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			ReportDocumentType,
			coFinanceLink: process.env.VUE_APP_LOAN_REPORTS_CO_FINANCE_LINK,
			projectExecutionReportLink: process.env.VUE_APP_LOAN_REPORTS_PROJECT_EXECUTION_REPORT_LINK,
			projectFinanceExecutionReportLink: process.env.VUE_APP_LOAN_REPORTS_PROJECT_FINANCE_EXECUTION_REPORT_LINK,
			projectPassportReport: process.env.VUE_APP_LOAN_REPORTS_PROJECT_PASSPORT_REPORT_LINK
		};
	},
	methods: {
		hasDocumentFiles() {
			return this.item.documentFiles && this.item.documentFiles.length > 0;
		}
	},
	components: { FrpTextBtn }
};
</script>

<style scoped>

</style>

<template>
	<v-card elevation="0" class="loan-card px-1 pt-6 pb-3">
		<v-row class="px-2 pt-2 align-center py-4">
			<v-col class="py-0 fill-height">
				<frp-custom-title-loader height="20"/>
			</v-col>
			<v-col class="py-0 d-flex justify-end">
				<frp-btn-loader height="36" class="mr-2"/>
				<frp-btn-loader height="36" class="mr-2"/>
				<frp-btn-loader height="36"/>
			</v-col>
		</v-row>

		<v-row class="px-2">
			<v-col class="d-flex flex-column pt-0">
				<frp-details-item-loader/>
			</v-col>
			<v-col class="d-flex flex-column pt-0">
				<frp-details-item-loader/>
			</v-col>
			<v-col class="d-flex flex-column pt-0">
				<frp-details-item-loader/>
			</v-col>
			<v-col class="d-flex flex-column pt-0">
				<frp-details-item-loader/>
			</v-col>
			<v-col v-if="$vuetify.breakpoint.lgAndUp" class="d-flex flex-column"></v-col>
		</v-row>
	</v-card>
</template>

<script>
import FrpCustomTitleLoader from "Components/loaders/common/FrpCustomTitleLoader";
import FrpDetailsItemLoader from "Components/loaders/details/FrpDetailsItemLoader";
import FrpDetailsTitleLoader from "Components/loaders/details/FrpDetailsTitleLoader";
import FrpBtnLoader from "Components/loaders/form/FrpBtnLoader";

export default {
	name: "LoanAgreementLoader",
	components: { FrpDetailsItemLoader, FrpDetailsTitleLoader, FrpBtnLoader, FrpCustomTitleLoader }
};
</script>

<style scoped>

</style>

<template>
	<loan-content-layout v-if="isInitialized">
		<v-row>
			<v-col class="px-3 py-4">
				<loan-agreement></loan-agreement>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="12" md="4" lg="3">
				<v-row>
					<v-col class="py-4" :class="{ 'pr-0 pl-0': $vuetify.breakpoint.mdAndUp }">
						<loan-schedule-items></loan-schedule-items>
					</v-col>
				</v-row>
				<v-row v-if="versionHeaders.length">
					<v-col class="py-4" :class="{ 'pr-0 pl-0': $vuetify.breakpoint.mdAndUp }">
						<loan-tranches></loan-tranches>
					</v-col>
				</v-row>
			</v-col>

			<v-col cols="12" md="8" lg="9">
				<v-row v-if="versionHeaders.length">
					<v-col class="py-4" :class="{ 'pr-0 pl-0': $vuetify.breakpoint.mdAndUp }">
						<loan-overdues></loan-overdues>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="py-4" :class="{ 'pr-0 pl-0': $vuetify.breakpoint.mdAndUp }">
						<loan-payments></loan-payments>
					</v-col>
				</v-row>
			</v-col>
		</v-row>

		<frp-dialog :title="$t('dialogs.wrongAmount.title')" :value="wrongAmountDialog !== WrongAmountDialogType.NONE">
			<template #content="{ onIntersect }">
				<span class="primary--text text-body-2" v-intersect="onIntersect">
					<span>{{ $t("dialogs.wrongAmount.part1") }}</span>
					<span class="blue--text">
						{{$t("dialogs.wrongAmount." + camelCase(wrongAmountDialog))}}
					</span>
					<span class="mr-1">{{ $t("dialogs.wrongAmount.part2") }}</span>
					<span class="blue--text mr-1">{{ $t("dialogs.wrongAmount.frpSum") }}</span>
					<span>{{ $t("dialogs.wrongAmount.part3") }}</span>
				</span>
			</template>

			<template #footer>
				<frp-btn outlined
						 @click="setWrongAmountDialog(WrongAmountDialogType.NONE)"
						 color="primary">
					{{ $t("buttons.close") }}
				</frp-btn>
			</template>
		</frp-dialog>
	</loan-content-layout>
	<loan-schedule-loader v-else/>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn";
import LoanContentLayout from "@/components/layouts/LoanContentLayout";
import FrpDialog from "Components/dialogs/FrpDialog";
import LoanScheduleLoader from "@/views/loan/loanSchedule/LoanScheduleLoader";
import LoanAgreement from "Views/loan/loanSchedule/agreement/LoanAgreement";
import LoanScheduleItems from "Views/loan/loanSchedule/scheduleItems/LoanScheduleItems";
import LoanPayments from "Views/loan/loanSchedule/payments/LoanPayments";
import LoanTranches from "Views/loan/loanSchedule/tranches/LoanTranches";
import LoanOverdues from "Views/loan/loanSchedule/overdues/LoanOverdues";
import { WrongAmountDialogType } from "Store/loan/modules/loanSchedule/types/wrongAmountDialogType";
import { namespace, getterTypes, mutationTypes, actionTypes } from "Store/loan/modules/loanSchedule/types";
import { createNamespacedHelpers } from "vuex";
import { i18n } from "@/plugins";
import { camelCase } from "lodash";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	metaInfo: {
		title: i18n.t("metaTitles.loanSchedule")
	},
	data() {
		return {
			WrongAmountDialogType,
			camelCase
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			versionHeaders: state => state.versionHeaders,
			wrongAmountDialog: state => state.wrongAmountDialog
		})
	},
	methods: {
		...mapActions({
			initializeStore: actionTypes.initialize
		}),
		...mapMutations({
			setWrongAmountDialog: mutationTypes.SET_WRONG_AMOUNT_DIALOG
		})
	},
	created() {
		this.initializeStore();
	},
	components: {
		FrpBtn,
		LoanContentLayout,
		FrpDialog,
		LoanScheduleLoader,
		LoanAgreement,
		LoanScheduleItems,
		LoanPayments,
		LoanTranches,
		LoanOverdues
	}
};
</script>

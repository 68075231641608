import AssignmentCompensationState from "@/store/loan/modules/assignmentCompensation/types/assignmentCompensationState";

export interface ApiGetAssignmentCompensationProjectsParameters {
	startDate: string,
	endDate: string,
	assignmentAgreementId?: string,
	assigneeId?: string
}


export class ApiGetAssignmentCompensationProjectsParametersMapper {
	static map(source: AssignmentCompensationState): ApiGetAssignmentCompensationProjectsParameters {
		return {
			startDate: source.filter.assignmentPeriodStartDate,
			endDate: source.filter.assignmentPeriodEndDate,
			assignmentAgreementId: source.filter.assignmentAgreementId || undefined,
			assigneeId: source.filter.assigneeId || undefined
		};
	}
}

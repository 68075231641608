import { addProfile, createMap, createMapper, forMember, mapFrom, MappingProfile } from "@automapper/core";
import { classes } from "@automapper/classes";
import { convertToZonedIso, convertToTimestamp } from "@/utils/dates";
import PenaltyInterest from "@/store/loan/modules/overdueInterest/modules/penaltyInterestList/types/penaltyInterest";
import ApiPenaltyInterest from "@/api/loan/types/loanInterest/apiPenaltyInterest";

const mapper = createMapper({
	strategyInitializer: classes()
});

const overdueInterestProfile: MappingProfile = (mapper) => {
	createMap(mapper, ApiPenaltyInterest, PenaltyInterest,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.number, mapFrom(x => x.number)),
		forMember(d => d.projectId, mapFrom(x => x.projectId)),
		forMember(d => d.penaltyTypeId, mapFrom(x => x.penaltyTypeId)),
		forMember(d => d.financingSourceId, mapFrom(x => x.financingSourceId)),
		forMember(d => d.quarterId, mapFrom(x => x.quarterId)),
		forMember(d => d.date, mapFrom(x => convertToTimestamp(x.date))),
		forMember(d => d.amount, mapFrom(x => x.amount)),
		forMember(d => d.comment, mapFrom(x => x.comment))
	);

	createMap(mapper, PenaltyInterest, ApiPenaltyInterest,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.number, mapFrom(x => x.number)),
		forMember(d => d.projectId, mapFrom(x => x.projectId)),
		forMember(d => d.penaltyTypeId, mapFrom(x => x.penaltyTypeId)),
		forMember(d => d.financingSourceId, mapFrom(x => x.financingSourceId)),
		forMember(d => d.quarterId, mapFrom(x => x.quarterId)),
		forMember(d => d.date, mapFrom(x => convertToZonedIso(x.date))),
		forMember(d => d.amount, mapFrom(x => x.amount)),
		forMember(d => d.comment, mapFrom(x => x.comment)));
};

addProfile(mapper, overdueInterestProfile);

export default mapper;

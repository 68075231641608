import { addProfile, createMap, createMapper, forMember, mapFrom, MappingProfile } from "@automapper/core";
import { classes } from "@automapper/classes";
import { convertToZonedIso, convertToTimestamp } from "@/utils/dates";
import Overdue from "@/store/loan/modules/loanSchedule/modules/overdues/types/overdue";
import ApiOverdue from "@/api/loan/types/loanSchedule/apiOverdue";
import scheduleMapper from "@/store/loan/modules/loanSchedule/mapper";
import Quarter from "@/types/loan/quarter";
import ApiQuarter from "@/api/loan/types/dictionaries/apiQuarter";

const mapper = createMapper({
	strategyInitializer: classes()
});

const overduesProfile: MappingProfile = (mapper) => {
	createMap(mapper, ApiOverdue, Overdue,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.number, mapFrom(x => x.number)),
		forMember(d => d.startDate, mapFrom(x => convertToTimestamp(x.startDate))),
		forMember(d => d.source, mapFrom(x => x.source)),
		forMember(d => d.quarter, mapFrom(x => scheduleMapper.map(x.quarter, ApiQuarter, Quarter))),
		forMember(d => d.amount, mapFrom(x => x.amount)),
		forMember(d => d.comment, mapFrom(x => x.comment)),
		forMember(d => d.isProblem, mapFrom(x => x.isProblem))
	);

	createMap(mapper, Overdue, ApiOverdue,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.number, mapFrom(x => x.number)),
		forMember(d => d.startDate, mapFrom(x => convertToZonedIso(x.startDate))),
		forMember(d => d.source, mapFrom(x => x.source)),
		forMember(d => d.quarter, mapFrom(x => scheduleMapper.map(x.quarter, Quarter, ApiQuarter))),
		forMember(d => d.amount, mapFrom(x => x.amount)),
		forMember(d => d.comment, mapFrom(x => x.comment)),
		forMember(d => d.isProblem, mapFrom(x => x.isProblem)));
};

addProfile(mapper, overduesProfile);

export default mapper;

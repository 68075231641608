<template>
	<v-container fluid class="pa-0">
		<v-row
			style="position: fixed; bottom: 0; left: 0; width: 100%"
			class="ma-0 loan-actions">
			<v-col :class="backgroundColor" class="d-flex pr-11 justify-end py-4">
				<slot></slot>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	props: {
		backgroundColor: {
			type: String,
			default: "white darken-2"
		}
	}
};
</script>

<style scoped lang="scss">
.loan-actions {
	margin-bottom: 50px !important;
	padding-left: 15px !important;
	padding-right: 15px !important;
}
</style>

export default class ApiQuarter {
	constructor(
		public id: string = "",
		public title: string = "",
		public startDate: string = "",
		public number: number = 0
	)
	{
	}
}

export default class ApiReportsParameters {
	constructor(
		public projectId: string = "",
		public companyId: string = "",
		public year: string = "",
		public quarter: string = "",
		public includeAllVersions: boolean = false
	)
	{
	}
	
}

import { IMiddleware } from "@/types/core/middleware";
import { NavigationGuardNext, Route } from "vue-router/types/router";
import { RouteNames } from "@/router/loan/routes";
import routeToPermissionsMap from "@/router/loan/routeToPermissionsMap";
import PermissionsService from "@/services/permissionsService";

const permissionsService = new PermissionsService();

export default class PermissionsMiddleware implements IMiddleware {
	constructor() {

	}

	async invoke(to: Route, from: Route) {
		let routeName = to.name as RouteNames;

		// Проверяем урл страницы, если он относится к отчетным документам, то мы пропускаем запрос permissions, так как там permissions получаем внутри модели запроса
		const isReports = routeName === RouteNames.REPORTS || routeName === RouteNames.REPORTS_FINAL;
		if (isReports) {
			console.debug('Данная страница отчетных документов не содержит запроса permissions');
			return;
		}


		if(routeName) {
			let permissions = routeToPermissionsMap.get(routeName);

			if(permissions && permissions.length) {
				if(!await permissionsService.check(permissions)) {
					return { name: RouteNames.ERROR_ACCESS_DENIED };
				}
			}
		}
	}
}

import { render, staticRenderFns } from "./LoanReportsButtonsGroup.vue?vue&type=template&id=52bb8cac&scoped=true"
import script from "./LoanReportsButtonsGroup.vue?vue&type=script&lang=js"
export * from "./LoanReportsButtonsGroup.vue?vue&type=script&lang=js"
import style0 from "./LoanReportsButtonsGroup.vue?vue&type=style&index=0&id=52bb8cac&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52bb8cac",
  null
  
)

export default component.exports
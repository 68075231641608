export default class StatusHistoryItem {
	constructor(
		public statusId: string = "",
		public stageTypeId: string = "",
		public stageStatusTypeId: string = "",
		public updatedAt: number = 0,
		public modifiedBy: string = "",
		public startDate: number = 0,
		public expirationDate: number = 0
	)
	{
	}
}

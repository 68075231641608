export default class RefundStatusTypeHistory {
	id: string;
	refundStatusTypeId?: number;
	createdBy: string;
	createdAt: number;

	constructor(id: string, refundStatusTypeId: number, createdBy: string, createdAt: number) {
		this.id = id;
		this.refundStatusTypeId = refundStatusTypeId;
		this.createdAt = createdAt;
		this.createdBy = createdBy;
	}
}

export default class ApiCashFlowItem {
	id: string;
	creatorId: number;
	projectId: number;
	number: number;
	numberPaymentOrder: string;
	paymentDate: string;
	quarterId: string;
	financingSourceId: number;
	paymentExpenseId: string;
	amount: number;
	description: string;
	isForced: boolean;
	isCession: boolean;
	isReserve: boolean;
	activeDate: string;

	constructor(
		id: string = "",
		creatorId: number = 0,
		projectId: number = 0,
		number: number = 0,
		numberPaymentOrder: string = "",
		paymentDate: string = "",
		quarterId: string = "",
		financingSourceId: number = 0,
		paymentExpenseId: string = "",
		amount: number = 0,
		description: string = "",
		isForced: boolean = false,
		isCession: boolean = false,
		isReserve: boolean = false,
		activeDate: string = ""
	)
	{
		this.id = id;
		this.creatorId = creatorId;
		this.projectId = projectId;
		this.number = number;
		this.numberPaymentOrder = numberPaymentOrder;
		this.paymentDate = paymentDate;
		this.quarterId = quarterId;
		this.financingSourceId = financingSourceId;
		this.paymentExpenseId = paymentExpenseId;
		this.amount = amount;
		this.description = description;
		this.isForced = isForced;
		this.isCession = isCession;
		this.isReserve = isReserve;
		this.activeDate = activeDate;
	}
}

export default class TreasuryStatementFile {
	data: File;
	isLoading: boolean;

	constructor(
		data: File,
		isLoading: boolean = false
	)
	{
		this.data = data;
		this.isLoading = isLoading;
	}
}

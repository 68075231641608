export default class ApiIncomePredictionHistory {
	id: string;
	year: number;
	quarter: number;
	conservativeValue: number;
	optimisticValue: number;
	createdBy: { id: number, name: string };
	createdAt: string;

	constructor(id: string, year: number, quarter: number, conservativeValue: number, optimisticValue: number, createdBy: { id: number, name: string }, createdAt: string) {
		this.id = id;
		this.year = year;
		this.quarter = quarter;
		this.conservativeValue = conservativeValue;
		this.optimisticValue = optimisticValue;
		this.createdAt = createdAt;
		this.createdBy = createdBy;
	}
}

<template>
	<loan-card-layout v-if="isInitialized">
		<template #title>
			{{ $t("titles.penaltyInterest") }}
		</template>
		<template #actions>
			<frp-btn v-if="mode === OverdueInterestModeType.READ && can(Permissions.LOAN_CREATE)"
					 @click="isNewItemDialogOpened = true"
					 class="text-none pl-1 pr-2"
					 dense
					 outlined
					 color="blue"
					 dark>
				<v-icon color="blue">mdi-plus</v-icon>
				{{ $t("buttons.addNote") }}
			</frp-btn>
			<template v-if="mode === OverdueInterestModeType.EDIT">
				<frp-btn class="text-none px-3"
						 text
						 plain
						 dense
						 color="primary"
						 @click="setMode(OverdueInterestModeType.READ)">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				<frp-btn class="text-none px-3"
						 outlined
						 dense
						 :loading="isItemsUpdating"
						 :disabled="!isDataValid"
						 @click="updateItems()"
						 color="blue"
						 dark>
					{{ $t("buttons.save") }}
				</frp-btn>
			</template>
			<frp-btn class="text-none px-3"
					 v-else-if="can(Permissions.LOAN_UPDATE)"
					 dense
					 outlined
					 color="blue"
					 dark
					 :disabled="!formattedItems.length"
					 @click="setMode(OverdueInterestModeType.EDIT)">
				{{ $t("buttons.edit") }}
			</frp-btn>
		</template>

		<template #content>
			<v-card
				v-if="isInitialized"
				elevation="0"
				class="loan-card pa-4 pt-3 d-flex flex-column"
				style="row-gap: 18px">
				<v-row v-if="!formattedItems.length">
					<v-col class="d-flex align-center flex-column py-8">
						<span class="text-body-2 grey--text">{{ $t("content.noNotes") }}</span>
					</v-col>
				</v-row>

				<v-row v-else class="d-flex flex-column">
					<v-col v-for="type in penaltyTypes" :key="type.id" v-if="formattedItems.some(x => x.penaltyTypeId === type.id)">
						<v-card-title class="px-0 pt-2 pb-6">
								<span style="word-break: initial"
									  class="mr-1 text-subtitle-1 font-weight-medium blue--text text--darken-4">
									{{ type.name }}
								</span>
						</v-card-title>
						<v-form v-model="isDataValid">
							<v-data-table :headers="headers"
										  :loading-text="$t('tables.loading')"
										  hide-default-footer
										  item-key="id"
										  :items="getItemsByPenaltyType(type)"
										  :items-per-page="-1"
										  :mobile-breakpoint="mobileBreakpoint"
										  :options="options"
										  :item-class="() => 'text-body-2 cy-table-row'"
										  class="loan-table clickable-rows d-flex flex-column">
								<template #item.financingSourceId="{ item }">
									<span v-if="mode === OverdueInterestModeType.READ">
										{{ financeSources.find(x => x.id === item.financingSourceId).name || "--" }}
									</span>
									<frp-autocomplete dense
													  required
													  :items="financeSources"
													  item-text="name"
													  item-value="id"
													  color="blue"
													  v-model="editableItems.find(x => x.id === item.id).financingSourceId"
													  hide-details
													  v-else
													  :placeholder="$t('fields.financeSource.label')">
									</frp-autocomplete>
								</template>

								<template #item.date="{ item }">
									<span v-if="mode === OverdueInterestModeType.READ"> {{ formatDate(item.date, dateFormat) }} </span>
									<frp-date-field dense
													required
													v-model="editableItems.find(x => x.id === item.id).date"
													hide-details
													v-else
													:placeholder="$t('fields.date.label')">
									</frp-date-field>
								</template>
								<template #item.amount="{ item }">
									<span v-if="mode === OverdueInterestModeType.READ">{{ formatCurrency(item.amount) }}</span>
									<frp-text-field dense
													required
													v-model.number="editableItems.find(x => x.id === item.id).amount"
													type="number"
													hide-details
													v-else
													:placeholder="$t('fields.amount.labelRUB')">
									</frp-text-field>
								</template>

								<template #item.quarterId="{ item }">
									<span v-if="mode === OverdueInterestModeType.READ">
										{{ quarters.find(x => x.id === item.quarterId).title || "--" }}
									</span>
									<frp-autocomplete dense
													  required
													  :items="quarters"
													  item-text="title"
													  item-value="id"
													  color="blue"
													  v-model="editableItems.find(x => x.id === item.id).quarterId"
													  hide-details
													  v-else
													  :placeholder="$t('fields.quarter.label')">
									</frp-autocomplete>
								</template>

								<template #item.comment="{ item }">
									<span v-if="mode === OverdueInterestModeType.READ">{{ item.comment || "--" }}</span>
									<frp-text-field dense
													v-model="editableItems.find(x => x.id === item.id).comment"
													type="text"
													hide-details
													v-else
													:placeholder="$t('fields.comment.placeholder')">
									</frp-text-field>
								</template>

								<template #item.delete="{ item }" v-if="can(Permissions.LOAN_DELETE)">
									<frp-btn without-padding
											 height="28"
											 small
											 icon
											 color="blue"
											 dark
											 @click="handleOpenDeleteItemDialog(item)">
										<frp-icon src="ico_delete" :color="colors.primary.darken1"></frp-icon>
									</frp-btn>
								</template>
							</v-data-table>
						</v-form>
					</v-col>
				</v-row>
			</v-card>

			<v-card v-if="formattedItems.length"
					elevation="0"
					color="primary"
					class="loan-card py-4 pl-5 pr-7 text-subtitle-1 font-weight-medium white--text d-flex justify-space-between"
					style="border-top-right-radius: 0; border-top-left-radius: 0">
				<span>{{ $t("content.allInAll") }}</span>
				<span>{{ sum }}</span>
			</v-card>

			<frp-dialog v-model="isNewItemDialogOpened" :title="$t('dialogs.addPenaltyInterest.title')" persistent>
				<template #content="{ onIntersect }">
					<v-form :ref="refs.form" v-intersect="onIntersect" v-model="formValid">
						<frp-autocomplete :label="$t('fields.financeSource.label')"
										  v-model="newItemFinancingSourceId"
										  :items="financeSources"
										  item-text="name"
										  item-value="id"
										  color="blue"
										  required
										  :placeholder="$t('fields.financeSource.placeholder')">
						</frp-autocomplete>
						<frp-autocomplete :label="$t('fields.quarter.label')"
										  v-model="newItemQuarterId"
										  :items="quarters"
										  item-text="title"
										  item-value="id"
										  color="blue"
										  required
										  :placeholder="$t('fields.quarter.label')">
						</frp-autocomplete>
						<frp-autocomplete :label="$t('fields.penaltyType.label')"
										  v-model="newItemPenaltyTypeId"
										  :items="penaltyTypes"
										  item-text="name"
										  item-value="id"
										  color="blue"
										  required
										  :placeholder="$t('fields.penaltyType.placeholder')">
						</frp-autocomplete>
						<frp-date-field :label="$t('fields.date.label')"
										v-model="newItemDate"
										required
										:placeholder="$t('fields.date.placeholder')">
						</frp-date-field>
						<frp-text-field :label="$t('fields.amount.labelRUB')"
										v-model.number="newItemAmount"
										type="number"
										required
										:placeholder="$t('fields.amount.placeholder')">
						</frp-text-field>
						<frp-text-field :label="$t('fields.comment.label')"
										v-model="newItemComment"
										:placeholder="$t('fields.comment.placeholder')">
						</frp-text-field>
					</v-form>
				</template>

				<template #footer>
					<frp-btn outlined
							 @click="resetCreateItem"
							 color="primary">
						{{ $t("buttons.cancel") }}
					</frp-btn>
					<frp-btn elevation="0"
							 color="blue"
							 dark
							 :loading="isItemCreating"
							 :disabled="!stateContainsUnsavedChanges || !isFormValid"
							 @click="handleCreateItem">
						{{ $t("buttons.addNote") }}
					</frp-btn>
				</template>
			</frp-dialog>

			<frp-dialog v-model="isDeleteItemDialogOpened" :title="$t('dialogs.deleteNote.title')">
				<template #content="{ onIntersect }">
					<span class="primary--text text-body-2" v-intersect="onIntersect">
						{{ $t("dialogs.deleteNote.warning") }}
					</span>
				</template>

				<template #footer>
					<frp-btn outlined
							 @click="isDeleteItemDialogOpened = false"
							 color="primary">
						{{ $t("buttons.cancel") }}
					</frp-btn>
					<frp-btn elevation="0"
							 color="blue"
							 dark
							 :loading="isItemDeleting"
							 @click="handleDeleteItem">
						{{ $t("buttons.deleteNote") }}
					</frp-btn>
				</template>
			</frp-dialog>
		</template>
	</loan-card-layout>
	<loan-penalty-interest-list-loader v-else/>
</template>

<script>
import FrpBtn from "Components/buttons/FrpBtn";
import FrpDialog from "Components/dialogs/FrpDialog";
import FrpAutocomplete from "Components/fields/FrpAutocomplete";
import FrpDateField from "Components/fields/FrpDateField";
import FrpTextField from "Components/fields/FrpTextField";
import FrpIcon from "Components/icon/FrpIcon";
import LoanCardLayout from "Components/layouts/LoanCardLayout";
import authorizationMixin from "Mixins/authorizationMixin";
import colorsMixin from "Mixins/colorsMixin";
import formMixin from "Mixins/formMixin";
import { listMixin } from "Mixins/listMixin";
import { convertIsoToNumber, formatDate } from "Utils/dates";
import { dateFormat } from "Utils/formats";
import { formatCurrency } from "Utils/formatting";
import storeManager from "Store/manager";
import LoanPenaltyInterestListLoader from "Views/loan/overdueInterest/penaltyInterestList/LoanPenaltyInterestListLoader";
import { getterTypes, mutationTypes, actionTypes } from "Store/loan/modules/overdueInterest/modules/penaltyInterestList/types";
import { OverdueInterestModeType } from "Store/loan/modules/overdueInterest/types/overdueInterestModeType";
import { createNamespacedHelpers } from "vuex";

const namespace = storeManager.loan.overdueInterest.penaltyInterestList.namespace;
const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);
const { mapState: loanOverdueInterestMapState } = createNamespacedHelpers(storeManager.loan.overdueInterest.namespace);

export default {
	mixins: [formMixin, listMixin, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			dateFormat,
			formatDate,
			formatCurrency,
			isDeleteItemDialogOpened: false,
			isNewItemDialogOpened: false,
			OverdueInterestModeType,
			itemToDelete: null,
			isDataValid: false
		};
	},
	computed: {
		...loanOverdueInterestMapState({
			quarters: state => state.quarters,
			financeSources: state => state.financeSources,
			penaltyTypes: state => state.penaltyTypes
		}),
		...mapState({
			isInitialized: state => state.isInitialized,
			newItem: state => state.newItem,
			isItemCreating: state => state.isItemCreating,
			isItemDeleting: state => state.isItemDeleting,
			isItemsUpdating: state => state.isItemsUpdating,
			mode: state => state.mode,
			editableItems: state => state.editableItems
		}),
		...mapGetters({
			formattedItems: getterTypes.formattedItems
		}),
		headers() {
			return [
				{
					text: this.$t("tables.financeSource"),
					value: "financingSourceId",
					class: "text-caption",
					width: "20%",
					sortable: false,
					sort: (a, b) => this.financeSources.find(x => x.id === a).name.localeCompare(this.financeSources.find(x => x.id === b).name)
				},
				{
					text: this.$t("tables.date"),
					value: "date",
					class: "text-caption",
					width: "20%",
					sortable: false
				},
				{
					text: this.$t("tables.quarter"),
					value: "quarterId",
					class: "text-caption",
					width: "20%",
					sortable: false,
					sort: (a, b) => convertIsoToNumber(this.quarters.find(x => x.id === a).startDate) - convertIsoToNumber(this.quarters.find(x => x.id === b).startDate)
				},
				{
					text: this.$t("tables.amountRUB"),
					value: "amount",
					class: "text-caption",
					width: "20%",
					sortable: false
				},
				{
					text: this.$t("tables.comment"),
					value: "comment",
					class: "text-caption",
					width: "20%",
					sortable: false
				},
				{
					text: "",
					value: "delete",
					width: "1%",
					sortable: false
				}
			];
		},
		sum() {
			return this.mode === OverdueInterestModeType.EDIT ?
				formatCurrency(this.getSum(this.editableItems)) :
				formatCurrency(this.getSum(this.formattedItems));
		},
		newItemPenaltyTypeId: {
			get() {
				return this.newItem.penaltyTypeId;
			},
			set(value) {
				this.setNewItemPenaltyTypeId(value);
			}
		},
		newItemFinancingSourceId: {
			get() {
				return this.newItem.financingSourceId;
			},
			set(value) {
				this.setNewItemFinancingSourceId(value);
			}
		},
		newItemDate: {
			get() {
				return this.newItem.date;
			},
			set(value) {
				this.setNewItemDate(value);
			}
		},
		newItemQuarterId: {
			get() {
				return this.newItem.quarterId;
			},
			set(value) {
				this.setNewItemQuarterId(value);
			}
		},
		newItemAmount: {
			get() {
				return this.newItem.amount;
			},
			set(value) {
				this.setNewItemAmount(value);
			}
		},
		newItemComment: {
			get() {
				return this.newItem.comment;
			},
			set(value) {
				this.setNewItemComment(value);
			}
		}
	},
	methods: {
		...mapActions({
			initializeStore: actionTypes.initialize,
			createItem: actionTypes.createItem,
			updateItems: actionTypes.updateItems,
			deleteItem: actionTypes.deleteItem
		}),
		...mapMutations({
			setMode: mutationTypes.SET_MODE,
			resetNewItem: mutationTypes.RESET_NEW_ITEM,
			setNewItemPenaltyTypeId: mutationTypes.SET_NEW_ITEM_PENALTY_TYPE_ID,
			setNewItemFinancingSourceId: mutationTypes.SET_NEW_ITEM_FINANCING_SOURCE_ID,
			setNewItemDate: mutationTypes.SET_NEW_ITEM_DATE,
			setNewItemQuarterId: mutationTypes.SET_NEW_ITEM_QUARTER_ID,
			setNewItemAmount: mutationTypes.SET_NEW_ITEM_AMOUNT,
			setNewItemComment: mutationTypes.SET_NEW_ITEM_COMMENT
		}),
		getItemsByPenaltyType(penaltyType) {
			return this.formattedItems.filter(x => x.penaltyTypeId === penaltyType.id);
		},
		async handleCreateItem() {
			await this.createItem();
			this.resetCreateItem();
		},
		resetCreateItem() {
			this.resetNewItem();
			this.$refs[this.refs.form].resetValidation();
			this.isNewItemDialogOpened = false;
		},
		async handleDeleteItem() {
			await this.deleteItem({ item: this.itemToDelete });
			this.itemToDelete = null;
			this.isDeleteItemDialogOpened = false;
		},
		handleOpenDeleteItemDialog(item) {
			this.isDeleteItemDialogOpened = true;
			this.itemToDelete = item;
		},
		getSum(items) {
			return items.map(x => x.amount).reduce((x, sum) => sum += +x, 0);
		}
	},
	created() {
		this.initializeStore();
	},
	components: {
		FrpBtn,
		FrpDialog,
		LoanCardLayout,
		FrpTextField,
		FrpDateField,
		FrpAutocomplete,
		FrpIcon,
		LoanPenaltyInterestListLoader
	}
};
</script>

<template>
	<v-row class="px-6">
		<v-col class="d-flex justify-center align-center" style="gap: 24px">
			<frp-range-date-field v-model="assignmentPeriodRange"
								  :label="$t('fields.assignmentPeriod.label')"
								  type="string"
								  dense
								  hide-details
								  hide-title
								  :disabled="isAssignmentCompensationLoading"
								  :placeholder="$t('fields.assignmentPeriod.placeholder')">
			</frp-range-date-field>
			
			<frp-autocomplete v-model="internalFilterValues.assignmentAgreementId"
							  :label="$t('fields.assignmentAgreement.label')"
							  :items="filteredAssignmentAgreements"
							  item-text="name"
							  item-value="id"
							  color="blue"
							  hide-details
							  clearable
							  :disabled="isAssignmentCompensationLoading"
							  :placeholder="$t('fields.assignmentAgreement.placeholder')"
							  style="min-width: 300px">
			</frp-autocomplete>
			
			<frp-autocomplete v-model="internalFilterValues.assigneeId"
							  :label="$t('fields.assignee.label')"
							  :items="formattedAssignmentCompanies"
							  item-text="name"
							  item-value="id"
							  color="blue"
							  hide-details
							  clearable
							  :disabled="isAssignmentCompensationCalculating || isAssignmentCompensationLoading"
							  :placeholder="$t('fields.assignee.placeholder')"
							  style="min-width: 400px">
			</frp-autocomplete>
			
			<v-spacer></v-spacer>
			
			<div class="align-self-end d-flex flex-nowrap">
				<frp-btn @click="resetFilter"
						 :disabled="(isFilterEmpty && !isFilterChanged) || isAssignmentCompensationLoading"
						 color="primary"
						 outlined>
					{{ $t("buttons.clearAll") }}
				</frp-btn>
				<frp-btn elevation="0"
						 color="blue"
						 dark
						 :disabled="!isFilterChanged || isAssignmentCompensationLoading"
						 @click="applyFilter">
					{{ $t("buttons.accept") }}
				</frp-btn>
			</div>
		</v-col>
	</v-row>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpAutocomplete from "@/components/fields/FrpAutocomplete.vue";
import FrpRangeDateField from "@/components/fields/FrpRangeDateField.vue";
import { listMixin } from "@/mixins/listMixin";
import { getterTypes, mutationTypes, namespace } from "@/store/loan/modules/assignmentCompensation/types";
import { formatDate } from "@/utils/dates";
import { isoDateFormat } from "@/utils/formats";
import { format } from "date-fns";
import { assign, isEqual } from "lodash";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [listMixin],
	props: {
		isAssignmentCompensationLoading: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			namespace,
			internalFilterValues: {
				assignmentPeriodStartDate: "",
				assignmentPeriodEndDate: "",
				assignmentAgreementId: "",
				assigneeId: ""
			}
		};
	},
	computed: {
		...mapState({
			state: state => state,
			isAssignmentCompensationCalculating: state => state.isAssignmentCompensationCalculating,
			isOrderForSubmissionToAccountingCreating: state => state.isOrderForSubmissionToAccountingCreating,
			lastQuarter: state => state.lastQuarter
		}),
		...mapGetters({
			formattedAssignmentAgreements: getterTypes.formattedAssignmentAgreements,
			formattedAssignmentCompanies: getterTypes.formattedAssignmentCompanies
		}),
		assignmentPeriodRange: {
			get() {
				return [this.internalFilterValues.assignmentPeriodStartDate, this.internalFilterValues.assignmentPeriodEndDate];
			},
			set([startDate, endDate]) {
				this.internalFilterValues.assignmentPeriodStartDate = startDate || this.defaultAssignmentPeriodStartDate;
				this.internalFilterValues.assignmentPeriodEndDate = endDate || this.defaultAssignmentPeriodEndDate;
			}
		},
		defaultAssignmentPeriodStartDate() {
			const lastQuarter = this.lastQuarter?.startDate;
			
			return lastQuarter ? formatDate(this.lastQuarter.startDate, isoDateFormat) : this.defaultAssignmentPeriodEndDate;
		},
		defaultAssignmentPeriodEndDate() {
			return formatDate(Date.now(), isoDateFormat);
		},
		filteredAssignmentAgreements() {
			// исходя из выбранного цессионария из списка мы ищем выбранную компанию, далее фильтруем договоры цессии (formattedAssignmentAgreements), которые относятся к текущему цессионарию (assignmentCompany)
			const filterAssignmentCompanyId = this.internalFilterValues.assigneeId;
			
			if(filterAssignmentCompanyId) {
				const { assignmentAgreements } = this.formattedAssignmentCompanies.find(assignmentCompany => assignmentCompany.id ===
					filterAssignmentCompanyId);
				
				if(assignmentAgreements && assignmentAgreements.length)
					return this.formattedAssignmentAgreements.filter(
						assignmentAgreement => assignmentAgreements.includes(+assignmentAgreement.id));
			}
			
			return this.formattedAssignmentAgreements;
		},
		filter() {
			return {
				assignmentPeriodStartDate: this.internalFilterValues.assignmentPeriodStartDate,
				assignmentPeriodEndDate: this.internalFilterValues.assignmentPeriodEndDate,
				assignmentAgreementId: this.internalFilterValues.assignmentAgreementId,
				assigneeId: this.internalFilterValues.assigneeId
			};
		},
		isFilterChanged() {
			return !isEqual(Object.fromEntries(Object.entries(this.internalFilterValues).map(([k, v]) => [k, v || ""])),
				assign({}, this.filterValues));
		},
		isFilterEmpty() {
			return Object.values(this.filterValues).every(x => !x);
		}
	},
	methods: {
		format,
		...mapMutations({
			setFilterAssignmentPeriodStartDate: mutationTypes.SET_FILTER_ASSIGNMENT_PERIOD_START_DATE,
			setFilterAssignmentPeriodEndDate: mutationTypes.SET_FILTER_ASSIGNMENT_PERIOD_END_DATE,
			setFilterAssignmentAgreementId: mutationTypes.SET_FILTER_ASSIGNMENT_AGREEMENT_ID,
			setFilterAssigneeId: mutationTypes.SET_FILTER_ASSIGNEE_ID
		}),
		setInternalFilterValues() {
			Object.keys(this.internalFilterValues).forEach(key => this.internalFilterValues[key] = this.filterValues[key]);
		},
		applyFilter() {
			this.setFilterAssignmentPeriodStartDate(this.internalFilterValues.assignmentPeriodStartDate ||
				this.defaultAssignmentPeriodStartDate);
			this.setFilterAssignmentPeriodEndDate(this.internalFilterValues.assignmentPeriodEndDate || this.defaultAssignmentPeriodEndDate);
			this.setFilterAssignmentAgreementId(this.internalFilterValues.assignmentAgreementId || "");
			this.setFilterAssigneeId(this.internalFilterValues.assigneeId || "");
		},
		resetFilter() {
			this.internalFilterValues.assignmentPeriodStartDate = this.defaultAssignmentPeriodStartDate;
			this.internalFilterValues.assignmentPeriodEndDate = this.defaultAssignmentPeriodEndDate;
			this.internalFilterValues.assignmentAgreementId = "";
			this.internalFilterValues.assigneeId = "";
			this.applyFilter();
		}
	},
	components: { FrpBtn, FrpRangeDateField, FrpAutocomplete }
};
</script>

<style scoped>

</style>

<template>
	<v-list one-line>
		<v-list-item v-for="(item, index) in selectedDocument?.documentFiles"
					 :key="`${index + 1}_${item.id}_file_history`"
					 class="px-0">
			<v-list-item-content>
				<v-list-item-title>
					<span>
						{{ `${index + 1}. ` }}
					</span>
					<a :href="`/documents/document/${item.documentId}`"
					   class="reports-link"
					   target="_blank">
						{{ item.fileName }}
					</a>
				</v-list-item-title>
				
				<v-list-item-subtitle>
					<span v-if="item.signer">{{ `${$t("common.signed")}: ${item.signer}. ` }}</span>
					<span v-if="item.uploader">
						<span>{{ `${$t("common.uploaded")}: ${item.uploader.lastName} ${item.uploader.firstName}, ` }}</span>
						<span>{{ formatDate(item.uploadDateTime, dateWithSecondsFormat) }}</span>
					</span>
				</v-list-item-subtitle>
			</v-list-item-content>
			
			<v-list-item-action v-if="item.signer && item.documentId">
				<frp-btn :href="`/documents/document/${item.documentId}/signature`"
						 color="primary"
						 elevation="0"
						 outlined
						 target="_blank">
					{{ $t("buttons.signature") }}
				</frp-btn>
			</v-list-item-action>
		</v-list-item>
	</v-list>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import colorsMixin from "@/mixins/colorsMixin";
import ReportDocument from "@/store/loan/modules/reports/types/reportDocument";
import ReportFinalDocument from "@/store/loan/modules/reportsFinal/types/reportFinalDocument";
import { formatDate } from "@/utils/dates";
import { dateWithSecondsFormat } from "@/utils/formats";

export default {
	mixins: [colorsMixin],
	props: {
		selectedDocument: {
			type: ReportDocument | ReportFinalDocument,
			required: true
		}
	},
	data() {
		return {
			formatDate,
			dateWithSecondsFormat
		};
	},
	components: { FrpBtn }
};
</script>

<style scoped lang="scss">

</style>

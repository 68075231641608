<template>
	<loan-content-layout v-if="isInitialized">
		<v-row>
			<v-col>
				<v-card class="d-flex align-center justify-space-between pl-8 pr-4 pt-4 pb-4 loan-card" elevation="0">
					<frp-autocomplete :label="$t('fields.paymentsType.label')"
									  :placeholder="$t('fields.paymentsType.placeholder')"
									  item-value="value"
									  item-text="text"
									  color="blue"
									  :items="paymentsTypes"
									  v-model="paymentsFilterType">
					</frp-autocomplete>

					<v-spacer></v-spacer>

					<frp-btn color="primary"
							 outlined
							 v-if="can(Permissions.LOAN_UPDATE)"
							 @click="isImportTreasuryStatementDialogOpened = true">
						{{ $t("buttons.importTreasuryStatement") }}
					</frp-btn>
					<frp-btn elevation="0"
							 color="blue"
							 dark
							 :loading="isTreasuryStatementProcessing"
							 :disabled="items.every(x => !x.isFinal)"
							 v-if="can(Permissions.LOAN_UPDATE)"
							 @click="isHandleTreasuryStatementDialogOpened = true">
						{{ $t("buttons.handleTreasuryStatement") }}
					</frp-btn>
				</v-card>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="4" xl="4" md="5" sm="6" class="px-0 pb-0">
				<loan-card-layout>
					<template #title>
						<span style="font-size: 1rem">{{ $t("titles.treasuryStatementRecords") }}</span>
					</template>

					<template #content>
						<v-card class="mt-1 loan-card fill-height" elevation="0" color="py-3 px-4">
							<div class="d-flex justify-space-between py-3 px-3">
								<span class="text-body-2 primary--text">{{ $t("details.titles.treasuryStatementRecordsCount") }}</span>
								<span class="text-body-1 font-weight-bold grey--text text--darken-3">{{ items.length || "-" }}</span>
							</div>
							<div class="d-flex justify-space-between py-3 px-3">
								<span class="text-body-2 primary--text">{{ $t("details.titles.treasuryStatementOrderRecordSum") }}</span>
								<span class="text-body-1 font-weight-bold grey--text text--darken-3">{{ orderRecordSum || "-" }}</span>
							</div>
							<div class="d-flex justify-space-between py-3 px-3">
								<span class="text-body-2 primary--text">{{ $t("details.titles.treasuryStatementPaymentRecordSum") }}</span>
								<span class="text-body-1 font-weight-bold grey--text text--darken-3">{{ paymentRecordSum || "-" }}</span>
							</div>
						</v-card>
					</template>
				</loan-card-layout>
			</v-col>
			<v-col cols="3" xl="3" md="5" sm="6" class="px-0 pb-0">
				<loan-card-layout class="fill-height">
					<template #title>
						<span style="font-size: 1rem">{{ $t("titles.treasuryStatementPayments") }}</span>
					</template>

					<template #content>
						<v-card class="mt-1 loan-card fill-height" elevation="0" color="py-3 px-4">
							<div class="d-flex justify-space-between py-3 px-3">
								<span class="text-body-2 primary--text">{{ $t("details.titles.treasuryStatementFinalPaymentsSum") }}</span>
								<span class="text-body-1 font-weight-bold grey--text text--darken-3">{{ finalPaymentsSum || "-" }}</span>
							</div>
							<div class="d-flex justify-space-between py-3 px-3">
								<span class="text-body-2 primary--text">
									{{ $t("details.titles.treasuryStatementNotFinalPaymentsSum") }}
								</span>
								<span class="text-body-1 font-weight-bold grey--text text--darken-3">{{ notFinalPaymentsSum || "-" }}</span>
							</div>
						</v-card>
					</template>
				</loan-card-layout>
			</v-col>
		</v-row>

		<v-row class="mt-0">
			<v-col class="pt-0" v-if="!isRecordsEmpty">
				<loan-treasury-statement-record v-for="record in items"
												:key="record.id"
												:record="record">
				</loan-treasury-statement-record>
			</v-col>
			<v-col class="mt-2" v-else>
				<v-card elevation="0" class="py-12 px-4 loan-card justify-center align-center d-flex">
					<span class="text-body-2 grey--text">{{ $t("content.noNotes") }}</span>
				</v-card>
			</v-col>
		</v-row>

		<frp-dialog v-model="isImportTreasuryStatementDialogOpened"
					max-width="568"
					persistent
					:title="$t(`dialogs.importTreasuryStatement.title`)">
			<template #content>
				<loan-dropzone :formats="['bd']"
							   max-size="25"
							   type="importTreasuryStatement"
							   :file="file"
							   @file:add="addFile"
							   @file:delete="deleteFile">
				</loan-dropzone>
			</template>

			<template #footer>
				<frp-btn color="primary"
						 outlined
						 @click="handleCloseImportDialog">
					{{ $t("buttons.cancel") }}
				</frp-btn>

				<v-spacer></v-spacer>

				<frp-btn elevation="0"
						 color="blue"
						 dark
						 :disabled="!file"
						 @click="handleClearImportForm">
					{{ $t("buttons.clearForm") }}
				</frp-btn>
				<frp-btn elevation="0"
						 :disabled="!file"
						 color="blue"
						 dark
						 :loading="isFileLoading"
						 @click="handleUploadFileTreasuryStatement">
					{{ $t("buttons.upload") }}
				</frp-btn>
			</template>
		</frp-dialog>

		<frp-dialog v-model="isHandleTreasuryStatementDialogOpened"
					max-width="568"
					persistent
					:title="$t(`dialogs.handleTreasuryStatement.title`)">

			<template #content>
				<span class="primary--text">
					{{ $t("dialogs.handleTreasuryStatement.text") }}
				</span>
			</template>

			<template #footer>
				<frp-btn color="primary"
						 outlined
						 @click="isHandleTreasuryStatementDialogOpened = false">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				<frp-btn class="elevation-0"
						 color="blue"
						 dark
						 :loading="isTreasuryStatementProcessing"
						 @click="handleProcessingTreasuryStatement">
					{{ $t("buttons.finishProcessing") }}
				</frp-btn>
			</template>
		</frp-dialog>
	</loan-content-layout>
	<loan-treasury-statement-loader v-else></loan-treasury-statement-loader>
</template>

<script>
import LoanContentLayout from "@/components/layouts/LoanContentLayout";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpAutocomplete from "Components/fields/FrpAutocomplete";
import FrpIcon from "Components/icon/FrpIcon";
import LoanCardLayout from "Components/layouts/LoanCardLayout";
import FrpDialog from "@/components/dialogs/FrpDialog";
import authorizationMixin from "Mixins/authorizationMixin";
import formMixin from "Mixins/formMixin";
import { listMixin } from "Mixins/listMixin";
import { i18n } from "Plugins/index";
import { TreasuryStatementOperationType } from "Store/loan/modules/treasuryStatement/types/treasuryStatementOperationType";
import { TreasuryStatementPaymentsType } from "Store/loan/modules/treasuryStatement/types/treasuryStatementPaymentsType";
import { formatCurrency } from "Utils/formatting";
import LoanTreasuryStatementLoader from "Views/loan/treasuryStatement/LoanTreasuryStatementLoader";
import { namespace, getterTypes, mutationTypes, actionTypes } from "Store/loan/modules/treasuryStatement/types";
import LoanTreasuryStatementRecord from "Views/loan/treasuryStatement/LoanTreasuryStatementRecord";
import LoanDropzone from "@/components/dropzone/LoanDropzone";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	metaInfo: {
		title: i18n.t("metaTitles.treasuryStatement")
	},
	mixins: [listMixin, formMixin, authorizationMixin],
	data() {
		return {
			namespace,
			formatCurrency,
			isImportTreasuryStatementDialogOpened: false,
			isHandleTreasuryStatementDialogOpened: false,
			paymentsTypes: [
				{ text: this.$t("aliases.treasuryStatementPaymentsType.ALL"), value: "ALL" },
				{ text: this.$t("aliases.treasuryStatementPaymentsType.NOT_FINAL"), value: "NOT_FINAL" },
				{ text: this.$t("aliases.treasuryStatementPaymentsType.FINAL"), value: "FINAL" }
			]
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			state: state => state,
			file: state => state.file.data,
			fileId: state => state.fileId,
			isFileLoading: state => state.file.isLoading,
			isTreasuryStatementProcessing: state => state.isTreasuryStatementProcessing,
			items: state => state.listing.items,
			paymentsFilterType: state => state.paymentsFilterType
		}),
		paymentsFilterType: {
			get() {
				return this.state.paymentsFilterType;
			},
			set(value) {
				if(value)
					this.setPaymentsFilterType(value);
			}
		},
		finalPaymentsSum() {
			return formatCurrency(this.items.reduce((sum1, x) => sum1 +=
				x.payments.reduce((sum2, y) => sum2 += y.isFinal ? y.amount : 0, 0), 0));
		},
		notFinalPaymentsSum() {
			return formatCurrency(this.items.reduce((sum1, x) => sum1 +=
				x.payments.reduce((sum2, y) => sum2 += !y.isFinal ? y.amount : 0, 0), 0));
		},
		paymentRecordSum() {
			return formatCurrency(this.items.reduce((sum, x) => sum +=
				x.typeDoc === TreasuryStatementOperationType.PAYOUT ? x.payment : 0, 0));
		},
		orderRecordSum() {
			return formatCurrency(this.items.reduce((sum, x) => sum +=
				x.typeDoc === TreasuryStatementOperationType.INCOME ? x.payment : 0, 0));
		},
		isRecordsEmpty() {
			return !this.items.length || !this.items.some(x => {
				return this.paymentsFilterType === TreasuryStatementPaymentsType.ALL ||
					(this.paymentsFilterType === TreasuryStatementPaymentsType.FINAL && x.isFinal) ||
					(this.paymentsFilterType === TreasuryStatementPaymentsType.NOT_FINAL && !x.isFinal);
			});
		}
	},
	methods: {
		...mapActions({
			initializeStore: actionTypes.initialize,
			handleUploadTreasuryStatement: actionTypes.handleUploadTreasuryStatement,
			handleTreasuryStatement: actionTypes.handleTreasuryStatement,
			cancelTreasuryStatementImport: actionTypes.cancelTreasuryStatementImport
		}),
		...mapMutations({
			setPaymentsFilterType: mutationTypes.SET_PAYMENTS_FILTER_TYPE,
			deleteFile: mutationTypes.DELETE_FILE_DATA,
			addFile: mutationTypes.SET_FILE_DATA
		}),
		async handleUploadFileTreasuryStatement() {
			await this.handleUploadTreasuryStatement();
			if(this.file) {
				this.deleteFile();
				this.isImportTreasuryStatementDialogOpened = false;
			}
		},
		async handleProcessingTreasuryStatement() {
			await this.handleTreasuryStatement();
			this.isHandleTreasuryStatementDialogOpened = false;
		},
		handleCloseImportDialog() {
			this.cancelTreasuryStatementImport();
			this.deleteFile();
			this.isImportTreasuryStatementDialogOpened = false;
		},
		handleClearImportForm() {
			this.cancelTreasuryStatementImport();
			this.deleteFile();
		}
	},
	created() {
		this.initializeStore();
	},
	components: {
		LoanDropzone,
		LoanContentLayout,
		LoanTreasuryStatementRecord,
		FrpIcon,
		FrpBtn,
		FrpAutocomplete,
		LoanTreasuryStatementLoader,
		LoanCardLayout,
		FrpDialog
	}
};
</script>

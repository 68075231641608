import { ApiAssignmentAgreement } from "@/api/loan/types/dictionaries/apiAssignmentAgreement";
import { convertToZonedTimestamp } from "@/utils/dates";

export interface AssignmentAgreement {
	id: string;
	dateCreate: number;
	number: string;
	agreementDate: number;
	rateIncrease: number;
	isActive: boolean;
}

export class AssignmentAgreementMapper {
	static map(source: ApiAssignmentAgreement): AssignmentAgreement {
		return {
			...source,
			id: source.id.toString(),
			dateCreate: convertToZonedTimestamp(source.dateCreate) as number,
			agreementDate: convertToZonedTimestamp(source.agreementDate) as number
		};
	}
}
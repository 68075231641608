import IPageState from "@/store/shared/base/types/pageState";
import { MainInfoModeType } from "@/store/loan/modules/troubledBorrower/modules/mainInfo/types/mainInfoModeType";
import FormState from "@/store/shared/form/models/formState";
import TroubledBorrowerVersion from "@/store/loan/modules/troubledBorrower/types/troubledBorrowerVersion";

export default class MainInfoState implements IPageState {
	constructor(
		public form: FormState,
		public snapshot: object,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public mode: MainInfoModeType = MainInfoModeType.READ,
		public editableVersion: TroubledBorrowerVersion = new TroubledBorrowerVersion()
	)
	{
	}
}

export default class ApiAccruedInterest {
	id: string;
	isActive: boolean;
	projectId: number;
	number: number;
	financingSourceId: number;
	quarterId: string;
	accrualTypeId: string;
	accrualDate: string;
	paymentDate: string;
	accountedDate: string;
	accruedAmount: number;
	comment: string;

	constructor(
		id: string = "",
		isActive: boolean = false,
		projectId: number = 0,
		number: number = 0,
		financingSourceId: number = 0,
		quarterId: string = "",
		accrualTypeId: string = "",
		accrualDate: string = "",
		paymentDate: string = "",
		accountedDate: string = "",
		accruedAmount: number = 0,
		comment: string = ""
	)
	{
		this.id = id;
		this.isActive = isActive;
		this.projectId = projectId;
		this.number = number;
		this.financingSourceId = financingSourceId;
		this.quarterId = quarterId;
		this.accrualTypeId = accrualTypeId;
		this.accrualDate = accrualDate;
		this.paymentDate = paymentDate;
		this.accountedDate = accountedDate;
		this.accruedAmount = accruedAmount;
		this.comment = comment;
	}
}

import { addProfile, createMap, createMapper, forMember, mapFrom, MappingProfile } from "@automapper/core";
import { classes } from "@automapper/classes";
import { convertToTimestamp, convertToZonedIso, formatDate } from "@/utils/dates";
import ApiReportDocumentFile from "@/api/loan/types/reports/apiReportDocumentFile";
import ReportAddQueryInfo from "@/store/loan/modules/reportsFinal/types/reportAddQueryInfo";
import ApiReportAddQueryInfo from "@/api/loan/types/reports/apiReportAddQueryInfo";
import { isoDateFormat } from "@/utils/formats";
import ApiReportFinal from "@/api/loan/types/reports/apiReportFinal";
import ReportFinal from "@/store/loan/modules/reportsFinal/types/reportFinal";
import ApiReportFinalDocument from "@/api/loan/types/reports/apiReportFinalDocument";
import ReportFinalDocument from "@/store/loan/modules/reportsFinal/types/reportFinalDocument";
import ReportFinalDocumentFile from "@/store/loan/modules/reportsFinal/types/reportFinalDocumentFile";

const mapper = createMapper({
	strategyInitializer: classes()
});

const reportsProfile: MappingProfile = (mapper) => {
	createMap(mapper, ApiReportFinal, ReportFinal,
		forMember(d => d.documents, mapFrom(x => x.documents.map(doc => mapper.map(doc, ApiReportFinalDocument, ReportFinalDocument)))),
		forMember(d => d.project, mapFrom(x => x.project)),
		forMember(d => d.acceptance, mapFrom(x => x.acceptance)),
		forMember(d => d.canCreateReportingQueries, mapFrom(x => x.canCreateReportingQueries)),
		forMember(d => d.reportQueries, mapFrom(x => x.reportQueries))
	);

	createMap(mapper, ReportFinal, ApiReportFinal,
		forMember(d => d.documents, mapFrom(x => x.documents.map(doc => mapper.map(doc, ReportFinalDocument, ApiReportFinalDocument)))),
		forMember(d => d.project, mapFrom(x => x.project)),
		forMember(d => d.acceptance, mapFrom(x => x.acceptance)),
		forMember(d => d.canCreateReportingQueries, mapFrom(x => x.canCreateReportingQueries)),
		forMember(d => d.reportQueries, mapFrom(x => x.reportQueries))
	);

	createMap(mapper, ApiReportDocumentFile, ReportFinalDocumentFile,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.fileName, mapFrom(x => x.fileName)),
		forMember(d => d.uploader, mapFrom(x => x.uploader)),
		forMember(d => d.uploadDateTime, mapFrom(x => convertToTimestamp(x.uploadDateTime))),
		forMember(d => d.signer, mapFrom(x => x.signer)),
		forMember(d => d.documentId, mapFrom(x => x.documentId))
	);

	createMap(mapper, ReportFinalDocumentFile, ApiReportDocumentFile,
		forMember(d => d.id, mapFrom(x => x.id)),
		forMember(d => d.fileName, mapFrom(x => x.fileName)),
		forMember(d => d.uploader, mapFrom(x => x.uploader)),
		forMember(d => d.uploadDateTime, mapFrom(x => convertToZonedIso(x.uploadDateTime))),
		forMember(d => d.signer, mapFrom(x => x.signer)),
		forMember(d => d.documentId, mapFrom(x => x.documentId))
	);

	createMap(mapper, ApiReportFinalDocument, ReportFinalDocument,
		forMember(d => d.description, mapFrom(x => x.description)),
		forMember(d => d.canUploadFile, mapFrom(x => x.canUploadFile)),
		forMember(d => d.canUploadGeneratedFile, mapFrom(x => x.canUploadGeneratedFile)),
		forMember(d => d.canGenerate, mapFrom(x => x.canGenerate)),
		forMember(d => d.isSignEnabled, mapFrom(x => x.isSignEnabled)),
		forMember(d => d.isSignRequired, mapFrom(x => x.isSignRequired)),
		forMember(d => d.uploadDateTime, mapFrom(x => convertToTimestamp(x.uploadDateTime))),
		forMember(d => d.documentFiles, mapFrom(x => x.documentFiles.map(d => mapper.map(d, ApiReportDocumentFile, ReportFinalDocumentFile))))
	);

	createMap(mapper, ReportFinalDocument, ApiReportFinalDocument,
		forMember(d => d.description, mapFrom(x => x.description)),
		forMember(d => d.canUploadFile, mapFrom(x => x.canUploadFile)),
		forMember(d => d.canUploadGeneratedFile, mapFrom(x => x.canUploadGeneratedFile)),
		forMember(d => d.canGenerate, mapFrom(x => x.canGenerate)),
		forMember(d => d.isSignEnabled, mapFrom(x => x.isSignEnabled)),
		forMember(d => d.isSignRequired, mapFrom(x => x.isSignRequired)),
		forMember(d => d.uploadDateTime, mapFrom(x => convertToZonedIso(x.uploadDateTime))),
		forMember(d => d.documentFiles, mapFrom(x => x.documentFiles.map(d => mapper.map(d, ReportFinalDocumentFile, ApiReportDocumentFile))))
	);

	createMap(mapper, ReportAddQueryInfo, ApiReportAddQueryInfo,
		forMember(d => d.title, mapFrom(x => x.title)),
		forMember(d => d.description, mapFrom(x => x.description)),
		forMember(d => d.executionDate, mapFrom(x => formatDate(x.executionDate, isoDateFormat)))
	);
};

addProfile(mapper, reportsProfile);

export default mapper;

import ApiTreasuryStatementRecord from "@/api/loan/types/paymentsProcessing/apiTreasuryStatementRecord";
import { Type } from "class-transformer";

export default class ApiImportTreasuryStatementResponse {
	fileId: string;
	@Type(() => ApiTreasuryStatementRecord)
	records: ApiTreasuryStatementRecord[];

	constructor(
		fileId: string = "",
		records: ApiTreasuryStatementRecord[] = [],
	)
	{
		this.fileId = fileId;
		this.records = records;
	}
}

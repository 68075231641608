export default class ApiChangeReason {
	id: string;
	number: number;
	name: string;

	constructor(
		id: string = "",
		number: number = 0,
		name: string = ""
	)
	{
		this.id = id;
		this.number = number;
		this.name = name;
	}
}

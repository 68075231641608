import IPageState from "@/store/shared/base/types/pageState";
import { OverdueInterestModeType } from "@/store/loan/modules/overdueInterest/types/overdueInterestModeType";
import ListingModel from "@/store/shared/listing/models/listingModel";
import OverdueInterest from "@/store/loan/modules/overdueInterest/modules/overdueInterestIList/types/overdueInterest";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import FormState from "@/store/shared/form/models/formState";
import SortingModel from "@/store/shared/sorting/models/sortingModel";

export default class OverdueInterestListState implements IPageState {
	constructor(
		public listing: ListingModel<OverdueInterest>,
		public sorting: SortingModel<String[]>,
		public paging: PagingModel,
		public search: SearchModel,
		public form: FormState,
		public snapshot: object,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public newItem: OverdueInterest = new OverdueInterest(),
		public editableItems: OverdueInterest[] = [],
		public isItemsUpdating: boolean = false,
		public isItemCreating: boolean = false,
		public isItemDeleting: boolean = false,
		public mode: OverdueInterestModeType = OverdueInterestModeType.READ,
	)
	{
	}
}

export enum TitleDocuments {
	/// Документ-основание отсутствует
	None = 0,
	/// Договор займа
	LoanAgreement = 1,
	/// Дополнительное соглашение
	AdditionalAgreement = 2,
	/// Мировое соглашение
	SettlementAgreement = 3,
	/// Требование / исполнительный лист
	Requirement = 4,
	/// Договор уступки
	CessionAgreement = 5,
}

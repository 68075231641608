import { difference } from "@/utils/difference";
import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import TroubledBorrowersRouteQuery from "@/store/loan/modules/troubledBorrowers/types/troubledBorrowersRouteQuery";
import TroubledBorrowersState from "@/store/loan/modules/troubledBorrowers/types/troubledBorrowersState";

export default class TroubledBorrowersRouteQueryService {
	defaultRouteQuery: TroubledBorrowersRouteQuery;

	constructor(defaultRouteQuery: TroubledBorrowersRouteQuery) {
		this.defaultRouteQuery = defaultRouteQuery;
	}

	resolveRouteQueryDictionary(state: TroubledBorrowersState) {
		let query = new TroubledBorrowersRouteQuery(
			state.paging.page,
			state.filter.companyName,
			state.filter.responsibleUserId
		);

		return difference(this.defaultRouteQuery, query);
	}

	async resolveRouteQuery(query: Dictionary<any>) {
		try {
			let result = plainToInstance(TroubledBorrowersRouteQuery, {
				...this.defaultRouteQuery,
				...query
			}, { enableImplicitConversion: true });

			await validateOrReject(result);

			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}
}

export default class ApiStatusHistoryItem {
	constructor(
		public statusId: string = "",
		public stageTypeId: string = "",
		public stageStatusTypeId: string = "",
		public updatedAt: string = "",
		public modifiedBy: string = "",
		public startDate: string = "",
		public expirationDate: string = ""
	)
	{
	}
}

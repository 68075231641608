export default class AccruedInterestFilter {
	accrualStartDate: string;
	accrualEndDate: string;
	accrualType: string;
	paymentStartDate: string;
	paymentEndDate: string;
	accountedStartDate: string;
	accountedEndDate: string;
	financingSourceId: string;
	quarterId: string;

	constructor(
		accrualStartDate: string = "",
		accrualEndDate: string = "",
		accrualType: string = "",
		paymentStartDate: string = "",
		paymentEndDate: string = "",
		accountedStartDate: string = "",
		accountedEndDate: string = "",
		financingSourceId: string = "",
		quarterId: string = ""
	)
	{
		this.accrualStartDate = accrualStartDate;
		this.accrualEndDate = accrualEndDate;
		this.accrualType = accrualType;
		this.paymentStartDate = paymentStartDate;
		this.paymentEndDate = paymentEndDate;
		this.accountedStartDate = accountedStartDate;
		this.accountedEndDate = accountedEndDate;
		this.financingSourceId = financingSourceId;
		this.quarterId = quarterId;
	}
}

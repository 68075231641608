import { IsInt, Min, Max, IsArray, IsString } from "class-validator";

export default class CashFlowRouteQuery {
	@IsInt()
	@Min(0)
	@Max(1000)
	page: number;

	@IsString()
	filterPaymentType: string;
	@IsString()
	filterStartPaymentDate: string;
	@IsString()
	filterEndPaymentDate: string;
	@IsArray()
	filterQuarterCodeIds: string[];
	@IsArray()
	filterPaymentExpenseIds: string[];

	constructor(
		page: number,
		filterPaymentType: string,
		filterStartPaymentDate: string,
		filterEndPaymentDate: string,
		filterQuarterCodeIds: string[],
		filterPaymentExpenseIds: string[]
	)
	{
		this.page = page;
		this.filterPaymentType = filterPaymentType;
		this.filterStartPaymentDate = filterStartPaymentDate;
		this.filterEndPaymentDate = filterEndPaymentDate;
		this.filterQuarterCodeIds = filterQuarterCodeIds;
		this.filterPaymentExpenseIds = filterPaymentExpenseIds;
	}
}

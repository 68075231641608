export default class ApiRefundStatusTypeHistory {
	id: string;
	refundStatusTypeId?: number;
	createdBy: { id: number, name: string };
	createdAt: string;

	constructor(id: string, refundStatusTypeId: number, createdBy: { id: number, name: string }, createdAt: string) {
		this.id = id;
		this.refundStatusTypeId = refundStatusTypeId;
		this.createdAt = createdAt;
		this.createdBy = createdBy;
	}
}

<template>
	<div>
		<v-autocomplete :items="internalItems"
						ref="frpDayAutocomplete"
						:placeholder="placeholder"
						outlined
						:background-color="colors.white.base"
						:clearable="clearable"
						dense
						@input="$emit('update:value', $event)"
						:value="value"
						v-mask="`##`"
						:rules="[...defaultRules, ...rules]"
						class="frp-day-autocomplete"
						:class="{ 'frp-dense-field': dense}"
						:hide-details="true"
						:disabled="disabled"
						:readonly="readonly"
						v-bind="$attrs"
						v-on="$listeners"
						:loading="loading"
						@update:search-input="query = $event"
						@mousedown="open"
						@focus="open"
						@blur="close"
						:menu-props="{ contentClass, auto }">
			<template #item="{ item, on, attrs }">
				<v-list-item @click="close" v-on="on" v-bind="attrs">
					<span>{{ item }}</span>
				</v-list-item>
			</template>
		</v-autocomplete>
	</div>
</template>

<script>
import FrpIcon from "Components/icon/FrpIcon";
import colorsMixin from "Mixins/colorsMixin";
import { requiredRule } from "Utils/validation";

export default {
	inheritAttrs: false,
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	data() {
		return {
			isOpened: false,
			items: [],
			internalItems: [],
			query: ""
		};
	},
	props: {
		rules: {
			type: Array,
			default: () => []
		},
		placeholder: String,
		disabled: Boolean,
		required: Boolean,
		clearable: Boolean,
		value: [Object, String, Number, Array],
		dense: Boolean,
		loading: Boolean,
		auto: {
			type: Boolean,
			default: false
		},
		readonly: {
			type: Boolean,
			default: false
		},
		color: String
	},
	computed: {
		defaultRules() {
			if(this.required) {
				return [requiredRule()];
			} else {
				return [];
			}
		},
		contentClass() {
			if(this.color === "blue")
				return "frp-menu frp-blue-menu";
			
			return "frp-menu";
		}
	},
	methods: {
		close() {
			this.isOpened = false;
		},
		open() {
			if(!this.disabled)
				this.isOpened = true;
		},
		updateItems() {
			if(!this.query)
				this.internalItems = this.items;
			else 
				this.internalItems = this.items.filter(x => x.includes(this.query));
			
			if(this.value) {
				const currentItem = this.items.find(x => x === this.value);
				if(currentItem && !this.internalItems.some(x => x === currentItem))
					this.internalItems.push(currentItem);
			}
			
		}
	},
	watch: {
		items() {
			this.updateItems();
			
			if(this.value && !this.internalItems.some(x => x === this.value))
				this.$emit("update:value", null);
		},
		query() {
			this.updateItems();
		},
		value() {
			this.$emit("update:field-is-valid", this.$refs.frpDayAutocomplete.valid);
			
			this.updateItems();
		}
	},
	created() {
		for (let i = 0; i <= 31; i++) {
			this.items.push(String(i));
		}
	},
	components: {
		FrpIcon
	}
};
</script>
<style lang="scss">
.frp-day-autocomplete {
	width: 42px !important;

	fieldset {
		width: 42px !important;
	}

	.v-input__append-inner {
		display: none !important;
	}
}
</style>

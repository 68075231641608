import { IsIn, IsInt, IsString, Max, Min } from "class-validator";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import { AVAILABLE_SORTS } from "@/store/loan/modules/agreementsAccruedInterest/constants";

export default class AgreementsAccruedInterestRouteQuery {
	@IsString()
	@IsIn(AVAILABLE_SORTS)
	sort: String;
	@IsString()
	@IsIn([sortingOrderType.ascending, sortingOrderType.descending])
	sortDirection: String;
	@IsString()
	filterPeriodId: string;
	@IsString()
	filterApplicationNumber: string;
	@IsString()
	filterCompanyName: string;
	@IsString()
	filterAgreementStatusId: string;
	@IsString()
	filterDaysMin: string;
	@IsString()
	filterDaysMax: string;

	constructor(
		sort: String,
		sortDirection: String,
		filterPeriodId: string,
		filterApplicationNumber: string,
		filterCompanyName: string,
		filterAgreementStatusId: string,
		filterDaysMin: string,
		filterDaysMax: string
	)
	{
		this.sort = sort;
		this.sortDirection = sortDirection;
		this.filterPeriodId = filterPeriodId;
		this.filterApplicationNumber = filterApplicationNumber;
		this.filterCompanyName = filterCompanyName;
		this.filterAgreementStatusId = filterAgreementStatusId;
		this.filterDaysMin = filterDaysMin;
		this.filterDaysMax = filterDaysMax;
	}
}

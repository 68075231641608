import { IsString, IsInt, Min, Max } from "class-validator";

export default class AssignmentCompensationRouteQuery {
	@IsInt()
	@Min(0)
	@Max(1000)
	page: number;
	
	@IsString()
	assignmentPeriodStartDate: string;
	@IsString()
	assignmentPeriodEndDate: string;
	@IsString()
	assignmentAgreementId: string;
	@IsString()
	assigneeId: string;
	
	constructor(
		page: number,
		assignmentPeriodStartDate: string,
		assignmentPeriodEndDate: string,
		assignmentAgreementId: string,
		assigneeId: string
	)
	{
		this.page = page;
		this.assignmentPeriodStartDate = assignmentPeriodStartDate;
		this.assignmentPeriodEndDate = assignmentPeriodEndDate;
		this.assignmentAgreementId = assignmentAgreementId;
		this.assigneeId = assigneeId;
	}
}

import ApiAgreementsAccruedInterest from "@/api/loan/types/loanInterest/apiAgreementsAccruedInterest";

export default class ApiAccrueInterestParameters {
	periodId: string;
	rows: ApiAgreementsAccruedInterest[];

	constructor(
		periodId: string = "",
		rows: ApiAgreementsAccruedInterest[] = []
	)
	{
		this.periodId = periodId;
		this.rows = rows;
	}
}

import ApiQuarter from "@/api/loan/types/dictionaries/apiQuarter";
import { convertToZonedTimestamp } from "@/utils/dates";

export interface Quarter {
	id: string;
	title: string;
	startDate: number;
	number: number;
}

export class QuarterMapper {
	static map(source: ApiQuarter): Quarter {
		return {
			...source,
			startDate: convertToZonedTimestamp(source.startDate) as number
		};
	}
}
import { ApiAssigneeCompany } from "@/api/loan/types/dictionaries/apiAssigneeCompany";
import { Company, CompanyMapper } from "@/store/loan/modules/assignmentCompensation/types/company";

export interface AssigneeCompany {
	company: Company;
	assignmentAgreements: number[];
}

export class AssigneeCompanyMapper {
	static map(source: ApiAssigneeCompany): AssigneeCompany {
		return {
			company: CompanyMapper.map(source.company),
			assignmentAgreements: source.assignmentAgreements
		};
	}
}
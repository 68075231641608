import { registerAlertService } from "@/store/modules/alerts/services/alertService";
import Vue from "vue";
import Vuex, { ActionTree, MutationTree } from "vuex";
import breadcrumbsModule from "@/store/loan/modules/breadcrumbs";
import loanScheduleModule from "@/store/loan/modules/loanSchedule";
import accruedInterestModule from "@/store/loan/modules/accruedInterest";
import agreementsAccruedInterestModule from "@/store/loan/modules/agreementsAccruedInterest";
import overdueInterestModule from "@/store/loan/modules/overdueInterest";
import alertsModule from "@/store/modules/alerts";
import cashFlowModule from "@/store/loan/modules/cashFlow";
import treasuryStatementModule from "@/store/loan/modules/treasuryStatement";
import troubledBorrowerModule from "@/store/loan/modules/troubledBorrower";
import troubledBorrowersModule from "@/store/loan/modules/troubledBorrowers";
import { PageModeType } from "@/store/types/pageModeType";
import { mutationTypes } from "@/store/loan/types";
import RootState from "@/store/types/rootState";
import reportsModule from "@/store/loan/modules/reports";
import reportsFinalModule from "@/store/loan/modules/reportsFinal";
import agreementsOverduePaymentsModule from "@/store/loan/modules/agreementsOverduePayments";
import agreementsAccruedPenaltiesModule from "@/store/loan/modules/agreementsAccruedPenalties";
import upcomingPaymentsModule from "@/store/loan/modules/upcomingPayments";
import userModule from "@/store/loan/modules/user";
import assignmentCompensationModule from "@/store/loan/modules/assignmentCompensation";

Vue.use(Vuex);

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new RootState(PageModeType.OK);
	}
}

const state = (new DefaultStateBuilder()).build();

const actions = <ActionTree<RootState, any>>{};

const mutations = <MutationTree<RootState>>{
	[mutationTypes.SET_IS_APP_LOADING](state, value) {
		state.isAppLoading = value;
	},
	[mutationTypes.SET_PAGE_MODE](state, value) {
		state.pageMode = value;
	},
	[mutationTypes.RESET_PAGE_MODE](state) {
		state.pageMode = PageModeType.OK;
	},
	[mutationTypes.SET_PAGE_MODE_NOT_FOUND](state) {
		state.pageMode = PageModeType.PAGE_NOT_FOUND;
	},
	[mutationTypes.SET_PAGE_MODE_ACCESS_FORBIDDEN](state) {
		state.pageMode = PageModeType.ACCESS_DENIED;
	}
};

const store = new Vuex.Store({
	state,
	actions,
	mutations,
	modules: {
		[breadcrumbsModule.namespace]: {
			...breadcrumbsModule
		},
		[loanScheduleModule.namespace]: {
			...loanScheduleModule
		},
		[accruedInterestModule.namespace]: {
			...accruedInterestModule
		},
		[overdueInterestModule.namespace]: {
			...overdueInterestModule
		},
		[agreementsAccruedInterestModule.namespace]: {
			...agreementsAccruedInterestModule
		},
		[cashFlowModule.namespace]: {
			...cashFlowModule
		},
		[treasuryStatementModule.namespace]: {
			...treasuryStatementModule
		},
		[troubledBorrowerModule.namespace]: {
			...troubledBorrowerModule
		},
		[troubledBorrowersModule.namespace]: {
			...troubledBorrowersModule
		},
		[reportsModule.namespace]: {
			...reportsModule
		},
		[reportsFinalModule.namespace]: {
			...reportsFinalModule
		},
		[upcomingPaymentsModule.namespace]: {
			...upcomingPaymentsModule
		},
		[agreementsOverduePaymentsModule.namespace]: {
			...agreementsOverduePaymentsModule
		},
		[agreementsAccruedPenaltiesModule.namespace]: {
			...agreementsAccruedPenaltiesModule
		},
		[assignmentCompensationModule.namespace]: {
			...assignmentCompensationModule
		},
		[userModule.namespace]: {
			...userModule
		},
		[alertsModule.namespace]: {
			...alertsModule
		}
	}
});

const registerSubscribers = (store: any) => {
	breadcrumbsModule.subscribe(store);
	loanScheduleModule.subscribe(store);
	overdueInterestModule.subscribe(store);
	troubledBorrowerModule.subscribe(store);
	
	assignmentCompensationModule.initializeSubscribersManager(store);
	agreementsAccruedInterestModule.initializeSubscribersManager(store);
	accruedInterestModule.initializeSubscribersManager(store);
	cashFlowModule.initializeSubscribersManager(store);
	troubledBorrowersModule.initializeSubscribersManager(store);
	agreementsOverduePaymentsModule.initializeSubscribersManager(store);
	agreementsAccruedPenaltiesModule.initializeSubscribersManager(store);
};
registerAlertService(store);
registerSubscribers(store);

export default store;

import { actionTypes, getterTypes, mutationTypes, namespace } from "@/store/loan/modules/user/types";
import BaseMixinBuilder from "@/store/shared/base";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import AbortService from "@/services/abortService";
import { UserState, UserStateHelper } from "@/store/loan/modules/user/types/userState";
import { LoanAuthorizationController } from "@/api/loan/authorization";
import { LoanRolesTypeEnum } from "@/store/loan/modules/user/types/LoanRolesTypeEnum";

const abortService = new AbortService();
const loanAuthorizationController = new LoanAuthorizationController(abortService);

const baseMixin = (new BaseMixinBuilder(abortService)).build();

const state = UserStateHelper.getEmpty();

const getters = <GetterTree<UserState, any>>{
	[getterTypes.isLoanUserAdministrator]: state => {
		return state.user.roles.includes(LoanRolesTypeEnum.ADMINISTRATOR);
	},
	[getterTypes.isLoanUserBorrower]: state => {
		return state.user.roles.includes(LoanRolesTypeEnum.BORROWER);
	},
	[getterTypes.isLoanUserAssignee]: state => {
		return state.user.roles.includes(LoanRolesTypeEnum.ASSIGNEE);
	}
};

const actions = <ActionTree<UserState, any>>{
	...baseMixin.actions,
	async [actionTypes.initialize]({ commit, dispatch }) {
		await dispatch(actionTypes.initializeBase);
		
		await dispatch(actionTypes.fetchUser);
		
		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.fetchUser]({ commit }) {
		commit(mutationTypes.SET_IS_USER_LOADING, true);
		
		try {
			let user = await loanAuthorizationController.getCurrentUser();
			
			commit(mutationTypes.SET_USER, user);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_USER_LOADING, false);
		}
	}
};

const mutations = <MutationTree<UserState>>{
	...baseMixin.mutations,
	[mutationTypes.SET_IS_USER_LOADING](state, value) {
		state.isUserLoading = value;
	},
	[mutationTypes.SET_USER](state, value) {
		state.user = value;
	}
};

export {
	namespace, state, actions, mutations, getters
};

const userModule = {
	namespace, state, actions, mutations, namespaced: true, getters
};

export default userModule;

import ApiDocumentDescription from "@/api/loan/types/reports/apiDocumentDescription";
import ApiReportDocumentFile from "@/api/loan/types/reports/apiReportDocumentFile";

export default class ApiReportDocument {
	description: ApiDocumentDescription;
	canUploadFile: boolean;
	canUploadGeneratedFile: boolean;
	canGenerate: boolean;
	uploadDateTime: string;
	documentFiles: ApiReportDocumentFile[];
	isSignRequired: boolean;

	constructor(
		description: ApiDocumentDescription = new ApiDocumentDescription(),
		canUploadFile: boolean = false,
		canUploadGeneratedFile: boolean = false,
		canGenerate: boolean = false,
		uploadDateTime: string = "",
		documentFiles: ApiReportDocumentFile[] = [],
		isSignRequired: boolean = false,
	)
	{
		this.description = description;
		this.canUploadFile = canUploadFile;
		this.canUploadGeneratedFile = canUploadGeneratedFile;
		this.canGenerate = canGenerate;
		this.uploadDateTime = uploadDateTime;
		this.documentFiles = documentFiles;
		this.isSignRequired = isSignRequired;
	}
}

export default class ApiExtraRateParameters {
	projectId: number;
	accrualStartDate: string;
	accrualEndDate: string;
	extraInterestRate: number;
	paymentDate: string;

	constructor(
		projectId: number = 0,
		accrualStartDate: string = "",
		accrualEndDate: string = "",
		extraInterestRate: number = 0,
		paymentDate: string = ""
	)
	{
		this.projectId = projectId;
		this.accrualStartDate = accrualStartDate;
		this.accrualEndDate = accrualEndDate;
		this.extraInterestRate = extraInterestRate;
		this.paymentDate = paymentDate;
	}
}

import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import urls, { urlTemplateParts } from "@/api/config/urls";
import { plainToInstance } from "class-transformer";
import ApiCashFlowItem from "@/api/loan/types/paymentsProcessing/apiCashFlowItem";
import ApiTreasuryStatementPayment from "@/api/loan/types/paymentsProcessing/apiTreasuryStatementPayment";
import ApiGetCashFlowItemsParameters from "@/api/loan/types/paymentsProcessing/apiGetCashFlowItemsParameters";
import ApiGetTreasuryStatementRecordsParameters from "@/api/loan/types/paymentsProcessing/apiGetTreasuryStatementRecordsParameters";
import ApiImportTreasuryStatementResponse from "@/api/loan/types/paymentsProcessing/apiImportTreasuryStatementResponse";
import { prepareFormData } from "@/utils/prepareFormData";
import { getFormDataHeaders } from "@/utils/getFormDataHeaders";
import { prepareUrl } from "@/utils/prepareUrlQuery";
import ApiGetCashFlowItemsResponse from "@/api/loan/types/paymentsProcessing/apiGetCashFlowItemsResponse";

export class LoanPaymentsProcessingController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}

	getCashFlowItems = async (projectId: string, parameters: ApiGetCashFlowItemsParameters): Promise<ApiGetCashFlowItemsResponse> => {
		let data = await this.client.post<{ items: ApiCashFlowItem[], type: string }>(urls.loan.paymentsProcessing.cashFlow.get.replace(
			urlTemplateParts.id,
			projectId), parameters);

		return plainToInstance(ApiGetCashFlowItemsResponse, data);
	};

	createCashFlowItem = async (item: ApiCashFlowItem): Promise<ApiCashFlowItem> => {
		const data = await this.client.post(urls.loan.paymentsProcessing.cashFlow.create, item);
		return plainToInstance(ApiCashFlowItem, data);
	};

	updateCashFlowItem = async (paymentId: string, item: ApiCashFlowItem): Promise<ApiCashFlowItem> => {
		const data = this.client.put(urls.loan.paymentsProcessing.cashFlow.update.replace(urlTemplateParts.id, paymentId), item);
		return plainToInstance(ApiCashFlowItem, data);
	};

	deleteCashFlowItem = async (paymentId: string): Promise<void> => {
		return await this.client.delete(urls.loan.paymentsProcessing.cashFlow.delete.replace(urlTemplateParts.id, paymentId));
	};

	createTreasuryStatementPayment = async (fileId: string,
		recordId: string,
		payment: ApiTreasuryStatementPayment): Promise<ApiTreasuryStatementPayment> => {
		const data = await this.client.post(urls.loan.paymentsProcessing.treasuryStatement.createPayment.replace(urlTemplateParts.id,
			fileId).replace(urlTemplateParts.subId, recordId), payment);
		return plainToInstance(ApiTreasuryStatementPayment, data);
	};

	updateTreasuryStatementPayment = async (recordId: string, paymentId: string, fileId: string, payment: ApiTreasuryStatementPayment): Promise<ApiTreasuryStatementPayment> => {
		const data = await this.client.put(urls.loan.paymentsProcessing.treasuryStatement.updatePayment.replace(urlTemplateParts.id,
			fileId).replace(urlTemplateParts.subId, recordId).replace(urlTemplateParts.thirdId, paymentId), payment);

		return plainToInstance(ApiTreasuryStatementPayment, data);
	};

	deleteTreasuryStatementPayment = async (recordId: string, paymentId: string, fileId: string): Promise<void> => {
		return await this.client.delete(urls.loan.paymentsProcessing.treasuryStatement.deletePayment.replace(urlTemplateParts.id,
			fileId).replace(urlTemplateParts.subId, recordId).replace(urlTemplateParts.thirdId, paymentId));
	};

	uploadTreasuryStatement = async (parameters: ApiGetTreasuryStatementRecordsParameters): Promise<ApiImportTreasuryStatementResponse> => {
		const url = urls.loan.paymentsProcessing.treasuryStatement.uploadStatement;
		let result = await this.client.post<object>(url, prepareFormData(parameters), getFormDataHeaders());

		return plainToInstance(ApiImportTreasuryStatementResponse, result);
	};

	handleTreasuryStatement = async (fileId: string): Promise<void> => {
		return await this.client.put(urls.loan.paymentsProcessing.treasuryStatement.handleStatement.replace(urlTemplateParts.id, fileId),
			fileId);
	};

	updateRecordStatus = async (fileId: string, recordId: string, isFinal: boolean): Promise<void> => {
		return await this.client.put(prepareUrl(urls.loan.paymentsProcessing.treasuryStatement.updateRecordStatus.replace(urlTemplateParts.id,
			fileId).replace(urlTemplateParts.subId, recordId), { isFinal }), {});
	};

	updateRecordProject = async (fileId: string, recordId: string, projectId: boolean): Promise<void> => {
		const url = urls.loan.paymentsProcessing.treasuryStatement.updateRecordProject.replace(urlTemplateParts.id, fileId).replace(
			urlTemplateParts.subId,
			recordId);
		return await this.client.put(prepareUrl(url, { projectId }), {});
	};
}

export default class ApiRefundSource {
	constructor(
		public id: string = "",
		public refundSourceTypeId?: string,
		public paymentDate: string = "",
		public paymentSum: number = 0
	)
	{
	}
}

import { actionTypes, getterTypes, mutationTypes, namespace } from "@/store/loan/modules/accruedInterest/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationPayload, MutationTree, Store } from "vuex";
import AbortService from "@/services/abortService";
import AccruedInterestState from "@/store/loan/modules/accruedInterest/types/accruedInterestState";
import { LoanInterestController } from "@/api/loan/loanInterest";
import FormMixinBuilder from "@/store/shared/form";
import ListingMixinBuilder from "@/store/shared/listing";
import PagingMixinBuilder from "@/store/shared/paging";
import SearchMixinBuilder from "@/store/shared/search";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import ListingModel from "@/store/shared/listing/models/listingModel";
import AccruedInterest from "@/store/loan/modules/accruedInterest/types/accruedInterest";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import { plainToInstance } from "class-transformer";
import ApiAccruedInterest from "@/api/loan/types/loanInterest/apiAccruedInterest";
import mapper from "@/store/loan/modules/accruedInterest/mapper";
import agreementMapper from "@/store/loan/modules/loanSchedule/modules/agreement/mapper";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import { cloneDeep } from "lodash";
import { DictionariesController } from "@/api/loan/dictionaries";
import router from "@/router/loan";
import BatchService from "@/services/batchService";
import RouteMixinBuilder from "@/store/shared/route";
import AccruedInterestRouteQuery from "@/store/loan/modules/accruedInterest/types/accruedInterestRouteQuery";
import SubscribersManager from "@/store/manager/subscribersManager";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import routeTypes from "@/store/shared/route/types";
import { RouteNames } from "@/router/loan/routes";
import AccruedInterestRouteQueryService from "@/store/loan/modules/accruedInterest/services/accruedInterestRouteQueryService";
import ApiGetAccruedInterestParameters from "@/api/loan/types/loanInterest/apiGetAccruedInterestParameters";
import MassUpdateAccruedInterest from "@/store/loan/modules/accruedInterest/types/massUpdateAccruedInterest";
import ApiMassUpdateAccruedInterest from "@/api/loan/types/loanInterest/apiMassUpdateAccruedInterest";
import ExtraRateParameters from "@/store/loan/modules/accruedInterest/types/extraRateParameters";
import ApiExtraRateParameters from "@/api/loan/types/loanInterest/apiExtraRateParameters";
import ApiAccruedInterestAddItemsParameters from "@/api/loan/types/loanInterest/apiAccruedInterestAddItemsParameters";
import AccruedInterestAddItemsParameters from "@/store/loan/modules/accruedInterest/types/accruedInterestAddItemsParameters";
import { LoanScheduleController } from "@/api/loan/loanSchedule";
import ApiAgreement from "@/api/loan/types/loanSchedule/apiAgreement";
import Agreement from "@/store/loan/modules/loanSchedule/modules/agreement/types/agreement";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import SortingMixinBuilder from "@/store/shared/sorting";
import AccruedInterestFilter from "@/store/loan/modules/accruedInterest/types/accruedInterestFilter";

const abortService = new AbortService();
const loanInterestController = new LoanInterestController(abortService);
const loanScheduleController = new LoanScheduleController(abortService);
const dictionariesController = new DictionariesController(abortService);

const defaultRouteQuery = new AccruedInterestRouteQuery();

const routeQueryService = new AccruedInterestRouteQueryService(defaultRouteQuery);

const updateListingBatchService = new BatchService(({ interval: 100 }));

const routeMixin = (new RouteMixinBuilder<AccruedInterestState>()).build();

const baseMixin = (new BaseMixinBuilder(abortService)).build();
const formMixin = (new FormMixinBuilder()).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["addItemsParameters"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new AccruedInterestState(
			new ListingModel<AccruedInterest>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String[]>({
				type: ["source", "accrualDate", "quarter", "accrualType"],
				order: [sortingOrderType.ascending, sortingOrderType.ascending, sortingOrderType.ascending, sortingOrderType.ascending]
			}),
			new PagingModel({
				total: 0,
				page: defaultRouteQuery.page,
				pageSize: 25
			}),
			new SearchModel({
				query: ""
			}),
			formMixin.state(),
			snapshotMixin.state(),
			routeMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

let subscribersManager: SubscribersManager<AccruedInterestState>;

let unsubscribeCallback = () => {
};
let store: Store<{}>;

const initializeSubscribersManager = (value: Store<{}>) => {
	store = value;
	subscribersManager = new SubscribersManager<AccruedInterestState>(store);
};

const subscribe = async (mutation: MutationPayload, rootState: any) => {
	let state = resolveNestedState<AccruedInterestState>(rootState, namespace);
	switch (mutation.type) {
		case resolveMutation(routeTypes.namespace, routeTypes.mutationTypes.ROUTE_CHANGED):
			if((mutation.payload.from.name === mutation.payload.to.name) && !state.route.isPushing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.processRouteQuery));
			break;
		case resolveMutation(namespace, mutationTypes.RESET_FILTER):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_ACCRUAL_START_DATE):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_ACCRUAL_END_DATE):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_ACCRUAL_TYPE):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_PAYMENT_START_DATE):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_PAYMENT_END_DATE):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_ACCOUNTED_START_DATE):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_ACCOUNTED_END_DATE):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_FINANCING_SOURCE_ID):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_QUARTER_ID):
			if(!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.resetPagingPage));
		case resolveMutation(namespace, mutationTypes.SET_PAGING_PAGE):
		{
			if(!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));

			updateListingBatchService.push(async () => {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.updateListingItems));
			});

			break;
		}
	}
};

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<AccruedInterestState, any>>{
	...listingMixin.getters,
	...formMixin.getters,
	...snapshotMixin.getters,
	[getterTypes.formattedItems]: state => {
		return state.listing.items.map(x => {
			return plainToInstance(AccruedInterest, {
				...x
			});
		});
	}
};

const actions = <ActionTree<AccruedInterestState, any>>{
	...listingMixin.actions,
	...pagingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	...routeMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }) {
		await dispatch(actionTypes.initializeBase);

		await dispatch(actionTypes.processRouteQuery);
		await dispatch(actionTypes.reconstituteRoute);

		await Promise.all([
			dispatch(actionTypes.fetchDictionaries),
			dispatch(actionTypes.updateListingItems)
		]);

		const agreement = await loanScheduleController.getAgreement(router.currentRoute.params.projectId);
		commit(mutationTypes.SET_AGREEMENT, agreementMapper.map(agreement, ApiAgreement, Agreement));

		unsubscribeCallback = subscribersManager.subscribe(subscribe);
		commit(mutationTypes.SET_IS_INITIALIZED, true);
		commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
	},
	async [actionTypes.updateListingItems]({ commit, state }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);

		try {
			const { items, totalCount, totalAmount } = await loanInterestController.getAccruedInterest(router.currentRoute.params.projectId,
				mapper.map(state, AccruedInterestState, ApiGetAccruedInterestParameters));

			commit(mutationTypes.SET_LISTING_ITEMS, items.map(x => mapper.map(x, ApiAccruedInterest, AccruedInterest)));
			commit(mutationTypes.RESET_EDITABLE_ITEMS);
			commit(mutationTypes.SET_PAGING_TOTAL, totalCount);
			commit(mutationTypes.SET_TOTAL_AMOUNT, totalAmount);
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			console.error(error);
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.fetchDictionaries]({ commit }) {
		commit(mutationTypes.SET_IS_DICTIONARIES_LOADING, true);

		try {
			const [quarters, financeSources, accrualTypes, borrowers] = await Promise.all([
				dictionariesController.getProjectQuarters(router.currentRoute.params.projectId),
				dictionariesController.getProjectFinanceSources(router.currentRoute.params.projectId),
				dictionariesController.getAccrualTypes(),
				dictionariesController.getBorrowers()
			]);

			commit(mutationTypes.SET_QUARTERS, quarters);
			commit(mutationTypes.SET_FINANCE_SOURCES, financeSources);
			commit(mutationTypes.SET_ACCRUAL_TYPES, accrualTypes);
			commit(mutationTypes.SET_BORROWERS, borrowers);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_DICTIONARIES_LOADING, false);
		}
	},
	async [actionTypes.addItems]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);
		try {
			await loanInterestController.addAccruedInterestItems(mapper.map({
					...state.addItemsParameters,
					projectId: +router.currentRoute.params.projectId
				},
				AccruedInterestAddItemsParameters,
				ApiAccruedInterestAddItemsParameters));

			await dispatch(actionTypes.updateListingItems);

			commit(mutationTypes.RESET_ADD_ITEMS_PARAMETERS);
			alertService.addInfo(AlertKeys.ACCRUED_INTEREST_ITEM_SUCCESS_CREATED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.deleteItem]({ dispatch, commit, rootState, state, getters }, { item }) {
		commit(mutationTypes.SET_IS_ITEM_DELETING, true);

		try {
			await loanInterestController.deleteAccruedInterestItem(item.id);

			alertService.addInfo(AlertKeys.ACCRUED_INTEREST_ITEM_SUCCESS_DELETED);

			commit(mutationTypes.SET_ITEM_IS_ACTIVE, { id: item.id, isActive: false });
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_ITEM_DELETING, false);
		}
	},
	async [actionTypes.updateItem]({ dispatch, commit, rootState, state, getters }, item) {
		commit(mutationTypes.ADD_SAVING_ITEM, item);

		try {
			await loanInterestController.updateAccruedInterest(item.id, mapper.map(item, AccruedInterest, ApiAccruedInterest));

			await dispatch(actionTypes.updateListingItems);

			alertService.addInfo(AlertKeys.ACCRUED_INTEREST_ITEM_SUCCESS_SAVED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.REMOVE_SAVING_ITEM, item);
		}
	},
	async [actionTypes.submitExtraRateParameters]({ dispatch, commit, state })
	{
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			await loanInterestController.submitExtraRateAccruedInterest(mapper.map({
				...state.extraRateParameters,
				projectId: +router.currentRoute.params.projectId
			}, ExtraRateParameters, ApiExtraRateParameters));

			await dispatch(actionTypes.updateListingItems);

			alertService.addInfo(AlertKeys.EXTRA_RATE_SUCCESS_CHARGED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.massUpdate]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			await loanInterestController.massUpdateAccruedInterest(mapper.map({
					...state.massUpdateParameters,
					projectId: +router.currentRoute.params.projectId
				},
				MassUpdateAccruedInterest,
				ApiMassUpdateAccruedInterest));

			await dispatch(actionTypes.updateListingItems);

			commit(mutationTypes.RESET_MASS_UPDATE_PARAMETERS);

			alertService.addInfo(AlertKeys.ACCRUED_INTEREST_ITEMS_SUCCESS_UPDATED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.processRouteQuery]({ rootState, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, true);

		let routeQuery = await routeQueryService.resolveRouteQuery(rootState.route.query);

		commit(mutationTypes.SET_PAGING_PAGE, routeQuery.page);
		commit(mutationTypes.SET_FILTER_ACCRUAL_START_DATE, routeQuery.filterAccrualStartDate);
		commit(mutationTypes.SET_FILTER_ACCRUAL_END_DATE, routeQuery.filterAccrualEndDate);
		commit(mutationTypes.SET_FILTER_ACCRUAL_TYPE, routeQuery.filterAccrualType);
		commit(mutationTypes.SET_FILTER_PAYMENT_START_DATE, routeQuery.filterPaymentStartDate);
		commit(mutationTypes.SET_FILTER_PAYMENT_END_DATE, routeQuery.filterPaymentEndDate);
		commit(mutationTypes.SET_FILTER_ACCOUNTED_START_DATE, routeQuery.filterAccountedStartDate);
		commit(mutationTypes.SET_FILTER_ACCOUNTED_END_DATE, routeQuery.filterAccountedEndDate);
		commit(mutationTypes.SET_FILTER_FINANCING_SOURCE_ID, routeQuery.filterFinancingSourceId);
		commit(mutationTypes.SET_FILTER_QUARTER_ID, routeQuery.filterQuarterId);

		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, false);
	},
	async [actionTypes.pushRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);

		await router.push({
			name: RouteNames.ACCRUED_INTEREST,
			params: router.currentRoute.params,
			query: routeQueryService.resolveRouteQueryDictionary(state)
		}).catch(() => {});

		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.reconstituteRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);

		await router.replace({
			name: RouteNames.ACCRUED_INTEREST,
			params: router.currentRoute.params,
			query: routeQueryService.resolveRouteQueryDictionary(state)
		}).catch(() => {});

		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.destroy]({ dispatch }) {
		unsubscribeCallback();
		await dispatch(actionTypes.destroyBase);
	}
};

const mutations = <MutationTree<AccruedInterestState>>{
	...listingMixin.mutations,
	...pagingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	...routeMixin.mutations,
	[mutationTypes.SET_IS_DICTIONARIES_LOADING](state, value) {
		state.isDictionariesLoading = value;
	},
	[mutationTypes.SET_ITEM_IS_ACTIVE](state, { id, isActive }) {
		state.listing.items[state.listing.items.findIndex(x => x.id === id)].isActive = isActive;
	},
	[mutationTypes.RESET_ADD_ITEMS_PARAMETERS](state) {
		state.addItemsParameters = new AccruedInterestAddItemsParameters();
	},
	[mutationTypes.RESET_EDITABLE_ITEMS](state) {
		state.editableItems = [];
	},
	[mutationTypes.ADD_EDITABLE_ITEM](state, value) {
		state.editableItems.push(cloneDeep(value));
	},
	[mutationTypes.REMOVE_EDITABLE_ITEM](state, value) {
		state.editableItems.splice(state.editableItems.findIndex(x => x.id === value.id), 1);
	},
	[mutationTypes.SET_ADD_ITEMS_PARAMETERS_ACCRUAL_TYPE_ID](state, value) {
		state.addItemsParameters.accrualTypeId = value;
	},
	[mutationTypes.SET_ADD_ITEMS_PARAMETERS_FINANCING_SOURCE_ID](state, value) {
		state.addItemsParameters.financingSourceId = value;
	},
	[mutationTypes.SET_ADD_ITEMS_PARAMETERS_QUARTER_ID](state, value) {
		state.addItemsParameters.quarterId = value;
	},
	[mutationTypes.SET_ADD_ITEMS_PARAMETERS_ACCRUAL_START_DATE](state, value) {
		state.addItemsParameters.accrualStartDate = value;
	},
	[mutationTypes.SET_ADD_ITEMS_PARAMETERS_ACCRUAL_END_DATE](state, value) {
		state.addItemsParameters.accrualEndDate = value;
	},
	[mutationTypes.SET_ADD_ITEMS_PARAMETERS_PAYMENT_DATE](state, value) {
		state.addItemsParameters.paymentDate = value;
	},
	[mutationTypes.SET_ADD_ITEMS_PARAMETERS_ACCRUED_AMOUNT](state, value) {
		state.addItemsParameters.accruedAmount = value;
	},
	[mutationTypes.RESET_MASS_UPDATE_PARAMETERS](state) {
		state.massUpdateParameters = new MassUpdateAccruedInterest();
	},
	[mutationTypes.SET_MASS_UPDATE_PARAMETERS_ACCRUAL_TYPE_ID](state, value) {
		state.massUpdateParameters.accrualTypeId = value;
	},
	[mutationTypes.SET_MASS_UPDATE_PARAMETERS_ACCRUAL_TYPE_FILTER_ENABLED](state, value) {
		state.massUpdateParameters.accrualTypeFilterEnabled = value;
	},
	[mutationTypes.SET_MASS_UPDATE_PARAMETERS_FINANCE_SOURCE_ID](state, value) {
		state.massUpdateParameters.financeSourceId = value;
	},
	[mutationTypes.SET_MASS_UPDATE_PARAMETERS_SOURCE_FILTER_ENABLED](state, value) {
		state.massUpdateParameters.sourceFilterEnabled = value;
	},
	[mutationTypes.SET_MASS_UPDATE_PARAMETERS_QUARTER_ID](state, value) {
		state.massUpdateParameters.quarterId = value;
	},
	[mutationTypes.SET_MASS_UPDATE_PARAMETERS_QUARTER_FILTER_ENABLED](state, value) {
		state.massUpdateParameters.quarterFilterEnabled = value;
	},
	[mutationTypes.SET_MASS_UPDATE_PARAMETERS_ACCRUAL_START_DATE](state, value) {
		state.massUpdateParameters.accrualStartDate = value;
	},
	[mutationTypes.SET_MASS_UPDATE_PARAMETERS_ACCRUAL_END_DATE](state, value) {
		state.massUpdateParameters.accrualEndDate = value;
	},
	[mutationTypes.SET_MASS_UPDATE_PARAMETERS_PAYMENT_DATE](state, value) {
		state.massUpdateParameters.paymentDate = value;
	},
	[mutationTypes.SET_MASS_UPDATE_PARAMETERS_PAYMENT_DATE_SETTING_ENABLED](state, value) {
		state.massUpdateParameters.paymentDateSettingEnabled = value;
	},
	[mutationTypes.SET_MASS_UPDATE_PARAMETERS_ACCRUED_AMOUNT](state, value) {
		state.massUpdateParameters.accruedAmount = value;
	},
	[mutationTypes.SET_MASS_UPDATE_PARAMETERS_ACCRUED_AMOUNT_SETTING_ENABLED](state, value) {
		state.massUpdateParameters.accruedAmountSettingEnabled = value;
	},
	[mutationTypes.SET_QUARTERS](state, value) {
		state.quarters = value;
	},
	[mutationTypes.SET_FINANCE_SOURCES](state, value) {
		state.financeSources = value;
	},
	[mutationTypes.SET_ACCRUAL_TYPES](state, value) {
		state.accrualTypes = value;
	},
	[mutationTypes.ADD_SAVING_ITEM](state, value) {
		state.savingItems.push(cloneDeep(value));
	},
	[mutationTypes.REMOVE_SAVING_ITEM](state, value) {
		state.savingItems.splice(state.savingItems.findIndex(x => x.id === value.id), 1);
	},
	[mutationTypes.SET_IS_ITEM_DELETING](state, value) {
		state.isItemDeleting = value;
	},
	[mutationTypes.RESET_FILTER](state) {
		state.filter = new AccruedInterestFilter();
	},
	[mutationTypes.SET_FILTER_ACCRUAL_START_DATE](state, value) {
		state.filter.accrualStartDate = value;
	},
	[mutationTypes.SET_FILTER_ACCRUAL_END_DATE](state, value) {
		state.filter.accrualEndDate = value;
	},
	[mutationTypes.SET_FILTER_ACCRUAL_TYPE](state, value) {
		state.filter.accrualType = value;
	},
	[mutationTypes.SET_FILTER_PAYMENT_START_DATE](state, value) {
		state.filter.paymentStartDate = value;
	},
	[mutationTypes.SET_FILTER_PAYMENT_END_DATE](state, value) {
		state.filter.paymentEndDate = value;
	},
	[mutationTypes.SET_FILTER_ACCOUNTED_START_DATE](state, value) {
		state.filter.accountedStartDate = value;
	},
	[mutationTypes.SET_FILTER_ACCOUNTED_END_DATE](state, value) {
		state.filter.accountedEndDate = value;
	},
	[mutationTypes.SET_FILTER_FINANCING_SOURCE_ID](state, value) {
		state.filter.financingSourceId = value;
	},
	[mutationTypes.SET_FILTER_QUARTER_ID](state, value) {
		state.filter.quarterId = value;
	},
	[mutationTypes.RESET_EXTRA_RATE_PARAMETERS](state) {
		state.extraRateParameters = new ExtraRateParameters();
	},
	[mutationTypes.SET_EXTRA_RATE_PARAMETERS_ACCRUAL_START_DATE](state, value) {
		state.extraRateParameters.accrualStartDate = value;
	},
	[mutationTypes.SET_EXTRA_RATE_PARAMETERS_ACCRUAL_END_DATE](state, value) {
		state.extraRateParameters.accrualEndDate = value;
	},
	[mutationTypes.SET_EXTRA_RATE_PARAMETERS_EXTRA_INTEREST_RATE](state, value) {
		state.extraRateParameters.extraInterestRate = value;
	},
	[mutationTypes.SET_EXTRA_RATE_PARAMETERS_PAYMENT_DATE](state, value) {
		state.extraRateParameters.paymentDate = value;
	},
	[mutationTypes.SET_AGREEMENT](state, value) {
		state.agreement = value;
	},
	[mutationTypes.SET_TOTAL_AMOUNT](state, value) {
		state.totalAmount = value;
	},
	[mutationTypes.SET_BORROWERS](state, value) {
		state.borrowers = value;
	}
};

export {
	namespace, state, getters, actions, mutations, initializeSubscribersManager
};

const accruedInterestModule = {
	namespace, state, getters, actions, mutations, initializeSubscribersManager, namespaced: true
};

export default accruedInterestModule;

import { IsString, IsInt, Min, Max } from "class-validator";

export default class AccruedInterestRouteQuery {
	@IsInt()
	@Min(0)
	@Max(1000)
	page: number;

	@IsString()
	filterAccrualStartDate: string;
	@IsString()
	filterAccrualEndDate: string;
	@IsString()
	filterAccrualType: string;
	@IsString()
	filterPaymentStartDate: string;
	@IsString()
	filterPaymentEndDate: string;
	@IsString()
	filterAccountedStartDate: string;
	@IsString()
	filterAccountedEndDate: string;
	@IsString()
	filterFinancingSourceId: string;
	@IsString()
	filterQuarterId: string;

	constructor(
		page: number = 1,
		filterAccrualStartDate: string = "",
		filterAccrualEndDate: string = "",
		filterAccrualType: string = "",
		filterPaymentStartDate: string = "",
		filterPaymentEndDate: string = "",
		filterAccountedStartDate: string = "",
		filterAccountedEndDate: string = "",
		filterFinancingSourceId: string = "",
		filterQuarterId: string = ""
	)
	{
		this.page = page;
		this.filterAccrualStartDate = filterAccrualStartDate;
		this.filterAccrualEndDate = filterAccrualEndDate;
		this.filterAccrualType = filterAccrualType;
		this.filterPaymentStartDate = filterPaymentStartDate;
		this.filterPaymentEndDate = filterPaymentEndDate;
		this.filterAccountedStartDate = filterAccountedStartDate;
		this.filterAccountedEndDate = filterAccountedEndDate;
		this.filterFinancingSourceId = filterFinancingSourceId;
		this.filterQuarterId = filterQuarterId;
	}
}

import { ApiLoanUser, ApiLoanUserHelper } from "@/api/loan/types/apiLoanUser";
import IPageState from "@/store/shared/base/types/pageState";

export interface UserState extends IPageState {
	user: ApiLoanUser;
	isUserLoading: boolean;
}

export class UserStateHelper {
	static getEmpty(): UserState {
		return {
			isInitialized: false,
			isDestroyed: false,
			user: ApiLoanUserHelper.getEmpty(),
			isUserLoading: false
		};
	}
}
import IPageState from "@/store/shared/base/types/pageState";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import RouteState from "@/store/shared/route/types/routeState";
import ApiTroubledBorrower from "@/api/loan/types/troubledBorrower/apiTroubledBorrower";
import TroubledBorrowersFilter from "@/store/loan/modules/troubledBorrowers/types/troubledBorrowersFilter";
import ApiRefundStatusTypes from "@/api/loan/types/dictionaries/apiRefundStatusTypes";
import ApiProblemDebtDepartment from "@/api/loan/types/dictionaries/apiProblemDebtDepartment";

export default class TroubledBorrowersState implements IPageState {
	constructor(
		public listing: ListingModel<ApiTroubledBorrower>,
		public sorting: SortingModel<String>,
		public paging: PagingModel,
		public search: SearchModel,
		public route: RouteState,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isDictionariesLoading: boolean = false,
		public problemDebtDepartments: ApiProblemDebtDepartment[] = [],
		public refundStatusTypes: ApiRefundStatusTypes[] = [],
		public filter: TroubledBorrowersFilter = new TroubledBorrowersFilter()
	)
	{
	}
}

export default class OverdueInterest {
	id: string;
	number: number;
	projectId: number;
	financingSourceId: number;
	quarterId: string;
	startDate: number;
	amount: number;
	comment: string;

	constructor(
		id: string = "",
		number: number = 0,
		projectId: number = 0,
		financingSourceId: number = 0,
		quarterId: string = "",
		startDate: number = 0,
		amount: number = 0,
		comment: string = ""
	)
	{
		this.id = id;
		this.number = number;
		this.projectId = projectId;
		this.financingSourceId = financingSourceId;
		this.quarterId = quarterId;
		this.startDate = startDate;
		this.amount = amount;
		this.comment = comment;
	}
}

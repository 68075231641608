import { actionTypes, mutationTypes, namespace } from "@/store/loan/modules/reports/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationPayload, MutationTree, Store } from "vuex";
import AbortService from "@/services/abortService";
import ListingMixinBuilder from "@/store/shared/listing";
import SortingMixinBuilder from "@/store/shared/sorting";
import SearchMixinBuilder from "@/store/shared/search";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import SearchModel from "@/store/shared/search/models/searchModel";
import router from "@/router/loan";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import mapper from "@/store/loan/modules/reports/mapper";
import { RouteNames } from "@/router/loan/routes";
import RouteMixinBuilder from "@/store/shared/route";
import ReportsState from "@/store/loan/modules/reports/types/reportsState";
import { ReportsController } from "@/api/loan/reports";
import ApiReportsParameters from "@/api/loan/types/reports/apiReportsParameters";
import { getterTypes } from "@/store/bod/modules/documents/types";
import ApiReport from "@/api/loan/types/reports/apiReport";
import Report from "@/store/loan/modules/reports/types/report";
import ReportDocument from "@/store/loan/modules/reports/types/reportDocument";
import { ReportsRouteType } from "@/store/loan/modules/reports/types/reportsRouteType";
import { first } from "lodash";
import ApiReportDocumentUploadParameters from "@/api/loan/types/reports/apiReportDocumentUploadParameters";
import NotDefinedException from "@/exceptions/notDefinedException";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import ReportAddQueryInfo from "@/store/loan/modules/reports/types/reportAddQueryInfo";

const abortService = new AbortService();

const reportsController = new ReportsController(abortService);

const routeMixin = (new RouteMixinBuilder<ReportsState>()).build();

const baseMixin = (new BaseMixinBuilder(abortService)).build();
const listingMixin = (new ListingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new ReportsState(
			new ListingModel<ReportDocument>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String>({
				type: "sectionNumber",
				order: sortingOrderType.descending
			}),
			new SearchModel({
				query: ""
			}),
			routeMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<ReportsState, any>>{
	...listingMixin.getters,
	[getterTypes.formattedItems]: state => {
		return state.listing.items.map(x => {
			let lastDocument = null;
			
			if(x.documentFiles.length && x.documentFiles.length === 1) {
				lastDocument = first(x.documentFiles);
			}
			if(x.documentFiles.length && x.documentFiles.length > 1) {
				const sortedFiles = x.documentFiles.sort((a, b) => {
					if(a.uploadDateTime > b.uploadDateTime)
						return -1;
					else if(a.uploadDateTime < b.uploadDateTime)
						return 1;
					else return 0;
				});
				lastDocument = first(sortedFiles);
			}
			return {
				...x,
				sectionNumber: x.description.sectionNumber,
				sectionTitle: x.description.sectionTitle,
				lastDocument
			};
		});
	}
};

const actions = <ActionTree<ReportsState, any>>{
	...listingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...routeMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }) {
		await dispatch(actionTypes.initializeBase);
		
		await dispatch(actionTypes.initializeDefaultActions);
		
		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.initializeDefaultActions]({ dispatch, commit }) {
		await dispatch(actionTypes.fetchReportsFilter);
		
		await dispatch(actionTypes.processRouteParams);
		await dispatch(actionTypes.reconstituteRoute);
		
		await dispatch(actionTypes.updateListingItems);
	},
	async [actionTypes.fetchReportsFilter]({ commit, rootState, state }) {
		commit(mutationTypes.SET_IS_REPORTS_FILTER_LOADING, true);
		
		try {
			const filterData = await reportsController.getFilter(rootState.route.params.projectId);
			commit(mutationTypes.SET_REPORTS_FILTER, filterData);
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			console.error(error);
		} finally {
			commit(mutationTypes.SET_IS_REPORTS_FILTER_LOADING, false);
		}
	},
	async [actionTypes.updateListingItems]({ commit, rootState, dispatch, state }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);
		
		try {
			const routeParams = rootState.route.params;
			const { companyId, year, quarter } = state.reportsInternalFilter;
			
			const report = await reportsController.getReports(new ApiReportsParameters(routeParams.projectId, companyId, year, quarter));
			
			await dispatch(actionTypes.setListingItems, report);
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			console.error(error);
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.setListingItems]({ commit, rootState, state }, value) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);
		
		commit(mutationTypes.SET_REPORT, mapper.map(value, ApiReport, Report));
		
		const groupedDocuments = state.report.sections.reduce<ReportDocument[]>((arr, elem) => arr.concat(elem.documents), []);
		commit(mutationTypes.SET_LISTING_ITEMS, groupedDocuments);
		
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
	},
	async [actionTypes.uploadDocument]({ commit, dispatch, rootState, state }, { file, signature }: { file: File, signature?: string }) {
		if(signature)
			commit(mutationTypes.SET_IS_SIGNED_DOCUMENT_UPLOADING, true);
		else
			commit(mutationTypes.SET_IS_DOCUMENT_UPLOADING, true);
		
		try {
			const routeParams = rootState.route.params;
			const { companyId, year, quarter } = state.reportsInternalFilter;
			
			if(!state.selectedDocument) throw new NotDefinedException("selectedDocument");
			
			const uploadParameters = new ApiReportDocumentUploadParameters(
				routeParams.projectId,
				companyId,
				year,
				quarter,
				state.selectedDocument.description.documentType,
				file,
				signature,
				state.selectedDocument.description?.calendarPlanStageId,
				state.selectedDocument.description?.keyIndicatorType,
				null
			);
			
			await reportsController.uploadReportDocument(uploadParameters);
			
			await dispatch(actionTypes.updateListingItems);
			
			alertService.addInfo(AlertKeys.DOCUMENT_SUCCESS_UPLOADED);
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			console.error(error);
		} finally {
			if(signature)
				commit(mutationTypes.SET_IS_SIGNED_DOCUMENT_UPLOADING, false);
			else
				commit(mutationTypes.SET_IS_DOCUMENT_UPLOADING, false);
		}
	},
	async [actionTypes.acceptReport]({ commit, dispatch, rootState, state }) {
		commit(mutationTypes.SET_IS_REPORT_ACCEPTING, true);
		
		try {
			const routeParams = rootState.route.params;
			const { companyId, year, quarter } = state.reportsInternalFilter;
			
			const report = await reportsController.acceptReport(new ApiReportsParameters(routeParams.projectId, companyId, year, quarter));
			
			await dispatch(actionTypes.setListingItems, report);
			
			alertService.addInfo(AlertKeys.REPORT_SUCCESS_ACCEPTED);
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			console.error(error);
		} finally {
			commit(mutationTypes.SET_IS_REPORT_ACCEPTING, false);
		}
	},
	async [actionTypes.declineReport]({ commit, dispatch, rootState, state }) {
		commit(mutationTypes.SET_IS_REPORT_DECLINING, true);
		
		try {
			const routeParams = rootState.route.params;
			const { companyId, year, quarter } = state.reportsInternalFilter;
			
			const report = await reportsController.declineReport(new ApiReportsParameters(routeParams.projectId, companyId, year, quarter));
			
			await dispatch(actionTypes.setListingItems, report);
			
			alertService.addInfo(AlertKeys.REPORT_SUCCESS_DECLINED);
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			console.error(error);
		} finally {
			commit(mutationTypes.SET_IS_REPORT_DECLINING, false);
		}
	},
	async [actionTypes.sendRequestEmailConfirmation]({ commit, dispatch, rootState, state }) {
		commit(mutationTypes.SET_IS_EMAIL_CONFIRMATION_REQUESTING, true);
		
		try {
			const routeParams = rootState.route.params;
			const { companyId, year, quarter } = state.reportsInternalFilter;
			
			await reportsController.sendRequestEmailConfirmation(new ApiReportsParameters(routeParams.projectId, companyId, year, quarter));
			
			alertService.addInfo(AlertKeys.EMAIL_REQUEST_CONFIRMATION_SUCCESS_SENT);
			
			const delay = 5000;
			await new Promise(resolve => setTimeout(resolve, delay));
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			console.error(error);
		} finally {
			commit(mutationTypes.SET_IS_EMAIL_CONFIRMATION_REQUESTING, false);
		}
	},
	async [actionTypes.processRouteParams]({ rootState, commit, state }) {
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, true);
		
		const routeParams = rootState.route.params;
		
		if(!routeParams.companyId) {
			commit(mutationTypes.SET_REPORTS_INTERNAL_FILTER_COMPANY_ID, state.reportsFilter.defaultCompany.id);
		} else {
			commit(mutationTypes.SET_REPORTS_INTERNAL_FILTER_COMPANY_ID, routeParams.companyId);
		}
		
		if(!routeParams.year) {
			commit(mutationTypes.SET_REPORTS_INTERNAL_FILTER_YEAR, state.reportsFilter.defaultPeriod.year);
		} else {
			commit(mutationTypes.SET_REPORTS_INTERNAL_FILTER_YEAR, routeParams.year);
		}
		
		if(!routeParams.quarter) {
			commit(mutationTypes.SET_REPORTS_INTERNAL_FILTER_QUARTER, state.reportsFilter.defaultPeriod.quarter);
		} else {
			commit(mutationTypes.SET_REPORTS_INTERNAL_FILTER_QUARTER, routeParams.quarter);
		}
		
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, false);
	},
	async [actionTypes.pushRoute]({ state, rootState, commit, dispatch },
		{ newValue, type }: { newValue: string, type: ReportsRouteType })
	{
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);
		
		if(!newValue || newValue === "null") return;
		
		let { projectId, companyId, year, quarter } = rootState.route.params;
		
		switch (type) {
			case ReportsRouteType.COMPANY:
				companyId = newValue;
				break;
			case ReportsRouteType.YEAR:
				year = newValue;
				break;
			case ReportsRouteType.QUARTER:
				quarter = newValue;
				break;
		}
		
		await router.push({
			name: RouteNames.REPORTS,
			params: { projectId, companyId, year, quarter }
		}).catch(err => {
		});
		
		commit(mutationTypes.SET_LISTING_ITEMS, []);
		await dispatch(actionTypes.initializeDefaultActions);
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.reconstituteRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);
		
		await router.replace({
			name: RouteNames.REPORTS,
			params: { ...state.reportsInternalFilter }
		}).catch(() => {
		});
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.destroy]({ dispatch }) {
		await dispatch(actionTypes.destroyBase);
	}
};

const mutations = <MutationTree<ReportsState>>{
	...listingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...routeMixin.mutations,
	[mutationTypes.SET_IS_REPORTS_FILTER_LOADING](state, value) {
		state.isReportsFilterLoading = value;
	},
	[mutationTypes.RESET_REPORT](state) {
		state.report = new Report();
	},
	[mutationTypes.SET_REPORT](state, value) {
		state.report = value;
	},
	[mutationTypes.SET_REPORTS_FILTER](state, value) {
		state.reportsFilter = value;
	},
	[mutationTypes.SET_REPORTS_INTERNAL_FILTER](state, value) {
		state.reportsInternalFilter = value;
	},
	[mutationTypes.SET_REPORTS_INTERNAL_FILTER_COMPANY_ID](state, value) {
		state.reportsInternalFilter.companyId = value;
	},
	[mutationTypes.SET_REPORTS_INTERNAL_FILTER_YEAR](state, value) {
		state.reportsInternalFilter.year = value;
	},
	[mutationTypes.SET_REPORTS_INTERNAL_FILTER_QUARTER](state, value) {
		state.reportsInternalFilter.quarter = value;
	},
	[mutationTypes.RESET_SELECTED_DOCUMENT](state) {
		state.selectedDocument = null;
	},
	[mutationTypes.SET_SELECTED_DOCUMENT](state, value) {
		state.selectedDocument = value;
	},
	[mutationTypes.SET_IS_SIGNED_DOCUMENT_UPLOADING](state, value) {
		state.isSignedDocumentUploading = value;
	},
	[mutationTypes.SET_IS_DOCUMENT_UPLOADING](state, value) {
		state.isDocumentUploading = value;
	},
	[mutationTypes.SET_IS_REPORT_ACCEPTING](state, value) {
		state.isReportAccepting = value;
	},
	[mutationTypes.SET_IS_REPORT_DECLINING](state, value) {
		state.isReportDeclining = value;
	},
	[mutationTypes.SET_IS_EMAIL_CONFIRMATION_REQUESTING](state, value) {
		state.isEmailConfirmationRequesting = value;
	},
	[mutationTypes.RESET_REPORT_ADD_QUERY_INFO](state) {
		state.reportAddQueryInfo = new ReportAddQueryInfo();
	},
	[mutationTypes.SET_REPORT_ADD_QUERY_INFO_TITLE](state, value) {
		state.reportAddQueryInfo.title = value;
	},
	[mutationTypes.SET_REPORT_ADD_QUERY_INFO_DESCRIPTION](state, value) {
		state.reportAddQueryInfo.description = value;
	},
	[mutationTypes.SET_REPORT_ADD_QUERY_INFO_EXECUTION_DATE](state, value) {
		state.reportAddQueryInfo.executionDate = value;
	}
};

export {
	namespace, state, getters, actions, mutations
};

const reportsModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default reportsModule;

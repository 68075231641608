import ApiReportDocument from "@/api/loan/types/reports/apiReportDocument";

export default class ApiReportSection {
	documents: ApiReportDocument[];
	title: string;
	number: string;

	constructor(
		documents: ApiReportDocument[] = [],
		title: string = "",
		number: string = ""
	)
	{
		this.documents = documents;
		this.title = title;
		this.number = number;
	}
}

import { convertToTimestamp, convertToZonedIso } from "@/utils/dates";
import ApiAgreementsAccruedInterest from "@/api/loan/types/loanInterest/apiAgreementsAccruedInterest";
import AgreementsAccruedInterest from "@/store/loan/modules/agreementsAccruedInterest/types/agreementsAccruedInterest";
import AgreementsAccruedInterestState from "@/store/loan/modules/agreementsAccruedInterest/types/agreementsAccruedInterestState";
import ApiGetAgreementsAccruedInterestParameters from "@/api/loan/types/loanInterest/apiGetAgreementsAccruedInterestParameters";
import ApiPeriod from "@/api/loan/types/dictionaries/apiPeriod";
import Period from "@/store/loan/modules/agreementsAccruedInterest/types/period";
import ApiAgreementsAccruedInterestReport from "@/api/loan/types/loanInterest/apiAgreementsAccruedInterestReport";
import AgreementsAccruedInterestReport from "@/store/loan/modules/agreementsAccruedInterest/types/agreementsAccruedInterestReport";
import ApiAgreementsAccruedInterestAccountedData from "@/api/loan/types/loanInterest/apiAgreementsAccruedInterestAccountedData";
import AgreementsAccruedInterestAccountedData
	from "@/store/loan/modules/agreementsAccruedInterest/types/agreementsAccruedInterestAccountedData";

export default class AgreementsAccruedInterestMapperProfile {
	mapToAgreementsAccruedInterest(source: ApiAgreementsAccruedInterest): AgreementsAccruedInterest {
		return {
			...source,
			accountedData: source.accountedData.map(x => this.mapToAgreementsAccruedInterestAccountedData(x)),
			isSelected: false,
			groupedItems: []
		};
	}
	
	mapToApiAgreementsAccruedInterest(source: AgreementsAccruedInterest): ApiAgreementsAccruedInterest {
		return {
			...source,
			accountedData: source.accountedData.map(x => this.mapToApiAgreementsAccruedInterestAccountedData(x))
		};
	}
	
	mapToAgreementsAccruedInterestAccountedData(source: ApiAgreementsAccruedInterestAccountedData): AgreementsAccruedInterestAccountedData {
		return {
			accountedAmount: source.accountedAmount,
			accountedDate: convertToTimestamp(source.accountedDate) as number
		};
	}
	
	mapToApiAgreementsAccruedInterestAccountedData(source: AgreementsAccruedInterestAccountedData): ApiAgreementsAccruedInterestAccountedData {
		return {
			accountedAmount: source.accountedAmount,
			accountedDate: convertToZonedIso(source.accountedDate) as string
		};
	}
	
	
	mapToPeriod(source: ApiPeriod): Period {
		return {
			...source,
			startDate: convertToTimestamp(source.startDate) as number,
			endDate: convertToTimestamp(source.endDate) as number
		};
	}
	
	mapToApiAgreementsAccruedInterestReport(source: AgreementsAccruedInterestReport): ApiAgreementsAccruedInterestReport {
		return {
			...source,
			reportNumber: +source.reportNumber
		};
	}
	
	mapToDefaultApiGetAgreementsAccruedInterestParameters(source: AgreementsAccruedInterestState): ApiGetAgreementsAccruedInterestParameters {
		return {
			projectIds: undefined,
			periodId: source.filter.periodId,
			applicationNumber: "",
			companyName: "",
			agreementNumber: "",
			agreementStatusId: "",
			take: null,
			skip: 0,
			sortField: source.sorting.type as string,
			sortDirection: source.sorting.order as string
		};
	}
	
	mapToApiGetAgreementsAccruedInterestParametersForUpdateSelectedItems(source: AgreementsAccruedInterestState): ApiGetAgreementsAccruedInterestParameters {
		const selectedItems = source.listing.items.filter(x => x.isSelected);
		return {
			projectIds: selectedItems.length ? selectedItems.map(x => x.projectId) : [],
			periodId: source.filter.periodId,
			applicationNumber: source.filter.applicationNumber || "",
			companyName: source.filter.companyName,
			agreementNumber: "",
			agreementStatusId: source.filter.agreementStatusId || "",
			take: selectedItems.length,
			skip: 0,
			sortField: source.sorting.type as string,
			sortDirection: source.sorting.order as string
		};
	}
}

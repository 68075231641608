import IPageState from "@/store/shared/base/types/pageState";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import ReportAddQueryInfo from "@/store/loan/modules/reports/types/reportAddQueryInfo";
import ReportFinalDocument from "@/store/loan/modules/reportsFinal/types/reportFinalDocument";
import ReportFinal from "@/store/loan/modules/reportsFinal/types/reportFinal";

export default class ReportsFinalState implements IPageState {
	constructor(
		public listing: ListingModel<ReportFinalDocument>,
		public sorting: SortingModel<String>,
		public search: SearchModel,
		public reportFinal: ReportFinal = new ReportFinal(),
		public selectedDocument: ReportFinalDocument | null = null,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isReportsFilterLoading: boolean = false,
		public isSignedDocumentUploading: boolean = false,
		public isDocumentUploading: boolean = false,
		public isReportAccepting: boolean = false,
		public isReportDeclining: boolean = false,
		public reportAddQueryInfo: ReportAddQueryInfo = new ReportAddQueryInfo()
	)
	{
	}
}

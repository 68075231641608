export default class ApiGetCashFlowItemsParameters {
	take?: number;
	skip?: number;
	paymentType?: string;
	startPaymentDate?: string;
	endPaymentDate?: string;
	quarterCodeIds?: string[];
	paymentExpenseIds?: string[];

	constructor(
		take?: number,
		skip?: number,
		paymentType?: string,
		startPaymentDate?: string,
		endPaymentDate?: string,
		quarterCodeIds?: string[],
		paymentExpenseIds?: string[]
	)
	{
		this.take = take;
		this.skip = skip;
		this.paymentType = paymentType;
		this.startPaymentDate = startPaymentDate;
		this.endPaymentDate = endPaymentDate;
		this.quarterCodeIds = quarterCodeIds;
		this.paymentExpenseIds = paymentExpenseIds;
	}
}

export default class ApiPaymentExpenseType {
	key: string;
	value: string;

	constructor(
		key: string = "",
		value: string = ""
	)
	{
		this.key = key;
		this.value = value;
	}
}

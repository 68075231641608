<template>
	<loan-content-layout v-if="isInitialized">
		<v-row>
			<v-col class="d-flex flex-column pt-4" style="gap: 24px">
				<v-card elevation="0" class="loan-card px-2 py-6">
					<v-row class="align-center">
						<v-col class="py-0">
							<v-card-title class="text-h6">
								{{ $t("titles.assignmentCompensation") }}
							</v-card-title>
						</v-col>
						<v-col class="py-0">
							<v-card-actions class="px-4">
								<div class="d-flex justify-md-end" style="width: 100%">
									<frp-btn v-if="can(Permissions.LOAN_CREATE)"
											 outlined
											 elevation="0"
											 color="primary"
											 :loading="isSubmissionToAccountingLoading"
											 :disabled="!requiredFiltersForAssignmentCompensationAction(AssignmentCompensationActionType.ORDER) || isAssignmentCompensationCalculating"
											 @click="handleSubmitToAccounting">
										{{ $t("buttons.submitToAccounting") }}
									</frp-btn>
									
									<frp-btn v-if="can(Permissions.LOAN_UPDATE)"
											 elevation="0"
											 color="blue"
											 :loading="isAssignmentCompensationCalculating"
											 :disabled="isSubmissionToAccountingLoading || !requiredFiltersForAssignmentCompensationAction(AssignmentCompensationActionType.CALCULATE)"
											 dark
											 @click="calculateAssignmentCompensation">
										{{ $t("buttons.calculateCompensation") }}
									</frp-btn>
								</div>
							</v-card-actions>
						</v-col>
					</v-row>
				</v-card>
				
				<v-card elevation="0" class="loan-card pa-0 pt-6">
					<loan-assignment-compensation-filter
						:is-assignment-compensation-loading="isSubmissionToAccountingLoading || isAssignmentCompensationCalculating">
					</loan-assignment-compensation-filter>
					
					<loan-assignment-compensation-table
						:is-assignment-compensation-loading="isSubmissionToAccountingLoading || isAssignmentCompensationCalculating">
					</loan-assignment-compensation-table>
				</v-card>
			</v-col>
		</v-row>
		
		<frp-dialog v-model="isEmptyAssignmentCompensationDialogOpened"
					:title="$t('dialogs.compensationAmount.title')"
					max-width="450"
					persistent
					@dialog:close="isEmptyAssignmentCompensationDialogOpened = false">
			<template #content="{ onIntersect }">
				<frp-text-body-two class="primary--text">
					{{ $t("dialogs.compensationAmount.noDataText") }}
				</frp-text-body-two>
			</template>
			
			<template #footer>
				<frp-btn elevation="0"
						 color="blue"
						 dark
						 @click="isEmptyAssignmentCompensationDialogOpened = false">
					{{ $t("buttons.close") }}
				</frp-btn>
			</template>
		</frp-dialog>
		
		<frp-dialog v-model="isOrderForCompensationPaymentDialogOpened"
					persistent
					max-width="1074"
					:title="$t('dialogs.compensationAmount.title')">
			
			<template #content="{ onIntersect }">
				<vue-pdf-embed style="min-height: 600px" v-intersect="onIntersect" :source="orderForCompensationPaymentPdfLink"/>
			</template>
			
			<template #footer>
				<frp-btn color="primary"
						 outlined
						 :disabled="isOrderDocumentToAccountingForApproveSending"
						 @click="isOrderForCompensationPaymentDialogOpened = false">
					{{ $t("buttons.close") }}
				</frp-btn>
				<frp-btn outlined
						 color="primary"
						 :href="orderForCompensationPaymentPdfLink"
						 download>
					{{ $t("buttons.download") }}
					<v-icon color="primary">mdi mdi-tray-arrow-down</v-icon>
				</frp-btn>
				
				<frp-btn elevation="0"
						 color="blue"
						 dark
						 :loading="isOrderDocumentToAccountingForApproveSending"
						 @click="handleSendToAccounting">
					{{ $t("buttons.sendToAccounting") }}
				</frp-btn>
			</template>
		</frp-dialog>
		
		<loan-alert-dialog v-if="isSentDocumentToAccountingDialogOpened"
						   close-btn-dark
						   :title="$t('dialogs.documentSentToAccounting.title')"
						   @dialog:close="closeSentDocumentToAccountingDialog">
		</loan-alert-dialog>
	</loan-content-layout>
	<loan-assignment-compensation-loader v-else/>
</template>

<script>
import { LoanAssignmentCompensationController } from "@/api/loan/assignmentCompensation";
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import LoanAlertDialog from "@/components/dialogs/LoanAlertDialog.vue";
import LoanContentLayout from "@/components/layouts/LoanContentLayout.vue";
import FrpTextBodyTwo from "@/components/typography/FrpTextBodyTwo.vue";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import AbortService from "@/services/abortService";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import { i18n } from "@/plugins/index";
import { namespace, getterTypes, mutationTypes, actionTypes } from "@/store/loan/modules/assignmentCompensation/types";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import LoanAssignmentCompensationLoader from "@/views/loan/assignmentCompensation/LoanAssignmentCompensationLoader.vue";
import LoanAssignmentCompensationFilter from "@/views/loan/assignmentCompensation/filter/LoanAssignmentCompensationFilter.vue";
import LoanAssignmentCompensationTable from "@/views/loan/assignmentCompensation/table/LoanAssignmentCompensationTable.vue";
import { isNil } from "lodash";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

const abortService = new AbortService();
const loanAssignmentCompensationController = new LoanAssignmentCompensationController(abortService);

const AssignmentCompensationActionType = {
	CALCULATE: "CALCULATE",
	ORDER: "ORDER"
};

export default {
	metaInfo: {
		title: i18n.t("metaTitles.assignmentCompensation")
	},
	mixins: [storeModuleBasedPage, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			isNil,
			AssignmentCompensationActionType,
			isOrderForCompensationPaymentDialogOpened: false,
			isEmptyAssignmentCompensationDialogOpened: false,
			isSentDocumentToAccountingDialogOpened: false,
			orderForCompensationPaymentPdfLink: null,
			isAccountingReportLoading: false
		};
	},
	computed: {
		...mapState({
			state: state => state,
			isInitialized: state => state.isInitialized,
			isAssignmentCompensationCalculating: state => state.isAssignmentCompensationCalculating,
			orderNumberForSubmissionToAccounting: state => state.orderNumberForSubmissionToAccounting,
			isOrderForSubmissionToAccountingCreating: state => state.isOrderForSubmissionToAccountingCreating,
			isOrderDocumentToAccountingForApproveSending: state => state.isOrderDocumentToAccountingForApproveSending,
			isReportApprovingHasError: state => state.isReportApprovingHasError
		}),
		...mapGetters({}),
		isSubmissionToAccountingLoading() {
			return this.isOrderForSubmissionToAccountingCreating || this.isAccountingReportLoading;
		}
	},
	methods: {
		...mapActions({
			initializeStore: actionTypes.initialize,
			updateListingItems: actionTypes.updateListingItems,
			createOrderForSubmissionToAccounting: actionTypes.createOrderForSubmissionToAccounting,
			calculateAssignmentCompensation: actionTypes.calculateAssignmentCompensation,
			sendOrderDocumentToAccountingForApprove: actionTypes.sendOrderDocumentToAccountingForApprove
		}),
		...mapMutations({
			resetIsReportApprovingHasError: mutationTypes.RESET_IS_REPORT_APPROVING_HAS_ERROR
		}),
		requiredFiltersForAssignmentCompensationAction(actionType) {
			const { assignmentAgreementId, assigneeId, assignmentPeriodEndDate, assignmentPeriodStartDate } = this.state.filter;
			
			switch (actionType) {
				case AssignmentCompensationActionType.CALCULATE:
				{
					return assignmentAgreementId && assigneeId && assignmentPeriodEndDate && assignmentPeriodStartDate;
				}
				case AssignmentCompensationActionType.ORDER:
				{
					return assignmentAgreementId && assignmentPeriodEndDate && assignmentPeriodStartDate;
				}
			}
		},
		async handleSubmitToAccounting() {
			await this.createOrderForSubmissionToAccounting();
			
			if(!isNil(this.orderNumberForSubmissionToAccounting))
				await this.getAccountingReport(this.orderNumberForSubmissionToAccounting);
		},
		async getAccountingReport(orderNumber) {
			this.isAccountingReportLoading = true;
			
			try {
				const report = await loanAssignmentCompensationController.getAccountingReport(orderNumber);
				
				this.setAccruedInterestInformationOutput(report);
			} catch (error) {
				AlertHelper.handleGeneralRequestErrors(error);
				console.error(error);
			} finally {
				this.isAccountingReportLoading = false;
			}
		},
		setAccruedInterestInformationOutput(data) {
			if(!data || !data.size) {
				this.isEmptyAssignmentCompensationDialogOpened = true;
			} else {
				this.orderForCompensationPaymentPdfLink = URL.createObjectURL(data);
				
				this.isOrderForCompensationPaymentDialogOpened = true;
			}
		},
		async handleSendToAccounting() {
			await this.sendOrderDocumentToAccountingForApprove();
			
			if(this.isReportApprovingHasError) return;
			
			this.isOrderForCompensationPaymentDialogOpened = false;
			
			this.orderForCompensationPaymentPdfLink = "";
			
			this.isSentDocumentToAccountingDialogOpened = true;
		},
		async closeSentDocumentToAccountingDialog() {
			this.isSentDocumentToAccountingDialogOpened = false;
			await this.updateListingItems();
			this.resetIsReportApprovingHasError();
		}
	},
	created() {
		abortService.initialize();
		this.initializeStore();
	},
	components: {
		FrpTextBodyTwo,
		LoanAlertDialog,
		FrpDialog,
		VuePdfEmbed,
		LoanAssignmentCompensationLoader,
		LoanAssignmentCompensationFilter,
		LoanAssignmentCompensationTable,
		FrpBtn,
		LoanContentLayout
		
	}
};
</script>
<style lang="scss" scoped>
</style>

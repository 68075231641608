<template>
	<v-card elevation="0" class="loan-card px-4 py-4">
		<v-row class="px-3 mb-5">
			<v-col cols="auto" class="px-0">
				<div class="d-flex flex-column">
					<frp-custom-title-loader width="130" class="mb-4"/>
					<frp-text-loader height="14" width="640"/>
				</div>
			</v-col>
		</v-row>

		<v-row class="px-4">
			<v-col cols="3">
				<v-skeleton-loader type="text" max-width="300"/>
			</v-col>
			<v-col cols="2">
				<v-skeleton-loader type="text" max-width="110"/>
			</v-col>
			<v-col cols="2">
				<v-skeleton-loader type="text" max-width="180"/>
			</v-col>
			<v-col>
				<v-skeleton-loader type="text" max-width="300"/>
			</v-col>
			<v-col>
				<v-skeleton-loader type="text" max-width="240"/>
			</v-col>
		</v-row>
		<v-divider class="mx-3" style="margin-top: 10px"></v-divider>
		<div class="mt-4 mb-7 px-4 d-flex">
			<frp-text-loader height="14" width="60"/>
		</div>
		<v-row class="px-4" v-for="i in 2" :key="i">
			<v-col cols="3">
				<v-skeleton-loader type="text" max-width="380"/>
			</v-col>
			<v-col cols="2">
				<v-skeleton-loader type="text" max-width="160"/>
			</v-col>
			<v-col cols="2">
				<v-skeleton-loader type="text" max-width="40"/>
			</v-col>
			<v-col>
				<div class="d-flex">
					<v-skeleton-loader type="text" width="80" class="mr-8"/>
					<v-skeleton-loader type="text" width="20"/>
				</div>
			</v-col>
			<v-col>
				<v-skeleton-loader type="text" max-width="40"/>
			</v-col>
		</v-row>
		<v-row class="px-4">
			<v-col cols="3">
				<v-skeleton-loader type="text" width="50"/>
			</v-col>
			<v-col cols="2">
			</v-col>
			<v-col cols="2">
				<v-skeleton-loader type="text" max-width="40"/>
			</v-col>
			<v-col>
			</v-col>
			<v-col>
				<v-skeleton-loader type="text" max-width="40"/>
			</v-col>
		</v-row>
		<v-row class="mt-4">
			<v-col class="d-flex justify-end">
				<frp-btn-loader height="40" width="160"/>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import FrpCustomTitleLoader from "Components/loaders/common/FrpCustomTitleLoader";
import FrpTextLoader from "Components/loaders/common/FrpTextLoader";
import FrpBtnLoader from "Components/loaders/form/FrpBtnLoader";

export default {
	components: { FrpCustomTitleLoader, FrpTextLoader, FrpBtnLoader }
};
</script>

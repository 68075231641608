import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import urls, { urlTemplateParts } from "@/api/config/urls";
import { AgreementsOverduePaymentsModeType } from "@/store/loan/modules/agreementsOverduePayments/types/agreementsOverduePaymentsModeType";
import { AgreementsAccruedPenaltiesModeType } from "@/store/loan/modules/agreementsAccruedPenalties/types/agreementsAccruedPenaltiesModeType";

export class OverdueOrderController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}

	private getRawUrl(operation: "add" | "report" | "submit" | "get", mode: AgreementsOverduePaymentsModeType): string {
		const modeUrlPart = (mode == AgreementsOverduePaymentsModeType.MAIN_DEBT ? "loan" : "interest");
		return urls.loan.overdueOrder[operation].replace("{mode}", modeUrlPart);
	}

	addOverdueOrder = async (items: any[], mode: AgreementsOverduePaymentsModeType): Promise<any> => {
		const url = this.getRawUrl("add", mode);
		return await this.client.post<any>(url, { rows: items });
	}

	getOverdueOrder = async (orderNumber: number, mode: AgreementsOverduePaymentsModeType): Promise<any> => {
		const url = this.getRawUrl("get", mode).replace("{orderNumber}", orderNumber.toString());
		return (await this.client.get<any>(url)) || null;
	};

	getOverdueOrderPdf = async (orderNumber: number, mode: AgreementsOverduePaymentsModeType): Promise<Blob> => {
		const url = this.getRawUrl("report", mode).replace("{orderNumber}", orderNumber.toString());
		return await this.client.get<Blob>(url, { responseType: "blob" });
	};

	submitOverdueOrder = async (orderId: string, comment: string, mode: AgreementsOverduePaymentsModeType): Promise<any> => {
		const url = this.getRawUrl("submit", mode).replace(urlTemplateParts.id, orderId);
		return await this.client.post<any>(url, { OrderId: orderId, Comment: comment });
	};

	private getPenaltyRawUrl(operation: "add" | "report" | "submit" | "get"): string {
		return urls.loan.overdueOrder[operation].replace("{mode}", "penalty");
	}

	private getPenaltyType(mode: AgreementsAccruedPenaltiesModeType): string {
		return mode == AgreementsAccruedPenaltiesModeType.MAIN_DEBT ? "MainDebtOverdue" : "InterestOverdue";
	}

	addPenaltyOverdueOrder = async (items: any[], mode: AgreementsAccruedPenaltiesModeType): Promise<any> => {
		const url = this.getPenaltyRawUrl("add");
		const penaltyType = this.getPenaltyType(mode);
		return await this.client.post<any>(url, { penaltyType: penaltyType, rows: items });
	}

	getPenaltyOverdueOrder = async (orderNumber: number, mode: AgreementsAccruedPenaltiesModeType): Promise<Blob> => {
		const penaltyType = this.getPenaltyType(mode);
		const url = this.getPenaltyRawUrl("get").replace("{orderNumber}", orderNumber.toString()) + "/" + penaltyType;
		return await this.client.get<Blob>(url) || null;
	};

	getPenaltyOverdueOrderPdf = async (orderNumber: number, mode: AgreementsAccruedPenaltiesModeType): Promise<Blob> => {
		const penaltyType = this.getPenaltyType(mode);
		const url = this.getPenaltyRawUrl("report").replace("{orderNumber}", orderNumber.toString()) + "/" + penaltyType;
		return await this.client.get<Blob>(url, { responseType: "blob" });
	};

	submitPenaltyOverdueOrder = async (orderId: string, comment: string): Promise<any> => {
		const url = this.getPenaltyRawUrl("submit").replace(urlTemplateParts.id, orderId);
		return await this.client.post<any>(url, { OrderId: orderId, Comment: comment });
	}
}

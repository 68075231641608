import {
	actionTypes,
	getterTypes,
	mutationTypes,
	namespace
} from "@/store/loan/modules/overdueInterest/modules/overdueInterestIList/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import AbortService from "@/services/abortService";
import OverdueListState from "@/store/loan/modules/overdueInterest/modules/overdueInterestIList/types/overdueInterestListState";
import OverdueInterestListState from "@/store/loan/modules/overdueInterest/modules/overdueInterestIList/types/overdueInterestListState";
import mapper from "@/store/loan/modules/overdueInterest/modules/overdueInterestIList/mapper";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import { cloneDeep } from "lodash";
import { LoanInterestController } from "@/api/loan/loanInterest";
import ApiOverdueInterest from "@/api/loan/types/loanInterest/apiOverdueInterest";
import OverdueInterest from "@/store/loan/modules/overdueInterest/modules/overdueInterestIList/types/overdueInterest";
import FormMixinBuilder from "@/store/shared/form";
import ListingMixinBuilder from "@/store/shared/listing";
import PagingMixinBuilder from "@/store/shared/paging";
import SearchMixinBuilder from "@/store/shared/search";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import ListingModel from "@/store/shared/listing/models/listingModel";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import { plainToInstance } from "class-transformer";
import { OverdueInterestModeType } from "@/store/loan/modules/overdueInterest/types/overdueInterestModeType";
import router from "@/router/loan";
import { resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import storeManager from "@/store/manager";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import SortingMixinBuilder from "@/store/shared/sorting";

const abortService = new AbortService();
const loanInterestController = new LoanInterestController(abortService);

const baseMixin = (new BaseMixinBuilder(abortService)).build();
const formMixin = (new FormMixinBuilder()).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["newItem"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new OverdueListState(
			new ListingModel<OverdueInterest>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String[]>({
				type: ["startDate", "financingSourceId", "quarterId"],
				order: [sortingOrderType.ascending, sortingOrderType.ascending, sortingOrderType.ascending]
			}),
			new PagingModel({
				total: 0,
				page: 1,
				pageSize: 25
			}),
			new SearchModel({
				query: ""
			}),
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}


const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<OverdueListState, any>>{
	...listingMixin.getters,
	...formMixin.getters,
	...snapshotMixin.getters,
	[getterTypes.formattedItems]: state => {
		return state.listing.items.map(x => {
			return plainToInstance(OverdueInterest, {
				...x
			});
		});
	}
};

const actions = <ActionTree<OverdueListState, any>>{
	...listingMixin.actions,
	...pagingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }) {
		await dispatch(actionTypes.initializeBase);
		
		await dispatch(actionTypes.fetch);
		
		commit(mutationTypes.SET_IS_INITIALIZED, true);
		commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
	},
	async [actionTypes.fetch]({ commit }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);
		
		try {
			let items = await loanInterestController.getOverdueInterest(router.currentRoute.params.projectId);
			
			commit(mutationTypes.SET_LISTING_ITEMS, items.map(x => mapper.map(x, ApiOverdueInterest, OverdueInterest)));
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			console.error(error);
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.updateItems]({ commit, state }) {
		commit(mutationTypes.SET_IS_ITEMS_UPDATING, true);
		
		try {
			await loanInterestController.updateOverdueInterest(router.currentRoute.params.projectId,
				state.editableItems.map(x => mapper.map(x, OverdueInterest, ApiOverdueInterest)));
			
			commit(mutationTypes.SET_LISTING_ITEMS, cloneDeep(state.editableItems));
			commit(mutationTypes.SET_MODE, OverdueInterestModeType.READ);
			alertService.addInfo(AlertKeys.OVERDUE_INTEREST_SUCCESS_SAVED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_ITEMS_UPDATING, false);
		}
	},
	async [actionTypes.createItem]({ commit, state }) {
		commit(mutationTypes.SET_IS_ITEM_CREATING, true);
		try {
			const item = await loanInterestController.createOverdueInterestItem(router.currentRoute.params.projectId,
				mapper.map(state.newItem, OverdueInterest, ApiOverdueInterest));
			
			alertService.addInfo(AlertKeys.OVERDUE_SUCCESS_CREATED);
			
			commit(mutationTypes.SET_LISTING_ITEMS, [...state.listing.items, mapper.map(item, ApiOverdueInterest, OverdueInterest)]);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_ITEM_CREATING, false);
		}
	},
	async [actionTypes.deleteItem]({ dispatch, commit, rootState, state, getters }, { item }) {
		commit(mutationTypes.SET_IS_ITEM_DELETING, true);
		
		try {
			await loanInterestController.deleteOverdueInterestItem(router.currentRoute.params.projectId, item.id);
			
			alertService.addInfo(AlertKeys.OVERDUE_INTEREST_SUCCESS_DELETED);
			
			commit(mutationTypes.SET_LISTING_ITEMS, state.listing.items.filter(x => x.id !== item.id));
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_ITEM_DELETING, false);
		}
	}
};

const mutations = <MutationTree<OverdueListState>>{
	...listingMixin.mutations,
	...pagingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	[mutationTypes.RESET_NEW_ITEM](state) {
		state.newItem = new OverdueInterest();
	},
	[mutationTypes.SET_EDITABLE_ITEMS](state, value) {
		state.editableItems = value;
	},
	[mutationTypes.SET_IS_ITEMS_UPDATING](state, value) {
		state.isItemsUpdating = value;
	},
	[mutationTypes.SET_IS_ITEM_CREATING](state, value) {
		state.isItemCreating = value;
	},
	[mutationTypes.SET_IS_ITEM_DELETING](state, value) {
		state.isItemDeleting = value;
	},
	[mutationTypes.SET_NEW_ITEM_FINANCING_SOURCE_ID](state, value) {
		state.newItem.financingSourceId = value;
	},
	[mutationTypes.SET_NEW_ITEM_QUARTER_ID](state, value) {
		state.newItem.quarterId = value;
	},
	[mutationTypes.SET_NEW_ITEM_START_DATE](state, value) {
		state.newItem.startDate = value;
	},
	[mutationTypes.SET_NEW_ITEM_AMOUNT](state, value) {
		state.newItem.amount = value;
	},
	[mutationTypes.SET_NEW_ITEM_COMMENT](state, value) {
		state.newItem.comment = value;
	},
	[mutationTypes.SET_MODE](state, value) {
		state.mode = value;
	}
};

const subscribe = (store: any) => {
	const { commit, dispatch } = store;
	
	store.subscribe(async ({ type, payload }: any, state: any) => {
		switch (type) {
			case resolveMutation(storeManager.loan.overdueInterest.overdueInterestList.namespace, mutationTypes.SET_MODE):
			{
				const { listing: { items } } = resolveNestedState<OverdueInterestListState>(state,
					storeManager.loan.overdueInterest.overdueInterestList.namespace);
				
				if(payload === OverdueInterestModeType.EDIT)
					commit(resolveMutation(storeManager.loan.overdueInterest.overdueInterestList.namespace,
							mutationTypes.SET_EDITABLE_ITEMS),
						cloneDeep(items));
				
				break;
			}
			
			default:
				break;
		}
	});
};

export {
	namespace, state, getters, actions, mutations, subscribe
};

const overdueInterestListModule = {
	namespace, state, getters, actions, mutations, subscribe, namespaced: true
};

export default overdueInterestListModule;

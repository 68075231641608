import "reflect-metadata";
import Vue from "vue";
import App from "./LoanApp.vue";
import router from "./router/loan";
import store from "./store/loan";
import { i18n, vuetify } from "./plugins";
import { sync } from "vuex-router-sync";
import "@/assets/main.css";
import configureApi from "@/api/index";
import LoanLocalStorageService from "@/services/localStorage/loanLocalStorageService";
import PermissionsResolver from "@/api/authorization/permissionsResolver";
import { checkReportingFinalUrl, checkReportingUrl } from "@/utils/validation";

const permissionsResolver = new PermissionsResolver();
new LoanLocalStorageService();

configureApi();

sync(store, router);
Vue.config.productionTip = false;

new Vue({
	i18n,
	router,
	store,
	vuetify,
	render: (h) => h(App),
	async created() {
		// Проверяем урл страницы, если он относится к отчетным документам, то мы пропускаем запрос permissions, так как там permissions получаем внутри модели запроса
		const isReportingUrl = checkReportingUrl();
		const isReportingFinalUrl = checkReportingFinalUrl();

		if (isReportingUrl || isReportingFinalUrl) {
			console.debug('Данная страница отчетных документов не содержит запроса permissions');
			return;
		}

		await permissionsResolver.resetPermissionsStorage();
		await permissionsResolver.updatePermissionsStorage();
	}
}).$mount("#app");

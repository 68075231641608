import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import urls, { urlTemplateParts } from "@/api/config/urls";
import { plainToInstance } from "class-transformer";
import ApiReportsParameters from "@/api/loan/types/reports/apiReportsParameters";
import ApiReport from "@/api/loan/types/reports/apiReport";
import ApiReportFilter from "@/api/loan/types/reports/apiReportFilter";
import { getFormDataHeaders } from "@/utils/getFormDataHeaders";
import ApiReportDocumentUploadParameters from "@/api/loan/types/reports/apiReportDocumentUploadParameters";
import ApiReportAddQueryInfo from "@/api/loan/types/reports/apiReportAddQueryInfo";
import ApiReportFinal from "@/api/loan/types/reports/apiReportFinal";
import { prepareUrl } from "@/utils/prepareUrlQuery";

export class ReportsController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getReports = async ({ projectId, companyId, year, quarter }: ApiReportsParameters): Promise<ApiReport> => {
		const url = urls.reports.reports.getReports
						.replace(urlTemplateParts.id, projectId)
						.replace(urlTemplateParts.subId, companyId)
						.replace(urlTemplateParts.thirdId, year)
						.replace(urlTemplateParts.fourthId, quarter);
		
		let data = await this.client.get<ApiReport>(url);
		
		return plainToInstance(ApiReport, data);
	};
	
	getFilter = async (projectId: string): Promise<ApiReportFilter> => {
		let data = await this.client.get<ApiReport>(urls.reports.reports.getReportsFilter.replace(urlTemplateParts.id, projectId));
		
		return plainToInstance(ApiReportFilter, data);
	};
	
	uploadReportDocument = async ({
		projectId,
		companyId,
		year,
		quarter,
		documentType,
		document,
		signature,
		calendarPlanStageId,
		keyIndicatorType
	}: ApiReportDocumentUploadParameters): Promise<void> => {
		const url = urls.reports.reports.uploadReportDocument
						.replace(urlTemplateParts.id, projectId)
						.replace(urlTemplateParts.subId, companyId)
						.replace(urlTemplateParts.thirdId, year)
						.replace(urlTemplateParts.fourthId, quarter)
						.replace(urlTemplateParts.fifthId, documentType);
		
		const fd = new FormData();
		
		fd.append("Document", document);
		
		if(signature)
			fd.append("Signature", signature);
		
		let params: any = {};
		
		if(calendarPlanStageId)
			params.calendarPlanStageId = calendarPlanStageId;
		if(keyIndicatorType)
			params.keyIndicatorType = keyIndicatorType;
		
		await this.client.post(url, fd, { params, ...getFormDataHeaders() });
	};
	
	generateReport = async (url: string): Promise<Blob> => {
		return await this.client.get<Blob>(urls.reports.reports.generateReport
											   .replace(urlTemplateParts.id, url), { responseType: "blob" }, true);
	};
	
	downloadBulk = async ({ projectId, companyId, year, quarter, includeAllVersions }: ApiReportsParameters): Promise<Blob | any> => {
		const url = prepareUrl(urls.reports.reports.downloadBulk
								   .replace(urlTemplateParts.id, projectId)
								   .replace(urlTemplateParts.subId, companyId)
								   .replace(urlTemplateParts.thirdId, year)
								   .replace(urlTemplateParts.fourthId, quarter), { includeAllVersions });
		
		return await this.client.get<Blob>(url, { responseType: "blob" }, true);
	};
	
	acceptReport = async ({ projectId, companyId, year, quarter }: ApiReportsParameters): Promise<ApiReport> => {
		const url = urls.reports.reports.acceptReport
						.replace(urlTemplateParts.id, projectId)
						.replace(urlTemplateParts.subId, companyId)
						.replace(urlTemplateParts.thirdId, year)
						.replace(urlTemplateParts.fourthId, quarter);
		
		const data = await this.client.post<ApiReport>(url, {});
		return plainToInstance(ApiReport, data);
	};
	declineReport = async ({ projectId, companyId, year, quarter }: ApiReportsParameters): Promise<ApiReport> => {
		const url = urls.reports.reports.declineReport
						.replace(urlTemplateParts.id, projectId)
						.replace(urlTemplateParts.subId, companyId)
						.replace(urlTemplateParts.thirdId, year)
						.replace(urlTemplateParts.fourthId, quarter);
		
		const data = await this.client.post<ApiReport>(url, {});
		return plainToInstance(ApiReport, data);
	};
	
	sendRequestEmailConfirmation = async ({ projectId, companyId, year, quarter }: ApiReportsParameters): Promise<void> => {
		const url = urls.reports.reports.sendRequestEmailConfirmation
						.replace(urlTemplateParts.id, projectId)
						.replace(urlTemplateParts.subId, companyId)
						.replace(urlTemplateParts.thirdId, year)
						.replace(urlTemplateParts.fourthId, quarter);
		
		await this.client.post<ApiReport>(url, {});
	};
	
	addQueryReport = async (params: ApiReportsParameters, file: File, addQueryInfo: ApiReportAddQueryInfo): Promise<void> => {
		const url = urls.reports.reports.addQuery
						.replace(urlTemplateParts.id, params.projectId)
						.replace(urlTemplateParts.subId, params.companyId)
						.replace(urlTemplateParts.thirdId, params.year)
						.replace(urlTemplateParts.fourthId, params.quarter);
		
		const data = new FormData();
		
		data.append("File", file);
		data.append("Title", addQueryInfo.title);
		data.append("Description", addQueryInfo.description);
		data.append("ExecutionDate", addQueryInfo.executionDate);
		
		await this.client.post<ApiReport>(url, data, getFormDataHeaders());
	};
	
	getReportFinal = async (projectId: string): Promise<ApiReportFinal> => {
		let data = await this.client.get<ApiReportFinal>(urls.reports.reportsFinal.getFinalReport.replace(urlTemplateParts.id, projectId));
		
		return plainToInstance(ApiReportFinal, data);
	};
	
	uploadReportFinalDocument = async (projectId: string, document: File, documentType: string, signature?: string): Promise<void> => {
		const url = urls.reports.reportsFinal.uploadFinalReportDocument
						.replace(urlTemplateParts.id, projectId)
						.replace(urlTemplateParts.subId, documentType);
		
		const fd = new FormData();
		
		fd.append("Document", document);
		
		if(signature)
			fd.append("Signature", signature);
		
		await this.client.post(url, fd, getFormDataHeaders());
	};
	
	downloadBulkFinal = async (projectId: string): Promise<Blob> => {
		return await this.client.get<Blob>(urls.reports.reportsFinal.downloadBulk
											   .replace(urlTemplateParts.id, projectId), { responseType: "blob" }, true);
	};
	
	acceptReportFinal = async (projectId: string): Promise<ApiReportFinal> => {
		const url = urls.reports.reportsFinal.acceptReport.replace(urlTemplateParts.id, projectId);
		
		const data = await this.client.post<ApiReportFinal>(url, {});
		return plainToInstance(ApiReportFinal, data);
	};
	declineReportFinal = async (projectId: string): Promise<ApiReportFinal> => {
		const url = urls.reports.reportsFinal.declineReport.replace(urlTemplateParts.id, projectId);
		
		const data = await this.client.post<ApiReportFinal>(url, {});
		return plainToInstance(ApiReportFinal, data);
	};
	
	addQueryReportFinal = async (projectId: string, file: File, addQueryInfo: ApiReportAddQueryInfo): Promise<void> => {
		const url = urls.reports.reportsFinal.addQueryFinal.replace(urlTemplateParts.id, projectId);
		
		const data = new FormData();
		
		data.append("File", file);
		data.append("Title", addQueryInfo.title);
		data.append("Description", addQueryInfo.description);
		data.append("ExecutionDate", addQueryInfo.executionDate);
		
		await this.client.post<ApiReportFinal>(url, data, getFormDataHeaders());
	};
}

<template>
	<loan-content-layout v-if="isInitialized && !isAgreementLoading">
		<loan-payments-agreement></loan-payments-agreement>

		<v-row class="mx-0">
			<v-col class="py-1 d-flex align-center">
				<span v-if="financeSources.length === 2" class="secondary--text text--lighten-2 font-weight-bold" style="font-size: 20px">
					{{ $t("alerts.info.upcomingPaymentsTwoSourcesAlert") }}
				</span>
				<v-spacer></v-spacer>
				<span v-if="payments[0]?.maxDateDds" class="text-body-2 font-weight-medium green--text text--lighten-3">
					{{ `${$t("common.relevantFor")} ${formatDate(payments[0]?.maxDateDds, dateFormat)}` }}
				</span>
			</v-col>
		</v-row>

		<loan-payments-planned-payments></loan-payments-planned-payments>
		<v-row class="mx-0">
			<v-col class="py-1 d-flex align-center">
				<span class="text-body-2 font-weight-bold">! Если плановая дата платежа приходится на выходной или праздничный день, то заемщик имеет право осуществить погашение в первый рабочий день после плановой даты платежа с оплатой процентов за дополнительный день в процентном периоде.</span>
			</v-col>
		</v-row>
		<loan-payments-overdues></loan-payments-overdues>
	</loan-content-layout>
	<loan-upcoming-payments-loader v-else></loan-upcoming-payments-loader>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn";
import LoanContentLayout from "@/components/layouts/LoanContentLayout";
import FrpTextBtn from "Components/buttons/FrpTextBtn";
import LoanSwitch from "Components/fields/LoanSwitch";
import FrpIcon from "Components/icon/FrpIcon";
import LoanCard from "Components/layouts/LoanCard";
import LoanNestedCard from "Components/layouts/LoanNestedCard";
import colorsMixin from "Mixins/colorsMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import { formatDate } from "Utils/dates";
import { dateFormat } from "Utils/formats";
import LoanOverdues from "Views/loan/loanSchedule/overdues/LoanOverdues";
import LoanPaymentsAgreement from "Views/loan/upcomingPayments/agreement/LoanPaymentsAgreement";
import LoanUpcomingPaymentsLoader from "Views/loan/upcomingPayments/LoanUpcomingPaymentsLoader";
import LoanPaymentsOverdues from "Views/loan/upcomingPayments/overdues/LoanPaymentsOverdues";
import LoanPaymentsPlannedPayments from "Views/loan/upcomingPayments/plannedPayments/LoanPaymentsPlannedPayments";
import { i18n } from "@/plugins";
import { createNamespacedHelpers } from "vuex";
import { mutationTypes, namespace } from "Store/loan/modules/upcomingPayments/types";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin, storeModuleBasedPage],
	metaInfo: {
		title: i18n.t("metaTitles.upcomingPayments")
	},
	data() {
		return {
			namespace,
			formatDate,
			dateFormat
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			isItemsLoading: state => state.isItemsLoading,
			payments: state => state.payments,
			financeSources: state => state.financeSources,
			isAgreementLoading: state => state.isAgreementLoading
		}),
		financeSourcesOptions() {
			return this.financeSources.map(x => ({
				text: x.name,
				value: x.id
			}));
		}
	},
	async created() {
		await this.initializeStore();
	},
	components: {
		LoanUpcomingPaymentsLoader,
		LoanPaymentsAgreement,
		LoanSwitch,
		FrpTextBtn,
		FrpIcon,
		LoanCard,
		LoanNestedCard,
		FrpBtn,
		LoanContentLayout,
		LoanOverdues,
		LoanPaymentsOverdues,
		LoanPaymentsPlannedPayments
	}
};
</script>

<template>
	<loan-nested-card :title="$t('titles.percents')">
		<v-data-table :headers="headers"
					  :loading-text="$t('tables.loading')"
					  hide-default-footer
					  item-key="id"
					  :loading="isItemsLoading"
					  :items="payments"
					  :items-per-page="-1"
					  :mobile-breakpoint="mobileBreakpoint"
					  :options="options"
					  :item-class="() => 'text-body-2'"
					  class="d-flex flex-column table-headers-top black--text upcoming-payments-table">
			<template #item="{ item }">
				<tr class="text-body-2">
					<td v-for="column in headers">
						<span v-if="column.value === 'balancePrevPercent'">
							<frp-btn without-padding
									 icon
									 height="20"
									 x-small
									 color="primary"
									 @click="setPaymentIsExpanded({ sourceId: item.financingSourceId, value: !item.isExpanded })"
									 dark>
								<frp-icon :color="colors.primary.base"
										  :src="item.isExpanded ? 'ico_loan-chevron-up' : 'ico_loan-chevron-down'">
								</frp-icon>
							</frp-btn>
							<span class="ml-2">{{ formatNullableTableValue(formatCurrency(item.balancePrevPercent)) }}</span>
						</span>

						<span v-else-if="column.value === 'datePayment'">
							{{ formatNullableTableValue(formatDate(item.datePayment, dateFormat)) }}
						</span>

						<span v-else-if="column.value === 'accruedPercent'">
							{{ formatNullableTableValue(formatCurrency(item.accruedPercent)) }}
						</span>

						<span v-else-if="column.value === 'paymentPrevPercent'">
							{{ formatNullableTableValue(formatCurrency(item.paymentPrevPercent)) }}
						</span>

						<span v-else-if="column.value === 'payment'">
							{{ formatNullableTableValue(formatCurrency(item.payment)) }}
						</span>
						
						<template v-else-if="column.value === 'financingSourceId'">
							<span>{{ financeSources.find(x => x.id === item.financingSourceId).name }}</span>
						</template>

						<template v-else-if="column.value === 'action'">
							<frp-btn elevation="0"
									 dark
									 :loading="loadingFiles.some(x => x.type === PaymentOrderType.SCHEDULE_MAIN_DEBT_INTEREST_REPAYMENT && x.sourceId === item.financingSourceId)"
									 color="blue"
									 @click="downloadPaymentOrder({ sourceId: item.financingSourceId, type: PaymentOrderType.SCHEDULE_MAIN_DEBT_INTEREST_REPAYMENT })">
								{{ $t("buttons.generatePaymentOrder") }}
							</frp-btn>
						</template>

						<span v-else>{{ formatNullableTableValue(item[column.value]) }}</span>
					</td>
				</tr>

				<tr v-if="item.isExpanded" class="row-no-hover">
					<td colspan="6" class="pr-0 pl-16">
						<v-data-table :headers="subHeaders"
									  :loading-text="$t('tables.loading')"
									  hide-default-footer
									  :loading="isItemsLoading"
									  item-key="id"
									  :items="getInterestDetailsItems(item.financingSourceId)"
									  :items-per-page="-1"
									  :mobile-breakpoint="mobileBreakpoint"
									  :options="options"
									  @click.ctrl:row=""
									  :item-class="() => 'text-body-2'"
									  class="d-flex flex-column black--text upcoming-payments-table">
							<template #header.periodName="{ header }">
								<span class="d-block">{{ $t("tables.period") }}</span>
								<span>
									{{
										getInterestDetailsItems(item.financingSourceId)[0]?.minDate && $t("common.with") + " " +
										formatDate(getInterestDetailsItems(item.financingSourceId)[0].minDate, dateFormat)
									}}
									{{
										getInterestDetailsItems(item.financingSourceId)[0]?.maxDate && $t("common.to") + " " +
										formatDate(getInterestDetailsItems(item.financingSourceId)[0].maxDate, dateFormat)
									}}
								</span>
							</template>

							<template #item.accruedAmount="{ item }">
								<span>{{ formatNullableTableValue(formatCurrency(item.accruedAmount)) }}</span>
							</template>

							<template #foot="{ items }">
								<tfoot class="text-subtitle-1 font-weight-medium grey--text text--darken-3">
								<tr>
									<template>
										<td>
											<span>{{ $t("content.total") }}</span>
										</td>
										<td>
											<span>
												{{ items.map(x => x.periodDays).reduce((x, sum) => sum += +x, 0) }}
											</span>
										</td>
										<td>
											<span>
												{{ items.map(x => x.accrualDays).reduce((x, sum) => sum += +x, 0) }}
											</span>
										</td>
										<td>
											<span>
												{{ formatCurrency(items.map(x => x.accruedAmount).reduce((x, sum) => sum += +x, 0)) }}
											</span>
										</td>
									</template>
								</tr>
								</tfoot>
							</template>
						</v-data-table>
					</td>
				</tr>
			</template>
		</v-data-table>
	</loan-nested-card>
</template>

<script>
import FrpBtn from "Components/buttons/FrpBtn";
import FrpIcon from "Components/icon/FrpIcon";
import LoanNestedCard from "Components/layouts/LoanNestedCard";
import colorsMixin from "Mixins/colorsMixin";
import { PaymentOrderType } from "Store/loan/modules/upcomingPayments/types/paymentOrderType";
import { formatDate } from "Utils/dates";
import { dateFormat } from "Utils/formats";
import { formatCurrency, formatNullableTableValue } from "Utils/formatting";
import { actionTypes, mutationTypes, namespace } from "Store/loan/modules/upcomingPayments/types";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin],
	data() {
		return {
			mobileBreakpoint: 880,
			options: {},
			PaymentOrderType,
			formatDate,
			dateFormat,
			formatCurrency,
			formatNullableTableValue
		};
	},
	computed: {
		...mapState({
			loadingFiles: state => state.loadingFiles,
			isItemsLoading: state => state.isItemsLoading,
			payments: state => state.payments,
			financeSources: state => state.financeSources,
			interestDetailsItems: state => state.interestDetailsItems
		}),
		balancePrevDate() {
			return this.payments[0]?.datePaymentPrev;
		},
		datePaymentPrev() {
			return formatDate(this.payments[0]?.datePaymentPrev, dateFormat);
		},
		headers() {
			const headers = [
				{
					text: `${this.$t("tables.balancePrevPercent")} ${this.datePaymentPrev ?
						this.$t("common.forDate") + " " + this.datePaymentPrev : ""}`,
					value: "balancePrevPercent",
					class: "text-body-2 font-weight-bold black--text py-2 pl-11",
					width: "19%",
					sortable: false
				},
				{
					text: this.$t("tables.datePayment"),
					value: "datePayment",
					class: "text-body-2 font-weight-bold black--text py-2",
					width: "20%",
					sortable: false
				},
				{
					text: this.$t("tables.accruedPercent"),
					value: "accruedPercent",
					class: "text-body-2 font-weight-bold black--text py-2",
					width: "20%",
					sortable: false
				},
				{
					text: this.$t("tables.paymentPrevPercent"),
					value: "paymentPrevPercent",
					class: "text-body-2 font-weight-bold black--text py-2",
					width: "20%",
					sortable: false
				},
				{
					text: this.$t("tables.paymentSum"),
					value: "payment",
					class: "text-body-2 font-weight-bold black--text py-2",
					width: "20%",
					sortable: false
				},
				{
					text: "",
					value: "action",
					width: "1%",
					sortable: false
				}
			];
			
			if(this.financeSources.length >= 2)
				headers.splice(-1, 0, {
					text: this.$t("tables.financeSource"),
					value: "financingSourceId",
					class: "text-body-2 font-weight-bold black--text py-2",
					width: "30%",
					sortable: false
				});
			
			return headers;
		},
		subHeaders() {
			return [
				{
					text: this.$t("tables.period"),
					value: "periodName",
					class: "text-body-2 font-weight-bold black--text py-2",
					width: "25%",
					sortable: false
				},
				{
					text: this.$t("tables.periodDays"),
					value: "periodDays",
					class: "text-body-2 font-weight-bold black--text py-2",
					width: "25%",
					sortable: false
				},
				{
					text: this.$t("tables.accrualDays"),
					value: "accrualDays",
					class: "text-body-2 font-weight-bold black--text py-2",
					width: "25%",
					sortable: false
				},
				{
					text: this.$t("tables.accruedInterestSum"),
					value: "accruedAmount",
					class: "text-body-2 font-weight-bold black--text py-2",
					width: "25%",
					sortable: false
				}
			];
		}
	},
	methods: {
		...mapActions({
			downloadPaymentOrder: actionTypes.downloadPaymentOrder
		}),
		...mapMutations({
			setPaymentIsExpanded: mutationTypes.SET_PAYMENT_IS_EXPANDED
		}),
		getInterestDetailsItems(sourceId) {
			return this.interestDetailsItems.filter(x => x.financingSourceId === sourceId);
		}
	},
	components: { LoanNestedCard, FrpIcon, FrpBtn }
};
</script>
